import React from "react";
import AddSinglePointOfContact from "../components/AddSinglePointOfContact";
import PointOfContactDetails from "../components/PointOfContactDetails";
import DeleteContact from "../functions/DeleteContact";
import { CustomPointOfContactEmailField } from "../customFields/CustomPointOfContactEmailField";
import { CustomPointOfContactPhoneField } from "../customFields/CustomPointOfContactPhoneField";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

const { listQuery: listPointOfContacts, updateMutation: updatePointOfContact } = generateGraphql(
  "PointOfContact",
  ["firstName", "lastName", "title", "email", "phone", "meetings", "user"],
  {
    meetings: `(limit: 50) { items { id } } `,
    user: `{ id username email name roleLinks { items { id } } }`,
  },
);

/**
 * Displays a list of Points of Contact
 * @param {object} gridConfig - The configuration for the DataGrid.
 * @param {object} cardConfig - The configuration for the Card.
 * @param {object} queryConfig - The configuration for the query.
 * @param {object} roleConfig - the configuration for role checking
 * @param {string} organizationID - The ID of the organization to display the Points of Contact for.
 * @param {string[]} options - The options to display for each Point of Contact.
 * @param {object} props - any additional props to pass down to the use data grid hook
 * @return {{selectedData: [], gridDisplay: *, cardDisplay: *, data: Object[]|Object, setData: function(*): void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: *, dashboardDisplay: *, changeTargetView: changeTargetView, isLoading: *, createResourceModal: {setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}, setIsLoading: (value: (((prevState: *) => *) | *)) => void, resetFunction: *, targetView: *, apiRef: React.MutableRefObject<GridApiPro>, selectedIDs: [], setSelectedIDs: (value: (((prevState: []) => []) | [])) => void, setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const usePointOfContactDataGrid = ({
  gridConfig,
  cardConfig,
  queryConfig,
  roleConfig,
  organizationID,
  options,
  ...props
}) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.POINT_OF_CONTACT;
  roleConfig = {
    module,
    resource,
    ...(roleConfig || {}),
  };
  const typename = "PointOfContact";

  cardConfig = {
    title: "Points of Contact",
    icon: "icon-people",
    ...cardConfig,
  };

  queryConfig = {
    query: listPointOfContacts,
    organizationID,
    ...roleConfig,
  };

  /**
   * Utility function which verifies phone numbers
   * @param {string} phoneNumberString - phone entered by user to be verified
   * @return {null|string} - null if invalid phone number, formatted phone number if valid
   */
  const formatPhoneNumber = ({ phoneNumberString }) => {
    const cleanedString = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleanedString.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const internationalCode = match[1] ? "+1 " : "";
      return [internationalCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return null;
  };

  const fields = [
    {
      name: "firstName",
      flex: 1,
      minWidth: 100,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "lastName",
      flex: 1,
      minWidth: 100,
    },
    {
      name: "title",
      width: 150,
      valueFormatter: (params) => {
        return params?.value || "No Title Provided";
      },
    },
    {
      name: "email",
      component: <CustomPointOfContactEmailField module={module} resource={resource} />,
      disablePropagation: true,
      width: 300,
      valueFormatter: (params) => {
        return params?.value || "No Email Provided";
      },
    },
    {
      name: "phone",
      component: <CustomPointOfContactPhoneField module={module} resource={resource} />,
      disablePropagation: true,
      width: 300,
      valueFormatter: (params) => {
        return formatPhoneNumber({ phoneNumberString: params?.value }) || "No Phone Provided";
      },
    },
  ];

  gridConfig = {
    fields,
    options: options || ["details", "delete"],
    createResourceComponent: <AddSinglePointOfContact organizationID={organizationID} />,
    enableSelection: gridConfig?.enableSelection,
    detailsComponent: <PointOfContactDetails tableDisplay={true} />,
    route: "#/organization_manager/contacts/",
    updateMutation: updatePointOfContact,
    deleteFunction: DeleteContact,
    persistenceUUID: "point-of-contact-c29fde11-597d-4772-ab31-90c09833dff4",
    typename,
    ...props,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
  });

  return { ...grid };
};
