import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { generateGraphqlFields } from "@rivial-security/generategraphql";
import { getProceduresNestedFields } from "../../PlaybookBuilder/functions/getProceduresNestedFields";

export const listResponsePlans = /* GraphQL */ `
  query ResponseByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelIncidentResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responseByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
         ${generateGraphqlFields(["id", "name", "procedures", "tiers", "detections", "mainContact", "definitions"], {
           mainContact: `{ firstName lastName title id email phone }`,
           tiers: `(limit: 100) {
                items {
                  id
                  level
                  name
                  description
                  teams (limit: 100) {
                    items {
                      id
                      team {
                        id
                        name
                        description
                        pointOfContactLinks (limit: 100) {
                          items {
                            id
                            pointOfContact {
                              id
                              firstName
                              lastName
                              title
                              email
                              phone
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }`,
           ...getProceduresNestedFields(),
           detections: `{ name description }`,
           definitions: `{ name definition }`,
         })}
      }
      nextToken
    }
  }
`;

/**
 * Gets the Incident Response Plan singleton
 * @param {string} organizationID - selected organization database id
 * @param {string} [query] - custom graphQL query if do not need all response data
 * @returns {Promise<*>}
 */
export const getResponsePlan = async (organizationID, query) => {
  query = query || listResponsePlans;

  const responsePlans = await ListQueryBy({
    query,
    limit: 1000,
    variables: {
      ownerGroup: organizationID,
    },
  });

  return responsePlans[0];
};
