import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import { checkSystemForRiskControlOverride, isRiskOverrideFieldEnabled } from "@rivial-security/risk-calc-utils";

// Returns Override value if an Override item exists and is Enabled. Else returns false
export const getSystemOverride = (system, override, field, overrideType = "riskControlOverrides") => {
  if (overrideType === "riskControlOverrides") {
    override = checkSystemForRiskControlOverride(system[overrideType], override);

    if (!override) {
      return false;
    }

    const riskControlOverride = isRiskOverrideFieldEnabled(override, field);

    if (!riskControlOverride) {
      return false;
    } else {
      return riskControlOverride;
    }
  }

  return false;
};

export const updateRiskControlOverride = (system, riskControl, field, value, updateSystemCallback) => {
  EventLogger(
    `Updating Control Override. System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Field: ${field}. Value: ${value}`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  EventLogger("Checking if override exists...");

  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  // Check if Override Object already exists for this Risk Control
  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    EventLogger(`Override already exists: ${JSON.stringify(override)}`);

    //EventLogger("Updated Override Object: " + JSON.stringify(overrideObject));

    riskControlOverrides[overrideIndex][field] = value;

    //Check that the override field is in enabled fields
    const currentEnabledFields = riskControlOverrides[overrideIndex]?.enabledFields ?? [];
    if (!currentEnabledFields.includes(field)) {
      riskControlOverrides[overrideIndex].enabledFields = [...currentEnabledFields, field];
    }

    const input = {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    };

    updateSystemCallback({
      input,
    }).then(() => {
      EventLogger("Risk Control Override Updated");
    });
  }

  // Else, add the override to the array
  else {
    EventLogger("Override Does Not Exist");
    // Create RiskControlOverride object
    const overrideObject = {
      enabledFields: [field],
      riskControlId: riskControl.id,
      [field]: value,
    };

    EventLogger(`Created Override Object: ${JSON.stringify(overrideObject)}`);

    riskControlOverrides.push(overrideObject);

    // EventLogger("Added Object, new Risk Control Overrides Array: " + JSON.stringify(riskControlOverrides));

    updateSystemCallback({
      input: {
        id: system.id,
        riskControlOverrides: riskControlOverrides,
      },
    }).then(() => {
      EventLogger("Risk Control Override Added");
    });
  }
};

export const updateRiskControlOverrides = (system, riskControl, fields, value, updateSystemCallback) => {
  EventLogger(
    `Updating Override. System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Fields: ${fields.length}. Value: ${value}`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  EventLogger("Checking if override exists...");

  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  for (const field of fields) {
    // Check if Override Object already exists for this Risk Control
    if (overrideIndex !== -1) {
      const override = system.riskControlOverrides[overrideIndex];

      EventLogger(`Override already exists: ${JSON.stringify(override)}`);

      riskControlOverrides[overrideIndex][field] = value;
    }

    // Else, add the override to the array
    else {
      EventLogger("Override Does Not Exist");
      // Create RiskControlOverride object
      const overrideObject = {
        enabledFields: [field],
        riskControlId: riskControl.id,
        [field]: value,
      };

      EventLogger(`Created Override Object: ${JSON.stringify(overrideObject)}`);

      riskControlOverrides.push(overrideObject);
    }
  }

  updateSystemCallback({
    input: {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    },
  }).then(() => {
    EventLogger("Risk Control Override Updated");
  });
};

export const enableRiskControlOverride = (system, riskControl, field, updateSystemCallback) => {
  EventLogger(
    `Enabling Override for field ${field}. System: ${JSON.stringify(
      system && system.name,
    )}. Risk Control: ${JSON.stringify(riskControl.name)}. Field: ${field}.`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  EventLogger(`Checking if override for ${field} exists...`);
  // Check if this override already exists

  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];
    EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    if (indexOfField === -1) {
      riskControlOverrides[overrideIndex].enabledFields.push(field);

      updateSystemCallback({
        input: {
          id: system.id,
          riskControlOverrides: riskControlOverrides,
        },
      }).then(({ data }) => {
        EventLogger(`Risk Control Override for ${field} Enabled`);
      });
    } else {
      EventLogger("Override already enabled!");
    }
  }
};

export const enableRiskControlOverrides = (system, riskControl, fields, updateSystemCallback) => {
  EventLogger(
    `Enabling Override for System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Field: ${fields.length}.`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;
  for (const field of fields) {
    EventLogger(`Checking if override for ${field} exists...`);

    if (overrideIndex !== -1) {
      const override = system.riskControlOverrides[overrideIndex];
      EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

      const indexOfField = override.enabledFields.indexOf(field);

      if (indexOfField === -1) {
        riskControlOverrides[overrideIndex].enabledFields.push(field);
      } else {
        EventLogger("Override already enabled!");
      }
    }
  }

  updateSystemCallback({
    input: {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    },
  }).then(() => {
    EventLogger(`Risk Control Override for fields Enabled`);
  });
};

export const getRiskControlOverride = (system, riskControl) => {
  const overridesArray = system && system.riskControlOverrides;

  if (overridesArray && overridesArray.length > 0) {
    // If a Specific RiskControl is included in the System.RiskControlOverrides array, find it
    const foundOverride = overridesArray.find((item) => item.riskControlId === riskControl.id);

    // Return the override object, else return undefined
    return foundOverride;
  }
};

export const isNonStandardRiskControl = async (system, riskControl) => {
  if (!system) {
    throw new Error("Invalid System Input");
  }

  if (!riskControl) {
    throw new Error("Invalid riskControl Input");
  }

  const getSystem = /* GraphQL */ `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        riskControlOverrides {
          enabledFields
          riskControlId
          isCompliant
          costOfControl
          controlEffectiveness
          annualRateReduction
          strengthRating
          implementationRating
          outsourced
          implementationDetails
        }
      }
    }
  `;

  const sys = await ItemQuery(getSystem, system.id);

  const overridesArray = sys && sys.riskControlOverrides;

  if (overridesArray && overridesArray.length > 0) {
    // If a Specific RiskControl is included in the System.RiskControlOverrides array, find it
    const foundOverride = overridesArray.find((item) => item.riskControlId === riskControl.id);

    if (foundOverride && foundOverride.enabledFields.length > 0) {
      // Return the override object, else return undefined
      return foundOverride;
    }
  }

  return false;
};

export const enableNonStandardRiskControl = (system, riskControl, updateSystemCallback, initOverrides = {}) => {
  EventLogger(`Setting a Risk Control as Non Standard..`);
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  EventLogger(`Checking if override exists...`);

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: ["implementationRating", "costOfControl", "implementationDetails", "outsourced"],
      implementationRating: override ? override.implementationRating : 0.0,
      costOfControl: override ? override.costOfControl : 0.0,
      implementationDetails: override ? override.implementationDetails : null,
      outsourced: override ? override.outsourced : false,
      ...initOverrides,
    };

    EventLogger(`Override Exists: ${JSON.stringify(override)}`);
    riskControlOverrides[overrideIndex] = overrideObject;
  } else {
    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: ["implementationRating", "costOfControl", "implementationDetails", "outsourced"],
      implementationRating: 0.0,
      costOfControl: 0.0,
      implementationDetails: null,
      ...initOverrides,
    };

    riskControlOverrides.push(overrideObject);
  }

  updateSystemCallback &&
    updateSystemCallback({
      input: {
        id: system.id,
        riskControlOverrides: riskControlOverrides,
      },
    }).then(() => {
      EventLogger(`Risk Control Overrides for Non Standard Control Enabled`);
    });
  return riskControlOverrides;
};

export const disableNonStandardRiskControl = (system, riskControl, updateSystemCallback) => {
  EventLogger(`Setting a Risk Control as Standard..`);
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  EventLogger(`Checking if override exists...`);

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: [],
      implementationRating: override ? override.implementationRating : 0.0,
      costOfControl: override ? override.costOfControl : 0.0,
      implementationDetails: override ? override.implementationDetails : null,
    };

    EventLogger(`Override Exists: ${JSON.stringify(override)}`);
    riskControlOverrides[overrideIndex] = overrideObject;
  } else {
    const overrideObject = {
      riskControlId: riskControl.id,
      enabledFields: [],
      implementationRating: 0.0,
      costOfControl: 0.0,
      implementationDetails: null,
    };
    riskControlOverrides.push(overrideObject);
  }

  updateSystemCallback({
    input: {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    },
  }).then(() => {
    EventLogger(`Risk Control Overrides for Non Standard Control Disabled`);
  });
};

export const disableRiskControlOverride = (system, riskControl, field, updateSystemCallback) => {
  EventLogger(
    `Disabling Override for ${field}. System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Field: ${field}.`,
  );
  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  EventLogger(`Checking if override for ${field} exists...`);
  // Check if this override already exists

  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  if (overrideIndex !== -1) {
    const override = system.riskControlOverrides[overrideIndex];

    EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    const enabledFields = riskControlOverrides[overrideIndex].enabledFields;

    enabledFields.splice(indexOfField, 1);

    EventLogger(`Risk Control Overrides with field disabled: ${JSON.stringify(enabledFields)}`);

    updateSystemCallback({
      input: {
        id: system.id,
        riskControlOverrides: riskControlOverrides,
      },
    }).then(({ data }) => {
      EventLogger(`Risk Control Override for ${field} Disabled`);
    });
  }
};

export const disableRiskControlOverrides = (system, riskControl, fields, updateSystemCallback) => {
  EventLogger(
    `Disabling Overrides. System: ${JSON.stringify(system && system.name)}. Risk Control: ${JSON.stringify(
      riskControl.name,
    )}. Fields.`,
  );

  const riskControlOverrides = system.riskControlOverrides ? system.riskControlOverrides : [];

  for (const a of riskControlOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Control Overrides: " + JSON.stringify(riskControlOverrides));
  EventLogger("Fetching Risk Control Overrides...");

  EventLogger(`Checking if override for fields exists...`);
  // Check if this override already exists
  const overrideIndex = system.riskControlOverrides
    ? system.riskControlOverrides.findIndex((item) => item.riskControlId === riskControl.id)
    : -1;

  for (const field of fields) {
    if (overrideIndex !== -1) {
      const override = system.riskControlOverrides[overrideIndex];

      EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

      const indexOfField = override.enabledFields.indexOf(field);

      const enabledFields = riskControlOverrides[overrideIndex].enabledFields;

      enabledFields.splice(indexOfField, 1);

      EventLogger(`Risk Control Overrides with field disabled: ${JSON.stringify(enabledFields)}`);
    }
  }

  updateSystemCallback({
    input: {
      id: system.id,
      riskControlOverrides: riskControlOverrides,
    },
  }).then(({ data }) => {
    EventLogger(`Risk Control Override for fields Disabled`);
  });
};

// Risks

export const updateRiskOverride = (system, risk, field, value, updateSystemCallback) => {
  EventLogger(
    `Updating Override. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}. Value: ${value}`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  // EventLogger("Fetching Risk Overrides: " + JSON.stringify(riskOverrides));

  EventLogger("Fetching Risk Overrides..");

  EventLogger("Checking if override exists...");

  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];

    EventLogger(`Override already exists: ${JSON.stringify(override)}`);

    //EventLogger("Updated Override Object: " + JSON.stringify(overrideObject));

    riskOverrides[overrideIndex][field] = value;

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      EventLogger("Risk Override Updated");
    });
  }

  // Else, add the override to the array
  else {
    EventLogger("Override Does Not Exist");
    // Create RiskControlOverride object
    const overrideObject = {
      enabledFields: [field],
      riskId: risk.id,
      [field]: value,
    };

    // EventLogger("Created Override Object: " + JSON.stringify(overrideObject));

    riskOverrides.push(overrideObject);

    // EventLogger("Added Object, new Risk Overrides Array: " + JSON.stringify(riskOverrides));

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      EventLogger("Risk Override Added");
    });
  }
};

export const enableRiskOverride = (system, risk, field, updateSystemCallback) => {
  EventLogger(
    `Enabling Override for field ${field}. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}.`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  EventLogger(`Fetching Risk Control Overrides: ${JSON.stringify(riskOverrides)}`);

  EventLogger(`Checking if override for ${field} exists...`);
  // Check if this override already exists

  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];
    EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    if (indexOfField === -1) {
      riskOverrides[overrideIndex].enabledFields.push(field);

      updateSystemCallback({
        input: {
          id: system.id,
          riskOverrides: riskOverrides,
        },
      }).then(({ data }) => {
        EventLogger(`Risk Override for ${field} Enabled`);
      });
    } else {
      EventLogger("Override already enabled!");
    }
  } else {
    EventLogger(`Override for ${field} does not exist. No override to enable.`);
  }
};

export const disableRiskOverride = (system, risk, field, updateSystemCallback) => {
  EventLogger(
    `Disabling Override for ${field}. System: ${JSON.stringify(system && system.name)}. Risk: ${JSON.stringify(
      risk.name,
    )}. Field: ${field}.`,
  );
  const riskOverrides = system.riskOverrides ? system.riskOverrides : [];

  for (const a of riskOverrides) {
    delete a.__typename;
  }

  EventLogger(`Fetching Risk Overrides: ${JSON.stringify(riskOverrides)}`);

  EventLogger(`Checking if override for ${field} exists...`);

  const overrideIndex =
    system && system.riskOverrides ? system.riskOverrides.findIndex((item) => item.riskId === risk.id) : -1;

  if (overrideIndex !== -1) {
    const override = system.riskOverrides[overrideIndex];

    EventLogger(`Override for ${field} Exists: ${JSON.stringify(override)}`);

    const indexOfField = override.enabledFields.indexOf(field);

    const enabledFields = riskOverrides[overrideIndex].enabledFields;

    enabledFields.splice(indexOfField, 1);

    EventLogger(`Risk Overrides with field disabled: ${JSON.stringify(enabledFields)}`);

    updateSystemCallback({
      input: {
        id: system.id,
        riskOverrides: riskOverrides,
      },
    }).then(({ data }) => {
      EventLogger(`Risk Override for ${field} Disabled`);
    });
  } else {
    EventLogger(`Override for ${field} does not exist. No override to disable.`);
  }
};
