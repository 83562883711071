"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyTableWidgetFilters = void 0;
const pope_1 = require("pope");
const applyRuleOnArray_1 = require("../formatData/applyRuleOnArray");
/**
 * Apply table widget filters, filters operators and settings structure is the same as the filterModel from useDataGrid
 * @param {object} seriesItem - series item containing filter information
 * @param {object[]} data - the data to be filtered
 * @param {object} automationContext - object with context to use for smart values
 * @param {object} automationContext.item - the triggering item, if automation was triggered by a resource create/update
 * @param {object} automationContext.steps - result objects from previous automation steps that are referenced in the email template
 * @param {object} automationContext.loop - the loop object, if this email is being sent from a loop
 * @param {object} automationContext.webhookData - the webhook data from the incoming request, if automation was triggered by a webhook
 * @returns {*[]} - the filtered data
 */
const applyTableWidgetFilters = ({ seriesItem, data, automationContext }) => {
    const filterModel = seriesItem?.viewSettings?.filterModel;
    const filters = filterModel?.items;
    console.log("[applyTableWidgetFilters] - ", { filters, filterModel, seriesItem });
    let filteredData = data;
    let newFilteredData = [];
    if (Array.isArray(filters)) {
        for (const filter of filters) {
            const { operatorValue, value, columnField } = filter || {};
            // apply pope smart values to filter value
            const originalFilterValue = value;
            let filterValue = value;
            // Then parse smart values
            try {
                console.log("[applyTableWidgetFilters] - Applying smart values to table filters: ", {
                    originalFilterValue,
                    automationContext,
                });
                filterValue = (0, pope_1.pope)(originalFilterValue, { ...automationContext });
                console.log("[applyTableWidgetFilters] - Applied smart values to table filters: ", {
                    originalFilterValue,
                    filterValue,
                    automationContext,
                });
            }
            catch (e) {
                console.error("[applyTableWidgetFilters] - Error parsing smart values for table filters: ", {
                    originalFilterValue,
                    automationContext,
                });
                // default to original filter value if smart values don't work
                filterValue = originalFilterValue;
            }
            newFilteredData = (0, applyRuleOnArray_1.applyRuleOnArray)({
                items: filteredData,
                field: columnField,
                operator: operatorValue,
                filterValue,
            });
            filteredData = newFilteredData;
            newFilteredData = [];
        }
    }
    return filteredData;
};
exports.applyTableWidgetFilters = applyTableWidgetFilters;
