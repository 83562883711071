import { checkArguments, isNullOrUndefined } from "@rivial-security/func-utils";

import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Changes value of a single customFieldData property to full JSON of all current custom fields
 * @param {string|boolean|object|number} value - the new value of the custom field
 * @param {object} item - the control's item id in the db
 * @param {string} fieldName - the customField name that is modified
 * @param {string} typename - the typename of the item being edited, could be Control or CustomResourceEntry
 * @return {Promise<string>} JSON string version of the full custom fields property with the value change applied
 */
export const updateCustomFieldInput = async ({ value, itemId, fieldName, typename = "" }) => {
  checkArguments(
    { value, itemId, fieldName },
    {
      value: {}, //only check for null on this one
      itemId: { type: "string" },
      fieldName: { type: "string" },
    },
  );

  const { getQuery } = generateGraphql(typename, ["customFieldData"]);

  try {
    //Get the latest db state of custom fields
    const currentResourceState = await GetQuery({
      query: getQuery,
      variables: {
        id: itemId,
      },
    });

    //Parse the received JSON
    const currentCustomFields = JSON.parse(currentResourceState.customFieldData);

    //Check for correct input value
    if (isNullOrUndefined(value)) {
      throw Error("No custom field data for mutation");
    }

    //TODO: check type of value to match config type parameter

    //Modify the final value based on the config and input value
    currentCustomFields[fieldName] = value;

    //Update the linked grid if it doesn't already update
    return JSON.stringify(currentCustomFields);
  } catch (e) {
    EventLogger("Unable to update a dynamic custom field!", e);
  }
};
