import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { UpsertMutation } from "../../../../utils/Functions/Graphql/UpsertMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Adds a list of user activities to the database (handles item creation/update choice automatically)
 * @param {string} userId - the db user entry identifier
 * @param {string} organizationID - the db organization identifier
 * @param {object[]} activityItemsToAdd - the title of the activity event event
 * @return
 */
export const addUserActivities = async ({ userID, organizationID, activityItemsToAdd }) => {
  //Check arguments
  if (!userID || !organizationID || !activityItemsToAdd || !Array.isArray(activityItemsToAdd)) {
    EventLogger(
      "Invalid input into the the addUserActivities function",
      JSON.stringify({ userID, organizationID, activityItemsToAdd }),
    );
  }

  const { createMutation, updateMutation } = generateGraphql("Event", ["header"]);

  //Start updating the activity in the database
  const activityRequests = [];
  const dbToToastId = {};
  for (const activity of activityItemsToAdd) {
    if (activity?.dbID && activity?.header && activity?.data) {
      if (activity?.id) {
        dbToToastId[activity?.dbID] = activity?.id;
      }
      activityRequests.push(
        await UpsertMutation({
          createMutation,
          updateMutation,
          input: {
            id: activity.dbID,
            header: activity.header,
            type: "activity",
            ownerGroup: organizationID,
            //TODO: figure out which session info to keep
            // data: JSON.stringify(await Auth.currentSession()),
            data: JSON.stringify(activity.data),
            hasBeenSeen: true,
            userID,
          },
        }),
      );
    }
  }

  //Check which items were actually updated
  const results = await Promise.allSettled(activityRequests);
  const updatedItems = [];
  for (const result of results) {
    if (result.status === "fulfilled" && result?.value?.id && dbToToastId.hasOwnProperty(result.value.id)) {
      if (dbToToastId[result.value.id]) {
        updatedItems.push(dbToToastId[result.value.id]);
      }
    }
  }

  return updatedItems;
};
