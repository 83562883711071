import React from "react";
import { Redirect } from "react-router-dom";

const SystemTemplateDashboard = React.lazy(
  () => import("../../views/Templates/SystemTemplates/components/SystemTemplateDashboard"),
);
const SystemTemplateDetails = React.lazy(
  () => import("../../views/Templates/SystemTemplates/components/SystemTemplateDetails"),
);
const OrganizationList = React.lazy(() => import("../../views/AdminPanel/Organizations/components/OrganizationList"));
const OrganizationDetails = React.lazy(
  () => import("../../views/AdminPanel/Organizations/components/OrganizationDetails"),
);
const ControlTemplateGrid = React.lazy(() => import("../../views/Templates/ControlTemplates/ControlTemplateGrid"));
const ControlDetails = React.lazy(() => import("../../views/Compliance/Controls/Controls/components/ControlDetails"));
const ControlSetDetails = React.lazy(
  () => import("../../views/Compliance/Controls/ControlSets/components/ControlSetDetails"),
);
const EvidenceTemplateGrid = React.lazy(() => import("../../views/Templates/EvidenceTemplates/EvidenceTemplateGrid"));
const EvidenceDetails = React.lazy(() => import("../../views/Compliance/Evidence/components/EvidenceDetails"));
const InformationAssetTemplateGrid = React.lazy(
  () => import("../../views/Templates/InformationAssetTemplates/InformationAssetTemplateGrid"),
);
const BlueprintTemplateGrid = React.lazy(
  () => import("../../views/Templates/BlueprintTemplates/BlueprintTemplateGrid"),
);
const RoleTemplateGrid = React.lazy(() => import("../../views/Templates/RoleTemplates/components/RoleTemplateGrid"));
const RoleDetailsCard = React.lazy(() => import("../../views/OrganizationManager/Roles/components/RoleDetailsCard"));
const UserDetailsCard = React.lazy(() => import("../../views/OrganizationManager/Users/components/UserDetails"));
const CisspReviewVendors = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/Vendor/components/CisspVendorDataGrid"),
);
const CisspReviewVendorDetails = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/Vendor/components/CisspVendorDetails"),
);
const CisspReviewVendorSolutions = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/VendorSolution/components/CisspVendorSolutionDataGrid"),
);
const CisspReviewVendorSolutionDetails = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/VendorSolution/components/CisspVendorSolutionDetails"),
);
const CisspReviewVendorReviews = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/VendorReview/components/CisspVendorReviewDataGrid"),
);
const CisspReviewVendorReviewDetails = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/VendorReview/components/CisspVendorReview"),
);
const CisspReviewVendorControls = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/VendorControl/components/CisspVendorControlCategoryDataGrid"),
);
const CisspReviewDashboard = React.lazy(
  () => import("../../views/AdminPanel/CisspReviews/Dashboard/CisspReviewDashboard"),
);

// Operation Teams
const OperationTeamDetails = React.lazy(
  () => import("../../views/AdminPanel/OperationTeams/components/OperationTeamDetails"),
);
const OperationTeamGrid = React.lazy(() => import("../../views/AdminPanel/OperationTeams/OperationTeams"));

const TemplateManager = React.lazy(() => import("../../views/AdminPanel/AdminTemplateManager/AdminTemplateManager"));

// Administrator Panel Routes
export const administratorPanelRoutes = [
  /**
   * Templates
   */
  {
    path: "/admin_panel/template_manager",
    exact: true,
    name: "Global Template Manager",
    component: TemplateManager,
  },
  {
    path: "/admin_panel/organizations",
    exact: true,
    name: "Organizations",
    component: OrganizationList,
  },
  {
    path: "/admin_panel/organizations/:id",
    exact: true,
    name: "Organization Details",
    component: OrganizationDetails,
  },
  {
    path: "/admin_panel/controls",
    exact: true,
    name: "Global Control Templates",
    component: ControlTemplateGrid,
  },
  {
    path: "/admin_panel/controls/:id",
    exact: true,
    name: "Global Control Templates Details",
    component: ControlDetails,
  },
  // { path: '/admin_panel/control_frameworks', exact: true, name: 'Control Framework Templates', component: FrameworkTemplateGrid},
  {
    path: "/admin_panel/control_frameworks/:id",
    exact: true,
    name: "Global Control Framework Template Details",
    component: ControlSetDetails,
  },
  {
    path: "/admin_panel/evidence",
    exact: true,
    name: "Global Evidence Templates",
    component: EvidenceTemplateGrid,
  },
  {
    path: "/admin_panel/evidence/:id",
    exact: true,
    name: "Global Evidence Template Details",
    component: EvidenceDetails,
  },
  {
    path: "/admin_panel/system_templates",
    exact: true,
    name: "Global System Templates",
    component: SystemTemplateDashboard,
  },
  {
    path: "/admin_panel/system_templates/:id",
    exact: true,
    name: "Global System Template Details",
    component: SystemTemplateDetails,
  },
  {
    path: "/admin_panel/global_information_assets",
    exact: true,
    name: "Global Information Asset Templates",
    component: InformationAssetTemplateGrid,
  },
  {
    path: "/admin_panel/setup_matrix",
    exact: true,
    name: "Global Blueprint Templates",
    component: BlueprintTemplateGrid,
  },
  {
    path: "/admin_panel/role_templates",
    exact: true,
    name: "Global Role Templates",
    component: RoleTemplateGrid,
  },
  {
    path: "/admin_panel/role_templates/:id",
    exact: true,
    name: "Global Role Template Details",
    component: RoleDetailsCard,
  },
  {
    path: "/admin_panel/users/:id",
    exact: true,
    name: "User Details",
    component: UserDetailsCard,
  },

  /**
   * CISSP Vendor Reviews
   */
  {
    path: "/admin_panel/cissp_reviews/vendors",
    exact: true,
    name: "CISSP Reviews - Vendors",
    component: CisspReviewVendors,
  },
  {
    path: "/admin_panel/cissp_reviews/vendors/:id",
    exact: true,
    name: "CISSP Reviews - Vendor Details",
    component: CisspReviewVendorDetails,
  },
  {
    path: "/admin_panel/cissp_reviews/solutions",
    exact: true,
    name: "CISSP Reviews - Vendor Solutions",
    component: CisspReviewVendorSolutions,
  },
  {
    path: "/admin_panel/cissp_reviews/solutions/:id",
    exact: true,
    name: "CISSP Reviews - Vendor Solution Details",
    component: CisspReviewVendorSolutionDetails,
  },
  {
    path: "/admin_panel/cissp_reviews/reviews",
    exact: true,
    name: "CISSP Reviews - Reviews",
    component: CisspReviewVendorReviews,
  },
  {
    path: "/admin_panel/cissp_reviews/reviews/:id",
    exact: true,
    name: "CISSP Reviews - Review Details",
    component: CisspReviewVendorReviewDetails,
  },
  {
    path: "/admin_panel/cissp_reviews/controls",
    exact: true,
    name: "CISSP Reviews - Controls",
    component: CisspReviewVendorControls,
  },
  {
    path: "/admin_panel/cissp_reviews/controls/:id",
    exact: true,
    name: "CISSP Reviews - Control Details",
    component: CisspReviewVendorControls,
  },
  {
    path: "/admin_panel/cissp_reviews",
    exact: true,
    name: "CISSP Reviews - Dashboard",
    component: CisspReviewDashboard,
  },

  /**
   * Operation Teams
   */
  {
    path: "/admin_panel/operation_teams",
    exact: true,
    name: "Operation Teams",
    component: OperationTeamGrid,
  },
  {
    path: "/admin_panel/operation_teams/:id",
    exact: true,
    name: "Operation Team Details",
    component: OperationTeamDetails,
  },

  /**
   * Default Route
   */
  {
    path: "/admin_panel",
    component: () => <Redirect to="/admin_panel/organizations" />,
  },
];
