import { modules, resources } from "@rivial-security/role-utils";

import ControlCategoryDetails from "../components/ControlCategoryDetails";
import React from "react";
import { deleteRiskControlCategory } from "../functions/deleteRiskControlCategory";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * Displays a list of Control Categories for an Organization's risk config
 * @param organizationID
 * @param queryConfig
 * @param cardConfig
 * @param gridConfig
 */
export const useControlCategoriesDataGrid = ({
  organizationID,
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
  ...props
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const route = "#/risk/risk_config/control_categories/";

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: realTimeRiskGQL.listRiskControlCategorys_systemAccordion,
    organizationID: organizationID,
    ...queryConfig,
  };

  const { updateMutation } = generateGraphql("RiskControlCategory", ["name"]);

  cardConfig = {
    title: "Risk Control Categories",
    headerIcon: getIcon("clarity:shield-check-line"),
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
      description: "Name of this Risk Control Category",
      flex: 1,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
  ];

  gridConfig = {
    route,
    fields,
    options: ["details", "delete"],
    enableContextMenu: true,
    persistenceUUID: "risk-control-categories-grid",
    deleteFunction: deleteRiskControlCategory,
    deleteFunctionNote:
      "⚠ Warning! This will delete all Risk Controls associated with the Control Category. This is a destructive action and could cause data loss.",
    typename: "RiskControlCategory",
    updateMutation: updateMutation,
    detailsComponent: <ControlCategoryDetails />,
    ...gridConfig,
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
    ...cardConfig,
    ...props,
  });

  return { ...grid };
};
