import React from "react";
import { Redirect } from "react-router-dom";
import { getResourceRoute } from "../../definitions/functions/getResourceRoute";
import { resources } from "@rivial-security/role-utils";
import { routeType } from "../../definitions/constants/routeTypes";

const CustomQueryDashboard = React.lazy(() => import("../../views/CustomQueries/components/CustomQueryDashboard"));
const CustomWidgetDashboard = React.lazy(
  () => import("../../views/CustomWidgets/WidgetDashboard/CustomWidgetDashboard"),
);

const CustomQueryDetails = React.lazy(() => import("../../views/CustomQueries/components/CustomQueryDetails"));
const CustomWidgetDetails = React.lazy(
  () => import("../../views/CustomWidgets/WidgetDetails/components/CustomWidgetDetails"),
);

const ReportDashboard = React.lazy(() => import("../../views/Reports/ReportsDashboard"));
const ReportDetails = React.lazy(() => import("../../views/Reports/ReportDetails"));
const CreateReport = React.lazy(() => import("../../views/Reports/CreateReport"));

const reportDetailsRoute = getResourceRoute({
  routeType: routeType.DETAILS,
  typename: resources.CUSTOM_QUERY,
  prefix: "/",
  suffix: "/:id",
});

// Reports Routes
export const reportsRoutes = [
  //Custom Queries and Widgets
  {
    path: getResourceRoute({
      routeType: routeType.GRID,
      typename: resources.CUSTOM_QUERY,
      prefix: "/",
    }),
    exact: true,
    name: `Custom Queries`,
    component: CustomQueryDashboard,
  },
  {
    path: getResourceRoute({
      routeType: routeType.DETAILS,
      typename: resources.CUSTOM_QUERY,
      prefix: "/",
      suffix: "/:id",
    }),
    exact: true,
    name: "Custom Query Details",
    component: CustomQueryDetails,
  },
  {
    path: getResourceRoute({
      routeType: routeType.GRID,
      typename: resources.CUSTOM_WIDGET,
      prefix: "/",
    }),
    exact: true,
    name: `Custom Widgets`,
    component: CustomWidgetDashboard,
  },
  {
    path: getResourceRoute({
      routeType: routeType.DETAILS,
      typename: resources.CUSTOM_WIDGET,
      prefix: "/",
      suffix: "/:id",
    }),
    exact: true,
    name: "Custom Widget Details",
    component: CustomWidgetDetails,
  },

  // Reports
  {
    path: "/reports/list",
    exact: true,
    name: "Reports",
    component: ReportDashboard,
  },
  {
    path: "/reports/list/:id",
    noScrolling: true,
    exact: true,
    name: "Report Details",
    component: ReportDetails,
  },
  {
    path: "/reports/create_report",
    noScrolling: true,
    exact: true,
    name: "Create a Report",
    component: CreateReport,
  },

  { path: "/reports", component: () => <Redirect to="/reports/list" /> },
];
