import { Button, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { controlCustomFields, controlFieldNameDictionary, controlFields, controlNestedFields } from "./constants";
import { modules, resources } from "@rivial-security/role-utils";

import AddOrRemoveIcon from "../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import AssociatedEvidence from "../../customFields/AssociatedEvidence/AssociatedEvidence";
import ControlAudits from "../../customFields/ControlAudits/ControlAudits";
import ControlEvidenceLinkImporterButton from "../../../../ControlEvidenceLinks/components/ControlEvidenceLinkImporterButton";
import ControlEvidenceLinking from "../../../../ControlEvidenceLinks/components/ControlEvidenceLinking";
import ControlObservations from "../../customFields/ControlObservations";
import CustomFieldEditor from "../../customFields/CustomFieldEditor/CustomFieldEditor";
import Dashboard from "../../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import KeyPerformanceIndicators from "../../../../../Risk/RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";
import { Button as MuiButton } from "@mui/material";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useOrganizationContext } from "../../../../../AdminPanel/Organizations/hooks/useOrganizationContext";

/**
 * Custom hook for displaying the details of a Control
 * @param {string} organizationID - the organization/operation team that the control is a part of
 * @param {string} module=modules.ADMINISTRATOR  - platform module for role checking
 * @param {string} resource=resources.CONTROL_TEMPLATE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {boolean} isTemplate=false - if TRUE will hide non template parts of the resource
 * @param {string} itemId - the id of the control for which to get data and display
 * @param {boolean} tableDisplay - if TRUE will display a table instead of a card
 * @param {string[]} hiddenFields - fields to hide from the details table
 * @returns {{isLoading, setInputId, inputId, item, display: *, reset, setItem}}
 */
export const useControlDetails = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,
  isTemplate = false,

  itemId,
  tableDisplay,
  hiddenFields = [],
  ...props
}) => {
  ///[GENERAL SETUP]
  const context = useOrganizationContext();
  // - Constants
  const originOrganizationID = context?.role?.ownerGroup;
  const typename = resources.CONTROL;
  // - State
  const [showKPIs, setShowKPIs] = useState(false);
  const [addonFields, setAddonFields] = useState([]);
  const [addonCustomFields, setAddonCustomFields] = useState([]);
  const [addonFieldNameDictionary, setAddonFieldNameDictionary] = useState({});
  // - Permissions
  const controlPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });
  // - Queries
  const { getQuery: controlGetQuery, updateMutation: controlUpdateMutation } = generateGraphql(
    typename,
    controlFields,
    controlNestedFields,
  );

  ///[DETAILS SETUP]
  const queryConfig = {
    query: controlGetQuery,
    itemId,
  };

  const getFields = () => {
    const fields = [];

    const defaultFields = [
      "statementNumber",
      "name",
      "controlSet",
      "inPlace",
      "isDisabled",
      "evidences",
      "customFieldData",
      "changes",
      "tags",
      "notes",
    ].concat(addonFields);

    defaultFields.forEach((field) => {
      if (Array.isArray(hiddenFields)) {
        if (!hiddenFields.includes(field)) {
          fields.push(field);
        }
      } else {
        fields.push(field);
      }
    });
    return fields;
  };

  const detailsFields = getFields();

  const detailsConfig = {
    fields: detailsFields,
    customFields: controlCustomFields({
      module,
      resource,
      disableRoleChecking,
      isTemplate,
      originOrganizationID,
      organizationID,
    }).concat(addonCustomFields),
    fieldNameDictionary: {
      ...controlFieldNameDictionary,
      ...addonFieldNameDictionary,
    },
    updateMutation: controlUpdateMutation,
  };

  const detailsHook = useDetailsCard({
    module,
    resource,
    disableRoleChecking,
    tableDisplay,
    queryConfig,
    detailsConfig,
    config: {
      enableNotes: true,
      headerButtons: [
        <Button
          color="ghost-primary"
          size="sm"
          onClick={() => setShowKPIs(!showKPIs)}
          disabled={!controlPermissions.resource.read}
          title={
            controlPermissions.resource.read
              ? "Show Key Performance Indicators"
              : "You don't have access to type 'Control'"
          }
        >
          {showKPIs ? "Hide" : "Show"} KPIs
          <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
        </Button>,
      ],
      observationConnectionField: "complianceControlID",
    },
    ...props,
  });

  ///[DETAILS CUSTOM FIELDS]
  // TODO: when loading display a component attachment saying that custom fields are still loading use the null value of state
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["name", "customFields"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format } } `,
  });
  // Handles retrieval of shown custom fields by looking at control framework fields
  const updateShownAddonFields = async (controlItem) => {
    //Show no fields if no control framework id is present
    let selectedControlFrameworkId = "";
    if (!controlItem?.controlSet?.id) {
      EventLogger("No control set found in control item to display custom fields");
      return;
    } else {
      selectedControlFrameworkId = controlItem.controlSet.id;
    }

    //Check to see if control framework of current control exist (retrieve all custom field info)
    let customFrameworkFields = [];
    try {
      const controlSet = await ItemQuery(getControlFrameworkQuery, selectedControlFrameworkId);
      if (controlSet?.customFields && Array.isArray(controlSet.customFields)) {
        customFrameworkFields = controlSet.customFields;
      }
    } catch (e) {
      EventLogger("Could not retrieve control set data for control details!", e);
      return;
    }

    //Check to see if custom fields property exists on this control
    if (!controlItem?.customFieldData) {
      return;
    }

    //Parse the custom field json from the control
    let controlCustomFields = {};
    try {
      controlCustomFields = JSON.parse(controlItem.customFieldData);
    } catch (e) {
      EventLogger("Could not retrieve control set data for control details!", e);
      return;
    }

    //Show all of control frameworks fields as additional fields for the control (update customFields state)
    const newAddonFields = [];
    const newAddonCustomFields = [];
    const newAddonFieldNameDictionary = {};
    for (const field of customFrameworkFields) {
      if (field?.name) {
        newAddonFields.push(field.name);
        newAddonCustomFields.push({
          field: field.name,
          isDynamicCustomField: true, //roles not yet implemented (field will be added even if not selected)
          component: (
            <CustomFieldEditor
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
              config={field}
              data={controlCustomFields.hasOwnProperty(field.name) ? controlCustomFields[field.name] : undefined}
            />
          ),
        });
        // Want to preserve the format of the field that the user typed in
        newAddonFieldNameDictionary[field.name] = field.name;
      }
    }

    setAddonFields(newAddonFields);
    setAddonCustomFields(newAddonCustomFields);
    setAddonFieldNameDictionary(newAddonFieldNameDictionary);
  };

  // Handles the changes in displayed control item by readjusting the displayed custom fields
  useEffect(() => {
    if (detailsHook?.item) {
      setAddonFields([]);
      setAddonCustomFields([]);
      setAddonFieldNameDictionary({});
      const item = detailsHook.item;
      updateShownAddonFields(item);
    }
  }, [detailsHook.item]);

  const rowStyle = {
    marginBottom: "1.0em",
  };

  const linkingModal = useModal(
    <>
      Link / Unlink Evidences for this Control
      <ControlEvidenceLinkImporterButton
        organizationID={organizationID}
        controlOrganizationID={organizationID}
        evidenceOrganizationID={organizationID}
      />
    </>,
    <ControlEvidenceLinking
      type={"control"}
      organizationID={organizationID}
      control={detailsHook.item}
      resetFunction={detailsHook.reset}
      isTemplate={isTemplate}
    />,
    <MuiButton className="float-right" title="Link / Unlink Evidence for this Control">
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "80vw",
    },
  );

  ///[RENDERING]
  const display = (
    <Dashboard resetFunction={detailsHook.reset}>
      <Row style={rowStyle}>
        <Col lg={6}>
          <DashboardCard title="General Information" wrapperStyle={{ marginBottom: "1em" }} style={{ height: "100%" }}>
            {detailsHook.tableDisplay}
          </DashboardCard>
          {!isTemplate && (
            <DashboardCard title="Observations and Recommendations" style={{ height: "100%" }}>
              <ControlObservations item={detailsHook.item} gridDisplay={true} resetFunction={detailsHook.reset} />
            </DashboardCard>
          )}
        </Col>
        <Col lg={6}>
          <DashboardCard
            title={`Associated Evidence${isTemplate ? " Templates" : ""}`}
            wrapperStyle={{ marginBottom: "1em" }}
            style={{ height: "100%" }}
            headerButtons={controlPermissions.resource.update && [linkingModal.modalButton]}
          >
            <AssociatedEvidence
              item={detailsHook.item}
              organizationID={organizationID}
              disableRoleChecking={disableRoleChecking}
              isTemplate={isTemplate}
            />
          </DashboardCard>
          {!isTemplate && (
            <DashboardCard
              title="Key Performance Indicators"
              wrapperStyle={{ marginBottom: "1em" }}
              style={{ height: "100%" }}
            >
              <KeyPerformanceIndicators control={detailsHook?.item} />
            </DashboardCard>
          )}
          {!isTemplate && (
            <DashboardCard title="Audits" wrapperStyle={{ marginBottom: "1em" }} style={{ height: "100%" }}>
              <ControlAudits item={detailsHook.item} />
            </DashboardCard>
          )}
        </Col>
      </Row>
    </Dashboard>
  );

  return {
    ...detailsHook,
    display,
  };
};
