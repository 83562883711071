import React, { useContext, useEffect } from "react";
import { useDocumentEditor } from "../../DocumentEditor/hooks/useDocumentEditor";
import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { modules, resources } from "@rivial-security/role-utils";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { generateVendorReviewReport } from "../functions/generateVendorReviewReport";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { getVendorReviewReportData } from "../functions/getVendorReviewReportData";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Automatically generates a Vendor Review report in the Document Editor
 *
 * Note: due to CISSP Reviews - this report generates Locally and not on the server
 *
 * @param organizationID
 * @param overrideOrganizationID
 * @returns {JSX.Element}
 * @constructor
 */
const VendorReviewReport = ({ organizationID, overrideOrganizationID }) => {
  const context = useContext(WorkflowContext);
  const { ref, display, generateFromTemplate } = useDocumentEditor({
    organizationID: overrideOrganizationID ? overrideOrganizationID : organizationID,
    defaultTabIndex: 2,
  });

  const pleaseWait = usePleaseWaitModal();

  const initiateReportGeneration = async () => {
    pleaseWait.setModalIsOpen(true);

    try {
      //Get all vendor review id information
      const vendorReview = context?.vendorReview;
      const itemId = vendorReview?.id;

      const vendorReviewData = await getVendorReviewReportData({
        input: { config: { itemId } },
      });

      //Generate the report locally
      await generateFromTemplate(generateVendorReviewReport, {
        ...vendorReviewData,
      });
    } catch (e) {
      EventLogger("Error: could not generate Vendor Review report! ", e);
    } finally {
      pleaseWait.setModalIsOpen(false);
    }
  };

  useEffect(() => {
    if (ref && ref.documentEditor) {
      initiateReportGeneration();
    }
  }, [ref.documentEditor]);

  return (
    <PermissionsOverlay module={modules.REPORTS} resource={resources.REPORT} operationType={"create"}>
      <div>
        {pleaseWait.modal}
        {display}
      </div>
    </PermissionsOverlay>
  );
};

export default withOrganizationCheck(VendorReviewReport);
