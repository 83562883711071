import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

/**
 * @description Lambda to handle the onArtifactUpload event and invoke the process artifact lambda
 * @param {string} organizationID - the organization ID
 * @param {string} artifactID - the artifact ID
 * @param {string} sentryTrace - the sentry trace
 * @returns {Promise<Array>}
 */
export const onArtifactUpload = async ({ organizationID, artifactID, sentryTrace }) => {
  /**
   * Validate input
   */
  if (!artifactID) {
    EventLogger("[onArtifactUpload.js] Missing artifactID");
    return;
  }

  /**
   * Query to invoke the onArtifactUpload lambda
   */
  const getOrganization = /* GraphQL */ `
    query GetOrganization($id: ID!, $input: AWSJSON) {
      getOrganization(id: $id) {
        id
        onArtifactUpload(input: $input)
      }
    }
  `;

  const jobID = "123456";

  /**
   * Input to send to the onArtifactUpload lambda
   */
  const input = {
    artifactID,
    sentryTrace,
    jobID,
  };

  EventLogger(`Sending request to onArtifactUpload lambda. Input: ${JSON.stringify(input)}`);

  /**
   * Send the request to the onArtifactUpload lambda
   */
  return await GetQuery({
    query: getOrganization,
    variables: {
      id: organizationID,
      input: JSON.stringify(input),
    },
  });
};
