export const ComplianceModuleLinks = {
  name: "Compliance",
  url: "/continuous_compliance",
  icon: "simple-line-icons:shield",
  children: [
    {
      dashboardPage: {
        name: "Dashboard",
        url: "/continuous_compliance/dashboard",
        icon: "simple-line-icons:grid",
      },
    },
    {
      evidencePage: {
        name: "Evidence",
        url: "/continuous_compliance/evidence",
        icon: "simple-line-icons:badge",
      },
    },
    {
      controlsPage: {
        name: "Controls",
        url: "/continuous_compliance/control_dashboard",
        icon: "simple-line-icons:list",
      },
    },
    {
      controlFrameworksPage: {
        name: "Frameworks",
        url: "/continuous_compliance/control_frameworks",
        icon: "simple-line-icons:grid",
      },
    },
    {
      auditsPage: {
        name: "Audits",
        url: "/continuous_compliance/audits",
        icon: "simple-line-icons:film",
      },
    },
    {
      complianceReportPage: {
        name: "Reports",
        url: "/continuous_compliance/reports/compliance",
        icon: "simple-line-icons:chart",
      },
    },
    {
      complianceArtifactsPage: {
        name: "Artifacts",
        url: "/continuous_compliance/artifacts",
        icon: "simple-line-icons:doc",
      },
    },
    {
      complianceConfigPage: {
        name: "Compliance Config",
        url: "/continuous_compliance/compliance_config",
        icon: "simple-line-icons:settings",
      },
    },
  ],
};
