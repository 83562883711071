import { TaskStatus } from "../constants/taskStatus";
import { checkArguments } from "@rivial-security/func-utils";
import { isSpecialRole } from "./isSpecialRole";

const isTaskCompleted = (task) => task?.status === TaskStatus.DONE;
const isTaskNotCompleted = (task) => task?.status !== TaskStatus.DONE;

/**
 * filters tasks based on the filterCondition
 * @param {object[]} tasks - the tasks to filter
 * @param {function} filterCondition - the condition to filter the tasks by
 * @returns {{completedTasks: *, filteredTasks: *}}
 */
const filterTasks = ({ tasks, filterCondition }) => {
  checkArguments(
    {
      tasks,
      filterCondition,
    },
    {
      tasks: { type: "object", isArray: true },
      filterCondition: { type: "function" },
    },
  );
  return {
    filteredTasks: tasks.filter((task) => filterCondition(task) && isTaskNotCompleted(task)),
    completedTasks: tasks.filter((task) => filterCondition(task) && isTaskCompleted(task)),
  };
};

/**
 * filters user assigned tasks
 * @param {object[]} tasks - the tasks to filter
 * @returns {{completedTasks: *, filteredTasks: *}}
 */
export const filterMyTasks = (tasks) => {
  checkArguments(
    {
      tasks,
    },
    {
      tasks: { type: "object", isArray: true },
    },
  );
  return filterTasks({
    tasks,
    filterCondition: () => true,
  });
};

/**
 * filters department tasks
 * @param {object[]} tasks - the tasks to filter
 * @param {string} loggedInPointOfContactId - the id of the logged in point of contact
 * @returns {{completedTasks: *, filteredTasks: *}}
 */
export const filterDepartmentTasks = ({ tasks, loggedInPointOfContactId }) => {
  checkArguments(
    {
      tasks,
      loggedInPointOfContactId,
    },
    {
      tasks: { type: "object", isArray: true },
      loggedInPointOfContactId: { type: "string" },
    },
  );
  return filterTasks({
    tasks,
    filterCondition: (task) => {
      return (task?.department && task?.department) || task?.pointOfContact?.id === loggedInPointOfContactId;
    },
  });
};

/**
 *
 * @param {object[]} tasks - the tasks to filter
 * @param {function} isTaskPastDue - determines if the task is past due (based on the due date or status)
 * @param {boolean} isSidebarView - determines if the task is being viewed in the sidebar
 * @param {string} loggedInPointOfContactId - the id of the logged in point of contact
 * @returns {{completedTasks: *, filteredTasks: *}}
 */
export const filterPastDueTasks = ({ tasks, isTaskPastDue, isSidebarView, loggedInPointOfContactId }) => {
  checkArguments(
    {
      tasks,
      isTaskPastDue,
      isSidebarView,
      loggedInPointOfContactId,
    },
    {
      tasks: { type: "object", isArray: true },
      isTaskPastDue: { type: "function" },
      isSidebarView: { type: "boolean" },
      loggedInPointOfContactId: { type: "string" },
    },
  );
  return filterTasks({
    tasks,
    filterCondition: (task) => {
      if (isTaskPastDue(task) && isTaskNotCompleted(task)) {
        return (
          task?.department?.departmentHeadID === loggedInPointOfContactId ||
          task?.pointOfContact?.id === loggedInPointOfContactId
        );
      }
      return false;
    },
  });
};

/**
 * filters tasks based on the current list (custom list)
 * @param {object[]} tasks - the tasks to filter
 * @param {string} loggedInPointOfContactId - the id of the logged in point of contact
 * @param {boolean} isListCreator - determines if the logged in point of contact is the creator of the list
 * @param {object} currentList - the current list (custom list)
 * @param {object} context - the current organization context
 * @returns {{completedTasks: *, filteredTasks: *}}
 */
export const filterListTasks = ({ tasks, loggedInPointOfContactId, isListCreator, currentList, context }) => {
  checkArguments(
    {
      tasks,
      loggedInPointOfContactId,
      isListCreator,
      currentList,
      context,
    },
    {
      tasks: { type: "object", isArray: true },
      loggedInPointOfContactId: { type: "string" },
      isListCreator: { type: "boolean" },
      currentList: { type: "object" },
      context: { type: "object" },
    },
  );
  return filterTasks({
    tasks,
    filterCondition: (task) =>
      (isListCreator || task?.pointOfContact?.id === loggedInPointOfContactId || isSpecialRole({ context })) &&
      task?.taskList?.id === currentList?.id,
  });
};
