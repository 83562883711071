import { usePreferences } from "../usePreferences/usePreferences";
import { useTour } from "@reactour/tour";
import React, { useEffect } from "react";
import Fade from "@mui/material/Fade";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * Hook for using a Welcome Tour for a specific component.
 * Utilizes User Preferences to only allow the tour to happen the first time a component loads
 * @param {StepType[]} steps - steps from @reactour library
 * @param {string} steps[].selector - css selector
 * @param {string} steps[].highlightedSelectors - acts like selector but highlights multiple elements
 * @param {string} steps[].title - title for the specific tour card
 * @param {string} steps[].content - content for the specific tour card
 * @param {string} optionKey - unique preference key for this component
 * @param {boolean} disable - disable a tour
 */
export const useWelcomeTour = ({ steps, optionKey, disable = false }) => {
  const component = "welcomeTours";

  const { getPreference, setPreference } = usePreferences();

  const tour = useTour();

  const { setCurrentTour } = useUIContext();

  useEffect(() => {
    if (steps && optionKey && !disable) {
      const pref = getPreference(component, optionKey);
      if (!pref) {
        tour.setSteps(
          steps.map((step) => {
            return {
              selector: step?.selector,
              highlightedSelectors: step?.highlightedSelectors,
              content: <TourCard title={step.title}>{step.content}</TourCard>,
            };
          }),
        );

        setCurrentTour(optionKey);
        tour.setCurrentStep(0);
        setTimeout(() => {
          tour.setIsOpen(true);
        }, 1000);
      }

      return () => {
        setPreference(component, { [optionKey]: true });
        tour.setIsOpen(false);
        setCurrentTour(null);
      };
    }
  }, []);
};

/**
 * Custom component to render a specific step for the welcome tour
 * @param title
 * @param content
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TourCard = ({ title, content, children, ...props }) => {
  return (
    <Fade in={true}>
      <span>
        {title && <h5>{title}</h5>}
        {content || children}
      </span>
    </Fade>
  );
};
