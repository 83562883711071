import React from "react";
import { Redirect } from "react-router-dom";

const MetricDashboard = React.lazy(() => import("../../views/Metrics/MetricDashboard/components/MetricDashboard"));
const MetricTypeDetails = React.lazy(() => import("../../views/Metrics/MetricTypes/components/MetricTypeDetails"));
const KeyPerformanceIndicatorDetails = React.lazy(
  () => import("../../views/Metrics/KeyPerformanceIndicators/components/KeyPerformanceIndicatorDetails"),
);

// Main Routes
export const metricsRoutes = [
  {
    path: "/metrics/metrics",
    exact: true,
    name: "Metric Dashboard",
    component: MetricDashboard,
  },
  {
    path: "/metrics/metrics/:id",
    exact: true,
    name: "Metric Details",
    component: MetricTypeDetails,
  },
  {
    path: "/metrics/keyPerformanceIndicators/:id",
    exact: true,
    name: "Key Performance Indicator Details",
    component: KeyPerformanceIndicatorDetails,
  },

  { path: "/metrics", component: () => <Redirect to="/metrics/metrics" /> },
];
