import "../styles/UploadDocument.css";

import * as Sentry from "@sentry/react";

import React, { useContext, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import PropTypes from "prop-types";
import { S3_BUCKET_REGION } from "../../../../env-config";
import SelectDocuments from "./SelectDocuments";
import { UIContext } from "../../../../utils/Context/UIContext";
import { createDocument_for_upload_document } from "../../../Program/Actions/graphql/__actionGQL";
import { generateGraphql } from "@rivial-security/generategraphql";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { getOrganization_minimal } from "../../../AdminPanel/Organizations/graphql/__organizationGQL";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { onS3UserErrorMessage } from "../../../../utils/Functions/S3Storage/onS3UserErrorMessage";
import { s3MultiPartUpload } from "../../../../utils/Functions/S3Storage/s3MultiPartUpload";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";

/**
 * Author: Anatoli Railean
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature
 *  - 7/26/19 JB: Checks for Action prop, CreateDocument will attach to Action and only Action Documents will be listed.
 *
 * Description: A Card Component.
 *              Allows user to upload a Document corresponding to an Organization
 *              and/or Particular Evidence
 *              and/or Particular Action.
 *
 */
const UploadDocument = (props) => {
  const [name, setName] = useState("");
  const [, setLastUpdate] = useState(new Date().toISOString());
  const [organizationID, setOrganizationID] = useState(props.organizationID);
  const [, setOwner] = useState("");

  const { addToast, updateToast } = useContext(UIContext);
  const pleaseWaitModal = usePleaseWaitModal({
    progressTotal: 100,
  });

  const createDocumentHook = useMutation({
    mutation: createDocument_for_upload_document,
    disableRoleChecking: true,
    typename: "Document",
    disableToast: true,
  });

  const getInitialState = () => {
    setLastUpdate(new Date().toISOString());
    setOrganizationID(props.organizationID);
    setOwner("");
  };

  const getOrganizationQueryHook = useQueryGetItem({
    query: getOrganization_minimal,
    itemId: organizationID,
    disableRoleChecking: true,
  });

  const handleSubmit = async ({ files } = {}) => {
    const toastId = addToast({
      header: `Uploading Document, please wait...`,
      icon: "spinner",
      color: "success",
    });

    pleaseWaitModal.setModalIsOpen(true);

    const onprogress = (loaded) => {
      if (loaded === 100) {
        updateToast({
          id: toastId,
          header: "Document was successfully uploaded",
          icon: "success",
        });
        pleaseWaitModal.setModalIsOpen(false);
      }
      pleaseWaitModal?.setProgress(loaded);
    };

    const onUploadError = (err, document) => {
      const errorMessage = onS3UserErrorMessage({ error: err });

      updateToast({
        id: toastId,
        header: errorMessage,
        icon: "danger",
      });

      ItemMutation(deleteDocument, { id: document?.id });
      pleaseWaitModal.setModalIsOpen(false);
    };

    const { deleteMutation: deleteDocument } = generateGraphql("Document");

    const region = S3_BUCKET_REGION;

    const organization = getOrganizationQueryHook?.item;

    const bucketName = organization?.s3BucketName;

    const { username: owner } = await Auth.currentUserInfo();

    const docs = [];

    const fileUploadThreads = files.map(async (f) => {
      const file = f?.rawFile;

      const fileName = file.name;
      const objectKey = generateS3ObjectKey(`evidence/${fileName}`);

      const fileInfo = {
        bucket: bucketName,
        region: region,
        key: objectKey,
      };

      const document = await createDocumentHook.createItem({
        name: fileName || name,
        owner: owner,
        documentOrganizationId: organizationID,
        file: fileInfo,
        ownerGroup: organizationID,
      });

      if (!isNullOrUndefined(document)) docs.push(document);

      await s3MultiPartUpload({
        file,
        bucket: bucketName,
        key: objectKey,
        organizationID,
        uploadProgress: onprogress,
      }).catch((err) => {
        onUploadError(err, document);
        Sentry.captureMessage(`Failed to upload document: ${document?.name}. Error: ${err?.message}`);
      });
    }); // End forEach loop.

    await Promise.all(fileUploadThreads);
    pleaseWaitModal.setModalIsOpen(false);

    props.onSubmit && props.onSubmit(docs);
    props.resetFunction && props.resetFunction();
    getInitialState();
  }; // End "handleSubmit" function.

  return (
    <div className="animated fadeIn">
      {pleaseWaitModal.modal}
      <SelectDocuments
        onSubmit={handleSubmit}
        onSelected={props.onSelected}
        buttons={{
          browse: "Choose Files",
          clear: "Clear All",
          upload: "Upload",
        }}
      />
    </div>
  );
};

UploadDocument.propTypes = {
  organizationID: PropTypes.string,
  evidence: PropTypes.object,
  action: PropTypes.object,
};

export default UploadDocument;
