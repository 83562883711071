import { Card, CardBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";

import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { connectionFilters } from "../../../CustomQueries/constants/connectionFilters";
import { getColumnDataFromTypes } from "../../../CustomQueries/functions/getColumnDataFromTypes";
import { getConditionBuilderResources } from "../functions/getConditionBuilderResources";
import { getCurrentTypeFields } from "../../../CustomQueries/functions/getCurrentTypeFields";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useLogicBuilderV2 } from "../../../../utils/LogicBuilder/hooks/useLogicBuilderV2";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";

/**
 * Logic Builder for setting up the Query and Conditions for an Automation on a single Resource Type
 * @param {function} onChangeCallback - callback function for the user form hook
 * @param {string} itemId - item ID for prefilling the 'id equals' condition
 * @param {string} typename - optional for pre-setting the typename dropdown
 * @param {Automation} item - Automation Item (if being used as an edit form)
 * @param {boolean} disableEdits - disables edits
 * @param {object[]} customResourceTypes - custom resource types for the org
 * @param {string} organizationID - currently selected organization ID
 */
export const useAutomationConditionBuilder = ({
  onChangeCallback,
  itemId,
  typename,
  item,
  disableEdits,
  customResourceTypes,
} = {}) => {
  /**
   * @typedef ParsedType
   * @property {string} typename
   * @property {object} fields
   */
  const [types, setTypes] = useState([]);

  /**
   * Dropdown input for selecting a type to use for this Query
   */
  const selectType = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    fieldConfig: {
      typename: {
        label: "Select a Resource Type",
        tooltip: "Select the Resource Type that will trigger this Automation",
        inputType: "dropdown",
        defaultValue: typename || item?.conditions?.[0]?.typename || typename,
        dropdownConfig: {
          data: types
            .sort((a, b) => a.typename.localeCompare(b.typename))
            .map((type) => {
              return {
                text: type?.name || convertCamelCaseToSentence(type.typename),
                value: type.typename,
              };
            }),
        },
        onChangeFunction: (item) => {
          if (typeof onChangeCallback === "function") {
            onChangeCallback(item);
          }
        },
        disabled: disableEdits,
      },
    },
  });

  /**
   * Reset form state when customResourceTypes are loaded
   */
  useEffect(() => {
    const types = getConditionBuilderResources({
      manyToMany: false,
      oneToMany: false,
      customResourceTypes,
    });
    setTypes(types);
  }, [JSON.stringify(customResourceTypes)]);

  /**
   * The Column Data for SyncFusion's QueryBuilder component
   */
  const [columnDataInit] = useStateEffect([], [selectType.input.typename], () => {
    return getColumnDataFromTypes(selectType.input.typename, types);
  });

  /**
   * The current state of the QueryBuilder rule
   */
  const [rule, setRule] = useState(null);

  // Converts the mui-querybuilder 'query' to the syncfusion 'rule'
  const handleRuleConversion = (rule) => {
    const res = { ...rule };
    res.condition = rule.combinator;
    return res;
  };

  /**
   * Initialize the Logic Builder for AND/OR Operations
   */
  const logicBuilder = useLogicBuilderV2({
    typename,
    columnDataInit,
    onChange: (rule) => {
      setRule(handleRuleConversion(rule));
    },
    itemId,
    initialRule: item?.conditions?.[0]?.rule ? JSON.parse(item?.conditions?.[0]?.rule) : undefined,
    readonly: disableEdits,
  });

  /**
   * If a CustomResourceType is selected, add a condition to the query to filter by the customResourceTypeID
   */
  useEffect(() => {
    try {
      // find the custom resource type
      const found = types.find((type) => type.typename === selectType?.input?.typename);

      if (found?.name?.includes("(Custom)") && found?.customResourceTypeID) {
        logicBuilder.setQuery({
          combinator: "and",
          rules: [
            {
              field: "customResourceTypeID",
              operator: "equal",
              value: found.customResourceTypeID,
            },
          ],
        });
      }
    } catch (e) {
      EventLogger("Could not set customResourceTypeID condition", e);
    }
  }, [selectType?.input?.typename]);

  /**
   * Update the QueryBuilder when the Column Data changes
   */
  useEffect(() => {
    logicBuilder.setColumnData(columnDataInit);
  }, [columnDataInit]);

  /**
   * All Available fields for the selected Type
   */
  const [allFields, setAllFields] = useStateEffect([], [selectType.input.typename], () => {
    return getCurrentTypeFields({
      typename: selectType.input.typename,
      connectionFilter: connectionFilters.NO_CONNECTIONS,
      onlyFieldNames: true,
    });
  });

  /**
   * Update the Custom Query form when graphql changes
   */
  useEffect(() => {
    if (!isNullOrUndefined(rule) && typeof onChangeCallback === "function") {
      onChangeCallback({
        typename: selectType.input.typename || undefined,
        rule: rule ? JSON.stringify(rule) : undefined,
      });
    }
  }, [typename, rule]);

  const display = (
    <Card>
      <CardBody>
        {selectType.display}
        {selectType.input.typename && logicBuilder.display}
      </CardBody>
    </Card>
  );

  return {
    logicBuilder,
    display,
    allFields,
    setAllFields,
    formHook: selectType,
  };
};
