import { Icon } from "@iconify/react";
import React from "react";

/**
 * Gets an icon from iconify
 *
 * Browse icons here:
 * https://icon-sets.iconify.design/
 * @param iconName
 * @returns {JSX.Element}
 */
export const getIcon = (iconName = "ci:dot-03-m") => <Icon icon={iconName} />;

/**
 * Handles the possibility of an icon being a simple-line-icons classname string
 * If not, calls handleGetIcon that will continue to process the icon.
 *
 * Note: all icons should be using iconify strings, this is for legacy 'simple-line-icons' support
 * @param {string | JSX.Element} icon - the icon to render. Can be an iconify string or a JSX.Element (or a simple-line-icons classname string)
 * @returns {*|JSX.Element|React.DetailedReactHTMLElement<{style: {marginRight: string}}, HTMLElement>}
 */
export const handleIcon = (icon) => {
  return (
    icon &&
    (typeof icon === "string" && icon.includes("icon-") && icon.split("icon-")?.[0] === "" ? ( // makes sure the string starts with "icon-" and this isn't an iconify string
      <i className={icon} style={{ marginRight: "0.75em", marginLeft: "3px" }} />
    ) : (
      handleGetIcon(icon)
    ))
  );
};

/**
 * Handles the possibility of an icon being a JSX element already, if not, assumes it's an iconify string
 * @param icon
 * @returns {*|JSX.Element|React.DetailedReactHTMLElement<{style: {marginRight: string}}, HTMLElement>}
 */
export const handleGetIcon = (icon) => {
  return (
    icon && (typeof icon === "string" ? getIcon(icon) : React.cloneElement(icon, { style: { marginRight: "1em" } }))
  );
};
