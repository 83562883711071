import { roleConfigDefault, updateModulePermissions } from "@rivial-security/role-utils";

import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import _ from "lodash";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Updates a Module 'enabled' field to true or false
 * @param {string} moduleName - the name of the module to update
 * @param {boolean} enabled - TRUE if module is enabled, FALSE if module is not enabled
 * @param {function} setRoleConfig - state handler function for updating the role config object
 * @param {function} setIsChanged - state handler function for toggling an 'isChanged' state
 */
export const handleModuleChange = (moduleName, enabled, setRoleConfig, setIsChanged) => {
  setRoleConfig &&
    setRoleConfig((roleConfig) => {
      const currentRoleConfig = _.cloneDeep(roleConfig);

      // if the current role config object doesn't have this module as a key, add it so that the state update can proceed
      if (
        isNullOrUndefined(currentRoleConfig?.modules?.[moduleName]) &&
        !isNullOrUndefined(roleConfigDefault?.modules?.[moduleName])
      ) {
        EventLogger(
          `This module (${moduleName}) is not included as a key in the current roleConfig object. Adding the key to prevent an update error`,
        );
        currentRoleConfig.modules[moduleName] = false;
      }

      const updatedRoleConfig = updateModulePermissions({
        roleConfig: currentRoleConfig,
        moduleName,
        enabled,
      });
      return { ...updatedRoleConfig };
    });
  setIsChanged && setIsChanged(true);
};
