import React, { useEffect, useState } from "react";
import { getOperationTeamsForOrganization } from "./useRoleDetails/functions/operationTeamsForOrganization";
import { OperationTeamList } from "../components/OperationTeams/OperationTeamList";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * @description Display Operation Teams that are assigned to a Role
 * @param {string} organizationID - selected organization id
 * @param {string} roleID - role id
 * @param {string} getBy - get operation teams by "role" or "organization"
 * @returns {{display: JSX.Element}}
 */
export const useGetOperationTeamsForOrg = ({ organizationID, roleID, getBy = "organization" } = {}) => {
  const [operationTeams, setOperationTeams] = useState([]);
  const [initLoad, setInitLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (organizationID && initLoad) {
      setInitLoad(false);
      setIsLoading(true);

      let input;

      if (roleID && getBy === "role") {
        input = {
          getBy: "role",
          organizationID,
          roleID,
        };
      } else if (organizationID && getBy === "organization") {
        input = {
          getBy: "organization",
          organizationID,
        };
      }

      if (input) {
        getOperationTeamsForOrganization({ input })
          .then((data) => setOperationTeams(data))
          .finally(() => setIsLoading(false));
      } else {
        EventLogger("Missing input to get operation teams");
      }
    }
  }, [roleID]);

  const display = <OperationTeamList operationTeams={operationTeams} isLoading={isLoading} />;

  return {
    display,
  };
};
