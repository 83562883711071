import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../../../hooks/views/useForm/useForm";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { Alert } from "@mui/material";
import { usePointOfContactDataGrid } from "../../../views/OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";
import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../EventLogger/EventLogger";
import AccessListDisplay from "../components/AccessListDisplay";
import { GetQuery } from "../../Functions/Graphql/GetQuery";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * @description Add Point of Contact Access Control for an item
 * @param {object} item - database object with an accessControl field
 * @param {string} organizationID - selected organization
 * @param {function} submitFunction - function to submit the form
 * @param {boolean} disableFormButtons - disable form buttons
 * @param {function} resetFunction - reset state of the item
 * @returns {{display: *, input: {}}}
 */
export const useAddPointOfContactControl = ({
  item,
  organizationID,
  submitFunction,
  disableFormButtons,
  resetFunction,
}) => {
  const context = useContext(OrganizationContext);
  const [allowedAccess, setAllowedAccess] = useState(true);
  const [pointOfContactAccessList, setPointOfContactAccessList] = useState([]);

  const { getQuery } = generateGraphql("PointOfContact", ["id", "firstName", "lastName", "email"]);

  const removePointOfContactFromList = (pointOfContactId) => {
    setPointOfContactAccessList((prevPointOfContacts) =>
      prevPointOfContacts.filter((pointOfContact) => pointOfContact.id !== pointOfContactId),
    );
  };
  const getPointOfContacts = async (pointOfContacts) => {
    if (isNonEmptyArray(pointOfContacts)) {
      const fetchedPointOfContacts = [];
      for (const pointOfContact of pointOfContacts) {
        try {
          const data = await GetQuery({
            query: getQuery,
            variables: {
              id: pointOfContact,
            },
          });
          fetchedPointOfContacts.push(data);
        } catch (err) {
          EventLogger("There was an error getting point of contacts: ", err);
        }
      }
      setPointOfContactAccessList(fetchedPointOfContacts);
    }
  };

  const grid = usePointOfContactDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
  });

  useEffect(() => {
    getPointOfContacts(item?.accessControl?.pointOfContacts);
    const pointOfContacts = item?.accessControl?.pointOfContacts;
    if (pointOfContacts && Array.isArray(pointOfContacts) && pointOfContacts.length > 0 && grid?.ref) {
      /**
       * Check if user have contacts access
       */
      const foundContact = pointOfContacts.map((id) => id === context?.loggedInPointOfContactId);
      !foundContact && setAllowedAccess(false);
    }
  }, [item, grid?.ref]);

  const form = useForm({
    fieldConfig: {
      pointOfContacts: {
        inputType: "custom",
        customConfig: {
          component: (
            <>
              <div style={{ height: "30em", marginBottom: "1rem" }}>{grid?.gridDisplay}</div>
              <AccessListDisplay
                contacts={pointOfContactAccessList}
                itemName={item?.name}
                listToDisplay="pointsOfContact"
                item={item}
                resetFunction={resetFunction}
                onPointOfContactRemoved={removePointOfContactFromList}
              />
            </>
          ),
        },
      },
    },
    ...((disableFormButtons || grid?.selectedData.length === 0) && {
      disableSubmitButton: true,
    }),
    disableResetButton: true,
    submitFunction,
  });

  const AssessDenied = () => <Alert color="info">Sorry, you do not have contact access</Alert>;

  return {
    ...form,
    display: allowedAccess ? form?.display : <AssessDenied />,
    selectedData: grid?.selectedData,
    setSelectedItems: grid?.setSelectedData,
    setSelectedItemIDs: grid?.setSelectedIDs,
  };
};
