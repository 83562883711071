import React from "react";
import { Redirect } from "react-router-dom";

const OperationPanelTemplates = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelTemplateManager/OperationPanelTemplateManager"),
);
const OperationTeamOrganizations = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelOrganizations/OperationPanelOrganizations"),
);
const OperationTeamUsers = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelUsers/components/OperationPanelUsers"),
);
const OperationTeamUserDetails = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelUsers/components/OperationPanelUserDetails"),
);

const OperationPanelDashboard = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelDashboard/OperationPanelDashboard"),
);

const OperationPanelConfiguration = React.lazy(
  () => import("../../views/OperationsPanel/OperationPanelConfiguration/OperationPanelConfiguration"),
);

// Operation Panel Routes
export const operationPanelRoutes = [
  /**
   * Dashboard
   */
  {
    path: "/operation_panel/dashboard",
    exact: true,
    name: "Operation Team Dashboard",
    component: OperationPanelDashboard,
  },

  /**
   * Templates
   */
  {
    path: "/operation_panel/template_manager",
    exact: true,
    name: "Operation Team Template Manager",
    component: OperationPanelTemplates,
  },

  /**
   * Organization checkout - here an operation team user can change the current organization they are working with
   */
  {
    path: "/operation_panel/organizations",
    exact: true,
    name: "Operation Team Organizations",
    component: OperationTeamOrganizations,
  },

  /**
   * Operation Team Users - displays a list of users that belong to an operation team
   */
  {
    path: "/operation_panel/users",
    exact: true,
    name: "Operation Team Users",
    component: OperationTeamUsers,
  },

  /**
   * Operation Team User details - displays an operation user details
   */
  {
    path: "/operation_panel/users/:id",
    exact: true,
    name: "Operation Team User Details",
    component: OperationTeamUserDetails,
  },

  /**
   * Operation Team Configuration - contains settings for the operation team
   */
  {
    path: "/operation_panel/configuration",
    exact: true,
    name: "Operation Team Configuration",
    component: OperationPanelConfiguration,
  },

  /**
   * Default Route
   */
  {
    path: "/operation_panel",
    component: () => <Redirect to="/operation_panel/organizations" />,
  },
];
