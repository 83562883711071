import { modules, resources } from "@rivial-security/role-utils";

import ControlCategoryDetails from "../components/ControlCategoryDetails";
import DeleteRiskControlCategory from "../../../Systems/functions/deleteFunctions/DeleteRiskControlCategory";
import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getIcon } from "../../../../../utils/Functions/Icon/getIcon";
import { mergeAdditionalFields } from "../../../../../hooks/views/useGrid/functions/mergeAdditionalFields";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useGridCard } from "../../../../../hooks/views/useGridCard";
import { useRiskControlImporter } from "../components/SubControls/hooks/useRiskControlsImporter";

/**
 * @deprecated - use useControlCategoriesDataGrid instead
 * Displays a list of Control Categories for an Organization's risk config
 * @param organizationID
 * @param additionalFields
 * @param queryConfig
 * @param cardConfig
 * @param gridConfig
 */
export const useControlCategoriesGrid = ({
  organizationID,
  additionalFields = [],
  queryConfig = {},
  cardConfig = {},
  gridConfig = {},
}) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL_CATEGORY;

  const route = "#/risk/risk_config/control_categories/";

  const roleConfig = {
    module,
    resource,
  };

  queryConfig = {
    query: realTimeRiskGQL.listRiskControlCategorys_systemAccordion,
    organizationID: organizationID,
    ...queryConfig,
  };

  const { updateMutation } = generateGraphql("RiskControlCategory", ["name"]);

  cardConfig = {
    title: "Risk Control Categories",
    headerIcon: getIcon("clarity:shield-check-line"),
    ...cardConfig,
  };

  const fields = [
    {
      name: "name",
    },
  ];
  mergeAdditionalFields({ fields, additionalFields });

  const uploadSubControlsHook = useRiskControlImporter({
    organizationID,
  });

  gridConfig = {
    route,
    fields,
    options: ["delete"],
    enableContextMenu: true,
    persistenceUUID: "risk-control-categories-grid",
    deleteFunction: DeleteRiskControlCategory,
    typename: "Risk Control Category",
    updateMutation: updateMutation,
    detailsComponent: <ControlCategoryDetails />,
    config: {
      limit: 25,
    },
    sortSettings: {
      columns: [{ field: "name", direction: "Ascending" }],
    },
    createResourceComponent: (
      <div>
        <h6>
          Control Categories are automatically created when Key Risk Indicators are created. Use the button below to
          import Sub-Controls.
        </h6>
        {uploadSubControlsHook.modalButton}
      </div>
    ),
    ...gridConfig,
  };

  const grid = useGridCard({ queryConfig, gridConfig, roleConfig, cardConfig });

  return { ...grid };
};
