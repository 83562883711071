export const getRiskRecommendation_recommendationDetails = /* GraphQL */ `
  query GetRiskRecommendation($id: ID!) {
    getRiskRecommendation(id: $id) {
      id
      ownerGroup
      name
      resolved
      costChange
      roiChange
      implementationChange
      riskControlID
      residualChange
      returnOnInvestment
      systemChanges {
        id
        name
        residualChange
        returnOnInvestment
      }
      riskControl {
        id
        name
        statementNumber
        costOfControl
        implementationRating
      }
    }
  }
`;

export const listRiskRecommendations_recommendationsList = /* GraphQL */ `
  query ListRiskRecommendations($filter: ModelRiskRecommendationFilterInput, $limit: Int, $nextToken: String) {
    listRiskRecommendations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerGroup
        name
        resolved
        riskControlID
        implementationChange
        costChange
        residualChange
        returnOnInvestment
        riskControl {
          id
          name
          statementNumber
          costOfControl
          strengthRating
          implementationRating
          outsourced
        }
        system {
          id
          name
        }
      }
    }
  }
`;

export const updateRiskRecommendation_recommendationList = /* GraphQL */ `
  mutation UpdateRiskRecommendation($input: UpdateRiskRecommendationInput!) {
    updateRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
      resolved
      riskControlID
      implementationChange
      costChange
      residualChange
      roiChange
      riskControl {
        id
        name
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        outsourced
      }
      system {
        id
        name
      }
    }
  }
`;

export const deleteRiskRecommendation_recommendationList = /* GraphQL */ `
  mutation DeleteRiskRecommendation($input: DeleteRiskRecommendationInput!) {
    deleteRiskRecommendation(input: $input) {
      id
      ownerGroup
      name
    }
  }
`;
