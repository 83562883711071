"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskAmount_System = void 0;
/**
 *
 * @param monteCarloResults
 * @param field
 * @returns {number|*}
 */
const getRiskAmount_System = ({ monteCarloResults, field = "residualRisk" }) => {
    // For getting the rating from the KRIs instead of the System
    if (monteCarloResults) {
        if (monteCarloResults?.systemStats && !isNaN(monteCarloResults?.systemStats?.[field])) {
            return monteCarloResults?.systemStats?.[field];
        }
        else {
            return 0;
        }
    }
};
exports.getRiskAmount_System = getRiskAmount_System;
