import { useContext, useEffect } from "react";
import { useCsvImporter } from "../../../CsvImporters/hooks/useCsvImporter";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { UIContext } from "../../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Importer for Vendor Sub Controls.
 * Vendor Control Categories MUST already be created, and the control category name MUST match exactly
 * @param organizationID
 * @param toggleModal
 * @param resetFunction
 * @returns {{display, dataJSONReady, setDataJSONReady, initSourceQuery, reset, createItemsFromArray: createItemsFromArray}}
 */
export const useUploadVendorSubControls = ({
  organizationID = "vendor-review-templates",
  toggleModal,
  resetFunction,
}) => {
  const { listQuery: listVendorControlCategories } = generateGraphql("VendorControlCategory", ["name"]);

  const { addToast, updateToast } = useContext(UIContext);

  const headerObjects = [
    {
      title: "statementNumber",
      type: "text",
    },
    {
      title: "name",
      type: "text",
    },
    {
      title: "controlCategoryName",
      type: "text",
    },
  ];

  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Vendor Sub Controls",
    passThroughDuplicateScanner: true,
  });

  const fields = ["id", "statementNumber", "name", "vendorControlCategoryID", "ownerGroup"];
  const { listQuery: listVendorSubControls, updateMutation: updateVendorSubControl } = generateGraphql(
    "VendorSubControl",
    fields,
  );
  const { createMutation: createVendorSubControl } = generateGraphql("VendorSubControl", [...fields, "createdAt"]);
  const { deleteMutation } = generateGraphql("VendorSubControl", ["id"]);

  //Set original data for duplicates scanner
  const initCsvImporter = async () => {
    //Used to display proper information in duplicates scanner
    const controlCategoryNames = await ListQuery({
      query: listVendorControlCategories,
      organizationID,
    });
    const controlCategoryNamesMap = {};
    for (const controlCategory of controlCategoryNames) {
      controlCategoryNamesMap[controlCategory.id] = controlCategory.name;
    }
    const renameMap = [
      {
        oldFieldName: "controlCategoryID",
        newFieldName: "controlCategoryName",
        map: controlCategoryNamesMap,
      },
    ];

    csvImporter.initSourceQuery(listVendorSubControls, organizationID, renameMap);
  };

  useEffect(() => {
    initCsvImporter();
  }, []);

  const createItemsFromArray = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;

      addToast({
        id: "csv_toast_upload_controls",
        icon: "spinner",
        header: `CSV Upload for Vendor Sub Controls Started (${totalOperations} Operations)`,
      });

      const existingControlCategories = await ListQuery({
        query: listVendorControlCategories,
        organizationID,
      });
      const existingControls = await ListQuery({
        query: listVendorSubControls,
        organizationID,
      });

      toggleModal && toggleModal();

      //perform sub control updates
      for (const operationItem of operations.update) {
        const itemFound = existingControls.some((originalItem) => {
          return originalItem.id != null && operationItem.id != null && originalItem.id === operationItem.id;
        });
        const categoryFound = existingControlCategories.some((x) => x.name === operationItem.controlCategoryName);
        delete operationItem.controlCategoryName;

        if (itemFound && categoryFound) {
          await ItemMutation(updateVendorSubControl, operationItem);
        }
      }

      //TODO: correctly perform risk control deletions (by deleting associated links)
      //
      // for (let operationItem of operations.delete) {
      //
      //   const foundItem = existingControls.find((originalItem) => {
      //     return (originalItem.id != null && operationItem.id != null && originalItem.id === operationItem.id)
      //   });
      //
      //   if (foundItem) {
      //     await ItemMutation(deleteMutation, {id: foundItem.id});
      //   }
      // }

      //perform sub control additions
      for (const operationItem of operations.add) {
        const existingControlCategory = existingControlCategories.find(
          (x) => x.name === operationItem.controlCategoryName,
        );

        if (existingControlCategory) {
          const input = {
            statementNumber: operationItem.statementNumber,
            name: operationItem.name,
            vendorControlCategoryID: existingControlCategory.id,
            ownerGroup: organizationID,
          };

          try {
            await ItemMutation(createVendorSubControl, input);
            EventLogger(`Vendor Sub Control created from CSV, with name#: ${operationItem.name}`);
          } catch (e) {
            EventLogger(`Failed to import a Vendor Sub Control: ${e}`);
          }
        }
      }

      updateToast({
        id: "csv_toast_upload_controls",
        icon: "success",
        header: `Successfully performed ${totalOperations} Risk Control operation(s)`,
      });

      resetFunction && resetFunction();
    }
  };

  // Perform the creations
  useEffect(() => {
    if (csvImporter.dataJSONReady) {
      createItemsFromArray(csvImporter.dataJSONReady).then(() => {
        updateToast({
          id: "csv_toast_upload_controls",
          icon: "success",
          header: `Vendor Sub Control CSV Upload is Complete!`,
        });
      });
    }
  }, [csvImporter.dataJSONReady]);

  return {
    ...csvImporter,
    createItemsFromArray,
  };
};
