import { Box, IconButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { convertCamelCaseToSentence, isNonEmptyArray } from "@rivial-security/func-utils";
import { precedenceTypes, resources } from "@rivial-security/role-utils";

import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import HistoryIcon from "@mui/icons-material/History";
import { ICON_MAP } from "../../../../../../enums/ICON_MAP";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import React from "react";
import Select from "@mui/material/Select";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Typography from "@mui/material/Typography";
import { getIcon } from "../../../../../../utils/Functions/Icon/getIcon";
import { shouldDisplayCustomList } from "../functions/shouldDisplayCustomList";
import { taskCategory } from "../constants/taskCategory";

/**
 * The component that handles rendering the task lists
 * @param {object} customLists - the list of custom lists
 * @param {object} pointOfContact - the point of contact object
 * @param {function} setCurrentList -  the function to set the current list
 * @param {string} selectedMenu - the selected menu for the TaskSidebar or TaskManagementUI
 * @param {function} setSelectedMenu - the function to set the selected menu for the TaskSidebar or TaskManagementUI
 * @param {boolean} isSidebarView - whether the component is being rendered in the TaskSidebar or TaskManagementUI
 * @param {object} taskListActionModal - the modal object for the task list actions
 * @param {object} createTaskListModal - the modal object for the create task list modal
 * @param {string} departmentHeadID - the id of the department head
 * @param {object} context - the current organization context
 * @param {function} isSpecialRole - returns true or false whether the user has a special role (admin, operation team member, manager)
 * @returns {JSX.Element}
 */
const TaskMenuSelection = ({
  customLists,
  pointOfContact,
  setCurrentList,
  selectedMenu,
  setSelectedMenu,
  taskListActionModal,
  createTaskListModal,
  departmentHeadID,
  isSidebarView,
  context,
  isSpecialRole,
}) => {
  const handleChange = (e) => {
    setSelectedMenu(e.target.value);
  };

  const handleCreateNewListClick = () => {
    createTaskListModal.setModalIsOpen(true);
  };

  const renderListItems = () => {
    return (
      <List>
        {context?.role?.precedence > precedenceTypes.MANAGER ||
        (context?.role?.precedence <= precedenceTypes.MANAGER &&
          context?.selectedOrganization === pointOfContact?.ownerGroup) ? (
          <>
            <Typography variant="subtitle1" align="inherit" sx={{ marginLeft: "1em" }}>
              Platform Lists
            </Typography>
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                margin: " .5em 1em",
              }}
            >
              <ListItemButton
                selected={selectedMenu === taskCategory.MY_TASKS}
                onClick={() => {
                  setSelectedMenu(taskCategory.MY_TASKS);
                  setCurrentList(null);
                }}
                sx={{
                  margin: ".5em",
                  padding: ".5em 1em",
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <TaskAltIcon />
                </ListItemIcon>
                <ListItemText primary={convertCamelCaseToSentence(taskCategory.MY_TASKS)} />
              </ListItemButton>
              {departmentHeadID && (
                <ListItemButton
                  selected={selectedMenu === taskCategory.DEPARTMENT_TASKS}
                  onClick={() => {
                    setSelectedMenu(taskCategory.DEPARTMENT_TASKS);
                    setCurrentList(null);
                  }}
                  sx={{
                    margin: ".5em",
                    padding: ".5em 1em",
                  }}
                >
                  <ListItemIcon sx={{ fontSize: "1.5rem", minWidth: "40px" }}>
                    {getIcon(ICON_MAP.RESOURCES[resources.DEPARTMENT])}
                  </ListItemIcon>
                  <ListItemText primary={convertCamelCaseToSentence(taskCategory.DEPARTMENT_TASKS)} />
                </ListItemButton>
              )}
              <ListItemButton
                selected={selectedMenu === taskCategory.PAST_DUE_TASKS}
                onClick={() => {
                  setSelectedMenu(taskCategory.PAST_DUE_TASKS);
                  setCurrentList(null);
                }}
                sx={{
                  margin: ".5em",
                  padding: ".5em 1em",
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary={convertCamelCaseToSentence(taskCategory.PAST_DUE_TASKS)} />
              </ListItemButton>
            </Box>
          </>
        ) : null}
        <Typography variant="subtitle1" align="inherit" sx={{ margin: "1em 0 0 1em" }}>
          Custom Task Lists
        </Typography>
        <Box
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
            margin: ".5em 1em",
          }}
        >
          {renderCustomLists()}
          <ListItemButton
            onClick={() => {
              setSelectedMenu(taskCategory.CREATE_NEW_LIST);
              createTaskListModal?.setModalIsOpen(true);
            }}
            sx={{
              margin: ".5em",
              padding: "0",
            }}
          >
            <ListItemIcon
              sx={{
                color: "#329EF4",
                minWidth: "40px",
              }}
            >
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primary={convertCamelCaseToSentence(taskCategory.CREATE_NEW_LIST)}
              sx={{
                color: "#329EF4",
                marginBottom: ".5em",
              }}
            />
          </ListItemButton>
        </Box>
      </List>
    );
  };

  const renderCustomLists = () => {
    return (
      isNonEmptyArray(customLists) &&
      customLists.map((list) => {
        const userAvailableTasks = list?.tasks?.items.filter(
          (task) => context?.loggedInPointOfContactId === task.pointOfContactID,
        );
        const shouldDisplayList = shouldDisplayCustomList({
          list,
          context,
          isSidebarView,
          userAvailableTasks,
        });
        if (shouldDisplayList) {
          const handleClick = () => {
            setCurrentList(list);
            setSelectedMenu(list?.name);
          };

          return isSidebarView ? (
            <MenuItem key={list?.id} value={list?.name} style={{ marginBottom: ".5em" }} onClick={handleClick}>
              {list?.name}
            </MenuItem>
          ) : (
            <ListItemButton
              key={list?.id}
              selected={selectedMenu === list?.name}
              onClick={handleClick}
              sx={{
                margin: ".3em",
                padding: ".3em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  <CircleIcon
                    sx={{
                      fontSize: ".5rem",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={list?.name}
                  style={{
                    marginRight: ".5em",
                  }}
                />
              </div>
              {(pointOfContact?.id === list?.pointOfContactID || isSpecialRole({ context })) && (
                <IconButton
                  onClick={() => {
                    taskListActionModal.setModalIsOpen(true);
                  }}
                  sx={{
                    marginLeft: "1em",
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </ListItemButton>
          );
        }

        return null;
      })
    );
  };
  return isSidebarView ? (
    <Select value={selectedMenu} onChange={handleChange} variant="standard" autoWidth disableUnderline={true}>
      <MenuItem value={taskCategory.MY_TASKS}>My Tasks</MenuItem>
      {departmentHeadID && <MenuItem value={taskCategory.DEPARTMENT_TASKS}>Department Tasks</MenuItem>}
      <MenuItem value={taskCategory.PAST_DUE_TASKS}>Past Due Tasks</MenuItem>
      <hr />
      {renderCustomLists()}
      <MenuItem value={taskCategory.CREATE_NEW_LIST} onClick={handleCreateNewListClick}>
        <PlaylistAddIcon style={{ marginRight: "0.5em" }} color={"success"} />
        Create New List
      </MenuItem>
    </Select>
  ) : (
    renderListItems()
  );
};
export default TaskMenuSelection;
