import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../../../../../../utils/EventLogger/EventLogger";
import { deleteItem } from "../../../../../../../utils/Functions/Graphql/deleteItem";

/**
 * Deletes a single Risk Control.
 * Deletes all many-to-many connections associated with this control.
 * @param riskControl
 * @returns {Promise<void>}
 */
export const deleteRiskControl = async (riskControl) => {
  if (!riskControl || !riskControl.id) {
    EventLogger("Could not delete Risk Control, invalid input");
    return;
  }

  // get full control data
  EventLogger("Querying Risk Control data");
  const { getQuery } = generateGraphql(
    "RiskControl",
    ["__typename", "riskChangeLinks", "evidences", "keyPerformanceIndicators"],
    {
      riskChangeLinks: `(limit: 1000) { items { __typename id } }`,
      evidences: `(limit: 1000) { items { __typename id } }`,
      keyPerformanceIndicators: `(limit: 1000) { items { __typename id } }`,
    },
  );

  return await deleteItem(getQuery, riskControl);
};
