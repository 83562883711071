import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { Alert } from "reactstrap";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { findLossToleranceExceedanceAtResidualLoss, getGreatestRiskCurves } from "@rivial-security/risk-calc-utils";
import { generateRiskCurveAtResidualLossWarning } from "../../LossTolerance/functions/generateRiskCurveAtResidualLossWarning";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Shows the status of risk curve being above or below loss tolerance for a system
 * @param {string} organizationID - the currently selected organization
 * @param {object} [monteCarloContext] - the context of the monte carlo simulation in system details, not needed if riskResidualLossCurve is not null
 * @param {object} [riskResidualLossCurve] - the risk curve generated by the simulations
 * @param {number} [riskResidualLoss] - the average loss across all simulations
 * @return {false|JSX.Element}
 */
const RiskCurveWarningAtResidualLoss = ({
  organizationID,
  monteCarloContext,
  riskResidualLossCurve,
  riskResidualLoss,
}) => {
  const [lossToleranceCurve, setLossToleranceCurve] = useState(null);
  const [riskWarning, setRiskWarning] = useState(null);

  //Retrieve the
  useEffect(() => {
    const getOrganization = /* GraphQL */ `
      query GetOrganization($id: ID!) {
        getOrganization(id: $id) {
          id
          lossToleranceCurve {
            amount
            probability
          }
        }
      }
    `;
    if (!isNullOrUndefined(organizationID)) {
      QueryGetItem({ query: getOrganization, itemId: organizationID })
        .then((org) => {
          setLossToleranceCurve(
            org.lossToleranceCurve
              .sort((a, b) => b.probability - a.probability)
              .map((row) => ({ x: row.amount, y: row.probability })),
          );
        })
        .catch((e) => EventLogger(`Error fetching Organization for Risk Curve Warning${e.message}`));
    }
  }, []);

  /**
   * Finds if residual risk is ever above the loss tolerance curve
   */
  useEffect(() => {
    //Try to get data from the top level arguments first
    let residualLossCurve = riskResidualLossCurve;
    let residualRisk = riskResidualLoss;

    //if data doesn't exist, try to get it from the monte carlo simulation context
    if ((!residualLossCurve || isNullOrUndefined(riskResidualLossCurve)) && monteCarloContext) {
      const curves = getGreatestRiskCurves({
        riskScore: monteCarloContext?.riskScore,
        monteCarloResults: monteCarloContext?.monteCarloResults,
      });

      if (curves) {
        residualLossCurve = curves.residualLossCurve;
        residualRisk = monteCarloContext?.riskScore?.greatestEnterpriseRisk?.residualRisk;
      }
    }

    if (!residualLossCurve || isNullOrUndefined(residualRisk)) {
      setRiskWarning(null);
      return;
    }

    const result = findLossToleranceExceedanceAtResidualLoss({
      lossToleranceCurve,
      residualLossCurve,
      residualRisk,
    });

    if (isNullOrUndefined(result?.lossToleranceDelta)) {
      setRiskWarning(null);
      return;
    }

    const lossToleranceDelta = result?.lossToleranceDelta;

    //Generate the string warning to display
    const currentRiskWarning = generateRiskCurveAtResidualLossWarning({
      lossToleranceDelta,
    });

    setRiskWarning(currentRiskWarning);
  }, [lossToleranceCurve, riskResidualLossCurve, riskResidualLoss, monteCarloContext]);

  return (
    riskWarning?.message != null && (
      <Alert color={!isNullOrUndefined(riskWarning?.type) ? riskWarning?.type : "info"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "2em", marginRight: ".5em", padding: 0 }}>
            {!isNullOrUndefined(riskWarning?.type) &&
              getIcon(
                riskWarning?.type === "warning" ? "ant-design:warning-outlined" : "clarity:success-standard-line",
              )}
          </div>
          <div>{riskWarning.message}</div>
        </div>
      </Alert>
    )
  );
};

export default RiskCurveWarningAtResidualLoss;
