export const recommendationsByOwnerGroup = /* GraphQL */ `
  query RecommendationsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resolvedAt
        name
        module
        rating
        status
        difficulty
        observations(limit: 100) {
          items {
            id
            observation {
              name
              description
              isFinding
              module
            }
            proposedChange {
              typename
              field
              proposedValue
              completed
              effect
            }
          }
        }
        ownerGroup
      }
      nextToken
    }
  }
`;

export const recommendationsByOwnerGroupTotals = /* GraphQL */ `
  query RecommendationsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
      }
      nextToken
    }
  }
`;
