import { EventLogger } from "../../../utils/EventLogger/EventLogger";

/**
 * Updates the query object in query builder in place to reflect the filter selection chosen by the user.
 * Uses the path prop to navigate recursively to the part of the query object to update.
 * @param {object[]} path - ordered set of nodes that lead to the leaf node where to add the filter
 * @param {object} newResultFields - the fields property of the query at current recursion level
 * @param {object} newFilter - mui logic builder filter object to save as the filter
 */
export const updateLeafResultFieldFilter = ({ path, newResultFields, newFilter }) => {
  if (typeof newResultFields !== "object") {
    EventLogger("Something is wrong when updating selected functions!");
    return;
  }

  //Drill down to the leaf of the path to update the filter
  if (Array.isArray(path) && path.length > 1) {
    const currentFieldName = path[0].name;
    if (!newResultFields.hasOwnProperty(currentFieldName)) {
      newResultFields[currentFieldName] = { ...path[0] };
    }
    const currentField = newResultFields[currentFieldName];

    if (typeof currentField?.fields !== "object") {
      currentField.fields = {};
    }

    if (typeof currentField?.functions !== "object") {
      currentField.functions = {};
    }

    updateLeafResultFieldFilter({
      path: path.slice(1),
      newResultFields: currentField?.fields,
      newFilter,
    });
  } else if (path.length === 1) {
    const currentFieldName = path[0].name;
    if (!newResultFields.hasOwnProperty(currentFieldName)) {
      newResultFields[currentFieldName] = { ...path[0] };
    }
    newResultFields[currentFieldName].filters = newFilter;
  }
};
