import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

/**
 * Single small card component used to display various elements in the Layout Footer
 *
 * @type {StyledComponent<PropsOf<OverridableComponent<PaperTypeMap>> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const LayoutFooterElement = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  elevation: 0,
}));

export default LayoutFooterElement;
