import React from "react";
import { modules, resources } from "@rivial-security/role-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import UserDetails from "../components/UserDetails";
import { deleteUser } from "../functions/deleteUser";
import UserRoleField from "../customFields/UserRoleField";
import UserPreferredMFA from "../components/UserPreferredMFA";
import { USER_PREFERRED_MFA_TYPES } from "../enums/USER_PREFERRED_MFA_TYPES";

const { listQuery: listUsers } = generateGraphql(
  "User",
  [
    "name",
    "username",
    "email",
    "phoneNumber",
    "accountType",
    "roleLinks",
    "preferredMFA",
    "lastChangedPasswordDate",
    "acceptanceDate",
  ],
  {
    roleLinks: `{ items { id role { id ownerGroup name } } }`,
  },
);

/**
 * @description This hook is used to get the data for users data grid.
 * @param {string} itemId - The id of the organization
 * @param {object} item - The organization object
 * @param {object[]} fields - The fields to display in the data grid
 * @param {object} props - The props to pass to the data grid
 * @returns {{selectedData: [], gridDisplay: JSX.Element, cardDisplay: JSX.Element, data: Object, setData: (value: (((prevState: Object) => Object) | Object)) => void, setSelectedData: (value: (((prevState: []) => []) | [])) => void, display: JSX.Element, dashboardDisplay: JSX.Element, isLoading: unknown, createResourceModal: {setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: function(): void}, setIsLoading: (value: unknown) => void, resetFunction: function(boolean=): Promise<void>, selectedIDs: [], setCheckboxSelection: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useUsersDataGrid = ({ itemId, item, fields = [], ...props }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.USER;

  fields = [
    {
      name: "name",
      width: 200,
      flex: 1,
    },
    {
      name: "username",
      flex: 1,
    },
    {
      name: "phoneNumber",
      flex: 1,
    },
    {
      name: "accountType",
      flex: 1,
      hidden: true,
    },
    {
      field: "role",
      width: 200,
      component: <UserRoleField />,
      valueGetter: (param) => {
        return param?.row?.roleLinks?.items?.[0]?.role?.name || "";
      },
    },
    {
      field: "preferredMFA",
      width: 150,
      component: <UserPreferredMFA />,
      valueGetter: ({ value }) => {
        switch (value) {
          case USER_PREFERRED_MFA_TYPES.SMS:
            return "Text Message";
          case USER_PREFERRED_MFA_TYPES.TOTP:
            return "Authenticator";
          case USER_PREFERRED_MFA_TYPES.NONE:
            return "None";
          default:
            return "Unknown";
        }
      },
    },
    // Uncomment this to display the last changed password date when we implement last changed password date from the login page
    // {
    //   field: "lastChangedPasswordDate",
    //   width: 150,
    //   type: "date",
    // },
    {
      field: "acceptanceDate",
      width: 150,
      type: "date",
      description: "Date the user accepted the license agreement",
    },
    ...fields,
  ];

  const queryConfig = {
    query: listUsers,
    organizationID: itemId || item?.id,
    module,
    resource,
  };

  return useDataGrid({
    queryConfig,
    helpCenterRoute: "security-dashboard-information",
    module,
    resource,
    title: "Users",
    headerIcon: "icon-user",
    fields,
    detailsComponent: <UserDetails />,
    query: listUsers,
    organizationID: itemId || item?.id,
    limit: 1000,
    typename: "User",
    persistenceUUID: "data-grid-users",
    options: ["delete"],
    deleteFunction: async (itemToDelete) => await deleteUser({ item: itemToDelete }),
    ...props,
  });
};
