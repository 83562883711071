import { Button } from "reactstrap";
import React from "react";
import { deleteVendorDocument } from "../../VendorDocuments/functions/deleteVendorDocument";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";

/**
 *
 * @param item {object}
 * @param resetFunction {function}
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {JSX.Element}
 * @constructor
 */

const CustomVendorRemoveButton = ({ item, resetFunction, module, resource, disableRoleChecking }) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <Button
      color="ghost-danger"
      disabled={!checkPermissions.resource.update}
      onClick={() => {
        if (window.confirm(`Are you sure you want to delete this Document?`)) {
          deleteVendorDocument(item).then(() => resetFunction && resetFunction());
        }
      }}
      title="Remove Document"
    >
      <i className="icon-trash" /> Remove
    </Button>
  );
};

export default CustomVendorRemoveButton;
