import { Auth } from "@aws-amplify/auth";
import { Col, Input, Label, UncontrolledTooltip } from "reactstrap";

import React, { useState } from "react";
import { EventLogger } from "../../../EventLogger/EventLogger";
import MFASetup from "./MFASetup";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import * as Sentry from "@sentry/react";
import LoginButton from "../LoginButton";

/**
 * @description This component is used to handle the login of the user, with multiple factor authentication.
 * @param {object} user - The user to login.
 * @param {function} changeState - The function to change the sign in state.
 * @returns {JSX.Element} - The login component.
 */
const MFAScreen = ({ user, changeState }) => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  /**
   * When MFA is required by the organization but the user has not set it up yet, allow the user to set it up.
   */
  const allowMFASetup = user?.preferredMFA === "NOMFA" && user?.attributes?.["custom:requireMFA"] === "true";

  /**
   * Check if the user needs to enter a MFA code.
   */
  const showMFAScreen = user?.challengeName === "SMS_MFA" || user?.challengeName === "SOFTWARE_TOKEN_MFA";

  /**
   * Handle the MFA code input.
   */
  const onSubmitMFACode = async () => {
    setIsLoading(true);

    // If MFA is enabled, sign-in should be confirmed with the confirmation code
    await Auth.confirmSignIn(
      user, // Return object from Auth.signIn()
      code, // Confirmation code
      user?.challengeName, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    )
      .then((response) => {
        changeState("signedIn", response);
      })
      .catch((err) => {
        EventLogger("Error! MFA Screen", err);
        if (err?.code === "CodeMismatchException") {
          alert("Invalid MFA code, please try again!");
        } else {
          alert("Something went wrong, please try again!");
        }

        Sentry.withScope((scope) => {
          scope.setTag("username", user?.username);
          Sentry.captureMessage(`MFA Screen: ${err.message}`);
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      {showMFAScreen && (
        <div>
          <Col md={12}>
            <h5 style={{ textAlign: "center" }}>Multi-factor Authentication</h5>
            <p style={{ textAlign: "center" }}>Enter an MFA code to complete the sign-in process.</p>
            <Label htmlFor="codeMFA">
              MFA Code{" "}
              <span style={{ cursor: "pointer" }} id="TooltipMFA">
                <i className="icon-question" />
              </span>
              <UncontrolledTooltip placement="right" target="TooltipMFA">
                <p>Please check your phone and enter the code sent to you as an SMS message.</p>
                <p>
                  If you changed your MFA delivery settings to a TOTP method (like Google Authenticator), please enter
                  the code from the app.
                </p>
              </UncontrolledTooltip>
            </Label>
            <span> </span>
            <Input
              className="appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              id="codeMFA"
              key="codeMFA"
              name="codeMFA"
              type="number"
              autoFocus={true}
              autoComplete="off"
              onChange={(e) => setCode(e.target.value)}
              onKeyPress={async (event) => {
                if (event?.key === "Enter") {
                  await onSubmitMFACode();
                }
              }}
            />
            <div style={{ height: "1em" }} />
            <LoginButton
              text={"Continue"}
              buttonColor={"#83C6E3"}
              textColor={"black"}
              disabled={isLoading}
              title={"Submit MFA code and continue sign in"}
              onClick={async () => {
                await onSubmitMFACode();
              }}
            />
            <LoginButton
              text={"Cancel"}
              buttonColor={"#CED4DA"}
              buttonBorder={"1px solid #CED4DA"}
              textColor={"black"}
              disabled={isLoading}
              variant={"outlined"}
              title={"Invalidate the MFA code and refresh the page"}
              onClick={async () => {
                await Auth.signOut().finally(() => {
                  history.push("/");
                  window.location.reload();
                });
              }}
            />
          </Col>
        </div>
      )}
      <br />
      {allowMFASetup && (
        <div>
          <Alert color={"info"} icon={false}>
            Please set up your MFA before continuing
          </Alert>
          <br />
          <MFASetup changeState={changeState} />
        </div>
      )}
    </div>
  );
};

export default MFAScreen;
