import * as Sentry from "@sentry/react";

import { Card, CardBody, Collapse } from "reactstrap";

import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../EventLogger/EventLogger";
import FirstSignIn from "./FirstSignIn";
import { Hub } from "@aws-amplify/core";
import Login from "./Login";
import MFAScreen from "./MFA/MFAScreen";
/**
 * Author: Anatoli Railean
 * Last Edit:
 *
 * Description: This component extends Amplify SignIn page with a custom layout
 *
 *
 */
import React from "react";
import { SignIn } from "aws-amplify-react";
import animatedLogo from "../../../assets/img/brand/animated_logo.gif";
import { handleSignInError } from "./functions/handleSignInError";
import { isMFAChallenge } from "./MFA/functions/isMFAChallenge";
import { isOrgRequiresMFA } from "./MFA/functions/isOrgRequiresMFA";
import reverseLogo from "../../../assets/img/brand/reverse_animated_logo.gif";

/**
 * @description This component extends Amplify SignIn page with a custom layout
 * @param {object} props - The props passed to the component
 * @returns {JSX.Element}
 */
export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      user: null,
      isMFA: false,
      isNewPassword: false,
      qrCode: "",
      isOpen: false,
      logoTimeOut: null,
      isLoading: false,
    };

    Hub.listen("auth", ({ payload: { event, data } }) => {
      const eventIsSignInFailure = event === "signIn_failure";
      const message = data?.message;
      const signInFailureBecauseOfInvalidRequest = message === "invalid_request";
      const signInFailureBecauseOfInvalidFunctionOutput =
        typeof message === "string" && message.includes("Invalid+lambda+function+output");
      const federatedSignInFail = signInFailureBecauseOfInvalidRequest || signInFailureBecauseOfInvalidFunctionOutput;

      if (eventIsSignInFailure && federatedSignInFail) {
        const errorMessage =
          "Failed to sign in with provider. This provider might not be configured yet for your organization, please contact an administrator.";
        //NOTE: attempted to call on mount but UI alert does not appear, therefore using a timeout
        setTimeout(() => {
          this.error(new Error(errorMessage));
        }, 2000);
      }
    });

    this.SignIn = this.signIn.bind(this);
  }

  async signIn(username, password) {
    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(username, password);
      this.setState({ user: user });

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // You need to get the new password and required attributes from the UI inputs
        // and then trigger the following function with a button click,
        // For example, the email and phone_number are required attributes
        this.setState({ isNewPassword: true });
      } else if (
        isMFAChallenge(user?.challengeName) || // If the user has enabled MFA, will be prompted for MFA code
        (isOrgRequiresMFA(user?.attributes) && user?.preferredMFA === "NOMFA") // If the user has not enabled MFA,
        // but the organization requires it, will be prompted to set it up
      ) {
        // You need to get the code from the UI inputs
        // and then trigger the following function with a button click
        this.setState({ isMFA: true });
      } else if (user.challengeName === "MFA_SETUP") {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
        Auth.setupTOTP(user).then((code) => {
          const authCode = `otpauth://totp/AWSCognito:${this.state.user?.username}?secret=${code}&issuer=AWSCognito`;
          this.setState({ qrCode: authCode });
        });
      } else {
        this.setState({ logo: "reverseLogo" });
        // The user directly signs in
        this.setState({ isOpen: false });
        setTimeout(() => {
          this.changeState("signedIn", user);
        }, 5000);

        this.setState({ isLoading: false });
      }
    } catch (err) {
      handleSignInError(err, {
        error: this.error,
        EventLogger,
      });

      Sentry.withScope((scope) => {
        scope.setTag("username", username);
        Sentry.captureMessage(err.message);
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.setState({ isOpen: false });
    this.setState({ logo: "animatedLogo" });
    const logoTimeOut = setTimeout(() => {
      this.setState({ isOpen: true });
    }, 3500);
    this.setState({ logoTimeOut });
  }

  componentWillUnmount() {
    if (this.state.logoTimeOut) {
      clearTimeout(this.state.logoTimeOut);
    }
  }

  renderLogo() {
    const { logo } = this.state;
    if (logo === "animatedLogo") {
      return (
        <img
          src={animatedLogo}
          alt="Animated Logo"
          style={{ width: "27rem" }}
          className="shadow appearance-none leading-tight"
        />
      );
    } else if (logo === "reverseLogo") {
      return (
        <img
          src={reverseLogo}
          alt="Reverse Logo"
          style={{ width: "27rem" }}
          className="shadow appearance-none leading-tight"
        />
      );
    }
  }

  renderContent() {
    const { isNewPassword, isMFA, user, isLoading } = this.state;
    if (isNewPassword) {
      return <FirstSignIn user={user} changeState={this.changeState} />;
    } else if (isMFA) {
      return <MFAScreen user={user} changeState={this.changeState} />;
    } else {
      return <Login onSignIn={this.signIn} onError={this.error} isLoading={isLoading} />;
    }
  }

  showComponent(_theme) {
    return (
      <div style={{ display: "flex", justifyContent: "center", paddingTop: "8em" }}>
        <Card style={{ maxWidth: "min-content" }}>
          {this.renderLogo()}
          <Collapse isOpen={this.state.isOpen}>
            <CardBody>{this.renderContent()}</CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
