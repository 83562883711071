import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Deletes a single Tool item from the database
 * NOTE: This is also deletes the secret associated with the tool
 * @param {object} item - tool to delete
 * @returns {Promise<null|*>}
 */
export const deleteTool = async (item) => {
  if (!item?.id) EventLogger("Missing item id");
  const { deleteMutation } = generateGraphql("Tool", ["deleteSecret"]);
  return await ItemMutation(deleteMutation, { id: item?.id });
};
