import { useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Chip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskIcon from "@mui/icons-material/AddTask";
import React from "react";
import { taskCategory } from "../constants/taskCategory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TaskMenuSelection from "./TaskMenuSelection";
import { isSpecialRole } from "../../../functions/isSpecialRole";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";

/**
 * @description This component is used to display the body of the TaskSidebar component.
 * @param {function} setSelectedMenu - the setter function to call when the selected menu is changed
 * @param {string} selectedMenu - the currently selected menu
 * @param {function} setTasks - the setter function to call when the tasks are changed
 * @param {object} currentList - the currently selected list (this is a custom list) in the sidebar menu
 * @param {function} setCurrentList - the setter function to call when the current list is changed
 * @param {function} setCreateModalOpen - the setter function to call when the task creation modal is opened
 * @param {string} departmentHeadID - the id of the department head
 * @param {boolean} isSidebarView - determines if the task is being viewed in the sidebar
 * @param {object[]} customLists - the custom lists
 * @param {function} setCustomLists - the setter function to call when the custom lists are changed
 * @param {object} pointOfContact - the point of contact
 * @param {object} taskListActionModal - the task list action modal for editing/deleting custom lists
 * @param {object} createTaskListModal - the create task list modal for creating custom lists
 * @return {JSX.Element}
 */
const TaskCardBody = ({
  setSelectedMenu,
  selectedMenu,
  currentList,
  setCurrentList,
  setTasks,
  setCreateModalOpen,
  departmentHeadID,
  isSidebarView,
  customLists,
  setCustomLists,
  pointOfContact,
  taskListActionModal,
  createTaskListModal,
}) => {
  const context = useContext(OrganizationContext);
  const BuiltInLists = [taskCategory.MY_TASKS, taskCategory.DEPARTMENT_TASKS, taskCategory.PAST_DUE_TASKS];

  useEffect(() => {
    const isListWithoutPointOfContact = (list) => {
      const hasNoPointOfContact = list?.pointOfContactID === null;
      const belongsToSelectedOrganization = list?.ownerGroup === context?.selectedOrganization;

      return hasNoPointOfContact && belongsToSelectedOrganization;
    };

    const isListWithPointOfContact = (list) => {
      const isPointOfContactMatch = list?.pointOfContactID === pointOfContact?.id;
      const isOwnerGroupMatch = pointOfContact?.ownerGroup === context?.selectedOrganization;
      const isTaskAssignedToCurrentPointOfContact = (task) => pointOfContact?.id === task.pointOfContactID;

      const hasTasksAssignedToCurrentPointOfContact = (list?.tasks?.items || []).some(
        isTaskAssignedToCurrentPointOfContact,
      );

      return (isPointOfContactMatch && isOwnerGroupMatch) || hasTasksAssignedToCurrentPointOfContact;
    };

    if (isSpecialRole({ context }) && context?.selectedOrganization !== pointOfContact?.ownerGroup && !isSidebarView) {
      const listWithoutPointOfContact = customLists.find(isListWithoutPointOfContact) || null;
      setCurrentList(listWithoutPointOfContact);
      setCustomLists(customLists.filter(isListWithoutPointOfContact) || []);
    } else {
      setCustomLists(customLists.filter(isListWithPointOfContact));
    }

    if (BuiltInLists.includes(selectedMenu)) {
      setCurrentList(null);
    }
  }, [pointOfContact?.id, context?.selectedOrganization]);

  return isSidebarView ? (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography
            variant="body1"
            style={{
              textAlign: "left",
              color: "#80868b",
            }}
          >
            Lists
          </Typography>
          <TaskMenuSelection
            customLists={customLists}
            pointOfContact={pointOfContact}
            setSelectedMenu={setSelectedMenu}
            setCurrentList={setCurrentList}
            setTasks={setTasks}
            selectedMenu={selectedMenu}
            taskListActionModal={taskListActionModal}
            createTaskListModal={createTaskListModal}
            departmentHeadID={departmentHeadID}
            isSidebarView={isSidebarView}
            context={context}
            isSpecialRole={isSpecialRole}
          />
        </div>
        <IconButton
          onClick={() => {
            if (context?.sideBar) {
              context.setSideBar(null);
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <hr />
      <div
        style={{
          textAlign: "left",
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Chip
          icon={<AddTaskIcon color={"success"} style={{ marginRight: "0.2em" }} />}
          label="Add a Task"
          color={"success"}
          clickable
          variant="outlined"
          style={{ border: "none" }}
          onClick={() => setCreateModalOpen(true)}
        />
        {!BuiltInLists.includes(selectedMenu) &&
          selectedMenu !== taskCategory.CREATE_NEW_LIST &&
          (currentList?.pointOfContactID === context?.loggedInPointOfContactId || isSpecialRole({ context })) && ( // removes option to edit/delete built in lists
            <IconButton
              onClick={() => {
                taskListActionModal.setModalIsOpen(true);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
      </div>
      {createTaskListModal.modal}
      {taskListActionModal.modal}
    </>
  ) : (
    <>
      <TaskMenuSelection
        customLists={customLists}
        pointOfContact={pointOfContact}
        setSelectedMenu={setSelectedMenu}
        setCurrentList={setCurrentList}
        setTasks={setTasks}
        selectedMenu={selectedMenu}
        taskListActionModal={taskListActionModal}
        createTaskListModal={createTaskListModal}
        departmentHeadID={departmentHeadID}
        isSidebarView={isSidebarView}
        context={context}
        isSpecialRole={isSpecialRole}
      />
      {createTaskListModal.modal}
      {taskListActionModal.modal}
    </>
  );
};

export default TaskCardBody;
