import { CardBody, Col, Row } from "reactstrap";

import DashboardCard from "../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import NumberChange from "../../../../utils/CustomFields/NumberChange";
import React from "react";
import { calculateChangeReturnOnInvestment } from "./functions/calculateChangeReturnOnInvestment";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { v4 as uuid } from "uuid";

/**
 * Displays the overall effect of the change as a separate card
 * @param {object} item - the RiskChange object with change information
 * @param {object[]} additionalFields - additional fields to display under the change numbers
 * @param {string} [residualChangeOverrideField] - an optional override for the field used as the residual change
 * @param {string} [returnOnInvestmentOverrideField] - an optional override for the field for the return on investment
 * @param {string} [costChangeOverrideField] - an optional override for the field for the total cost change
 * @returns {JSX.Element}
 */
export const OverallEffectOfChange = ({
  item,
  additionalFields,
  residualChangeOverrideField,
  returnOnInvestmentOverrideField,
  costChangeOverrideField,
}) => {
  const change = item?.change && JSON.parse(item.change);
  const residualChange =
    residualChangeOverrideField && !isNullOrUndefined(item.hasOwnProperty(residualChangeOverrideField))
      ? item[residualChangeOverrideField]
      : change?.residualChange;
  const costChange =
    costChangeOverrideField && !isNullOrUndefined(item.hasOwnProperty(costChangeOverrideField))
      ? item[costChangeOverrideField]
      : change?.costChange;

  const returnOnInvestment =
    returnOnInvestmentOverrideField && item.hasOwnProperty(returnOnInvestmentOverrideField)
      ? item[returnOnInvestmentOverrideField]
      : calculateChangeReturnOnInvestment({ change });

  return (
    <Row>
      <Col>
        <Row style={{ marginBottom: "1em" }}>
          Change in Residual Risk:{" "}
          <NumberChange
            style={{ marginLeft: "1em" }}
            value={residualChange ?? 0}
            format="dollar"
            positiveNumber="red"
            negativeNumber="green"
          />
        </Row>
        <Row style={{ marginBottom: "1em" }}>
          {"Return on Investment: "}
          <NumberChange style={{ marginLeft: "1em" }} value={returnOnInvestment ?? 0} format="percent" />
        </Row>
        {additionalFields &&
          additionalFields.length > 0 &&
          additionalFields.map((field, index) => {
            const friendlyName = field?.friendlyName ?? "Field";
            const value = field?.name && item.hasOwnProperty(field.name) ? item[field.name] : 0;

            return (
              <Row key={uuid()} style={{ marginBottom: "1em" }}>
                {`${friendlyName}: ${value}`}
              </Row>
            );
          })}
      </Col>
    </Row>
  );
};

export const OverallEffectOfChangeCard = (props) => {
  return (
    <DashboardCard title={"Overall Effect"} icon={"icon-graph"}>
      <CardBody>
        <OverallEffectOfChange {...props} />
      </CardBody>
    </DashboardCard>
  );
};

export default OverallEffectOfChange;
