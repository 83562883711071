import React from "react";
import { useForm } from "../../../../../../hooks/views/useForm/useForm";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import { isValidUrl } from "../../../../../../utils/Functions/isValidUrl";

/**
 * @description External Url Evidence form
 * @param {function} onSubmit - function to submit the external url
 * @returns {*}
 * @constructor
 */
const ExternalUrlEvidenceForm = ({ onSubmit }) => {
  const externalUrlForm = useForm({
    fieldConfig: {
      externalUrl: {
        inputType: "textarea",
        label: "External URL",
        tooltip: "External URL for an Evidence Artifact",
        required: true,
        warningFunction: (input) => {
          if (input && !isValidUrl(input)) {
            return "Entered URL may not be valid";
          }
        },
      },
    },
    submitFunction: async (input) => {
      onSubmit &&
        onSubmit({
          type: EVIDENCE_ACTIVITY_TYPES.EXTERNAL_URL,
          externalUrl: input?.externalUrl,
        });
    },
  });

  return <div style={{ paddingTop: "1em", paddingBottom: "1em" }}>{externalUrlForm.display}</div>;
};

export default ExternalUrlEvidenceForm;
