import React from "react";
import { Redirect } from "react-router-dom";
import { getResourceRoute } from "../../definitions/functions/getResourceRoute";
import { resources } from "@rivial-security/role-utils";
import { routeType } from "../../definitions/constants/routeTypes";

const PointOfContactList = React.lazy(
  () => import("../../views/OrganizationManager/PointOfContacts/components/PointOfContactDataGrid"),
);
const PointOfContactDetails = React.lazy(
  () => import("../../views/OrganizationManager/PointOfContacts/components/PointOfContactDetails"),
);
const RiskTolerance = React.lazy(() => import("../../views/Risk/RiskConfig/RiskTolerance/components/RiskTolerance"));

const RoleList = React.lazy(() => import("../../views/OrganizationManager/Roles/components/RoleDataGrid"));
const RoleDetailsCard = React.lazy(() => import("../../views/OrganizationManager/Roles/components/RoleDetailsCard"));

const GeneralConfig = React.lazy(
  () => import("../../views/OrganizationManager/GeneralConfig/components/GeneralConfig"),
);

const Locations = React.lazy(() => import("../../views/OrganizationManager/Locations/components/LocationsGrid"));
const LocationDetails = React.lazy(
  () => import("../../views/OrganizationManager/Locations/components/LocationDetails"),
);

const Automations = React.lazy(
  () => import("../../views/OrganizationManager/Automations/components/AutomationsDashboard"),
);
const AutomationDetails = React.lazy(
  () => import("../../views/OrganizationManager/Automations/components/AutomationDetails"),
);
const CreateAutomation = React.lazy(
  () => import("../../views/OrganizationManager/Automations/components/CreateAutomation"),
);

const EmailTemplates = React.lazy(
  () => import("../../views/OrganizationManager/EmailTemplates/components/EmailTemplateDashboard"),
);
const EmailTemplateDetails = React.lazy(
  () => import("../../views/OrganizationManager/EmailTemplates/components/EmailTemplateDetails"),
);
const CreateEmailTemplate = React.lazy(
  () => import("../../views/OrganizationManager/EmailTemplates/components/EmailTemplateDetails"),
);

const Jobs = React.lazy(() => import("../../views/OrganizationManager/Jobs/components/JobsGrid"));
const JobDetails = React.lazy(() => import("../../views/OrganizationManager/Jobs/components/JobDetails"));

const Departments = React.lazy(() => import("../../views/OrganizationManager/Departments/components/DepartmentGrid"));
const DepartmentDetails = React.lazy(
  () => import("../../views/OrganizationManager/Departments/components/DepartmentDetails"),
);
const SecurityDashboard = React.lazy(
  () => import("../../views/OrganizationManager/SecurityDashboard/components/SecurityDashboard"),
);

const Questionnaires = React.lazy(
  () => import("../../views/OrganizationManager/Questionnaires/components/QuestionnaireDataGrid"),
);

const QuestionnaireDetails = React.lazy(
  () => import("../../views/OrganizationManager/Questionnaires/components/QuestionnaireDetails"),
);

// Organization Manager
export const organizationManagerRoutes = [
  // General Config
  {
    path: "/organization_manager/general_config",
    exact: true,
    name: "General Configuration",
    component: GeneralConfig,
  },

  // Points of Contact
  {
    path: "/organization_manager/contacts",
    exact: true,
    name: "Points of Contact",
    component: PointOfContactList,
  },
  {
    path: "/organization_manager/contacts/:id",
    exact: true,
    name: "Point of Contact Details",
    component: PointOfContactDetails,
  },
  {
    path: "/organization_manager/risk_tolerance",
    exact: true,
    name: "Risk Tolerance",
    component: RiskTolerance,
  },

  // Role Config
  {
    path: "/organization_manager/roles",
    exact: true,
    name: "Role Management",
    component: RoleList,
  },
  {
    path: "/organization_manager/roles/:id",
    exact: true,
    name: "Role Details Card",
    component: RoleDetailsCard,
  },

  // Questionnaires
  {
    path: getResourceRoute({
      routeType: routeType.GRID,
      typename: resources.QUESTIONNAIRE,
      prefix: "/",
    }),
    exact: true,
    name: "Questionnaires",
    component: Questionnaires,
  },
  {
    path: getResourceRoute({
      routeType: routeType.DETAILS,
      typename: resources.QUESTIONNAIRE,
      prefix: "/",
      suffix: "/:id",
    }),
    exact: true,
    name: "Questionnaire Details",
    component: QuestionnaireDetails,
  },

  // Email Templates
  {
    path: "/organization_manager/email_templates",
    exact: true,
    name: "Email Templates",
    component: EmailTemplates,
  },
  {
    path: "/organization_manager/email_templates/create_email_template",
    exact: true,
    name: "Create Email Template",
    component: CreateEmailTemplate,
  },
  {
    path: "/organization_manager/email_templates/:id",
    exact: true,
    name: "Email Template Details",
    component: EmailTemplateDetails,
  },

  // Automations
  {
    path: "/organization_manager/automations",
    exact: true,
    name: "Automations",
    component: Automations,
  },
  {
    path: "/organization_manager/automations/create_automation",
    exact: true,
    name: "Create Automation",
    component: CreateAutomation,
  },
  {
    path: "/organization_manager/automations/:id",
    exact: true,
    name: "Automation Details",
    component: AutomationDetails,
  },

  // Jobs
  {
    path: "/organization_manager/jobs",
    exact: true,
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/organization_manager/jobs/:id",
    exact: true,
    name: "Job Details",
    component: JobDetails,
  },

  // Departments
  {
    path: "/organization_manager/departments",
    exact: true,
    name: "Departments",
    component: Departments,
  },
  {
    path: "/organization_manager/departments/:id",
    exact: true,
    name: "Department Details",
    component: DepartmentDetails,
  },

  // Locations
  {
    path: "/organization_manager/locations",
    exact: true,
    name: "Locations",
    component: Locations,
  },
  {
    path: "/organization_manager/locations/:id",
    exact: true,
    name: "Location Details",
    component: LocationDetails,
  },

  // Security Dashboard
  {
    path: "/organization_manager/security_dashboard",
    exact: true,
    name: "Security Dashboard",
    component: SecurityDashboard,
  },

  {
    path: "/organization_manager",
    component: () => <Redirect to="/organization_manager/contacts" />,
  },
];
