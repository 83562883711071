import { OrganizationContext } from "../../../utils/Context/OrganizationContext";
import { checkPermissions as checkPermissionsFunc } from "@rivial-security/role-utils";
import { useContext } from "react";

/**
 * @description Checks RolePermissions for the current logged in user.
 *
 * Documentation: https://rivialsecurity.atlassian.net/l/c/u9jc5jQt
 *
 * @param {object} checkPermissions
 * @param {string} [checkPermissions.module] - The module to check permissions for.
 * @param {string} [checkPermissions.resource] - The resource to check permissions for.
 * @param {string} [checkPermissions.field] - The field to check permissions for.
 * @param {string} [checkPermissions.func] - The function to check permissions for.
 * @param {boolean} [checkPermissions.disableRoleChecking] - Whether to disable role checking. If true, will not check for role permissions.
 * @returns {{module: *, resource: *, field: *, function: *, roleConfig: *, CheckFieldPermissionsRead: (function(*=): boolean), CheckFieldPermissionsUpdate: (function(*=): boolean), CheckFuncPermissions: (function(*=): boolean)}}
 */
export const useCheckPermissions = ({ module, resource, field, func, disableRoleChecking }) => {
  const { roleConfig } = useContext(OrganizationContext);

  const res = checkPermissions({
    module,
    resource,
    field,
    func,
    roleConfig,
    disableRoleChecking,
  });

  const CheckFieldPermissionsRead = (fieldName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      field: fieldName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).field.read;
  };

  const CheckFieldPermissionsUpdate = (fieldName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      field: fieldName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).field.update;
  };

  const CheckFuncPermissions = (funcName) => {
    return checkPermissions({
      module: module,
      resource: resource,
      func: funcName,
      roleConfig: roleConfig,
      disableRoleChecking,
    }).function.isEnabled;
  };

  return {
    ...res,
    CheckFieldPermissionsRead,
    CheckFieldPermissionsUpdate,
    CheckFuncPermissions,
    roleConfig,
  };
};

export const checkPermissions = ({ module, resource, field, func, roleConfig, disableRoleChecking = false }) => {
  if (disableRoleChecking === true) {
    return {
      module: {
        isEnabled: true,
      },
      resource: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      field: {
        read: true,
        update: true,
      },
      function: {
        isEnabled: true,
      },
    };
  } else return checkPermissionsFunc({ module, resource, field, func, roleConfig });
};
