import "../styles/csvImporter.css";

import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import Grid from "@mui/material/Grid";
import { flattenArrayOfObjects, isNonEmptyArray } from "@rivial-security/func-utils";
import { getCurrentHeaders } from "./useCurrentHeaders";
import jspreadsheet from "jspreadsheet-ce";
import DragAndDrop from "../../../../utils/Tools/DragAndDrop";
import Papa from "papaparse";
import { CsvFilesList } from "@views/Testing/Vulnerabilities/hooks/useUploadVulnerabilities";
import { EventLogger } from "@utils/EventLogger/EventLogger";

/**
 * Hook that handles the file input for CSV imports.
 * Allows the User to select a Local CSV file or use the jspreadsheet table editor to create one.
 *
 * @param {ImporterField[]} fields - field configuration for the importer
 * @param {boolean} [isLoading]
 * @param {boolean} [allowMultiSelect]
 * @param {object[]} [filesInit]
 * @param {string} [sizeLimitMessage]
 */
export const useCsvSelector = ({ fields, isLoading, allowMultiSelect, files: filesInit, sizeLimitMessage }) => {
  // Get the default CSV headers from the Fields input. Used in the example file
  const csvHeader = flattenArrayOfObjects(fields, "name");

  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [table, setTable] = useState(null);
  const [currentHeaders, setCurrentHeaders] = useState([]);

  /**
   * Gets JSON data from the table and returns it as an array of objects
   */
  const getData = async () => {
    const data = table?.getJson() || [];

    // String, needs to convert to array
    const headerString = table?.getHeaders();

    // Array of header strings
    const headers = headerString?.split(",");

    // Combine the header string array and data array to have a named object array
    const resultArray = [];

    for (const item of data) {
      // First check if this is an empty row, skip empty rows
      let isEmpty = true;

      for (const field in item) {
        if (item[field] !== "" && item[field] !== null && item[field] !== undefined) {
          isEmpty = false;
        }
      }

      // If all fields are empty, skip this row
      if (isEmpty) {
        continue;
      }

      const resultObject = {};

      for (let i = 0; i < headers.length; i++) {
        resultObject[headers[i]] = item[i];
      }

      resultArray.push(item);
    }

    setData(resultArray);
    return resultArray;
  };

  /**
   * resets the table back to initial state
   */
  const resetTable = () => {
    const tableNode = document.getElementById("spreadsheet");
    if (tableNode) {
      document.getElementById("spreadsheet").innerHTML = "";
    }
  };

  /**
   * When selecting a file from the file input, this function is called,
   * resets the table and fills it to match the corresponding file
   *
   * Assumes first row is a header row
   * @param {object[]} json
   */
  const loadFromFile = (json) => {
    resetTable();

    if (!json) {
      return;
    }

    // Get array of current header strings from first row of json
    const currentHeaders = getCurrentHeaders(json);

    // Configure column definition for table based on passed in json
    const columns = currentHeaders.map((header) => {
      // See if header already matches a particular field configuration
      const fieldConfig = fields.find((field) => {
        // Check if header name matches field 'name'
        if (header === field.name) {
          return true;
        }

        // Check if header name matches field 'field'
        if (header === field.field) {
          return true;
        }

        // Check if header name matches field 'keywords'
        if (Array.isArray(field.keywords) && field.keywords.includes(header)) {
          return true;
        }

        return false;
      });

      // If found match, return field config
      if (fieldConfig) {
        const adapted = {
          type: convertFieldConfigToJspreadsheetTypes(fieldConfig) || "text",
          title: header || fieldConfig.name || fieldConfig.title,
          width: "150",
        };

        if (fieldConfig.options) {
          adapted.source = fieldConfig.options;
        }

        if (fieldConfig.autocomplete) {
          adapted.autocomplete = true;
        }

        if (fieldConfig.connectionItemList) {
          adapted.source = getSingleConnectionOptions({
            data: fieldConfig.connectionItemList,
          });
        }

        return adapted;
      }

      // If no match found, add header manually as a text column
      return {
        title: header,
        width: "150",
        type: "text",
      };
    });

    // Format a new jspreadsheet table
    const newTable = jspreadsheet(document.getElementById("spreadsheet"), {
      data: json,
      tableOverflow: true,
      minDimensions: [1, 1],
      csvHeader: true,
      defaultColWidth: "200",
      tableHeight: "50vh",
      columns,
    });

    // Set headers from CSV file
    if (typeof newTable?.setHeader === "function") {
      currentHeaders?.forEach((header, index) => newTable.setHeader(index, header));
    } else {
      EventLogger("Could not set headers on newTable:", newTable);
    }

    // Set the jspreadsheet table state
    setTable(newTable);

    setCurrentHeaders(currentHeaders);
  };

  const convertFieldConfigToJspreadsheetTypes = (fieldConfig) => {
    const jspreadsheetTypes = {
      text: "text",
      numeric: "numeric",
      hidden: "hidden",
      select: "dropdown",
      autocomplete: "autocomplete",
      checkbox: "checkbox",
      radio: "radio",
      calendar: "date",
      image: "image",
      color: "color",
    };

    if (fieldConfig?.format) {
      return jspreadsheetTypes[fieldConfig.format] || "text";
    } else {
      return "text";
    }
  };

  /**
   * Creates the CSV import table UI based on header configuration
   */
  const createNewTable = () => {
    resetTable();

    const currentHeaders = flattenArrayOfObjects(fields, "name");

    // Configure column definition for table based on field config
    const columns = currentHeaders.map((header) => {
      // See if header already matches a particular field configuration
      const fieldConfig = fields.find((field) => {
        // Check if header name matches field 'name'
        if (header === field.name) {
          return true;
        }

        // Check if header name matches field 'field'
        if (header === field.field) {
          return true;
        }

        // Check if header name matches field 'keywords'
        if (field.keywords && field.keywords.includes(header)) {
          return true;
        }

        return false;
      });

      // If found match, return field config
      if (fieldConfig) {
        const adapted = {
          type: convertFieldConfigToJspreadsheetTypes(fieldConfig),
          title: fieldConfig.name,
          width: "150",
          source: fieldConfig.options,
          autocomplete: true,
          ...fieldConfig,
        };

        if (fieldConfig.connectionItemList) {
          adapted.source = getSingleConnectionOptions({
            data: fieldConfig.connectionItemList,
          });
        }

        return adapted;
      }

      // If no match found, add header manually as a text column
      return {
        title: header,
        width: "150",
        type: "text",
      };
    });

    const newTable = jspreadsheet(document.getElementById("spreadsheet"), {
      data: [[]],
      minDimensions: [1, 10],
      tableOverflow: true,
      columns,
      columnDrag: true,
      tableHeight: "50vh",
    });

    setTable(newTable);
    setCurrentHeaders(currentHeaders);
  };

  const getDragAndDropFiles = (files) => {
    files = isNonEmptyArray(files) ? files : [];
    setFiles(files);
    if (files.length >= 1) {
      const file = files[0];
      let previewRowCount;
      if (allowMultiSelect) {
        // multiple files only supported by backend importers so only need to preview the first 10 rows
        previewRowCount = 10;
      }

      if (file) {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            loadFromFile(results.data);
          },
          preview: previewRowCount,
        });
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isNonEmptyArray(filesInit)) {
      getDragAndDropFiles(filesInit);
    } else {
      createNewTable();
    }
  }, [isLoading, filesInit]);

  /**
   * Display main CSV importer UI
   */
  const display = (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <span>
          <span>
            {csvHeader && (
              <CSVLink data={[csvHeader]} filename="Rivial_Platform_Import_Example.csv">
                <i className={"icon-cloud-download"} /> Download Example CSV
              </CSVLink>
            )}
          </span>
          {!allowMultiSelect && (
            <span className={"float-right"}>
              <Button
                onClick={() => {
                  createNewTable();
                }}
                startIcon={<i className={"icon-refresh"} />}
                style={{ marginLeft: "1em" }}
              >
                Reset Table
              </Button>
            </span>
          )}
        </span>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DragAndDrop
          key={"drag-and-drop-csv-importer"}
          acceptFileFormat="csv"
          callback={getDragAndDropFiles}
          sizeLimit={300 * 1024 * 1024} // 300mb
          sizeLimitMessage={sizeLimitMessage}
          files={filesInit}
          allowMultiSelect={allowMultiSelect}
        />
      </Grid>
      {allowMultiSelect && isNonEmptyArray(files) && (
        <div style={{ display: "flex", flexDirection: "row", gap: ".5em", margin: "16px" }}>
          <p> Files: </p>
          <CsvFilesList files={files} disableRemoveItems={true} />
        </div>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {allowMultiSelect && <p> Preview: </p>}
        <div id="spreadsheet" style={{ width: "100%", overflow: "auto" }} />
      </Grid>
    </Grid>
  );

  return {
    currentHeaders,
    display,
    data,
    getData,
    files,
    createNewTable,
  };
};

const getSingleConnectionOptions = ({ data, nameField = "name" }) => {
  return data?.map((item) => ({
    id: item[nameField], // doing this so that we can use the dropdown with copy + paste and selected files
    name: item[nameField],
  }));
};
