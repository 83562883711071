/**
 * Author: Anatoli Railean
 * Created At: 11/17/19
 * Description: Deletes a Policy resource in the DB.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { DeletePolicyVersion } from "./DeletePolicyVersion";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deletePolicy } = generateGraphql("Policy");

export const DeletePolicy = async (policy) => {
  policy &&
    policy.policyVersions &&
    policy.policyVersions.items &&
    policy.policyVersions.items.length > 0 &&
    policy.policyVersions.items.map((item) => {
      DeletePolicyVersion(item);
    });

  API.graphql(
    graphqlOperation(deletePolicy, {
      input: {
        id: policy.id,
      },
    }),
  ).then(({ data }) => {
    if (data && data.deletePolicy) {
      EventLogger(`Policy ${data.deletePolicy.id} was Successfully Deleted`);
    }
  });
};
