import { EventLogger } from "../EventLogger/EventLogger";
import { GetQuery } from "./Graphql/GetQuery";

/**
 * Frontend Graphql call for the genericScheduleEmail Lambda.
 * @param {object} input - input for genericScheduleEmail Lambda
 * @returns {Promise<Array>}
 */

export const genericScheduleEmail = async ({ input }) => {
  if (!input) {
    EventLogger("[genericScheduleEmail.js] Missing the input object");
  }

  const genericScheduleEmailQuery = /* GraphQL */ `
    query GenericScheduleEmail($input: AWSJSON) {
      genericScheduleEmail(input: $input)
    }
  `;

  EventLogger(`Sending request to genericScheduleEmail Lambda. Input: ${JSON.stringify(input)}`);

  return await GetQuery({
    query: genericScheduleEmailQuery,
    variables: {
      input: JSON.stringify(input),
    },
  });
};
