import { useCsvImporter } from "../../../../../CsvImporters/hooks/useCsvImporter";
import React, { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useControlSetDataGrid } from "../../../ControlSets/hooks/useControlSetDataGrid";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { UIContext } from "../../../../../../utils/Context/UIContext";
import { usePleaseWaitModal } from "../../../../../../hooks/views/usePleaseWaitModal";
import { handleUploadControls } from "./handleUploadControls";
import { getControlFramework } from "./getControlFramework";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { modules, resources } from "@rivial-security/role-utils";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { listControlsByControlSet } from "../../../../../Reports/BoardReport/graphql/__boardReportGQL";
import ImportButton from "../../../../../../utils/GenericComponents/ImportButton";

/**
 * @description Import controls from CSV file
 * @param {string} organizationID - selected organization
 * @param {string} frameworkOrganizationID - the framework for which to upload controls
 * @param {function} resetFunction - the function to call after the upload is complete to refresh underlying data
 * @param {function} toggleModal - the function to call to close all parent modals
 * @returns {object} {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}
 */
export const useImportControl = ({ organizationID, frameworkOrganizationID, resetFunction, toggleModal }) => {
  const module = modules.COMPLIANCE;

  const { addToast, updateToast } = useContext(UIContext);

  const pleaseWaitModal = usePleaseWaitModal();

  const [controlFramework, setControlFramework] = useState(null);
  const [frameworkCustomFields, setFrameworkCustomFields] = useState([]);
  const [headerObjects, setHeaderObjects] = useState([]);

  /**
   * Controls frameworks grid
   */
  const controlSetGrid = useControlSetDataGrid({
    organizationID: frameworkOrganizationID || organizationID,
    enableSelectButton: true,
    onSelectCallback: (selectedControlSet) => {
      getControlFramework({
        controlFramework: selectedControlSet,
        setControlFramework,
        setFrameworkCustomFields,
        setHeaderObjects,
      });
    },
  });

  /**
   * Process row controls after submit
   */
  const handleSubmit = async (data) => {
    pleaseWaitModal.setModalIsOpen(true);

    //Adds any new controls to the control set and updates existing ones
    try {
      await handleUploadControls({
        data,
        frameworkCustomFields,
        controlFramework,
        addToast,
        updateToast,
        organizationID,
      });
      if (typeof resetFunction === "function") resetFunction();
    } catch (e) {
      EventLogger("Error: Could not create controls:");
      EventLogger(e);
    }

    if (typeof toggleModal === "function") toggleModal();
    modal.setModalIsOpen(false);
    pleaseWaitModal.setModalIsOpen(false);
  };

  const fieldMappings = {
    "Statement Number": "statementNumber",
    "In Place": "inPlace",
    Notes: "notes",
    Name: "name",
    "Control Set": "controlSet",
    Tags: "tags",
  };

  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Controls",
    passThroughDuplicateScanner: true,
    getDataCallback: handleSubmit,
    dictionary: fieldMappings,
  });

  //Load source information into csv uploader for duplicates scanner
  useEffect(() => {
    if (controlFramework?.id) {
      csvImporter.initSourceQuery(
        ListQueryBy({
          query: listControlsByControlSet,
          limit: 1000,
          variables: {
            ownerGroup: organizationID,
            controlControlSetId: {
              eq: controlFramework.id,
            },
          },
        }),
        organizationID,
      );
    }
  }, [controlFramework]);

  /**
   * Permissions for displaying control framework grid
   */
  const controlSetPermissions = useCheckPermissions({
    module,
    resource: resources.CONTROL_FRAMEWORK,
  });

  /**
   * Permissions for displaying control importer
   */
  const controlImportPermissions = useCheckPermissions({
    module,
    resource: resources.CONTROL,
  });

  const modal = useModal(
    "Import Controls",
    <div>
      {controlFramework ? (
        ""
      ) : (
        <div>
          <h4>Select a Control Framework</h4>
          <br />
        </div>
      )}
      {controlSetPermissions.resource.create && <div style={{ height: "30em" }}>{controlSetGrid.display}</div>}
      {controlFramework?.name && (
        <div>
          <br />
          Selected Control Framework: <strong>{controlFramework?.name}</strong>
        </div>
      )}
      <br />
      {controlImportPermissions.resource.create && controlFramework && csvImporter.display}
      {pleaseWaitModal.modal}
    </div>,
    <ImportButton />,
    { width: "90vw" },
  );

  return { ...modal };
};
