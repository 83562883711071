import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { deleteControl } from "../../Controls/functions/deleteControl";

/**
 * Deletes an Existing Control Set with all of it's Controls
 * Also deletes linking between Controls and Evidence
 *
 * @param {Object} controlSet - Control Set to Delete
 * @param {string} controlSet.id - ID of the control set to delete
 */
const deleteControlSet = async (controlSet) => {
  if (!controlSet || !controlSet.id) {
    return null;
  }

  const { getQuery } = generateGraphql("ControlSet", ["controls", "__typename"], {
    controls: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
  });

  return await QueryGetItem({ query: getQuery, itemId: controlSet.id }).then(async (controlSet) => {
    const controls = controlSet?.controls?.items;
    const deleteRequests = [];
    if (Array.isArray(controls)) {
      for (let i = 0; i < controls.length; i++) {
        //Delete controls in chunks to avoid 429 (too many requests) errors
        deleteRequests.push(deleteControl(controls[i]));
        if (i % 20 === 0) {
          await Promise.allSettled(deleteRequests);
          deleteRequests.length = 0;
        }
      }
    }

    //Delete all remaining controls for the control set (and its links)
    await Promise.allSettled(deleteRequests);

    await GenericDeleteGQL({
      objectToDelete: {
        id: controlSet.id,
        __typename: "ControlSet",
      },
    }).then(() => EventLogger(`Control Framework was Successfully Deleted!`));
  });
};

export default deleteControlSet;
