import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import GenericEditField from "../../../../../utils/GenericComponents/GenericEditField";
import { useModal } from "../../../../../hooks/views/useModal";
import { ApprovePolicyVersion } from "../../functions/ApprovePolicyVersion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCheckPermissions } from "../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDocumentEditor } from "../../../../Reports/DocumentEditor/hooks/useDocumentEditor";
import { ViewPolicyVersion } from "../../components/ViewPolicyVersion";
import AssignReviewer from "../../components/AssignReviewer";
import AddSinglePolicyVersion from "../../components/AddSinglePolicyVersion";
import { modules, resources } from "@rivial-security/role-utils";
import { PolicyVersionStatus as PolicyVersionStatusEnum } from "../../constants/PolicyVersionStatus";
import PolicyVersionStatus from "../../components/PolicyVersionStatus";
import { AV_STATUS } from "../../../../../enums/AV_STATUS";
import { useSetAsyncData } from "../../../../../hooks/functional/useSetAsyncData";
import { getPolicyVersionFromS3 } from "../../functions/getPolicyVersionFromS3";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../../../../../utils/LoadingComponents/Loader";
import MuiItemDownloadButton from "../../../../../utils/GenericComponents/buttons/MuiItemDownloadButton";
import NotEnoughData from "../../../../../utils/GenericComponents/NotEnoughData";
import { GetQuery } from "@rivial-security/appsync-utils";
import { selectShownPolicyVersion } from "./functions/selectShownPolicyVersion";

const { getQuery: getPolicy } = generateGraphql("Policy", ["name", "policyVersions"], {
  policyVersions: `(limit: 100) { items { id version createdAt status description approvedBy avStatus lastAVCheck approvalDate reviewers { items { id status pointOfContact { id firstName lastName email }  } } file { key bucket region } } }`,
});

/**
 * Hook allowing user to update the status and view individual policy information
 * @param {string} organizationID
 * @param {string} itemId
 * @param {function} setQueryRef
 * @param {function} setIsLoading
 * @param {JSX.Element[]} headerButtonsInit
 * @param {string} uploadVersionShortText
 */
export const usePolicyDetails = ({
  organizationID,
  itemId,
  setIsLoading,
  headerButtons: headerButtonsInit,
  uploadVersionShortText,
}) => {
  const module = modules.GOVERNANCE;
  const resource = resources.POLICY;

  const checkPermissionsHook = useCheckPermissions({ module, resource });
  const hasAccess = checkPermissionsHook.resource.read === true;

  const [policy, setPolicy] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  const [highestVersionNumber, setHighestVersionNumber] = useState(0);
  const [url, setUrl] = useState(undefined);
  const [approvedBy, setApprovedBy] = useState("");
  const [approvalDate, setApprovalDate] = useState(new Date());
  const [ignoreNullAntivirusStatusForPreview, setIgnoreNullAntivirusStatusForPreview] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item) => {
    setVersion(item);
    setIgnoreNullAntivirusStatusForPreview(false);
    handleClose();
  };

  const { isLoading: isLoadingPolicy, resetFunction } = useSetAsyncData({
    getData: async () => {
      if (!hasAccess) {
        return null;
      }

      return await GetQuery({
        query: getPolicy,
        variables: { id: itemId },
      });
    },
    setData: setPolicy,
    setIsLoading,
    dependencies: [itemId],
  });

  /**
   * Gets the status color for the Policy Version
   * @param item
   * @return {string}
   */
  const getStatusStyle = (item) => {
    let statusColor;
    switch (item?.status) {
      case AV_STATUS.INFECTED:
        statusColor = "error";
        break;
      case PolicyVersionStatusEnum.DRAFT:
        statusColor = "#FFC107";
        break;
      case PolicyVersionStatusEnum.APPROVED:
        statusColor = "#4DBD74";
        break;
      case PolicyVersionStatusEnum.OLD:
        statusColor = "#C8CED3";
        break;
      default:
        statusColor = "#C8CED3";
        break;
    }
    return statusColor;
  };
  /**
   * Allows the user to select a specific Policy Version to view
   */
  const versionSettingsDropdown = (
    <div>
      <Button onClick={handleClick}>
        <Typography
          variant="body1"
          sx={{
            backgroundColor: "#fff",
            padding: ".2em 1em",
            textTransform: "none",
            borderRadius: "3px",
          }}
        >
          Versions
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ padding: "0" }}
      >
        {policy?.policyVersions?.items
          .sort((a, b) => b.version - a.version)
          .map((item) => (
            <MenuItem
              key={item.id || item.version}
              onClick={() => handleItemClick(item)}
              style={{ backgroundColor: getStatusStyle(item) }}
              disabled={item?.avStatus === AV_STATUS.INFECTED}
              title={`Description: ${item.description} | Status: ${item.status}`}
              divider={true}
            >
              {item?.avStatus === AV_STATUS.INFECTED
                ? "Document is infected"
                : `Version ${item?.version} - ${item?.status}`}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );

  /**
   * Gets the PolicyVersion file from S3
   */
  const { isLoading: isLoadingVersion } = useSetAsyncData({
    getData: async () => {
      const policyVersions = policy?.policyVersions?.items;
      if (!isNonEmptyArray(policyVersions)) {
        return {
          shownPolicyVersion: undefined,
          versionURL: undefined,
        };
      }

      const shownPolicyVersion = selectShownPolicyVersion(policyVersions);
      const versionURL = await getPolicyVersionFromS3({ version: shownPolicyVersion, organizationID });

      return {
        shownPolicyVersion,
        versionURL,
      };
    },
    setData: ({ shownPolicyVersion, versionURL }) => {
      setUrl(versionURL);
      setVersion(shownPolicyVersion);
      if (shownPolicyVersion) {
        setHighestVersionNumber(parseInt(shownPolicyVersion.version) + 1);
      }
    },
    defaultValue: { shownPolicyVersion: null, versionURL: null },
    dependencies: [policy],
    skipUpdate: (policy) => {
      return !policy?.id;
    },
  });

  const [headerButtons, setHeaderButtons] = useState(headerButtonsInit);

  const isSubmitEnabled =
    version && version.status !== PolicyVersionStatusEnum.OLD && version.status !== PolicyVersionStatusEnum.APPROVED;

  /**
   * Allows the user to Approve a draft policy version
   */
  const ApprovePolicyVersionModal = useModal(
    "Approve Policy Version",
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">Approved By:</Typography>
          </Grid>
          <Grid item xs={6}>
            <input
              type="text"
              id="approvedBy"
              value={approvedBy}
              onChange={(data) => setApprovedBy(data?.target?.value)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1">Approval Date:</Typography>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a Date and Time"
              showTimeInput
              minDate={new Date()}
              maxDate={new Date().setMonth(new Date().getMonth() + 24)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={new Date(approvalDate)}
              onChange={(date) => setApprovalDate(date)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<CheckCircleIcon sx={{ marginRight: ".5em" }} />}
          onClick={() => {
            ApprovePolicyVersion(version, policy?.policyVersions?.items || [], approvedBy, approvalDate).then(() =>
              resetFunction(),
            );
          }}
          disabled={!approvedBy}
        >
          Approve Policy Version
        </Button>
      </CardActions>
    </Card>,
    <Button
      color="success"
      size="small"
      disabled={!isSubmitEnabled}
      startIcon={<CheckCircleIcon sx={{ marginRight: "0.2em" }} />}
    >
      Approve Policy Version
    </Button>,
    null,
  );

  /**
   * Allows the user to upload a new Policy Version as a draft
   */
  const addSinglePolicyVersionModal = useModal(
    "Upload New Version of Policy",
    <AddSinglePolicyVersion
      organizationID={organizationID}
      policyID={itemId}
      highestVersionNumber={highestVersionNumber}
      setHighestVersionNumber={setHighestVersionNumber}
      resetFunction={resetFunction}
    />,
    <Button startIcon={<CloudUploadIcon style={{ marginRight: "0.2em" }} />} color="success" size="small">
      {!uploadVersionShortText && <> Upload New Version </>}
    </Button>,
    null,
  );

  const [showDocumentEditor, setShowDocumentEditor] = useState(false);

  const documentEditor = useDocumentEditor({
    organizationID: organizationID,
  });

  const getPolicyContent = () => {
    if (isLoadingVersion) {
      return <Loader text={"Loading policy version document..."} />;
    }

    if (!url || !version) {
      return "Unable to load policy version content.";
    }

    if (version?.avStatus === AV_STATUS.INFECTED) {
      return "The preview for this policy is not available because it is infected with a virus.";
    }

    if (version?.avStatus !== AV_STATUS.CLEAN && !ignoreNullAntivirusStatusForPreview) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <NotEnoughData
            message={`The preview for this policy version does not load automatically until after it has been scanned
            for viruses. For small files (under 50MB), this typically takes several minutes.`}
            callToAction={{
              message: " to preview the unscanned file anyways.",
              size: "md",
              function: () => {
                setIgnoreNullAntivirusStatusForPreview(true);
              },
            }}
          />
        </div>
      );
    }

    if (version && url) {
      return <ViewPolicyVersion url={url} />;
    }

    return "No approved policy versions yet!";
  };

  let display;
  if (isLoadingPolicy) {
    display = <Alert severity={"info"}>Loading Policy...</Alert>;
  } else if (!hasAccess) {
    display = <Alert severity={"warning"}>No read access to Policy resource.</Alert>;
  } else if (!policy) {
    display = <Alert severity={"error"}>Policy not found, it could have been deleted.</Alert>;
  } else {
    display = (
      <Card>
        <CardContent
          style={{
            backgroundColor: "#F0F3F5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: ".5em",
              flexDirection: "row",
            }}
          >
            {headerButtons?.length > 0 && headerButtons.map((button) => React.cloneElement(button))}
            {checkPermissionsHook.resource.update && addSinglePolicyVersionModal.modalButton}
            <MuiItemDownloadButton
              item={version}
              organizationID={organizationID}
              itemDisplayName={"Policy"}
              showDisplayNameInButtonText={true}
            />{" "}
            {checkPermissionsHook.resource.update && isSubmitEnabled && ApprovePolicyVersionModal.modalButton}{" "}
            {checkPermissionsHook.resource.read && version && (
              <AssignReviewer policyVersion={version} policy={policy} organizationID={organizationID} />
            )}{" "}
          </div>
          <div>{checkPermissionsHook.resource.read && versionSettingsDropdown}</div>
        </CardContent>

        <CardContent>
          <Grid container justifyContent="center">
            <Grid item xs={12} textAlign="center">
              <Box borderBottom={"1px solid #E5E5E5"}>
                <Typography variant="h5">
                  <label htmlFor="name">Policy Name: </label>{" "}
                  <strong>
                    <GenericEditField item={policy} field="name" mutation="updatePolicy" />
                  </strong>
                </Typography>
              </Box>
            </Grid>

            {version && (
              <>
                <Grid item xs={12} textAlign="center">
                  <Box borderBottom={"1px solid #E5E5E5"}>
                    <Typography variant="h6">Version: {version?.version ? version.version : null}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Box borderBottom={"1px solid #E5E5E5"}>
                    <Typography variant="h6">
                      Status: <PolicyVersionStatus version={version} />
                    </Typography>
                  </Box>
                </Grid>
                {version.approvedBy && version.approvalDate && (
                  <>
                    <Grid item xs={12} textAlign="center">
                      <Box borderBottom={"1px solid #E5E5E5"}>
                        <Typography variant="h6">
                          Approved By: {version.approvedBy ? version.approvedBy : null}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Box borderBottom={"1px solid #E5E5E5"}>
                        <Typography variant="h6">
                          Approved Date: {version.approvalDate ? new Date(version.approvalDate).toLocaleString() : null}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} textAlign="center">
                  <Typography variant="body1">
                    {version.description && (
                      <p>
                        <strong>Description</strong>: {version.description}
                      </p>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button color="primary" size="small">
                    {version?.approvedBy && version?.approvalDate && (
                      <Link
                        title="Go to Acknowledgement Page"
                        href={`#/governance/review_policy/${version.id}`}
                        variant="body1"
                        hover={"none"}
                        sx={{ textDecoration: "none" }}
                      >
                        Acknowledge
                      </Link>
                    )}
                  </Button>
                </Grid>
                <hr />
              </>
            )}
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={12}>
              {showDocumentEditor ? documentEditor.display : getPolicyContent()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return {
    display,
    showDocumentEditor,
    setShowDocumentEditor,
    documentEditor,
    headerButtons,
    setHeaderButtons,
    highestVersionNumber,
    setHighestVersionNumber,
    setVersion,
    reset: resetFunction,
  };
};
