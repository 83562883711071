import { EventLogger } from "../../EventLogger/EventLogger";
import { GetQuery } from "../Graphql/GetQuery";
import { createJob } from "../../../views/OrganizationManager/Jobs/functions/createJob";
import { waitForJobToComplete } from "../../../views/OrganizationManager/Jobs/functions/waitForJobToComplete";
import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../hooks/graphql/useQueryGetItem";

/**
 * @description Zip andDownloadFiles
 * @example zipAndDownloadFiles({
      zipFileName: String,
      fileStructure: Objects,
      bucket: String,
      folder: String
 * }).then((data) => console.log(data))
 * @param {object} input - input object from graphql call
 * @param {string} input.organizationID - organization id to get bucket permissions and use to get and save files
 * @returns {Promise<Array>}
 */
export const zipAndDownloadFiles = async (input) => {
  const queryLambdaGraphql = `
    query zipAndDownloadFiles($input: AWSJSON) {
      zipAndDownloadFiles(input: $input)
    }
  `;

  EventLogger(`Sending request to zipAndDownloadFiles lambda. Input: ${JSON.stringify(input)}`);

  /**
   * Create a job
   */
  const job = await createJob({
    name: "Create zip archive job",
    status: "started",
    ownerGroup: input?.organizationID,
    type: "zipAndDownloadFiles",
  });

  /**
   * Send request to the backend
   */
  const res = await GetQuery({
    query: queryLambdaGraphql,
    variables: {
      input: JSON.stringify({ ...input, job }),
    },
  });

  let result = res;

  if (res?.statusCode === 400) {
    EventLogger("It takes longer to zip and download files than expected. Subscribing to the job status updates");

    try {
      const body = res?.body && JSON.parse(res.body);
      if (
        Array.isArray(body?.errors) &&
        body?.errors?.length > 0 &&
        body?.errors[0]?.message === "Execution timed out."
      ) {
        /**
         * Wait for the job to complete
         */
        await waitForJobToComplete({
          job,
          timeout: 900000,
        });

        const { getQuery } = generateGraphql("Job", ["status", "result", "logs"]);

        const item = await QueryGetItem({
          query: getQuery,
          itemId: job?.id,
        });
        if (item?.status === "completed" && item?.result) {
          result = item?.result;
        } else {
          EventLogger("Error while waiting for the job to complete");
          return;
        }
      }
    } catch (e) {
      EventLogger("Can not get response from the zipAndDownloadFiles lambda", e);
    }
  }

  try {
    return JSON.parse(result);
  } catch (e) {
    EventLogger("Can not get response from the zipAndDownloadFiles lambda", e);
  }
};
