import React, { useEffect, useState } from "react";
import { controlCustomFields, controlFieldNameDictionary, controlFields, controlNestedFields } from "./constants";
import { modules, resources } from "@rivial-security/role-utils";

import AddOrRemoveIcon from "../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import AssociatedEvidence from "../../customFields/AssociatedEvidence/AssociatedEvidence";
import AutomationsButton from "../../../../../OrganizationManager/Automations/components/AutomationsButton";
import { Button } from "reactstrap";
import ControlAudits from "../../customFields/ControlAudits/ControlAudits";
import ControlEvidenceLinkImporterButton from "../../../../ControlEvidenceLinks/components/ControlEvidenceLinkImporterButton";
import ControlEvidenceLinking from "../../../../ControlEvidenceLinks/components/ControlEvidenceLinking";
import ControlFindingsCard from "../../components/ControlFindings";
import CustomFieldEditor from "../../customFields/CustomFieldEditor/CustomFieldEditor";
import Dashboard from "../../../../../../utils/GenericComponents/Dashboard";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import Grid from "@mui/material/Grid";
import { ItemQuery } from "../../../../../../utils/Functions/Graphql/ItemQuery";
import KeyPerformanceIndicators from "../../../../../Risk/RiskConfig/ControlCategories/components/SubControls/customFields/KeyPerformanceIndicators";
import { Button as MuiButton } from "@mui/material";
import { TEMPLATE } from "../../../../../../enums/TEMPLATE";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAccordion } from "../../../../../../hooks/views/useAccordion/useAccordion";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useOrganizationContext } from "../../../../../AdminPanel/Organizations/hooks/useOrganizationContext";

/**
 * Custom hook for displaying the details of a Control
 * @param {string} itemId - The id of the Control to display
 * @param {string} organizationID - The id of the organization the Control belongs to
 * @param {boolean} tableDisplay - Whether or not the Control is being displayed in a table
 * @param {function} resetFunction - A function to reset the state of the parent component
 * @param {boolean} isLoading - Whether or not the parent component is loading
 * @param {object} props - Any other props to pass to the component
 * @returns {{isLoading, setInputId, inputId, item, display: *, reset, setItem}}
 */
export const useControlDetailsAccordion = ({
  itemId,
  organizationID,
  tableDisplay,
  resetFunction,
  isLoading,
  ...props
}) => {
  ///[GENERAL SETUP]
  const context = useOrganizationContext();
  // - Constants
  const originOrganizationID = context?.role?.ownerGroup;
  const isTemplate = organizationID === TEMPLATE || props?.location?.pathname.includes("admin_panel");
  const module = isTemplate ? modules.ADMINISTRATOR : modules.COMPLIANCE;
  const resource = isTemplate ? resources.CONTROL_TEMPLATE : resources.CONTROL;
  const typename = "Control";
  // - State
  const [showKPIs, setShowKPIs] = useState(false);
  const [addonFields, setAddonFields] = useState([]);
  const [addonCustomFields, setAddonCustomFields] = useState([]);
  const [addonFieldNameDictionary, setAddonFieldNameDictionary] = useState({});
  // - Permissions
  const controlPermissions = useCheckPermissions({ module, resource });
  // - Queries
  const { getQuery: controlGetQuery, updateMutation: controlUpdateMutation } = generateGraphql(
    typename,
    controlFields,
    controlNestedFields,
  );

  ///[DETAILS SETUP]
  const queryConfig = {
    query: controlGetQuery,
    itemId,
  };

  const detailsFields = [
    "statementNumber",
    "name",
    "controlSet",
    "inPlace",
    "isDisabled",
    "evidences",
    "customFieldData",
    "changes",
    "tags",
    "notes",
  ].concat(addonFields);

  const detailsConfig = {
    fields: detailsFields,
    customFields: controlCustomFields({
      isTemplate,
      originOrganizationID,
      organizationID,
    }).concat(addonCustomFields),
    fieldNameDictionary: {
      ...controlFieldNameDictionary,
      ...addonFieldNameDictionary,
    },
    updateMutation: controlUpdateMutation,
  };

  const detailsHook = useDetailsCard({
    module,
    resource,
    tableDisplay,
    queryConfig,
    detailsConfig,
    config: {
      enableNotes: true,
      observationConnectionField: "complianceControlID",
      headerButtons: [
        <Button
          color="ghost-primary"
          size="sm"
          onClick={() => setShowKPIs(!showKPIs)}
          disabled={!controlPermissions.resource.read}
          title={
            controlPermissions.resource.read
              ? "Show Key Performance Indicators"
              : "You don't have access to type 'Control'"
          }
        >
          {showKPIs ? "Hide" : "Show"} KPIs
          <i className="icon-graph" style={{ marginLeft: "0.5em" }} />
        </Button>,
      ],
    },
    ...props,
  });

  ///[DETAILS CUSTOM FIELDS]
  // TODO: when loading display a component attachment saying that custom fields are still loading use the null value of state
  const { getQuery: getControlFrameworkQuery } = generateGraphql("ControlSet", ["name", "customFields"], {
    customFields: `{name type options { label value } multipleSelect { label value } numberSettings { min max step format } }`,
  });
  // Handles retrieval of shown custom fields by looking at control framework fields
  const updateShownAddonFields = async (controlItem) => {
    //Show no fields if no control framework id is present
    let selectedControlFrameworkId = "";
    if (!controlItem?.controlSet?.id) {
      EventLogger("No control set found in control item to display custom fields");
      return;
    } else {
      selectedControlFrameworkId = controlItem.controlSet.id;
    }

    //Check to see if control framework of current control exist (retrieve all custom field info)
    let customFrameworkFields = [];
    try {
      const controlSet = await ItemQuery(getControlFrameworkQuery, selectedControlFrameworkId);
      if (controlSet?.customFields && Array.isArray(controlSet.customFields)) {
        customFrameworkFields = controlSet.customFields;
      }
    } catch (e) {
      EventLogger("Could not retrieve control set data for control details!", e);
      return;
    }

    //Check to see if custom fields property exists on this control
    if (!controlItem?.customFieldData) {
      return;
    }

    //Parse the custom field json from the control
    let controlCustomFields = {};
    try {
      controlCustomFields = JSON.parse(controlItem.customFieldData);
    } catch (e) {
      EventLogger("Could not retrieve control set data for control details!", e);
      return;
    }

    //Show all of control frameworks fields as additional fields for the control (update customFields state)
    const newAddonFields = [];
    const newAddonCustomFields = [];
    const newAddonFieldNameDictionary = {};
    for (const field of customFrameworkFields) {
      if (field?.name) {
        newAddonFields.push(field.name);
        newAddonCustomFields.push({
          field: field.name,
          isDynamicCustomField: true, //roles not yet implemented (field will be added even if not selected)
          component: (
            <CustomFieldEditor
              module={module}
              resource={resource}
              config={field}
              data={controlCustomFields.hasOwnProperty(field.name) ? controlCustomFields[field.name] : undefined}
            />
          ),
        });
        // Want to preserve the format of the field that the user typed in
        newAddonFieldNameDictionary[field.name] = field.name;
      }
    }

    setAddonFields(newAddonFields);
    setAddonCustomFields(newAddonCustomFields);
    setAddonFieldNameDictionary(newAddonFieldNameDictionary);
  };

  // Handles the changes in displayed control item by readjusting the displayed custom fields
  useEffect(() => {
    if (detailsHook?.item) {
      setAddonFields([]);
      setAddonCustomFields([]);
      setAddonFieldNameDictionary({});
      const item = detailsHook.item;
      updateShownAddonFields(item);
    }
  }, [detailsHook.item]);

  const rowStyle = {
    marginBottom: "1.0em",
  };

  const linkingModal = useModal(
    <>
      Link / Unlink Evidences for this Control
      <ControlEvidenceLinkImporterButton
        organizationID={organizationID}
        controlOrganizationID={organizationID}
        evidenceOrganizationID={organizationID}
      />
    </>,
    <ControlEvidenceLinking
      type={"control"}
      organizationID={organizationID}
      control={detailsHook.item}
      resetFunction={detailsHook.reset}
    />,
    <MuiButton className="float-right" title="Link / Unlink Evidence for this Control">
      <AddOrRemoveIcon />
    </MuiButton>,
    {
      width: "80vw",
    },
  );

  const accordion = useAccordion({
    organizationID,
    details: detailsHook,
    items: [
      {
        id: "control-details-evidence",
        title: "Evidence",
        subTitle: "Artifacts that prove that this Control is operating effectively",
        component: (
          <AssociatedEvidence
            disablePointOfContactAssign={true}
            resetFunction={detailsHook?.resetFunction}
            isLoading={detailsHook?.isLoading}
          />
        ),
        icon: "icon-badge",
        defaultExpanded: true,
        headerButtons: controlPermissions.resource.update ? [linkingModal.modalButton] : undefined,
        //Prevent unmounting of the grid to not have to re-query evidence link data
        unmountOnExit: false,
      },
      {
        id: "control-details-kpis",
        title: "Key Performance Indicators",
        subTitle: "Performance thresholds rolled up from Associated Evidence",
        component: <KeyPerformanceIndicators control={detailsHook?.item} />,
        icon: "icon-graph",
      },
      {
        id: "control-details-findings",
        title: "Findings",
        subTitle: "Observations, Recommendations, and Action Items",
        icon: "icon-eye",
        component: <ControlFindingsCard module={module} />,
      },
      {
        id: "control-details-audits",
        title: "Audits",
        subTitle: "Point in time evaluations of this Control",
        icon: "icon-film",
        unmountOnExit: false,
        component: <ControlAudits resetFunction={detailsHook?.resetFunction} isLoading={detailsHook?.isLoading} />,
      },
    ],
  });

  ///[RENDERING]
  const display = (
    <Dashboard
      resetFunction={detailsHook.reset}
      id={"control-details-dashboard"}
      headerButtons={[<AutomationsButton key={0} itemId={itemId} typename={"Control"} />]}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DashboardCard
            title="General Information"
            style={{ height: "100%" }}
            id={"control-details-general-information"}
          >
            {detailsHook.tableDisplay}
          </DashboardCard>
        </Grid>
        <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
          {accordion.display}
        </Grid>
      </Grid>
    </Dashboard>
  );

  return {
    ...detailsHook,
    ...accordion,
    display,
  };
};
