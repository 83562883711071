import React, { useEffect, useState } from "react";
import { usePleaseWaitModal } from "../../hooks/views/usePleaseWaitModal";
import { generateSFDTFromURL } from "@rivial-security/report-utils";
import { EventLogger } from "../EventLogger/EventLogger";
import {
  DocumentEditorComponent,
  Editor,
  Selection,
  SfdtExport,
  WordExport,
} from "@syncfusion/ej2-react-documenteditor";
import { useGUID } from "../../hooks/functional/useGUID";

DocumentEditorComponent.Inject(WordExport, SfdtExport, Selection, Editor);

/**
 * @description Document Viewer hook
 * @param {string} url - url of the .doc document
 * @param {string} fileType - file extension
 * @param {boolean} enableDownload - enable download button
 * @param {string} fileName - file name to download
 * @param {string} viewerHeight - passed through as height prop to viewer
 * @returns {JSX.Element}
 */
export const useDocumentViewer = ({ url, fileType, enableDownload, viewerHeight, fileName = "document" }) => {
  const [id] = useGUID();
  const documentViewerId = `document-viewer-${id}`;

  const [ref, setRef] = useState(null);

  const waitModal = usePleaseWaitModal();

  const viewDocument = async () => {
    if (!ref || !url) {
      return;
    }

    try {
      waitModal.setModalIsOpen(true);
      const isSFDT = fileType === "sfdt";
      const sfdt = await generateSFDTFromURL({ url, isSFDT });
      ref.open(sfdt);
    } catch (e) {
      EventLogger(e);
    } finally {
      waitModal.setModalIsOpen(false);
    }

    // Download document if enabled
    enableDownload && ref?.save(fileName, "Docx");
  };

  useEffect(() => {
    viewDocument();
  }, [ref, url]);

  const display = (
    <div style={{ height: "100%", overflowX: "hidden", overflowY: "hidden" }}>
      {waitModal.modal}
      <DocumentEditorComponent
        id={documentViewerId}
        ref={(r) => setRef(r)}
        isReadOnly={true}
        height={viewerHeight || "85vh"}
        enableSelection={true}
        enableEditor={true}
        enableSfdtExport={true}
        enableWordExport={true}
      />
    </div>
  );

  return {
    display,
    ref,
  };
};
