import React, { useEffect, useState } from "react";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { CustomInput } from "reactstrap";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

export const getOrganizationConfig = async ({ organizationID }) => {
  const getOrganization = /* GraphQL */ `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        config
      }
    }
  `;

  if (organizationID) {
    try {
      const organization = await QueryGetItem({
        query: getOrganization,
        itemId: organizationID,
      });

      organization["config"] = JSON.parse(organization?.config);

      return organization?.config;
    } catch (e) {
      EventLogger("Error! Can not parse organization config object", e);
    }
  }
};

export const getOrganizationConfigValue = async ({ organizationID, key, withConfig = false }) => {
  if (organizationID) {
    const organizationConfig = await getOrganizationConfig({ organizationID });

    if (organizationConfig) {
      if (organizationConfig.hasOwnProperty(key)) {
        if (withConfig) {
          return {
            config: { ...organizationConfig },
            value: organizationConfig[key],
          };
        } else {
          return organizationConfig[key];
        }
      }
    }
  }
  if (withConfig) {
    return { value: null, config: {} };
  } else {
    return null;
  }
};

export const useOrganizationConfig = ({ organizationID, key }) => {
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handler = async () => {
      setIsLoading(true);

      const { value } = await getOrganizationConfigValue({
        organizationID,
        key,
        withConfig: true,
      });

      setValue(value);
      setIsLoading(false);
    };
    if (organizationID) {
      handler();
    }
  }, [organizationID]);

  const updateOrganization = /* GraphQL */ `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
      updateOrganization(input: $input) {
        id
        config
      }
    }
  `;

  const setNewValue = async (val) => {
    const configLocal = (await getOrganizationConfig({ organizationID })) || {};

    configLocal[key] = val;
    setValue(val);
    return await updateOrganizationConfigHook.editItem({
      id: organizationID,
      config: JSON.stringify({ ...configLocal }),
    });
  };

  const updateOrganizationConfigHook = useMutation({
    mutation: updateOrganization,
    disableRoleChecking: true,
  });

  const displayEnableAutoReminders = (
    <CustomInput
      id={"enableAutoReminders"}
      type="switch"
      name="enabledStatistics"
      label="Enable Auto Reminders"
      checked={value}
      onChange={() => setValue(!value)}
      title="Turn On/Off Auto Reminders"
    />
  );

  return {
    value,
    // setValue,     // deprecated do not use
    setValue: (val) => setNewValue(val),
    displayEnableAutoReminders,
    isLoading,
  };
};
