import { EventLogger } from "../EventLogger/EventLogger";
import { GetQuery } from "./Graphql/GetQuery";

/**
 * @description cognitoApi function to invoke the cognitoApi lambda
 * @param {object} params - the options
 * @param {string} params.organizationID - the organization ID
 * @param {string} [params.sentryTrace] - the sentry trace
 * @param {string} params.operation - the operation to perform
 * @param {object} params.input - the input to send to the cognitoApi lambda
 * @param {boolean} [params.isOperationTeamUser] - whether the user is an Operation Team user
 */
export const cognitoApi = async ({ organizationID, sentryTrace, operation, input, isOperationTeamUser = false }) => {
  if (!organizationID) {
    EventLogger("[cognitoApi] Missing organizationID");
    return;
  }

  if (!operation) {
    EventLogger("[cognitoApi] Missing operation");
    return;
  }

  if (Object.values(CognitoApiOperation).includes(operation) === false) {
    EventLogger("[cognitoApi] Invalid operation");
    return;
  }

  if (input === null || typeof input !== "object" || Object.keys(input).length === 0) {
    EventLogger("[cognitoApi] Missing input");
    return;
  }

  // Input to send to the cognitoApi lambda
  const finalInput = {
    ...input,
    operation,
    sentryTrace,
  };

  EventLogger(`Invoke cognitoApi lambda. Input: ${JSON.stringify(finalInput)}`);

  return await GetQuery({
    query: isOperationTeamUser ? cognitoApiOperationTeamQuery : cognitoApiOrganizationQuery,
    variables: {
      id: organizationID,
      input: JSON.stringify(finalInput),
    },
  }).then((data) => {
    return data?.cognitoApi && JSON.parse(data?.cognitoApi);
  });
};

export const CognitoApiOperation = {
  CREATE_COGNITO_USER: "createCognitoUser",
  ENABLE_COGNITO_USER_MFA: "enableCognitoUserMFA",
  REQUIRE_MFA_FOR_COGNITO_USER: "requireMFAForCognitoUser",
  REQUIRE_MFA_FOR_COGNITO_GROUP: "requireMFAForCognitoGroup",
  CHANGE_COGNITO_USER_PASSWORD: "changeCognitoUserPassword",
  CHECK_USER_EXISTS: "checkUserExists",
  DELETE_COGNITO_USER: "deleteCognitoUser",
};

export const cognitoApiOrganizationQuery = /* GraphQL */ `
  query GetOrganization($id: ID!, $input: AWSJSON) {
    getOrganization(id: $id) {
      id
      cognitoApi(input: $input)
    }
  }
`;

export const cognitoApiOperationTeamQuery = /* GraphQL */ `
  query GetOperationTeam($id: ID!, $input: AWSJSON) {
    getOperationTeam(id: $id) {
      id
      cognitoApi(input: $input)
    }
  }
`;
