import { convertTargetVulnerabilityStatusCountsToVulnerabilityStatus } from "./convertTargetVulnerabilityStatusCountsToVulnerabilityStatus";

/**
 * Gets the final vulnerability status enum value from the status counts
 * @param {object} params.row.statusCounts - count of statuses for each target finding link
 * @return {string}
 */
export const vulnerabilityStatusValueGetter = (params) => {
  const statusCounts = params?.row?.statusCounts;
  return convertTargetVulnerabilityStatusCountsToVulnerabilityStatus({
    statusCounts,
  }).vulnerabilityStatus;
};
