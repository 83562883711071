import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes a VendorControlDocumentLink
 *
 * @param {object} item - VendorControlDocumentLink
 * @param {string} item.id
 * @returns {Promise<null>}
 * @constructor
 */

export const DeleteVendorControlDocumentLink = async (item) => {
  if (!item || !item.id) {
    EventLogger("Error! Invalid input for DeleteVendorControlDocumentLink function!");
    return null;
  }

  const { deleteMutation } = generateGraphql("VendorControlDocumentLink");
  if (item && item.id) {
    await ItemMutation(deleteMutation, {
      id: item && item.id,
    }).then(() => EventLogger("VendorControlDocumentLink successfully deleted!"));
  } else {
    EventLogger("VendorControlDocumentLink was NOT successfully deleted! Invalid Input");
  }
};
