import { Auth } from "@aws-amplify/auth";
import { Lambda } from "aws-sdk";
import { MonteCarloCalculation } from "../../../../env-config";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Calls the MonteCarloSystemCalculation lambda
 * @param {object} system - the on which to perform calculations (either system or systems must be passed in)
 * @param {object[]} systems - optionally perform calculations on multiple systems at once
 * @param {string} organizationID - the currently selected organization
 * @param {object} [reusableRandoms] - the random keys used during simulations, saved to recreate the same results
 * @param {object} [changeItem] - currently unused
 * @param {object} [recommendation] - if present will calculate the risk values if the recommendation was to be applied
 * @param {string} [calculationContext] - the context of the calculation, used to determine hw to save the calculation results
 * @returns {Promise<*>} - results of the monteCarloSystemCalculation lambda, array if multiple systems were passed in, object if a single system
 */
export const MonteCarloCalculationWithQueryLambda = async ({
  system,
  systems,
  organizationID,
  reusableRandoms,
  changeItem,
  recommendation,
  calculationContext,
}) => {
  const systemResults = [];

  await Auth.currentCredentials().then(async (credentials) => {
    const lambda = new Lambda({
      httpOptions: {
        timeout: 600000,
      },
      credentials: Auth.essentialCredentials(credentials),
      region: "us-west-2",
    });

    //NOTE: calculations are ran for each system individually in order for reusable randoms to be saved per system
    let systemsToProcess = [];
    if (systems) {
      systemsToProcess.push(...systems);
    } else if (system) {
      systemsToProcess.push(system);
    }

    //Make sure all systems have ids
    if (Array.isArray(systemsToProcess) && systemsToProcess.length > 0) {
      systemsToProcess = systemsToProcess.filter((system) => system?.id);
    }

    for (const system of systemsToProcess) {
      const params = {
        FunctionName: MonteCarloCalculation,
        InvocationType: "RequestResponse",
        Payload: JSON.stringify({
          system,
          organizationID,
          reusableRandoms: reusableRandoms || undefined,
          changeItem: changeItem || undefined,
          recommendation: recommendation || undefined,
          calculationContext: calculationContext || undefined,
        }),
      };

      systemResults.push(lambda.invoke(params).promise());
    }
  });

  //Parse responses from lambdas
  const data = [];
  const lambdaResults = await Promise.allSettled(systemResults);
  if (Array.isArray(lambdaResults) && lambdaResults.length > 0) {
    for (const result of lambdaResults) {
      try {
        if (result.status === "fulfilled") {
          const payload = JSON.parse(result.value.Payload);
          if (payload) {
            data.push(payload);
          }
        }
      } catch (e) {
        EventLogger({
          message: "Error processing lambda result",
          error: e,
        });
      }
    }
  }

  //Return parsed data based on the parameters used
  try {
    if (Array.isArray(data) && data.length > 0) {
      if (system) {
        return data[0];
      } else {
        return data;
      }
    } else {
      return data;
    }
  } catch (e) {
    EventLogger(e);
  }

  //When no data is available return an empty array
  return [];
};
