import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { TargetVulnerabilityHistoryType } from "../../../../typedefs/Testing/Vulnerability/Vulnerability";

/**
 * @description Create AssessmentVulnerabilityLink function
 * @param assessment
 * @param vulnerability
 * @param organizationID
 * @returns {Promise<void>}
 */
export const createAssessmentVulnerabilityLink = async ({ assessment, vulnerability, organizationID }) => {
  const { createMutation } = generateGraphql("AssessmentVulnerabilityLink", ["assessmentID", "vulnerabilityID"]);
  const { createMutation: createTargetVulnerabilityHistory } = generateGraphql("TargetVulnerabilityHistory", [
    "id",
    "type",
    "description",
    "date",
    "targetVulnerabilityLinkID",
  ]);

  return await ItemMutation(createMutation, {
    assessmentID: assessment?.id,
    vulnerabilityID: vulnerability?.id,
    ownerGroup: organizationID,
  }).then(async (data) => {
    await ItemMutation(createTargetVulnerabilityHistory, {
      targetVulnerabilityLinkID: data?.id,
      type: TargetVulnerabilityHistoryType.INITIAL,
      description: `Vulnerability Created`,
      date: new Date().toISOString(),
      ownerGroup: organizationID,
    });
    EventLogger("AssessmentVulnerabilityLink was successfully created.");
  });
};
