import { EventLogger } from "../../EventLogger/EventLogger";

/**
 * Opens a link programmatically in a new tab making sure the new tab doesn't have access to the first one
 * REFERENCE: https://stackoverflow.com/questions/46147949/using-rel-noopener-in-window-open
 */
export const openInNewTab = ({ url }: { url: string }) => {
  if (!url) {
    EventLogger("No URL to open provided to openInNewTab function", { url });
    return;
  }

  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
