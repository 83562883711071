import { useEvidenceDataGrid } from "../../../../Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";
import { withOrganizationCheck } from "../../../../../../utils/Context/withOrganizationCheck";
import React, { useEffect } from "react";
import { DETAILS_TYPES } from "../../../../../../hooks/views/useGrid/enums/DETAILS_TYPES";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { deleteControlEvidenceLink } from "../../../../ControlEvidenceLinks/functions/deleteControlEvidenceLink";
import { submitRiskComplianceSyncJob } from "../../../../Evidence/functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../../../../Evidence/enums/updateControlOverrideType";
import EvidenceTemplateDetails from "../../../../../Templates/EvidenceTemplates/EvidenceTemplateDetails";

/**
 * Displays a grid of evidences linked to a control item
 * @param {string} organizationID - the organization that the evidence is associated with
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will only show template related resource parts
 * @param {object} cardConfig - additional card configuration
 * @param {object} gridConfig - additional grid configuration
 * @param {Control} item - the Control item to show evidences for
 * @param {boolean} disablePointOfContactAssign - if TRUE will disable assigning of point of contact to this evidence
 * @return {JSX.Element}
 */
const AssociatedEvidence = ({
  organizationID,
  disableRoleChecking = false,
  isTemplate = false,
  resetFunction,
  isLoading,
  cardConfig = {},
  gridConfig = {},
  item,
  disablePointOfContactAssign,
}) => {
  cardConfig = {
    enableSticky: false,
    persistenceUUID: "associated-evidence-grid-card",
    title: "Associated Evidence",
    ...cardConfig,
  };

  gridConfig = {
    disableRoleChecking,
    gridHeight: "100%",
    allowPaging: false,
    enableMenu: false,
    persistenceUUID: "associated-evidence-grid",
    enableColumnChooser: !isTemplate,
    detailsType: DETAILS_TYPES.NONE,
    ...(isTemplate && {
      detailsComponent: <EvidenceTemplateDetails disableRoleChecking={disableRoleChecking} />,
    }),
    createResourceComponent: null,
    enableQuickDetails: true,
    deleteFunction: async (evidenceToDelete) => {
      if (evidenceToDelete?.evidenceLinkId) {
        await deleteControlEvidenceLink(evidenceToDelete.evidenceLinkId);
        submitRiskComplianceSyncJob({
          evidenceID: evidenceToDelete?.id,
          organizationID,
          controlOverrideID: item?.id,
          overrideType: updateControlOverrideType.EVIDENCE_UNLINKED,
        });
      } else {
        EventLogger("No evidence link id when deleting associated evidence from the grid!");
      }
    },
    ...gridConfig,
  };

  const queryConfig = {
    query: null,
  };

  const evidenceGrid = useEvidenceDataGrid({
    organizationID,
    disableRoleChecking,
    isTemplate,
    ...queryConfig,
    cardConfig,
    ...gridConfig,
    disablePointOfContactAssign,
    resetFunction,
    isLoading,
  });

  // updates the evidence grid to match control
  useEffect(() => {
    if (item?.evidences?.items) {
      const evidences = item.evidences.items.map((evidenceLink) => {
        return {
          ...evidenceLink.evidence,
          evidenceLinkId: evidenceLink?.id,
        };
      });
      evidenceGrid.setData([...evidences]);
    }
  }, [item]);

  return <div style={{ height: "30em" }}> {evidenceGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(AssociatedEvidence);
