import { useState } from "react";
import { ARTIFACT_STATUS } from "../../ArtifactValidation/constants/ARTIFACT_STATUS";
import { useSetAsyncData } from "../../../../../hooks/functional/useSetAsyncData";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";

/**
 * Returns the artifacts that have a pending validation status
 * @param {function} queryFunction - the custom query function to use to retrieve an initial list of artifacts to check
 * @param {object[]} [artifacts] - optional list of artifacts to check and not use query function
 * @param {function} resetFunction - a function to refresh the artifacts param
 * @param {boolean} isLoading - if TRUE artifacts param is still loading
 * @returns {{isLoading: *, resetFunction, artifactsPendingValidation: *}}
 */
export const useArtifactsPendingValidation = ({ queryFunction, artifacts, resetFunction, isLoading }) => {
  const [artifactsPendingValidation, setArtifactsPendingValidation] = useState([]);

  const { resetFunction: _resetFunction, isLoading: _isLoading } = useSetAsyncData({
    resetFunction,
    isLoading,
    getData: async () => {
      try {
        let queriedArtifacts = [];
        if (artifacts) {
          queriedArtifacts = artifacts;
        } else if (typeof queryFunction === "function") {
          queriedArtifacts = await queryFunction();
        }

        if (Array.isArray(queriedArtifacts)) {
          return queriedArtifacts.filter((artifact) => artifact?.status === ARTIFACT_STATUS.PENDING_VALIDATION);
        } else {
          return [];
        }
      } catch (e) {
        EventLogger(e);
      }
      return [];
    },
    setData: (newArtifactsPendingValidation) => {
      setArtifactsPendingValidation(newArtifactsPendingValidation);
    },
    dependencies: [artifacts],
  });

  return {
    artifactsPendingValidation,
    resetFunction: _resetFunction,
    isLoading: _isLoading,
  };
};
