export const createRisk_custom = `mutation CreateRisk($input: CreateRiskInput!) {
  createRisk(input: $input) {
    id
    name
    classification
    description
    annualRateOfOccurrence
    confidenceIntervalLower
    confidenceIntervalUpper
    costOfControls
    controlEffectiveness
    system {
      id
      name
    ownerGroup
  }
}
`;
export const updateRisk_custom = `mutation UpdateRisk($input: UpdateRiskInput!) {
  updateRisk(input: $input) {
    id
    name
    classification
    description
    annualRateOfOccurrence
    confidenceIntervalLower
    confidenceIntervalUpper
    costOfControls
    controlEffectiveness
    system {
      id
      name
    ownerGroup
  }
}
`;
export const deleteRisk_custom = `mutation DeleteRisk($input: DeleteRiskInput!) {
  deleteRisk(input: $input) {
    id
    name
  }
}
`;

export const listRisks_riskListAdminPanel = `query ListRisks(
  $filter: ModelRiskFilterInput
  $limit: Int
  $nextToken: String
) {
  listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      classification
      description
      annualRateOfOccurrence
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      system {
        id
        name
        description
        ownerGroup
      }
      ownerGroup
      riskControls {
        items {
          id
          name
          statementNumber
          isCompliant
          costOfControl
          controlEffectiveness
          annualRateReduction
          ownerGroup
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listRisks_riskList = `query ListRisks(
  $filter: ModelRiskFilterInput
  $limit: Int
  $nextToken: String
) {
  listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      classification
      description
      annualRateOfOccurrence
      confidenceIntervalLower
      confidenceIntervalUpper
      costOfControls
      controlEffectiveness
      system {
        id
        name
        description
        ownerGroup
      }
      ownerGroup
      riskControls {
        items {
          id
          name
          statementNumber
          isCompliant
          costOfControl
          controlEffectiveness
          annualRateReduction
          ownerGroup
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listSnapshots_custom = `query ListSnapshots(
  $filter: ModelSnapshotFilterInput
  $limit: Int
  $nextToken: String
) {
  listSnapshots(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ownerGroup
      name
      date
    }
    nextToken
  }
}
`;

export const deleteSystemRiskLink_custom = `mutation DeleteSystemRiskLink($input: DeleteSystemRiskLinkInput!) {
  deleteSystemRiskLink(input: $input) {
    id
    ownerGroup
    system {
      id
      name
    }
    risk {
      id
      name
    }
  }
}
`;
