import React, { useEffect, useState } from "react";
import { findLossToleranceExceedance, getGreatestRiskCurves } from "@rivial-security/risk-calc-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateRiskCurveWarning } from "../../LossTolerance/functions/generateRiskCurveWarning";
import { Alert } from "reactstrap";
import { getIcon } from "../../../../utils/Functions/Icon/getIcon";
import NotEnoughData from "../../../../utils/GenericComponents/NotEnoughData";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Shows the status of risk curve being above or below loss tolerance for a system
 * @param {string} organizationID - the currently selected organization
 * @param {object} [monteCarloContext] - the context of the monte carlo simulation in system details, not needed if riskResidualLossCurve is not null
 * @param {object} [riskResidualLossCurve] - the risk curve generated by the simulations
 * @param {object} [systemResidualLossCurve] - only needed if systemResidualLossShown will be true
 * @param {boolean} [systemResidualLossShown = false] - if TRUE the system residual loss will be used
 * @param {boolean} [enableChartSwitch = false] - if TRUE, will show a click here message to switch to annual loss comparison chart
 * @param {function} [setShowAnnualLossChart] - enableChartSwitch must be true, the state update function used to switch graphs
 * @param {boolean} [showAnnualLossChart] - enableChartSwitch must be true, the state of which graph is shown currently
 * @return {false|JSX.Element}
 */
const RiskCurveWarning = ({
  organizationID,
  monteCarloContext,
  riskResidualLossCurve,
  systemResidualLossCurve,
  systemResidualLossShown = false,
  enableChartSwitch = false,
  setShowAnnualLossChart,
  showAnnualLossChart = false,
}) => {
  const [lossToleranceCurve, setLossToleranceCurve] = useState(null);
  const [riskWarning, setRiskWarning] = useState(null);

  //Retrieve the
  useEffect(() => {
    const getOrganization = /* GraphQL */ `
      query GetOrganization($id: ID!) {
        getOrganization(id: $id) {
          id
          lossToleranceCurve {
            amount
            probability
          }
        }
      }
    `;
    if (!isNullOrUndefined(organizationID)) {
      QueryGetItem({ query: getOrganization, itemId: organizationID })
        .then((org) => {
          setLossToleranceCurve(
            org.lossToleranceCurve
              .sort((a, b) => b.probability - a.probability)
              .map((row) => ({ x: row.amount, y: row.probability })),
          );
        })
        .catch((e) => EventLogger(`Error fetching Organization for Risk Curve Warning${e.message}`));
    }
  }, []);

  /**
   * Finds if residual risk is ever above the loss tolerance curve
   */
  useEffect(() => {
    //Try to get data from the top level arguments first
    let residualLossCurve;
    if (systemResidualLossShown === true) {
      residualLossCurve = systemResidualLossCurve;
    } else {
      residualLossCurve = riskResidualLossCurve;
    }

    //if data doesn't exist, try to get it from the monte carlo simulation context
    if (!residualLossCurve && !systemResidualLossShown && monteCarloContext) {
      const curves = getGreatestRiskCurves({
        riskScore: monteCarloContext?.riskScore,
        monteCarloResults: monteCarloContext?.monteCarloResults,
      });

      if (curves) {
        residualLossCurve = curves.residualLossCurve;
      }
    }

    if (!residualLossCurve) {
      setRiskWarning(null);
      return;
    }

    const result = findLossToleranceExceedance({
      lossToleranceCurve,
      residualLossCurve,
    });

    if (
      isNullOrUndefined(result?.lossToleranceAnnualLossCurveAverage) ||
      isNullOrUndefined(result?.residualLossAnnualLossCurveAverage)
    ) {
      return;
    }

    const lossToleranceDelta = result.residualLossAnnualLossCurveAverage - result.lossToleranceAnnualLossCurveAverage;

    //Generate the string warning to display
    const currentRiskWarning = generateRiskCurveWarning({
      lossToleranceDelta,
      systemResidualLossShown,
    });

    setRiskWarning(currentRiskWarning);
  }, [lossToleranceCurve, riskResidualLossCurve, systemResidualLossShown, monteCarloContext]);

  return (
    riskWarning?.message != null && (
      <Alert color={!isNullOrUndefined(riskWarning?.type) ? riskWarning?.type : "info"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "2em", marginRight: ".5em", padding: 0 }}>
            {!isNullOrUndefined(riskWarning?.type) &&
              getIcon(
                riskWarning?.type === "warning" ? "ant-design:warning-outlined" : "clarity:success-standard-line",
              )}
          </div>
          <div>
            {riskWarning.message}
            {enableChartSwitch && (
              <NotEnoughData
                message={""}
                callToAction={{
                  function: () => {
                    setShowAnnualLossChart && setShowAnnualLossChart((showAnnualLossChart) => !showAnnualLossChart);
                  },
                  message: showAnnualLossChart
                    ? " to view the risk tolerance curves."
                    : " to view the annual risk comparison chart.",
                }}
              />
            )}
          </div>
        </div>
      </Alert>
    )
  );
};

export default RiskCurveWarning;
