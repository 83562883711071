import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Gets the ID of a connection item based on the 'name' that was selected from the CSV Reader Table.
 *
 * If it can't match a name lookup, and if a 'connectionItemID' is explicitly passed in it will use that ID
 *
 * If it can't find any matches, and the field is required, throws an error
 *
 * @param {string} connectionItemID - explicitly passed in ID to use for the connection.
 * @param {string} connectionItemName - name of the connection item from the CSV Reader Table dropdown. Compared against the list to choose the correct item to form connection
 * @param {object[]} connectionItemList - array of potential connection items for the CSV importer dropdown menu.
 * @returns {string}
 */
export const getSingleConnectionID = ({ connectionItemID, connectionItemName, connectionItemList }) => {
  // If a connection item name is passed in, prioritize trying to use it (means a user explicitly selected a connection item)
  if (!isNullOrUndefined(connectionItemName) && connectionItemName !== "") {
    // Try and match connection item name to an existing connection item ID
    const foundItem = connectionItemList.find((item) => item.name === connectionItemName);

    // If connection item match is found, use this connection item ID
    if (foundItem) {
      return foundItem.id;
    }
  }

  // Else still try and default to passed in connectionItemID
  if (connectionItemID) {
    return connectionItemID;
  }
  // Finally, if no connection item ID can be found, skips this row if required
  else {
    EventLogger("Error: Connection Item Name is not found and no Connection Item ID was passed in");
  }
};
