import { Auth } from "@aws-amplify/auth";
import { Lambda } from "aws-sdk";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { AddUserToGroup } from "./AddUserToGroup";
import { CognitoApiOperation, cognitoApi } from "../../../../utils/Functions/cognitoApi";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Create a new platform User
 * @param {string} name - The name of the user
 * @param {string} email - The email of the user
 * @param {string} phoneNumber - The phone number of the user
 * @param {string} password - The password of the user
 * @param {string} organizationID - The organization ID of the user
 * @param {string} pointOfContactId - The point of contact ID of the user
 * @param {object} role - The role of the user
 * @param {string} accountType - The account type of the user
 * @param {object} config - The config of the user
 * @param {function} callbackFunction - The callback function
 * @param {function} resetFunction - The reset function
 * @param {string} sentryTrace - The sentry trace
 * @returns {Promise<void>}
 * @constructor
 */
export const createUser = async ({
  name,
  email,
  phoneNumber,
  password,
  organizationID,
  pointOfContactId,
  role,
  accountType,
  config,
  callbackFunction,
  resetFunction,
  sentryTrace,
}) => {
  //Making sure email/username are both lowercase at creation
  email = email.toLowerCase();

  /**
   * Check if user already exists by email
   */
  const isUserExist = await cognitoApi({
    operation: CognitoApiOperation.CHECK_USER_EXISTS,
    organizationID,
    sentryTrace,
    input: {
      email,
    },
  });

  /**
   * If user does not exist, create user
   */
  if (!isUserExist) {
    await Auth.currentCredentials().then(async (credentials) => {
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-west-2",
      });
      await invokeUserCreateLambda(
        lambda,
        name,
        email,
        phoneNumber,
        password,
        organizationID,
        pointOfContactId,
        role,
        accountType,
        config,
        callbackFunction,
        resetFunction,
        {},
        sentryTrace,
      );
    });
  } else {
    /**
     * If user already exists, alert user
     */
    callbackFunction && callbackFunction();
    alert(
      `The user creation process was not successful. A user with the email address: ${email} already exists in our system.
      This means that the intended user will not be able to access the platform.
      Please double-check the email address and try again, or contact our support team for further assistance.`,
    );
    EventLogger(`User ${email} was NOT Successfully Created in the User Pool!`);
  }
};

/**
 * Create a new platform User
 * @param {object} lambda - The lambda object
 * @param {string} propName - The name of the user
 * @param {string} propEmail - The email of the user
 * @param {string} propPhoneNumber - The phone number of the user
 * @param {string} propPassword - The password of the user
 * @param {string} propOrganizationID - The organization ID of the user
 * @param {string} propPointOfContactId - The point of contact ID of the user
 * @param {object} role - The role of the user
 * @param {string} accountType - The account type of the user
 * @param {object} config - The config of the user
 * @param {function} callbackFunction - The callback function
 * @param {function} resetFunction - The reset function
 * @param {object} userPreferences - The user preferences
 * @param {string} sentryTrace - The sentry trace
 * @returns {Promise<Array|void>}
 */
export const invokeUserCreateLambda = async (
  lambda,
  propName,
  propEmail,
  propPhoneNumber,
  propPassword,
  propOrganizationID,
  propPointOfContactId,
  role,
  accountType,
  config,
  callbackFunction,
  resetFunction,
  userPreferences = {},
  sentryTrace,
) => {
  // Creates User object in Database. Passes this ID to User Pool lambda
  return await ItemMutation(createUserMutation, {
    name: propName ? propName : undefined,
    username: propEmail ? propEmail : undefined,
    email: propEmail ? propEmail.toLowerCase() : undefined,
    phoneNumber: propPhoneNumber ? propPhoneNumber : undefined,
    ownerGroup: propOrganizationID ? propOrganizationID : undefined,
    accountType: accountType ? accountType : undefined,
    config: accountType === "examiner" ? JSON.stringify(config) : undefined,
    preferences: JSON.stringify(userPreferences || {}),
  }).then(async (user) => {
    const userID = user?.id;

    EventLogger(`User ${userID} was Successfully Created in the DB`);

    if (role) {
      await ItemMutation(createUserRoleLink, {
        userID: userID ? userID : undefined,
        roleID: role && role.id ? role.id : undefined,
        ownerGroup: propOrganizationID,
      })
        .then(() => {
          EventLogger("UserRoleLink was successfully created!");
        })
        .catch((err) => EventLogger("Error: UserRoleLink was NOT successfully created!", err));
    }

    EventLogger(`Linking user to point of contact in DB - ${propPointOfContactId}`);
    if (propPointOfContactId) {
      await ItemMutation(updatePointOfContact, {
        id: propPointOfContactId,
        pointOfContactUserId: userID,
      }).then(() => {
        EventLogger(` User: ${userID} was linked to Point of Contact: ${propPointOfContactId}`);
      });
    }

    return await cognitoApi({
      operation: CognitoApiOperation.CREATE_COGNITO_USER,
      organizationID: propOrganizationID,
      sentryTrace,
      input: {
        name: propName,
        userName: propEmail,
        temporaryPassword: propPassword,
        email: propEmail,
        phone: propPhoneNumber,
        pointOfContactId: propPointOfContactId,
        userID: userID,
        accountType: accountType,
      },
    })
      .then(async (data) => {
        if (!data?.User) {
          const errorMessage = `User ${propEmail} was NOT successfully created!`;
          EventLogger(errorMessage);
          alert(errorMessage);
        } else {
          await AddUserToGroup({
            userName: propEmail,
            organizationID: propOrganizationID,
          });
          callbackFunction && callbackFunction();
          resetFunction && resetFunction();
        }
      })
      .catch((err) => EventLogger(err, err.stack));
  });
};

export const createUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
      username
      email
      phoneNumber
      ownerGroup
    }
  }
`;

export const createUserRoleLink = /* GraphQL */ `
  mutation CreateUserRoleLink($input: CreateUserRoleLinkInput!) {
    createUserRoleLink(input: $input) {
      id
      userID
      roleID
      user {
        id
        name
      }
    }
  }
`;

export const updatePointOfContact = /* GraphQL */ `
  mutation UpdatePointOfContact($input: UpdatePointOfContactInput!) {
    updatePointOfContact(input: $input) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
    }
  }
`;
