import React from "react";
import { Redirect } from "react-router-dom";

const ResponseDashboard = React.lazy(() => import("../../views/Response/Dashboard/ResponseDashboard"));
const IncidentGrid = React.lazy(() => import("../../views/Response/Incidents/components/IncidentGrid"));
const IncidentDetails = React.lazy(() => import("../../views/Response/Incidents/components/IncidentDetails"));
const PlaybookDetails = React.lazy(() => import("../../views/Response/Plan/Playbooks/components/PlaybookDetails"));
const ExerciseGrid = React.lazy(() => import("../../views/Response/Exercises/components/ExerciseGrid"));
const ExerciseDetails = React.lazy(() => import("../../views/Response/Exercises/components/ExerciseDetails"));
const ResponseTeamGrid = React.lazy(() => import("../../views/Response/ResponseTeams/components/ResponseTeamGrid"));
const ResponseTeamDetails = React.lazy(
  () => import("../../views/Response/ResponseTeams/components/ResponseTeamDetails"),
);
const IncidentResponsePlan = React.lazy(
  () => import("../../views/Response/IncidentResponsePlan/components/IncidentResponsePlan"),
);

// Incident Response Routes
export const incidentResponseRoutes = [
  {
    path: "/response/dashboard",
    exact: true,
    name: "Incident Response Dashboard",
    component: ResponseDashboard,
  },
  {
    path: "/response/incidents",
    exact: true,
    name: "Incidents",
    component: IncidentGrid,
  },
  {
    path: "/response/incidents/:id",
    exact: true,
    name: "Incident Details",
    component: IncidentDetails,
  },
  {
    path: "/response/playbooks/:id",
    exact: true,
    name: "Playbook Details",
    component: PlaybookDetails,
  },
  {
    path: "/response/exercises",
    exact: true,
    name: "Exercises",
    component: ExerciseGrid,
  },
  {
    path: "/response/exercises/:id",
    exact: true,
    name: "Exercise Details",
    component: ExerciseDetails,
  },
  {
    path: "/response/teams",
    exact: true,
    name: "Response Teams",
    component: ResponseTeamGrid,
  },
  {
    path: "/response/teams/:id",
    exact: true,
    name: "Response Team Details",
    component: ResponseTeamDetails,
  },
  {
    path: "/response/plan",
    exact: true,
    name: "Incident Response Plan",
    component: IncidentResponsePlan,
  },
  { path: "/response", component: () => <Redirect to="/response/dashboard" /> },
];
