import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

export interface IamAPIType {
  operation: string;
  roleName?: string;
  roleDescription?: string;
  maxSessionDuration?: number;
  organizationID?: string;
  operationTeamID?: string;
  policyName?: string;
  bucketName?: string;
}

export interface IamAPIGetOrganizationRoleCredentialsResponseType {
  statusCode: number;
  body: {
    error?: string;
    credentials: {
      accessKeyId: string;
      secretAccessKey: string;
      sessionToken: string;
    };
  };
}

/**
 * Send request to IAM API lambda
 */
const iamAPI = async (
  input: IamAPIType,
): Promise<{
  credentials?: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
  };
}> => {
  const iamAPILambdaGraphql = `
    query iamAPI($input: AWSJSON) {
      iamAPI(input: $input)
    }
  `;

  EventLogger(`Sending request to IAM API. Input: ${JSON.stringify(input)}`);

  return GetQuery({
    query: iamAPILambdaGraphql,
    variables: {
      input: JSON.stringify(input),
    },
  }).then((event: string) => {
    EventLogger("Got response from iamAPI lambda");

    let response: IamAPIGetOrganizationRoleCredentialsResponseType | null = null;
    try {
      response = JSON.parse(event);
    } catch (e) {
      EventLogger("Error! Can not parse result from iamAPI lambda!", e as object);
    }

    if (response?.statusCode === 200 && response?.body) {
      return response?.body;
    } else {
      throw new Error(response?.body?.error);
    }
  });
};

export default iamAPI;
