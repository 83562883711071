import React from "react";

const AccountManager = React.lazy(
  () => import("../../views/OrganizationManager/PointOfContacts/components/AccountManager"),
);

export const generalRoutes = [
  {
    path: "/my_dashboard/:id",
    exact: true,
    name: "My Dashboard",
    component: AccountManager,
  },
];
