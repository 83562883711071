"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxConfidentiality = void 0;
const getMaxConfidentiality = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
system, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
ratingScale) => {
    const informationAssetLinks = system?.informationAssets?.items ? system.informationAssets.items : [];
    const informationAssets = [];
    for (const item of informationAssetLinks) {
        informationAssets.push(item.informationAsset);
    }
    const assetsWithScale = [];
    for (const item of informationAssets) {
        // @ts-expect-error Fix me, needs better typing
        const assetWithScale = { ...item, confidentiality: ratingScale[item.confidentiality] };
        assetsWithScale.push(assetWithScale);
    }
    const arr = [];
    for (const item of assetsWithScale) {
        // @ts-expect-error Fix me, needs better typing
        arr.push(item.confidentiality);
    }
    return Math.max(...arr);
};
exports.getMaxConfidentiality = getMaxConfidentiality;
