import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deletePointOfContactMeetingLink } = generateGraphql("PointOfContactMeetingLink");

export const deleteMeetingParticipant = async (meetingLink) => {
  await API.graphql(
    graphqlOperation(deletePointOfContactMeetingLink, {
      input: {
        id: meetingLink.id,
      },
    }),
  )
    .then(() => EventLogger(`Contact Meeting Link${meetingLink.id} was Successfully Deleted`))
    .catch((err) => EventLogger(err));
};
