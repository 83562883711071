/**
 * Author: Anatoli Railean
 * Created At: 11/17/19
 * Description: Deletes a Policy Version resource in the DB.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deletePolicyVersion } = generateGraphql("PolicyVersion");

export const DeletePolicyVersion = async (policyVersion) => {
  return API.graphql(
    graphqlOperation(deletePolicyVersion, {
      input: {
        id: policyVersion.id,
      },
    }),
  ).then(({ data }) => {
    if (data && data.deletePolicyVersion) {
      EventLogger(`Policy Version ${data.deletePolicyVersion.id} was Successfully Deleted`);
    }
  });
};
