import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";

/**
 * Deletes an Incident object from the database.
 * Also removes ThreatLinks and SystemLinks many-to-many connections
 * @param {object} incident - the incident to remove
 * @param {string} incident.id - the ID  of the incident to remove
 * @returns {Promise<null|void>}
 */
export const deleteIncident = async (incident) => {
  if (!incident || !incident.id) {
    return null;
  }

  // query to gather all resources that need deletion
  const { getQuery: resourcesToDelete } = generateGraphql("Incident", ["threatLinks", "__typename"], {
    threatLinks: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
  });

  return await QueryGetItem({
    query: resourcesToDelete,
    itemId: incident.id,
  }).then(async (item) => {
    EventLogger(`Deleting incident - ${item?.id}`);
    await GenericDeleteGQL({ objectToDelete: item });
  });
};
