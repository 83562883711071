import { Button, Form, FormGroup } from "reactstrap";
import React, { useEffect, useState } from "react";

import { CreateReportDocument } from "./functions/CreateReportDocument";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { tryFunction } from "../../utils/Functions/tryFunction";
import { useFileUpload } from "../../hooks/storage/useFileUpload";
import { useForm } from "../../hooks/views/useForm/useForm";
import { useMutation } from "../../hooks/graphql/useMutation/useMutation";
import { withOrganizationCheck } from "../../utils/Context/withOrganizationCheck";

export const createReport = /* GraphQL */ `
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
      name
      status
      description
      type
      ownerGroup
      module
      documents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * Component that allows the user to Create a Report and upload the initial ReportVersion file
 * @param {string} organizationID - the organization id
 * @param {function} toggleModal - function to toggle the modal
 * @param {function} getNewItem - function to call to get the new item
 * @param {string} version - the version of the report
 * @returns {JSX.Element}
 * @constructor
 */
const UploadReport = ({ organizationID, toggleModal, getNewItem, version }) => {
  const types = {
    risk: ["Risk Assessment Report"],
    compliance: ["IT Audit Report", "CC Report"],
    vulnerabilities: ["Vulnerability Assessment Report"],
    governance: ["Board Report", "Meeting Report", "Blueprint Report", "Findings Report"],
    custom: ["Custom Report"],
    incidentResponse: ["Incident Response Plan", "Exercise Report", "Incident Report"],
    tools: ["Phishing Report", "Training Report"],
    vendor: ["Vendor Review Report", "Vendor Review Controls Report"],
  };

  /**
   * Returns the module for a given report type, all modules with reports are returned if the type is not provided
   * @return {string[]} - the modules under which the specified report type can be under
   */
  const getAvailableModules = () => {
    return Object.keys(types) || [];
  };

  const moduleForm = useForm({
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Report Name",
        required: true,
      },
      description: {
        inputType: "text",
        label: "Description",
      },
      module: {
        inputType: "dropdown",
        label: "Module",
        defaultValue: module || "governance",
        dropdownConfig: {
          data: getAvailableModules().map((text) => {
            return { text: convertCamelCaseToSentence(text), value: text };
          }),
        },
      },
    },
    item: {
      module: "governance",
    },
    disableSubmitButton: true,
    disableResetButton: true,
  });

  const [newReport, setNewReport] = useState(null);

  const fileUploadHook = useFileUpload({
    organizationID: organizationID,
    visibility: "private",
    mutationFunction: CreateReportDocument,
    version: version || 1,
    linkedResourceName: "reportDocumentReportId",
    linkedResourceId: newReport && newReport.id,
    folder: "reports",
    callback: () => {
      tryFunction(toggleModal);
    },
  });

  /**
   * Auto-sets the file friendly name input to the report name
   */
  useEffect(() => {
    if (moduleForm?.input?.name) {
      fileUploadHook.setFriendlyName(moduleForm.input.name);
    }
  }, [moduleForm?.input?.name]);

  const mutationHook = useMutation({
    mutation: createReport,
    typename: "Report",
    disableRoleChecking: true,
  });

  const handleSubmit = async () => {
    try {
      const data = await mutationHook.createItem({
        status: "created",
        name: moduleForm?.input?.name,
        description: moduleForm?.input?.description,
        module: moduleForm?.input?.module,
        type: "Custom Report",
        ownerGroup: organizationID,
      });

      if (getNewItem && typeof getNewItem === "function") {
        getNewItem(data);
      }

      setNewReport(data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Form style={{ marginBottom: "1em" }}>
        <FormGroup>{moduleForm.display}</FormGroup>
        {!newReport && (
          <Button disabled={moduleForm?.submitDisabled} color="success" onClick={() => handleSubmit()}>
            Continue to File Upload
          </Button>
        )}
      </Form>
      {newReport && fileUploadHook.display}
    </>
  );
};

export default withOrganizationCheck(UploadReport);
