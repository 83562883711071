import { generateGraphqlFields } from "@rivial-security/generategraphql";

const listGenericActionsByOwnerGroup_fields = [
  "name",
  "status",
  "description",
  "priority",
  "dueDate",
  "completionDate",
  "startDate",
  "module",
  "recommendations",
  "createdAt",
];
const listGenericActionsByOwnerGroup_nestedFields = {
  recommendations: `(limit: 500) {
    items {
      recommendation {
        observations (limit: 500){
          items {
            observation {
              name
              isFinding
            }
          }
        }
      }
    }
  }`,
};
export const listGenericActionsByOwnerGroup_For_Board_Report = /* GraphQL */ `
  query ActionsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelGenericActionItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${generateGraphqlFields(listGenericActionsByOwnerGroup_fields, listGenericActionsByOwnerGroup_nestedFields)}
      }
      nextToken
    }
  }
`;

const listGenericRecommendationsByOwnerGroup_fields = [
  "name",
  "status",
  "rating",
  "resolvedAt",
  "createdAt",
  "updatedAt",
  "module",
];
export const listGenericRecommendationsByOwnerGroup_For_Board_Report = `
  query RecommendationsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRecommendationItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${generateGraphqlFields(listGenericRecommendationsByOwnerGroup_fields)}
      }
      nextToken
    }
  }
`;

export const listSystemsByOwner_For_Board_Report = /* GraphQL */ `
  query SystemsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        name
        description
        hosting
        ownerGroup
        pointOfContact {
          firstName
          lastName
          title
        }
        informationAssets {
          items {
            id
            numberOfRecords
            informationAsset {
              id
              ownerGroup
              name
              confidentiality
              integrity
            }
          }
        }
        risks {
          items {
            id
            ownerGroup
            probabilityModifier
            riskMapping {
              dataBreach
              systemDisruption
              facilityDisruption
              fraud
              malware
              vendor
              compliance
            }
            risk {
              id
              ownerGroup
              name
              annualRateOfOccurrence
            }
            notes {
              content
              observationID
            }
          }
        }
        riskControlOverrides {
          enabledFields
          riskControlId
          implementationRating
          outsourced
          implementationDetails
        }
      }
    }
  }
`;

export const listInformationAssetsByOwner_For_Board_Report = /* GraphQL */ `
  query InformationAssetByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelInformationAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    informationAssetByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        confidentiality
        integrity
      }
      nextToken
    }
  }
`;

const listControlsByControlSet_fields = [
  "statementNumber",
  "name",
  "inPlace",
  "isDisabled",
  "changes",
  "tags",
  "notes",
  "controlControlSetId",
  "controlSet",
  "customFieldData",
  "createdAt",
];
const listControlsByControlSet_nestedFields = {
  tags: `(limit: 100) { items { id tag { id name description fontColor backgroundColor } } }`,
  notes: `{ content observationID }`,
  controlSet: `{id name}`,
  changes: `(limit: 500) {
    items {
      date
      field
      change
    }
  }`,
};
export const listControlsByControlSet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
         ${generateGraphqlFields(listControlsByControlSet_fields, listControlsByControlSet_nestedFields)}
      }
      nextToken
    }
  }
`;
