import { useEffect, useState } from "react";
import { usePreferences } from "../../../usePreferences/usePreferences";
import ViewListMenu from "./components/ViewListMenu/ViewListMenu";
import ViewControlButton from "./components/ViewControlButton";
import SaveAsViewMenu from "./components/SaveAsViewMenu";
import { saveUserSelectedView } from "./functions/saveUserSelectedView";
import { saveDataGridView } from "./functions/saveDataGridView";
import { deleteDataGridView } from "./functions/deleteDataGridView";
import { getColumnsObjectFromGridAPI } from "./functions/getColumnsObjectFromGridAPI";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import * as Sentry from "@sentry/react";
import { useDataGridViewsState } from "./hooks/useDataGridViewsState";

/**
 * Controls the way a data grid shows its columns, controls non-default
 * options for the following columns settings
 * - visibility
 * - pinning
 * - widths
 * - filtering
 * - sorting
 * - ordering
 * @param {string} gridID - the grid for which to load the views
 * @param {object} apiRef - the grid API reference
 * @param {function} [onTargetViewChange] - callback for when the grids visual settings change
 * @param {function} [onSelectedViewChange]  - callback when user has persistence view loaded for the first time or a new view selected from the drop down
 * @return {{viewControlsDisplay: JSX.Element, changeTargetViewColumns: changeTargetViewColumns, targetView: *, selectView: ((function({viewID: *, view: *}): Promise<void>)|*), changeTargetView: changeTargetView}}
 */
export const useDataGridViews = ({ gridID, apiRef, onTargetViewChange, onSelectedViewChange }) => {
  /// [HOOKS]
  const preferencesHook = usePreferences();
  const { views, selectedViewID } = useDataGridViewsState({ gridID });

  /// [STATE]
  // - view setting that was selected by the user (default is null for developer defined view)
  const [selectedView, setSelectedView] = useState(null);
  // - the view data that all data grid model controllers and column generators should try to use
  const [targetView, setTargetView] = useState(null);
  // - boolean storing whether the view has been modified by the user
  const [userHasModifiedView, setUserHasModifiedView] = useState(false);

  /// [SIDE EFFECTS]
  // - when the gridID or selected view changes, update the target view
  useEffect(() => {
    let newSelectedView = null;
    if (views && gridID && selectedViewID) {
      newSelectedView = views.find((view) => view.id === selectedViewID);
    }

    setSelectedView(newSelectedView);
    setTargetView(newSelectedView);
    setUserHasModifiedView(false);

    if (typeof onSelectedViewChange === "function") {
      onSelectedViewChange({ view: newSelectedView, apiRef });
    }
  }, [gridID, selectedViewID]);

  /**
   * Performs the action of user selecting a view from the view list menu
   * - updates the target view to display with the given view object
   * - saves view as the preferred view for the grid
   * @param {string} viewID - the ID of the view to select
   * @param {object} view - data grid view object selected to use for the grid
   * @return {Promise<void>}
   */
  const selectView = async ({ viewID, view }) => {
    const viewToSelect = viewID ? views.find((view) => view.id === viewID) : view;
    const selectedViewID = viewToSelect?.id;

    setSelectedView(viewToSelect);
    setTargetView(viewToSelect);
    setUserHasModifiedView(false);

    if (typeof onSelectedViewChange === "function") {
      onSelectedViewChange({ view: viewToSelect, apiRef });
    }

    await saveUserSelectedView({
      gridID,
      viewID: selectedViewID,
      preferencesHook,
    });
  };

  /// [UTILS]
  /**
   * Deletes the given view from the list of available views (if its present)
   * NOTE: only one of the below arguments needs to be supplied
   * @param {string} viewID - id of the view to delete
   * @param {object} view - data grid view object to delete
   * @return {Promise<void>}
   */
  const deleteView = async ({ viewID, view }) => {
    //Confirm with user they want to delete the view
    const confirmed = window.confirm("Are you sure you want to delete this view?");
    if (!confirmed) {
      return;
    }

    const viewToDelete = viewID ? views.find((view) => view.id === viewID) : view;
    const deletedViewID = viewToDelete?.id;

    if (deletedViewID) {
      try {
        await deleteDataGridView({
          gridID,
          viewID: deletedViewID,
          preferencesHook,
        });
        if (selectedView?.id === deletedViewID) {
          await selectView({});
        }
      } catch (e) {
        EventLogger("Failed to delete a data grid view", e);
        Sentry.captureMessage(e.message, {
          level: "warning",
        });
      }
    }
  };

  /**
   * Updates the target view, used primarily to update grid on user interaction with MUI UI
   * NOTE: using this function will  mark the current view as modified
   * @param {object} view - any new settings to apply to the target view cam be a partial change to a subset of properties
   */
  const changeTargetView = (view) => {
    if (view) {
      let finalView = { ...view };
      if (targetView) {
        finalView = { ...targetView, ...view };
      }
      setTargetView(finalView);
      if (typeof onTargetViewChange === "function") {
        onTargetViewChange({ view: finalView, apiRef });
      }

      setUserHasModifiedView(true);
    }
  };

  /**
   * Updates the target view columns property, may use on user column width or order change
   * @param {object} [columnChange] - optional column change object to apply to the target view
   */
  const changeTargetViewColumns = ({ columnChange }) => {
    const { colDef: { field: fieldName } = {}, width } = columnChange || {};
    const newColumns = getColumnsObjectFromGridAPI({ apiRef });

    const columnsArrayAvailable = Array.isArray(newColumns);
    const changedFieldNameValid = typeof fieldName === "string";
    const changedWidthAboveMinimum = width > 5;

    //If a column change is passed in, update the appropriate columns entry
    if (columnsArrayAvailable && changedFieldNameValid && changedWidthAboveMinimum) {
      for (const column of newColumns) {
        if (column.name === fieldName) {
          column.width = columnChange?.width;
        }
      }
    } else if (columnChange) {
      EventLogger("Failed to pass argument requirements to set new columns object width.", {
        columnsArrayAvailable,
        changedFieldNameValid,
        changedWidthAboveMinimum,
      });
    }

    //If the columns array is valid in itself update the target view to match
    if (columnsArrayAvailable) {
      changeTargetView({ columns: newColumns });
    }

    setTargetView({ ...targetView, columns: newColumns });
  };

  /**
   * Handles "Save" button press in view menu
   * @return {Promise<void>}
   */
  const handleSaveTargetView = async () => {
    try {
      const selectedViewID = selectedView?.id;
      if (selectedViewID) {
        await saveDataGridView({
          gridID,
          viewID: selectedViewID,
          view: targetView,
          preferencesHook,
        });
        setSelectedView(targetView);
        setUserHasModifiedView(false);
      }
    } catch (e) {
      EventLogger("Failed to save data grid view", e);
    }
  };

  /**
   * Handles "Reset" button press in view menu
   */
  const handleResetSelectedView = () => {
    setTargetView(selectedView);
    setUserHasModifiedView(false);
  };

  // [GUI]
  const viewControlsDisplay = (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ViewListMenu
        selectedView={selectedView}
        views={views}
        onSelectedView={({ view }) => selectView({ view })}
        onDeleteView={({ view }) => deleteView({ view })}
      />
      {selectedView && userHasModifiedView && (
        <ViewControlButton
          title={"Saves the current grid configuration under the selected view"}
          onClick={handleSaveTargetView}
        >
          Save
        </ViewControlButton>
      )}
      {userHasModifiedView && <SaveAsViewMenu gridID={gridID} targetView={targetView} />}
      {userHasModifiedView && (
        <ViewControlButton
          title={"Restores the grid configuration saved in the selected view"}
          onClick={handleResetSelectedView}
        >
          Reset
        </ViewControlButton>
      )}
    </div>
  );

  return {
    viewControlsDisplay,
    targetView,
    selectView,
    changeTargetView,
    changeTargetViewColumns,
  };
};
