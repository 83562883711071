import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "../../../../../utils/Functions/Graphql/GetQuery";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";

/**
 * Runs a custom query on the graphql lambda
 * @param {string} customQueryID - the id of the custom query to run
 * @returns {Promise<any|null>}
 */
export const runCustomQueryOnLambda = async ({ customQueryID }) => {
  if (!customQueryID) return null;

  try {
    // Run the query through the graphql lambda
    const { getQuery: invokeLambdaQuery } = generateGraphql("CustomQuery", ["runQuery"], {
      runQuery: `{
          data
          errors
        }`,
    });

    const result = await GetQuery({
      query: invokeLambdaQuery,
      variables: { id: customQueryID },
    });

    const queryData = result?.runQuery?.data;

    return JSON.parse(queryData);
  } catch (e) {
    EventLogger("Could not finish running custom query on lambda - ", e);
    return null;
  }
};
