import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { DeleteImplementationLevel } from "../../ImplementationLevel/functions/DeleteImplementationLevel";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteProgramSection } = generateGraphql("ProgramSection");

export const DeleteProgramSection = async (item) => {
  if (item && item.implementationLevels && item.implementationLevels.items) {
    for (const implementationLevel of item.implementationLevels.items) {
      await DeleteImplementationLevel(implementationLevel);
    }
  }

  return await API.graphql(
    graphqlOperation(deleteProgramSection, {
      input: {
        id: item.id,
      },
    }),
  )
    .then(({ data }) => {
      if (data && data.deleteProgramSection) {
        EventLogger(`Program Section ${data.deleteProgramSection.id} was Successfully Deleted`);
      }
    })
    .catch((err) => EventLogger("Error! Program Section delete function: ", err));
};
