import "react-datepicker/dist/react-datepicker.css";
import "../../../views/OrganizationManager/Automations/styles/JSONEditor.css";

import { ButtonGroup, Form } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { hasProperty, isNonEmptyArray, isNullOrUndefined, isObject } from "@rivial-security/func-utils";

import FormButtons from "./components/FormButtons";
import FormSteps from "./components/FormSteps";
import { OperationQuantity } from "../../../definitions/constants/operationQuantity";
import { UIContext } from "../../../utils/Context/UIContext";
import { combineFieldConfig } from "./functions/combineFieldConfig";
import { getInitialState } from "./functions/getInitialState";
import { handleEmptyFieldInputs } from "./functions/handleEmptyFieldInputs";
import { tryFunction } from "../../../utils/Functions/tryFunction";
import { useConfirmDeleteItemModal } from "../useConfirmDeleteItemModal";
import { useMutation } from "../../graphql/useMutation/useMutation";
import { useSelectTemplatesModal } from "../useSelectTemplates/hooks/useSelectTemplatesModal";
import { useStepper } from "../useStepper/useStepper";
import { v4 as uuid } from "uuid";

/**
 * Dynamically generates a ReactStrap form.
 * Utilizes the useMutation hook to create a DB entry through AppSync
 *
 * Confluence Documentation: https://rivialsecurity.atlassian.net/l/c/7o1aj2g9
 * ReactStrap Input Documentation: https://reactstrap.github.io/components/form/
 *
 * @param {object} input - event object with lambda params
 * @param {string} [input.id]
 * @param {string} [input.typename] - the graphQL schema model name of the item being created or modified
 * @param {string} input.organizationID - the organization ID
 * @param {string} [input.formID] - if provided will be used throughout the form to uniquely define id's for components inside of each field
 * @param {string} [input.mutation] - the mutation to use for creating the item
 * @param {object | object[]} input.fieldConfig - the configuration of the fields to be created: may be a object of fields or an array of steps with fields
 * @param {string} [input.module] - the module the form is being used in
 * @param {string} [input.resource] - the resource the form is being used in
 * @param {boolean} [input.disableRoleChecking] - whether or not to disable the role checking
 * @param {function} [input.callback] - the callback to be called after the form is submitted
 * @param {function} [input.resetFunction] - the function to be called after the form submitted to reset the state of the parent
 * @param {function} [input.resetCallback] - called when the reset button is pressed
 * @param {function} [input.toggleModal] - the function to be called to toggle the modal
 * @param {string} [input.header] - the header of the form
 * @param {JSX[]} [input.headerButtons] - JSX buttons array to display on top of the component
 * @param {boolean} [input.externalFormInvalid] - if TRUE will make the submit button invalid for this form as well
 * @param {function} [input.updateInputFunction] - called on submit, allows to preprocess the input before writing it to db
 * @param {function} [input.submitFunction] - custom submit function to be called on submit
 * @param {boolean} [input.disableCancelOnSubmit] - if TRUE will disable the cancel button on submit
 * @param {boolean} [input.disableSubmitButton] - if TRUE will disable the submit button
 * @param {boolean} [input.disableResetButton] - if TRUE will disable the reset button
 * @param {string[]} [input.fields] - if an array of fields is passed in, it will only use those fields. if no array, it shows all available fields
 * @param {object} [input.item] - the item to be modified
 * @param {function} [input.deleteFunction] - optional function to allow to delete an existing item if its an update form
 * @param {string} [input.route] - the route to be used for the form
 * @param {boolean} [input.enableTemplates] - if TRUE will allow the user to select a resource from templates
 * @param {boolean} [input.showOverridesButton] - if TRUE will show the overrides button
 * @param {boolean} [input.showIDField] - if TRUE will show the built-in ID field
 * @param {boolean} [input.disableItemSelect] - only applicable if 'showIDField' is true, this allows the field to display but disables the item selection itself
 * @param {function} [input.onIDFieldChange] - called when the ID field is changed
 * @param {JSXElement} [input.grid] - the grid to be used with the ID field form to select an item to edit
 * @param {function} [input.onChange] - calls an external onChange function whenever the input is updated
 * @param {string} [input.submitButtonText] - any text to use instead of 'submit' or 'update' at the end of the form
 * @param {ReactElement} [input.footerPrefix] - any JSX to display before the buttons
 * @returns {{display: *, input: {}, setInput: function, submitDisabled: boolean, stepper: {handleNext: function, setSteps: function, handleReset: function}}}
 */
export const useForm = ({
  id,
  typename,
  organizationID,
  formID,
  mutation,
  fieldConfig,
  module,
  resource,
  disableRoleChecking,
  callback,
  getNewItem,
  resetFunction,
  resetCallback,
  toggleModal,
  header,
  headerButtons,
  externalFormInvalid = false,
  updateInputFunction,
  submitFunction,
  disableCancelOnSubmit,
  disableSubmitButton = false,
  disableResetButton = false,
  disableResetFunction = false,
  fields: fieldsInit, // if an array of fields is passed in, it will only use those fields. if no array, it shows all available fields
  deleteFunction,
  item, //if an item is passed in this is now an update form
  route,
  enableToast = false,
  enableTemplates = false,
  showOverridesButton = false,
  showIDField = false,
  disableItemSelect, // only applicable if 'showIDField' is true, this allows the field to display but disables the item selection itself
  onIDFieldChange,
  grid,
  onChange,
  submitButtonText,
  footerPrefix,
}) => {
  /*
        fieldConfig = {
          fieldName: {
            tooltip: string || component
            label: string
            placeholder: string,
            defaultValue: string,
            inputType: string, // text, textarea, number, dropdown, custom, rich-text-editor, switch, color-picker, item-select, multi-select, password
            required: boolean,
            isHidden: boolean | function, // TRUE if field must not be shown (can use required fields that are known ahead of time). If a function, it will be called with the current input and must return TRUE or FALSE
            validationFunction: function, // a function that checks the input and returns true (for valid) or false (for invalid)
            validationText: string // a string that displays if the validationFunction returns false
            warningFunction: function // returns some warning text if the input warrants a warning
            inputGroupAddon: {
              addonType,
              addonComponent
            }
            numberConfig: {
              min: Int
              max: Int
              step: Int
            },
            dropdownConfig: {
                customFindValueFunction - can be used if value is not a string in data
                onSelectedOption - callback for when a user selects an item specifically from the UI
                data: [
                 {
                   text: "Hello World",
                   value: "helloWorld"
                 }
                ]
            },
            switchConfig: {

            },
            itemSelectConfig: {
              grid: //Select[Resource]Grid.js component with overridable grid configuration
              typename: //the graphql model name of the item being selected
              formatSelectedItem: function, // a function that formats the name for display in the form
              outputAsObject: false, // if true will output the entire selected row instead of just the id
            },
            customConfig: {
              component: React Component
            }
          }
        }

        OR

        fieldConfig = [
          {
            id: "general-information",
            stepName: "General Information",
            fields: {...}
          },
          {
            id: "create-questions",
            stepName: "Create Questions",
            fields: {...}
          }
        ];
   */

  const { addToast, updateToast } = useContext(UIContext);
  const toastId = uuid();

  const [input, setInput] = useState({});
  const [fields, setFields] = useState(fieldsInit);

  /**
   * Object containing fieldNames to internal input validations results
   *  - submit will be disabled if any of these are false
   */
  const [internalValidationResults, setInternalValidationResults] = useState({});

  const steps = [];
  if (isNonEmptyArray(fieldConfig)) {
    for (const formStep of fieldConfig) {
      steps.push({
        id: formStep?.id || formStep?.stepName,
        text: formStep?.stepName,
        fields: formStep?.fields,
      });
    }
  } else if (isObject(fieldConfig)) {
    steps.push({
      id: "general-information",
      text: "General Information",
      fieldConfig,
    });
  }
  const showStepper = steps?.length > 1;

  const stepper = useStepper({
    steps,
  });

  // Keep the 'fields' config in sync with props
  useEffect(() => {
    if (!isNullOrUndefined(fieldsInit)) {
      if (JSON.stringify(fieldsInit) !== JSON.stringify(fields)) {
        setFields(fieldsInit);
      }
    }
  }, [JSON.stringify(fieldsInit)]);

  /**
   * Allows to replace all matching input values with new ones (useful for updating the form externally after initial creation)
   * @param {object} newData - the field values to merge
   * @param {boolean} forceAdd - TRUE if all newData properties should be added to the input, regardless whether an existing field exists
   */
  const mergeInput = (newData, forceAdd = false) => {
    const newInput = { ...input };

    for (const field in newData) {
      if (forceAdd || hasProperty(newInput, field)) {
        newInput[field] = newData[field];
      }
    }

    setInput(newInput);
  };

  /**
   * Allows to delete input fields externally (for example with a dynamic field config)
   * @param {string[]} fieldsToDelete
   */
  const deleteInput = (fieldsToDelete) => {
    const newInput = { ...input };
    for (const field of fieldsToDelete) {
      if (hasProperty(newInput, field)) {
        delete newInput[field];
      }
    }

    setInput({ ...newInput });
  };

  useEffect(() => {
    getInitialState({
      item,
      fieldConfig,
      setInput,
    });
  }, [formID]);

  // call external onChange function when input changes
  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(input);
    }
  }, [JSON.stringify(input)]);

  /**
   * Adds new properties to input from the given field config handling default values
   * @param {object} newFieldConfigInit - the new field config to add
   * @param {boolean} overwrite - if TRUE, will overwrite existing input values
   */
  const addFieldConfigToInput = (newFieldConfigInit, overwrite = false) => {
    const newFieldConfig = combineFieldConfig({
      fieldConfig: newFieldConfigInit,
    });
    const newInput = overwrite ? {} : { ...input };
    for (const field in newFieldConfig) {
      if (!hasProperty(newInput, field)) {
        const fieldObj = newFieldConfig[field];
        if (fieldObj?.defaultValue) {
          newInput[field] = fieldObj.defaultValue;
        } else {
          newInput[field] = "";
        }
      }
    }
    setInput({ ...newInput });
  };

  const mutationHook = useMutation({
    mutation,
    module,
    resource,
    typename,
    disableRoleChecking,
    route,
  });

  const submit = async () => {
    setSubmitDisabled(true);

    enableToast &&
      addToast({
        id: toastId,
        header: `Creating ${typename ? typename : "item"}..`,
        icon: "spinner",
        color: "success",
      });

    let mutationInput = input;

    // handle default or blank values that will cause graphql errors for certain field types
    mutationInput = handleEmptyFieldInputs({ mutationInput, fieldConfig });

    if (updateInputFunction) {
      mutationInput = await updateInputFunction(input);
    }

    const finalInput = { ...mutationInput };

    if (!item || !item.id) {
      finalInput.ownerGroup = organizationID;
    }

    /**
     * If there is a mutation, an item id, and no submit function,
     * this is now an 'edit' form.
     */
    if (mutation && !submitFunction && item?.id) {
      mutationHook.editItem({ id: item?.id, ...finalInput }).then((data) => {
        afterSubmit(data);
      });
    } else if (mutation && !submitFunction) {
      /**
       * If there is a mutation and no submit function, use the mutation to create a new item
       */
      mutationHook.createItem({ ...finalInput }).then((data) => {
        afterSubmit(data);
      });
    }

    /**
     * If there is a submit function, use this instead of the mutation hook
     */
    if (submitFunction) {
      await submitFunction(mutationInput)?.then((data) => {
        afterSubmit(data);
      });
    }
  };

  /**
   * Invoke this function after you create a new item
   * @param data
   */
  const afterSubmit = (data) => {
    callback?.(data);
    data && getNewItem?.(data);
    !disableResetFunction && !getNewItem && resetFunction?.();
    !disableCancelOnSubmit && cancel();

    if (enableToast) {
      if (data && data.id) {
        updateToast({
          id: toastId,
          header: `${typename || "Item"} was successfully created..`,
          icon: "success",
        });
      } else {
        updateToast({
          id: toastId,
          header: `${typename || "Item"} was not successfully created..`,
          icon: "danger",
        });
      }
    }
  };

  const reset = () => {
    let activeStep = stepper?.activeStep;
    const steps = stepper?.steps;
    if (!isNonEmptyArray(steps) || steps.length === 1) {
      activeStep = null;
    }
    resetCallback && resetCallback();
    getInitialState({
      item,
      fieldConfig,
      input,
      setInput,
      activeStep,
    });
  };

  const deleteItem = async () => {
    if (typeof deleteFunction === "function") {
      await deleteFunction(item);
    }

    tryFunction(resetFunction, toggleModal);
  };

  const cancel = () => {
    getInitialState({
      item,
      fieldConfig,
      setInput,
    });
    toggleModal && toggleModal();
  };

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const checkValid = (fieldName) => {
    const combinedFieldConfig = combineFieldConfig({ fieldConfig });
    if (typeof combinedFieldConfig?.[fieldName]?.validationFunction === "function") {
      return combinedFieldConfig[fieldName].validationFunction(input[fieldName], input);
    } else {
      const isEmptyArray = Array.isArray(input[fieldName]) && input[fieldName].length === 0;

      return (
        combinedFieldConfig[fieldName]?.required &&
        input[fieldName] !== "" &&
        input[fieldName] !== null &&
        input[fieldName] !== undefined &&
        !isEmptyArray
      );
    }
  };

  const checkInvalid = (fieldName) => {
    const combinedFieldConfig = combineFieldConfig({ fieldConfig });

    let inputValue = input[fieldName];
    // check if the input is a string and if it is required, then use the trimmed value for validation
    if (typeof inputValue === "string" && combinedFieldConfig[fieldName]?.required) {
      inputValue = inputValue.trim();
    }

    if (combinedFieldConfig[fieldName]?.validationFunction) {
      return !combinedFieldConfig[fieldName].validationFunction(inputValue, input);
    } else {
      const isEmptyArray = Array.isArray(input[fieldName]) && input[fieldName].length === 0;

      return (
        combinedFieldConfig[fieldName]?.required &&
        (input[fieldName] === "" || input[fieldName] === null || input[fieldName] === undefined || isEmptyArray)
      );
    }
  };

  const confirmDeleteModal = useConfirmDeleteItemModal({
    typename,
    operationQuantity: OperationQuantity.SINGLE,
    onConfirmDelete: deleteItem,
  });

  useEffect(() => {
    let disabled = false;
    const combinedFieldConfig = combineFieldConfig({ fieldConfig });
    Object.entries(combinedFieldConfig).forEach(([fieldName, properties]) => {
      //Check if the field needs to be validated, doesn't if not in the fields array
      if (isNonEmptyArray(fields) && !fields.includes(fieldName)) {
        return;
      }

      // If fields array is included, only do this check for selected fields
      if (checkInvalid(fieldName) || externalFormInvalid) {
        disabled = true;
      }

      // If internal input state is in invalid state also set submit as disabled
      if (internalValidationResults?.[fieldName] === false) {
        disabled = true;
      }
    });

    setSubmitDisabled(disabled);
  }, [input, externalFormInvalid, internalValidationResults]);

  /**
   * Template UI
   */
  const selectTemplateModal = useSelectTemplatesModal({
    typename,
    resource,
    onStartedDuplication: () => {
      if (toggleModal && typeof toggleModal === "function") {
        toggleModal();
      }
    },
    getNewItem,
  });

  const display = (
    <Form onSubmit={(e) => e.preventDefault()} id={id}>
      {confirmDeleteModal?.modal}
      {header}
      {headerButtons && headerButtons.length > 0 && (
        <ButtonGroup className="float-right">
          {headerButtons &&
            headerButtons.map(
              (button, index) =>
                !isNullOrUndefined(button) && button !== false && React.cloneElement(button, { key: index }),
            )}
        </ButtonGroup>
      )}
      {header && <hr />}
      {enableTemplates && selectTemplateModal.modalButton}

      {showStepper && stepper?.display}

      <FormSteps
        fieldConfig={fieldConfig}
        steps={steps}
        stepper={stepper}
        stepProps={{
          typename,
          fields,
          formID,
          input,
          setInput,
          checkValid,
          checkInvalid,
          showOverridesButton,
          showIDField,
          disableItemSelect,
          onIDFieldChange,
          grid,
          setInternalValidationResults,
        }}
      />
      <FormButtons
        stepper={stepper}
        fields={fields}
        fieldConfig={fieldConfig}
        checkInvalid={checkInvalid}
        externalFormInvalid={externalFormInvalid}
        disableResetButton={disableResetButton}
        disableSubmitButton={disableSubmitButton}
        submitDisabled={submitDisabled}
        submit={submit}
        reset={reset}
        deleteItem={() => {
          confirmDeleteModal.show();
        }}
        item={item}
        deleteFunction={deleteFunction}
        resetFunction={resetFunction}
        toggleModal={toggleModal}
        input={input}
        submitButtonText={submitButtonText}
        footerPrefix={footerPrefix}
      />
    </Form>
  );

  return {
    display,
    input,
    setInput,
    mergeInput,
    deleteInput,
    getInitialState: () => {
      getInitialState({
        item,
        fieldConfig,
        setInput,
      });
    },
    addFieldConfigToInput,
    fieldConfig,
    fields,
    setFields,
    submitDisabled,
    stepper,
  };
};
