import { useContext, useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { OrganizationContext } from "../../../utils/Context/OrganizationContext";

/**
 * Automatically logs out the user after a set interval
 * @param signOutModal - the sign-out modal instance
 */
export const useUserTimeout = ({ signOutModal }) => {
  const context = useContext(OrganizationContext);

  /**
   * Check every minute if a user is logged in
   */
  return useEffect(() => {
    const interval = setInterval(() => {
      Auth.currentAuthenticatedUser().catch((err) => {
        EventLogger(`User: ${context?.userEmail} was logged out from the Platform`);
        signOutModal.setModalIsOpen(true);
        clearInterval(interval);
      });
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);
};
