/**
 * Author: Anatoli Railean
 * Created At: 01/08/20
 * Edits:
 *
 * @description: A custom hook for querying DB to get one Object.
 *
 * @param query is the query by it self
 * @param itemId id of the item to get
 * @param onUpdateItemSubscription is the subscription for the object change
 * @param {string} module - the module name
 * @param {string} resource - the resource name
 * @param {boolean} disableRoleChecking - if true, role checking will be disabled
 * @param {string} typename - the typename of the object
 * @param {array} fields - the fields of the object
 * @param {array} nestedFields - the nested fields of the object
 * @param {object} queryExternalApi - the query to external api
 * @param {string} organizationID - the current organization id
 * @param {function} normalizeData - the function to normalize the data (optional)
 *
 *
 * example:
 const getPolicyHook = useQueryGetItem(
 getPolicy,
 "4d8ee38e-6718-4327-a115-eac9f6ed7ed9",
 onUpdatePolicy,
 module,
 resource
 );
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";
import { useEffect, useState } from "react";

import { EventLogger } from "../../utils/EventLogger/EventLogger";
import { fetchData } from "../../views/Tools/Tool/functions/fetchData";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";

export const useQueryGetItem = ({
  query,
  itemId,
  onUpdateItemSubscription,
  module,
  resource,
  disableRoleChecking = false,
  typename,
  fields,
  nestedFields,
  queryExternalApi,
  organizationID,
  normalizeData,
}) => {
  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    disableRoleChecking,
  });

  const [inputId, setInputId] = useState(itemId);
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resetIndex, setResetIndex] = useState(0);

  useEffect(() => {
    if (itemId) {
      setInputId(itemId);
    }
  }, [itemId]);

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true && inputId) {
      setIsLoading(true);
      API.graphql(
        graphqlOperation(typename && fields ? generateGraphql(typename, fields, nestedFields).getQuery : query, {
          id: inputId || undefined,
        }),
      )
        .then(({ data }) => {
          const typeName = Object.keys(data)[0];
          if (typeof normalizeData === "function") {
            setItem(normalizeData(data[typeName]));
          } else {
            setItem(data[typeName]);
          }
          setIsLoading(false);
        })
        .catch((data) => {
          EventLogger(`Error while getting item: ${itemId} ${data?.errors[0]?.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (checkPermissionsHook.resource.read === true && queryExternalApi) {
      fetchData({
        toolType: queryExternalApi?.toolType,
        input: queryExternalApi?.input,
        organizationID,
      })
        .then((res) => setItem(res))
        .catch((err) => EventLogger(err))
        .finally(() => setIsLoading(false));
    }
  }, [inputId, resetIndex, checkPermissionsHook?.resource?.read]);

  useEffect(() => {
    if (checkPermissionsHook.resource.read === true) {
      const subscriptionResult =
        onUpdateItemSubscription &&
        API.graphql(graphqlOperation(onUpdateItemSubscription)).subscribe({
          next: (data) => {
            const typeName = Object.keys(data.value.data)[0];

            const itemOnUpdate = data.value.data[typeName];
            if (item?.id === itemOnUpdate?.id) {
              EventLogger(`Subscription: Item: ${item?.id} was successfully updated`);
              setItem((item) => {
                return { ...item, ...itemOnUpdate };
              });
            }
          },
        });
      return function cleanup() {
        subscriptionResult?.unsubscribe();
      };
    }
  }, [item]);

  return {
    item,
    setItem,
    isLoading,
    setIsLoading,
    inputId,
    setInputId,
    resetIndex,
    reset: () => setResetIndex((resetIndex) => resetIndex + 1),
  };
};

export const QueryGetItem = async ({ query, itemId }) => {
  let item = null;
  await API.graphql(
    graphqlOperation(query, {
      id: itemId || undefined,
    }),
  )
    .then(({ data }) => {
      const typeName = Object.keys(data)[0];

      item = data[typeName];
    })
    .catch((data) => {
      EventLogger(`Error while getting item: ${itemId} ${data?.errors[0]?.message}`);
    });

  return item;
};
