import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import React, { useCallback, useEffect, useState } from "react";
import { isNullOrUndefined, removeDuplicateObjects } from "@rivial-security/func-utils";

import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { useStateEffect } from "../../functional/useStateEffect";

/**
 * Author: Jacob Blazina
 * Created At: 10/22/19
 *
 * Description: A Custom Hook.
 *              Displays a search bar that enables searching through any array based on defined fields.
 *
 *              Note: Currently can only filter for up to 10 fields in the array.
 *
 * @param {object[]} initialArray -
 *              An array of objects to to be searched through.
 *              Ex: [ { name: "Jacob", color: "blue" }, { name: "Bob", color: "red" } ]
 * @param {string[]} searchFields -
 *              An array of strings corresponding to fields that may be searched/filtered by.
 *              These fields much match the objects from above.
 *              Ex. [ "name", "color" ]
 * @param {boolean} showInitialResults -
 *              A boolean value indicating whether to show search results if the search bar is blank,
 *              or only show results during the search operation
 * @param {object} fieldNameDictionary -
 *              An object representing a dictionary of Friendly names for the corresponding database fields.
 *              Ex. { name: "Person's Name", color: "Favorite Color" }
 * @param {object[]} customFields -
 *              An object array containing a possible unique way to generate the search value for a field, if one of
 *              the entries has a valid searchValue property the object customSearchValues is populated with properties
 *              its keys are custom field names and values are a function or other value used to generate a searchable term.
 *              See example in useComplianceActionsList.js.
 * @param tags
 * @returns {object} {{
 *    setSearchResults: (function(*=): void),
 *              A function used to manually set search results
 *    display: *,
 *              A React component that shows the search bar UI
 *    searchResults: Array
 *              The raw array of search results
 * }}
 */

export const useSearchBar = (
  initialArray = [],
  searchFields = [],
  showInitialResults = true,
  fieldNameDictionary,
  customFields = [],
  tags,
  dataTestId,
) => {
  const [data, setData] = useState(initialArray);
  const [searchResults, setSearchResults] = useState(initialArray);
  const [input, setInput] = useState("");
  const [fields, setFields] = useState(searchFields);
  const [filters, setFilters] = useState([]);
  const [currentFilterField, setCurrentFilterField] = useState(null);
  const [filterDropdownIsOpen, setFilterDropdownIsOpen] = useState(false);
  const [operationType, setOperationType] = useState("AND");

  //Convert a standard custom fields array into a customSearchValues object (to improve performance)
  const [customSearchValues, setCustomSearchValues] = useStateEffect({}, [], () => {
    const temp = {};
    for (const field of customFields) {
      if (
        field &&
        field.searchValue !== null &&
        field.searchValue !== undefined &&
        field.field !== null &&
        field.field !== undefined
      ) {
        temp[field.field] = field.searchValue;
      }
    }
    setCustomSearchValues(temp);
  });

  /*
        filter example:
        {
          field: "name",
          value: "jacob"
        }
   */

  useEffect(() => {
    input[0] !== "::" &&
      setSearchResults(
        fields && fields.length > 0 ? data && applyFilters(data) : showInitialResults ? searchResults : [],
      );
  }, [input, data, filters, operationType]);

  /**
   * Handles filter logic from tags
   * @param data
   */
  const handleTagFilters = (data) => {
    // If there are selected tags, do some filtering
    if (tags?.selectedTags?.length > 0) {
      // Return data filtered by tags
      return data.filter((item) => {
        const itemTagLinks = item?.tags?.items;
        // Item has some tags, so check them against selectedTags array
        if (itemTagLinks) {
          for (const itemTagLink of itemTagLinks) {
            // Found at least one matching tag, display in list
            if (tags.selectedTags.some((selectedTag) => selectedTag.id === itemTagLink?.tag?.id)) {
              return true;
            }
            // Did not find a tag, don't display in list
          }
        }
        // Item has no tags, so won't be shown in list
        else {
          return false;
        }
      });
    }
    // If there are no tags, just return the data
    else {
      return data;
    }
  };

  /**
   * Handles AND filters, the data must match all filter criteria
   * @param data
   */
  const handleAND = (data) => {
    // Then handle searchbar filters
    return data
      .filter((item) =>
        filters.length > 0 ? filters[0] && checkFilter(item, filters[0].field, filters[0].value) : true,
      )
      .filter((item) =>
        filters.length > 1 ? filters[1] && checkFilter(item, filters[1].field, filters[1].value) : true,
      )
      .filter((item) =>
        filters.length > 2 ? filters[2] && checkFilter(item, filters[2].field, filters[2].value) : true,
      )
      .filter((item) =>
        filters.length > 3 ? filters[3] && checkFilter(item, filters[3].field, filters[3].value) : true,
      )
      .filter((item) =>
        filters.length > 4 ? filters[4] && checkFilter(item, filters[4].field, filters[4].value) : true,
      )
      .filter((item) =>
        filters.length > 5 ? filters[5] && checkFilter(item, filters[5].field, filters[5].value) : true,
      )
      .filter(
        (item) =>
          // This could probably be simplified and cleaned up with some sort of loop
          checkFilter(item, currentFilterField || fields[0], input) ||
          checkFilter(item, currentFilterField || fields[1], input) ||
          checkFilter(item, currentFilterField || fields[2], input) ||
          checkFilter(item, currentFilterField || fields[3], input) ||
          checkFilter(item, currentFilterField || fields[4], input) ||
          checkFilter(item, currentFilterField || fields[5], input) ||
          checkFilter(item, currentFilterField || fields[6], input) ||
          checkFilter(item, currentFilterField || fields[7], input) ||
          checkFilter(item, currentFilterField || fields[8], input) ||
          checkFilter(item, currentFilterField || fields[9], input) ||
          null,
      );
  };

  /**
   * Handles OR filters, the data must match either filter criteria
   * @param data
   */
  const handleOR = (data) => {
    let res = [];

    for (const filter of filters) {
      res.push(...data.filter((item) => filter && checkFilter(item, filter.field, filter.value)));
    }

    res = removeDuplicateObjects(res);

    if (filters.length > 0) {
      return res.filter(
        (item) =>
          // This could probably be simplified and cleaned up with some sort of loop
          checkFilter(item, currentFilterField || fields[0], input) ||
          checkFilter(item, currentFilterField || fields[1], input) ||
          checkFilter(item, currentFilterField || fields[2], input) ||
          checkFilter(item, currentFilterField || fields[3], input) ||
          checkFilter(item, currentFilterField || fields[4], input) ||
          checkFilter(item, currentFilterField || fields[5], input) ||
          checkFilter(item, currentFilterField || fields[6], input) ||
          checkFilter(item, currentFilterField || fields[7], input) ||
          checkFilter(item, currentFilterField || fields[8], input) ||
          checkFilter(item, currentFilterField || fields[9], input) ||
          null,
      );
    } else {
      return data;
    }
  };

  const applyFilters = (data) => {
    // Handle Tag Filtering first
    const dataFilteredByTags = handleTagFilters(data);

    // Then handle searchbar filters
    if (operationType === "AND") {
      return handleAND(dataFilteredByTags);
    } else if (operationType === "OR") {
      return handleOR(dataFilteredByTags);
    } else {
      return handleAND(dataFilteredByTags);
    }
  };

  /**
   * When selected tags get changed, update search results
   */
  useEffect(() => {
    if (tags?.selectedTags) {
      setSearchResults(applyFilters(data));
    }
  }, [tags?.selectedTags]);

  const checkFilter = (item, field, value) => {
    if (field && field !== "any") {
      let string = "";

      //Check for custom search field value
      if (customSearchValues && customSearchValues[field] != null && customSearchValues[field] != undefined) {
        if (typeof customSearchValues[field] === "function") {
          string = customSearchValues[field](item);
        } else {
          string = customSearchValues[field].toString();
        }
      } else {
        //Get default primary or nested fields
        const splitField = field.split(".");
        const rootField = splitField[0];

        const nestedField1 = splitField[1];
        const nestedField2 = splitField[2];
        const nestedField3 = splitField[3];

        string = item[rootField];

        // nesting level 1
        if (item[rootField] && item[rootField][nestedField1]) {
          string = item[rootField][nestedField1];

          // nesting level 2
          if (item[rootField][nestedField1][nestedField2]) {
            string = item[rootField][nestedField1][nestedField2];

            // nesting level 3
            if (item[rootField][nestedField1][nestedField2][nestedField3]) {
              string = item[rootField][nestedField1][nestedField2][nestedField3];
            }
          }
        }
      }

      return string && string !== "" && string.toString().toLowerCase().indexOf(value.toString().toLowerCase()) !== -1;
    } else {
      return false;
    }
  };

  const removeFilter = (filter) => {
    const temp = filters;
    temp.splice(filters.indexOf(filter), 1);
    setFilters([...temp]);
  };

  const clearFilters = () => {
    filters.length = 0;
  };

  const addFilter = (input) => {
    const split = input.split("::");

    if (split && split.length === 3) {
      setFilters([...filters, { field: split[1], value: split[2] }]);
    } else if (split && split.length === 2) {
      setCurrentFilterField(split[1]);
    } else if (currentFilterField) {
      setFilters([...filters, { field: currentFilterField, value: input }]);
    } else {
      setFilters([...filters, { field: "any", value: input }]);
    }
  };

  const applyCurrentFilterField = (value) => {
    if (fields.includes(value.toString())) {
      setCurrentFilterField(value.toString());
    } else {
      alert("Not a valid field");
    }
  };

  /**
   * Updates an Item in this list.
   * Finds the item by id, then merges in the object fields.
   * Only the present fields get updated
   *
   * Returns the updated item
   * @param item
   */
  const updateItemById = (item) => {
    if (item === null || item === undefined) {
      return item;
    }

    setData((list) => {
      const temp = [...list];
      const foundIndex = temp?.findIndex((x) => x.id === item.id);

      if (foundIndex !== null && foundIndex !== undefined && foundIndex !== -1) {
        temp[foundIndex] = {
          ...temp[foundIndex],
          ...item,
        };

        return temp;
      } else {
        EventLogger("Error, could not find item in list to update");
        return item;
      }
    });
  };

  const searchBar = (
    <>
      <InputGroup>
        {currentFilterField && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Row>
                <Col>
                  {currentFilterField && fieldNameDictionary
                    ? fieldNameDictionary[currentFilterField]
                    : currentFilterField}
                </Col>
                <Col>
                  <Button size="sm" close onClick={() => setCurrentFilterField(null)} />
                </Col>
              </Row>
            </InputGroupText>
          </InputGroupAddon>
        )}
        {!currentFilterField && (
          <InputGroupAddon addonType="prepend">
            <Dropdown
              group
              isOpen={filterDropdownIsOpen}
              toggle={() => setFilterDropdownIsOpen(!filterDropdownIsOpen)}
              size="sm"
            >
              <DropdownToggle style={{ background: "transparent" }}>
                <i className="icon-magnifier" />
              </DropdownToggle>
              <DropdownMenu>
                {fields &&
                  fields.length > 0 &&
                  fields.map((field, index) => (
                    <DropdownItem onClick={() => applyCurrentFilterField(field)} key={field + index}>
                      {field && fieldNameDictionary && fieldNameDictionary[field] ? fieldNameDictionary[field] : field}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          </InputGroupAddon>
        )}
        <Input
          data-testid={`search-bar-${dataTestId}`}
          id={`search-bar-${dataTestId}`}
          disabled={fields.length < 1 || isNullOrUndefined(data) || !Array.isArray(data) || data.length === 0}
          placeholder={fields.length > 0 ? "Search.." : "Searching is Disabled"}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && currentFilterField) {
              addFilter(input);
              setCurrentFilterField(null);
              setInput("");
            } else if (e.key === "Backspace" && input === "" && currentFilterField) {
              setCurrentFilterField(null);
              setInput("");
            } else if (e.key === "Tab") {
              e.preventDefault();
              applyCurrentFilterField(input);
              setInput("");
            }
          }}
        />
      </InputGroup>
      <Row>
        {filters.length > 0 && (
          <span style={{ marginTop: "5px", marginLeft: "15px" }}>
            <ButtonGroup size="sm">
              <Button
                color={operationType === "AND" ? "secondary" : "ghost-secondary"}
                onClick={() => operationType !== "AND" && setOperationType("AND")}
              >
                AND
              </Button>
              <Button
                color={operationType === "OR" ? "secondary" : "ghost-secondary"}
                onClick={() => operationType !== "OR" && setOperationType("OR")}
              >
                OR
              </Button>
            </ButtonGroup>
          </span>
        )}
        {filters.length > 0 &&
          filters.map((filter, index) => (
            <Badge key={`filter_badge${index}`} color="info" pill style={{ marginTop: "5px", marginLeft: "15px" }}>
              <ButtonGroup size="sm">
                <Button disabled color="info">
                  <strong>
                    {`${
                      filter.field && fieldNameDictionary && fieldNameDictionary[filter.field]
                        ? fieldNameDictionary[filter.field]
                        : filter.field
                    }
                      : ${filter.value}`}
                  </strong>
                </Button>
                <Button close onClick={() => removeFilter(filter)} />
              </ButtonGroup>
            </Badge>
          ))}
      </Row>
    </>
  );

  return {
    display: searchBar,
    searchResults,
    updateItemById,
    searchResultsNoInit: input !== "" ? searchResults : [],
    input,
    setInput,
    setData: useCallback((data) => setData(data)),
    data,
    addFilter,
    filters,
    setFilters,
    clearFilters,
    setFields,
    fields,
  };
};
