import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { adaptSFDT } from "./validateSFDT/adaptSFDT";
import { generateSFDTFromURL } from "./generateSFDTFromURL";
import { getSignedObjectUrlV2 } from "../../../../utils/Functions/S3Storage/getSignedObjectUrlV2";

/**
 * Gets a report database info and opens in the referenced editor
 * @param {object} ref - document editor container reference
 * @param {object} report - report info with 'documents' nested field present
 * @param {string} organizationID - selected organization
 * @return {Promise<void>} - loads in the last report document into the referenced document editor
 */
export const openReportInEditor = async ({ ref, report, organizationID }) => {
  // Check arguments
  if (!ref?.documentEditor) {
    EventLogger("Cannot open report because no document editor reference was passed in!");
    return;
  }

  let latestDocument = "";

  const documents = report?.documents?.items;
  if (!Array.isArray(documents) || documents.length === 0) {
    EventLogger("Cannot open report because it contains no documents files!");
    return;
  }

  // Find the latest version document in the report
  let documentsSortedByVersion = documents.sort((a, b) => b.version - a.version);
  latestDocument = documentsSortedByVersion[0];

  // Check if the latest document have a version, if not then use "createdAt" field to calculate the version
  if (!latestDocument?.version) {
    documentsSortedByVersion = documentsSortedByVersion.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    documentsSortedByVersion = documentsSortedByVersion.map((item, index, array) => {
      return { ...item, version: array?.length - index };
    });
    latestDocument = documentsSortedByVersion[0];
  }

  // Check that the latest document has a valid s3 key
  const key = latestDocument?.file?.key;
  if (!key) {
    EventLogger("Cannot open report because the latest document does not have a valid s3 key!");
    return;
  }

  const isSFDT = key.substr(key.lastIndexOf(".")) === ".sfdt";
  const url = await getSignedObjectUrlV2({ key, organizationID });
  const sfdt = await generateSFDTFromURL({ url, isSFDT });
  adaptSFDT({ sfdt }); // updates old sfdt to the latest version
  ref?.documentEditor?.open(sfdt);

  return {
    latestDocument,
    documentsSortedByVersion,
  };
};
