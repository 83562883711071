import CustomTooltip from "../../CustomTooltip";
import { LoadingSpinner } from "../../../LoadingComponents/LoadingSpinner";
import React from "react";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useGUID } from "../../../../hooks/functional/useGUID";

/**
 * Displays a styled Dashboard Card
 * @param {string} title - the title for the top of the card
 * @param {string} [subTitle] - an optional subtitle for the top of the card
 * @param {string} id - a unique ID for this card
 * @param [dataTestId] - a unique id for finding this dashboard in automated tests
 * @param {object} [style] - optional Style object that is passed to the main Card div
 * @param {JSX.Element} content - a React component used for the Card Content, takes precedence over children
 * @param {JSX.Element} children - the widgets to place in default dashboard card content
 * @param {function} onClick - click on dashboard card callback
 * @param {object} wrapperStyle - css inline styles for the dashboard container
 * @param {JSX.Element} headerButtons - dashboard header actions displayed to the right of title
 * @param {JSX.Element} headerContent - a React component used as last child in the card header
 * @param {boolean} isLoading - if TRUE will show loading state for dashboard including spinner instead of icon
 * @param {string|JSX.Element} icon - an icon to display in the dashboard header
 * @param {string} [tooltip] - tooltip text to display helper text for the dashboard card
 * @returns {{display: JSX.Element}}
 */
export const useDashboardCard = ({
  title,
  subTitle,
  content,
  id,
  dataTestId,
  style = { height: "25em", overflowY: "auto" },
  children,
  onClick,
  wrapperStyle = {},
  headerButtons = [],
  headerContent,
  isLoading = false,
  icon,
  tooltip,
}) => {
  const [guid] = useGUID();

  const display = (
    <div data-testid={dataTestId} className="e-card" id={`${id}dashboard-card`} onClick={onClick} style={wrapperStyle}>
      <div className="e-card-header">
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div className="e-card-header-caption">
              <div className="e-card-header-title" style={{ fontSize: "16px" }}>
                {isLoading ? (
                  <LoadingSpinner style={{ marginRight: "0.5em" }} />
                ) : (
                  icon &&
                  (typeof icon === "string" ? (
                    <i className={icon} style={{ marginRight: "0.75em", marginLeft: "3px" }} />
                  ) : (
                    React.cloneElement(icon, { style: { marginRight: "1em" } })
                  ))
                )}
                {title}
                {tooltip && <CustomTooltip tooltip={tooltip} />}
                {subTitle && (
                  <div
                    style={{
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {subTitle}
                  </div>
                )}
              </div>
            </div>
            {headerButtons &&
              headerButtons.map(
                (button, index) => !isNullOrUndefined(button) && React.cloneElement(button, { key: guid + index }),
              )}
          </div>

          {headerContent}
        </div>
      </div>
      <div className="e-card-content" style={style}>
        {content || children}
      </div>
    </div>
  );

  return { display };
};
