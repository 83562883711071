import { generateGraphql } from "@rivial-security/generategraphql";
import { GetQuery } from "@rivial-security/appsync-utils";

/**
 * Retrieves the current operation team information for user that are part of an operation team
 * @param {string} operationTeamID - the id of the operation team for which to retrieve the information
 * @return {Promise<{}|{id}|*>}
 */
export const getMinimalOperationTeam = async ({ operationTeamID }) => {
  const { getQuery } = generateGraphql("OperationTeam", ["name", "s3BucketName"]);

  const operationTeam = await GetQuery({
    query: getQuery,
    variables: { id: operationTeamID },
  });

  if (operationTeam?.id) {
    return operationTeam;
  }
  return {};
};
