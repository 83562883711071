import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { ItemMutation } from "../../../Functions/Graphql/ItemMutation";
import { GetQuery } from "../../../Functions/Graphql/GetQuery";
import { EventLogger } from "../../../EventLogger/EventLogger";

/**
 * Function that handles link updates for the NestedField component (both when updating to a new link and when removing a link)
 * NOTE: If 'newChildId' is null or undefined, then the link is being removed
 * @param {string} field - the field that will have the information about the linked item (child)
 * @param {string} idField - the id field for the child item on the parent item
 * @param {string} typename - the type of the item to link
 * @param {string} parentIdField - the id field for the parent on the child item
 * @param {string} parentTypename - the type of the 'item' parameter
 * @param {boolean} [biDirectional=false] - if TRUE, will attempt to manage deletion and updates of 1-1 links (needs parentIdField)
 * @param {boolean} [disableEdits=false] - if TRUE, then the link cannot be edited
 * @param {object} item - the parent item information with the current link
 * @param {string} newChildId - the id of the new child to link to the parent
 * @return {Promise<void>}
 */
export const updateNestedField = async ({
  field,
  idField,
  typename,
  parentIdField = "",
  parentTypename,
  biDirectional,
  disableEdits = false,
  item,

  newChildId,
}) => {
  const oldChildId = item?.[idField] || item?.[field]?.id;

  if (disableEdits) {
    return;
  }

  //No need for mutations if the new child is the same as the old one
  if (newChildId === oldChildId) {
    return;
  }

  const { getQuery: getChildQuery, updateMutation: updateChildMutation } = generateGraphql(typename, [parentIdField]);
  const { updateMutation: updateParentMutation } = generateGraphql(parentTypename, [idField]);

  if (isNullOrUndefined(newChildId)) {
    // all the connections
    if (idField && item?.id) {
      await ItemMutation(updateParentMutation, {
        id: item?.id,
        [idField]: null,
      });
    } else {
      EventLogger("Could not delete connection on parent - ", {
        idField,
        item,
      });
    }

    if (biDirectional && parentIdField && oldChildId) {
      await ItemMutation(updateChildMutation, {
        id: oldChildId,
        [parentIdField]: null,
      });
    } else {
      if (!biDirectional) {
        EventLogger("Could not delete connection on child - ", {
          biDirectional,
          parentIdField,
          oldChildId,
          item,
        });
      }
    }
  } else {
    // Update the link from both sides as necessary
    if (idField && item?.id) {
      await ItemMutation(updateParentMutation, {
        id: item?.id,
        [idField]: newChildId,
      });
    } else {
      EventLogger("Could not update connection on parent - ", {
        idField,
        item,
      });
    }

    if (biDirectional && newChildId && item?.id && parentIdField) {
      //First check if the new child already has a connection (remove the 1-1 connections if so)
      const childItem = await GetQuery({
        query: getChildQuery,
        variables: {
          id: newChildId,
        },
      });
      if (childItem?.[parentIdField]) {
        //Using recursion to delete links of the new child (reversing parent and child
        await updateNestedField({
          field: undefined,
          idField: parentIdField,
          typename: parentTypename,
          parentIdField: idField,
          parentTypename: typename,
          biDirectional,
          disableEdits,
          item: childItem,
          newChildId: null,
        });
      }

      //Also delete the connection of the old child
      if (oldChildId) {
        await ItemMutation(updateChildMutation, {
          id: oldChildId,
          [parentIdField]: null,
        });
      }

      await ItemMutation(updateChildMutation, {
        id: newChildId,
        [parentIdField]: item?.id,
      });
    } else {
      if (!biDirectional) {
        EventLogger("Could not update connection on child - ", {
          biDirectional,
          parentIdField,
          newLinkedItemId: newChildId,
          item,
        });
      }
    }
  }
};
