import { Button, Modal, ModalBody } from "reactstrap";
import {
  DocumentEditorContainerComponent,
  Editor,
  EditorHistory,
  ParagraphDialog,
  Print,
  Search,
  Selection,
  SfdtExport,
  TextExport,
  Toolbar,
  WordExport,
} from "@syncfusion/ej2-react-documenteditor";
import { REPORT_JOB_TYPE, createText } from "@rivial-security/report-utils";
import React, { useEffect, useRef, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Alert } from "@mui/material";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import InsertWidget from "../components/InsertWidget";
import LoadingOverlay from "react-loading-overlay";
import { LoadingSpinner } from "../../../../utils/LoadingComponents/LoadingSpinner";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { adaptSFDT } from "../functions/validateSFDT/adaptSFDT";
import { createSingleWidget } from "../functions/createSingleWidget";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { pasteReportSection } from "../functions/pasteReportSection";
import { platformSettings } from "../../../../platformSettings";
import { useAddPolicyVersion } from "../../../Program/Policies/hooks/useAddPolicyVersion";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDocumentEditorDrawer } from "./useDocumentEditorDrawer";
import { useHistory } from "react-router-dom";
import { useJobTracker } from "../../../../hooks/graphql/useJobTracker/useJobTracker";
import { useModal } from "../../../../hooks/views/useModal";
import { useOrganizationContext } from "../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { useRefState } from "../../../../hooks/functional/useRefState";
import { useReportConfiguration } from "./useReportConfiguration";
import { useUIContext } from "@utils/Context/UIContext";

DocumentEditorContainerComponent.Inject(Toolbar);
DocumentEditorContainerComponent.Inject(Print);
DocumentEditorContainerComponent.Inject(Selection);
DocumentEditorContainerComponent.Inject(Search);
DocumentEditorContainerComponent.Inject(WordExport);
DocumentEditorContainerComponent.Inject(SfdtExport);
DocumentEditorContainerComponent.Inject(TextExport);
DocumentEditorContainerComponent.Inject(Editor);
DocumentEditorContainerComponent.Inject(EditorHistory);
DocumentEditorContainerComponent.Inject(ParagraphDialog);

export const DOCUMENT_EDITOR_MODE = {
  EDIT: "edit",
  VIEW: "view",
};

/**
 * @description Create and update Reports
 * @param {string} organizationID
 * @param {string} moduleInit - module to use for the Documents and saving reports
 * @param {string} typeInit - the report type, used in the report file name and for saving
 * @param {string} descriptionInit - used when saving reports
 * @param {object} policyDetails - policy details object for creating new policies
 * @param {string} itemId - policy details object for creating new policies
 * @param {string} defaultTemplate - used to automatically load a template when the document editor opens
 * @param {boolean} [inModal = true] - if TRUE will change styling so that editor expands in modal, set to false for no scroll pages
 * @param {string} mode - either edit or view, determines how th editor looks
 * @param {boolean} isExternalLoading - shows/hides the overlay with spinner
 * @param {number} [defaultTabIndex = 0] - default doc editor drawer tab. 0 = create, 1 = open, 2 = save
 * @param {string} sfdtToLoad - SFDT JSON to load into the editor
 * @param props
 * @return {object} {{isLoading: boolean, ref: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, menuOnClick: (function(*, *=): Promise<void>), display: JSX.Element}}
 */
export const useDocumentEditor = ({
  organizationID,
  module: moduleInit = "Custom",
  type: typeInit = "Custom",
  description: descriptionInit,
  policyDetails,
  itemId,
  defaultTemplate,
  inModal = true,
  mode = DOCUMENT_EDITOR_MODE.EDIT,
  isExternalLoading = false,
  defaultTabIndex = 0,
  sfdtToLoad,
  ...props
}) => {
  const { isMobile } = useUIContext();
  const organizationContext = useOrganizationContext();

  const checkPermissions = useCheckPermissions({
    module: modules.REPORTS,
    resource: resources.REPORT,
  });

  /**
   * Used to hold a reference to the DocumentEditor component.
   */
  const [ref, setRef, documentEditorContainerRef] = useRefState("");
  const currentRef = useRef(null);
  useEffect(() => {
    currentRef.current = ref;
  }, [ref, mode]);

  const [isLoading, setIsLoading] = useState(false);
  const [lastReportGenerateJob, setLastReportGenerateJob, lastReportGenerateJobRef] = useRefState(null);
  const [, setCanceledRedirect, canceledRedirectRef] = useRefState(false);

  const saveAsWordBlob = async () => {
    if (ref.documentEditor) {
      return await ref.documentEditor.saveAsBlob("Docx");
    }
  };

  /**
   * Calls the passed-in function with 'reportContext' as a single argument.
   * The pass-in function should return an SFDT JSON
   * that is passed to the documentEditor's 'open' funciton.
   */
  const generateFromTemplate = async (func, config = {}) => {
    const ref = currentRef?.current;

    setIsLoading(true);
    const reportContext = { organizationID, config, ...organizationContext };
    if (func) {
      const sfdt = await func(reportContext);

      if (sfdt) {
        adaptSFDT({ sfdt }); // updates old sfdt to the latest version
        await ref?.documentEditor?.open(sfdt);
      }
    } else {
      EventLogger("[useDocumentEditor.js] Error: No function passed in");
    }

    setIsLoading(false);
  };

  /**
   * Handles functionality for custom report configs
   */
  const { handleTemplateClick, modal: reportConfigurationModal } = useReportConfiguration(generateFromTemplate);

  /**
   * Hook used for Policy Support
   */
  const addPolicyVersion = useAddPolicyVersion({
    policyId: policyDetails?.id,
    organizationID,
    saveAsWordBlob,
    ...policyDetails,
  });

  /**
   * If true, tags will not be converted to Widgets.
   * Used in order to load a custom template with tags for more editing
   */
  const [preserveTags, setPreserveTags] = useState(false);

  /**
   * Track ongoing jobs that were started within the editor
   */
  const history = useHistory();
  const jobTracker = useJobTracker({
    onStarted: async (job) => {
      switch (job?.type) {
        case REPORT_JOB_TYPE.GENERATE_TEMPLATE:
          setIsLoading(true);
          setCanceledRedirect(false);
          setLastReportGenerateJob(job);
          break;
        case REPORT_JOB_TYPE.RENDER_WIDGET:
          return {
            id: job?.id,
            header: "Creating a widget...",
            icon: "spinner",
          };
      }
    },

    onComplete: async (job) => {
      switch (job?.type) {
        case REPORT_JOB_TYPE.GENERATE_TEMPLATE:
          const result = JSON.parse(job?.result);
          const reportId = result?.reportId;

          if (lastReportGenerateJobRef?.current?.id === job.id && canceledRedirectRef?.current !== true) {
            if (reportId) {
              history.push({
                pathname: `/reports/list/${reportId}`,
                state: { editOnStart: true },
              });
            }
            setIsLoading(false);
          }
          break;
        case REPORT_JOB_TYPE.RENDER_WIDGET:
          let widgetBlock;
          try {
            widgetBlock = JSON.parse(job?.result);
          } catch (e) {
            widgetBlock = createText("[Invalid Widget Result]");
            EventLogger("[useDocumentEditor.js] Error: Failed to render widget", e);
          }
          // check if widgetBlock nt null and not empty
          try {
            if (
              !isNullOrUndefined(widgetBlock) &&
              typeof widgetBlock === "object" &&
              Object.keys(widgetBlock).length > 0
            ) {
              await pasteReportSection(ref, [widgetBlock]);
              return {
                id: job?.id,
                header: "Finished creating a widget.",
                icon: "success",
              };
            }
          } catch (e) {
            EventLogger("[useDocumentEditor.js] Error: Failed to render final widget", e);
            EventLogger(e.stack);
            return {
              id: job?.id,
              header: "Failed to create a widget.",
              icon: "error",
            };
          }
          break;
      }
    },
    onFailed: async (job) => {
      setIsLoading(false);

      jobFailedModal?.setModalIsOpen(true);
    },
  });

  const documentEditorWrapperRef = useRef(null);

  /**
   * Side/Top bar with File Menu Options
   */
  const drawer = useDocumentEditorDrawer({
    documentEditorContainerRef, //document editor container reference
    organizationID,
    preserveTags,
    onJobStarted: jobTracker.addJob,
    handleTemplateClick,
    generateFromTemplate,
    initWidth: "22em",
    defaultTabIndex,
    documentEditorWrapperRef,
  });

  /**
   * Handler for generating new reports manually with preselected config objects
   */
  const menuOnClick = async (reportId, config = {}) => {
    await drawer?.generateReport({ reportId, config });
  };

  /**
   * Converts the Widget configuration and Query results into an SFDT element
   * and inserts it into the Report at the Cursor location
   * @param {object} widget - all avaliable data for the widget to insert
   */
  const insertWidgetCallback = async (widget) => {
    await createSingleWidget({
      organizationID,
      widget,
      ref,
      onJobStarted: jobTracker.addJob,
    });
  };

  /**
   * Allows the user to select a widget to insert into report
   */
  const insertWidgetModal = useModal(
    "Select a Widget to Insert",
    <InsertWidget insertWidgetCallback={insertWidgetCallback} />,
    null,
    {
      width: "80vw",
    },
  );

  /**
   * Allows the user to select a widget to insert into report
   */
  const insertWidgetTagModal = useModal(
    "Select a Widget Placeholder to Insert",
    <InsertWidget widgetTag={true} insertWidgetCallback={insertWidgetCallback} />,
    null,
    {
      width: "80vw",
    },
  );

  const checkJobPermissions = useCheckPermissions({
    module: modules.ORGANIZATION_MANAGER,
    resource: resources.JOB,
  });

  /**
   * Display user a failed report status
   */
  const jobFailedModal = useModal(
    "Report Job Failed",
    <div>
      <Alert color={"error"} style={{ marginBottom: "1em" }}>
        Report or Widget was not successfully generated.{" "}
        {checkJobPermissions.resource.read && (
          <span>
            <Button
              color="link"
              onClick={() => {
                history.push({
                  pathname: `/organization_manager/jobs/${lastReportGenerateJob?.id}`,
                });
              }}
            >
              Click Here
            </Button>{" "}
            for more details.
          </span>
        )}
      </Alert>
    </div>,
    null,
    {
      width: "30vw",
    },
  );

  /**
   * Handles the custom Context Menu item onClicks
   * @param item
   */
  const handleContextMenuSelect = (item) => {
    const menuItemId = item.id.split("_editor")[1];
    switch (menuItemId) {
      case "insert_a_widget":
        insertWidgetModal.setModalIsOpen(true);
        break;
      case "insert_a_widget_tag":
        insertWidgetTagModal.setModalIsOpen(true);
        break;
      default:
        break;
    }
  };

  /**
   * Set-Up Custom Context Menu options
   */
  const setCustomContextMenuOptions = () => {
    let newMenuItems = [];
    if (checkPermissions.resource.create && mode === DOCUMENT_EDITOR_MODE.EDIT) {
      newMenuItems = [
        {
          text: "Insert a Widget",
          id: "insert_a_widget",
          iconCss: "e-icons e-de-ctnr-find",
        },
        {
          text: "Insert a Widget Placeholder",
          id: "insert_a_widget_tag",
          iconCss: "e-icons e-de-ctnr-find",
        },
      ];
    }

    drawer.setIsOpen(mode === DOCUMENT_EDITOR_MODE.EDIT);
    ref.documentEditor?.contextMenu.addCustomMenu(newMenuItems, false);
    ref.documentEditor.customContextMenuSelect = (args) => handleContextMenuSelect(args);
  };

  /**
   * Sets up custom document editor configs on create
   */
  const onCreate = () => {
    setCustomContextMenuOptions();
    ref.documentEditor.enableLocalPaste = true;
    if (!isNullOrUndefined(defaultTemplate)) {
      menuOnClick(defaultTemplate, { itemId, ...props });
    }
  };

  /**
   * Perform custom document editor initializations
   */
  useEffect(() => {
    if (ref?.documentEditor) {
      EventLogger("Initializing Document Editor..");
      onCreate();
    }
  }, [ref?.documentEditor, mode]);

  /**
   * Dynamically fetches the list of toolbar items
   */
  const getToolbarItems = () => {
    const preserveWidgetTags = {
      prefixIcon: "e-de-ctnr-lock",
      tooltipText: preserveTags
        ? "Won't dynamically populate Widget Placeholders when generating from Custom Templates"
        : "Will dynamically populate Widget Placeholders when generating from Custom Templates",
      text: preserveTags ? "Preserving Placeholders" : "Populating Widgets",
      id: "PRESERVE_WIDGET_TAGS",
    };

    // TODO: Fix memory leak with dynamic menu option when edit mode is toggled on and off quickly in report details
    // const closeShown = drawer.isOpen === true;
    // let fileOptionsSidebar = {
    //   prefixIcon: closeShown === true ? "e-close" : "e-menu",
    //   tooltipText: "File Options Toggle",
    //   text: closeShown ? "Close File Options" : "Open File Options",
    //   id: "FILE_OPTIONS",
    // }

    // WARNING: this is a static text and icon on purpose see note above
    const fileOptionsSidebar = {
      prefixIcon: "e-menu",
      tooltipText: "File Options Toggle",
      text: "Toggle File Options",
      id: "FILE_OPTIONS",
    };

    return [
      fileOptionsSidebar,
      "New",
      "Open", // NOTE: moved to file options (button hidden programmatically - do not comment out)
      "Separator",
      "Undo",
      "Redo",
      "Separator",
      preserveWidgetTags,
      "Separator",
      "Image",
      "Table",
      "Hyperlink",
      // "Bookmark",
      // "Comments",
      "TableOfContents",
      "Separator",
      "Header",
      "Footer",
      "PageSetup",
      "PageNumber",
      "Break",
      "Separator",
      "Find",
      "Separator",
      "LocalClipboard",
      // "RestrictEditing"
    ];
  };

  // Upon change of sfdtToLoad, open the new document
  useEffect(() => {
    if (ref && sfdtToLoad) {
      ref?.documentEditor?.open(sfdtToLoad);
    }
  }, [sfdtToLoad]);

  /**
   * On Click handler for custom toolbar items
   * @param args
   */
  const onToolbarClick = (args) => {
    switch (args.item.id) {
      case "PRESERVE_WIDGET_TAGS":
        setPreserveTags((preserveTags) => !preserveTags);
        break;
      case "FILE_OPTIONS":
        drawer.setIsOpen((isOpen) => !isOpen);
        break;
      default:
        break;
    }
  };

  const display = (
    <PermissionsOverlay module={modules.REPORTS} resource={resources.REPORT}>
      <div
        ref={documentEditorWrapperRef}
        style={{
          height: "calc(100% - 10px)",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          ...(inModal === true && { width: "100%" }),
          ...(inModal === true && { height: "90vh" }),
        }}
        data-tourid={"document-editor-container"}
      >
        <LoadingOverlay
          active={isExternalLoading}
          spinner
          text="Loading Document..."
          styles={{
            wrapper: {
              position: "relative",
              display: "inherit",
              flexGrow: "inherit",
              flexDirection: "inherit",
              width: "100%",
            },
            overlay: (base) => ({
              ...base,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }),
          }}
        >
          <div
            id={"document-editor-div-container"}
            style={{
              maxHeight: "100%",
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div>
              {insertWidgetModal.modal}
              {insertWidgetTagModal.modal}
              {addPolicyVersion?.pleaseWait?.modal}
              {reportConfigurationModal}
              {jobFailedModal.modal}
              <div style={{ background: "white" }}>
                <Modal isOpen={isLoading}>
                  <ModalBody>
                    <LoadingSpinner loadingMessage="Generating Report from Template. Please Wait.. " />
                    <Button
                      size="sm"
                      className="float-right"
                      close
                      onClick={() => {
                        setIsLoading(false);
                        setCanceledRedirect(true);
                      }}
                    />
                    <br />
                    <br />
                    <p>
                      {" "}
                      You may close this modal if you do not wish to be redirected. Your report will still be saved to
                      the reports module.{" "}
                    </p>
                  </ModalBody>
                </Modal>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                overflow: "hidden",
                display: !isMobile || (isMobile && !drawer.isOpen) ? "block" : "none",
              }}
            >
              <DocumentEditorContainerComponent
                ref={(r) => {
                  if (r) {
                    setRef(r);
                  }
                }}
                serviceUrl={`${platformSettings.syncfusionServiceUrl}/api/documenteditor/`}
                // DO NOT USE THIS, breaks chart colors documentEditorSettings={{ optimizeSfdt: false }}
                // reference : https://ej2.syncfusion.com/documentation/document-editor/how-to/optimize-sfdt/
                height={"100%"}
                width={"100%"}
                showPropertiesPane={false}
                enableToolbar={mode === DOCUMENT_EDITOR_MODE.EDIT}
                restrictEditing={mode === DOCUMENT_EDITOR_MODE.VIEW}
                toolbarItems={getToolbarItems()}
                toolbarClick={onToolbarClick}
                enableParagraphDialog={true}
              />
            </div>
          </div>
          {drawer.display}
        </LoadingOverlay>
      </div>
    </PermissionsOverlay>
  );

  return {
    display,
    menuOnClick,
    ref,
    isLoading,
    setIsLoading,
    generateFromTemplate,
    saveAsWordBlob,
    destroy: ref?.documentEditor?.destroy,
    addPolicyVersion,
    resize: ref?.documentEditor?.resize,
    drawer,
  };
};
