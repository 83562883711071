import React, { useContext, useEffect, useRef, useState } from "react";
import { usePDFViewer } from "../../../../../hooks/views/usePDFViewer/usePDFViewer";
import { useSelectArtifactLabelPopover } from "./useSelectLabelPopover/useSelectArtifactLabelPopover";
import { convertSelectionToLabelAnnotation } from "../../../../../hooks/views/usePDFViewer/functions/convertSelectionToLabelAnnotation";
import { OrganizationContext } from "../../../../../utils/Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { createArtifactLabelLink } from "../../../../../utils/Labels/functions/createLabelLinks/createArtifactLabelLink";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { v4 as uuid } from "uuid";
import ArtifactLabelAnnotation from "../components/ArtifactLabelAnnotation";
import { retrieveUpToDateLabelLinks } from "../functions/retrieveUpToDateLabelLinks";
import { useUIContext } from "@utils/Context/UIContext";
import { deleteArtifactLabelLinkBookmark } from "../functions/deleteArtifactLabelLinkBookmark";

/**
 * A pdf viewer configured for annotating content with labels (creating bookmarks)
 * @param {object} artifact - full artifact from the database
 * @param {string} organizationID - the current selected organization id
 * @param {object} detailsHook - the artifact details hook to control what data is displayed
 * @param {string} emphasizedLabel - the label that the user tried to find last
 * @returns {{ref: *, display: JSX.Element}}
 */
export const usePDFLabelAnnotator = ({ artifact, organizationID, detailsHook, emphasizedLabel }) => {
  const { userEmail } = useContext(OrganizationContext);
  const [selection, setSelection] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const { addToast, updateToast } = useUIContext();

  //Stores label id -> last emphasized bookmark id map (so that each subsequent emphasis will scroll to the next bookmark)
  const [annotationShowcase, setAnnotationShowcase] = useState({});
  const [emphasizedAnnotation, setEmphasizedAnnotation] = useState(null);

  /**
   * Get all annotations from the artifact and apply them to the viewer
   */
  useEffect(() => {
    const newAnnotations = [];
    const labelLinks = artifact?.labels?.items || [];
    if (artifact?.labels?.items) {
      for (const labelLink of labelLinks) {
        const bookmarks = labelLink?.bookmarks || [];
        if (Array.isArray(bookmarks)) {
          for (const bookmark of bookmarks) {
            try {
              const parsedData = JSON.parse(bookmark?.data);

              //skip annotations without version data
              if (isNullOrUndefined(parsedData?.version)) {
                continue;
              }

              //append label link color or fallback to yellow
              newAnnotations.push({
                ...parsedData,
                id: bookmark?.id, // for label annotations the bookmark id is the annotation id
                labelLinkID: labelLink?.id,
                bookmarkID: bookmark?.id,
                color: labelLink?.label?.backgroundColor || "yellow",
              });
            } catch (e) {
              EventLogger("Failed to parse bookmark data");
            }
          }
        }
      }
    }
    setAnnotations([...newAnnotations]);
  }, [JSON.stringify(artifact?.labels?.items)]);

  /**
   * When a label is selected for emphasis, scroll to the annotation and show an overlay over all other content
   * temporarily highlighting the annotation
   */
  useEffect(() => {
    if (!emphasizedLabel || !isNonEmptyArray(annotations)) {
      return;
    }

    //find the annotation to emphasize
    const emphasizedLabelLinkID = emphasizedLabel?.labelLinkID;
    const previouslyEmphasizedAnnotationIndex = annotationShowcase[emphasizedLabelLinkID];
    const startIndex = isNullOrUndefined(previouslyEmphasizedAnnotationIndex)
      ? 0
      : previouslyEmphasizedAnnotationIndex + 1;

    let foundAnnotation, foundAnnotationIndex;

    // - look through the remainder of the annotations to find the next annotation to emphasize
    if (startIndex < annotations.length) {
      for (let i = startIndex; i < annotations.length; i++) {
        if (annotations[i]?.labelLinkID === emphasizedLabelLinkID) {
          foundAnnotation = annotations[i];
          foundAnnotationIndex = i;
          break;
        }
      }
    }
    // - if we didn't find an annotation, look through the annotations before the start index
    if (startIndex > 0 && !foundAnnotation) {
      for (let i = 0; i < Math.min(startIndex, annotations.length); i++) {
        if (annotations[i]?.labelLinkID === emphasizedLabelLinkID) {
          foundAnnotation = annotations[i];
          foundAnnotationIndex = i;
          break;
        }
      }
    }

    //if found an annotation to emphasize save its index and data
    if (foundAnnotation) {
      setAnnotationShowcase({
        ...annotationShowcase,
        [emphasizedLabelLinkID]: foundAnnotationIndex,
      });
      setEmphasizedAnnotation({
        ...foundAnnotation,
        emphasisID: emphasizedLabel?.emphasisID,
      });
    }
  }, [emphasizedLabel]);

  /// [EVENTS
  const onLabelSelection = async ({ selectedLabel }) => {
    //Check if there is any highlighted text info
    if (!selection) {
      return;
    }

    //Generate the new annotation object
    const bookmarkID = uuid();
    const newAnnotation = convertSelectionToLabelAnnotation({
      userEmail,
      label: selectedLabel,
      selection,
      bookmarkID,
    });

    // Update the local state of annotations
    if (newAnnotation) {
      setAnnotations((annotations) => [...annotations, newAnnotation]);
    } else {
      EventLogger("Failed to convert selection to annotation!");
      return;
    }

    // Generate the new bookmark object
    const bookmarkData = { ...newAnnotation };
    delete bookmarkData.color;
    const newBookmark = {
      id: bookmarkID,
      owner: userEmail,
      text: selection?.text,
      data: JSON.stringify(bookmarkData),
    };

    // retrieve current list of labels links
    const databaseLabelLinks = await retrieveUpToDateLabelLinks({
      artifactID: artifact?.id,
    });

    // check if the label is already linked to the artifact
    const foundArtifactLabelLink = databaseLabelLinks.find((link) => link?.label?.id === selectedLabel?.id);

    // if the label is already linked to the artifact, we need to update the label link
    try {
      if (foundArtifactLabelLink) {
        const { updateMutation } = generateGraphql("ArtifactLabelLink", ["bookmarks"], {
          bookmarks: `{ id createdByAI owner text data }`,
        });

        const newBookmarks = [...(foundArtifactLabelLink?.bookmarks || []), newBookmark];

        // Update the label link with the new bookmark
        await ItemMutation(updateMutation, {
          id: foundArtifactLabelLink?.id,
          bookmarks: newBookmarks,
        });
        foundArtifactLabelLink.bookmarks = newBookmarks;

        detailsHook.setItem((item) => ({
          ...item,
          labels: {
            items: [...databaseLabelLinks],
          },
        }));
      } else {
        // Label is not linked to artifact, so we need to create a new link and bookmark
        const newArtifactLabelLink = await createArtifactLabelLink({
          organizationID,
          artifact,
          label: selectedLabel,
          bookmarks: [newBookmark],
        });

        detailsHook.setItem((item) => ({
          ...item,
          labels: {
            items: [...databaseLabelLinks, newArtifactLabelLink],
          },
        }));
      }
    } catch (e) {
      EventLogger("Failed to create artifact label link", e);
      addToast({
        header: `Failed to add a bookmark`,
        icon: "danger",
      });
      detailsHook.setItem((item) => ({
        ...item,
        labels: {
          items: [...databaseLabelLinks],
        },
      }));
    }

    setSelection(null);
  };

  const onLabelAnnotationDeletion = async ({ deletedAnnotation }) => {
    const showFailure = (e) => {
      EventLogger("Failed to delete artifact label bookmark", e);
      addToast({
        header: `Failed to delete a bookmark`,
        icon: "danger",
      });
    };

    // locally remove the annotation
    const previousAnnotations = [...annotations];
    setAnnotations((annotations) =>
      annotations.filter((shownAnnotation) => shownAnnotation?.bookmarkID !== deletedAnnotation?.bookmarkID),
    );

    // retrieve current list of labels links, if failed rollback change
    let databaseLabelLinks = [];
    try {
      databaseLabelLinks = await retrieveUpToDateLabelLinks({
        artifactID: artifact?.id,
      });
    } catch (e) {
      setAnnotations(previousAnnotations);
      showFailure(e);
      return;
    }

    try {
      // try to delete the bookmark from the label link
      const updatedLabelLink = await deleteArtifactLabelLinkBookmark({
        ...deletedAnnotation,
      });

      // if successful, update the local state of the artifact
      databaseLabelLinks = databaseLabelLinks.map((link) => {
        if (link?.id === updatedLabelLink?.id) {
          return updatedLabelLink;
        }
        return link;
      });

      detailsHook.setItem((item) => ({
        ...item,
        labels: {
          items: [...databaseLabelLinks],
        },
      }));
    } catch (e) {
      showFailure(e);
      detailsHook.setItem((item) => ({
        ...item,
        labels: {
          items: [...databaseLabelLinks],
        },
      }));
    }
  };

  /**
   * A pdf viewer configured for annotating content with labels (creating bookmarks)
   */
  const pdfViewer = usePDFViewer({
    file: artifact?.file,
    annotations,
    organizationID,
    features: {
      annotations: {
        enabled: true,
      },
    },

    emphasizedAnnotation,
    annotationComponent: <ArtifactLabelAnnotation onDelete={onLabelAnnotationDeletion} />,
    onTextSelected: (selection) => {
      //ignore event if selected text is just white space or empty
      const trimmedText = selection?.text?.trim();
      if (trimmedText) {
        //open popover when some text is selected
        selectLabel?.openPopover();
        setSelection(selection);
      }
    },
  });

  const containerRef = useRef(null);
  const selectLabel = useSelectArtifactLabelPopover({
    artifact,
    onLabelSelection,
    organizationID,
    containerRef,
  });

  const display = (
    <div ref={containerRef} style={{ height: "100%" }}>
      {pdfViewer.display}
      {selectLabel.display}
    </div>
  );

  return { ...pdfViewer, display };
};
