export const recommendationRatings = ["info", "low", "lowMedium", "medium", "mediumHigh", "high"];

export const RECOMMENDATION_RATINGS = {
  info: 0,
  low: 1,
  lowMedium: 2,
  medium: 3,
  mediumHigh: 4,
  high: 5,
};
