import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Add Agenda Items to Meeting
 * @param {object[]} agendaItems
 * @param {object} newMeeting
 * @param {string} organizationID
 * @return {object} {Promise<void>}
 */
export const addAgendaItemsToMeeting = async ({ agendaItems = [], newMeeting, organizationID }) => {
  if (
    isNullOrUndefined(agendaItems) ||
    isNullOrUndefined(newMeeting) ||
    isNullOrUndefined(newMeeting.id) ||
    organizationID === ""
  ) {
    throw new Error("[addAgendaItemsToMeeting.js] Invalid function input");
  }

  const { createMutation } = generateGraphql("AgendaItem", ["content"]);

  for (const item of agendaItems) {
    await ItemMutation(createMutation, {
      content: item,
      ownerGroup: organizationID,
      meetingAgendaItemsId: newMeeting.id,
    }).catch((err) => {
      EventLogger("[addAgendaItemsToMeeting.js] Cannot create AgendaItem", err);
    });
  }
};
