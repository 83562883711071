export const deleteSystemRiskThreatLink_minimal = /* GraphQL */ `
  mutation DeleteSystemRiskThreatLink($input: DeleteSystemRiskThreatLinkInput!) {
    deleteSystemRiskThreatLink(input: $input) {
      id
      systemRiskLink {
        id
      }
    }
  }
`;

export const deleteSystemControlCategoryLink_minimal = /* GraphQL */ `
  mutation DeleteSystemControlCategoryLink($input: DeleteSystemControlCategoryLinkInput!) {
    deleteSystemControlCategoryLink(input: $input) {
      id
    }
  }
`;

export const deleteSystemChangeLink_minimal = /* GraphQL */ `
  mutation DeleteSystemChangeLink($input: DeleteSystemChangeLinkInput!) {
    deleteSystemChangeLink(input: $input) {
      id
    }
  }
`;

export const deleteSystem_minimal = /* GraphQL */ `
  mutation DeleteSystem($input: DeleteSystemInput!) {
    deleteSystem(input: $input) {
      id
    }
  }
`;

export const deleteRiskControlCategory_minimal = /* GraphQL */ `
  mutation DeleteRiskControlCategory($input: DeleteRiskControlCategoryInput!) {
    deleteRiskControlCategory(input: $input) {
      id
      ownerGroup
    }
  }
`;
