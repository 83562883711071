import { Col, Row } from "reactstrap";
import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "@mui/material";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import OrganizationCheck from "../../../../utils/Context/OrganizationCheck/OrganizationCheck";
import PointOfContactList from "../../../OrganizationManager/PointOfContacts/components/PointOfContactList";
import PropTypes from "prop-types";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../hooks/views/useModal";
import { usePolicyVersionReviewerList } from "../hooks/usePolicyVersionReviewerList";
import { useSelectContact } from "../hooks/useSelectContact";

/**
 * Author: Anatoli Railean
 * Created At: 12/19/19
 * Edits:
 *  - 1/7/2020 JB: Updated Reviewer Mutation for new Reviewer key schema
 *  - 1/7/2020 JB: Performing Reviewers query and mutation and org check in this component to pass to children
 *  - 3/30/2020 JB: Removed subscription and manually handling reviewer array
 *
 * Description: Assign a Reviewer for a Policy Version component.
 *
 */
const AssignReviewerBody = ({ policy, policyVersion, organizationID, ...props }) => {
  const previousPolicyVersion = policy?.policyVersions?.items?.find((pv) => pv.version === policyVersion.version - 1);
  const prevReviewers = previousPolicyVersion?.reviewers?.items;

  const module = modules.GOVERNANCE;
  const resource = resources.MEETING;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const policyVersionReviewerList = usePolicyVersionReviewerList({
    policyVersion,
  });
  const selectContact = useSelectContact(organizationID, policy, policyVersion, policyVersionReviewerList);

  // checks if the policy version is approved and if there are reviewers from the previous version ( if there is a previous version )
  useEffect(() => {
    if (policyVersion?.status === "approved" && !!previousPolicyVersion && prevReviewers?.length > 0) {
      // if the policy version is approved and there are reviewers from the previous version, add them to this version
      prevReviewers.forEach((reviewer) => {
        // check if the reviewer is already assigned to this policy version
        if (
          policyVersion?.reviewers.items.find((rv) => rv?.pointOfContact?.id === reviewer?.pointOfContact?.id) !==
          undefined
        ) {
          EventLogger("Reviewer already assigned to this policy version");
        } else {
          // check that the point of contact has an email before adding them as a reviewer
          if (!isNullOrUndefined(reviewer?.pointOfContact?.email)) {
            selectContact(reviewer?.pointOfContact);
          }
        }
      });
    }
  }, [policyVersion]);

  const AddSingleReviewerModal = useModal(
    "Assign People to Read and Acknowledge this Policy",
    <Row>
      <Col sm={6}>{policyVersionReviewerList.display}</Col>
      {checkPermissionsHook.CheckFieldPermissionsUpdate("reviewers") && (
        <Col sm={6}>
          <PointOfContactList
            showSelectBoxes={true}
            organizationID={organizationID}
            fields={["firstName", "lastName"]}
            options={["select"]}
            selectPointOfContact={selectContact}
            isAdmin
            selectionType="button"
            buttonCallback={selectContact}
          />
        </Col>
      )}
    </Row>,
    <Button
      startIcon={<HowToRegIcon style={{ marginRight: "0.2em" }} />}
      disabled={!policyVersion}
      color="primary"
      size="small"
      className="btn-pill"
    >
      Acknowledgements
    </Button>,
    {
      width: "60vw",
    },
  );

  return <div>{AddSingleReviewerModal.modalButton}</div>;
};

const AssignReviewer = (props) => {
  return (
    <OrganizationCheck {...props}>
      <AssignReviewerBody />
    </OrganizationCheck>
  );
};

AssignReviewer.propTypes = {
  policyVersion: PropTypes.object.isRequired,
};

AssignReviewer.defaultProps = {
  policyVersion: {},
};

export default AssignReviewer;
