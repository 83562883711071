import { PolicyLandingPageURL } from "../../../../env-config";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { Auth } from "@aws-amplify/auth";
import { Lambda } from "aws-sdk";

export const SendPolicyEmail = async (policy, policyVersion, pointOfContact) => {
  const url = PolicyLandingPageURL;

  await Auth.currentCredentials().then(async (credentials) => {
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: "us-west-2",
    });

    await invokeLambda(lambda, policy, policyVersion, pointOfContact, url)
      .then((data) => EventLogger("Email was Scheduled"))
      .catch((err) => EventLogger(`Email was NOT Scheduled${err}`));
  });
};

const invokeLambda = async (lambda, policy, policyVersion, pointOfContact, url) => {
  const params = {
    FunctionName: "SendPolicyEmail",
    InvocationType: "Event",
    Payload: JSON.stringify({
      email: {
        to: [pointOfContact && pointOfContact.email ? pointOfContact.email : "No Email Found"],
        subject: "Cybersecurity Policy Update",
        textBody: "Oops. Looks like this email can't render HTML. See Rivial Admin for more details",
      },
      url: url,
      policy: {
        name: policy.name,
      },
      policyVersion: {
        id: policyVersion.id,
        version: policyVersion.version,
      },
      pointOfContact: {
        firstName: pointOfContact.firstName,
        lastName: pointOfContact.lastName,
        email: pointOfContact.email,
      },
      appendScheduleDateToBody: true,
    }),
  };

  EventLogger(`Email Params: ${JSON.stringify(params)}`);

  return lambda.invoke(params, function (err, data) {
    if (err) EventLogger(err, err.stack);
    else EventLogger(data);
  });
};
