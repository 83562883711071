import { API } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { onJobUpdateSubscription } from "../../../../hooks/graphql/useJobTracker/graphql/onJobUpdateSubscription";

/**
 * Waits for a job to complete
 * @param {object} job - the job object
 * @param {string} job.id - the job id
 * @param {number} timeout - timeout in milliseconds
 * @returns {Promise<unknown>}
 */
export const waitForJobToComplete = async ({ job, timeout }) => {
  if (!job?.id) {
    EventLogger("[waitForJobToComplete.js] Missing job id");
    return;
  }

  // eslint-disable-next-line no-async-promise-executor
  return await new Promise(async (resolve, reject) => {
    if (timeout) {
      setTimeout(() => {
        reject("[waitForJobToComplete.js] Timeout");
      }, timeout);
    }

    EventLogger(`Waiting for job ${job?.id} to complete`);

    /**
     * Subscribe to the job updates
     */
    await API.graphql({
      query: onJobUpdateSubscription,
      variables: {
        id: job?.id,
      },
    }).subscribe({
      next: async (eventData) => {
        const job = eventData?.value?.data?.onJobUpdate;
        const jobName = job?.name ? job?.name : job?.id;
        if (job?.status === "completed") {
          EventLogger(`Job ${jobName} completed`);
          resolve(job);
        } else if (job?.status === "failed") {
          EventLogger(`Job ${jobName} failed`);
          reject(job);
        } else if (job?.status === "started") {
          EventLogger(`Job ${jobName} started`);
        }
      },
    });
  });
};
