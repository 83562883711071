import { EventLogger } from "../../../utils/EventLogger/EventLogger";

/**
 * @description Function to get sorted by version report documents from a report
 * @param {object} report - a report with report documents object
 * @param {object[]} reportDocuments - optionally pass an array of report documents
 * @returns {*}
 */
export const getSortedByVersionReportDocuments = ({ report, reportDocuments }) => {
  const documents = report?.documents?.items || reportDocuments;
  if (!Array.isArray(documents) || documents.length === 0) {
    EventLogger("Cannot open report because it contains no documents files!");
    return [];
  }

  // Find the latest version document in the report
  let documentsSortedByVersion = documents.sort((a, b) => b.version - a.version);

  // Check if the latest document have a version, if not then use "createdAt" field to calculate the version
  if (!documentsSortedByVersion[0]?.version) {
    documentsSortedByVersion = documentsSortedByVersion.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    documentsSortedByVersion = documentsSortedByVersion.map((item, index, array) => {
      return { ...item, version: array?.length - index };
    });
  }

  return documentsSortedByVersion;
};
