import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { RecommendationStatus } from "../../Recommendations/enums/RecommendationStatus";
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const interpretActionItemStatus = (value) => {
  const keywords = {
    proposed: ["open", "proposed"],
    inProgress: ["in progress", "in process", "doing", "inProgress", "inprogress"],
    onHold: ["hold", "onHold", "onhold"],
    pastDue: ["past due", "pastDue", "pastdue"],
    complete: ["complete"],
  };

  return interpreter(value, keywords);
};

export const interpretModule = (value) => {
  const keywords = {
    risk: ["risk"],
    compliance: ["compliance", "continuous compliance"],
    vulnerabilities: ["testing", "security testing", "vulnerabilities"],
    governance: ["program", "governance"],
    incidentResponse: ["incidentResponse", "incident response"],
    vendor: ["vendor", "vendors"],
    tools: ["tools", "tool"],
    training: ["training", "security training"],
  };

  return interpreter(value, keywords);
};

export const determineRecommendationRating = (value, recommendation) => {
  const keywords = {
    info: ["info", "information", "informational"],
    low: ["low"],
    lowMedium: ["lowmedium", "low medium", "medium low", "mediumlow"],
    medium: ["medium", "moderate"],
    mediumHigh: ["mediumHigh", "mediumhigh", "medium high", "highmedium", "high medium"],
    high: ["high", "critical"],
  };

  for (const key in keywords) {
    const words = keywords[key];
    if (words.includes(value.toLowerCase())) {
      return (recommendation.rating = key);
    }
  }
};

export const interpretRecommendationRating = (value) => {
  const keywords = {
    info: ["info", "information", "informational"],
    low: ["low"],
    lowMedium: ["lowmedium", "low medium", "medium low", "mediumlow"],
    medium: ["medium", "moderate"],
    mediumHigh: ["mediumHigh", "mediumhigh", "medium high", "highmedium", "high medium"],
    high: ["high", "critical"],
  };

  for (const key in keywords) {
    const words = keywords[key];
    if (words.includes(value.toLowerCase())) {
      return interpreter(value, keywords);
    }
  }
};

export const interpretActionItemDueDate = (value) => {
  if (value) {
    const date = new Date(value);

    if (date) {
      return date;
    }
  }
};

export const determineActionItemPointOfContact = (val) => {
  if (isNullOrUndefined(val)) {
    return null;
  }

  const pointOfContact = {};

  // Remove leading and trailing whitespace
  const value = val.trim();

  // if format is 'lastName, firstName'
  if (value.includes(",")) {
    value.replaceAll(" ", "");
    const split = value.split(",");
    const lastName = split[0];
    const firstName = split[1];

    if (firstName) pointOfContact.firstName = firstName;
    if (lastName) pointOfContact.lastName = lastName;
  }

  // if format is 'firstName lastName'
  else if (value.includes(" ")) {
    const split = value.split(" ");
    const firstName = split[0];
    const lastName = split[1];

    if (firstName) pointOfContact.firstName = firstName;
    if (lastName) pointOfContact.lastName = lastName;
  }

  // if format is 'something@email'
  if (value.includes("@")) {
    pointOfContact.email = value;
  }

  return pointOfContact;
};

export const interpretActionItemPointOfContact = (val) => {
  if (isNullOrUndefined(val)) {
    return null;
  }

  const pointOfContact = {};

  // Remove leading and trailing whitespace
  const value = val.trim();

  // if format is 'lastName, firstName'
  if (value.includes(",")) {
    value.replaceAll(" ", "");
    const split = value.split(",");
    const lastName = split[0];
    const firstName = split[1];

    if (firstName) pointOfContact.firstName = firstName;
    if (lastName) pointOfContact.lastName = lastName;
  }

  // if format is 'firstName lastName'
  else if (value.includes(" ")) {
    const split = value.split(" ");
    const firstName = split[0];
    const lastName = split[1];

    if (firstName) pointOfContact.firstName = firstName;
    if (lastName) pointOfContact.lastName = lastName;
  }

  // if format is 'something@email'
  if (value.includes("@")) {
    pointOfContact.email = value;
  }

  return pointOfContact;
};

export const interpretActionItemPriority = (value) => {
  //Try to convert string values to numbers
  try {
    const numericValue = parseInt(value);
    if (Number.isFinite(numericValue)) {
      return Math.min(Math.max(numericValue, 0), 600);
    }
  } catch (e) {
    EventLogger(e);
  }

  //convert string values to numbers
  const keywords = {
    0: ["info", "information", "informational", "none"],
    100: ["low"],
    200: ["lowmedium", "low medium", "medium low", "mediumlow"],
    300: ["medium", "moderate"],
    400: ["mediumHigh", "mediumhigh", "medium high", "highmedium", "high medium"],
    500: ["high"],
    600: ["critical"],
  };
  const stringInterpretation = interpreter(value, keywords);
  return stringInterpretation ?? 300;
};

/**
 * Interprets values to map to high, mediumHigh, medium, lowMedium, low, and info enums
 * @param value
 */
export const interpretRating = (value) => {
  const keywords = {
    info: ["info", "information", "informational"],
    low: ["low"],
    lowMedium: ["lowmedium", "low medium", "medium low", "mediumlow"],
    medium: ["medium", "moderate"],
    mediumHigh: ["mediumHigh", "mediumhigh", "medium high", "highmedium", "high medium"],
    high: ["high", "critical"],
  };

  return interpreter(value, keywords);
};

/**
 * Interprets decision enum values
 * @param {string} value - value from the csv file to interpret
 */
export const interpretDecision = (value) => {
  const keywords = {
    accept: ["accept"],
    mitigate: ["mitigate"],
    transfer: ["transfer"],
    avoid: ["avoid"],
  };

  return interpreter(value, keywords);
};

export const interpretRecommendationStatus = (value) => {
  const keywords = {
    [RecommendationStatus.UNRESOLVED]: ["unresolved"],
    [RecommendationStatus.RESOLVED]: ["resolved"],
    [RecommendationStatus.WONT_RESOLVE]: ["wont resolve", "wont fix", "ignore"],
  };

  return interpreter(value, keywords);
};

export const interpreter = (value, keywords) => {
  for (const key in keywords) {
    const words = keywords[key];
    const lowerCaseWords = words.map((word) => word.toLowerCase());
    if (lowerCaseWords.includes(value.toLowerCase())) {
      return key;
    }
  }
};
