import React, { useState } from "react";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import { useStateEffect } from "../../../../../hooks/functional/useStateEffect";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQuery } from "../../../../../utils/Functions/Graphql/ListQuery";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { resources, modules } from "@rivial-security/role-utils";

/**
 * Displays the Control Framework that a Control is connected to
 * @param {string} module=modules.COMPLIANCE  - platform module for role checking
 * @param {string} resource=resources.CONTROL - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 *
 * @param {string} item.id - the id of the control framework to display
 * @param {object} controlSet - the control framework to display
 * @param {function} updateItemById - callback on item value change
 * @returns {JSX.Element}
 */
const ControlSet = ({
  module = modules.COMPLIANCE,
  resource = resources.CONTROL,
  disableRoleChecking = false,

  item,
  controlSet,
  updateItemById,
}) => {
  //[STATE]
  //The current field value (id has to be set and controlSet needs to be the controlSet name)
  const controlSetName = controlSet && controlSet.name;
  const [dropdownItem, setDropdownItem] = useState({
    id: item?.id,
    controlSet: controlSetName,
  });
  //Retrieve the list of available control sets
  const [controlSets, setControlSets] = useStateEffect([], [], async () => {
    const dbControlSets = await ListQuery({
      organizationID: item.ownerGroup,
      query: generateGraphql("ControlSet", ["name"]).listQuery,
    });

    setControlSets(dbControlSets);
  });

  //[QUERIES]
  // Create the mutation to use for updating the control set id
  const { updateMutation: controlUpdateMutation } = generateGraphql("Control", ["controlControlSetId"], {
    controlSet: `{
      id
    }`,
  });

  // When writing back to the database modify the input so that updates by control set id
  const updateControlSetOnInput = (input) => {
    //Check for selected control id to exist (if null returned no mutation takes place
    if (!input?.id || !input?.controlSet?.id) {
      return null;
    }

    const mutationInput = {
      id: input.id,
      controlControlSetId: input.controlSet.id,
    };

    return mutationInput;
  };

  const getControlSetDropdownData = () => {
    const data = [];

    if (!controlSets || !Array.isArray(controlSets)) {
      return;
    }

    for (const controlSet of controlSets) {
      if (controlSet?.name) {
        data.push({ text: controlSet.name, value: controlSet });
      }
    }

    return data;
  };

  const updateSelectedItem = (modifiedControl) => {
    const curItem = { id: item?.id };

    //find the control set name by id of the selected one
    const foundControlSet = controlSets.find((item) => {
      return item.id === modifiedControl.controlControlSetId;
    });

    if (!foundControlSet) {
      EventLogger("Failed to update control set field in the front end! - no control set information found");
      return;
    }

    if (!foundControlSet?.name) {
      EventLogger("Failed to update control set field in the front end! - no name on found control set");
      return;
    }

    curItem.controlSet = foundControlSet.name;

    setDropdownItem(curItem);
  };

  return (
    <GenericEditFieldV3
      item={dropdownItem}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      field={"controlSet"}
      inputConfig={{
        data: getControlSetDropdownData(),
        callback: (control) => updateSelectedItem(control),
      }}
      updateMutationFunction={updateControlSetOnInput}
      mutation={controlUpdateMutation}
      updateItemById={updateItemById}
    />
  );
};

export default ControlSet;
