import { useModal } from "../../../hooks/views/useModal";
import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../EventLogger/EventLogger";
import React, { useEffect } from "react";
import { useForm } from "../../../hooks/views/useForm";

/**
 * @description This component is used to reset the password for the user.
 * @param {string} username - username of the user.
 * @param {function} setIsForgotPassword - function to set the forgot password state.
 * @returns {JSX.Element}
 * @constructor
 */
const ResetPasswordForm = ({ username, setIsForgotPassword }) => {
  /**
   * Reset the password for the user.
   */
  const submitFunction = async ({ usernameToReset }) => {
    //Username to reset is always an email, because of this it is same to covert the characters to lowercase for the convience of the user.
    usernameToReset = usernameToReset.toLowerCase();

    await Auth.forgotPassword(usernameToReset)
      .then(() => {
        EventLogger(`Reset password attempt for user: ${usernameToReset}`);
      })
      .catch((err) => {
        EventLogger("Error! Reset password: ", err);
      })
      .finally(() => {
        resetPasswordModal.setModalIsOpen(false);
        if (typeof setIsForgotPassword === "function") {
          setIsForgotPassword(true);
        }
        alert(
          `Please check your email. If we find your account, we will send you a verification code to reset your password.`,
        );
      });
  };

  const form = useForm({
    disableRoleChecking: true,
    disableResetButton: true,
    disableCancelOnSubmit: true,
    submitFunction,
    fieldConfig: {
      usernameToReset: {
        inputType: "email",
        label: "Confirm Your Email",
        required: true,
      },
    },
  });

  /**
   * Prefill the email field from the login page username field.
   */
  useEffect(() => {
    if (username) form?.mergeInput({ usernameToReset: username });
  }, [username]);

  const resetPasswordModal = useModal(
    "Reset Password",
    form.display,
    <a style={{ cursor: "pointer" }}> Reset Password </a>,
  );

  return resetPasswordModal?.modalButton;
};

export default ResetPasswordForm;
