import React, { useEffect, useMemo, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import EvaluateControls from "../components/EvaluateControls";
import FinalizeReview from "../components/FinalizeReview";
import FinalizedReviewAlert from "../components/FinalizedReviewAlert";
import ResetButton from "../../../../utils/GenericComponents/buttons/ResetButton";
import UploadRelevantDocuments from "../components/UploadRelevantDocuments";
import { VENDOR_REVIEW_STATUS } from "../constants/vendorReviewStatus";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useGenerateReport } from "./useGenerateReport";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { useSetRating } from "./useSetRating";
import { useSummary } from "./useSummary";
import { useVendorReviewControlsReport } from "./useVendorReviewControlsReport";
import { useVendorReviewTour } from "../tours/useVendorReviewtour";
import { useWorkflow } from "../../../../hooks/views/useWorkflow";

/**
 * Allows the user to view/edit a Vendor Solution Review
 *
 * @param {string} itemId - the ID of the vendor review
 * @param {string} organizationID - the ownerGroup of the vendor review
 * @param {object[]} [extraSteps] - extra workflow steps that can be added to this vendor review workflow
 * @param {function} [getSummaryText] - optional function for generating summary text
 * @param {boolean} disableRoleChecking - if TRUE will not check if the user has the correct permissions to view this page
 * @param {function} getUpdatedItem - callback for parent to get new vendor review item details when it is updated
 * @param {object} props
 * @return {{display: JSX.Element}}
 */
export const useVendorReview = ({
  itemId,
  organizationID,
  extraSteps = [],
  getSummaryText,
  disableRoleChecking,
  getUpdatedItem,
  disableFinalizeStep = false,
  ...props
}) => {
  useVendorReviewTour();

  const module = modules.VENDORS;
  const resource = resources.VENDOR_REVIEW;

  const { getQuery } = generateGraphql("VendorReview", ["summary", "status"]);

  const [resetIndex, setResetIndex] = useState(0);
  const vendorReview = useQueryGetItem({
    query: getQuery,
    itemId,
    module,
    resource,
    disableRoleChecking,
  });

  useEffect(() => {
    props.setQueryRef && props.setQueryRef(vendorReview);
  }, [props, vendorReview]);

  const summary = useSummary({
    vendorReview: { id: itemId },
    organizationID,
    module,
    resource,
    disableRoleChecking,
    resetFunction: vendorReview.reset,
    getSummaryText,
  });

  const setRating = useSetRating({ module, resource, disableRoleChecking });
  const generateReport = useGenerateReport();
  const controlsReport = useVendorReviewControlsReport({
    organizationID,
    vendorReviewID: itemId,
  });

  const [workflowSteps, setWorkflowSteps] = useState([
    {
      id: "step_1",
      text: "Relevant Documents",
      display: (
        <UploadRelevantDocuments module={module} resource={resource} disableRoleChecking={disableRoleChecking} />
      ),
    },
    {
      id: "step_2",
      text: "Evaluate Controls",
      display: (
        <EvaluateControls
          item={{ id: itemId }}
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
        />
      ),
    },
    {
      id: "step_3",
      text: "Set Rating",
      display: setRating.display,
      context: { rating: setRating.rating },
    },
    {
      id: "step_4",
      text: "Summary",
      display: summary.display,
    },
    {
      id: "step_5",
      text: "Controls Report",
      display: controlsReport.display,
    },
    {
      id: "step_6",
      text: "Full Report",
      display: generateReport.display,
    },
    ...extraSteps,
  ]);

  useEffect(() => {
    const vendorReviewItem = vendorReview?.item;

    if (!disableFinalizeStep && !isNullOrUndefined(vendorReviewItem?.status) && !vendorReview?.isLoading) {
      const finalizeReviewWorkflowStep = {
        id: "step_finalize_review",
        text: "Finalize Review",
        display: <FinalizeReview vendorReviewID={itemId} getUpdatedItem={getUpdatedItem} />,
      };

      setWorkflowSteps((workflowSteps) => [...workflowSteps, finalizeReviewWorkflowStep]);
    }
  }, [disableFinalizeStep, itemId, getUpdatedItem, vendorReview?.item, vendorReview?.isLoading]);

  const memoizedWorkflow = useMemo(() => {
    const isReadOnly = ({ context: workflowContext }) => {
      // finalize step never read only for some steps
      const nonReadOnlySteps = ["step_finalize_review", "step_6", "step_5"];
      if (workflowContext?.currentStep?.id && nonReadOnlySteps.includes(workflowContext.currentStep.id)) {
        return false;
      }

      // if the review is not finalized then it is not read only
      if (workflowContext?.vendorReview?.status !== VENDOR_REVIEW_STATUS.final) {
        return false;
      }

      return true;
    };

    const isInteractable = ({ context: workflowContext }) => {
      const readOnly = isReadOnly({ context: workflowContext });
      if (!readOnly) {
        return true;
      }

      //Allow interaction for some steps even if its marked as readonly
      // - evaluate controls step needs to allow clicks to expand/collapse the control categories
      const interactiveSteps = ["step_1", "step_2"];
      if (workflowContext?.currentStep?.id && interactiveSteps.includes(workflowContext.currentStep.id)) {
        return true;
      }

      return false;
    };

    return {
      steps: workflowSteps,
      displayPrefix: <FinalizedReviewAlert />,
      isReadOnly,
      isInteractable,
      context: { vendorReview: { id: itemId } },
      initialStepIndex: 1,
      resetIndex,
    };
  }, [workflowSteps, itemId, resetIndex]);

  const workflow = useWorkflow(memoizedWorkflow);

  useEffect(() => {
    if (vendorReview.item) {
      workflow.addToContext("vendorReview", vendorReview.item);
      workflow.setSteps(workflowSteps);
    }
  }, [vendorReview.item, workflowSteps]);

  const display = (
    <div style={{ marginBottom: "3em", display: "flex", gap: "1em" }}>
      <div style={{ flex: 1, minWidth: 0 }}>{workflow.display}</div>
      <div data-tourid={"Vendor-Review-progress-tracker"}>
        {workflow.progressTracker.display}
        {isNullOrUndefined(props.setQueryRef) && (
          <ResetButton
            resetFunction={() => {
              vendorReview?.reset?.();
              setResetIndex((resetIndex) => resetIndex + 1);
            }}
            text={"Refresh"}
            title={"Retrieve up-to-date vendor review data"}
          />
        )}
      </div>
    </div>
  );

  return {
    display,
  };
};
