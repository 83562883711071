import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { checkArguments } from "@rivial-security/func-utils";

/**
 * Data pre-processing function (allows to search by CustomResourceEntries, and display custom fields)
 * @param {object} customResourceEntry - the current item for which to process data
 * @param {string[]} customFields - list of custom field names to add to the CustomResourceEntry
 * @return {object} new CustomResourceEntry object with custom fields added
 */
export const normalizeCustomResourceEntryRowData = ({ customResourceEntry, customFields }) => {
  // - check arguments
  try {
    checkArguments(
      { customResourceEntry, customFields },
      {
        item: { type: "object" },
        customFields: { type: "string", isArray: true, deepTypeCheck: true },
      },
    );
  } catch (e) {
    EventLogger("Invalid customResourceEntry entry given to preprocess.", e.message);
    return customResourceEntry;
  }

  // - create entries for each field in each customResourceEntry
  try {
    let customFieldData;
    if (customResourceEntry?.customFieldData) {
      customFieldData = JSON.parse(customResourceEntry?.customFieldData);
      for (const field of customFields) {
        if (customFieldData && customFieldData.hasOwnProperty(field)) {
          customResourceEntry[field] = customFieldData[field];
        }
      }
    }
  } catch (e) {
    EventLogger("Failed to generate full custom fields for a customResourceEntry in the customResourceEntrys grid.", e);
  }

  return customResourceEntry;
};
