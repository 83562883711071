export const VulnerabilitiesModuleLinks = {
  name: "Vulnerabilities",
  url: "/vulnerabilities",
  icon: "simple-line-icons:magnifier-add",
  children: [
    {
      dashboardPage: {
        name: "Dashboard",
        url: "/vulnerabilities/dashboard",
        icon: "simple-line-icons:grid",
      },
    },
    {
      assessmentsPage: {
        name: "Assessments",
        url: "/vulnerabilities/assessments",
        icon: "simple-line-icons:badge",
      },
    },
    {
      targetsPage: {
        name: "Targets",
        url: "/vulnerabilities/targets",
        icon: "simple-line-icons:target",
      },
    },
    {
      findingsPage: {
        name: "Vulnerabilities",
        url: "/vulnerabilities/vulnerabilities",
        icon: "simple-line-icons:fire",
      },
    },
  ],
};
