import React, { ReactElement, useEffect, useMemo } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

import { FeatureFlags } from "./featureFlags";
import { useOrganizationContext } from "../views/AdminPanel/Organizations/hooks/useOrganizationContext";

export interface FeatureFlagProviderProps {
  children: React.ReactNode;
}

export interface FeatureFlagsContextType {
  flags?: FeatureFlags;
}

export const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>({
  flags: undefined,
});

export const FeatureFlagsProvider = ({ children }: FeatureFlagProviderProps): ReactElement => {
  const ldClient = useLDClient();
  const flags = useFlags();
  const { loggedInUserId, userEmail, selectedOrganizationObjectMinimal } = useOrganizationContext();
  const { id } = selectedOrganizationObjectMinimal;
  const featureFlags = flags as FeatureFlags;

  // Update feature flags available to the authenticated user
  useEffect(() => {
    if (ldClient && loggedInUserId && userEmail && id) {
      void ldClient.identify({ key: loggedInUserId, email: userEmail, organizationID: id });
    }
  }, [ldClient, loggedInUserId, userEmail, id]);

  const contextValue = useMemo(() => ({ flags: featureFlags }), [featureFlags]);

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlagsContext = (): FeatureFlagsContextType => React.useContext(FeatureFlagsContext);
