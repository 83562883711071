import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { DeleteProgramSection } from "../../ProgramSection/functions/DeleteProgramSection";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteProgramElement } = generateGraphql("ProgramElement");

export const DeleteProgramElement = async (item) => {
  if (item && item.programSections && item.programSections.items) {
    for (const programSection of item.programSections.items) {
      DeleteProgramSection(programSection);
    }
  }

  return await API.graphql(
    graphqlOperation(deleteProgramElement, {
      input: {
        id: item.id,
      },
    }),
  )
    .then(({ data }) => {
      if (data && data.deleteProgramElement) {
        EventLogger(`Program Element ${data.deleteProgramElement.id} was Successfully Deleted`);
      }
    })
    .catch((err) => EventLogger("Error! Program Element delete function: ", err));
};
