import { LoadingSpinner } from "../../../utils/LoadingComponents/LoadingSpinner";
import { ReactElement } from "react";

/**
 * Displays a loading message when the changelog iframe is loading
 */
const HelpCenterLoader = ({ loading }: { loading: boolean }): ReactElement => {
  return (
    <>
      {loading && (
        <div style={{ padding: "2em" }}>
          <LoadingSpinner loadingMessage={"Loading Help Center... "} />
        </div>
      )}
    </>
  );
};

export default HelpCenterLoader;
