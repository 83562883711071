import { operationType } from "../constants/operationType";
import { reasonType } from "../constants/reasonType";
import { locationType } from "../constants/locationType";

/**
 * Returns a full error code from the category enums describing the error
 * @param {string} error.location - the place in the code base where the error has occurred
 * @param {string} error.operation - the type of action that resulted in an error
 * @param {number} error.reason - the reason why an action could have failed
 */
export const getErrorCode = (error) => {
  const location = error?.location || locationType.GENERIC;
  const operation = error?.operation || operationType.GENERIC;
  const reason = error?.reason || reasonType.GENERIC;

  return `${location}-${operation}-${reason}`;
};
