import { useForm } from "../../../hooks/views/useForm";
import React, { useContext, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { OrganizationContext } from "../../Context/OrganizationContext";
import { useRoleDataGrid } from "../../../views/OrganizationManager/Roles/hooks/useRoleDataGrid";
import AccessListDisplay from "../components/AccessListDisplay";
import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../EventLogger/EventLogger";
import { GetQuery } from "../../Functions/Graphql/GetQuery";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * @description Add Role Access Control for an item
 * @param {object} item - database object with an accessControl field
 * @param {string} organizationID - selected organization
 * @param {function} submitFunction - function to submit the form
 * @param {boolean} disableFormButtons - disable form buttons
 * @param {function} resetFunction - reset state of the item
 * @returns {{display: *, input: {}}}
 */
export const useAddRoleControl = ({ item, organizationID, submitFunction, disableFormButtons, resetFunction }) => {
  const context = useContext(OrganizationContext);
  const [allowedAccess, setAllowedAccess] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const { getQuery } = generateGraphql("Role", ["id", "name"]);

  const removeRoleFromList = (roleId) => {
    setRoleList((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
  };

  const getRoles = async (roles) => {
    if (isNonEmptyArray(roles)) {
      const fetchedRoles = [];
      for (const role of roles) {
        try {
          const data = await GetQuery({
            query: getQuery,
            variables: {
              id: role,
            },
          });
          fetchedRoles.push(data);
        } catch (err) {
          EventLogger("There was an error getting roles: ", err);
        }
      }
      setRoleList(fetchedRoles);
    }
  };

  const grid = useRoleDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
  });

  useEffect(() => {
    getRoles(item?.accessControl?.roles);

    const roles = item?.accessControl?.roles;

    if (roles && Array.isArray(roles) && roles.length > 0 && grid) {
      /**
       * Check if user have role access
       */
      const foundRole = roles.map((id) => id === context?.role?.id);
      !foundRole && setAllowedAccess(false);
    }
  }, [item]);

  const form = useForm({
    fieldConfig: {
      roles: {
        inputType: "custom",
        customConfig: {
          component: (
            <>
              <div style={{ height: "30em", marginBottom: "1rem" }}>{grid?.gridDisplay}</div>
              <AccessListDisplay
                roles={roleList}
                itemName={item?.name}
                item={item}
                listToDisplay="roles"
                resetFunction={resetFunction}
                onRoleRemoved={removeRoleFromList}
              />
            </>
          ),
        },
      },
    },
    ...((disableFormButtons || grid?.selectedData.length === 0) && {
      disableSubmitButton: true,
    }),
    disableResetButton: true,
    submitFunction,
  });

  const AccessDenied = () => <Alert color="info">Sorry, you do not have role access</Alert>;

  return {
    ...form,
    display: allowedAccess ? form?.display : <AccessDenied />,
    selectedItems: grid?.selectedData,
    setSelectedItems: grid?.setSelectedData,
    setSelectedItemIDs: grid?.setSelectedIDs,
  };
};
