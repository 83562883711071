import { isNonEmptyArray } from "@rivial-security/func-utils";
import { EventLogger } from "@utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "@rivial-security/appsync-utils";

/**
 * Function to assign new points of contacts to a questionnaire, does not spawn tasks, does not check for existing points of contact
 * @param {object} params - the parameters for the function
 * @param {string} params.organizationID - the organization of the questionnaire to which to attach the points of contact
 * @param {string} params.questionnaireID - the questionnaire to which to attach the points of contact
 * @param {object} params.pointsOfContact - the points of contact to attach to the questionnaire
 * @returns {Promise<void>}
 */
export const assignPointsOfContactToQuestionnaire = async ({ organizationID, questionnaireID, pointsOfContact }) => {
  if (!organizationID || !questionnaireID || !isNonEmptyArray(pointsOfContact)) {
    EventLogger(
      "[assignPointsOfContactToQuestionnaire]",
      "Invalid arguments to assign points of contact to questionnaire",
    );
    return;
  }

  const { createMutation } = generateGraphql("QuestionnaireAssignee");

  const createRequests = [];
  for (const pointOfContact of pointsOfContact) {
    const pointOfContactID = pointOfContact?.id;
    if (pointOfContactID) {
      createRequests.push(
        ItemMutation({
          mutation: createMutation,
          input: {
            ownerGroup: organizationID,
            questionnaireID,
            pointOfContactID,
          },
        }),
      );
    }
  }

  if (isNonEmptyArray(createRequests)) {
    await Promise.allSettled(createRequests);
  }
};
