import React from "react";

const RiskControlsImporter = React.lazy(
  () => import("../../views/CsvImporters/RiskControlsImporter/components/RiskControlsImporter"),
);
const RisksImporter = React.lazy(() => import("../../views/CsvImporters/RisksImporter/components/RisksImporter"));
const ControlsEvidenceLinkingImporter = React.lazy(
  () => import("../../views/CsvImporters/ControlEvidenceLinkImporter/components/ControlEvidenceLinkImporter"),
);

// Importers and Linking Routes
export const administratorImportersRoutes = [
  {
    path: "/admin_panel/controls_evidence_import",
    exact: true,
    name: "Import Control Evidence Links",
    component: ControlsEvidenceLinkingImporter,
  },
  {
    path: "/admin_panel/risk_controls_import",
    exact: true,
    name: "Import Risk Controls",
    component: RiskControlsImporter,
  },
  {
    path: "/admin_panel/risks_import",
    exact: true,
    name: "Import Risks",
    component: RisksImporter,
  },
];
