import { precedenceTypes } from "@rivial-security/role-utils";

/**
 * Determines if the user is in a special role (admin, manager, or operation team member)
 * with the ability to exclude specific roles.
 * @param {object} context - the organization context
 * @param {object} [options] - Options to exclude specific roles.
 * @returns {boolean}
 */
export const isSpecialRole = ({ context }, options = {}) => {
  const { isAdmin, isInOperationTeamGroup, role } = context;
  const { excludeRoles = [] } = options;

  // Check roles, excluding any specified in excludeRoles
  return (
    (!excludeRoles.includes("admin") && isAdmin) ||
    (!excludeRoles.includes("operationTeam") && isInOperationTeamGroup) ||
    (!excludeRoles.includes("manager") && role?.precedence === precedenceTypes.MANAGER)
  );
};
