import { taskCategory } from "../constants/taskCategory";
import {
  filterDepartmentTasks,
  filterListTasks,
  filterMyTasks,
  filterPastDueTasks,
} from "../../../functions/taskFilteringUtilities";
import { isTaskPastDue } from "../functions/isTaskPastDue";

/**
 *  custom hook to handle task filtering based on the selected menu
 * @param {string} selectedMenu - The selected menu
 * @param {string} departmentID - The department ID to filter by
 * @param {object} context - The organization context
 * @param {object} currentList - The current list (Custom List)
 * @param {boolean} isSidebarView - Whether the component is being rendered in the TaskSidebar or TaskManagementUI
 * @returns {object}
 */
export const useTaskFiltering = ({ selectedMenu, departmentID, context, currentList, isSidebarView }) => {
  const isListCreator = context?.loggedInPointOfContactId === currentList?.pointOfContactID;

  const handleTaskFiltering = (tasks) => {
    switch (selectedMenu) {
      case taskCategory.MY_TASKS:
        return filterMyTasks(tasks);
      case taskCategory.DEPARTMENT_TASKS:
        return filterDepartmentTasks({
          tasks,
          departmentID,
          loggedInPointOfContactId: context?.loggedInPointOfContactId,
        });
      case taskCategory.PAST_DUE_TASKS:
        return filterPastDueTasks({
          tasks,
          isTaskPastDue,
          isSidebarView,
          loggedInPointOfContactId: context?.loggedInPointOfContactId,
        });
      case currentList?.name:
        return filterListTasks({
          tasks,
          context,
          currentList,
          loggedInPointOfContactId: context?.loggedInPointOfContactId,
          isListCreator,
        });
      default:
        return {
          filteredTasks: [],
          completedTasks: [],
        };
    }
  };

  return { handleTaskFiltering };
};
