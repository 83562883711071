"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParameterValue = void 0;
const client_ssm_1 = require("@aws-sdk/client-ssm");
/**
 * Get a value from AWS Parameter Store.
 *
 * @param keyName The key name to lookup in AWS Parameter Store
 * @returns The parameter value or undefined
 */
const getParameterValue = async (keyName) => {
    const region = process.env.REGION;
    if (!region) {
        throw new Error("[getParameterValue] Environment variable REGION is not defined");
    }
    const ssmClient = new client_ssm_1.SSMClient({ region });
    const getParameterCommand = new client_ssm_1.GetParameterCommand({
        Name: keyName,
        WithDecryption: true,
    });
    try {
        const response = await ssmClient.send(getParameterCommand);
        const value = response.Parameter?.Value;
        if (!value) {
            throw new Error(`[getParameterValue] Value undefined for key: ${keyName}`);
        }
        return value;
    }
    catch (error) {
        if (error instanceof Error) {
            console.error(error.message);
        }
        throw new Error(`[getParameterValue] Error fetching value for key: ${keyName}`);
    }
};
exports.getParameterValue = getParameterValue;
