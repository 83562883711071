import React, { useEffect, useState } from "react";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

/**
 * @description Displays timeline information about a point of contact changing the type of Evidence
 * @param {string} data - custom data, needs to be parsed
 * @returns {JSX.Element}
 * @constructor
 */
const TypeChangedActivity = ({ data }) => {
  const [customData, setCustomData] = useState({});

  useEffect(() => {
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        setCustomData(parsedData);
      } catch (e) {
        EventLogger("Cannot parse custom data for TypeChangedActivity", e);
      }
    }
  }, [data]);

  return (
    <span>
      {customData?.pointOfContactName || "Someone"} changed type{" "}
      {customData?.oldType && `from '${convertCamelCaseToSentence(customData?.oldType)}'`}{" "}
      {customData?.newType && `to '${convertCamelCaseToSentence(customData?.newType)}'`}{" "}
    </span>
  );
};

export default TypeChangedActivity;
