export const getTarget_target_details = /* GraphQL */ `
  query GetTarget($id: ID!) {
    getTarget(id: $id) {
      id
      hostName
      ip
      macAddress
      createdAt
      lastTestedDate
      targetAssessmentId
      ownerGroup
      disabled
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

export const listTargetsByOwnerGroup = /* GraphQL */ `
  query ListTargetsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ip
        macAddress
        hostName
        ownerGroup
      }
      nextToken
    }
  }
`;

export const listTargetFindingLinksByTargetId = /* GraphQL */ `
  query ListTargetFindingLinksByTargetId(
    $targetID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTargetFindingLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetFindingLinksByTargetID(
      targetID: $targetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        falsePositive
        status
        priority
        pointOfContact {
          id
          firstName
          lastName
          email
        }
        vulnerability {
          id
          createdAt
          name
          type
          severityLevel
          ports
          protocols
          cves
          cvss
          pluginID
          impact
          solution
          solutionType
          summary
          exploitAvailable
          pluginID
          manualPriority
          targets(limit: 900) {
            items {
              id
              status
              createdAt
              priority
              firstSeen
              lastSeen
              targetID
              vulnerabilityID
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listAssessmentTargetLinksByTargetId = /* GraphQL */ `
  query ListAssessmentTargetLinksByTargetId(
    $targetID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentTargetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentTargetLinksByTargetID(
      targetID: $targetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        assessment {
          id
          name
          scheduledDate
          type
          pointOfContact {
            id
            firstName
            lastName
          }
          penTester
          status
          ownerGroup
        }
      }
      nextToken
    }
  }
`;

export const listAssessmentVulnerabilityLinksByAssessmentID = /* GraphQL */ `
  query ListAssessmentVulnerabilityLinksByAssessmentID(
    $assessmentID: ID
    $vulnerabilityID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentVulnerabilityLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentVulnerabilityLinksByAssessmentID(
      assessmentID: $assessmentID
      vulnerabilityID: $vulnerabilityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentID
        vulnerabilityID
        ownerGroup
      }
      nextToken
    }
  }
`;
