import { getResourceRoute } from "../../definitions/functions/getResourceRoute";
import { routeType } from "../../definitions/constants/routeTypes";
import { resources } from "@rivial-security/role-utils";

export const ReportsModuleLinks = {
  name: "Reports",
  url: "/reports",
  icon: "simple-line-icons:docs",
  children: [
    {
      createReportPage: {
        name: "Create a Report",
        url: "/reports/create_report",
        icon: "simple-line-icons:doc",
      },
    },
    {
      reportsListPage: {
        name: "Saved Reports",
        url: "/reports/list",
        icon: "simple-line-icons:docs",
      },
    },
  ],
};

export const ReportsModuleManagerLinks = {
  name: "Reports",
  url: "/reports",
  icon: "simple-line-icons:docs",
  children: [
    {
      customQueriesPage: {
        name: "Queries",
        url: getResourceRoute({
          routeType: routeType.GRID,
          typename: resources.CUSTOM_QUERY,
          prefix: "/",
        }),
        icon: "simple-line-icons:chemistry",
      },
    },
    {
      customWidgetsPage: {
        name: "Widgets",
        url: getResourceRoute({
          routeType: routeType.GRID,
          typename: resources.CUSTOM_WIDGET,
          prefix: "/",
        }),
        icon: "simple-line-icons:pie-chart",
      },
    },
  ],
};
