import { PlatformComponent, PlatformComponentProps } from "@components/shared/Platform";
import React, { ReactElement } from "react";

import Spinner from "@components/Ions/motion/components/Spinner";
import cx from "classnames";
import styles from "./Button.module.scss";

export interface ButtonProps extends PlatformComponentProps {
  /** The content of the button, usually text. If `children` is not provided, the button should provide a value for `startIcon`. */
  children?: React.ReactNode;
  /** The variant of the button (example: `primary` or `link`). */
  variant?: ButtonVariant;
  /** The SVG icon to display on the left side of the text.  Set this prop for buttons that should only display an icon without text. */
  startIcon?: ReactElement;
  /** The SVG icon to display on the right side of the text. */
  endIcon?: ReactElement;
  /** When true, specifies that the button is used to submit a form. */
  isSubmit?: boolean;
  /** When true, the button will render with a spinner and block calling the `onClick` event handler function. */
  isLoading?: boolean;
  /** The event handler function to call when the button is clicked by the user. */
  onClick?: (event: React.MouseEvent) => void;
}

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXT = "text",
  LINK = "link",
  ALERT = "alert",
}

/**
 * A button component is an interactive UI element used to trigger actions or navigate within an application,
 * providing a clear and intuitive way for users to interact with the system.
 */
const Button = ({
  children,
  disabled,
  variant = ButtonVariant.PRIMARY,
  startIcon,
  endIcon,
  id,
  isSubmit = false,
  isLoading = false,
  ariaLabel,
  onClick,
  className,
  ...props
}: ButtonProps): PlatformComponent => {
  const classNames = cx(
    styles[variant],
    {
      [styles["icon-with-text-padding"]!]:
        children && [ButtonVariant.PRIMARY, ButtonVariant.SECONDARY, ButtonVariant.ALERT].includes(variant),
    },
    { [styles["button-icon"]!]: startIcon ?? endIcon },
    { [styles["button-text"]!]: !(startIcon ?? endIcon) },
    { [styles.loading!]: isLoading },
    className,
  );

  const type = isSubmit ? "submit" : "button";

  if (isLoading) {
    if (startIcon) {
      startIcon = <Spinner />;
    } else if (endIcon) {
      endIcon = <Spinner />;
    } else {
      children = (
        <div className={styles["icon-only-spinner-container"]}>
          <div className={styles.hidden}>{children}</div>
          <div className={styles["icon-only-spinner"]}>
            <Spinner />
          </div>
        </div>
      );
    }
  }

  return (
    <button
      id={id}
      className={classNames}
      disabled={disabled ?? isLoading}
      type={type}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-disabled={disabled ?? isLoading}
      aria-busy={isLoading}
      {...props}
    >
      <div className={styles["button-contents-grid"]}>
        {startIcon}
        {children}
        {endIcon}
      </div>
    </button>
  );
};

export default Button;
