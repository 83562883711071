import React, { useEffect } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import CreateNewRisk from "../components/AddRisksToOrganization/CreateNewRisk";
import DeleteRisk from "../../../Systems/functions/deleteFunctions/DeleteRisk";
import KeyRiskIndicatorDetails from "../components/KeyRiskIndicatorDetails";
import { generateGraphql } from "@rivial-security/generategraphql";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useQueryCard } from "../../../../../hooks/views/useQueryCard";

const { updateMutation: updateRisk } = generateGraphql("Risk");

export const useListKeyRiskIndicators = ({ organizationID, dataCardConfig }) => {
  const module = modules.RISK;
  const resource = resources.KEY_RISK_INDICATOR;

  const fieldNameDictionary = {
    id: "ID",
    name: "Name",
    classification: "Classification",
    description: "Description",
    annualRateOfOccurrence: "Annual Rate of Occurrence",
    annualRateOfOccurrenceMax: "ARO Max",
    confidenceIntervalLower: "Confidence Interval Lower",
    confidenceIntervalUpper: "Confidence Interval Upper",
    confidenceInterval: "Confidence Interval",
    costOfControls: "Cost of Controls",
    controlEffectiveness: "Control Effectiveness",
  };

  const queryConfig = {
    query: realTimeRiskGQL.listRisks_systemAccordion,
    organizationID,
  };

  const dataCardConfigInitial = {
    header: "Key Risk Indicators",
    fields: ["name", "description"],
    options: ["details", "delete"],
    deleteFunction: DeleteRisk,
    route: "#/risk/risk_config/risks/",
    fieldNameDictionary,
    detailsComponent: (
      <KeyRiskIndicatorDetails organizationID={organizationID} fieldNameDictionary={fieldNameDictionary} />
    ),
    createResourceComponent: <CreateNewRisk organizationID={organizationID} />,
    updateMutation: updateRisk,
    typename: "Key Risk Indicator",
    ...dataCardConfig,
  };

  const riskQueryCard = useQueryCard({
    queryConfig,
    dataCardConfig: dataCardConfigInitial,
    module,
    resource,
  });

  useEffect(() => {
    riskQueryCard.setSortField("name", "asc");
  }, []);

  return { ...riskQueryCard };
};
