export const getSystem = `query GetSystem($id: ID!) {
  getSystem(id: $id) {
    id
    name
    description
    ownerGroup
    risks(limit: 100) {
      items {
        id
        name
        classification
        description
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
        controlEffectiveness
        riskSystemId
        ownerGroup
        riskControls(limit: 100) {
          items {
            id
            name
            statementNumber
            isCompliant
            costOfControl
            controlEffectiveness
            annualRateReduction
            riskControlRiskId
            ownerGroup
            notes {
              items {
                id
                type
                ownerGroup
                author
                timeStamp
                content
                controlNotesId
                riskControlNotesId
                itemId
                observationID
              }
              nextToken
            }
            complianceControlLinks {
              items {
                id
                ownerGroup
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
    calculations {
      date
      expectedInherentLoss
      expectedResidualLoss
      lossExceedanceCurve {
        amount
        inherentLossProb
        residualLossProb
      }
    }
    riskSnapshots(limit: 100) {
      items {
        id
        ownerGroup
        name
        date
        systemStats {
          id
          ownerGroup
          name
          inherentLoss
          residualRisk
          currentCostOfControls
          currentROI
          inherentLossCurve {
            id
            ownerGroup
            points {
              x
              y
            }
          }
          residualLossCurve {
            id
            ownerGroup
            points {
              x
              y
            }
          }
          snapshot {
            id
            ownerGroup
            name
            date
            systemStats {
              id
              ownerGroup
              name
              inherentLoss
              residualRisk
              currentCostOfControls
              currentROI
              inherentLossCurve {
                id
                ownerGroup
              }
              residualLossCurve {
                id
                ownerGroup
              }
              snapshot {
                id
                ownerGroup
                name
                date
                snapshotSystemId
              }
            }
            risksStats {
              items {
                id
                ownerGroup
                name
                inherentLoss
                residualRisk
                currentCostOfControls
                currentROI
              }
              nextToken
            }
            snapshotSystemId
          }
        }
        risksStats {
          items {
            id
            ownerGroup
            name
            inherentLoss
            residualRisk
            currentCostOfControls
            currentROI
            inherentLossCurve {
              id
              ownerGroup
              points {
                x
                y
              }
            }
            residualLossCurve {
              id
              ownerGroup
              points {
                x
                y
              }
            }
            snapshot {
              id
              ownerGroup
              name
              date
              systemStats {
                id
                ownerGroup
                name
                inherentLoss
                residualRisk
                currentCostOfControls
                currentROI
              }
              risksStats {
                nextToken
              }
              snapshotSystemId
            }
          }
          nextToken
        }
        snapshotSystemId
      }
      nextToken
    }
    hosting
    pointOfContact {
      id
      firstName
      lastName
      title
      email
      ownerGroup
    }
    numberOfUsers
    accessType
    confidentiality
    integrity
    availability
    components(limit: 100) {
      items {
        id
        name
        description
        ownerGroup
        notes {
          items {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            controlNotesId
            riskControlNotesId
            itemId
            observationID
          }
          nextToken
        }
      }
    }
    notes(limit: 100) {
      items {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        controlNotesId
        riskControlNotesId
        itemId
      }
      nextToken
    }
  }
}
`;

export const createSystem = `mutation CreateSystem($input: CreateSystemInput!) {
  createSystem(input: $input) {
    id
    name
    description
    ownerGroup
  }
}
`;

export const updateSystemOwner = `mutation UpdateSystem($input: UpdateSystemInput!) {
  updateSystem(input: $input) {
    id
  }
}
`;

export const listSystems_systemList = `query ListSystems(
  $filter: ModelSystemFilterInput
  $limit: Int
  $nextToken: String
) {
  listSystems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      ownerGroup
    }
    nextToken
  }
}
`;

export const onCreateSystem = `subscription OnCreateSystem {
  onCreateSystem {
    id
    name
    description
    ownerGroup
  }
}
`;

export const onUpdateSystem = `subscription OnUpdateSystem {
  onUpdateSystem {
    id
    name
    description
    ownerGroup
  }
}
`;
export const onDeleteSystem = `subscription OnDeleteSystem {
  onDeleteSystem {
    id
    name
    description
    ownerGroup
  }
}
`;

export const onUpdateRiskControlOverride = `subscription OnUpdateSystem {
  onUpdateSystem {
    id
    riskControlOverrides {
       enabledFields
       riskControlId
       isCompliant
       costOfControl
       controlEffectiveness
       annualRateReduction
       __typename
    }
  }
}
`;

export const updateSystemRiskControlOverride = `mutation UpdateSystem($input: UpdateSystemInput!) {
  updateSystem(input: $input) {
     id
     riskControlOverrides {
       enabledFields
       riskControlId
       isCompliant
       costOfControl
       controlEffectiveness
       annualRateReduction
       __typename
     }
  }
}
`;
