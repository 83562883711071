import { format } from "date-fns";
import { EventLogger } from "../../EventLogger/EventLogger";

/**
 * @description Generate a new S3 object key with a date stamp
 * @param {string} objectKey - object key to generate
 * @returns {string|null}
 */
export const generateS3ObjectKey = (objectKey) => {
  if (!objectKey) {
    EventLogger("[generateS3ObjectKey.js] Missing object key!");
    return null;
  }
  /**
   * Get the current date and time
   */
  const date = format(new Date(), "MM-dd-yyyy HH-mm-ss");

  /**
   * Get the file extension
   */
  const fileExt = objectKey.split(".").pop();

  /**
   * Generate the new key with the date stamp
   */
  let newObjectKey;
  if (objectKey.lastIndexOf(".") === -1) {
    /**
     * If there is no file extension, add the date stamp to the end of the file name
     */
    newObjectKey = `${objectKey} (${date})`;
  } else {
    /**
     * If there is a file extension, add the date stamp before the file extension
     */
    newObjectKey = `${objectKey.substring(0, objectKey.lastIndexOf("."))} (${date}).${fileExt}`;
  }

  return newObjectKey;
};
