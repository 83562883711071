import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { ReactElement, useEffect, useState } from "react";
import { useSelectPointOfContact } from "@views/OrganizationManager/PointOfContacts/hooks/useSelectPointOfContact/useSelectPointOfContact";
import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useUIContext } from "@utils/Context/UIContext";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { performToastOperation } from "@utils/Toasts/functions/toastOperation";
import { formattedName } from "@rivial-security/func-utils";
import { PointOfContact } from "@rivial-security/schema-types";
import { EventLogger } from "@utils/EventLogger/EventLogger";

interface UpdateItemParams {
  id: string;
}

export interface PointOfContactFieldProps<T extends { id: string }> {
  module: string;
  resource: string;
  typename: string;
  item?: T;
  field: string;
  idField: string;
  disabled?: boolean;
  updateItemById?: (item: UpdateItemParams) => void;
  resetFunction?: () => void;
  organizationID: string;
  additionalFormSteps?: Record<string, unknown>[];
  getMutationID?: ({ item }: { item: Record<string, unknown> }) => string;
  formatMutationInput?: ({ mutationInput, formInput, pointOfContact }) => {
    id: string;
    [key: string]: unknown;
  };
  afterSubmit?: ({ pointOfContact }) => Promise<void>;
  handleUnassign?: ({ pointOfContact }) => Promise<void>;
  onSubmitExternal?: ({ formInput, pointOfContact }) => Promise<void>;
  onInitialRender?: () => void;
}

const PointOfContactField = <T extends { id: string }>({
  module,
  resource,
  typename,
  item,
  field,
  idField,
  disabled,
  updateItemById,
  resetFunction,
  organizationID,
  additionalFormSteps,
  getMutationID,
  formatMutationInput,
  afterSubmit,
  handleUnassign,
  onSubmitExternal,
  onInitialRender,
}: PointOfContactFieldProps<T>): ReactElement => {
  const { addToast, updateToast } = useUIContext();
  const checkPermissionsHook = useCheckPermissions({ module, resource, field });
  const isDisabled = disabled || !checkPermissionsHook?.resource?.update;
  const [updatedPointOfContactFields, setUpdatedPointOfContactFields] = useState({});

  const onSubmit = async ({
    formInput,
    pointOfContact,
  }: {
    formInput: Record<string, unknown>;
    pointOfContact: Partial<PointOfContact>;
  }): Promise<void> => {
    if (onSubmitExternal) {
      try {
        await onSubmitExternal({ formInput, pointOfContact });
        setUpdatedPointOfContactFields({
          [field]: pointOfContact,
          [idField]: pointOfContact?.id,
        });
      } catch (e) {
        EventLogger("Failed to complete external on submit", e);
      }
      return;
    }

    const { updateMutation } = generateGraphql(typename);

    if (!item?.id) {
      addToast({
        header: `Failed to update ${field} value`,
        icon: "error",
      });
      return;
    }

    let id = item?.id;
    if (getMutationID) {
      id = getMutationID({ item });
    }

    let mutationInput: {
      id: string;
      [key: string]: unknown;
    } = {
      id,
      [idField]: pointOfContact?.id || null,
    };

    if (formatMutationInput) {
      mutationInput = formatMutationInput({ mutationInput, formInput, pointOfContact });
    }

    await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        await ItemMutation({
          mutation: updateMutation,
          input: mutationInput,
        });
      },
      inProgressText: `Updating field...`,
      successText: `Field: '${field}' has been updated to: ${pointOfContact ? formattedName({ pointOfContact }) : "empty"}`,
      failedText: `Failed to update '${field}' field`,
      onSuccess: () => {
        setUpdatedPointOfContactFields({
          [field]: pointOfContact,
          [idField]: mutationInput?.[idField],
        });
      },
    });

    const newItem: UpdateItemParams = {
      id: item.id,
      [field]: pointOfContact,
      [idField]: pointOfContact?.id,
    };

    if (typeof updateItemById === "function") {
      updateItemById(newItem);
    } else if (typeof resetFunction === "function") {
      resetFunction();
    }
  };

  useEffect(() => {
    onInitialRender?.();
  }, []);

  const selectPointOfContactHook = useSelectPointOfContact({
    item: { ...(item ?? {}), ...updatedPointOfContactFields },
    connectionField: field,
    connectionIDField: idField,
    onSubmit,
    afterSubmit,
    handleUnassign,
    isDisabled,
    organizationID,
    additionalFormSteps,
    resetFunction,
  });

  return selectPointOfContactHook.modalButton;
};

export default withOrganizationCheck(PointOfContactField);
