import React from "react";
import { Badge } from "reactstrap";

const CertificationName = ({ item, vendorCertification = {} }) => {
  return (
    <>
      {vendorCertification ? (
        <Badge color="success">
          <i className={"icon-badge"} /> {vendorCertification.name}
        </Badge>
      ) : (
        <span style={{ fontStyle: "italic" }}>N/A</span>
      )}
    </>
  );
};

export default CertificationName;
