export const AdministratorPanelModuleLinks = {
  name: "Administrator",
  url: "/admin_panel",
  icon: "simple-line-icons:wrench",
  children: [
    {
      name: "Organizations",
      url: "/admin_panel/organizations",
      icon: "simple-line-icons:mustache",
    },
    {
      name: "Operation Teams",
      url: "/admin_panel/operation_teams",
      icon: "simple-line-icons:people",
    },
    {
      name: "Global Templates",
      url: "/admin_panel/template_manager",
      icon: "simple-line-icons:layers",
    },
    {
      name: "CISSP Reviews",
      url: "/admin_panel/cissp_reviews",
      icon: "simple-line-icons:screen-tablet",
    },
    // {
    //   name: 'Framework Templates',
    //   url: '/admin_panel/control_frameworks',
    //   icon: 'simple-line-icons:layers'
    // },
    // {
    //   name: 'Control Templates',
    //   url: '/admin_panel/controls',
    //   icon: 'simple-line-icons:list'
    // },
    // {
    //   name: 'Evidence Templates',
    //   url: '/admin_panel/evidence',
    //   icon: 'simple-line-icons:badge'
    // },
    // {
    //   name: 'System Templates',
    //   url: '/admin_panel/system_templates',
    //   icon: 'simple-line-icons:film'
    // },
    // {
    //   name: 'Blueprint Templates',
    //   url: '/admin_panel/setup_matrix',
    //   icon: 'simple-line-icons:map'
    // },
    // {
    //   name: 'Role Templates',
    //   url: '/admin_panel/role_templates',
    //   icon: 'simple-line-icons:user-female'
    // },
  ],
};
