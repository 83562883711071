import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Gets list of targets IPs or MAC addresses from vulnerability
 * @param {object[]} params.row.targetsCustom - list of targets attached to the vulnerability
 * @return {string}
 */
export const vulnerabilityTargetValueGetter = (params) => {
  const targetsObject = params?.row?.targetsCustom;
  if (!targetsObject) {
    return "";
  }

  const targets = Object.values(targetsObject);
  if (!isNonEmptyArray(targets)) {
    return "";
  }

  return targets.map((target) => target.ip || target.macAddress).join(", ");
};
