import { Checkbox, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { ARTIFACT_STATUS } from "../constants/ARTIFACT_STATUS";
import { EVIDENCE_STATUS } from "../../../../../typedefs/Compliance/Evidence/Evidence";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { GENERIC_FIELD_TYPES } from "../../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { GetQuery } from "@rivial-security/appsync-utils";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { ListQueryBy } from "../../../../../utils/Functions/Graphql/ListQueryBy";
import Loader from "../../../../../utils/LoadingComponents/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import SyncIcon from "@mui/icons-material/Sync";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { onEvidenceStatusChange } from "../../../Evidence/functions/onEvidenceStatusChange";
import { useOrganizationContext } from "../../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { useRefState } from "../../../../../hooks/functional/useRefState";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * Handles the status of the artifact
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifact - full artifact information from the database, including the status and reason fields
 * @param {function} resetFunction - function to reset the artifact details panel
 * @returns {JSX.Element}
 */
const ArtifactStatus = ({ organizationID, artifact, resetFunction }) => {
  const { addToast } = useUIContext();
  const { loggedInUserId, loggedInPointOfContactId } = useOrganizationContext();
  const [status, setStatus, statusRef] = useRefState(artifact?.status || ARTIFACT_STATUS.PENDING_VALIDATION);
  const [reason, setReason, reasonRef] = useRefState(artifact?.reason || "");

  const handleStatusChange = async (event) => {
    const oldStatus = status;
    const newStatus = event?.target?.value || ARTIFACT_STATUS.PENDING_VALIDATION;
    setStatus(newStatus);

    try {
      await ItemMutation(updateArtifactMutation, {
        id: artifact?.id,
        status: newStatus,
      });
    } catch (e) {
      EventLogger(e);
      if (typeof addToast === "function") {
        addToast({
          header: `Artifact status failed to update, try again later...`,
          icon: "danger",
        });
      }
      setStatus(oldStatus);
    }
  };

  const [isSyncingEvidence, setIsSyncingEvidence] = useState(false);
  const handleSyncEvidenceStatus = async ({ statusOverride } = {}) => {
    setIsSyncingEvidence(true);
    try {
      const artifactStatus = statusOverride || status;
      const newEvidenceStatus = artifactToEvidenceStatusMap[artifactStatus];
      const evidence = await GetQuery({
        query: getEvidenceQuery,
        variables: {
          id: artifact?.evidence?.id,
        },
      });

      //Do not change status if evidence is already in a synced state
      if (evidence?.status !== newEvidenceStatus) {
        if (
          evidence?.enabled &&
          (newEvidenceStatus === EVIDENCE_STATUS.EXPIRING_SOON || newEvidenceStatus === EVIDENCE_STATUS.EXPIRED)
        ) {
          if (
            !window.confirm(
              `Syncing evidence will result in a notification being sent for each point of contact to upload evidence. Do you want to continue?`,
            )
          ) {
            return;
          }
        }

        const updatedItem = await ItemMutation(updateEvidenceMutation, {
          id: evidence?.id,
          status: newEvidenceStatus,
        });

        if (
          updatedItem?.status !== EVIDENCE_STATUS.EXPIRED ||
          updatedItem?.status !== EVIDENCE_STATUS.EXPIRING_SOON ||
          !updatedItem?.enabled
        ) {
          await onEvidenceStatusChange({
            evidenceID: evidence?.id,
            oldStatus: evidence?.status,
            newStatus: newEvidenceStatus,
            pointOfContactID: loggedInPointOfContactId,
            userID: loggedInUserId,
            organizationID,
            comment: reasonRef?.current,
          });
        }

        if (typeof resetFunction === "function") {
          resetFunction();
        }
      }
    } catch (e) {
      EventLogger(e);
    }

    setIsSyncingEvidence(false);
  };

  const syncEvidenceButtonMessage = () => {
    const matchingEvidenceStatus = artifactToEvidenceStatusMap[status];
    if (matchingEvidenceStatus === artifact?.evidence?.status) {
      return "Evidence status already matches artifact status";
    }
    return "";
  };

  const [syncOnClose, setSyncOnClose, syncOnCloseRef] = useRefState(false);
  const [syncOnCloseLoading, setSyncOnCloseLoading] = useState(false);
  // If so enable the sync on close if most recent artifact for the evidence and artifact hasn't been validated yet
  const checkIfMostRecentArtifact = async () => {
    setSyncOnCloseLoading(true);
    try {
      const mostRecentEvidenceArtifacts = await ListQueryBy({
        query: listArtifactsByEvidence,
        variables: {
          evidenceID: artifact?.evidence?.id,
          sortDirection: "DESC",
        },
        itemLimit: 1,
      });

      //Find if any artifact is newer than this one
      let isMostRecentArtifact = true;
      if (Array.isArray(mostRecentEvidenceArtifacts)) {
        for (const otherArtifact of mostRecentEvidenceArtifacts) {
          if (otherArtifact?.id === artifact?.id) {
            continue;
          }

          if (isNullOrUndefined(otherArtifact?.createdAt)) {
            continue;
          }

          if (new Date(artifact?.createdAt) <= new Date(otherArtifact?.createdAt)) {
            isMostRecentArtifact = false;
            break;
          }
        }
      }

      //Also check if artifact was initially pending validation (so the status of evidence doesn't get replaced on view of recent artifact)
      const artifactInitiallyPending = artifact?.status === ARTIFACT_STATUS.PENDING_VALIDATION;

      //Check for the current location int the app to not be the evidence landing page (not meant to validate artifacts from here)
      const currentPath = window.location.href;
      const inArtifactSubmissionLandingPage = currentPath.includes("continuous_compliance/evidence_response");

      setSyncOnClose(isMostRecentArtifact && artifactInitiallyPending && !inArtifactSubmissionLandingPage);
    } catch (e) {
      EventLogger(e);
    }
    setSyncOnCloseLoading(false);
  };
  useEffect(() => {
    if (artifact) {
      artifact?.status && setStatus(artifact?.status);
      artifact?.reason && setReason(artifact?.reason);
      checkIfMostRecentArtifact();
    }
    return () => {
      if (syncOnCloseRef?.current) {
        handleSyncEvidenceStatus({ statusOverride: statusRef?.current });
      }
    };
  }, [artifact]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ToggleButtonGroup color="primary" value={status} exclusive onChange={handleStatusChange} aria-label="Platform">
        <ToggleButton color="success" value={ARTIFACT_STATUS.VALID}>
          Valid
        </ToggleButton>
        <ToggleButton color="error" value={ARTIFACT_STATUS.NOT_VALID}>
          Not Valid
        </ToggleButton>
        <ToggleButton value={ARTIFACT_STATUS.PENDING_VALIDATION}>Pending</ToggleButton>
      </ToggleButtonGroup>
      {status !== ARTIFACT_STATUS.PENDING_VALIDATION && (
        <div style={{ marginTop: "1em" }}>
          <p style={{ fontWeight: "bold", margin: 0 }}>Reason:</p>
          <GenericEditFieldV3
            item={{ ...artifact, reason: reason }}
            field={"reason"}
            inputType={GENERIC_FIELD_TYPES.TEXT_AREA}
            tooltip={"Provide a reason for why the artifact submission is not valid"}
            mutation={updateArtifactMutation}
            disableRoleChecking={true}
            defaultOpen={reason ? false : true}
            updateItemById={(item) => {
              setReason(item?.reason);
            }}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1em",
          alignItems: "center",
        }}
      >
        <Tooltip title={syncEvidenceButtonMessage()} syncTooltipOpen>
          <span>
            <LoadingButton
              variant="contained"
              color="success"
              loadingPosition="start"
              disabled={syncEvidenceButtonMessage() ? true : false}
              startIcon={<SyncIcon />}
              loading={isSyncingEvidence}
              onClick={() => handleSyncEvidenceStatus()}
            >
              {isSyncingEvidence ? "Syncing..." : "Sync Evidence"}
            </LoadingButton>
          </span>
        </Tooltip>
        {syncOnCloseLoading ? (
          <Loader style={{ marginLeft: ".5em", marginRight: ".5em" }} />
        ) : (
          <Checkbox
            checked={syncOnClose}
            onChange={(event) => {
              setSyncOnClose(event?.target?.checked);
            }}
          />
        )}

        <p style={{ margin: 0 }}>Automatically sync when navigating away</p>
      </div>
    </div>
  );
};

const { getQuery: getEvidenceQuery, updateMutation: updateEvidenceMutation } = generateGraphql("Evidence", [
  "status",
  "enabled",
]);

const listArtifactsByEvidence = /* GraphQL */ `
  query ArtifactsByEvidence(
    $evidenceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArtifactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    artifactsByEvidence(
      evidenceID: $evidenceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        createdAt
        ownerGroup
      }
      nextToken
    }
  }
`;

const { updateMutation: updateArtifactMutation } = generateGraphql("Artifact", ["status", "reason"]);

const artifactToEvidenceStatusMap = {
  [ARTIFACT_STATUS.VALID]: EVIDENCE_STATUS.IN_PLACE,
  [ARTIFACT_STATUS.NOT_VALID]: EVIDENCE_STATUS.EXPIRED,
  [ARTIFACT_STATUS.PENDING_VALIDATION]: EVIDENCE_STATUS.PENDING_VALIDATION,
};

export default ArtifactStatus;
