import { EVIDENCE_STATUS, EVIDENCE_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { modules, resources } from "@rivial-security/role-utils";

import EvidenceFrequency from "../components/EvidenceFrequency";
import EvidenceImporterModalButton from "../components/EvidenceImporterModalButton";
import React from "react";
import { allowUnauthenticatedSubmissionsTooltip } from "../components/EvidenceAllowUnauthenticatedSubmissionsSwitch";
import { enumToDropdownData } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useAddSingleNote } from "../../../../utils/Notes/hooks/useAddSingleNote";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useSelectPointOfContacts } from "../../../OrganizationManager/PointOfContacts/hooks/useSelectPointOfContacts";
import { v4 as uuid } from "uuid";

/**
 * A custom hook for creating a new Evidence item. This hook is used by the EvidenceGrid component.
 *
 * @param {string} organizationID - ownerGroup to use for creating/querying resources
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-templatable resource parts
 * @param {function} toggleModal - triggers a parent modal to close
 * @param {boolean} noHeader - if TRUE will hide the form header text
 * @param {function} getNewItem - callback that returns the new item after successful mutation
 * @param {function} resetFunction - calls an external reset function when the form is reset
 * @param {object} fieldConfig - gets spread to the useForm fieldConfig, may be used to override default field config
 * @param {object} props - all other props passed to useForm hook
 * @return {{display: JSX.Element}}
 */
export const useCreateEvidence = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.EVIDENCE,
  disableRoleChecking = false,
  isTemplate = false,
  toggleModal,
  noHeader,
  getNewItem,
  resetFunction,
  fieldConfig = {},
  ...props
}) => {
  const typename = resources.EVIDENCE;

  /**
   * Add single note to a new evidence
   */
  const addSingleNoteHook = useAddSingleNote({ typename, organizationID });

  /**
   * Custom mutation for the evidencePointOfContactLink to get actions for the grid
   */
  const { createMutation: createEvidencePointOfContactLink } = generateGraphql(
    "EvidencePointOfContactLink",
    ["pointOfContact"],
    {
      pointOfContact: `{ id firstName lastName email title actions(limit: 100) { items { id name status createdAt data } } }`,
    },
  );

  /**
   * Attach multiple points of contact to the new evidence
   */
  const selectPointOfContact = useSelectPointOfContacts({
    typename,
    mutation: createEvidencePointOfContactLink,
    organizationID,
    disabled: isTemplate,
  });

  /**
   * Attach resources after an evidence was created
   */
  const callback = async (item) => {
    if (addSingleNoteHook?.hasContent()) {
      await addSingleNoteHook?.submitFunction(item);
    }

    const links = await selectPointOfContact?.submitFunction(item);

    /**
     * Update an evidence in the evidence grid with points of contact and action items
     */
    getNewItem &&
      getNewItem({
        ...item,
        pointOfContacts: { items: links },
      });
  };

  /**
   * Mutation to create an evidence
   */
  const { createMutation } = generateGraphql("Evidence", [
    "name",
    "itemNumber",
    "frequency",
    "allowUnauthenticatedSubmissions",
    "status",
  ]);

  //Checks for proper permissions to see the option to use an evidence template
  const checkPermissionsHook = useCheckPermissions({
    module: modules.COMPLIANCE,
    resource: resources.EVIDENCE,
  });

  /**
   * Instantiate a custom form hook for creating an evidence. Passes all useForm functionality through
   */
  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,
    ...(!noHeader && { header: <div>Create a new Evidence </div> }),
    typename,
    mutation: createMutation,
    fieldConfig: {
      name: {
        required: true,
        label: "Name",
        tooltip: "A descriptive name for the Evidence being requested. For example: 'Anti-Virus Logs'",
      },
      itemNumber: {
        required: true,
        defaultValue: (!isTemplate && uuid()) || "",
        isHidden: !isTemplate,
        label: "Evidence ID",
        tooltip: "Give a unique identifier for this Evidence that won't change",
      },
      type: {
        label: "Type",
        defaultValue: EVIDENCE_TYPES.DOCUMENT,
        inputType: "dropdown",
        tooltip: "What type of Evidence Response are you expecting?",
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: EVIDENCE_TYPES }),
        },
      },
      frequency: {
        inputType: "custom",
        label: "Frequency",
        tooltip: "How often should this Evidence be gathered?",
        customConfig: {
          component: <EvidenceFrequency disableRoleChecking={true} initValue={"0 9 1 * *"} />,
        },
      },
      status: {
        required: true,
        label: "Initial Status",
        tooltip: "The state of this evidence based on submitted documents and due date.",
        inputType: "dropdown",
        dropdownConfig: {
          data: enumToDropdownData({
            ENUM: EVIDENCE_STATUS,
            overrides: {
              [EVIDENCE_STATUS.EXPIRED]: "Not In Place",
            },
          }),
        },
        defaultValue: EVIDENCE_STATUS.IN_PLACE,
      },
      pointOfContact: {
        label: "Point Of Contact Assignment",
        inputType: "custom",
        removeFromInput: true,
        isHidden: isTemplate,
        customConfig: {
          component: (
            <div
              style={{
                minHeight: "40em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {selectPointOfContact?.gridDisplay}
            </div>
          ),
        },
        tooltip: "Select one or multiple Points of Contact for the new evidence",
      },
      task: {
        label: "Task",
        inputType: "textarea",
        tooltip: "Specify how to accomplish the evidence gathering",
      },
      notes: {
        label: "Notes",
        tooltip: "Add a note for the new evidence",
        inputType: "custom",
        removeFromInput: true,
        customConfig: {
          component: addSingleNoteHook?.display,
        },
      },
      enabled: {
        required: true,
        label: "Enable Auto Gathering",
        inputType: "switch",
        tooltip: "Enable or disable evidence from the continuous compliance cycle.",
        defaultValue: false,
        isHidden: isTemplate,
      },
      allowUnauthenticatedSubmissions: {
        required: true,
        label: "Allow Guest Submissions",
        inputType: "switch",
        tooltip: allowUnauthenticatedSubmissionsTooltip,
        defaultValue: false,
      },
      ...fieldConfig,
    },
    toggleModal,
    callback,
    enableTemplates: !isTemplate && checkPermissionsHook.resource.read,

    headerButtons: [<EvidenceImporterModalButton organizationID={organizationID} />],
    ...props,
  });

  return formHook;
};
