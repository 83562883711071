"use strict";
/* Amplify Params - DO NOT EDIT
    API_RISC_GRAPHQLAPIENDPOINTOUTPUT
    API_RISC_GRAPHQLAPIIDOUTPUT
    AUTH_AUTH_USERPOOLID
Amplify Params - DO NOT EDIT */
Object.defineProperty(exports, "__esModule", { value: true });
exports.monteCarloSystemCalculation = void 0;
const CALCULATION_CONTEXT_1 = require("../constants/CALCULATION_CONTEXT");
const createMonteCarloSystemInput_1 = require("../createMonteCarloSystemInput/createMonteCarloSystemInput");
const saveMonteCarloSystemInputObject_1 = require("../createMonteCarloSystemInput/functions/saveMonteCarloSystemInputObject");
const monteCarloCalculation_1 = require("../monteCarloCalculation/monteCarloCalculation");
/**
 * Performs an entire Monte Carlo Calculation on one more multiple systems.
 * Returns a single result object (not an array) if an object is passed to the "system" param
 * Returns an array of result objects if an array is passed to the "systems" param, prioritizes the "systems" param.
 *
 * @param {monteCarloSystemCalculationInput} monteCarloSystemCalculationInput
 * @returns {Promise<monteCarloCalculationResult[]>}
 */
const monteCarloSystemCalculation = async (monteCarloSystemCalculationInput) => {
    const { system, organizationID, systems, recommendation } = monteCarloSystemCalculationInput;
    const systemsToCalculate = [];
    if (systems && systems.length > 0) {
        systemsToCalculate.push(...systems);
    }
    else if (system) {
        systemsToCalculate.push(system);
    }
    else {
        throw new Error("Error: No System(s) Input found");
    }
    try {
        const calculations = [];
        for (const system of systemsToCalculate) {
            try {
                // Create the MonteCarloCalculation input
                console.log("Creating Monte Carlo System Input for: ", JSON.stringify({ system, organizationID }));
                const monteCarloSystemInput = await (0, createMonteCarloSystemInput_1.createMonteCarloSystemInput)({ system, organizationID, recommendation });
                console.log("Successfully Created MonteCarloSystemInput - ", JSON.stringify({ monteCarloSystemInput }));
                const { s3BucketName, systemInputHasChanged, lossToleranceCurve } = monteCarloSystemInput;
                // Run the calculation
                console.log("Performing Monte Carlo Calculation..");
                const { calculation, reusableRandoms } = await (0, monteCarloCalculation_1.monteCarloCalculation)({
                    system: { ...monteCarloSystemInput.system },
                    lossCurveMaxX: monteCarloSystemInput.lossCurveMaxX,
                    recommendation,
                    monteCarloSystemInput,
                });
                calculation.lossToleranceCurve = lossToleranceCurve;
                console.log("Successfully performed Monte Carlo Calculation");
                calculations.push(calculation);
                // Save the calculation results
                if (systemInputHasChanged) {
                    try {
                        console.log("There has been a change in the System Input, saving new input to S3..");
                        await (0, saveMonteCarloSystemInputObject_1.saveMonteCarloSystemInputObject)({
                            system: { ...monteCarloSystemInput.systemDetailsItem },
                            monteCarloSystemInput,
                            calculation,
                            calculationContext: CALCULATION_CONTEXT_1.CALCULATION_CONTEXT.VIEW_SYSTEM,
                            reusableRandoms,
                            organizationID,
                            s3BucketName,
                            env: process.env,
                        });
                    }
                    catch (e) {
                        console.log("Error saving to S3! ", e);
                    }
                }
                else {
                    console.log("There hasn't been any change in the System Input.");
                }
            }
            catch (e) {
                console.log("Error with Monte Calculation for System: ", { id: system.id, name: system.name }, e);
            }
        }
        return calculations.length > 1 ? calculations : calculations[0];
    }
    catch (e) {
        console.log("Error with Monte Carlo Calculations! ", e);
    }
};
exports.monteCarloSystemCalculation = monteCarloSystemCalculation;
