import MuiDownloadButton from "./MuiDownloadButton";

export interface MuiItemDownloadButtonType {
  item: {
    file: {
      bucket: string;
      key: string;
    };
    avStatus: string;
    createdAt: string;
    ownerGroup: string;
  };
  organizationID?: string;
  size?: "small" | "medium" | "large";
}

/**
 * Download button for a single item representing a document in dynamo db
 */
const MuiItemDownloadButton = ({ item, organizationID, size = "small" }: MuiItemDownloadButtonType): JSX.Element => {
  return (
    <MuiDownloadButton
      s3File={{
        bucket: item?.file?.bucket,
        key: item?.file?.key,
        organizationID: organizationID ?? item?.ownerGroup,
      }}
      avStatus={item?.avStatus}
      showDisplayNameInButtonText={false}
      size={size}
    />
  );
};

export default MuiItemDownloadButton;
