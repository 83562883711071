import { EventLogger } from "../../EventLogger/EventLogger";
import { GetQuery } from "../Graphql/GetQuery";

/**
 * @description Helper function to send a request to the fargate API
 *
 * @param input - params to send to the api
 */
export const fargateApi = async ({ input }: { input: object }): Promise<string> => {
  if (!input) {
    EventLogger("[fargateApi] Missing the input object");
  }

  const fargateApiQuery = /* GraphQL */ `
    query FargateApi($input: AWSJSON) {
      fargateApi(input: $input)
    }
  `;

  const stringifiedInput = JSON.stringify(input);

  EventLogger("Sending request to Fargate API. Input:", stringifiedInput);

  return (await GetQuery({
    query: fargateApiQuery,
    variables: {
      input: stringifiedInput,
    },
  })) as string;
};
