/**
 * Author: Jacob Blazina
 * Created At: 10/22/19
 * Edits: 11/7/19 JB: Functional form.
 *
 * Description: A Custom Hook.
 *              Allows user to select and upload a file
 */

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Progress,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../utils/EventLogger/EventLogger";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { PutS3Object } from "../../utils/Functions/S3Storage/PutS3Object";
import { S3_BUCKET_REGION } from "../../env-config";
import { generateS3ObjectKey } from "../../utils/Functions/S3Storage/generateS3ObjectKey";

/**
 * @description Upload documents hook
 * @param {string} organizationID - selected organization
 * @param {string} visibility - document visibility
 * @param {function} mutationFunction - function to handle mutation
 * @param {string} linkedResourceName - linked resource name
 * @param {string} linkedResourceId - linked resource id
 * @param {string} folder - folder to store file on s3 bucket
 * @param {string} version - document version. NOTE: Currently supported only for report documents
 * @param {function} callback - callback function for after the submitFunction runs
 * @returns {{display: *}}
 */
export const useFileUpload = ({
  organizationID,
  visibility = "private",
  mutationFunction,
  linkedResourceName,
  linkedResourceId = undefined,
  folder = "general",
  version,
  callback,
}) => {
  const [friendlyName, setFriendlyName] = useState("");
  const [files, setFiles] = useState([]);
  const [progressBar, setProgressBar] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [disableEdits, setDisableEdits] = useState(false);
  const [bucketName, setBucketName] = useState("");

  const context = useContext(OrganizationContext);

  useEffect(() => {
    if (
      context &&
      context.selectedOrganizationObjectMinimal &&
      context.selectedOrganizationObjectMinimal.s3BucketName
    ) {
      setBucketName(
        context && context.selectedOrganizationObjectMinimal && context.selectedOrganizationObjectMinimal.s3BucketName,
      );
    }
  }, [context && context.selectedOrganizationObjectMinimal && context.selectedOrganizationObjectMinimal.s3BucketName]);

  const cancel = () => {
    getInitialState();
  };

  const getInitialState = () => {
    setFiles([]);
    setFriendlyName("");
  };

  const handleFileChange = () => {
    setFiles(Array.from(document.getElementById("selectFiles").files));
  };

  const isSubmitEnabled = friendlyName !== "" && files.length > 0;

  const display = (
    <Card>
      <CardHeader>
        <i className="icon-cloud-upload" /> Upload Document
      </CardHeader>
      <CardBody>
        <Form encType="multipart/form-data">
          <FormGroup>
            <Label for="friendlyName">Friendly Name</Label>
            <Input
              disabled={disableEdits}
              value={friendlyName}
              id="friendlyName"
              type="text"
              onChange={(e) => {
                e.preventDefault();
                setFriendlyName(e.target.value);
              }}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="selectFiles">File to Upload</Label>
            <Input id="selectFiles" type="file" multiple onChange={() => handleFileChange()} required />
          </FormGroup>
        </Form>
        <Progress animated={progressBar !== 100} value={progressBar}>
          {parseInt(progressBar)}%
        </Progress>
        {uploadMessage ? <Alert color="primary">{uploadMessage}</Alert> : null}
      </CardBody>
      <CardFooter>
        <Button onClick={() => handleSubmit()} disabled={!isSubmitEnabled} size="sm" color="primary">
          <i className="upload" />
          Upload File
        </Button>
        {friendlyName !== "" && files && files.length > 0 && (
          <Button type="reset" size="sm" color="danger" onClick={() => cancel()}>
            <i className="fa fa-ban" />
            Cancel
          </Button>
        )}
      </CardFooter>
    </Card>
  );
  const handleS3Upload = async (file, owner, region, visibility, bucketName, name, linkedResourceID) => {
    const fileName = file.name || name;
    const fileType = file.type;

    const objectKey = generateS3ObjectKey(`${folder}/${fileName}`);

    await PutS3Object({
      file,
      bucketName,
      objectKey,
      fileType,
      organizationID,
      onprogress: (progress) => {
        setProgressBar(progress);
        if (progress === 100) {
          EventLogger(`${fileName}  uploaded!`);
          setUploadMessage(`${fileName}  uploaded!`);
        }
      },
    })
      .then(() => {
        const fileInfo = {
          bucket: bucketName,
          region: region,
          key: objectKey,
        };

        mutationFunction({
          input: {
            owner: owner,
            visibility: visibility,
            version: version,
            createdAt: new Date(),
            file: fileInfo,
            name: name || friendlyName,
            ownerGroup: organizationID,
            [linkedResourceName]: linkedResourceID || linkedResourceId,
          },
        });
      })
      .catch((err) => {
        EventLogger(`${file.name}  failed to upload. ${err}`);
        setUploadMessage(`${file.name}  failed to upload. ${err}`);
      }); // If the upload fails.;
  };

  const handleSubmit = async (file, name, linkedResourceId) => {
    const region = S3_BUCKET_REGION;

    const { username: owner } = await Auth.currentUserInfo();

    const fileInput = document.getElementById("selectFiles");

    if (file) {
      setDisableEdits(true);
      setFriendlyName(name);
      await handleS3Upload(file, owner, region, visibility, bucketName, name, linkedResourceId);
      setUploadMessage(`${file?.name} uploaded!`);
    } else if (fileInput) {
      const fileUploadThreads = Array.from(fileInput.files).map(async (file) => {
        await handleS3Upload(file, owner, region, visibility, bucketName);
      }); // End forEach loop.
      await Promise.all(fileUploadThreads);
      getInitialState();
    } else {
      EventLogger("Error from useFileUpload.js [func handleSubmit()]: Could not upload files to S3");
    }

    if (typeof callback === "function") {
      callback();
    }
  }; // End "handleSubmit" function.

  return {
    display,
    handleSubmit,
    setFriendlyName,
  };
};
