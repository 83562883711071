import { deepDelete } from "../../../../utils/Functions/deepDelete";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Deletes an Observation object. Also deletes associated Many-To-Many connections with RecommendationItems
 * @param {object} observation - Observation to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteRecommendations - if true, will delete all Recommendations associated with this Observation
 * @param {boolean} config.deleteActionItems - if true, will delete all Action Items associated with the Recommendations associated with this Observation
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<*>}
 */
export const deleteObservation = async (observation, config = {}, onJobStarted) => {
  const { deleteRecommendations = false, deleteActionItems = false } = config;

  if (!observation?.id) {
    EventLogger("[deleteObservation.js] Missing Observation ID");
    return;
  }

  /**
   * Delete Observation in Fargate
   */
  return await deepDelete({
    typename: "Observation",
    item: observation,
    config: {
      deleteRecommendations,
      deleteActionItems,
    },
    onJobStarted,
  });
};
