import { DEPARTMENT } from "../../views/OrganizationManager/Departments/constants/DEPARTMENT";
import { getResourceRoute } from "../../definitions/functions/getResourceRoute";
import { routeType } from "../../definitions/constants/routeTypes";
import { resources } from "@rivial-security/role-utils";
import { getResourceIcon } from "../../definitions/functions/getResourceIcon";

export const OrganizationManagerLinks = {
  name: "Organization",
  url: "/organization_manager",
  icon: "simple-line-icons:globe",
  children: [
    {
      contactsPage: {
        name: "Contacts",
        url: "/organization_manager/contacts",
        icon: "simple-line-icons:people",
      },
    },
    {
      departmentsPage: {
        name: "Departments",
        url: "/organization_manager/departments",
        icon: DEPARTMENT.icon,
      },
    },
    {
      roleManagementPage: {
        name: "Role Management",
        url: "/organization_manager/roles",
        icon: "simple-line-icons:user-female",
      },
    },
    {
      securityDashboardPage: {
        name: "Security Dashboard",
        url: "/organization_manager/security_dashboard",
        icon: "simple-line-icons:lock",
      },
    },
    {
      questionnairesPage: {
        name: "Questionnaires",
        url: getResourceRoute({
          routeType: routeType.GRID,
          typename: resources.QUESTIONNAIRE,
          prefix: "/",
        }),
        icon: getResourceIcon({
          typename: resources.QUESTIONNAIRE,
        }),
      },
    },
    {
      emailTemplatesPage: {
        name: "Email Templates",
        url: "/organization_manager/email_templates",
        icon: "simple-line-icons:envelope",
      },
    },
    {
      automationsPage: {
        name: "Automations",
        url: "/organization_manager/automations",
        icon: "simple-line-icons:energy",
      },
    },
    {
      generalConfigPage: {
        name: "Configuration",
        url: "/organization_manager/general_config",
        icon: "simple-line-icons:settings",
      },
    },
  ],
};
