import React, { useContext } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isSpecialRole } from "../../../functions/isSpecialRole";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";

/**
 * This component is used to display the content of the custom task list modal (the modal that appears when the user clicks the '...' button on a custom task list)
 * @param {function} handleEditListName - The function to call when the user clicks the 'Rename' button
 * @param {function} handleDeleteList - The function to call when the user clicks the 'Delete' button
 * @param {object} currentList - The currently selected custom task list
 * @returns {JSX.Element}
 */
const CustomTaskListModalContent = ({ handleEditListName, handleDeleteList, currentList }) => {
  const context = useContext(OrganizationContext);

  const isOwnerOrSpecialRole =
    currentList?.pointOfContactID === context?.loggedInPointOfContactId || isSpecialRole({ context });

  return isOwnerOrSpecialRole ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        onClick={handleEditListName}
        color="info"
        startIcon={<EditIcon />}
        title="Rename this custom list"
        style={{ marginBottom: ".5rem" }}
      >
        {"Rename"}
      </Button>
      <Button
        variant="contained"
        onClick={handleDeleteList}
        color="error"
        startIcon={<DeleteIcon />}
        title="Delete this custom list"
      >
        {"Delete"}
      </Button>
    </div>
  ) : null;
};

export default CustomTaskListModalContent;
