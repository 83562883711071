import { createUser } from "../../Users/functions/createUser";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Create a new Point of Contact
 * @param {string} firstName - The first name of the point of contact
 * @param {string} lastName - The last name of the point of contact
 * @param {string} title - The title of the point of contact
 * @param {string} email - The email of the point of contact
 * @param {string} phoneNumber - The phone number of the point of contact
 * @param {string} organizationID - The organization ID of the point of contact
 * @param {boolean} addNewUser - Whether or not to create a new user
 * @param {string} password - The password of the new user
 * @param {object} role - The role of the new user
 * @param {function} callback - The callback function
 * @param {function} resetFunction - The reset function
 * @param {string} sentryTrace - The sentry trace
 * @returns {Promise<void>}
 * @constructor
 */
export const createPointOfContact = async ({
  firstName,
  lastName,
  title,
  email,
  phoneNumber,
  organizationID,
  addNewUser,
  password,
  role,
  callback,
  resetFunction,
  sentryTrace,
}) => {
  return await ItemMutation(createPointOfContactMutation, {
    firstName: firstName ? firstName : undefined,
    lastName: lastName ? lastName : undefined,
    title: title ? title : undefined,
    email: email ? email.toLowerCase() : undefined,
    phone: phoneNumber ? phoneNumber : undefined,
    pointOfContactOrganizationId: organizationID,
    ownerGroup: organizationID,
  })
    .then(async (pointOfContact) => {
      EventLogger(`Point of Contact with email: ${email} was Successfully Created!`);

      /**
       * Create new user
       */
      if (addNewUser && password && pointOfContact?.id) {
        await createUser({
          name: `${firstName} ${lastName}`,
          email: email && email.toLowerCase(),
          phoneNumber: phoneNumber,
          password: password,
          organizationID: organizationID,
          pointOfContactId: pointOfContact?.id,
          accountType: "standard",
          role: role ? role : undefined,
          resetFunction,
          sentryTrace,
        }).then(() => EventLogger(`New User Created`));
      }

      callback && callback(pointOfContact);

      return pointOfContact;
    })
    .catch((err) => {
      callback && callback(err);
    });
};

const createPointOfContactMutation = /* GraphQL */ `
  mutation CreatePointOfContact($input: CreatePointOfContactInput!) {
    createPointOfContact(input: $input) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
    }
  }
`;
