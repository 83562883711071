import React from "react";

const DevTools = React.lazy(() => import("../../views/DevPanel/Tools/DevToolsDashboard"));

//Developer Routes
export const developerPanelRoutes = [
  {
    path: "/dev_tools",
    exact: true,
    name: "Developer Tools",
    component: DevTools,
  },
];
