import { CircularProgress, IconButton, Radio, Tooltip, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { TaskStatus } from "../../../constants/taskStatus";
import TaskDueDateChip from "./TaskDueDateChip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formattedName } from "@rivial-security/func-utils";
import { useModal } from "../../../../../../hooks/views/useModal";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 *  A List of tasks to be displayed in the task sidebar
 * @param {object[]} tasks - the list of tasks to display
 * @param {function} setTasks - the function to call when the tasks are updated
 * @param {function} handleCheckboxClick - the function to call when a checkbox is clicked
 * @param {function} setSelectedTaskID - the function to call when a task is selected
 * @param {string} selectedTaskID - the id of the selected task
 * @param {function} setTaskModalIsOpen - the function to call when the task modal is opened
 * @param {boolean} isLoading - whether the tasks are loading or not
 * @param {boolean} isSidebarView - whether the component is being rendered in the TaskSidebar or TaskManagementUI
 *@param {object} context - the current organization context
 * @return {JSX.Element}
 */
const TaskList = ({
  tasks,
  setTasks,
  handleCheckboxClick,
  setSelectedTaskID,
  selectedTaskID,
  setTaskModalIsOpen,
  isLoading,
  isSidebarView,
  context,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState(null);

  const taskOptionModalBody = (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Button
        onClick={() => {
          setTaskModalIsOpen(true);
        }}
        startIcon={<InfoIcon />}
      >
        View Details
      </Button>
      {isNonEmptyArray(tasks) &&
        tasks.find((task) => task?.id === selectedTaskID)?.pointOfContact?.id === context?.loggedInPointOfContactId && (
          <Button
            onClick={async () => {
              const updatedTasks = tasks.filter((task) => task?.id !== selectedTaskID);
              setTasks(updatedTasks);
              await ItemMutation(generateGraphql("Task").deleteMutation, {
                id: selectedTaskID,
              });
              taskOptionModal.setModalIsOpen(false);
            }}
            startIcon={<DeleteIcon />}
            color={"error"}
          >
            Delete Task
          </Button>
        )}
    </div>
  );

  const taskOptionModal = useModal("Task Options", taskOptionModalBody, null, {
    width: "25vw",
    onClosed: () => {
      setSelectedTaskID(null);
    },
  });

  /**
   * @description Renders a radio button or a check icon depending on whether the task is hovered or not
   * @param task
   * @return {JSX.Element}
   */
  const renderRadioOrIcon = (task) => {
    if (hoveredTaskId === task?.id) {
      return (
        <Tooltip title={task?.status === TaskStatus.DONE ? "Mark Not Completed" : "Mark Completed"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleCheckboxClick(task);
            }}
          >
            {task?.status === TaskStatus.DONE ? (
              <RemoveCircleOutlineIcon color="error" />
            ) : (
              <CheckIcon color="primary" />
            )}
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Radio
        color="primary"
        checked={task?.status === TaskStatus.DONE}
        onClick={(e) => {
          e.stopPropagation();
          handleCheckboxClick(task);
        }}
      />
    );
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isNonEmptyArray(tasks) && isSidebarView) {
    return (
      <div style={{ marginTop: ".2rem" }}>
        {tasks
          .sort((a, b) => new Date(a?.dueDate) - new Date(b?.dueDate))
          .map((task) => (
            <div
              key={task?.id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "10px",
                textDecoration: task?.status === TaskStatus.DONE ? "line-through" : "none",
                color: task?.status === TaskStatus.DONE ? "grey" : "black",
                background: isSidebarView ? "none" : "#e5e5e566",
                padding: isSidebarView ? "0px" : "1rem",
                borderRadius: "5px",
              }}
              onClick={() => {
                setSelectedTaskID(task?.id);
                setTaskModalIsOpen(true);
              }}
            >
              <div onMouseEnter={() => setHoveredTaskId(task?.id)} onMouseLeave={() => setHoveredTaskId(null)}>
                {renderRadioOrIcon(task)}
              </div>
              <div style={{ marginLeft: "10px", flex: 1 }}>
                <Typography variant="body1" align="left">
                  {task?.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="left">
                  {task?.description || "No description"}
                </Typography>
                <div style={{ textAlign: "left" }}>
                  {task?.status !== TaskStatus.DONE && <TaskDueDateChip task={task} isSidebarView={true} />}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  } else if (isNonEmptyArray(tasks) && !isSidebarView) {
    return (
      <div style={{ minHeight: "75vh", overflowY: "auto" }}>
        {tasks
          .sort((a, b) => new Date(a?.dueDate) - new Date(b?.dueDate))
          .map((task) => (
            <div
              key={task?.id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "10px",
                textDecoration: task?.status === TaskStatus.DONE ? "line-through" : "none",
                color: task?.status === TaskStatus.DONE ? "grey" : "black",
                background: "#e5e5e566",
                padding: "1rem",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedTaskID(task?.id);
                setTaskModalIsOpen(true);
              }}
            >
              <div onMouseEnter={() => setHoveredTaskId(task?.id)} onMouseLeave={() => setHoveredTaskId(null)}>
                {renderRadioOrIcon(task)}
              </div>
              <div style={{ marginLeft: "10px", flex: 1 }}>
                <Typography variant="body1" align="left">
                  {task?.name}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {task?.status !== TaskStatus.DONE && (
                    <>
                      <TaskDueDateChip task={task} isSidebarView={false} />
                      <span style={{ margin: "0 8px" }}>&bull;</span>
                    </>
                  )}
                  <Typography variant="body2" color={task?.pointOfContact ? "green" : "red"} align="left">
                    {formattedName({ pointOfContact: task?.pointOfContact }) || "Unassigned"}
                  </Typography>
                  <span style={{ margin: "0 8px" }}>&bull;</span>
                  <Typography variant="body2" color="textSecondary" align="left">
                    {task?.description || "No description"}
                  </Typography>
                </div>
              </div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTaskID(task?.id);
                  taskOptionModal.toggleModal();
                }}
              >
                <MoreVertIcon />
              </IconButton>
              {taskOptionModal.modal}
            </div>
          ))}
      </div>
    );
  }

  if (!isLoading && (!Array.isArray(tasks) || tasks.length === 0)) {
    return (
      <div style={{ marginTop: ".2rem" }}>
        <Typography variant="h6" align="center" gutterBottom>
          No tasks to display
        </Typography>
      </div>
    );
  }
};

export default TaskList;
