import AuditDataGrid from "../../views/Compliance/Audit/components/AuditDataGrid";
import React from "react";
import { Redirect } from "react-router-dom";

const ComplianceDashboard = React.lazy(() => import("../../views/Compliance/ComplianceDashboard/ComplianceDashboard"));
const AuditReport = React.lazy(() => import("../../views/Reports/AuditReport/AuditReport"));
const ControlDetails = React.lazy(() => import("../../views/Compliance/Controls/Controls/components/ControlDetails"));
const ControlSetGrid = React.lazy(
  () => import("../../views/Compliance/Controls/ControlSets/components/ControlSetDataGrid"),
);
const ControlDashboard = React.lazy(
  () => import("../../views/Compliance/Controls/Controls/components/ControlDashboard"),
);
const ControlSetDetails = React.lazy(
  () => import("../../views/Compliance/Controls/ControlSets/components/ControlSetDetails"),
);
const EvidenceList = React.lazy(() => import("../../views/Compliance/Evidence/components/EvidenceDataGrid"));
const EvidenceDetails = React.lazy(() => import("../../views/Compliance/Evidence/components/EvidenceDetails"));
const ComplianceArtifacts = React.lazy(() => import("../../views/Compliance/Documents/components/ComplianceArtifacts"));
const ComplianceReport = React.lazy(() => import("../../views/Reports/ComplianceReport/components/ComplianceReport"));
const DocumentDetails = React.lazy(() => import("../../views/Compliance/Documents/components/DocumentDetails"));
const ComplianceConfig = React.lazy(
  () => import("../../views/Compliance/ComplianceConfig/components/ComplianceConfig"),
);
const AuditDetails = React.lazy(() => import("../../views/Compliance/Audit/components/AuditDetails"));
const AuditGrid = React.lazy(() => import("../../views/Compliance/Audit/components/AuditDataGrid"));

// Compliance Routes
export const complianceRoutes = [
  {
    path: "/continuous_compliance/dashboard",
    name: "Compliance Dashboard",
    component: ComplianceDashboard,
  },
  {
    path: "/continuous_compliance/control_dashboard",
    exact: true,
    name: "Compliance Dashboard",
    component: ControlDashboard,
  },
  {
    path: "/continuous_compliance/compliance_config",
    exact: true,
    name: "Compliance Configuration",
    component: ComplianceConfig,
  },
  {
    path: "/continuous_compliance/controls",
    exact: true,
    name: "Controls",
    component: ComplianceDashboard,
  },
  {
    path: "/continuous_compliance/controls/:id",
    exact: true,
    name: "Control Details",
    component: ControlDetails,
  },
  {
    path: "/continuous_compliance/control_frameworks",
    exact: true,
    name: "Control Frameworks",
    component: ControlSetGrid,
  },
  {
    path: "/continuous_compliance/control_frameworks/:id",
    exact: true,
    name: "Control Framework Details",
    component: ControlSetDetails,
  },
  {
    path: "/continuous_compliance/reports/audit",
    exact: true,
    name: "IT Audit Report",
    component: AuditReport,
  },
  {
    path: "/continuous_compliance/evidence",
    exact: true,
    name: "Evidence",
    component: EvidenceList,
  },
  {
    path: "/continuous_compliance/evidence/:id",
    exact: true,
    name: "Evidence Details",
    component: EvidenceDetails,
  },
  {
    path: "/continuous_compliance/artifacts",
    exact: true,
    name: "Compliance Artifacts",
    component: ComplianceArtifacts,
  },
  {
    path: "/continuous_compliance/reports/compliance",
    noScrolling: true,
    exact: true,
    name: "Compliance Report",
    component: ComplianceReport,
  },
  {
    path: "/continuous_compliance/documents/:id",
    exact: true,
    name: "Document Details",
    component: DocumentDetails,
  },
  {
    path: "/continuous_compliance/audits",
    exact: true,
    name: "IT Audits",
    component: AuditDataGrid,
  },
  {
    path: "/continuous_compliance/audits/:id",
    exact: true,
    name: "IT Audit Details",
    component: AuditDetails,
  },

  {
    path: "/continuous_compliance",
    component: () => <Redirect to="/continuous_compliance/dashboard" />,
  },
];
