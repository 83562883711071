import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

export const deleteMetric = async (metricType) => {
  if (!metricType?.id) {
    return;
  }

  const { getQuery } = generateGraphql("Metric", ["__typename"]);

  return await QueryGetItem({ query: getQuery, itemId: metricType.id }).then(async (metricType) => {
    await GenericDeleteGQL({ objectToDelete: metricType });
    EventLogger(`Metric ${metricType.id} was Successfully Deleted`);
  });
};
