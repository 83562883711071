import ChecklistItem, { CHECKLIST_ITEM_UPDATE_TYPE } from "./components/ChecklistItemV2";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Collapse } from "reactstrap";
import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import List from "@mui/material/List";
import NotEnoughData from "../../../utils/GenericComponents/NotEnoughData";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useBoolean } from "../../functional/useBoolean";
import { useCheckPermissions } from "../../permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "../useForm/useForm";
import { useUUID } from "../../functional/useUUID";
import { v4 as uuid } from "uuid";

export const UPDATE_STEP_OPERATION = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};

const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "lightblue dashed 2px" : undefined,
});
/**
 * @description A utility for handling checklists with Drag and Drop, editing, and deleting items
 * @param {object} item - parent item for the type from schema.
 * @example {id: a02a9d77-c22c-4102-9164-1783021ff33f, __typename: "Exercise", injections: [injection]}
 * @param {string} field - child type name. Ex: "injections"
 * @param {string} typename - parent type name
 * @param {object} nestedFields - object with nested fields for the parent object
 * @param {string} updateMutation - graphql mutation
 * @param {function} [updateFunction] - if provided will use the function to make backend changes instead of the mutation
 * @param {object[]} data - pass an array of child items of the parent. Note: use this option OR pass the parent
 * @param {boolean} enableCheckbox - enable or disable checkboxes
 * @param {boolean} disableEdits - disable text field edits
 * @param {boolean} disableCheckboxEdits - disable ability to check and uncheck checkboxes (still alows to view the state)
 * @param {string} checkboxFieldName - field name that holds boolean for the item
 * @param {string} textFieldName - field name that holds text for the item
 * @param {function} resetFunction - fetch item from the database
 * @param {boolean} enableNotes - enable or disable notes ui
 * @param {string} organizationID - selected organization
 * @param {string} stepButtonName - text to show in the checklist buttons
 * @param {boolean} disableRoleChecking - disable role checking for the checklist
 * @param {JSX.Element[]} addonComponents - all custom components to put at the end of the checklist list UI
 * @param {boolean} [isMobile] - defined if need to enable override for mobile view
 * @param {function} onChangeCallback - callback function to call when the checklist is updated
 * @param {object} notesProps - properties to pass into each of the checklist items' notes component
 * @param {string} module - module to use for role checking
 * @param {string} resource - resource to use for role checking
 * @returns {object} {{addItem: addItem, display: JSX.Element, setItems: (value: (((prevState: *[]) => *[]) | *[])) => void, items: *[]}}
 */
export const useChecklist = ({
  item,
  field,
  typename = "",
  nestedFields = {},
  updateMutation,
  updateFunction,
  data = [],
  enableCheckbox = false,
  disableEdits: disableEditsInit,
  disableCheckboxEdits: disableCheckboxEditsInit,
  checkboxFieldName,
  textFieldName,
  resetFunction,
  enableNotes,
  organizationID,
  stepButtonName = "Step",
  disableRoleChecking,
  addonComponents,
  isMobile,
  onChangeCallback,
  notesProps,
  module,
  resource,
}) => {
  /**
   * Based on users role and an outside override, determine if edits should be disabled
   */
  const permissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });
  const disableEditsPermissions = !permissions?.resource?.update;
  const disableCheckboxEdits = !isNullOrUndefined(disableCheckboxEditsInit)
    ? disableCheckboxEditsInit
    : disableEditsPermissions;
  const disableEdits = !isNullOrUndefined(disableEditsInit) ? disableEditsInit : disableEditsPermissions;

  /**
   * State holding step information
   */
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (item?.[field]) {
      setItems(item?.[field]);
    } else {
      setItems([]);
    }
  }, [item]);

  /**
   * Handle if data passed directly
   */
  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const [componentId] = useUUID();
  const [showNewStep, setShowNewStep] = useBoolean(false);

  /**
   * Auto-focuses the form again after submitting a step, allows another step to by inputted quickly
   */
  const autoFocusForm = () => {
    window.setTimeout(function () {
      document.getElementById(`stepstep-input${componentId}`)?.focus();
    }, 0);
  };

  /**
   * Function handler for dragging and dropping
   * @param result
   */
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updatedItems = reorderItems(items, result.source.index, result.destination.index);
    handleUpdate(updatedItems);
    setItems(updatedItems);
  };

  /**
   * Adds a new to the checklist
   */
  const addItem = (input) => {
    if (input.step) {
      const item = {
        id: uuid(),
        [textFieldName]: input.step,
        [checkboxFieldName]: false,
      };

      setItems((items) => {
        const newArray = items.concat(item);
        handleUpdate(newArray, item?.id, UPDATE_STEP_OPERATION.ADD);
        return newArray;
      });

      newStepForm?.setInput({ step: "" });
      autoFocusForm();
    } else {
      alert("Input can not be empty");
    }
  };

  /**
   * Removes an item from the checklist
   */
  const removeItem = (item) => {
    setItems((items) => {
      const temp = [...items];
      const foundIndex = items.findIndex((a) => a.id === item.id);
      temp.splice(foundIndex, 1);
      handleUpdate(temp, item?.id, UPDATE_STEP_OPERATION.DELETE);
      return temp;
    });
  };

  /**
   * Update an item in the checklist
   */
  const editItem = (itemToUpdate, editType) => {
    setItems((items) => {
      const temp = [...items];
      const foundIndex = temp.findIndex((a) => a.id === itemToUpdate?.id);

      if (foundIndex > -1) {
        let tempItem = {
          ...temp[foundIndex],
        };
        tempItem = { ...tempItem, ...itemToUpdate };
        temp.splice(foundIndex, 1, tempItem);
        if (editType === CHECKLIST_ITEM_UPDATE_TYPE.TEXT) {
          handleUpdate(temp, itemToUpdate?.id, UPDATE_STEP_OPERATION.EDIT);
        } else {
          handleUpdate(temp);
        }
      }

      return temp;
    });
  };

  /**
   * @description Function to help us with reordering the result
   */
  const reorderItems = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const newStepForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    submitFunction: addItem,
    fieldConfig: {
      step: {
        componentId: `step-input${componentId}`,
        inputType: "textarea",
      },
    },
  });

  /**
   * Performs the mutation for an updated array
   */
  const handleUpdate = async (updatedSteps, updatedStepID, updateOperation) => {
    if (typeof onChangeCallback === "function") {
      onChangeCallback(updatedSteps);
    }

    if (updateFunction) {
      await updateFunction(updatedSteps, updatedStepID, updateOperation);
      resetFunction && resetFunction();
    } else {
      const { updateMutation: mutation } = generateGraphql([typename], [field], nestedFields);

      if (item?.id) {
        ItemMutation(updateMutation ? updateMutation : mutation, {
          id: item.id,
          [field]: [...updatedSteps],
        })
          .then(() => {
            EventLogger(`CheckList: ${field} was successfully updated`);
            resetFunction && resetFunction();
          })
          .catch(() => EventLogger(`CheckList: ${field} was NOT successfully updated`));
      } else {
        EventLogger(`CheckList: ${field} was NOT successfully updated, missing item id`);
      }
    }
  };

  const [id] = useUUID();

  const display = (
    <div style={{ width: "100%" }}>
      <span style={{ marginBottom: "1em" }}>
        <div key={id}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <List style={getListStyle(snapshot.isDraggingOver)} key={id}>
                      {!isNullOrUndefined(items) && Array.isArray(items) && items.length > 0
                        ? items?.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => {
                                //This is here on purpose, fixes a bug with incorrect offset while dragging items
                                //REFERENCE: https://github.com/atlassian/react-beautiful-dnd/issues/499#issuecomment-905783891
                                provided.draggableProps.style.left = undefined;
                                provided.draggableProps.style.top = undefined;

                                return (
                                  <div ref={provided.innerRef} style={{ width: "100%" }}>
                                    <ChecklistItem
                                      provided={provided}
                                      snapshot={snapshot}
                                      item={item}
                                      checkboxFieldName={checkboxFieldName}
                                      textFieldName={textFieldName}
                                      handleDelete={removeItem}
                                      handleEdit={editItem}
                                      enableCheckbox={enableCheckbox}
                                      disableEdits={disableEdits}
                                      disableCheckboxEdits={disableCheckboxEdits}
                                      field={field}
                                      enableNotes={enableNotes}
                                      organizationID={organizationID}
                                      resetFunction={resetFunction}
                                      stepButtonName={stepButtonName}
                                      module={module}
                                      resource={resource}
                                      disableRoleChecking={disableRoleChecking}
                                      notesProps={notesProps}
                                      isMobile={isMobile}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))
                        : disableEdits && <NotEnoughData message={"To add steps please enable edits."} />}
                      {provided.placeholder}
                    </List>
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </span>
      <Collapse isOpen={!disableEdits && showNewStep} style={{ width: "100%" }}>
        {newStepForm.display}
      </Collapse>
      {!disableEdits && (
        <span>
          {showNewStep && (
            <div style={{ marginLeft: "1em", display: "inline" }}>
              <ButtonComponent
                data-testid={"button-submit-new-step"}
                onClick={async () => await addItem(newStepForm.input)}
                title={`Submit new ${stepButtonName}`}
              >
                Submit
              </ButtonComponent>
            </div>
          )}
          <div style={{ marginLeft: "1em", marginRight: "1em", display: "inline" }}>
            <ButtonComponent
              data-testid={"button-add-or-cancel-new-step"}
              onClick={() => {
                setShowNewStep(!showNewStep);
                newStepForm?.setInput({ step: "" });
              }}
              title={!showNewStep ? `Add New ${stepButtonName || "Step"}` : "Cancel creating a new step"}
            >
              {!showNewStep ? `Add New ${stepButtonName || "Step"}` : "Cancel"}
            </ButtonComponent>
          </div>
          {addonComponents && addonComponents.map((component) => React.cloneElement(component, { item }))}
        </span>
      )}
    </div>
  );

  return {
    display,
    items,
    setItems,
    addItem,
  };
};
