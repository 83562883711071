import { v4 as uuid } from "uuid";
import { isSpecialRole } from "../../../functions/isSpecialRole";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { useContext, useEffect } from "react";
import { useForm } from "../../../../../../hooks/views/useForm";

/**
 *Component that displays the form to create a custom list
 * @param {boolean} isEditing - whether the user is editing a custom list
 * @param {string} updateListMutation - the mutation to update a custom list
 * @param {string} createListMutation - the mutation to create a custom list
 * @param {function} submitFunction - the function to submit the form
 * @param {object} initialValues - the initial values for the form ( specifically used for editing a custom list name)
 * @param {object} pointOfContact - the point of contact object
 * @returns {JSX.Element}
 */
const CreateCustomListForm = ({
  isEditing,
  updateListMutation,
  createListMutation,
  submitFunction,
  initialValues,
  pointOfContact,
}) => {
  const context = useContext(OrganizationContext);

  const listFieldConfig = {
    fieldConfig: {
      id: {
        defaultValue: uuid(),
        isHidden: true,
      },
      name: {
        label: "Custom list name",
        inputType: "text",
        required: true,
      },
      pointOfContactID: {
        removeFromInput: isSpecialRole({ context }) && context?.selectedOrganization !== pointOfContact?.ownerGroup,
        defaultValue: context?.loggedInPointOfContactId,
        isHidden: true,
      },
      ownerGroup: {
        defaultValue: context?.selectedOrganization,
        isHidden: true,
      },
    },
  };

  useEffect(() => {
    if (isEditing && initialValues) {
      customListForm?.mergeInput(initialValues);
    }
  }, [isEditing, initialValues]);

  const customListForm = useForm({
    ...listFieldConfig,
    typename: "TaskList",
    mutation: isEditing ? updateListMutation : createListMutation,
    submitFunction: async (item) => {
      await submitFunction({ item, isEditing });
    },
  });

  return <div>{customListForm?.display}</div>;
};

export default CreateCustomListForm;
