import React from "react";
import { Redirect } from "react-router-dom";

const GuestEvidenceLandingPage = React.lazy(
  () => import("../../views/Compliance/Evidence/components/EvidenceLandingPage/components/GuestEvidenceLandingPage"),
);
const TaskLandingPage = React.lazy(
  () => import("../../views/Program/Tasks/components/TaskLandingPage/TaskLandingPage"),
);

//All routes that are accessible to guests (individuals accessing the app that do not have a User account)
export const guestRoutes = [
  {
    path: "/guest/continuous_compliance/evidence_response/:id",
    exact: true,
    name: "Evidence Landing Page",
    component: GuestEvidenceLandingPage,
  },
  {
    path: "/guest/landing_page/task/:id",
    exact: true,
    name: "Task Landing Page",
    component: TaskLandingPage,
  },
  {
    path: "/guest",
    component: () => <Redirect to="/" />,
  },
];
