import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

/**
 * Calls the onKeyPerformanceIndicatorUpdate lambda function with custom evidence to check as input
 * @param {object} evidences - the evidence to check for a status change, must have an id
 * @param {object} triggerKPI - the KPI that prompted the evidence to be checked
 */
export const checkKPIEvidence = async ({ evidence, triggerKPI }) => {
  // Check arguments
  if (!evidence?.id || !triggerKPI?.id) {
    EventLogger(
      `Cannot run the KPI trigger without evidence id and kpi id!${JSON.stringify({ evidence, triggerKPI })}`,
    );
    return;
  }

  // Create query
  const onKeyPerformanceIndicatorUpdate = `
    query onKeyPerformanceIndicatorUpdate($input: AWSJSON) {
      onKeyPerformanceIndicatorUpdate(input: $input)
    }
  `;

  // Generate the lambda input
  const event = {
    override: {
      type: "evidenceCheck",
      input: {
        evidenceOverride: evidence?.id,
        kpiID: triggerKPI?.id,
      },
    },
  };

  // Make the lambda request
  try {
    return GetQuery({
      query: onKeyPerformanceIndicatorUpdate,
      variables: {
        input: JSON.stringify(event),
      },
    });
  } catch (e) {
    throw new Error(`Unable to call onKeyPerformanceIndicatorUpdate!${e}`);
  }
};
