import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { RunDataGridInput } from "./types";

export const runDataGridGraphqlQuery = async <T>({
  filters,
  organizationID,
  query,
  variables,
  limit,
  normalizeData,
  normalizeRowData,
  setIntermediateData,
}: RunDataGridInput<T>): Promise<T[] | undefined> => {
  try {
    let filter = filters ? { ...filters } : undefined;
    if (!variables) {
      filter = { ownerGroup: { eq: organizationID }, ...filters };
    }

    return (await ListQueryBy({
      query,
      variables,
      filter,
      limit,
      normalizeData,
      normalizeRowData,
      getDataByNextToken: setIntermediateData,
    })) as T[];
  } catch (e) {
    EventLogger("Error running query - ", e as object);
  }

  return undefined;
};
