import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { usePointOfContactDataGrid } from "./usePointOfContactDataGrid";

/**
 * @description Assign multiple points of contact to an item. NOTE: needs to be many to many connection
 * @param {string} typename - item type name from the schema
 * @param {string} connectionTypeName - many to many connection type name
 * @param {string} organizationID - selected organization
 * @param {string} mutation - custom mutation
 * @param {boolean} disabled - flag for disabling queries and subscriptions
 * @returns {object} {{submitFunction: (function(*): Promise<*[]>), gridDisplay: JSX.Element, data: *, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element|*, error: unknown, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, setError: (value: unknown) => void, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useSelectPointOfContacts = ({
  typename,
  connectionTypeName,
  organizationID,
  mutation,
  disabled = false,
}) => {
  const pointOfContactGrid = usePointOfContactDataGrid({
    organizationID,
    gridConfig: {
      enableSelection: true,
    },
    queryConfig: {
      query: disabled ? null : undefined,
      subscriptionHook: disabled ? null : undefined,
    },
    subscriptionConfig: {
      disabled: true,
    },
  });

  /**
   * Assign points of contact to an evidence
   */
  const submitFunction = async (item) => {
    if (!typename) {
      EventLogger("Missing typeName");
      return [];
    }

    if (!item?.id) {
      EventLogger("Missing item");
      return [];
    }

    const links = [];
    const selectedPointsOfContact = pointOfContactGrid?.selectedData;

    if (Array.isArray(selectedPointsOfContact) && selectedPointsOfContact > 0) {
      const { createMutation } = generateGraphql(connectionTypeName || "Item");
      for (const pointOfContact of selectedPointsOfContact) {
        await ItemMutation(mutation || createMutation, {
          pointOfContactID: pointOfContact?.id,
          [`${typename[0].toLowerCase() + typename.substring(1)}ID`]: item?.id,
          ownerGroup: organizationID,
        }).then((link) => {
          EventLogger(`Linked ${typename}: ${item?.id} to a pointOfContact: ${pointOfContact?.id}`);
          links.push(link);
        });
      }
    }
    return links;
  };

  return {
    ...pointOfContactGrid,
    submitFunction,
  };
};
