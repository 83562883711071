import { GridLinkOperator } from "@mui/x-data-grid-pro";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Generates the `filterModel` part of the `Default` data grid view based on field configurations for the grid
 * @param {object} input
 * @param {object[]} input.fields - fields prop passed through from useDataGrid
 * @return {object} - the default filter object for the data grid
 */
export const getDefaultDataGridFilterModel = ({ fields }) => {
  const allFilters = [];
  for (const field of fields) {
    const filters = field?.filters;
    const fieldName = field?.name;
    if (!isNonEmptyArray(filters) || !fieldName) {
      continue;
    }

    for (const filter of filters) {
      allFilters.push({
        columnField: fieldName,
        operatorValue: filter.operator,
        value: filter.value,
      });
    }
  }
  return {
    items: allFilters,
    linkOperator: GridLinkOperator.And,
  };
};
