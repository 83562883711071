import { tryFunction } from "../../../../../../utils/Functions/tryFunction";
import { createJob } from "../../../../../OrganizationManager/Jobs/functions/createJob";
import { resources } from "@rivial-security/role-utils/dist";
import { tryParse } from "@rivial-security/func-utils";
import { fargateApi } from "../../../../../../utils/Functions/FargateApi/fargateApi";
import { updateJob } from "../../../../../OrganizationManager/Jobs/functions/updateJob";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { Job } from "@rivial-security/schema-types";
import { OptionalNullable } from "../../../../../../typedefs/Utility/mutations";

export interface CreateAuditSubmitFunctionInput {
  item: {
    importData: unknown;
    name: string;
    auditDate: string;
    controlSetID: string;
  };
  toggleModal: () => void;
  addJob: ({ job }: { job: OptionalNullable<Job> }) => Promise<void>;
  includeDisabledControls: boolean;
  organizationID: string;
}

export const createAuditSubmitFunction = async ({
  item,
  toggleModal,
  addJob,
  includeDisabledControls,
  organizationID,
}: CreateAuditSubmitFunctionInput): Promise<void> => {
  try {
    tryFunction(toggleModal);

    const job = await createJob({
      name: `Audit Creation Job ${new Date().toLocaleString()}`,
      status: "started",
      ownerGroup: organizationID,
    });

    if (!job?.id) {
      throw new Error("Job ID not found, cannot track progress");
    }

    await addJob({ job });

    const importData = item?.importData || null;
    delete item?.importData;

    const input = {
      route: `create/${resources.AUDIT}`,
      audit: {
        ...item,
      },
      includeDisabledControls,
      organizationID,
      importData,
      job,
    };

    const fargateJob = tryParse(
      await fargateApi({
        input,
      }),
    ) as OptionalNullable<Job> | null;
    const fargateJobID = fargateJob?.id;
    if (typeof fargateJobID !== "string") {
      throw new Error("Fargate job ID not found");
    }

    if (fargateJobID !== job?.id) {
      await updateJob({
        id: job?.id,
        status: "failed",
        error: "Job IDs do not match",
      });
    }
  } catch (e) {
    EventLogger("Failed to create an audit - ", { e });
  }
};
