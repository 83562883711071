import React, { useContext } from "react";

/**
 * Context and helpers for resources that are regarded as Templates
 *
 * @type {React.Context<{isTemplate: boolean}>}
 */
export const TemplateContext = React.createContext({
  isTemplate: false,
});

export const TemplateContextProvider = TemplateContext.Provider;
export const TemplateContextConsumer = TemplateContext.Consumer;

export const useTemplateContext = () => {
  return useContext(TemplateContext);
};
