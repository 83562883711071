import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { CreateVendorReviewButton } from "../../VendorSolutions/customFields/VendorReviews";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useForm } from "../../../../hooks/views/useForm/useForm";

/**
 * Allows creation of a new VendorReview
 * @param {string} vendorSolutionID - the Vendor Solution to attach this review to
 * @param {function} getNewItem - callback for the data after creation
 * @param {function} toggleModal - callback for toggling a modal after creation
 * @param {string} organizationID - the current org ID
 * @param route
 * @param module
 * @param resource
 * @returns {{input: {}, display: JSX.Element}}
 */
export const useCreateVendorReview = ({
  vendorSolutionID,
  getNewItem,
  toggleModal,
  organizationID,
  route,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
}) => {
  const typename = "VendorReview";

  const { createMutation } = generateGraphql(typename, ["name", "status", "ownerGroup", "vendorSolutionID"]);

  //Get full list of available vendor solutions
  const { listQuery } = generateGraphql("VendorSolution", ["name"]);
  const [vendorList, setVendorList] = useState([]);

  //Allows user to select the vendor solution to which this vendor review belongs
  // (defaults to current vendor solution if vendorSolutionID provided)
  const retrieveVendorSolutions = async () => {
    const vendorData = await ListQuery({ query: listQuery, organizationID });

    if (!vendorData) {
      EventLogger("Failed to retrieve vendor solution data for create vendor solution hook!");
      return;
    }

    //Only keep vendor solutions with valid names and ids
    const formattedVendors = vendorData
      .filter((vendor) => {
        if (!vendor.name || !vendor.id) {
          return false; //skip invalid vendor
        } else {
          return true;
        }
      })
      .map((vendor) => {
        return { text: vendor.name, value: vendor.id };
      });

    setVendorList([...formattedVendors]);
  };
  useEffect(() => {
    retrieveVendorSolutions();
  }, []);

  // Makes sure that the default vendor solution is selected after retrieving the vendor solution list
  useEffect(() => {
    formHook.mergeInput({ vendorSolutionID });
  }, [vendorList]);

  const formHook = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    mutation: createMutation,
    toggleModal: toggleModal,
    organizationID,
    getNewItem,
    module,
    resource,
    typename,
    fieldConfig: {
      vendorSolutionID: {
        label: "Vendor Solution",
        tooltip: "The vendor product to perform a security review on",
        inputType: "dropdown",
        dropdownConfig: {
          data: [...vendorList],
        },
        defaultValue: vendorSolutionID,
        disabled: !!vendorSolutionID,
        required: true,
      },
    },
  });

  // Makes sure that the default vendor solution is selected after retrieving the vendor solution list
  // TODO: may not want to do this every time the vendor solution list changes
  useEffect(() => {
    formHook.mergeInput({ vendorSolutionID });
  }, [vendorList]);

  const display = (
    <div>
      {formHook.display}
      <span className={"float-right"}>
        <CreateVendorReviewButton
          module={module}
          resource={resource}
          vendorSolution={{
            name: vendorList && vendorList.find((item) => item?.value === formHook?.input?.vendorSolutionID)?.text,
            id: formHook?.input?.vendorSolutionID,
          }}
          organizationID={organizationID}
          style={{ marginBottom: "0.5em" }}
          disabled={formHook.submitDisabled}
          route={route}
        />
      </span>
    </div>
  );

  return { ...formHook, display };
};
