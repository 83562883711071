import { TaskStatus } from "../../../constants/taskStatus";

/**
 * check if task is past due by checking if due date is past due
 * @param {string} date - task due date
 * @returns {boolean}
 */
const isTaskDatePastDue = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const taskDate = new Date(date);
  taskDate.setHours(0, 0, 0, 0);
  return taskDate < today;
};

/**
 * check if task is past due by checking if status is past due or if due date is past due
 * @param {object} task - task object
 * @returns {boolean|*}
 */
export const isTaskPastDue = (task) => {
  const isStatusPastDue = task?.status === TaskStatus.PAST_DUE;
  const isDatePastDueStatus = task?.dueDate && isTaskDatePastDue(task?.dueDate);
  return isStatusPastDue || isDatePastDueStatus;
};
