import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import React, { useContext, useEffect, useState } from "react";
import PointOfContactDetails from "../../PointOfContacts/components/PointOfContactDetails";
import { formattedName } from "@rivial-security/func-utils";
import PointOfContactDataGrid from "../../PointOfContacts/components/PointOfContactDataGrid";
import { generateGraphql } from "@rivial-security/generategraphql";
import { DEPARTMENT } from "../constants/DEPARTMENT";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Custom field for displaying and editing the Department Head for a sinle Department
 * @param {DEPARTMENT} item - the Department object
 * @param {function} resetFunction - resets the parent query after an update
 * @param {function} updateItemById - updates the item in the parent query
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentHead = ({ item, resetFunction, updateItemById }) => {
  const [departmentData, setDepartmentData] = useState(null);
  const context = useContext(OrganizationContext);
  const queryFields = [
    DEPARTMENT.fields.name,
    DEPARTMENT.fields.description,
    DEPARTMENT.fields.departmentHead,
    DEPARTMENT.fields.departmentHeadID,
  ];
  const { listQuery: listDepartments, updateMutation: updateDepartment } = generateGraphql(
    DEPARTMENT.typename,
    queryFields,
    DEPARTMENT.nestedFields,
  );

  const { updateMutation: updatePointOfContact } = generateGraphql(
    "PointOfContact",
    ["id", "departmentID", "department"],
    {
      department: `{ id name departmentHeadID }`,
    },
  );

  const getAllDepartments = async () => {
    return await ListQueryBy({
      query: listDepartments,
      variables: { limit: 1000 },
      filter: { ownerGroup: { eq: context?.selectedOrganization } },
    });
  };

  useEffect(() => {
    getAllDepartments().then((departments) => {
      setDepartmentData(departments);
    });
  }, []);

  return (
    <NestedField
      item={item}
      updateItemById={updateItemById}
      parentTypename={"Department"}
      resetFunction={resetFunction}
      icon={"icon-shield"}
      typename={"PointOfContact"}
      field={"departmentHead"}
      idField={"departmentHeadID"}
      detailsComponent={<PointOfContactDetails />}
      modalConfig={{
        header: "Department Head Details",
        config: { width: "90vw" },
      }}
      grid={<PointOfContactDataGrid />}
      displayFieldFunction={(departmentHead) => formattedName({ pointOfContact: departmentHead })}
      biDirectional={true}
      submitFunction={async (departmentHead) => {
        const previousDepartmentHead =
          Array.isArray(departmentData) &&
          departmentData.find((department) => department?.departmentHeadID === departmentHead?.value);
        await ItemMutation(updateDepartment, {
          id: item?.id,
          departmentHeadID: departmentHead?.value,
        });

        await ItemMutation(updatePointOfContact, {
          id: departmentHead?.value,
          departmentID: item?.id,
        });

        if (previousDepartmentHead) {
          EventLogger("Removing previous department head");
          await ItemMutation(updateDepartment, {
            id: previousDepartmentHead?.id,
            departmentHeadID: null,
          });
          resetFunction && resetFunction();
        }
      }}
    />
  );
};

export default withOrganizationCheck(DepartmentHead);
