export const ToolsLinks = {
  name: "Integrations",
  url: "/integrations",
  icon: "simple-line-icons:puzzle",
  children: [
    // {
    //   dashboardPage: {
    //     name: 'Dashboard',
    //     url: '/tools/dashboard',
    //     icon: 'simple-line-icons:grid',
    //   }
    // },
    {
      knowBe4Page: {
        name: "KnowBe4",
        url: "/integrations/know_be_4",
        icon: "simple-line-icons:drawer",
      },
    },
    {
      mitreAttackPage: {
        name: "Mitre Att&ck",
        url: "/integrations/mitre_attack",
        icon: "simple-line-icons:umbrella",
      },
    },
    {
      toolConfigPage: {
        name: "Configuration",
        url: "/integrations/configuration/",
        icon: "simple-line-icons:settings",
      },
    },
  ],
};
