import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { precedenceTypes } from "@rivial-security/role-utils";
import { isSpecialRole } from "../../../functions/isSpecialRole";

/**
 *Determines if the custom list should be displayed based on the user's role and the view type
 * @param {object} list - the custom list
 * @param {object} context - the organization context
 * @param {boolean} isSidebarView - whether the view is a sidebar view or not
 * @param {object[]} userAvailableTasks - the tasks that the user has assigned to them
 * @returns {boolean}
 */
export const shouldDisplayCustomList = ({ list, context, isSidebarView, userAvailableTasks }) => {
  const isCreator = context?.loggedInPointOfContactId === list?.pointOfContactID;
  const hasAssignedTask = isNonEmptyArray(userAvailableTasks);

  const isManager = context?.role?.precedence === precedenceTypes.MANAGER;

  const canStandardUserView = isCreator || hasAssignedTask;

  const canManagerViewSidebarLists = isManager && (isCreator || hasAssignedTask);
  const canManagerViewNonSidebarLists = isManager && (isCreator || isNullOrUndefined(list?.pointOfContactID));

  const canSpecialRoleViewLists =
    isSpecialRole({ context }, { excludeRoles: ["manager"] }) &&
    (isNullOrUndefined(list?.pointOfContactID) || isCreator);

  // Determine visibility based on view type and user role
  if (isSidebarView) {
    return canSpecialRoleViewLists || canManagerViewSidebarLists || (!isManager && canStandardUserView);
  } else {
    return canManagerViewNonSidebarLists || canSpecialRoleViewLists || (!isManager && canStandardUserView);
  }
};
