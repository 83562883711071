import React from "react";
import { Redirect } from "react-router-dom";

const RealTimeRiskDashboard = React.lazy(() => import("../../views/Risk/Dashboard/RiskDashboard"));
const RealTimeRiskSystems = React.lazy(() => import("../../views/Risk/Systems/components/SystemDataGrid"));
const RealTimeRiskSystemDetails = React.lazy(() => import("../../views/Risk/Systems/components/SystemDetails"));
const RealTimeRiskRecommendations = React.lazy(
  () => import("../../views/Risk/Recommendations/components/RecommendationsDataGrid"),
);
const RealTimeRiskRecommendationsDetails = React.lazy(
  () => import("../../views/Risk/Recommendations/components/RecommendationDetails"),
);
const RealTimeRiskChanges = React.lazy(
  () => import("../../views/Risk/RiskChanges/components/GroupedRiskChangeDataGrid"),
);
const RealTimeCreateRiskChange = React.lazy(
  () => import("../../views/Risk/RiskChanges/components/CreateRiskChangeLandingPage"),
);
const RealTimeRiskChangeDetails = React.lazy(() => import("../../views/Risk/RiskChanges/components/RiskChangeDetails"));
const RealTimeRiskAssessments = React.lazy(
  () => import("../../views/Risk/RiskAssessment/components/RiskAssessmentList"),
);
const RealTimeRiskAssessmentDetails = React.lazy(
  () => import("../../views/Risk/RiskAssessment/components/RiskAssessment"),
);
const RealTimeRiskAssessmentReport = React.lazy(
  () => import("../../views/Reports/RiskAssessmentReport/components/RiskAssessmentReport"),
);

// Threats
const ThreatGrid = React.lazy(() => import("../../views/Response/Threats/components/ThreatDataGrid"));
const ThreatDetails = React.lazy(() => import("../../views/Response/Threats/components/ThreatDetails"));

// Risk Config
const RiskConfig = React.lazy(() => import("../../views/Risk/RiskConfig/RiskConfig"));
const RiskConfigAssets = React.lazy(() => import("../../views/Risk/RiskConfig/Assets/RiskConfigAssets"));
const RiskConfigThreats = React.lazy(() => import("../../views/Risk/RiskConfig/Threats/components/ListThreats"));
const RiskConfigRisks = React.lazy(
  () => import("../../views/Risk/RiskConfig/KeyRiskIndicators/components/ListKeyRiskIndicators"),
);
const RiskConfigDetails = React.lazy(
  () => import("../../views/Risk/RiskConfig/KeyRiskIndicators/components/KeyRiskIndicatorDetails"),
);
const RiskConfigControlCategories = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/ListControlCategories"),
);
const RiskConfigControlCategoryDetails = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/ControlCategoryDetails"),
);
const RiskControlGrid = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlGrid"),
);
const RiskControlDetails = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails"),
);
const RiskControlCategoryGrid = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/ControlCategoriesDataGrid"),
);
const RiskControlCategoryDetails = React.lazy(
  () => import("../../views/Risk/RiskConfig/ControlCategories/components/ControlCategoryDetails"),
);

// SystemDashboard
const SystemDashboard = React.lazy(() => import("../../views/Risk/Systems/components/SystemDashboard"));

export const riskRoutes = [
  {
    path: "/risk/dashboard",
    exact: true,
    name: "Real-Time Risk Dashboard",
    component: RealTimeRiskDashboard,
  },
  {
    path: "/risk/systems",
    exact: true,
    name: "Information Systems",
    component: SystemDashboard,
  },
  {
    path: "/risk/recommendations",
    exact: true,
    name: "Risk Recommendations",
    component: RealTimeRiskRecommendations,
  },
  {
    path: "/risk/recommendations/:id",
    exact: true,
    name: "Risk Recommendation Details",
    component: RealTimeRiskRecommendationsDetails,
  },
  {
    path: "/risk/systems/:id",
    exact: true,
    name: "System Details",
    component: RealTimeRiskSystemDetails,
  },
  {
    path: "/risk/changes",
    exact: true,
    name: "Risk Changes",
    component: RealTimeRiskChanges,
  },
  {
    path: "/risk/changes/:id",
    exact: true,
    name: "Risk Change Details",
    component: RealTimeRiskChangeDetails,
  },
  {
    path: "/risk/create_risk_change/",
    exact: true,
    name: "Create Risk Change",
    component: RealTimeCreateRiskChange,
  },
  {
    path: "/risk/assessments",
    exact: true,
    name: "Risk Assessments",
    component: RealTimeRiskAssessments,
  },
  {
    path: "/risk/assessments/:id",
    exact: true,
    name: "Risk Assessment Details",
    component: RealTimeRiskAssessmentDetails,
  },
  {
    path: "/risk/reports/risk_assessment",
    noScrolling: true,
    exact: true,
    name: "Risk Assessment Report",
    component: RealTimeRiskAssessmentReport,
  },
  {
    path: "/risk/control_categories",
    exact: true,
    name: "Risk Control Categories",
    component: RiskControlCategoryGrid,
  },
  {
    path: "/risk/control_categories/:id",
    exact: true,
    name: "Risk Control Category Details",
    component: RiskControlCategoryDetails,
  },

  // Threats
  {
    path: "/risk/threats",
    exact: true,
    name: "Threats",
    component: ThreatGrid,
  },
  {
    path: "/risk/threats/:id",
    exact: true,
    name: "Threat Details",
    component: ThreatDetails,
  },

  // Risk Config
  {
    path: "/risk/risk_config",
    exact: true,
    name: "Risk Configuration",
    component: RiskConfig,
  },
  {
    path: "/risk/risk_config/assets",
    exact: true,
    name: "Risk Configuration: Assets",
    component: RiskConfigAssets,
  },
  {
    path: "/risk/risk_config/threats",
    exact: true,
    name: "Risk Configuration: Threats",
    component: RiskConfigThreats,
  },
  {
    path: "/risk/risk_config/risks",
    exact: true,
    name: "Risk Configuration: Risks",
    component: RiskConfigRisks,
  },
  {
    path: "/risk/risk_config/risks/:id",
    exact: true,
    name: "Risk Configuration: Risk Details",
    component: RiskConfigDetails,
  },
  {
    path: "/risk/risk_config/control_categories",
    exact: true,
    name: "Risk Configuration: Control Categories",
    component: RiskConfigControlCategories,
  },
  {
    path: "/risk/risk_config/control_categories/:id",
    exact: true,
    name: "Risk Configuration: Control Category Details",
    component: RiskConfigControlCategoryDetails,
  },
  {
    path: "/risk/risk_config/risk_controls",
    exact: true,
    name: "Risk Configuration: Risk Controls",
    component: RiskControlGrid,
  },
  {
    path: "/risk/risk_config/risk_controls/:id",
    exact: true,
    name: "Risk Configuration: Risk Control Details",
    component: RiskControlDetails,
  },

  { path: "/risk", component: () => <Redirect to="/risk/dashboard" /> },
];
