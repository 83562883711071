import { Card, CardContent, CardHeader, Chip, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ItemMutation } from "../../Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../EventLogger/EventLogger";
import { formattedName } from "@rivial-security/func-utils";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * @description Displays the Access List for point of contacts and roles
 * @param {object} item - database object with an accessControl field
 * @param {array} contacts - list of point of contacts with access
 * @param {array} roles - list of roles with access
 * @param {string} itemName - name of the item
 * @param {string} listToDisplay - list to display (Point of Contacts or Roles)
 * @param {function} resetFunction - reset state of the item
 * @param {function} onRoleRemoved - function to call when a role is removed
 * @param {function} onPointOfContactRemoved - function to call when a point of contact is removed
 * @return {JSX.Element}
 * @constructor
 */
const AccessListDisplay = ({
  item,
  contacts: initialContacts,
  roles: initialRoles,
  itemName,
  listToDisplay,
  resetFunction,
  onRoleRemoved,
  onPointOfContactRemoved,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState(initialContacts);
  const [roles, setRoles] = useState(initialRoles);

  const { updateMutation } = generateGraphql("Meeting", ["id", "accessControl"], {
    accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
  });
  /**
   * @description Remove access to an item
   * @param {object} itemToRemove - item to remove
   * @param {string} type - type of item to remove (pointsOfContact or roles)
   * @return {Promise<void>}
   */
  const removeAccess = async (itemToRemove, type) => {
    setIsLoading(true);
    const updatedAccessControl = { ...item?.accessControl };

    if (type === "pointsOfContact") {
      updatedAccessControl.pointOfContacts = contacts
        .filter((contact) => contact?.id !== itemToRemove?.id)
        .map((contact) => contact?.id);
    } else if (type === "roles") {
      updatedAccessControl.roles = roles.filter((role) => role?.id !== itemToRemove?.id).map((role) => role?.id);
    }

    try {
      await ItemMutation(updateMutation, {
        id: item?.id,
        accessControl: updatedAccessControl,
      });

      // Update local state after successful mutation
      if (type === "pointsOfContact") {
        setContacts((prevContacts) => prevContacts.filter((contact) => contact?.id !== itemToRemove?.id));
        onPointOfContactRemoved && onPointOfContactRemoved(itemToRemove?.id);
      } else if (type === "roles") {
        setRoles((prevRoles) => prevRoles.filter((role) => role?.id !== itemToRemove?.id));
        onRoleRemoved && onRoleRemoved(itemToRemove?.id);
      }
    } catch (err) {
      EventLogger(`Error: There was an error removing ${type} access to this item.`, err);
    }
    setIsLoading(false);
    resetFunction && resetFunction();
  };

  /**
   * @description Renders the list of point of contacts or roles
   * @param {object[]} list - list of point of contacts or roles
   * @param {string} label - label to display (Point of Contacts or Roles)
   * @param {string} type - type of item to remove (pointOfContact or role)
   * @return {JSX.Element}
   */
  const renderList = (list, label, type) => (
    <Card sx={{ boxShadow: "none", border: "1px solid #e0e0e0" }}>
      <CardHeader
        sx={{ padding: ".6rem", textAlign: "center" }}
        titleTypographyProps={{ variant: "h6" }}
        title={`List of ${label} with access to: ${itemName}`}
      />
      <CardContent sx={{ padding: ".5rem" }}>
        {isNonEmptyArray(list) ? (
          list.map((item) => (
            <Chip
              key={item?.id}
              label={type === "pointsOfContact" ? formattedName({ pointOfContact: item }) || item?.email : item?.name}
              onDelete={() => removeAccess(item, type)}
              deleteIcon={<RemoveCircleOutlineIcon style={{ color: "#df0101" }} />}
              color="primary"
              variant="outlined"
              sx={{ margin: "0.5em" }}
            />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            All {label}s have access to this item. To limit access, select {label} from the list above and press submit.
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  if (isLoading) {
    return (
      <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </CardContent>
    );
  }

  return listToDisplay === "pointsOfContact"
    ? renderList(contacts, "Points of Contact", "pointsOfContact")
    : renderList(roles, "Roles", "roles");
};

export default AccessListDisplay;
