import React, { useContext, useEffect, useReducer, useState } from "react";
import { ReportsModuleLinks, ReportsModuleManagerLinks } from "../../NavLinks/ReportsModuleLinks";
import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";

import { ActionCenterLinks } from "../../NavLinks/ActionCenterLinks";
import { AdministratorPanelModuleLinks } from "../../NavLinks/AdministratorPanelModuleLinks";
import { ComplianceModuleLinks } from "../../NavLinks/ComplianceModuleLinks";
import { DeveloperModuleLinks } from "../../NavLinks/DeveloperModuleLinks";
import { GovernanceModuleLinks } from "../../NavLinks/GovernanceModuleLinks";
import { IncidentResponseLinks } from "../../NavLinks/IncidentResponseLinks";
import { ListQuery } from "../../../utils/Functions/Graphql/ListQuery";
import { MetricsLinks } from "../../NavLinks/MetricsLinks";
import { OperationPanelLinks } from "../../NavLinks/OperationPanelLinks";
import { OrganizationContext } from "../../../utils/Context/OrganizationContext";
import { OrganizationManagerLinks } from "../../NavLinks/OrganizationManagerLinks";
import { RiskModuleLinks } from "../../NavLinks/RiskModuleLinks";
import { ToolsLinks } from "../../NavLinks/ToolsLinks";
import { VendorLinks } from "../../NavLinks/VendorLinks";
import { VulnerabilitiesModuleLinks } from "../../NavLinks/VulnerabilitiesModuleLinks";
import { actionCenterRoutes } from "../../Routes/ActionCenterRoutes";
import { administratorImportersRoutes } from "../../Routes/AdministratorImportersRoutes";
import { administratorPanelRoutes } from "../../Routes/AdministratorPanelRoutes";
import { checkPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { complianceRoutes } from "../../Routes/ComplianceRoutes";
import { developerPanelRoutes } from "../../Routes/DeveloperPanelRoutes";
import { generalRoutes } from "../../Routes/GeneralRoutes";
import { generateGraphql } from "@rivial-security/generategraphql";
import { governanceRoutes } from "../../Routes/GovernanceRoutes";
import { helpCenterRoutes } from "../../Routes/HelpCenterRoutes";
import { incidentResponseRoutes } from "../../Routes/IncidentResponseRoutes";
import { mainRoutes } from "../../Routes/MainRoutes";
import { metricsRoutes } from "../../Routes/MetricsRoutes";
import { modules } from "@rivial-security/role-utils";
import { operationPanelRoutes } from "../../Routes/OperationPanelRoutes";
import { organizationManagerRoutes } from "../../Routes/OrganizationManagerRoutes";
import { reportsRoutes } from "../../Routes/ReportsRoutes";
import { riskRoutes } from "../../Routes/RiskRoutes";
import { settingsRoutes } from "../../Routes/SettingsRoutes";
import { toolsRoutes } from "../../Routes/ToolsRoutes";
import { vendorRoutes } from "../../Routes/VendorRoutes";
import { vulnerabilitiesRoutes } from "../../Routes/VulnerabilitiesRoutes";
import "../css/Navbar.css";

/** @returns {import('./types/Navigation').UseNavigationLinksResult} */
export const useNavigationLinks = () => {
  const context = useContext(OrganizationContext);
  const initialState = {
    items: [],
  };

  const reducer = (state, newItem) => {
    if (newItem === "clear") {
      return {
        items: [],
      };
    }

    return {
      items: [...state.items, newItem],
    };
  };

  const [navigation, navLinkUpdate] = useReducer(reducer, initialState);

  const [roleAccountType, setRoleAccountType] = useState("standard");

  const [routes, setRoutes] = useState([]);

  const getChildren = (moduleLink, moduleName) => {
    const moduleLinkCopy = JSON.parse(JSON.stringify(moduleLink));

    if (moduleLink && moduleLink.children) {
      const pageChildren = [];

      for (const page of moduleLink.children) {
        //NOTE: currently not checking for permissions
        pageChildren.push(Object.values(page)[0]);
      }

      moduleLinkCopy.children = pageChildren;
    }

    return moduleLinkCopy;
  };

  // Holds array of dynamic links to merge with the static links
  const [dynamicLinks, setDynamicLinks] = useState([]);

  // Handle dynamic nav links
  useEffect(() => {
    if (context?.selectedOrganization) {
      // Dynamic Tool Links
      // TODO: refactor to listQueryBy
      ListQuery({
        query: generateGraphql("Tool", ["type", "name", "description"]).listQuery,
        organizationID: context?.selectedOrganization,
        filters: { type: { eq: "custom" } },
      }).then((tools) => {
        const customToolNavLinks = [];

        // truncates the name if it's too long
        const getName = (text) => {
          if (text.length > 15) {
            return `${text.substring(0, 15)}...`;
          }
          return text;
        };

        if (Array.isArray(tools)) {
          for (const tool of tools) {
            customToolNavLinks.push({
              name: getName(tool?.name),
              description: tool?.name,
              url: `/integrations/dashboard/${tool?.id}`,
              icon: "simple-line-icons:wrench",
              parent: "/integrations",
            });
          }
        }

        setDynamicLinks(customToolNavLinks);
      });
    }
  }, [context?.selectedOrganization]);

  // Merge dynamic links with static links
  useEffect(() => {
    if (navigation && dynamicLinks) {
      for (const link of dynamicLinks) {
        const parent = navigation.items.find((item) => item.url === link.parent);

        if (parent?.children) {
          const childExists = parent.children.find((child) => child.url === link.url);

          if (!childExists) {
            parent.children.push(link);
          }

          // move configuration links to the end
          for (const child of parent.children) {
            if (child.url.includes("configuration")) {
              parent.children.splice(parent.children.indexOf(child), 1);
              parent.children.push(child);
            }
          }
        } else if (parent) {
          parent.children = [link];
        }
      }
    }
  }, [navigation, dynamicLinks]);

  useEffect(() => {
    let newRoutes = [];
    navLinkUpdate("clear");

    // not sure what this does?
    if (navigation && navigation.items && Array.isArray(navigation.items)) {
      // navigation.items.splice(1);
    }

    newRoutes = newRoutes.concat(mainRoutes);
    newRoutes = newRoutes.concat(settingsRoutes);

    /**
     * First you need to add new module to roleConfigDefault, please go to roleConfigDefault.js
     * Add new Module example:
     if (checkPermissions({module: "vciso", roleConfig: context.roleConfig}).module.isEnabled) { // check permissions
          addNavLinks(getChildren(VCISOModuleLinks, "vciso")); // "VCISOModuleLinks" you need to create links for new module
          newRoutes = newRoutes.concat(vcisoRoutes); // add "vcisoRoutes" routes array to a Routes folder
          newRoutes = newRoutes.concat(actionCenterRoutes); // more routes for new module (optional)
        }
     */

    if (context.loggedInPointOfContactId) {
      newRoutes = newRoutes.concat(generalRoutes);
    }

    if (
      checkPermissions({
        module: modules.HELP_CENTER,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      newRoutes = newRoutes.concat(helpCenterRoutes);
    }

    if (
      checkPermissions({
        module: modules.ACTION_CENTER,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(ActionCenterLinks);
      // addNavLinks(getChildren(ActionCenterLinks, "actionCenter"));
      newRoutes = newRoutes.concat(actionCenterRoutes);
    }

    let reportOrganizationManagerLinks = [];
    if (
      checkPermissions({
        module: modules.ORGANIZATION_MANAGER,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      reportOrganizationManagerLinks = getChildren(ReportsModuleManagerLinks, modules.REPORTS);
    }

    // REPORT
    if (
      checkPermissions({
        module: modules.REPORTS,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      const reportsLinks = getChildren(ReportsModuleLinks, modules.REPORTS) || {};
      const reportsManagerChildren = reportOrganizationManagerLinks?.children;
      if (isNonEmptyArray(reportsManagerChildren)) {
        reportsLinks.children = [
          ...reportsManagerChildren,
          ...(Array.isArray(reportsLinks?.children) ? reportsLinks.children : []),
        ];
      }

      navLinkUpdate(reportsLinks, modules.REPORTS);
      newRoutes = newRoutes.concat(reportsRoutes);
    }

    /**
     * Handle the old 'vciso' module as well as the renamed 'program' module,
     * For backwards compatibility with legacy user accounts.
     */
    if (
      checkPermissions({
        module: modules.GOVERNANCE,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(GovernanceModuleLinks, modules.GOVERNANCE));
      newRoutes = newRoutes.concat(governanceRoutes);
    } else if (checkPermissions({ module: "vciso", roleConfig: context.roleConfig }).module.isEnabled) {
      navLinkUpdate(getChildren(GovernanceModuleLinks, "vciso"));
      newRoutes = newRoutes.concat(governanceRoutes);
    }

    if (checkPermissions({ module: modules.RISK, roleConfig: context.roleConfig }).module.isEnabled) {
      navLinkUpdate(getChildren(RiskModuleLinks, modules.RISK));
      newRoutes = newRoutes.concat(riskRoutes);
    }

    if (
      checkPermissions({
        module: modules.COMPLIANCE,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(ComplianceModuleLinks, modules.COMPLIANCE));
      newRoutes = newRoutes.concat(complianceRoutes);
    }

    if (
      checkPermissions({
        module: modules.VULNERABILITIES,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(VulnerabilitiesModuleLinks, modules.VULNERABILITIES));
      newRoutes = newRoutes.concat(vulnerabilitiesRoutes);
    }

    if (
      checkPermissions({
        module: modules.VENDORS,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(VendorLinks, modules.VENDORS));
      newRoutes = newRoutes.concat(vendorRoutes);
    }

    /**
     * Temporarily Disabled until the Training module is implemented
     */
    // if (checkPermissions({module: "training", roleConfig: context.roleConfig}).module.isEnabled) {
    //   addNavLinks(getChildren(TrainingLinks, "training"));
    //   newRoutes = newRoutes.concat(trainingRoutes);
    // }

    if (
      checkPermissions({
        module: modules.INCIDENT_RESPONSE,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(IncidentResponseLinks, modules.INCIDENT_RESPONSE));
      newRoutes = newRoutes.concat(incidentResponseRoutes);
    }

    /**
     * TODO: Add new resources to the roles package and enable this check
     */
    if (
      checkPermissions({
        module: modules.TOOLS,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(ToolsLinks, modules.TOOLS));
      newRoutes = newRoutes.concat(toolsRoutes);
    }

    navLinkUpdate({
      title: true,
      name: <hr />,
      class: "text-center divider-line",
      divider: true,
    });

    if (
      checkPermissions({
        module: modules.ORGANIZATION_MANAGER,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(OrganizationManagerLinks, modules.ORGANIZATION_MANAGER));
      newRoutes = newRoutes.concat(organizationManagerRoutes);
    }

    if (
      checkPermissions({
        module: modules.METRICS,
        roleConfig: context.roleConfig,
      }).module.isEnabled
    ) {
      navLinkUpdate(getChildren(MetricsLinks, modules.METRICS));
      newRoutes = newRoutes.concat(metricsRoutes);
    }

    // Handle Operation Panel routes
    if (
      checkPermissions({
        module: modules.OPERATION_PANEL,
        roleConfig: context.roleConfig,
      }).module.isEnabled &&
      !isNullOrUndefined(context?.operationTeamID) &&
      context?.accountType === "operationTeamMember"
    ) {
      navLinkUpdate({
        title: true,
        name: <hr />,
        class: "text-center divider-line",
        divider: true,
      });
      navLinkUpdate(OperationPanelLinks);
      newRoutes = newRoutes.concat(operationPanelRoutes);
      setRoleAccountType("Operation Team Member");
    }

    if (
      checkPermissions({
        module: modules.ADMINISTRATOR,
        roleConfig: context.roleConfig,
      }).module.isEnabled &&
      context.userCognitoGroups &&
      context.userCognitoGroups.includes("Admin")
    ) {
      navLinkUpdate({
        title: true,
        name: <hr />,
        class: "text-center divider-line",
        divider: true,
      });
      navLinkUpdate(AdministratorPanelModuleLinks);
      newRoutes = newRoutes.concat(administratorPanelRoutes);
      newRoutes = newRoutes.concat(administratorImportersRoutes);
      setRoleAccountType("administrator");
    }

    if (
      checkPermissions({
        module: modules.DEVELOPER,
        roleConfig: context.roleConfig,
      }).module.isEnabled &&
      context.userCognitoGroups &&
      context.userCognitoGroups.includes("Developer")
    ) {
      navLinkUpdate(DeveloperModuleLinks);
      newRoutes = newRoutes.concat(developerPanelRoutes);
      setRoleAccountType("Developer");
    }

    //Set all available routes at once so that a redirect doesn't occur prematurely
    setRoutes(newRoutes);
  }, [context?.roleConfig]);

  return {
    routes,
    navigation,
    roleAccountType,
  };
};
