import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a ActionItemTagLink object
 * @param {object} actionItem - the action item that needs to be attached to the tag
 * @param {object} tag - the tag that needs to be attached to the action item
 * @param {string} organizationID - the organization identifier of the action item in the database
 * @return {Promise<*>}
 */
export const createActionItemTagLink = (actionItem, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(actionItem?.id)) {
    EventLogger("Invalid input!");
  }

  const { createMutation: createActionItemTagLink } = generateGraphql("ActionItemTagLink", [
    "actionItemID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createActionItemTagLink, {
    actionItemID: actionItem.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
