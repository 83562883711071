import { useEffect, useState } from "react";

import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useModal } from "../../../../hooks/views/useModal";

/**
 * Handles state and logic for Custom Report Configurations for the DocumentEditor.
 *
 * @param {function} generateFromTemplate - the documentEditor 'generateFromTemplate' function
 * @returns {{
 *   {function} handleTemplateClick(templateFunction, fieldConfig) - pass in the generateXReport function that returns SFDT code, and a fieldConfig object that matches the useForm hook for custom configs
 * }}
 *
 * @example handleTemplateClick(generateRiskAssessmentReport, {includeAllSystems: {label: "Include All Systems", inputType: "switch", defaultValue: true} }
 */
export const useReportConfiguration = (generateFromTemplate) => {
  /**
   * Holds the fieldConfig in state for the useForm hook
   */
  const [fieldConfig, setFieldConfig] = useState({});

  /**
   * Holds the submitFunction in state for the useForm hook
   * Note: due to React State lazy loading, the function must be saved in the following manner:
   * setState( () => () => {...} )
   */
  const [submitFunction, setSubmitFunction] = useState(null);

  /**
   * useForm hook to dynamically set the configuration inputs
   * @type {{display: *, input: {}}}
   */
  const configurationForm = useForm({
    submitFunction: async (input) => await generateFromTemplate(submitFunction, input),
    disableResetButton: true,
    fieldConfig,
  });

  /**
   * Modal that holds the configuration settings. Opens when the template button is clicked, and auto-closes after submit
   * @type {{setModalIsOpen: (function(*=): void), modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element, toggleModal: toggleModal}}
   */
  const configurationModal = useModal("Report Configuration", configurationForm.display);

  /**
   * When the configuration form fieldConfig gets updated, ensures the form hook gets updated for default values
   */
  useEffect(() => {
    if (!isNullOrUndefined(fieldConfig)) {
      configurationForm.getInitialState();
    }
  }, [fieldConfig]);

  /**
   * Wraps the template function in a handler to be able in automatically incorporate a dynamic useForm and useModal hook for custom configs
   * @param {function} func - async function that returns the sfdt for the document editor
   * @param {object} fieldConfig - field configurations for the configuration form (see useForm hook)
   * @returns {Promise<void>}
   */
  const handleTemplateClick = async (func, fieldConfig) => {
    setSubmitFunction((submitFunction) => {
      return async (input) => {
        configurationModal.setModalIsOpen(false);

        let data;
        try {
          data = await func(input);
        } catch (e) {
          EventLogger("Error while generating a report: ", e);
        }

        return data;
      };
    });
    setFieldConfig(fieldConfig);
    configurationModal.setModalIsOpen(true);
  };

  return {
    ...configurationModal,
    ...configurationForm,
    fieldConfig,
    setFieldConfig,
    handleTemplateClick,
  };
};
