import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../../../../utils/Functions/Graphql/ItemQuery";

const { getQuery: getInformationAsset } = generateGraphql("InformationAsset", ["systems"], {
  systems: `(limit: 500) { items { id } }`,
});

const { deleteMutation: deleteInformationAsset } = generateGraphql("InformationAsset");

const { deleteMutation: deleteSystemInformationAssetLink } = generateGraphql("SystemInformationAssetLink");

const DeleteInformationAsset = async (informationAsset) => {
  const fullItem = await ItemQuery(getInformationAsset, informationAsset.id);

  // Delete System links
  if (
    fullItem &&
    fullItem.systems &&
    fullItem.systems.items &&
    fullItem.systems.items.length &&
    fullItem.systems.items.length > 0
  ) {
    for (const systemLink of fullItem.systems.items) {
      API.graphql(
        graphqlOperation(deleteSystemInformationAssetLink, {
          input: {
            id: systemLink.id,
          },
        }),
      ).then(() => EventLogger(`Information Asset System Link${systemLink.id} was Successfully Deleted`));
    }
  }

  API.graphql(
    graphqlOperation(deleteInformationAsset, {
      input: {
        id: informationAsset.id,
      },
    }),
  ).then(() => EventLogger(`Information Asset${informationAsset.name} was Successfully Deleted`));
};

export default DeleteInformationAsset;
