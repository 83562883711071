import React, { useEffect, useState } from "react";
import { convertToKebabCase, isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import CustomResourceEntryDetails from "../components/CustomResourceEntryDetails";
import CustomResourceEntryForm from "../components/CustomResourceEntryForm";
import { EventLogger } from "@utils/EventLogger/EventLogger";
import { ItemQuery } from "@utils/Functions/Graphql/ItemQuery";
import { deleteCustomResourceEntry } from "../functions/deleteCustomResourceEntry";
import { generateGraphql } from "@rivial-security/generategraphql";
import { generateGridCustomFields } from "@views/Compliance/Controls/Controls/functions/generateGridCustomFields";
import { objectListSimilarByField } from "@utils/Functions/objectListSimilarByField";
import { useCustomResourceEntryGridData } from "./useCustomResourceEntryGridData";
import { useDataGrid } from "@hooks/views/useDataGrid/useDataGrid";

/**
 * Custom hook to display a list of CustomResourceEntry items
 *
 * @param {string} organizationID - the organization id from the database of current selected organization
 * @param {string} module=modules.TOOLS - platform module for role checking
 * @param {string} resource=resources.CUSTOM_RESOURCE_TYPE - platform resource for role checking
 * @param {boolean} disableRoleChecking=false - if TRUE will disable role checking
 * @param {string} customResourceTypeID - the CustomResourceType identifier from the database for which to show CustomResourceEntrys and display custom fields
 * @param {string[]} queryFields - filter which fields to display in the table by not retrieving certain properties
 * @param {object} queryConfig - use if need to customize the get query for the grid completely
 * @param {object} gridConfig - grid settings/props see useGrid.js
 * @param {boolean} isTemplate - if TRUE a CustomResourceEntry template will be shown
 * @param {function} resetFunction - function to reset the parent of the grid
 * @param {object} props - additional props to pass to the grid
 * @return {{customFrameworkFields: *[], gridDisplay: *, data: *, tagFilterApplied: boolean, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: *, isLoading: boolean, createResourceComponent: "preserve" | "react" | "react-native", ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, resetFunction: function(): void, customFrameworkFieldNames: *[], fields: Object[], setItemsToCheck: (value: (((prevState: undefined) => undefined) | undefined)) => void, selectedItems: []}}
 */
export const useCustomResourceEntryDataGrid = ({
  organizationID,
  module = modules.TOOLS,
  resource = resources.CUSTOM_RESOURCE_TYPE,
  disableRoleChecking = false,
  customResourceTypeID,
  queryFields = ["customResourceTypeID", "customFieldData"],
  queryConfig = {},
  gridConfig = {},
  isTemplate = false,
  resetFunction,
  ...props
}) => {
  ///[GENERAL SETUP]
  // - Constants
  const typename = "CustomResourceEntry";
  // - Queries
  const { getQuery: getCustomResourceTypeQuery } = generateGraphql("CustomResourceType", ["customFields", "name"], {
    customFields: `{name description type options { label value } multipleSelect { label value } numberSettings { min max step format }}`,
  });

  // - Query and Mutation for CustomResourceEntry
  const { updateMutation: customResourceEntryUpdateMutation, listQuery } = generateGraphql(typename, queryFields, {
    customResourceType: `{id name ownerGroup}`,
  });

  // - Permissions
  const roleConfig = {
    module,
    resource,
    disableRoleChecking,
  };

  /// [STATE]
  const [fields, setFields] = useState([]);
  const [customFieldNames, setCustomFieldNames] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const basePersistenceID = "c10661db-eaa6-4b4f-a7fc-60bed75b9egssd";
  const [persistenceUUID, setPersistenceUUID] = useState(basePersistenceID);
  /// [GRID SETUP]
  // - define which FIELDS are shown and the components to use for them
  useEffect(() => {
    let newFields = [];

    newFields = newFields.concat(
      generateGridCustomFields({
        module,
        resource,
        disableRoleChecking,
        customFields,
        visible: true,
        typename,
      }),
    );

    //Check if change in fields is necessary (prevents infinite loop)
    if (!objectListSimilarByField({ listOne: fields, listTwo: newFields })) {
      setFields(newFields);
    }
  }, [JSON.stringify(customFields)]);

  // - updates custom fields and persistenceID configuration for the selected CustomResourceType
  const updateFrameworkSettings = async () => {
    //Retrieve data for the new CustomResourceType
    const customResourceTypeCustomFieldNames = [];
    let newCustomFields = [];
    try {
      // - execute CustomResourceType query
      let customResourceType;
      if (customResourceTypeID) {
        customResourceType = await ItemQuery(getCustomResourceTypeQuery, customResourceTypeID);
      }

      // - check for custom the field property to be present
      if (Array.isArray(customResourceType?.customFields)) {
        newCustomFields = customResourceType.customFields;
      }

      // - get all customResourceType custom field names
      if (newCustomFields && Array.isArray(newCustomFields)) {
        for (const customField of newCustomFields) {
          if (customField?.name) {
            customResourceTypeCustomFieldNames.push(customField.name);
          }
        }
      }

      // - update persistenceID
      let newPersistenceUUID = basePersistenceID;
      const customResourceTypeSnakeCaseName = convertToKebabCase({
        text: customResourceType?.name,
      });

      if (customResourceTypeSnakeCaseName) {
        newPersistenceUUID = `${basePersistenceID}-${customResourceTypeSnakeCaseName}`;
      }
      setPersistenceUUID(newPersistenceUUID);
    } catch (e) {
      EventLogger("Failed to get CustomResourceType custom fields in CustomResourceEntry grid!", e);
    }
    setCustomFields(newCustomFields);
    setCustomFieldNames(customResourceTypeCustomFieldNames);
  };

  // - Updates the custom field configuration when CustomResourceType changes
  useEffect(() => {
    if (!isNullOrUndefined(customResourceTypeID) && customResourceTypeID !== "") {
      updateFrameworkSettings();
    }
  }, [customResourceTypeID]);

  // - define general GRID settings as well as create/update/delete/details functions to use
  gridConfig = {
    // graphql operation functions
    options: ["details", "duplicate", "delete", "edit"],
    deleteFunction: deleteCustomResourceEntry,
    createItemModalHeader: <div>{`Create a new Custom Resource Entry${isTemplate ? " Template" : ""}`}</div>,
    createResourceComponent: (
      <CustomResourceEntryForm customResourceTypeID={customResourceTypeID} organizationID={organizationID} />
    ),
    updateMutation: customResourceEntryUpdateMutation,

    // details component setup
    typename: "CustomResourceEntry",
    detailsComponent: <CustomResourceEntryDetails tableDisplay={true} />,
    config: {
      width: "95vw",
    },

    // field and routes settings
    fields,
    columnSize: 8,

    // persistence settings
    persistenceUUID,

    organizationID,
    duplicationSettings,

    ...gridConfig,
    ...props,
  };

  const [resetKey, setResetKey] = useState(1);
  queryConfig = {
    query: null,
    resetFunction: () => {
      if (typeof resetFunction === "function") {
        resetFunction();
      }
      setResetKey((resetKey) => resetKey + 1);
    },
    ...queryConfig,
  };

  const gridCard = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...roleConfig,
  });

  // - hydrates the CustomResourceEntry gridCard manually
  useCustomResourceEntryGridData({
    resetKey,
    organizationID,
    customFieldNames,
    customResourceTypeID,
    gridCard,
    query: listQuery,
    isTemplate,
  });

  return {
    ...gridCard,
    customFields,
    customFieldNames,
  };
};

const duplicationSettings = {
  enabled: true,
  description: "Duplicates a Custom Resource Entry, preserves custom field data",
  fields: ["customResourceTypeID", "customFieldData"],
  primaryField: "name",
};
