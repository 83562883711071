import { EventLogger } from "@utils/EventLogger/EventLogger";
import { cloneDeep } from "lodash";
import { fieldContexts } from "@enums/fieldContexts";
import { generateGraphql } from "@rivial-security/generategraphql";
import { generateListByQuery } from "./queries/generateListByQuery";
import { getResourceNestedFields } from "./queries/getResourceNestedFields";
import { getResourceQueryFields } from "./queries/getResourceQueryFields";

/**
 * Input into getResourceQueryData
 * @typedef {Object} GetResourceQueryDataParams
 * @param {string} fieldContext - the location where the query info is used (grid, details, form, etc.)
 * @param {string} typename - the name of the resource of which definition to get
 * @param {string[]} overrideQueryFields - custom fields to use instead of the default ones
 * @param {object} overrideQueryConfig - custom final query settings to use instead of the default ones (must contain indexName or query to take effect)
 */

/**
 * Function optimized to get the correct default query information for a resource, but still allow for overrides
 * @param {GetResourceQueryDataParams} params - the parameters to use to get the query data
 * @returns {{createMutation: *, onCreate: *, onDelete: *, updateMutation: *, queryFields: *, queryConfig: {}, invokeFunction: function(*, *): string, onUpdate: *, getQuery: *, deleteMutation: *, listQuery: *, nestedFields: {}}|null}
 */
export const getResourceQueryData = ({ fieldContext, typename, overrideQueryFields, overrideQueryConfig }) => {
  //Check arguments
  if (!fieldContext || !typename) {
    EventLogger("Invalid input into getResourceQueryData - ", {
      fieldContext,
      typename,
    });
    return null;
  }

  //Check if there are any query fields for the resource
  const queryFields = getResourceQueryFields({
    overrideQueryFields,
    typename,
    fieldContext,
  });

  if (!Array.isArray(queryFields) || queryFields.length === 0) {
    return null;
  }

  //Build out nested fields
  const nestedFields = getResourceNestedFields({
    typename,
    queryFields,
    fieldContext,
  });

  //Create the graphql
  const graphql = generateGraphql(typename, queryFields, nestedFields);
  let listByQuery;

  //Create the correct query config based on context and overrides
  let queryConfig = {};
  if (overrideQueryConfig?.indexName || overrideQueryConfig?.query !== undefined) {
    const { indexName, indexQueryField, indexQueryFieldTypename } = overrideQueryConfig || {};
    //NOTE: a null `query` is still valid and takes precedence, data is populated from outside the grid
    let { query } = overrideQueryConfig || {};

    if (query === null) {
      query = null;
    } else if (indexName && !query) {
      query = generateListByQuery({
        typename,
        indexName,
        indexQueryField,
        indexQueryFieldTypename,
        queryFields,
        nestedFields,
      });
      listByQuery = query;
    }

    queryConfig = { ...overrideQueryConfig, query };
  } else if (fieldContext === fieldContexts.GRID) {
    queryConfig = {
      query: graphql?.listQuery,
      limit: 1000,
    };
  } else if (fieldContext === fieldContexts.DETAILS) {
    queryConfig = {
      query: graphql?.getQuery,
    };
  }

  return cloneDeep({
    ...graphql,
    listByQuery,
    queryFields,
    nestedFields,
    queryConfig,
  });
};
