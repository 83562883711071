import { useEffect, useState } from "react";
import { gridVisibleSortedRowEntriesSelector } from "@mui/x-data-grid-pro";
import { isNonEmptyArray, isNullOrUndefined, removeObjectFromArray } from "@rivial-security/func-utils";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import ResetButton from "../../../../utils/GenericComponents/buttons/ResetButton";
import { useOrganizationContext } from "../../../../views/AdminPanel/Organizations/hooks/useOrganizationContext";
import { useDataGridCustomFields } from "./useDataGridCustomFields";
import { useQueryCustomFields } from "./useQueryCustomFields/useQueryCustomFields";
import { runDataGridQuery } from "../functions/runDataGridQuery/runDataGridQuery";
import { accessResourcesByRole } from "../functions/accessResourcesByRole";

/**
 * @description Handles data state handling for the grid.
 * If query information is passed in, handles all query functionality.
 * Returns handlers for updateById, removeItemById, and addItem
 *
 * @param {object} initialData - initial data to be displayed in the grid
 * @param {object[]} [externalData] - takes priority over 'initialData', does not get modified by grid operations directly
 * @param {function} [setExternalCustomFieldData] - setter for internal data for collected for custom fields to be added into externalData
 * @param {object} [externalCustomFieldData] - custom field data to init the data grid with
 * @param {object} apiRef - reference to the api object
 * @param {string} query - query string
 * @param {object} queryExternalApi - query an external api config object
 * @param {function} setData - setter data function
 * @param {function} setIsLoading - setter for loading state
 * @param {object} variables - variables to query by
 * @param {object} filters - apply filters by while query
 * @param {number} limit - specify how many items to return
 * @param {function} normalizeData - function to normalize data after the query
 * @param {function} normalizeRowData - function to normalize data by row after the query
 * @param {function} finalizeData - function to finalize data after the query
 * @param {function} queryCallback - function to return data
 * @param {string} organizationID - selected organization
 * @param {function} externalResetFunction - reset function passed externally
 * @param {function} [refreshCallback] - use to notify of refresh button hit and also to conditionally disable internal data refresh
 * @param {function} [updateCallback] - callback on item changed through updateItemById
 * @param {string} module - name of the module to query
 * @param {string} resource - name of the resource to query
 * @param {object[]} customFields - custom fields that are rendered dynamically after initial load
 * @param {object[]} customFieldsReadOnly - determines if custom fields should be forced read-only
 * @param {string[]} loadingFields - list of fields that may still be loading
 * @param {functions} setLoadingFields - function to set the state of loadingFields
 * @param {object} columnVisibilityModel - field name to visible or not (tru/false)
 */
export const useDataGridData = ({
  initialData = [],
  externalData,
  setExternalCustomFieldData,
  externalCustomFieldData,
  apiRef,
  query,
  queryExternalApi,
  setIsLoading,
  variables,
  filters,
  limit,
  normalizeData,
  normalizeRowData,
  finalizeData,
  queryCallback,
  organizationID,
  resetFunction: externalResetFunction,
  refreshCallback,
  updateCallback,
  resource,
  customFields,
  loadingFields,
  setLoadingFields,
  columnVisibilityModel,
}) => {
  const context = useOrganizationContext();
  const populatedByExternalData = isNullOrUndefined(query) && Array.isArray(externalData);
  const hasQuery = !isNullOrUndefined(query) || !isNullOrUndefined(queryExternalApi);
  const [data, setData] = useState(populatedByExternalData ? externalData : initialData);

  /**
   * When a list query can return items while still retrieving the rest of the data
   * this function can be used to append data to already existing items
   * @param {object[]} items - the data to append
   */
  const setIntermediateData = (items) => {
    //Do not show intermediate data (as query is loading) when data will be modified after the query
    if (finalizeData) return;

    //Filter out resources that shouldn't be seen
    const filteredArray = accessResourcesByRole({
      array: items,
      resource,
      role: context?.role,
    });
    setData((data) => data.concat(filteredArray));
  };

  /**
   * When the custom fields change can update their data here
   * @param {string} queryCustomFieldData - the data for custom fields that come from secondary queries
   */
  const updateCustomFieldData = ({ queryCustomFieldData }) => {
    if (!isNonEmptyArray(customFields)) {
      return;
    }

    if (populatedByExternalData) {
      setExternalCustomFieldData?.(queryCustomFieldData);
    } else {
      setData((data) => {
        return formatCustomFields({ data, queryCustomFieldData });
      });
    }
  };

  const runQuery = ({ initialMount }) => {
    return runDataGridQuery({
      initialMount,
      query,
      queryExternalApi,
      variables,
      filters,
      limit,
      normalizeData,
      normalizeRowData,
      setIntermediateData,
      finalizeData,
      queryCallback,
      organizationID,
      resetFunction: externalResetFunction,
      refreshCallback,
    });
  };

  const { queryDataRef: queryCustomFieldDataRef } = useQueryCustomFields({
    customFields,
    externalCustomFieldData,
    organizationID,
    updateCustomFieldData,
    loadingFields,
    setLoadingFields,
    columnVisibilityModel,
  });

  // Handle any handle additional fields either defined by user or requiring an extra query
  const { formatCustomFields } = useDataGridCustomFields({
    customFields,
  });

  const getData = async ({ initialMount = false } = { initialMount: false }) => {
    try {
      if (populatedByExternalData || !hasQuery) {
        if (!initialMount && externalResetFunction) {
          externalResetFunction();
        }
        return;
      }

      setIsLoading(true);
      setData([]);

      let newData = (await runQuery({ initialMount })) || [];
      newData = accessResourcesByRole({ array: newData, role: context?.role, resource });
      newData = formatCustomFields({ data: newData, queryCustomFieldData: queryCustomFieldDataRef.current });

      if (typeof finalizeData === "function") {
        newData = await finalizeData(newData);
      }

      setData(newData);
    } catch (e) {
      EventLogger("Error while getting data for data grid", e);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Initial get data
   */
  useEffect(() => {
    getData({ initialMount: true });
  }, []);

  /**
   * If provided initiate data callback on new data retrieval
   */
  useEffect(() => {
    queryCallback?.(data);
  }, [data]);

  /**
   * Adds a new item to state
   */
  const addItem = (item) => {
    if (populatedByExternalData) return;

    setData((data) => [...data, item]);
  };

  /**
   * Removes an item from state, by ID
   */
  const removeItemById = (item) => {
    if (populatedByExternalData) return;

    setData((data) => removeObjectFromArray(data, item, "id"));
  };

  /**
   * Updates an object in state, by ID
   */
  const updateItemById = (item) => {
    if (typeof updateCallback === "function") {
      updateCallback(item);
    }
    if (populatedByExternalData) return;

    //NOTE: Performs a state update of a single row which is faster than an update on the entire data
    const updateRows = apiRef?.current?.updateRows;
    if (typeof updateRows === "function") {
      updateRows([item]);
    }
  };

  /**
   * Updates the entire data state
   */
  const setDataState = (data) => {
    if (populatedByExternalData) return;

    setData(data);
  };

  return {
    data: populatedByExternalData ? externalData : data,
    setData: setDataState,
    addItem,
    removeItemById,
    updateItemById,
    getVisibleRows: () => gridVisibleSortedRowEntriesSelector(apiRef),
    resetFunction: getData,
    resetButton: <ResetButton resetFunction={getData} />,
  };
};
