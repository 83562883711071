import { isNonEmptyArray } from "@rivial-security/func-utils";

export interface CombineChildQueryDataParams<P extends { id: string }, C> {
  parents: P[];
  children: C[];
  childFieldInParent: string;
  parentIDFieldInChild: string;
}

export const combineChildQueryData = <P extends { id: string }, C>({
  parents,
  children,
  childFieldInParent,
  parentIDFieldInChild,
}: CombineChildQueryDataParams<P, C>): P[] => {
  if (!isNonEmptyArray(parents) || !isNonEmptyArray(children)) {
    return parents;
  }

  // group  all children by parentID
  const childrenByParentID: Record<string, C[]> = {};
  for (const child of children) {
    const parentID = child[parentIDFieldInChild] as string;
    if (!parentID) {
      continue;
    }

    if (!childrenByParentID[parentID]) {
      childrenByParentID[parentID] = [];
    }

    childrenByParentID[parentID]!.push(child);
  }

  // assign children to parents
  return parents.map((parent) => {
    const parentID = parent?.id;
    if (!parentID) {
      return parent;
    }

    const childrenForParent = childrenByParentID[parentID] || [];
    return {
      ...parent,
      [childFieldInParent]: {
        items: childrenForParent,
      },
    };
  });
};
