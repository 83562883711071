import { Button, Table } from "reactstrap";
import { checkArguments, enumToDropdownData, isNullOrUndefined } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";
import { useContext, useState } from "react";

import AddButton from "@utils/GenericComponents/buttons/AddButton";
import CustomDropdown from "@utils/GenericComponents/GenericEditFieldV3/components/CustomDropdown";
import { CustomFieldEditType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldEditType";
import { CustomFieldType } from "@views/Compliance/Controls/ControlSets/constants/CustomFieldType";
import { EventLogger } from "@utils/EventLogger/EventLogger";
import { FORM_INPUT_TYPES } from "@hooks/views/useForm/enums/FORM_INPUT_TYPES";
import MultiInputs from "../components/MultiInputs";
import NumberSettingsInputs from "../../../../CustomResources/CustomResourceFields/components/NumberSettingsInputs";
import StringListField from "@utils/CustomFields/StringListField";
import { UIContext } from "@utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import pluralize from "pluralize";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useForm } from "@hooks/views/useForm";
import { useModal } from "@hooks/views/useModal";
import { useMutation } from "@hooks/graphql/useMutation/useMutation";

//Displayed as a row entry when there are no custom fields
const emptyPlaceholder = ({ haveUpdatePermissions = false, customFieldName }) => (
  <>
    <br />
    <p
      title="This field has no data"
      style={{ fontStyle: "italic", color: "grey" }}
      data-testid={"empty-custom-fields-table-notification"}
    >
      {`No ${pluralize(customFieldName)} to display yet. ${
        haveUpdatePermissions ? ` To add a ${customFieldName} press the green plus button!` : ""
      }`}
    </p>
  </>
);

/**
 * Displays a table with custom fields and their type in a list pattern with the option to delete fields
 * @param {object} props CustomFieldsTableProps
 * @param {string} props.module=modules.COMPLIANCE  - platform module for role checking
 * @param {string} props.resource=resources.CONTROL_FRAMEWORK - platform resource for role checking
 * @param {boolean} props.disableRoleChecking=false - if TRUE will disable role checking
 * @param {boolean} props.enableBackendUpdate=true - set to TRUE if need to use mutations to send edits to the db
 * @param {boolean} props.disableRoleChecking - set to TRUE if no need to check for permission to add/remove fields (eg. in create form)
 * @param {function} props.updateItemById - allows to update the grid state of the item when details component is updated
 * @param {object[]} props.customFields - default values for the table
 * @param {string} props.updateMutation - if present the delete and edit operations will trigger a mutation operation
 * @param {boolean} props.showAddButtonInit - if TRUE shows alternative add button at the bottom of table (in use with details fields)
 * @param {boolean} props.forceTableVisibility - if defined will keep the table in the specified state and disable the toggle table visibility button
 * @param {string} props.nameFieldLabel - the label to use for the name field
 * @param {string} props.customFieldName - the display name of each custom field entry (eg. "question" for a "questionnaire")
 * @param {string} props.customFieldParentName - the display name of the parent of each custom field entry (eg. "questionnaire" or "control set")
 */
export const useCustomFieldsTable = ({
  typename = "ControlSet",
  module = modules.COMPLIANCE,
  resource = resources.CONTROL_FRAMEWORK,
  disableRoleChecking = false,
  enableBackendUpdate = true,
  updateItemById,
  customFields: originalCustomFields = [],
  item = {},
  showAddButtonInit = false,
  forceTableVisibility,
  nameFieldLabel = "Name",
  customFieldName = "custom field",
  customFieldParentName = "control set",
}) => {
  const { addToast } = useContext(UIContext);
  const [tableIsOpen, setTableIsOpen] = useState(
    !isNullOrUndefined(forceTableVisibility) ? forceTableVisibility : false,
  );
  const [customFields, setCustomFields] = useState(originalCustomFields || []);
  const [hideDropdownOptions, setHideDropdownOptions] = useState(true);
  const [hideMultipleSelectOptions, setHideMultipleSelectOptions] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [multipleSelectOptions, setMultipleSelectOptions] = useState([]);
  const [currentEditedField, setCurrentEditedField] = useState(null);
  const [originalFieldName, setOriginalFieldName] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
    field: "customFields",
  });

  /**
   * Convenience method returning index of the field
   * @param {string} fieldName - the name of the custom field to find
   */
  const findFieldByName = (fieldName) => {
    //the domain to search needs to be an array
    if (!customFields || !Array.isArray(customFields)) {
      return -1;
    }

    //the search term needs to be an string
    if (!fieldName || typeof fieldName !== "string") {
      return -1;
    }

    return customFields.findIndex((item) => item.name === fieldName);
  };

  const { updateMutation } = generateGraphql(typename, ["customFields"], {
    customFields:
      "{ name description type options { label value } multipleSelect { label value } numberSettings { min max step format } }",
  });

  const updateMutationHook = useMutation({
    mutation: updateMutation,
    disableRoleChecking: true,
  });

  /**
   * Allows to update the db version of the custom fields
   * @param {object} oldValue - the previous value of the field (before edits)
   * @param {object} newValue - the new value o the field with edits applied
   * @return {Promise<void>}
   */
  const updateBackend = async ({ oldValue, newValue }) => {
    //Check if need to update the db entry
    if (!enableBackendUpdate) {
      return;
    }

    //Check for all needed mutation parameters to exist
    if (!item?.id || item.id === "") {
      setCustomFields([...oldValue]);
      return;
    }

    // Check if mutation is setup
    if (!updateMutation || !updateMutationHook) {
      setCustomFields([...oldValue]);
      return;
    }

    // Check if the input is of correct type
    if (!oldValue || !newValue || !Array.isArray(oldValue) || !Array.isArray(newValue)) {
      setCustomFields([...oldValue]);
      return;
    }

    if (!checkPermissionsHook?.resource?.update) {
      setCustomFields([...oldValue]);
      addToast({
        header: `You do not have proper permissions to update ${pluralize(customFieldName)}.`,
        icon: "danger",
      });
      return;
    }

    try {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        const mutationInput = {
          id: item.id,
          customFields: newValue,
        };
        updateMutationHook.editItem(mutationInput).then((data) => {
          updateItemById?.(data);
        });
      } else {
        EventLogger("The value for `customFields` hasn't changed.");
      }
    } catch (e) {
      setCustomFields([...oldValue]);
      EventLogger("Unable to modify 'customFields' in the backend", e);
      addToast({
        header: `Something went wrong when editing ${pluralize(customFieldName)}. Try again later.`,
        icon: "danger",
      });
    }
  };

  /**
   * Removes an added custom field by name
   * @param {string} removedFieldName - name of the field to remove
   */
  const onRemoveField = async (removedFieldName) => {
    const fieldIndex = findFieldByName(removedFieldName);

    if (fieldIndex !== -1) {
      const newCustomFields = [...customFields];
      newCustomFields.splice(fieldIndex, 1);
      setCustomFields(newCustomFields);
      await updateBackend({
        oldValue: customFields,
        newValue: newCustomFields,
        editType: CustomFieldEditType.REMOVE_FIELD,
      });
    }
  };

  const onEditField = async ({ editedField, editType }) => {
    const foundIndex = findFieldByName(originalFieldName);

    if (foundIndex !== -1) {
      const newCustomFields = customFields.map((field, index) => (index === foundIndex ? editedField : field));
      setCustomFields(newCustomFields);
      await updateBackend({
        oldValue: customFields,
        newValue: newCustomFields,
        editType,
      });
    } else {
      EventLogger(`Could not find field with original name: ${originalFieldName}`);
    }
  };

  /**
   * Handles the submission of the edit form
   */
  const handleEditFormSubmit = () => {
    if (currentEditedField?.type !== CustomFieldType.ENUM) {
      delete currentEditedField?.options;
    }

    if (currentEditedField?.type !== CustomFieldType.MULTIPLE_SELECT) {
      delete currentEditedField?.multipleSelect;
    }

    onEditField({
      editedField: currentEditedField,
    });
    setCustomFields((prevFields) =>
      prevFields.map((field) => (field.name === originalFieldName ? currentEditedField : field)),
    );
    addToast({
      header: `Successfully edited ${customFieldName}: ${currentEditedField?.name}`,
      icon: "success",
    });
    setCurrentEditedField(null);
    setIsEditing(false);
    createCustomFieldModal.setModalIsOpen(false);
  };

  /**
   * Adds a non existing field to the list (notifies user if the field already exists)
   * @param {object} newField - object with name and type of field properties
   */
  const onAddField = async (newField) => {
    try {
      checkArguments(newField, {
        name: { type: "string" },
        type: { type: "string" },
      });
    } catch (e) {
      addToast({
        header: `Something went wrong when adding a new ${customFieldName}. Try again later.`,
        icon: "danger",
      });
      EventLogger(`Unable to add a ${customFieldName}`, e);
      return;
    }

    const foundIndex = findFieldByName(newField?.name);
    if (foundIndex !== -1) {
      addToast({
        header: `Cannot add a ${customFieldName} with the same name as an existing ${customFieldName}.`,
        icon: "danger",
      });
      return;
    }

    if (newField?.type === CustomFieldType.ENUM && newField?.options?.length > 0) {
      newField.options = newField?.options.map((option) => {
        return { label: option?.label, value: option?.value };
      });
    } else if (newField?.type === CustomFieldType.ENUM && !newField?.options?.length) {
      newField.options = dropdownOptions.map((option) => {
        return { label: option?.label, value: option?.value };
      });
    } else if (newField.hasOwnProperty("options")) {
      delete newField?.options;
    }

    if (newField?.type === CustomFieldType.MULTIPLE_SELECT && newField?.multipleSelect?.length > 0) {
      newField.multipleSelect = newField?.multipleSelect.map((option) => {
        return { label: option?.label, value: option?.value };
      });
    } else if (newField?.type === CustomFieldType.MULTIPLE_SELECT && !newField?.multipleSelect?.length) {
      newField.multipleSelect = multipleSelectOptions.map((option) => {
        return { label: option?.label, value: option?.value };
      });
    } else if (newField.hasOwnProperty("multipleSelect")) {
      delete newField?.multipleSelect;
    }

    if (!newField?.numberSettings) {
      newField.numberSettings = {
        min: null,
        max: null,
        step: null,
        format: null,
      };
    }

    //Add the field to the custom field list
    const newCustomFields = [...customFields, newField];
    setCustomFields(newCustomFields);
    await updateBackend({
      oldValue: customFields,
      newValue: newCustomFields,
    });
  };

  //Placed here to be able to use it in the form below (updated after the modal is created)
  let closeCreateCustomFieldModal = () => EventLogger("Should not get called!");
  const closeCreateCustomFieldModalCallback = () => {
    closeCreateCustomFieldModal();
  };

  const createCustomFieldForm = useForm({
    disableRoleChecking: true,
    submitFunction: onAddField,
    fieldConfig: {
      name: {
        inputType: "text",
        label: nameFieldLabel || "Name",
        defaultValue: null,
        required: true,
      },
      type: {
        label: "Type",
        inputType: "dropdown",
        defaultValue: CustomFieldType.STRING,
        dropdownConfig: {
          data: enumToDropdownData({
            ENUM: CustomFieldType,
            overrides: {
              [CustomFieldType.ENUM]: "Dropdown",
            },
          }),
        },
        required: true,
      },
      options: {
        label: "Add Dropdown Options",
        inputType: "custom",
        customConfig: {
          component: <MultiInputs onChange={setDropdownOptions} />,
        },
        defaultValue: null,
        required: false,
        isHidden: hideDropdownOptions,
      },
      multipleSelect: {
        label: "Add Multiple Select Options",
        inputType: "custom",
        customConfig: {
          component: <MultiInputs onChange={setMultipleSelectOptions} />,
        },
        defaultValue: null,
        required: false,
        isHidden: hideMultipleSelectOptions,
      },
      numberSettings: {
        label: "Edit Number Options",
        inputType: "custom",
        customConfig: {
          component: <NumberSettingsInputs onChange={setCurrentEditedField} item={currentEditedField} />,
        },
        isHidden: ({ input }) => {
          return input?.type !== CustomFieldType.NUMBER;
        },
      },
      description: {
        inputType: FORM_INPUT_TYPES.RICH_TEXT_EDITOR,
        label: "Description",
        defaultValue: null,
        required: false,
        richTextEditorConfig: {
          removeFromInput: true,
          enableAllFeatures: true,
        },
      },
    },

    callback: closeCreateCustomFieldModalCallback,
    onChange: (e) => {
      if (e.type === CustomFieldType.ENUM) {
        setHideDropdownOptions(false);
      } else {
        setHideDropdownOptions(true);
        setDropdownOptions([]);
      }

      if (e.type === CustomFieldType.MULTIPLE_SELECT) {
        setHideMultipleSelectOptions(false);
      } else {
        setHideMultipleSelectOptions(true);
        setMultipleSelectOptions([]);
      }
    },
  });

  const editCustomFieldForm = useForm({
    disableRoleChecking: true,
    submitFunction: handleEditFormSubmit,
    fieldConfig: {
      name: {
        inputType: "text",
        label: nameFieldLabel || "Name",
        defaultValue: currentEditedField?.name,
        required: true,
        disabled: false,
      },
      type: {
        label: "Type",
        inputType: "dropdown",
        dropdownConfig: {
          data: enumToDropdownData({
            ENUM: CustomFieldType,
            overrides: {
              [CustomFieldType.ENUM]: "Dropdown",
            },
          }),
        },
        required: true,
        disabled: false,
      },
      options: {
        label: "Edit Dropdown Options",
        inputType: "custom",
        customConfig: {
          component: <MultiInputs onChange={setCurrentEditedField} initialValues={currentEditedField} />,
        },
        isHidden: ({ input }) => {
          return input?.type !== CustomFieldType.ENUM;
        },
        defaultValue: null,
        required: false,
        disabled: false,
      },
      multipleSelect: {
        label: "Edit Multiple Select Options",
        inputType: "custom",
        customConfig: {
          component: <MultiInputs onChange={setCurrentEditedField} initialValues={currentEditedField} />,
        },
        isHidden: ({ input }) => {
          return input?.type !== CustomFieldType.MULTIPLE_SELECT;
        },
        defaultValue: null,
        required: false,
        disabled: false,
      },
      numberSettings: {
        label: "Edit Number Options",
        inputType: "custom",
        customConfig: {
          component: <NumberSettingsInputs onChangeCallback={setCurrentEditedField} item={currentEditedField} />,
        },
        isHidden: ({ input }) => {
          return input?.type !== CustomFieldType.NUMBER;
        },
      },
      description: {
        inputType: FORM_INPUT_TYPES.RICH_TEXT_EDITOR,
        label: "Description",
        defaultValue: null,
        required: false,
      },
      richTextEditorConfig: {
        removeFromInput: true,
        enableAllFeatures: true,
      },
    },

    callback: closeCreateCustomFieldModalCallback,
    onChange: (input) => {
      if (!Array.isArray(input?.options)) {
        input.options = null;
      }

      if (!Array.isArray(input?.multipleSelect)) {
        input.multipleSelect = null;
      }

      const updatedField = { ...currentEditedField, ...input };
      setCurrentEditedField(updatedField);
    },
  });

  const createCustomFieldModal = useModal(
    isEditing ? `Edit a ${customFieldName}` : `Add a ${customFieldName}`,
    isEditing ? editCustomFieldForm.display : createCustomFieldForm.display,
    <AddButton
      data-testid="create_custom_field_modal_add_button"
      color="ghost-success"
      style={{ marginLeft: "1em" }}
      title={`Add a ${customFieldName} to this ${customFieldParentName}`}
    />,
    {
      width: "60vw",
      onClosed: () => {
        setIsEditing(false);
        setCurrentEditedField(null);
      },
    },
  );

  //Updates the close modal callback so the field creation form can use it
  closeCreateCustomFieldModal = () => {
    createCustomFieldModal.setModalIsOpen(false);
  };

  /**
   * A generator for custom field table body
   */
  const getCustomFieldRows = () => {
    if (!customFields || !Array.isArray(customFields) || customFields.length === 0) {
      return emptyPlaceholder({
        haveUpdatePermissions: checkPermissionsHook?.resource?.update,
        customFieldName,
      });
    }

    return (
      <tbody>
        {customFields.map((field, index) => {
          return (
            <tr key={`customFieldRow${index}`}>
              <td data-testid={`customFieldName${index}`}>{field?.name}</td>
              <td>
                {checkPermissionsHook?.resource?.update ? (
                  <CustomDropdown
                    field={`customFieldType${index}`}
                    value={field.type}
                    setValue={(newType) => {
                      onEditField({
                        editedFieldName: field.name,
                        editedField: { ...field, type: newType },
                        editType: CustomFieldEditType.CHANGE_TYPE,
                      });
                    }}
                    isEnabled={false}
                    title={`Click the edit button to change the type of this custom field`}
                    label={""}
                    inputConfig={{
                      data: enumToDropdownData({
                        ENUM: CustomFieldType,
                        overrides: {
                          [CustomFieldType.ENUM]: "Dropdown",
                        },
                      }),
                    }}
                  />
                ) : (
                  <p>{field?.type}</p>
                )}
              </td>
              {checkPermissionsHook?.resource?.update && (
                <td>
                  <span>
                    <Button
                      data-testid={`button-delete-customField${index}`}
                      id={"button-delete-for-customField"}
                      color="ghost-danger"
                      className={"btn-pill"}
                      onClick={() => onRemoveField(field?.name)}
                      size="sm"
                      title={`Delete the ${customFieldName} - ${field?.name}`}
                    >
                      <i className="icon-trash" />
                    </Button>
                    <Button
                      data-testid={`button-edit-customField${index}`}
                      id={"button-edit-for-customField"}
                      color="ghost-warning"
                      className={"btn-pill"}
                      onClick={() => {
                        editCustomFieldForm.mergeInput({ ...field });
                        setCurrentEditedField(field);
                        setIsEditing(true);
                        setOriginalFieldName(field?.name);
                        createCustomFieldModal.setModalIsOpen(true);
                      }}
                      size="sm"
                      title={`Edit the ${customFieldName} - ${field?.name}`}
                    >
                      <i className="icon-pencil" />
                    </Button>
                  </span>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const showAddButton = checkPermissionsHook?.resource?.update && showAddButtonInit && tableIsOpen;

  const display = (
    <>
      {tableIsOpen && (
        <Table>
          <thead>
            <tr>
              <th>{nameFieldLabel || "Name"}</th>
              <th>Type</th>
              {checkPermissionsHook?.resource?.update && <th>Options</th>}
            </tr>
          </thead>
          {getCustomFieldRows()}
        </Table>
      )}
      {!tableIsOpen && <StringListField item={item} fieldName={"customFields"} deepProperty={"name"} />}
      {!tableIsOpen && <div style={{ height: ".5em" }} />}
      {isNullOrUndefined(forceTableVisibility) && (
        <Button
          color={"primary"}
          size={"sm"}
          onClick={() => {
            setTableIsOpen((tableIsOpen) => !tableIsOpen);
          }}
        >
          <i className={tableIsOpen ? "fa fa-eye-slash" : "fa fa-eye"} style={{ marginRight: ".5em" }} />
          {tableIsOpen ? "Hide the Table" : "Edit in a Table"}
        </Button>
      )}{" "}
      {showAddButton && (
        <Button
          data-testid={"create_custom_field_addon_add_button"}
          color={"success"}
          size={"sm"}
          title={`Creates a new ${customFieldName} for the selected ${customFieldParentName}`}
          onClick={() => {
            setIsEditing(false);
            setCurrentEditedField(null);
            createCustomFieldModal.setModalIsOpen(true);
          }}
        >
          <i className={"icon-plus"} style={{ marginRight: ".5em" }} />
          {`Add a ${customFieldName}`}
        </Button>
      )}
      {createCustomFieldModal.modal}
    </>
  );

  return {
    display,
    createButton: createCustomFieldModal.modalButton,
    customFields,
    setCustomFields,
  };
};
