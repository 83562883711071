export const createDocument_for_upload_document = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
      name
      owner
      visibility
      createdAt
      file {
        bucket
        region
        key
      }
      ownerGroup
    }
  }
`;

export const updateAction_Upload_Document = `mutation UpdateAction($input: UpdateActionInput!) {
  updateAction(input: $input) {
    id
    status
  }
}`;

export const updateDocument_Upload_Document = /* GraphQL */ `
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
      encryptionKey
    }
  }
`;

export const getAction_ActionDetails = `query GetAction($id: ID!) {
  getAction(id: $id) {
    dateCreated
    description
    executionARN
    id
    name
    ownerGroup
    status
    type
    notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    documents(limit: 50) {
      items {
        createdAt
        id
        name
        encrypted
        encryptionKey
        visibility
        ownerGroup
        owner
        file {
          bucket
          key
          region
        }
        evidence {
          id
          name
        }
      }
    }
    evidence {
      evidencePointOfContactId
      pointOfContact {
        id
        firstName
        lastName
        title
        email
      }
      frequency
      id
      name
      nextDate
      recentUploadDate
      task
      reminder
      ownerGroup
      controlLinks(limit: 100) {
        items {
          id
          ownerGroup
          control {
            controlControlSetId
            controlSet {
              id
              name
              ownerGroup
            }
            domain
            id
            isCompliant
            maturityLevel
            name
            ownerGroup
            statementNumber
            validation
            evidenceLinks(limit: 50) {
              items {
                id
                ownerGroup
                evidence {
                  evidencePointOfContactId
                  frequency
                  id
                  name
                  nextDate
                  ownerGroup
                  recentUploadDate
                  reminder
                  task
                  pointOfContact {
                    email
                    firstName
                    id
                    lastName
                    ownerGroup
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const listActions_For_Compliance_Report = `query ListActions(
  $filter: ModelActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      ownerGroup
      executionARN
      type
      priority
      module
    }
    nextToken
  }
}
`;

export const listActions_actionList = `query ListActions(
  $filter: ModelActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      dateCreated
      dateCompleted
      description
      status
      ownerGroup
      executionARN
      type
      priority
      module
      documents {
        items {
          id
          name
          encrypted
          encryptionKey
        }
      }
      evidence {
        id
        name
        pointOfContact {
          id
          email
          firstName
          lastName
        }
      }
    }
    nextToken
  }
}
`;
