import React from "react";

const AuthenticatedEvidenceLandingPage = React.lazy(
  () =>
    import(
      "../../views/Compliance/Evidence/components/EvidenceLandingPage/components/AuthenticatedEvidenceLandingPage"
    ),
);
const TaskLandingPage = React.lazy(
  () => import("../../views/Program/Tasks/components/TaskLandingPage/TaskLandingPage"),
);

const DocumentsList = React.lazy(() => import("../../hooks/views/useDocumentGrid/components/DocumentDataGrid"));
const DocumentDetails = React.lazy(() => import("../../hooks/views/useDocumentGrid/components/DocumentDetails"));

// Main Routes
export const mainRoutes = [
  {
    path: "/continuous_compliance/evidence_response/:id",
    exact: true,
    name: "Evidence Landing Page",
    component: AuthenticatedEvidenceLandingPage,
  },
  {
    path: "/landing_page/task/:id",
    exact: true,
    name: "Task Landing Page",
    component: TaskLandingPage,
  },
  {
    path: "/documents/document/",
    exact: true,
    name: "Documents",
    component: DocumentsList,
  },
  {
    path: "/documents/document/:id",
    exact: true,
    name: "Documents Details",
    component: DocumentDetails,
  },
];
