import { deepDelete } from "../../../../utils/Functions/deepDelete";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Deletes a Vulnerability resource in the DB with all linked resources.
 * @param {object} item - Vulnerability to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteObservations - if true, will delete all Observations associated with this Vulnerability
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<void>}
 */
export const deleteVulnerability = async (item, { deleteObservations = true }, onJobStarted) => {
  if (!item?.id) {
    EventLogger("[deleteVulnerability.js] Missing Vulnerability ID");
    return;
  }

  /**
   * Delete Vulnerability in Fargate
   */
  return await deepDelete({
    typename: "Vulnerability",
    item,
    config: {
      deleteObservations,
    },
    onJobStarted,
  });
};
