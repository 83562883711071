import React, { useContext, useEffect, useState } from "react";
import { Input, Toast, ToastBody, ToastHeader } from "reactstrap";
import Button from "@mui/material/Button";
import { UIContext } from "../../../Context/UIContext";
import { OrganizationContext } from "../../../Context/OrganizationContext";
import { setupMFA } from "../../../../views/Settings/SettingPages/LoginOptions/functions/setupMFA";
import QRCode from "qrcode.react";
import { setPreferredMFA } from "../../../../views/Settings/SettingPages/LoginOptions/functions/setPreferredMFA";
import { EventLogger } from "../../../EventLogger/EventLogger";
import { updateUserPreferredMFA } from "../../../Functions/MFA/updateUserPreferredMFA";
import { useHistory } from "react-router-dom";

/**
 * @description Setup TOTP MFA for a user
 * @param {object} user - The user to set up SMS MFA for.
 * @param {function} changeState - The function to change the sign in state.
 * @returns {JSX.Element}
 * @constructor
 */
const MFASetupTOTP = ({ user, changeState }) => {
  const [challengeAnswer, setChallengeAnswer] = useState("");
  const [qrCode, setQRCode] = useState("");
  const [qrCodePure, setQrCodePure] = useState("");

  const context = useContext(OrganizationContext);
  const { addToast } = useContext(UIContext);
  const history = useHistory();

  useEffect(() => {
    setupMFA({
      user,
      setQRCode,
      setQrCodePure,
      context,
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {qrCode && (
        <div style={{ textAlign: "center" }}>
          <Toast>
            <ToastHeader>Setup Time-based One-time Password</ToastHeader>
            <ToastBody>
              <p>1. On your mobile device, open a 2-step authenticator app such as Google Authenticator.</p>
              <p>
                2. Use your device’s camera to scan the QR code that appears on the page or enter the code manually.
              </p>
            </ToastBody>
          </Toast>
          <QRCode value={qrCode} />
          <br />
          <Input type="textarea" disabled={true} value={qrCodePure} />
          <br />
          <Input placeholder="Code from Authenticator App" onChange={(e) => setChallengeAnswer(e.target.value)} />
          <br />
          <Button
            variant="contained"
            disabled={!challengeAnswer}
            onClick={async () => {
              try {
                setPreferredMFA({
                  authType: "TOTP",
                  user,
                  challengeAnswer,
                  addToast,
                });

                await updateUserPreferredMFA({ preferredMFA: "TOTP", user });
                changeState("signedIn", user);
              } catch (e) {
                EventLogger(`Failed to setup TOTP MFA: ${e}`);
              }
            }}
          >
            Submit Code
          </Button>
        </div>
      )}
    </div>
  );
};

export default MFASetupTOTP;
