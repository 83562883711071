export const nestedFieldsMeetingDetails = {
  agendaItems: ` (limit: 100) {
        items {
          id
          content
          ownerGroup
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
          createdAt
          updatedAt
        }
        nextToken
      }`,
  agenda: `{
    id
    content
    completed
    notes {
      id
      type
      ownerGroup
      author
      timeStamp
      content
      tag
      observationID
    }
  }`,
  documents: ` (limit: 100) {
        items {
          id
          name
          owner
          visibility
          createdAt
          avStatus
          lastAVCheck
          file {
            bucket
            region
            key
          }
          accessControl {
            password
            passwordOwnerEmail
            pointOfContacts
            roles
          }
          ownerGroup
          meetingID
          updatedAt
        }
        nextToken
      }`,
  accessControl: `{
        password
        passwordOwnerEmail
        roles
        pointOfContacts
      }`,
  notes: `{
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }`,
  pointOfContacts: `(limit: 1000) {
    items {
      pointOfContact {
        email
      }
    }
  }`,
  tags: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
};

export const listMeetings_MeetingsList = `query ListMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      startTime
      endTime
      status
      isAllDay
      pointOfContacts {
        items {
          id
          pointOfContact {
            id
            firstName
            lastName
            title
            email
            ownerGroup
          }
          ownerGroup
        }
        nextToken
      }
      organizer
      location
      duration
      documents {
        items {
          id
          name
          owner
          visibility
          createdAt
          file {
            bucket
            region
            key
          }
          ownerGroup
          meeting {
            id
            name
            description
            meetingDate
            meetingTime
            ownerGroup
          }
          meetingDocumentMeetingId
        }
        nextToken
      }
      linkedResources
      ownerGroup
    }
    nextToken
  }
}
`;
export const getMeeting_For_Meeting_Grid = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      name
      description
      status
      awsChimeMeeting
      pointOfContacts {
        items {
          id
          ownerGroup
          createdAt
          updatedAt
          pointOfContact {
            id
            firstName
            lastName
            title
            email
            phone
            ownerGroup
          }
        }
        nextToken
      }
      organizer
      location
      isAllDay
      startTime
      endTime
      agendaItems {
        items {
          id
          content
          ownerGroup
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;

export const getMeeting_For_Meeting_Details = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      name
      description
      status
      awsChimeMeeting
      pointOfContacts {
        items {
          id
          ownerGroup
          createdAt
          updatedAt
          pointOfContact {
            id
            firstName
            lastName
            title
            email
            phone
            ownerGroup
          }
        }
        nextToken
      }
      organizer
      location
      isAllDay
      startTime
      endTime
      agendaItems {
        items {
          id
          content
          ownerGroup
          notes {
            id
            type
            ownerGroup
            author
            timeStamp
            content
            tag
            observationID
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          owner
          visibility
          createdAt
          file {
            bucket
            region
            key
          }
          ownerGroup
          meetingDocumentMeetingId
          updatedAt
        }
        nextToken
      }
      linkedResources
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
      ownerGroup
      createdAt
      updatedAt
    }
  }
`;

export const updateMeetingNotes = /* GraphQL */ `
  mutation UpdateMeeting($input: UpdateMeetingInput!) {
    updateMeeting(input: $input) {
      id
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;

export const updateAgendaItemMeeting = /* GraphQL */ `
  mutation UpdateAgendaItem($input: UpdateAgendaItemInput!) {
    updateAgendaItem(input: $input) {
      id
      content
      notes {
        id
        type
        ownerGroup
        author
        timeStamp
        content
        tag
        observationID
      }
    }
  }
`;
