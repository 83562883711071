export const DeveloperModuleLinks = {
  name: "Developer",
  url: "/developer/",
  icon: "simple-line-icons:notebook",
  children: [
    {
      name: "Dev Tools",
      url: "/dev_tools",
      icon: "simple-line-icons:rocket",
    },
  ],
};
