import { useCsvImporter } from "../../../../../hooks/views/useCsvImporter/hooks/useCsvImporter";
import { useKeyRiskIndicatorsDataGrid } from "./useKeyRiskIndicatorsDataGrid";
import { IMPORTER_FIELD_FORMATS } from "../../../../../hooks/views/useCsvImporter/enums/IMPORTER_FIELD_FORMATS";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useModal } from "../../../../../hooks/views/useModal";
import React from "react";
import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import ImportButton from "../../../../../utils/GenericComponents/ImportButton";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";

/**
 * CSV importer for Key Risk Indicators
 * @param organizationID
 * @returns {{display: JSX.Element}}
 */
export const useKeyRiskIndicatorImporter = ({ organizationID }) => {
  const RISK_IMPORTER_FIELDS = () => [
    {
      field: "name",
      name: "Name",
      keywords: ["risk", "kri"],
      required: true,
    },
    {
      field: "description",
      name: "Description",
      keywords: ["summary"],
    },
    {
      field: "annualRateOfOccurrence",
      name: "Annual Rate of Occurrence",
      format: IMPORTER_FIELD_FORMATS.PERCENT,
      keywords: ["aro", "rate", "occurrence", "probability"],
      defaultValue: 0.0,
    },

    /**
     * this will get automatically generated using {risk.name + " Controls"}
     * but may be overridden by the user
     * performs a custom preprocess function that creates the control category then passes the ID to the KRI mutation
     */
    {
      field: "controlCategoryName",
      name: "Control Category Name",
      format: "text",
      keywords: ["category", "control category"],
      removeFromMutation: true,
    },
  ];

  const csvImporter = useCsvImporter({
    organizationID,
    typename: "Risk",
    fields: RISK_IMPORTER_FIELDS(),
    isAsync: false,
    resultGrid: useKeyRiskIndicatorsDataGrid,
    preProcessRow: async (input) => {
      if (!isNullOrUndefined(input)) {
        // First create the Risk Control Category, then use the ID to attach to the KRI
        const controlCategoryName = input.controlCategoryName || `${input.name} Controls`;
        const controlCategory = await createRiskControlCategory({
          name: controlCategoryName,
          organizationID,
        });

        return {
          ...input,
          controlCategoryID: controlCategory?.id,
        };
      } else {
        EventLogger("Error: could not pre-process row because input was null or undefined");
      }
    },
  });

  const modal = useModal("Import Key Risk Indicators", csvImporter.display, <ImportButton />, { width: "90vw" });

  return {
    ...csvImporter,
    ...modal,
  };
};

/**
 * Helper function for creating a Risk Control Category while creating the corresponding KRI
 * @param {string} name - the name of the Risk Control Category
 * @param {string} organizationID - the ID of the organization to create the Risk Control Category in
 * @returns {Promise<*>}
 */
const createRiskControlCategory = async ({ name, organizationID }) => {
  const { createMutation } = generateGraphql("RiskControlCategory", ["name"]);

  if (!isNullOrUndefined(name) && organizationID) {
    return await ItemMutation(createMutation, {
      name,
      ownerGroup: organizationID,
    });
  }
};
