import { Chip } from "@mui/material";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { TaskStatus } from "../../../constants/taskStatus";
import { useFormattedDueDate } from "../hooks/useFormattedDueDate";

/**
 * @description A chip component that displays the due date of a task
 * @param {object} task - the task to display the due date for
 * @param {boolean} isSidebarView - whether the task is being displayed in the sidebar or not
 * @return {JSX.Element|null}
 * @constructor
 */
const TaskDueDateChip = ({ task, isSidebarView }) => {
  const { formattedDate, isPastDue } = useFormattedDueDate({
    dueDate: task?.dueDate,
  });
  if (isNullOrUndefined(task)) {
    return null;
  }
  return (
    <Chip
      label={formattedDate}
      variant="outlined"
      style={{
        marginTop: !isSidebarView ? "0" : "5px",
        border: !isSidebarView ? "none" : null,
      }}
      color={task?.status === TaskStatus.PAST_DUE || isPastDue ? "error" : "info"}
    />
  );
};

export default TaskDueDateChip;
