import { MonteCarloCalculationWithQueryLambda } from "../../../../Systems/functions/MonteCarloCalculationWithQueryLambda";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { getRiskControlAssociatedSystems } from "./getRiskControlAssociatedSystems";

export const calculateRecommendationMonteCarlo = async ({
  setIsLoading,
  selectedRiskControl,
  changeInImplementation,
  system,
  organizationID,
  changeInCost,
  setIsOutdated,
  setOverallResidualChange,
  setCalculationResults,
  setSystemChanges,
}) => {
  EventLogger("Calculating Monte Carlo for a Risk Recommendation..");
  setIsLoading(true);

  let overallResidual = 0;

  const systemChanges = [];

  const riskControl = { ...selectedRiskControl };

  const systems = await getRiskControlAssociatedSystems(riskControl, system);

  const monteCarloSystemResults = await MonteCarloCalculationWithQueryLambda({
    systems,
    organizationID,
    recommendation: {
      costChange: parseFloat(changeInCost),
      implementationChange: parseFloat(changeInImplementation / 100),
      riskControl: { ...riskControl },
    },
    calculationContext: "RiskRecommendation",
  });

  const results = [];

  if (Array.isArray(monteCarloSystemResults)) {
    results.push(...monteCarloSystemResults);
  } else {
    results.push(monteCarloSystemResults);
  }

  for (const result of results) {
    const residualChange =
      result?.systemRiskValues?.recommendationResidualRisk - result?.systemRiskValues?.residualRisk;
    systemChanges.push({
      id: result.systemID,
      name: result.systemStats.name,
      residualChange,
      systemRiskValues: result?.systemRiskValues,
    });

    overallResidual += residualChange;
  }

  setIsOutdated(false);

  setSystemChanges(systemChanges);
  setOverallResidualChange(overallResidual);
  setCalculationResults(results);
  setIsLoading(false);
};
