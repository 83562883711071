import React, { useState } from "react";
import Tag from "../components/Tag";
import useListQuery from "../../../hooks/graphql/useListQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCreateTag } from "./useCreateTag";
import { Collapse } from "reactstrap";
import Loader from "../../LoadingComponents/Loader";

/**
 * @description Displays a list of Tags for the organization and allows the user to select tags
 * @param {string} [elementId="tagList"] - applied as the id prop to top level container
 * @param {string} heading - if provided will show this text above the list of tags
 * @param {string} organizationID - the database identifier for an organization
 * @param {function} onSelectedTagCallback - when tag selection changes this function will be called with an array of selected tags
 * @param {object} selectedTagsRef - if provided will use the 'current' property as the initial list of the selected tags (takes precedence over initSelectedTags)
 * @param {object[]} initSelectedTags - if provided will use as initial selection of tags (alternative to the selectedTagsRef)
 * @param {boolean} [enableDelete=false] - if TRUE will show an X on each tag allowing users to delete a tag
 * @param {boolean} [enableQuery=true] - if this is false, tags will not be queried initially
 * @param {boolean} [showTagsInitially=false] - TRUE if tags should not be collapsed at the beginning
 * @param {boolean} [showCreateTagsOption=true] - TRUE if need to show the create button to create tags
 * @param {boolean} [showHideTagsOption=true] - TRUE if need to show the collapse tags button (do not set this to true if showTagsInitially is false or not provided)
 * @param {function} [showHeader] - A function that determines whether to show the header based on the selected tags
 * @return {{enableQuery: boolean, selectTag: selectTag, display: *, selectedTags: unknown, deselectTag: deselectTag, setEnableQuery: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export const useTags = ({
  elementId = "tagList",
  heading,
  organizationID,
  onSelectedTagCallback,
  selectedTagsRef,
  initSelectedTags,
  enableDelete = false,
  enableQuery = true,
  showTagsInitially = false,
  showCreateTagsOption = true,
  showHideTagsOption = true,
  showHeader = (selectedTags) => true,
} = {}) => {
  const [showTags, setShowTags] = useState(showTagsInitially);
  const [selectedTags, setSelectedTags] = useState(selectedTagsRef?.current || initSelectedTags || []);

  const { listQuery: listTags } = generateGraphql("Tag", ["name", "description", "fontColor", "backgroundColor"]);

  const tagsList = useListQuery({
    query: listTags,
    organizationID,
    disableRoleChecking: true,
    enableQuery,
  });

  const selectTag = (tag) => {
    let newSelectedTags;
    if (Array.isArray(selectedTags) && selectedTags.length > 0) {
      newSelectedTags = [...selectedTags, tag];
    } else {
      newSelectedTags = [tag];
    }

    onSelectedTagCallback && onSelectedTagCallback(newSelectedTags);
    setSelectedTags(newSelectedTags);
  };

  const deselectTag = (tag) => {
    const index = selectedTags.findIndex((item) => item.id === tag.id);
    if (index !== -1) {
      const temp = [...selectedTags];
      temp.splice(index, 1);
      onSelectedTagCallback && onSelectedTagCallback(temp);
      setSelectedTags(temp);
    }
  };

  const createTagCallback = (tag) => {
    tagsList.setList([...tagsList.list, tag]);
  };

  const createTag = useCreateTag({
    organizationID,
    callback: createTagCallback,
  });

  const isTagSelected = (tag) => {
    return selectedTags.findIndex((item) => tag?.id && item?.id && tag.id === item.id) !== -1;
  };

  const display = (
    <div id={elementId}>
      <Collapse isOpen={showTags}>
        {heading && showHeader(selectedTags) === true && <p>{heading}</p>}
        {showTags && (
          <div>
            {tagsList?.isLoading === true ? (
              <div>
                Loading Tags... <Loader />
              </div>
            ) : tagsList?.list?.length > 0 ? (
              tagsList.list.map((tag) => (
                <Tag
                  isSelected={isTagSelected(tag)}
                  tag={tag}
                  selectTag={selectTag}
                  deselectTag={deselectTag}
                  enableDelete={enableDelete}
                />
              ))
            ) : (
              "No Tags"
            )}
            {showCreateTagsOption && createTag.modalHook.modalButton}
          </div>
        )}
      </Collapse>
      {showHideTagsOption && (
        <i
          title={showTags ? "Hide Tags" : "Show Tags"}
          style={{
            cursor: "pointer",
            marginLeft: "0.5em",
          }}
          className="icon-options"
          onClick={() => setShowTags(!showTags)}
        />
      )}
    </div>
  );

  return {
    display,
    selectedTags,
    selectTag,
    deselectTag,
    enableQuery: listTags.enableQuery,
    setEnableQuery: listTags.setEnableQuery,
  };
};
