import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteImplementationLevel } = generateGraphql("ImplementationLevel");

export const DeleteImplementationLevel = async (item) => {
  return await API.graphql(
    graphqlOperation(deleteImplementationLevel, {
      input: {
        id: item.id,
      },
    }),
  )
    .then(({ data }) => {
      if (data && data.deleteImplementationLevel) {
        EventLogger(`Implementation Level ${data.deleteImplementationLevel.id} was Successfully Deleted`);
      }
    })
    .catch((err) => EventLogger("Error! Implementation Level delete function: ", err));
};
