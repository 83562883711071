import { useForm } from "../../../../hooks/views/useForm/useForm";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { v4 as uuid } from "uuid";
import { Storage } from "@aws-amplify/storage";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

const { createMutation: createReportTemplate, updateMutation: updateReportTemplate } = generateGraphql(
  "ReportTemplate",
  ["name", "description"],
);

export const useCreateCustomTemplate = ({
  item,
  resetFunction,
  organizationID,
  ref,
  toggleModal,
  defaultValues = {},
}) => {
  const callback = async (newTemplate) => {
    if (ref?.documentEditor) {
      ref.documentEditor.saveAsBlob("Sfdt").then((sfdtBlob) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          // Get Json string here
          const sfdtText = fileReader.result;
          const fileName = newTemplate.name;
          const key = `report_templates/${organizationID}/${uuid()}_${fileName}`;

          await Storage.put(key, sfdtText)
            .then(async (result) => {
              await ItemMutation(updateReportTemplate, {
                id: newTemplate.id,
                key: result.key,
              });
            })
            .catch((err) => {
              EventLogger(`${fileName}  failed to upload. ${err}`);
            }); // If the upload fails.;
        };
        fileReader.readAsText(sfdtBlob);
      });
    }
  };

  const formHook = useForm({
    item,
    toggleModal,
    typename: "Report Template",
    organizationID,
    mutation: item ? updateReportTemplate : createReportTemplate,
    module: modules.REPORTS,
    resource: resources.REPORT,
    resetFunction,
    callback: item == null ? callback : null,
    fieldConfig: {
      name: {
        label: "Report Template Name",
        defaultValue: defaultValues?.name || "",
        required: true,
      },
      description: {
        label: "Description",
      },
    },
  });

  return { ...formHook };
};

export const CreateCustomTemplate = (props) => useCreateCustomTemplate(props).display;
