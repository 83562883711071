import React from "react";
import { useModal } from "../../../../hooks/views/useModal";
import { Button } from "reactstrap";
import ModuleSections from "./useRoleDetails/components/ModuleSections";
import RolePrecedence from "../customFields/RolePrecedence";
import { modules, resources } from "@rivial-security/role-utils";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useHistory } from "react-router-dom";

/**
 * Displays a read-only version of a single Role.
 *
 * @param {object} role - the Role to be displayed
 * @param {object} role.roleConfig - the Role Configuration object
 * @return {{setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, display: JSX.Element, modal: JSX.Element, toggleModal: function(): void}}
 */
export const useDisplayRole = ({ role }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.ROLE;

  const checkPermissions = useCheckPermissions({ module, resource });

  const { push } = useHistory();

  const display = <ModuleSections precedence={role?.precedence} roleConfig={role?.roleConfig} disableEdits={true} />;

  const isRoleExists = role && Object.keys(role).length > 0;

  const displayRoleModal = useModal(
    <span>
      <span style={{ marginRight: "1em" }}>{role?.name || "Role"}</span>
      <RolePrecedence item={role} />
    </span>,
    <div>{display}</div>,
    <>
      <Button
        size="sm"
        color="ghost-success"
        className="btn-pill"
        title="Click to view your Role"
        disabled={!isRoleExists}
        onClick={() => displayRoleModal.setModalIsOpen(!displayRoleModal.modalIsOpen)}
      >
        <i className="icon-user-female" /> {!isRoleExists ? "No Role" : role && role.name ? role.name : "Display Role"}
      </Button>
      {checkPermissions.resource.read && isRoleExists && (
        <Button
          size="sm"
          color="link"
          className="btn-pill"
          title="Go to Role Details"
          onClick={(e) => {
            e.stopPropagation();
            push(`/organization_manager/roles/${role?.id}`);
          }}
        >
          <i className="icon-share-alt" />
        </Button>
      )}
    </>,
    { width: "75vw" },
  );

  return {
    ...displayRoleModal,
    display,
  };
};
