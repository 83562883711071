import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes a KPIMetricLink
 *
 * @author Jacob Blazina
 * @function
 * @async
 * @param {object} item - the item to be deleted
 * @param {string} item.id - the AppSync ID of the KPIMetricTypeLink
 * @returns {Promise<*>}
 */
export const deleteKPIMetricTypeLink = async (item, typename = "KPIMetricTypeLink") => {
  // full query to get KPIMetricLinks
  const fields = ["id"];

  const { deleteMutation } = generateGraphql(typename, fields);

  // delete KeyRiskIndicator

  return await ItemMutation(deleteMutation, { id: item.id });
};

export default deleteKPIMetricTypeLink;
