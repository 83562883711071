import { checkArguments } from "@rivial-security/func-utils";
import {
  listTasksByPointOfContact,
  listTasksByDepartment,
  listTasksByTaskList,
  listTasksByOwnerGroup,
} from "../graphql/listTaskQueries";
import { ListQueryBy } from "../../../../../../utils/Functions/Graphql/ListQueryBy";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { taskCategory } from "../constants/taskCategory";

/**
 * @description function to get the tasks for a given type and id
 * @param {string} taskListType - the type of the task list to get
 * @param {string} id - the id of the type to get tasks for
 * @param {function} setIsLoading - function to set the loading state of the component
 * @param {object} currentList - the current list (custom list) to get tasks for
 * @return {Promise<*|*[]>}
 */
export const getTasks = async ({ taskListType, id, setIsLoading, currentList }) => {
  let query, variables;

  checkArguments(
    { id, taskListType, setIsLoading },
    {
      id: { type: "string", required: true },
      taskListType: { type: "string", required: true },
      setIsLoading: { type: "function", required: true },
    },
  );

  switch (taskListType) {
    case taskCategory.MY_TASKS:
      query = listTasksByPointOfContact;
      variables = { pointOfContactID: id };
      break;
    case taskCategory.DEPARTMENT_TASKS:
      query = listTasksByDepartment;
      variables = { departmentID: id };
      break;
    case taskCategory.PAST_DUE_TASKS:
      query = listTasksByOwnerGroup;
      variables = { ownerGroup: id };
      break;
    default:
      query = listTasksByTaskList;
      variables = { taskListID: currentList?.id };
      break;
  }

  try {
    return await ListQueryBy({
      query,
      variables,
      setIsLoading,
      limit: 1000,
    });
  } catch (error) {
    EventLogger(`Error: Unable to get ${taskListType} tasks:`, error);
    return [];
  }
};
