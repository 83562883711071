"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemIndexes = void 0;
exports.systemIndexes = [
    {
        indexName: "systemByOwnerGroup",
        queryField: "systemByOwnerGroup",
        partitionKey: "ownerGroup",
        partitionKeyType: "String",
    },
];
