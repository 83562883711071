export const listPointOfContacts_pointOfContactList = `query ListPointOfContacts(
  $filter: ModelPointOfContactFilterInput
  $limit: Int
  $nextToken: String
) {
  listPointOfContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      title
      email
      phone
      meetings {
        items {
          id
        }
      }
      user {
        id
        username
        name
        roleLinks {
          items {
            id
          }
        }
      }
    }
    nextToken
  }
}
`;

export const getPointOfContact_minimal = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    firstName
    lastName
    title
    email
    phone
    ownerGroup
  }
}
`;

export const getPointOfContact_evidenceList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    evidences(limit: 200) {
      items {
        id
        evidence {
          id
          name
          status
          frequency
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        }
      }
    }
  }
}
`;

export const getPointOfContact_systemList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    systems(limit: 500) {
      items {
        id
        name
        description
        hosting
        riskRating
        residualRisk
        inherentRisk
        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
      }
    }
    systemsAdmin(limit: 500) {
      items {
        id
        name
        description
        hosting
        riskRating
        residualRisk
        inherentRisk
        tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
      }
    }
  }
}
`;

export const getPointOfContact_meetingList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    meetings(limit: 500) {
      items {
        meeting {
          id
          name
          description
          meetingDate
          meetingTime
          duration
          organizer
          location
          status
          isAllDay
          startTime
          endTime
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }
        module
        }
      }
    }
  }
}
`;

export const getPointOfContact_actionList = `query GetPointOfContact($id: ID!) {
  getPointOfContact(id: $id) {
    id
    evidences(limit: 200) {
      items {
        id
        evidence {
          id
          name
          pointOfContacts(limit: 100) {
            items {
              pointOfContact {
                id
                firstName
                lastName
                email
                title
                ownerGroup
              }
            }
          }
          observations(limit: 100) {
            items {
              id
              name
              module
              resource
              field
              recommendations (limit: 100) {
                items {
                  id
                  recommendation {
                    id
                    actionItems (limit: 100) {
                      items {
                      id
                      action {
                        id
                        name
                        status
                        dueDate
                        module
                      }
                    }
                  }
                }
              }
            }
           }
          }
        }
      }
    }
  }
}
`;
