import { useState } from "react";
import { v4 as uuid } from "uuid";

/**
 * @description Combines the useState and useEffect hook.
 * @deprecated Just call uuid() directly instead
 */
export const useUUID = (): string => {
  const [value] = useState(uuid());

  return value;
};
