import { createTheme as createThemeV5, createTheme } from "@mui/material/styles";

export const themeV4 = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
  },
});

export const applicationTheme = createThemeV5({
  palette: {
    primary: {
      main: themeV4.palette.primary.main,
    },

    mode: "light", // 'dark',
  },
  mobileHeader: {
    foreground: {
      color: "#fff",
    },
    background: {
      color: "#448AB8",
    },
  },
  // Nav Bar
  navigationBar: {
    // Nav bar background
    background: {
      default: "#448ab8",

      secondary: "var(--sidebar-dark-background)",

      selected: "#4453b8",
    },

    // Nav Bar Menu Item
    menuItem: {
      // Nav Bar Menu item Background
      background: {
        color: {
          // Color when nav bar button is not selected, hovered, or clicked
          default: "#fff",

          // Color when the nav bar button is selected
          selected: "#25556a",

          // Color when the nav bar button is hovered
          hover: "#20a8d8",

          // Color when the nav bar button is not selected
          nestedDefault: "#0f2634",
        },
      },

      text: {
        size: "0.95rem",
        color: "#ffffff",
      },

      icon: {
        minWidth: "35px",
      },
    },
  },
  footer: {},
  main: {
    backgroundColor: {
      light: "#ffffff",
      dark: "#1e1e1e",
    },
    padding: 2,
  },
});
