import React, { useContext, useEffect, useState } from "react";
import { GetQuery } from "../../../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQuery } from "../../../../../../utils/Functions/Graphql/ListQuery";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { isNonEmptyArray } from "@rivial-security/func-utils";
import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { taskCategory } from "../constants/taskCategory";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { isSpecialRole } from "../../../functions/isSpecialRole";
import { useModal } from "../../../../../../hooks/views/useModal";
import CustomTaskListModalContent from "../components/CustomTaskListModalContent";
import CreateCustomListForm from "../components/CreateCustomListForm";

/**
 * Custom hook to manage custom task lists
 * @param {object} currentList - The current custom list being viewed
 * @param {function} setCurrentList - The setter function to call when the current list is changed
 * @param {boolean} isSidebarView - Determines if the task is being viewed in the sidebar
 * @param {function} setSelectedMenu - The setter function to call when the selected menu is changed
 * @returns {object}
 */
export const useCustomTaskLists = ({ currentList, setCurrentList, isSidebarView, setSelectedMenu }) => {
  const [customLists, setCustomLists] = useState([]);
  const [pointOfContact, setPointOfContact] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewListCreated, setIsNewListCreated] = useState(false);
  const context = useContext(OrganizationContext);

  const isUserInNonOriginalOrganization =
    isSpecialRole({ context }) && context?.selectedOrganization !== pointOfContact?.ownerGroup;

  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["id", "firstName", "lastName"]);
  const {
    createMutation: createNewCustomList,
    listQuery: listCustomLists,
    deleteMutation: deleteCustomList,
    updateMutation: updateCustomList,
  } = generateGraphql("TaskList", ["name", "pointOfContactID", "tasks"], {
    tasks: `{ items { id pointOfContactID name description status type dueDate pointOfContact { firstName lastName id }  } }`,
  });
  const { deleteMutation: deleteTask } = generateGraphql("Task", ["id"]);

  const fetchPointOfContact = async () => {
    try {
      const pointOfContactData = await GetQuery({
        query: getPointOfContact,
        variables: { id: context?.loggedInPointOfContactId },
      });
      setPointOfContact(pointOfContactData);
    } catch (error) {
      EventLogger("Failed to fetch point of contact:", error);
    }
  };

  const getCustomLists = async () => {
    try {
      const customListData = await ListQuery({
        query: listCustomLists,
        organizationID: context?.selectedOrganization,
      });
      setCustomLists(customListData);
    } catch (error) {
      EventLogger("Failed to get custom lists:", error);
      return [];
    }
  };

  const handleDeleteList = async () => {
    try {
      // First, delete all tasks associated with the list
      if (isNonEmptyArray(currentList?.tasks?.items)) {
        const deleteTasksPromises = currentList?.tasks.items.map((task) => {
          return ItemMutation(deleteTask, { id: task?.id });
        });

        await Promise.all(deleteTasksPromises);
      }

      // Then, delete the list
      setCustomLists((prevLists) => prevLists.filter((list) => list?.id !== currentList?.id));

      await ItemMutation(deleteCustomList, {
        id: currentList?.id,
      }).then(() => {
        if (isSidebarView && !isUserInNonOriginalOrganization) {
          setSelectedMenu(taskCategory.MY_TASKS);
        } else if (!isSidebarView && !isUserInNonOriginalOrganization) {
          setSelectedMenu(taskCategory.MY_TASKS);
        } else {
          setSelectedMenu(null);
          setCurrentList(null);
        }
        taskListActionModal?.setModalIsOpen(false);
      });
    } catch (error) {
      EventLogger("Error deleting task list:", error);
    }
  };

  const handleEditListName = () => {
    setIsEditing(true);
    createTaskListModal.setModalIsOpen(true);
  };

  const handleOnClose = () => {
    if (isNewListCreated) {
      setSelectedMenu(currentList?.name);
      return;
    }

    if (isSidebarView && !isEditing && !isUserInNonOriginalOrganization && !isNewListCreated) {
      setSelectedMenu(taskCategory.MY_TASKS);
      taskListActionModal.setModalIsOpen(false);
      return;
    }
    taskListActionModal.setModalIsOpen(false);
  };
  const handleFormSubmit = async ({ item, isEditing }) => {
    const mutation = isEditing ? updateCustomList : createNewCustomList;
    const mutationData = {
      name: item?.name,
      id: isEditing ? currentList?.id : item?.id,
      ownerGroup: item?.ownerGroup,
      pointOfContactID: item?.pointOfContactID,
    };

    try {
      const response = await ItemMutation(mutation, { ...mutationData });

      if (isEditing) {
        // Update existing list
        setCustomLists((prevLists) => {
          return prevLists.map((list) => {
            if (list?.id === currentList?.id) {
              return { ...list, name: item.name };
            }
            return list;
          });
        });
        setSelectedMenu(item?.name);
        setCurrentList(response);
        taskListActionModal.setModalIsOpen(false);
      } else {
        setCustomLists((prevLists) => [...prevLists, response]);
        setSelectedMenu(response?.name);
        setCurrentList(response);
        setIsNewListCreated(true);
        setIsEditing(false);
      }
      createTaskListModal.setModalIsOpen(false);
    } catch (error) {
      EventLogger("Error handling form submission:", error);
    }
  };

  const createTaskListModal = useModal(
    isEditing ? `Edit ${currentList?.name}` : "Create a new custom task list",
    <CreateCustomListForm
      isEditing={isEditing}
      currentList={currentList}
      updateListMutation={updateCustomList}
      createListMutation={createNewCustomList}
      submitFunction={handleFormSubmit}
      pointOfContact={pointOfContact}
      initialValues={{ name: currentList?.name }}
    />,
    null,
    {
      width: "40vw",
      onClosed: handleOnClose,
    },
  );
  const taskListActionModal = useModal(
    `Manage ${currentList?.name}`,
    <CustomTaskListModalContent
      currentList={currentList}
      handleDeleteList={handleDeleteList}
      handleEditListName={handleEditListName}
    />,
    null,
    { width: "25vw", onClosed: handleOnClose },
  );

  useEffect(() => {
    fetchPointOfContact();
    getCustomLists();
  }, [context?.selectedOrganization, isEditing, isNewListCreated]);

  return {
    customLists,
    setCustomLists,
    pointOfContact,
    setPointOfContact,
    createNewCustomList,
    deleteCustomList,
    updateCustomList,
    handleDeleteList,
    taskListActionModal,
    createTaskListModal,
  };
};
