import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../utils/Functions/Graphql/GetQuery";

/**
 * @description Query templates from the database
 * @example queryTemplates({
 * resource: resources.EVIDENCE
 * }).then((data) => console.log(data))
 * @param {object} input.resource
 * @returns {Promise<Array>}
 */
export const queryTemplates = async (input) => {
  const queryTemplatesLambdaGraphql = `
    query queryTemplates($input: AWSJSON) {
      queryTemplates(input: $input)
    }
  `;

  EventLogger(`Sending request to queryTemplates lambda. Input: ${JSON.stringify(input)}`);

  const res = await GetQuery({
    query: queryTemplatesLambdaGraphql,
    variables: {
      input: JSON.stringify(input),
    },
  });

  try {
    return JSON.parse(res);
  } catch (e) {
    EventLogger("Can not get templates", e);
  }
};
