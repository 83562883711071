import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Generic Snackbar component to display a snackbar after an action is performed
 * @param {boolean} open - whether the snackbar is open
 * @param {function} onClose - action to perform when the snackbar is closed
 * @param {string} message - the message to display in the snackbar
 * @param {string} buttonText - the text to display on the button
 * @param {function} buttonAction - the action to perform when the button is clicked
 * @param {number} autoHideDuration - the duration to display the snackbar (in milliseconds)
 * @param {string} alertSeverity - the severity of the alert (error, warning, info, success)
 * @param  {object} position - the position of the snackbar ({vertical: "bottom", horizontal: "center"} will display the snackbar in the center of the bottom of the screen)
 * @returns {JSX.Element}
 */
const GenericSnackbar = ({
  open,
  onClose,
  message,
  buttonText,
  buttonAction,
  autoHideDuration = 5000,
  alertSeverity = "success",
  position = { vertical: "bottom", horizontal: "right" },
}) => {
  return (
    <div style={{ position: "absolute", bottom: 0, right: 0 }}>
      <Snackbar anchorOrigin={position} open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
        <Alert
          severity={alertSeverity}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">{message}</Typography>
            {buttonText && (
              <Button
                color="primary"
                size="small"
                onClick={buttonAction}
                style={{ margin: "0 1rem" }}
                startIcon={<SettingsBackupRestoreIcon />}
              >
                {buttonText}
              </Button>
            )}
            <CloseIcon fontSize="small" onClick={onClose} />
          </div>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GenericSnackbar;
