import { Auth } from "@aws-amplify/auth";

/**
 * @author Bryan Caesar
 * @function
 * @description A small utility function that takes in a String and returns a boolean. The boolean indicates
 *                whether or not the current Cognito user belongs to a Cognito group whose name matches the
 *                passed-in String. Case sensitive.
 * @param groupName {string}
 * @returns {Promise<boolean|CognitoUserSession>}
 */

export const isUserInGroup = async (groupName) => {
  if (!(groupName && groupName.length && groupName.length > 0)) return false;

  return await Auth.currentSession().then((response) => {
    const groups = response.accessToken.payload["cognito:groups"];
    return groups && groups.includes(groupName);
  });
};

/**
 * @deprecated
 * @function
 * @description Get Point Of Contact From User
 * @param id {string}
 * @returns {Promise<void>}
 */

export const getPointOfContactFromUser = async (id) => {
  await Auth.currentSession().then((response) => {
    return JSON.stringify(response.accessToken.payload);
  });
};
