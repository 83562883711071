import React, { useEffect } from "react";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDataCard } from "../../../../hooks/views/useDataCard";
import { useUploadVendorReviewDocument } from "./useUploadVendorReviewDocument";
import CustomVendorRemoveButton from "../customFields/CustomVendorRemoveButton";
import CertificationName from "../../VendorCertification/customFields/CertificationName";
import { modules, resources } from "@rivial-security/role-utils";
import AVStatus from "../../../../utils/CustomFields/AVStatus";
import DateField from "../../../../utils/CustomFields/DateField";
import MuiItemPreviewButton from "../../../../utils/GenericComponents/buttons/MuiItemPreviewButton";
import MuiItemDownloadButton from "../../../../utils/GenericComponents/buttons/MuiItemDownloadButton";
import { Storage } from "@aws-amplify/storage";

/**
 * @description Displays a list of existing vendor review documents
 * @param {object} vendorReview - vendor review object, id is required - Required
 * @param {string} organizationID - current selected organization - Required
 * @param {function} resetFunction -reset parent state - Optional
 * @param {function} toggleModal - close the parent modal - Optional
 * @param {string} module - platform module to use for role checking
 * @param {string} resource - platform resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will not
 * @param {boolean} disableEdits - if TRUE will not allow uploading and remove documents
 * @returns {JSX.Element}
 */

export const useVendorReviewDocuments = ({
  vendorReview,
  organizationID,
  resetFunction,
  toggleModal,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
  disableEdits = false,
}) => {
  const { onUpdate: onUpdateVendorReview, getQuery: getVendorReviewQuery } = generateGraphql(
    "VendorReview",
    ["name", "documents"],
    {
      documents: ` (limit: 100) {
        items {
          id
          name
          avStatus
          lastAVCheck
          ownerGroup
          file {
            bucket
            region
            key
          }
          vendorCertification {
            name
          }
        }
        nextToken
      }`,
    },
  );

  const queryItem = useQueryGetItem({
    query: getVendorReviewQuery,
    itemId: vendorReview && vendorReview.id,
    module,
    resource: "vendorReview",
    onUpdateItemSubscription: onUpdateVendorReview,
    disableRoleChecking: true,
  });

  const { display: createVendorDocument } = useUploadVendorReviewDocument({
    vendorReview,
    organizationID,
    resetFunction,
    toggleModal,
    module,
    resource,
    disableRoleChecking,
  });

  const customFields = [
    {
      field: "preview",
      component: (
        <MuiItemPreviewButton
          downloadFunction={async ({ key }) => {
            return await Storage.get(key, {
              expires: 300,
            });
          }}
        />
      ),
    },
    {
      field: "download",
      component: (
        <MuiItemDownloadButton
          downloadFunction={async ({ key }) => {
            return await Storage.get(key, {
              expires: 300,
            });
          }}
        />
      ),
    },
    {
      field: "remove",
      component: (
        <CustomVendorRemoveButton module={module} resource={resource} disableRoleChecking={disableRoleChecking} />
      ),
    },
    {
      field: "vendorCertification",
      component: <CertificationName />,
    },
    {
      field: "avStatus",
      component: <AVStatus />,
    },
    {
      field: "lastAVCheck",
      component: <DateField fieldName={"lastAVCheck"} />,
    },
  ];

  const fields = ["vendorCertification", "name", "preview", "download", "avStatus", "lastAVCheck"];
  if (disableEdits !== true) {
    fields.push("remove");
  }

  const documentList = useDataCard({
    data:
      queryItem.item && queryItem.item.documents && queryItem.item.documents.items
        ? queryItem.item.documents.items
        : [],
    header: "Relevant Documents",
    fields,
    fieldNameDictionary: {
      vendorCertification: "Pre-Qualified Certification",
      avStatus: "Anti-Virus Status",
      lastAVCheck: "Last Anti-Virus Check",
    },
    customFields: customFields,
    createResourceComponent: disableEdits === true ? null : createVendorDocument,
    config: { width: "90vw" },
    module,
    resource: resources.VENDOR_DOCUMENT,
    resetFunction: queryItem.reset,
    disableRoleChecking: true,
  });

  useEffect(() => {
    if (queryItem && queryItem.item && queryItem.item.documents && queryItem.item.documents.items) {
      documentList.setData(queryItem.item.documents.items);
    }
  }, [queryItem && queryItem.item && queryItem.item.documents && queryItem.item.documents.items]);

  useEffect(() => {
    documentList.setLoading(queryItem.isLoading);
  }, [queryItem.isLoading]);

  const display = <div>{documentList.display}</div>;

  return {
    display,
    resetFunction: queryItem.reset,
  };
};
