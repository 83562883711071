import AccountManager from "../../../../../views/OrganizationManager/PointOfContacts/components/AccountManager";
import Button from "@mui/material/Button";
import Styles from "../../../css/AvatarCard.module.css";
import { getPointOfContactName } from "../../../../../views/Reports/ComplianceReport/functions/getPointOfContactName";
import { useModal } from "../../../../../hooks/views/useModal";

export const useProfileModal = ({ loggedInPointOfContactId, pointOfContact }) => {
  return useModal(
    "User Dashboard",
    <AccountManager item={{ id: loggedInPointOfContactId }} />,
    <Button
      id="button-account-manager"
      data-testid="button-account-manager"
      aria-details={pointOfContact?.email}
      className={`${Styles["avatar-card-names"]} btn-ghost-info`}
      size="md"
      title="Point of Contact Dashboard"
      style={{ marginTop: "-0.25em" }}
    >
      {" "}
      {pointOfContact ? getPointOfContactName(pointOfContact) : "My Dashboard"}
    </Button>,
    { width: "80vw" },
  );
};
