import React, { useState } from "react";

import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import IframeResizer from "iframe-resizer-react";
import { mitreAttackApiUrl } from "../../../../../env-config";

const countStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#448ab8",
  height: "100%",
  marginTop: "-1em",
};

/**
 * @description Displays Mitre Attack Mitigation details
 * @param {object} item - Mitre Attack Mitigation item
 * @returns {{display: JSX.Element}}
 */
export const useMitreAttackMitigationDetails = ({ item }) => {
  const [loading, setLoading] = useState(true);

  const display = (
    <DashboardCard style={countStyle}>
      <IframeResizer
        src={`${mitreAttackApiUrl}/mitigation/${item?.id}`}
        style={{ minWidth: "100%", height: "80vh", border: 0 }}
        scrolling={true}
        onLoad={() => setLoading(false)}
      />
    </DashboardCard>
  );

  return { display };
};
