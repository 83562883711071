import Button, { ButtonVariant } from "../Button/Button";
import { ComponentPropsWithoutRef, ReactNode, useState } from "react";
import { getIcon, IconName } from "@components/Ions/icons/components/Icons";
import Text, { TextVariant } from "../Text/Text";

import IconButton from "../IconButton/IconButton";
import cx from "classnames";
import styles from "./Alert.module.scss";
import { useTranslation } from "react-i18next";

export enum AlertVariant {
  SUCCESS = "Success",
  INFO = "Info",
  WARNING = "Warning",
  ERROR = "Error",
}

export interface AlertProps extends ComponentPropsWithoutRef<"div"> {
  /** The type of alert to communicate information or urgency to the user. */
  variant: AlertVariant;
  /** The text to display at the top of the alert. */
  title?: string;
  /** The text to display in the body of the alert. */
  content: string;
  /** The function to call when the action button is clicked or if set to `true` it means the alert is dismissible.  Defaults to `false`. */
  onActionClicked?: VoidFunction | boolean;
  /** The text to display for the action button.  Required, and only visible when `onActionClicked` is a function. */
  actionButtonText?: string;
  /** The class name to apply to the alert. */
  className?: string;
}

const AlertIcon = (variant: AlertVariant): ReactNode => {
  const icons = {
    [AlertVariant.SUCCESS]: IconName.SUCCESS,
    [AlertVariant.INFO]: IconName.INFO,
    [AlertVariant.WARNING]: IconName.WARNING,
    [AlertVariant.ERROR]: IconName.ERROR,
  };

  const icon = getIcon(icons[variant]);

  if (!icon) {
    console.warn(`Cannot find icon for AlertVariant: ${variant}`);

    return null;
  }

  return icon;
};

const hasActionButton = ({
  actionButtonText,
  onActionClicked,
}: {
  actionButtonText?: string;
  onActionClicked: VoidFunction | boolean;
}): boolean => {
  return !!actionButtonText && onActionClicked && typeof onActionClicked === "function";
};

const ActionButton = ({
  actionButtonText,
  onActionClicked,
  onDismissAlert,
  className,
}: {
  actionButtonText?: string;
  onActionClicked: VoidFunction | boolean;
  onDismissAlert: VoidFunction;
  className?: string;
}): ReactNode => {
  const { t } = useTranslation();

  const showCloseButton = onActionClicked && typeof onActionClicked === "boolean";

  if (hasActionButton({ actionButtonText, onActionClicked })) {
    const actionButtonClassName = cx(styles["alert__action-button"], className);

    return (
      <div className={styles["alert__action-button-container"]}>
        <Button
          className={actionButtonClassName}
          variant={ButtonVariant.TEXT}
          onClick={onActionClicked as VoidFunction}
        >
          {actionButtonText}
        </Button>
      </div>
    );
  } else if (showCloseButton) {
    const actionButtonClassName = cx(styles["alert__dismiss-button"], className);

    return (
      <div className={styles["alert__dismiss-button-container"]}>
        <IconButton
          iconName={IconName.CLOSE}
          onClick={onDismissAlert}
          aria-label={t("alert.close alert")}
          className={actionButtonClassName}
        />
      </div>
    );
  } else {
    return null;
  }
};

/**
 * An `Alert` component is used to communicate information to a user.  Some alerts have actions associated with them, or can be dismissed which will hide the alert.
 */
const Alert = ({
  variant,
  title,
  content,
  onActionClicked = false,
  actionButtonText,
  className,
}: AlertProps): ReactNode => {
  const [isDismissed, setIsDismissed] = useState(false);

  const onDismissAlert = (): void => {
    setIsDismissed(true);
  };

  if (isDismissed) {
    return null;
  }

  const alertClassName = cx(
    styles.alert,
    {
      [styles["alert__with-title"]!]: title,
      [styles["alert--success"]!]: variant === AlertVariant.SUCCESS,
      [styles["alert--info"]!]: variant === AlertVariant.INFO,
      [styles["alert--warning"]!]: variant === AlertVariant.WARNING,
      [styles["alert--error"]!]: variant === AlertVariant.ERROR,
    },
    className,
  );

  if (title) {
    return (
      <div className={alertClassName} role="alert">
        <div className={styles.alert__title_row}>
          <div className={styles["alert__variant-icon"]}>{AlertIcon(variant)}</div>
          <Text className={styles.alert__title} variant={TextVariant.SUBTITLE2} htmlElement="span">
            {title}
          </Text>
        </div>
        <Text className={styles["alert__content--with-title"]} variant={TextVariant.BODY3} htmlElement="span">
          {content}
        </Text>
        <ActionButton
          actionButtonText={actionButtonText}
          onActionClicked={onActionClicked}
          onDismissAlert={onDismissAlert}
        />
      </div>
    );
  }

  return (
    <div className={alertClassName} role="alert">
      <div
        className={cx(styles["alert__variant-icon"], {
          [styles["alert__variant-icon--with-action-button"]!]: hasActionButton({ actionButtonText, onActionClicked }),
        })}
      >
        {AlertIcon(variant)}
      </div>
      <Text
        className={cx(styles.alert__content, styles["alert__content--sans-title"], {
          [styles["alert__content--with-action-button"]!]: hasActionButton({ actionButtonText, onActionClicked }),
        })}
        variant={TextVariant.BODY3}
        htmlElement="span"
      >
        {content}
      </Text>
      <ActionButton
        actionButtonText={actionButtonText}
        onActionClicked={onActionClicked}
        onDismissAlert={onDismissAlert}
      />
    </div>
  );
};

export default Alert;
