import React from "react";
import { Redirect } from "react-router-dom";

const VendorDashboard = React.lazy(() => import("../../views/Vendor/VendorDashboard/VendorDashboard"));
const VendorList = React.lazy(() => import("../../views/Vendor/Vendor/components/VendorDataGrid"));
const VendorDetails = React.lazy(() => import("../../views/Vendor/Vendor/components/VendorDetails"));
const VendorSolutionsList = React.lazy(
  () => import("../../views/Vendor/VendorSolutions/components/VendorSolutionsDataGrid"),
);
const VendorSolutionDetailsAccordion = React.lazy(
  () => import("../../views/Vendor/VendorSolutions/components/VendorSolutionDetailsAccordion"),
);
const VendorSolutionReviewDetails = React.lazy(
  () => import("../../views/Vendor/VendorSolutionReviews/components/VendorSolutionReview"),
);
const VendorSolutionReviewList = React.lazy(
  () => import("../../views/Vendor/VendorSolutionReviews/components/VendorReviewDataGrid"),
);
const VendorControlCategoryList = React.lazy(
  () => import("../../views/Vendor/VendorControlCategories/components/VendorControlCategoryDataGrid"),
);
const VendorControlCategoryDetails = React.lazy(
  () => import("../../views/Vendor/VendorControlCategories/components/VendorControlCategoryDetails"),
);

const VendorSubControlDetails = React.lazy(
  () => import("../../views/Vendor/VendorSubControls/components/VendorSubControlDetails"),
);

const VendorCertification = React.lazy(
  () => import("../../views/Vendor/VendorCertification/components/VendorCertificationGrid"),
);
const VendorCertificationDetails = React.lazy(
  () => import("../../views/Vendor/VendorCertification/components/VendorCertificationDetails"),
);
const VendorReviewImport = React.lazy(() => import("../../views/Vendor/Vendor/components/ImportVendors"));
const VendorConfig = React.lazy(() => import("../../views/Vendor/VendorConfig/VendorConfig"));

// Vendor Routes
export const vendorRoutes = [
  {
    path: "/vendors/dashboard",
    exact: true,
    name: "Vendor Dashboard",
    component: VendorDashboard,
  },
  {
    path: "/vendors/vendor/",
    exact: true,
    name: "Vendors",
    component: VendorList,
  },
  {
    path: "/vendors/vendor/:id",
    exact: true,
    name: "Vendor Details",
    component: VendorDetails,
  },
  {
    path: "/vendors/solutions",
    exact: true,
    name: "Vendor Solutions",
    component: VendorSolutionsList,
  },
  {
    path: "/vendors/solutions/:id",
    exact: true,
    name: "Vendor Solution Details",
    component: VendorSolutionDetailsAccordion,
  },
  {
    path: "/vendors/reviews",
    exact: true,
    name: "Vendor Solution Reviews",
    component: VendorSolutionReviewList,
  },
  {
    path: "/vendors/reviews/:id",
    exact: true,
    name: "Vendor Solution Review Details",
    component: VendorSolutionReviewDetails,
  },
  {
    path: "/vendors/controls",
    exact: true,
    name: "Vendor Control Categories",
    component: VendorControlCategoryList,
  },
  {
    path: "/vendors/controls/:id",
    exact: true,
    name: "Vendor Control Category Details",
    component: VendorControlCategoryDetails,
  },
  {
    path: "/vendors/subControls/:id",
    exact: true,
    name: "Vendor Sub Control Details",
    component: VendorSubControlDetails,
  },
  {
    path: "/vendors/certification/",
    exact: true,
    name: "Vendor Certification",
    component: VendorCertification,
  },
  {
    path: "/vendors/certification/:id",
    exact: true,
    name: "Vendor Certification Details",
    component: VendorCertificationDetails,
  },
  {
    path: "/vendors/config",
    exact: true,
    name: "Vendor Configuration",
    component: VendorConfig,
  },

  {
    path: "/vendors/import_vendor",
    exact: true,
    name: "Import Vendor Reviews",
    component: VendorReviewImport,
  },

  { path: "/vendors", component: () => <Redirect to="/vendors/dashboard" /> },
];
