import { API, graphqlOperation } from "@aws-amplify/api";

import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { UIContext } from "../../../utils/Context/UIContext";
import { USER_ACTIVITY_TYPES } from "../../../views/OrganizationManager/Users/enums/USER_ACTIVITY_TYPES";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useCheckPermissions } from "../../permissions/useCheckPermissions/useCheckPermissions";
import { useContext } from "react";

/**
 *
 * @author Anatoli Railean - 02/20/2020
 * @description Hook for GraphQL mutation
 * @param {object} mutation - is the graphql type object for creating, updating or deleting item in database. ex: createControl
 * @param {string} module - is a string of the name of the Module you want to access
 * @param {string} resource - is a string of the name of the resource you want to access
 * @param {string} field - is a string of the name of the field you want to access
 * @param {boolean} disableRoleChecking - is a boolean that determines if the role checking should be disabled
 * @param {string} friendlyName - is a string of the friendly name of the mutation
 * @param {string} typename - is a string of the name of the type you want to access
 * @param {boolean} disableToast - is a boolean that determines if the toast should be disabled
 * @param {string} [route] - if a route is passed in, uses it for the toast Event
 * @returns {object} {{
 *  mutateItem: type function, takes an object as argument that you want to create, update or delete.
 * }}
 * @example
 const updateEvidenceHook = useMutation({updateEvidence, module, resource});

 updateEvidenceHook.mutateItem({
      id: props.item.id,
      evidencePointOfContactId: null
    });
 */

export const useMutation = ({
  mutation,
  module,
  resource,
  field,
  disableRoleChecking,
  typename = "Resource",
  disableToast = false,
  route,
  friendlyName,
}) => {
  const { addToast, updateToast } = useContext(UIContext);

  const checkPermissionsHook = useCheckPermissions({
    module: module,
    resource: resource,
    field: field,
    disableRoleChecking: disableRoleChecking,
  });

  const CreateMutation = async (input) => {
    const toastId =
      !disableToast &&
      addToast({
        icon: "spinner",
        header: `Creating ${typename}...`,
      });

    if (
      checkPermissionsHook.module.isEnabled &&
      checkPermissionsHook.resource.read &&
      checkPermissionsHook.resource.create
    ) {
      return await API.graphql(graphqlOperation(mutation, { input: input }))
        .then(({ data }) => {
          !disableToast &&
            updateToast({
              id: toastId,
              icon: "success",
              header: `Successfully Created ${typename}!`,
              data: {
                type: USER_ACTIVITY_TYPES.CREATE,
                itemId: data?.id,
                typename,
                name: data?.name,
                route,
              },
            });
          return data && data[Object.keys(data)[0]];
        })
        .catch((err) => {
          !disableToast &&
            updateToast({
              id: toastId,
              icon: "danger",
              header: `Error! Could not create ${typename}!`,
            });
          EventLogger(`Error: Mutation ${JSON.stringify(err)}`, err);
        });
    } else {
      !disableToast &&
        updateToast({
          id: toastId,
          icon: "danger",
          header: `Error! You don't have permission to create this ${typename}!`,
        });
      EventLogger(`You don't have permission to create this ${typename}!`);
      return {};
    }
  };

  const UpdateMutation = async (input, throwOnError = false) => {
    if (
      checkPermissionsHook.module.isEnabled &&
      checkPermissionsHook.resource.read &&
      (isNullOrUndefined(field) || checkPermissionsHook.field.update)
    ) {
      return await API.graphql(graphqlOperation(mutation, { input: input }))
        .then(({ data }) => {
          return data && data[Object.keys(data)[0]];
        })
        .catch((err) => {
          EventLogger(`Error: Mutation ${JSON.stringify(err)}`, err);
          if (throwOnError === true) {
            throw err;
          }
        });
    } else {
      EventLogger(`You don't have permission to edit the field: ${friendlyName || field}!`);
      return {};
    }
  };

  const DeleteMutation = async (input) => {
    if (
      checkPermissionsHook.module.isEnabled &&
      checkPermissionsHook.resource.read &&
      checkPermissionsHook.resource.delete
    ) {
      return API.graphql(graphqlOperation(mutation, { input: input }))
        .then(({ data }) => {
          return data && data[Object.keys(data)[0]];
        })
        .catch((err) => EventLogger(`Error: Mutation ${JSON.stringify(err)}`, err));
    } else {
      EventLogger(`You don't have permission to delete this resource: ${resource}!`);
      return {};
    }
  };

  return {
    mutateItem: async (input) => await CreateMutation(input),
    mutateItemLegacy: (input) => CreateMutation(input.input),
    editItem: UpdateMutation,
    deleteItem: DeleteMutation,
    createItem: CreateMutation,
    ...checkPermissionsHook,
  };
};
