export const GovernanceModuleLinks = {
  name: "Governance",
  url: "/governance",
  icon: "simple-line-icons:user-female",
  children: [
    {
      maturityMatrixPage: {
        name: "Blueprint",
        url: "/governance/blueprints",
        icon: "simple-line-icons:chart",
      },
    },
    {
      findingsDashboardPage: {
        name: "Findings",
        url: "/governance/findings_dashboard",
        icon: "simple-line-icons:eye",
      },
    },
    {
      policiesPage: {
        name: "Policies",
        url: "/governance/policies",
        icon: "simple-line-icons:docs",
      },
    },
    {
      raciListPage: {
        name: "Responsibilities",
        url: "/governance/implementationLevels",
        icon: "simple-line-icons:briefcase",
      },
    },
    {
      maturityMatrixCalendarPage: {
        name: "Calendar",
        url: "/governance/calendar",
        icon: "simple-line-icons:calendar",
      },
    },
    {
      meetingsPage: {
        name: "Meetings",
        url: "/governance/meetings",
        icon: "simple-line-icons:people",
      },
    },
    {
      spreadsheetPage: {
        name: "Spreadsheets",
        url: "/governance/spreadsheets",
        icon: "simple-line-icons:grid",
      },
    },
    {
      boardReportPage: {
        name: "Executive Summary",
        url: "/governance/executive_summary_report",
        icon: "simple-line-icons:book-open",
      },
    },
    {
      blueprintReportPage: {
        name: "Blueprint Report",
        url: "/governance/blueprint_reports",
        icon: "simple-line-icons:directions",
      },
    },
  ],
};
