import { modules, resources } from "@rivial-security/role-utils";

import CreateMetricType from "../components/CreateMetricType";
import MetricAutomation from "../customFields/MetricAutomation";
import MetricTypeDetails from "../components/MetricTypeDetails";
import Metrics from "../customFields/Metrics";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { deleteMetricType } from "../functions/deleteMetricType";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";

/**
 * Displays a grid with metric types for a particular organization
 *
 * @param {string} organizationID - ownerGroup used for querying
 * @param {object} ...props - all props passed to the useDataGrid hook
 */
export const useMetricTypeDataGrid = ({ organizationID, ...props }) => {
  //[GENERAL SETTINGS]
  const module = modules.METRICS;
  const resource = resources.METRIC_TYPE;
  const roleConfig = { module, resource };
  const typename = "MetricType";

  //[QUERY CONFIG]
  const queryFields = ["name", "frequency", "metrics", "enabled", "automation"];
  const nestedFields = {
    metrics: "(limit: 200) {items {id name description date data { value format }}}",
    automation: `{ name description jsonPath config fetchConfig { url apiKey } }`,
  };
  const { updateMutation, listQuery } = generateGraphql(typename, queryFields, nestedFields);
  const queryConfig = {
    query: listQuery,
    organizationID,
    module,
    resource,
  };

  //[GRID CONFIG]
  const fields = [
    {
      name: "name",
      sort: {
        direction: "asc",
        priority: 1,
      },
      bulkEdit: true,
      width: 200,
    },
    {
      name: "frequency",
      width: 150,
    },
    {
      name: "metrics",
      component: <Metrics />,
      width: 275,
      valueGetter: ({ value }) => {
        const metrics = value?.items;
        if (Array.isArray(metrics) && metrics.length > 0) {
          value?.items.sort((a, b) => new Date(b.date) - new Date(a.date));
          const date = value?.items?.[0]?.date;
          const dateString = date ? new Date(date).toLocaleDateString() : "No Date";
          const valueString = value?.items?.[0]?.data?.value || "No Value";
          return `${dateString} - ${valueString}`;
        } else {
          return "No Metric Data";
        }
      },
      searchKeys: ["metrics.items.0.data.value"],
    },
    {
      name: "automation",
      component: <MetricAutomation hideTriggerButton={true} />,
      flex: 1,
      minWidth: 400,
      valueGetter: ({ value }) => {
        return convertCamelCaseToSentence(value?.name || "No Automation is set up for this Metric Type.");
      },
      searchKeys: ["automation.name"],
    },
  ];
  const gridConfig = {
    fields,
    options: ["details", "delete"],
    createItemModalHeader: <div>Create a new Metric</div>,
    createResourceComponent: <CreateMetricType />,
    deleteFunction: deleteMetricType,
    updateMutation,
    route: "#/metrics/metrics/",
    helpCenterRoute: "metrics-kpis",
    detailsComponent: <MetricTypeDetails />,
    detailsTitle: "Metric Type Details",
    persistenceUUID: "f6e2t5f7-68fe-423c-b122-2bwcb6gff9e6",
    typename,
  };

  //[CARD CONFIG]
  const cardConfig = {
    headerIcon: "icon-graph",
  };

  const grid = useDataGrid({
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...roleConfig,
    ...props,
  });

  return { ...grid };
};
