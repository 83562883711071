import "./AnnualRateOfOccurrenceModifier.css";

import { Button, ButtonGroup } from "reactstrap";
import { modules, resources } from "@rivial-security/role-utils";

import { EventLogger } from "../../../../../../../utils/EventLogger/EventLogger";
import React from "react";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMutation } from "../../../../../../../hooks/graphql/useMutation/useMutation";
import { useSync } from "../../../../../../../hooks/functional/useSync";

/**
 * Allows the user to set the 'probabilityModifier' field on a SystemRiskLink
 * @param {object} systemRiskLink
 * @param {string} systemRiskLink.id
 * @param {number} systemRiskLink.probabilityModifier
 * @param {function} [resetFunction]
 * @param disableRoleChecking
 * @returns {JSX.Element}
 * @constructor
 */
const AnnualRateOfOccurrenceModifier = ({ systemRiskLink, resetFunction, disableRoleChecking = false }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "probabilityModifier";

  const [probabilityModifier, setProbabilityModifier] = useSync(systemRiskLink && systemRiskLink.probabilityModifier);

  const mutationHook = useMutation({
    module,
    resource,
    field,
    mutation: realTimeRiskGQL.updateSystemRiskLink_probabilityModifier,
    disableRoleChecking,
  });

  const onChange = async ({ value }) => {
    if (probabilityModifier !== value) {
      setProbabilityModifier(value);

      await mutationHook
        .editItem({
          id: systemRiskLink.id,
          probabilityModifier: value,
        })
        .then(() => EventLogger("Probability Modifier was successfully modified!"));

      resetFunction && resetFunction(false);
    }
  };

  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <>
      <ButtonGroup>
        <Button
          size="sm"
          color={probabilityModifier !== null && probabilityModifier === -0.8 ? "info" : "ghost-info"}
          disabled={!checkPermissions.resource.update}
          onClick={() => onChange({ value: -0.8 })}
        >
          Much Lower
        </Button>
        <Button
          size="sm"
          color={probabilityModifier !== null && probabilityModifier === -0.4 ? "primary" : "ghost-primary"}
          disabled={!checkPermissions.resource.update}
          onClick={() => onChange({ value: -0.4 })}
        >
          Slightly Lower
        </Button>
        <Button
          size="sm"
          color={probabilityModifier !== null && probabilityModifier === 0 ? "success" : "ghost-success"}
          disabled={!checkPermissions.resource.update}
          onClick={() => onChange({ value: 0.0 })}
        >
          Normal
        </Button>
        <Button
          size="sm"
          color={probabilityModifier !== null && probabilityModifier === 0.4 ? "warning" : "ghost-warning"}
          disabled={!checkPermissions.resource.update}
          onClick={() => onChange({ value: 0.4 })}
        >
          Slightly Higher
        </Button>
        <Button
          size="sm"
          color={probabilityModifier !== null && probabilityModifier === 0.8 ? "danger" : "ghost-danger"}
          disabled={!checkPermissions.resource.update}
          onClick={() => onChange({ value: 0.8 })}
        >
          Much Higher
        </Button>
      </ButtonGroup>
    </>
  );
};

export default AnnualRateOfOccurrenceModifier;
