import { ConsoleLogging } from "../../env-config";
import { isUserInGroup } from "../Functions/UserGroupChecker";
import { getErrorCode } from "../../analytics/functions/getErrorCode";
import { locationType } from "../../analytics/constants/locationType";
import { inspect } from "util";

/**
 * @description This function handles logging logic for the app.
 * @param {string|unknown} message - the main message for the log
 * @param {object} [error] - additional information if it is an error log
 */
export const EventLogger = (message, error) => {
  const consoleLog = ConsoleLogging;
  const consoleLogErrorCSS = "background: #222; color: #ff0000;";
  const consoleLogInfoCSS = "background: #222; color: #2a9df4;";

  //Log an error in the database if error category data is provided
  let code;
  if (error && typeof error === "object" && (error.reason || error.operation || error.location)) {
    code = getErrorCode(error);
    console.log("window.location", window.location);
    const input = {
      // error information
      code,
      message,

      // TODO: try to get automatically, see error boundary component
      // browser and user information
      client: error?.client,

      // code location information
      route: error?.route || window.location.href,
      component: error?.component || "No Component",
      location: error?.location || locationType.GENERIC,
      stack: new Error()?.stack || "No Stack",

      data: JSON.stringify(error?.errorInfo), // optional JSON object for additional information
    };

    //TODO: log the error in the database when the schema type is created
    console.log("Would have logged: ", { input });
  }

  //Showing error message (with code if available) in the dev console
  if (consoleLog || isUserInGroup("Developer")) {
    if (code) {
      console.log(`%c${code}: ${message}`, consoleLogInfoCSS);
    } else {
      console.log(`%c${message}`, consoleLogInfoCSS);
    }
  }

  //Showing error details in the front-end dev console
  if (error) {
    if (typeof error === "string") {
      console.log(`%c${error}`, consoleLogErrorCSS);
    } else {
      console.log(`%c${inspect(error)}`, consoleLogErrorCSS);
    }
  }
};
