import { Alert, Button, ButtonGroup, Input, Label, Progress } from "reactstrap";
import React, { useEffect, useRef, useState } from "react";
import { S3_BUCKET_NAME, S3_BUCKET_REGION } from "../../../../env-config";
import { modules, resources } from "@rivial-security/role-utils";

import Auth from "@aws-amplify/auth";
import { CreateVendorControlDocumentLink } from "../../VendorSubControls/functions/CreateVendorControlDocumentLink";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { Storage } from "@aws-amplify/storage";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { generateGraphql } from "@rivial-security/generategraphql";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import useListQuery from "../../../../hooks/graphql/useListQuery";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";

/**
 * Custom hook for uploading a Vendor Review document
 *
 * @param vendorReview {Object}
 * @param organizationID {String}
 * @param resetFunction {function}
 * @param toggleModal {function}
 * @param context {object}
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {{display: JSX.Element}}
 */

export const useUploadVendorReviewDocument = ({
  vendorReview,
  organizationID,
  resetFunction,
  toggleModal,
  context,
  module = modules.VENDORS,
  resource = resources.VENDOR_DOCUMENT,
  disableRoleChecking,
}) => {
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);
  const [progressBar, setProgressBar] = useState(0);
  const [uploadMessage, setUploadMessage] = useState(null);

  const isSubmitEnabled = files.length > 0;

  const { createMutation } = generateGraphql(
    "VendorDocument",
    ["name", "owner", "file", "visibility", "vendorReviewID"],
    {
      file: `{
        bucket
        region
        key
      }`,
    },
  );

  const uploadRelevantDocumentsHook = useMutation({
    mutation: createMutation,
    typename: "Vendor Document",
    module,
    resource,
    disableRoleChecking: true,
  });

  /**
   * WIP!!
   * Finds a subControl based on exact match of statementNumber and name
   * @param statementNumber
   * @param name
   * @param controlCategories
   * @returns {*}
   */
  const findSubControl = (statementNumber, name, controlCategories) => {
    const controls = [];

    for (const controlCategory of controlCategories) {
      controls.push(...controlCategory.subControls.items);
    }

    return controls.find((item) => {
      return item.statementNumber === statementNumber && item.name === name;
    });
  };

  const updateSubControl = ({ id, inPlace, audited, controlCategories }) => {
    const { updateMutation } = generateGraphql("VendorSubControl", ["inPlace", "audited"]);

    const tempControlCategories = [];

    ItemMutation(updateMutation, { id, inPlace, audited }).then(() => {
      for (const controlCategory of controlCategories) {
        for (const subControl of controlCategory.subControls.items) {
          if (subControl.id === id) {
            if (inPlace !== null && inPlace !== undefined) {
              subControl.inPlace = inPlace;
            }
            if (audited !== null && audited !== undefined) {
              subControl.audited = audited;
            }
          }
        }
        tempControlCategories.push({ ...controlCategory });
      }
    });
  };

  const { setModalIsOpen, modal, setStepStatus, setSteps, setFinished } = usePleaseWaitModal({
    steps: [
      {
        text: "Uploading Document to S3 Bucket",
      },
    ],
    confirmationText: "Successfully Uploaded Vendor Review Document!",
    autoClose: true,
    autoCloseInterval: 3,
  });

  const onSubmit = async () => {
    setUploadMessage("Please wait...");
    setModalIsOpen(true);

    const region = S3_BUCKET_REGION;
    const bucketName = S3_BUCKET_NAME;

    const { username: owner } = await Auth.currentUserInfo();

    const fileInput = document.getElementById("vendor-solution-documents");

    if (vendorReview && vendorReview.id) {
      setStepStatus(0, "inProgress");
      const fileUploadThreads = Array.from(fileInput && fileInput.files).map(async (file) => {
        const objectKey = generateS3ObjectKey(`vendor-solution/${file.name}`);

        const fileName = file.name;

        const fileInfo = {
          bucket: bucketName,
          region: region,
          key: objectKey,
        };

        await Storage.put(objectKey, file, {
          progressCallback(progress) {
            const loaded = parseInt((progress?.loaded * 100) / progress?.total);
            setProgressBar(loaded);
            if (loaded === 100) {
              setUploadMessage(`${fileName} uploaded`);
            }
          },
        }).then(async () => {
          await uploadRelevantDocumentsHook
            .createItem({
              name: name ? name : fileName,
              owner,
              file: fileInfo,
              visibility: "private",
              vendorReviewID: vendorReview && vendorReview.id,
              vendorDocumentVendorCertificationId: certificationId ? certificationId : undefined,
              ownerGroup: organizationID,
            })
            .then(async (item) => {
              setStepStatus(0, "complete");

              const { getQuery } = generateGraphql("VendorDocument", ["vendorCertification"], {
                vendorCertification: `{
                id
                controlLinks (limit: 100) {
                  items {
                    control {
                      id
                      name
                      statementNumber
                    }
                  }
                  nextToken
                }
              }`,
              });

              const document = await QueryGetItem({
                query: getQuery,
                itemId: item && item.id,
              });

              if (
                document &&
                document.vendorCertification &&
                document.vendorCertification.controlLinks &&
                document.vendorCertification.controlLinks.items &&
                Array.isArray(document.vendorCertification.controlLinks.items)
              ) {
                setSteps((steps) => {
                  return [
                    ...steps,
                    {
                      text: "Getting Pre-Qualified Certification Controls",
                      status: "inProgress",
                    },
                    {
                      text: "Updating Vendor Review Controls",
                      status: "waiting",
                    },
                  ];
                });

                const controlArray = [];

                EventLogger("Querying Vendor Review Control Categories for Auto-Cert..");
                ItemQuery(
                  generateGraphql("VendorReview", ["controlCategories"], {
                    controlCategories: `(limit: 100) { items { id ownerGroup name subControls (limit: 100) { items { id name statementNumber ownerGroup inPlace audited } } } }`,
                  }).getQuery,
                  vendorReview.id,
                ).then((vendorReview) => {
                  setStepStatus(1, "complete");
                  setStepStatus(2, "inProgress");

                  EventLogger("Retrieved Controls for Auto-Cert..");
                  EventLogger({ vendorReview });

                  /**
                   * For each Vendor Certification Control, find the corresponding Vendor Review Control and update it.
                   */
                  for (const link of document?.vendorCertification?.controlLinks?.items ?? []) {
                    if (link && link.control) {
                      EventLogger(`Finding Existing control for Auto-Cert: ${JSON.stringify(link.control)}`);
                      const { name, statementNumber } = link.control;

                      const controlCategories = vendorReview?.controlCategories?.items || [];

                      const foundSubControl = findSubControl(statementNumber, name, controlCategories);

                      EventLogger(`Results form finding Existing Control: ${JSON.stringify(foundSubControl)}`);

                      if (foundSubControl) {
                        EventLogger(`Updating Sub Control: ${foundSubControl.id}`);
                        updateSubControl({
                          id: foundSubControl.id,
                          inPlace: true,
                          audited: true,
                          controlCategories,
                        });

                        CreateVendorControlDocumentLink({
                          document: document,
                          control: foundSubControl,
                        });
                      }
                    }
                  }

                  setStepStatus(2, "complete");
                });
                context.addToContext("certificationControls", controlArray);
              }
            });
        });
      });

      await Promise.all(fileUploadThreads).then(() => {
        resetFunction && resetFunction();
        toggleModal && toggleModal();
      });

      setCertificationId(null);
      setIsCertification(false);
      setName("");
      setFinished(true);
      setFiles([]);
    } else {
      setUploadMessage("Error! Can not upload Document!");
      EventLogger("Error! Can not upload Vendor Review Document. Missing vendorReview!");
    }
  };

  const handleFileChange = () => {
    setFiles(Array.from(document.getElementById("vendor-solution-documents").files));
  };

  const [certificationId, setCertificationId] = useState(null);
  const [isCertification, setIsCertification] = useState(false);

  const { listQuery: vendorCertificationListQuery } = generateGraphql("VendorCertification", ["name"]);

  const { list: vendorCertificationList } = useListQuery({
    query: vendorCertificationListQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const fields = { text: "name", value: "id" };

  // Resets the selected files in the input field once files state is cleared
  useEffect(() => {
    if (fileInputRef.current && (isNullOrUndefined(files) || (files && Array.isArray(files) && files.length === 0))) {
      fileInputRef.current.value = "";
    }
  }, [files]);
  const fileInputRef = useRef(null);

  const display = (
    <PermissionsOverlay
      module={module}
      resource={resource}
      operationType={"update"}
      disableRoleChecking={disableRoleChecking}
    >
      <div className="e-card" id="basic">
        {modal}
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-title">
              Upload a Document for <strong>{vendorReview && vendorReview.name}</strong> Vendor Review
            </div>
          </div>
        </div>
        <div className="e-card-content">
          <div style={{ padding: "0.5em" }}>
            <span>Pre-Qualified Certification</span>{" "}
            <SwitchComponent
              checked={isCertification}
              change={(res) => {
                if (res && res.checked === false) {
                  setCertificationId(null);
                }
                setIsCertification(res && res.checked);
              }}
            />
            <br />
            {isCertification && (
              <DropDownListComponent
                dataSource={vendorCertificationList}
                fields={fields}
                placeholder="Select Certification"
                change={({ itemData }) => setCertificationId(itemData && itemData.id)}
              />
            )}
          </div>
          <div style={{ padding: "0.5em" }}>
            <TextBoxComponent
              value={name}
              change={(res) => setName(res && res.value)}
              placeholder="Enter friendly name"
            />
          </div>
          <div style={{ padding: "0.5em" }}>
            <Label for="vendor-solution-documents">
              File(s) to Upload <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              id="vendor-solution-documents"
              innerRef={fileInputRef}
              type="file"
              multiple
              onChange={() => handleFileChange()}
              required
            />
          </div>
          <Progress animated={progressBar !== 100} value={progressBar}>
            {parseInt(progressBar)}%
          </Progress>
          {uploadMessage ? (
            <Alert color="primary" key="uploadMessage">
              {uploadMessage}
            </Alert>
          ) : null}
          <br />
          <ButtonGroup>
            <Button disabled={!isSubmitEnabled} onClick={() => onSubmit()} size="sm" color="primary">
              <i className="fa fa-dot-circle-o" />
              Upload
            </Button>
            <Button size="sm" color="danger" onClick={() => toggleModal && toggleModal()}>
              <i className="fa fa-ban" />
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </PermissionsOverlay>
  );

  return {
    display,
  };
};
