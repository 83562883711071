import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { getFunctionFieldName } from "@rivial-security/widget-utils";

/**
 * Modifies the outermost node's functions of the resultField tree, using the path to get to the end, modification occurs in place
 * @param {object[]} path - the path of resources to use (by resource name) to find the leaf node inside newResultFields
 * @param {object} newResultFields - the current result fields representing the query setup
 * @param {object} newResultFunctions - the current result functions representing the post-query transformation setup
 * @param {object[]} newLeafFunctions - the functions to set for the end of the path
 */
export const addLeafFunctionsToResultFields = ({ path, newResultFields, newResultFunctions, newLeafFunctions }) => {
  if (typeof newResultFields !== "object") {
    EventLogger("Something is wrong when updating selected functions!");
    return;
  }

  //Drill down to the leaf of the path to add selected functions
  if (Array.isArray(path) && path.length > 0) {
    const currentFieldName = path[0].name;
    if (!newResultFields.hasOwnProperty(currentFieldName)) {
      newResultFields[currentFieldName] = { ...path[0] };
    }
    const currentField = newResultFields[currentFieldName];

    if (typeof currentField?.fields !== "object") {
      currentField.fields = {};
    }

    if (typeof currentField?.functions !== "object") {
      currentField.functions = {};
    }

    addLeafFunctionsToResultFields({
      path: path.slice(1),
      newResultFields: currentField?.fields,
      newResultFunctions: currentField?.functions,
      newLeafFunctions,
    });
  } else {
    for (const functionFieldName of Object.keys(newResultFunctions)) {
      delete newResultFunctions[functionFieldName];
    }

    for (const leafFunction of newLeafFunctions) {
      const leafFunctionName = getFunctionFieldName({
        pathElement: leafFunction,
      });
      if (!leafFunctionName) {
        EventLogger("Something is wrong when updating functions, a function didn't have any name!");
        continue;
      }

      newResultFunctions[leafFunctionName] = leafFunction;
    }
  }
};
