import React, { useContext, useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { WorkflowContext } from "../../../../hooks/views/useWorkflow";
import { useUploadVendorReviewDocument } from "../hooks/useUploadVendorReviewDocument";
import { useVendorReviewDocuments } from "../hooks/useVendorReviewDocuments";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * @description Upload a Vendor Review Document component
 * @param organizationID {String} current selected organization - Required
 * @param resetFunction {function} reset parent state - Optional
 * @param toggleModal {function} close the parent modal - Optional
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {JSX.Element}
 * @constructor
 */

const UploadRelevantDocuments = ({
  organizationID,
  resetFunction,
  toggleModal,
  module = modules.VENDORS,
  resource = resources.VENDOR_REVIEW,
  disableRoleChecking,
}) => {
  const context = useContext(WorkflowContext);

  const [vendorReview, setVendorReview] = useState({});

  useEffect(() => {
    if (context.vendorReview && context.vendorReview.id) {
      setVendorReview(context.vendorReview);
    }
  }, [context.vendorReview]);

  const useVendorReviewDocumentsHook = useVendorReviewDocuments({
    vendorReview,
    organizationID,
    resetFunction,
    toggleModal,
    module,
    resource,
    disableRoleChecking,
    disableEdits: context?.readOnly,
  });

  const useUploadVendorReviewDocumentHook = useUploadVendorReviewDocument({
    vendorReview,
    organizationID,
    resetFunction: useVendorReviewDocumentsHook.resetFunction,
    toggleModal,
    context,
    module,
    resource,
    disableRoleChecking,
  });

  return (
    <div>
      {vendorReview && vendorReview.id ? (
        <>
          {context?.readOnly !== true && useUploadVendorReviewDocumentHook.display}
          {context?.readOnly !== true && <br />}
          {useVendorReviewDocumentsHook.display}
        </>
      ) : (
        "Vendor Review Not Found"
      )}
    </div>
  );
};

export default withOrganizationCheck(UploadRelevantDocuments);
