import React, { useContext } from "react";

import { ChartDataBuilderContext } from "../ChartWidgetDataGraphBuilder";
import { CreateWidgetContext } from "../../../../../useCreateWidget";
import { EventLogger } from "../../../../../../../../../../utils/EventLogger/EventLogger";
import { TreeItem } from "@mui/x-tree-view";
import { chartDataBuilderActions } from "../constants/chartDataBuilderActions";
import { createWidgetActions } from "../../../../../reducers/createWidgetActions";
import { getFunctionFieldName } from "@rivial-security/widget-utils";
import { getUnderlyingFieldType } from "../../../../../../../../../CustomQueries/functions/getUnderlyingFieldType";
import { isNonEmptyArray } from "@rivial-security/func-utils";
/**
 * A custom query tree item that is also selectable when field selection is active inside the widget data builder
 * @param {string[]} traversePath - path in the custom query to the current field
 * @param {string} customQueryId - the custom query id that this field belongs to
 * @param {string} nodeId - a unique id for the tree item
 * @param {JSX.Element} children - any children tree items
 * @param {object} field - the details about this field in the custom query
 * @param {object[]} path - a more detailed path to the current field in the custom query
 * @param {string} label - the text to display for the tree item
 * @param {object} props - any additional props to pass down to TreeItem
 * @returns {JSX.Element}
 */
const SelectableTreeItem = ({ traversePath, customQueryId, nodeId, children, field, path, label, ...props }) => {
  const { dispatch } = useContext(CreateWidgetContext);
  const { fieldSelection, dispatchChartDataBuilder } = useContext(ChartDataBuilderContext);

  const doesMatchFieldSelectionRequirements = () => {
    //Not selectable when field selection is not active
    if (!fieldSelection) {
      return false;
    }

    //Get field requirements
    const { includeTypes, allowMany, allowOne, requiredPath } = fieldSelection?.fieldRequirements || {};

    //All object fields currently cannot be selected
    if (field?.isNested) {
      return false;
    }

    const underlyingFieldType = getUnderlyingFieldType({ path, field });
    if (Array.isArray(includeTypes) && !includeTypes.includes(underlyingFieldType)) {
      return false;
    }

    //Check if path represents an array of items, limit selection based on requirements
    let hasMany = false;
    if (isNonEmptyArray(path)) {
      for (const pathElement of path) {
        if (pathElement?.hasMany) {
          hasMany = true;
          break;
        }
      }
    }
    if (!allowMany && !allowOne) {
      return false;
    }
    if (!allowOne && allowMany && !hasMany) {
      return false;
    }
    if (!allowMany && allowOne && hasMany) {
      return false;
    }

    //Check for sibling requirements
    if (Array.isArray(path) && isNonEmptyArray(requiredPath) && path.length >= requiredPath.length) {
      //Any path that's longer than required sibling path is not a sibling
      if (path.length > requiredPath.length + 1) {
        return false;
      }

      //Check the path elements to match
      for (let i = 0; i < requiredPath.length; i++) {
        const requiredElementName = requiredPath[i];
        const currentPathName = getFunctionFieldName({ pathElement: path[i] });
        if (requiredElementName !== currentPathName) {
          return false;
        }
      }
    }

    return true;
  };

  return (
    <TreeItem
      traversePath={traversePath}
      customQueryId={customQueryId}
      nodeId={nodeId}
      children={children}
      style={{
        backgroundColor: doesMatchFieldSelectionRequirements() ? "#CCFFDD" : "white",
      }}
      label={
        <div
          onClick={(event) => {
            event.stopPropagation();
            if (!doesMatchFieldSelectionRequirements()) {
              return;
            }
            const setPath = fieldSelection?.path;
            if (!isNonEmptyArray(setPath)) {
              EventLogger("Invalid set path given for selectable query tree item!");
              return;
            }

            const fieldsPath = fieldSelection?.path?.slice(0, -1);
            const customQueryFieldName = "customQueryId";
            const setFieldName = fieldSelection?.path?.slice(-1)[0];

            const setFieldValue = [];
            for (const pathElement of fieldsPath) {
              setFieldValue.push(getFunctionFieldName({ pathElement }));
            }

            dispatch({
              type: createWidgetActions.MERGE_SERIES_FIELDS,
              mergeSeriesInput: {
                seriesId: fieldSelection?.seriesItem?.id,
                path: fieldsPath,
                value: {
                  [customQueryFieldName]: customQueryId,
                  [setFieldName]: traversePath,
                },
              },
            });
            dispatchChartDataBuilder({
              type: chartDataBuilderActions.FINISH_FIELD_SELECTION,
            });
          }}
        >
          {label}
        </div>
      }
      {...props}
    />
  );
};

export default SelectableTreeItem;
