import "./styles/App.scss";

import { ConfirmSignUp, ForgotPassword, SignIn, withAuthenticator } from "aws-amplify-react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { ThemeProvider as ThemeProviderV4, ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import { applicationTheme, themeV4 } from "../Theme/applicationTheme";

import { ApolloProvider } from "react-apollo";
import AuthenticatedApp from "./AuthenticatedApp";
import { CustomSignIn } from "@utils/SignIn/components/CustomSignIn";
import GuestApp from "./GuestApp";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { MUI_LICENSE_KEY } from "../../env-config";
import { ReactElement } from "react";
import { initializeAppsyncClient } from "./functions/initializeAppsyncClient";
import { withGuestAuthentication } from "./hoc/withGuestAuthentication/withGuestAuthentication";
import { FeatureFlagsProvider } from "../../featureFlags/FeatureFlagsProvider";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: "mui-jss",
});

const client = initializeAppsyncClient();

const WrappedGuestApp = withGuestAuthentication(GuestApp);

const AuthenticatedAppWithApolloClient = ({ onStateChange }): ReactElement => (
  <AuthenticatedApp client={client} onStateChange={onStateChange} />
);

const WrappedAuthenticatedApp = withAuthenticator(AuthenticatedAppWithApolloClient, false, [
  // @ts-expect-error Thinks it's not a valid component
  <CustomSignIn override={SignIn} key={0} />,
  // @ts-expect-error Thinks it's not a valid component
  <ForgotPassword key={1} />,
  // @ts-expect-error Thinks it's not a valid component
  <ConfirmSignUp key={2} />,
]);

const App = (): ReactElement => (
  <FeatureFlagsProvider>
    <StylesProvider generateClassName={generateClassName}>
      {/* @ts-expect-error Thinks it's not a valid component */}
      <ApolloProvider client={client}>
        <ThemeProviderV4 theme={themeV4}>
          <ThemeProviderV5 theme={applicationTheme}>
            <HashRouter>
              <Switch>
                <Route path={"/guest/"} component={WrappedGuestApp} />
                <Route path={"/"} component={WrappedAuthenticatedApp} />
              </Switch>
            </HashRouter>
          </ThemeProviderV5>
        </ThemeProviderV4>
      </ApolloProvider>
    </StylesProvider>
  </FeatureFlagsProvider>
);

export default App;
