/**
 * Author: Jacob Blazina
 * Created At: 10/1/2019
 * Edits:
 *
 * Description: A Function.
 *              Deletes a Maturity Column from the DB.
 *              First Deletes all Associated Maturity Levels to avoid NULL issues.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import DeleteMaturityMatrixLevel from "./DeleteMaturityMatrixLevel";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteMaturityColumn } = generateGraphql("MaturityColumn", ["name"]);

const DeleteMaturityColumn = (maturityColumn) => {
  if (
    maturityColumn.maturityMatrixLevels &&
    maturityColumn.maturityMatrixLevels.items &&
    maturityColumn.maturityMatrixLevels.items.length > 0
  ) {
    maturityColumn.maturityMatrixLevels.items.forEach((maturityMatrixLevel) => {
      DeleteMaturityMatrixLevel(maturityMatrixLevel);
    });
  }

  API.graphql(
    graphqlOperation(deleteMaturityColumn, {
      input: {
        id: maturityColumn.id,
      },
    }),
  ).then(EventLogger(`maturityColumn: ${maturityColumn.id} was Successfully Deleted`));
};

export default DeleteMaturityColumn;
