import { deepDelete } from "../../../../utils/Functions/deepDelete";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Deletes an Action Item
 * @param {object} actionItem - the Action Item to delete
 * @param {string} actionItem.id - the ID of the Action Item to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteRecommendations - determines if we should delete all Recommendations associated with this Action Item
 * @param {boolean} config.deleteObservations - pass through for the deleteRecommendation function
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<*>}
 */
export const deleteActionItem = async (actionItem, config = {}, onJobStarted) => {
  const { deleteRecommendations = false, deleteObservations = false } = config;

  if (!actionItem?.id) {
    EventLogger("[deleteActionItem.js] Missing Action Item ID");
    return;
  }

  /**
   * Delete Action item in Fargate
   */
  return await deepDelete({
    typename: "ActionItem",
    item: actionItem,
    config: {
      deleteRecommendations,
      deleteObservations,
    },
    onJobStarted,
  });
};
