import cronParser from "cron-parser";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * @description Get difference in days between 2 dates
 * @param {object} evidence
 * @returns {number}
 */
export const getDifferenceInDays = (evidence) => {
  try {
    /**
     * Calculate dueDate based on the cron expression
     */
    const interval = cronParser.parseExpression(evidence.frequency);

    /**
     * Calculate prev date based on the cron expression
     */
    const prevDate = interval.prev();

    /**
     * Calculate next date based on the cron expression
     */
    const nextDate = interval.next();

    const differenceInTime = nextDate.getTime() - prevDate.getTime();

    /**
     * To calculate the number of days between two dates
     */
    return differenceInTime / (1000 * 3600 * 24);
  } catch (e) {
    EventLogger("Error! Can not calculate the number of days between two dates");
  }
};
