import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useStateEffect } from "../../../functional/useStateEffect";

/**
 * State and effect manager for current CSV headers when the CSV input file changes.
 * Assumes first row of the file is headers
 * @param {object[]} input - array of objects representing the CSV file rows. First Row must be a header
 */
export const useCurrentHeaders = (input) =>
  useStateEffect([], [input], () => {
    EventLogger("CSV Input Changed, Found Headers:");
    const res = [];
    if (!isNullOrUndefined(input[0])) {
      for (const key in input[0]) {
        res.push(key);
      }
    }
    EventLogger(res);
    return [...res];
  });

export const getCurrentHeaders = (input) => {
  const res = [];
  if (!isNullOrUndefined(input[0])) {
    for (const key in input[0]) {
      res.push(key);
    }
  }
  EventLogger(res);
  return [...res];
};
