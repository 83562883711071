import { generateGraphql } from "@rivial-security/generategraphql";
import { deleteItem } from "../../../../../../../utils/Functions/Graphql/deleteItem";
import { submitRiskComplianceSyncJob } from "../../../../../../Compliance/Evidence/functions/submitRiskComplianceSyncJob";
import { updateControlOverrideType } from "../../../../../../Compliance/Evidence/enums/updateControlOverrideType";
import { EventLogger } from "../../../../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../../../../utils/Functions/Graphql/GetQuery";

/**
 * Deletes a single link between risk controls and evidence
 * @param {object} link - the link to delete with an id property defined
 * @param {object} [evidence] - if passed in, will be used as the evidence that has triggered the control changes
 * @param {object} organizationID - the organization that hosts the link
 * @return {Promise<null|void>}
 */
export const deleteRiskControlEvidenceLink = async (link, evidence = null, organizationID) => {
  //Cancel the link deletion if there is no link id
  if (!link?.id) {
    EventLogger("Theres is no valid link id to perform deleteRiskControlEvidenceLink!");
    return;
  }

  //Retrieve the link data from the database for the manual control check
  const { getQuery: getLinkQuery } = generateGraphql("RiskControlEvidenceLink", ["evidenceID", "riskControlID"]);
  const linkData = await GetQuery({
    query: getLinkQuery,
    variables: {
      id: link.id,
    },
  });

  //Perform the link deletion
  const { getQuery } = generateGraphql("RiskControlEvidenceLink", ["__typename", "riskControlID"]);
  const deleteResponse = await deleteItem(getQuery, link);

  //Find the evidence id
  let evidenceID = evidence?.id;
  if (!evidenceID) {
    evidenceID = linkData?.evidenceID;
  }

  //Find the risk control id
  const riskControlID = linkData?.riskControlID;

  // Check if the Evidence is enabled. If not, skip the riskComplianceSyncJob
  if (evidence?.enabled) {
    if (riskControlID) {
      submitRiskComplianceSyncJob({
        evidenceID,
        organizationID: organizationID || evidence?.ownerGroup,
        riskControlOverrideID: riskControlID,
        overrideType: updateControlOverrideType.EVIDENCE_UNLINKED,
      });
    } else {
      EventLogger(
        `Cannot check control upon deleting evidence risk control link!${JSON.stringify({
          linkData,
          evidence,
        })}`,
      );
    }
  }

  return deleteResponse;
};
