import { getIcon } from "../../../../utils/Functions/Icon/getIcon";

export const TRIGGER_TYPES = {
  MANUAL: "manual",
  RESOURCE_UPDATED: "resourceUpdated",
  RESOURCE_CREATED: "resourceCreated",
  SCHEDULED: "scheduled",
  WEBHOOK: "webhook",
};

export const AUTOMATION_TYPES = {
  GENERATE_REPORT: "generateReport",
  SEND_NOTIFICATION: "sendNotification",
  CREATE_RESOURCE: "createResource",
  UPDATE_RESOURCE: "updateResource",
  WEBHOOK: "webhook",
  HTTP: "http",
  LOOP: "loop",
  CUSTOM_QUERY: "customQuery",
};

export const AUTOMATION_TYPES_CONFIG = {
  [AUTOMATION_TYPES.GENERATE_REPORT]: {
    type: AUTOMATION_TYPES.GENERATE_REPORT,
    icon: getIcon("heroicons-outline:document-report"),
    text: "Generate Report",
    helpCenterUrl:
      "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-ReportAutomation",
  },
  [AUTOMATION_TYPES.SEND_NOTIFICATION]: {
    type: AUTOMATION_TYPES.SEND_NOTIFICATION,
    icon: getIcon("ic:outline-email"),
    text: "Email",
    helpCenterUrl:
      "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-NotificationAutomation",
  },
  [AUTOMATION_TYPES.HTTP]: {
    type: AUTOMATION_TYPES.HTTP,
    icon: getIcon("carbon:webhook"),
    text: "HTTP",
    helpCenterUrl:
      "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-WebRequestAutomation",
  },
  [AUTOMATION_TYPES.CREATE_RESOURCE]: {
    type: AUTOMATION_TYPES.CREATE_RESOURCE,
    icon: getIcon("gridicons:create"),
    text: "Create Resource",
    helpCenterUrl:
      "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-CreateResourceAutomation",
  },
  [AUTOMATION_TYPES.UPDATE_RESOURCE]: {
    type: AUTOMATION_TYPES.UPDATE_RESOURCE,
    icon: getIcon("akar-icons:edit"),
    text: "Update Resource",
    helpCenterUrl:
      "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-UpdateResourceAutomation",
  },
  [AUTOMATION_TYPES.LOOP]: {
    type: AUTOMATION_TYPES.LOOP,
    icon: getIcon("line-md:downloading-loop"),
    text: "Loop",
    helpCenterUrl: "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-LoopAutomation",
  },
  [AUTOMATION_TYPES.CUSTOM_QUERY]: {
    type: AUTOMATION_TYPES.CUSTOM_QUERY,
    icon: getIcon("icon-park-outline:history-query"),
    text: "Custom Query",
    helpCenterUrl: "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-LoopAutomation",
  },
};
