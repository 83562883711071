import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

/**
 * Deletes a single report template item from database
 * @param {object} reportTemplate  - the item to delete, must have id
 * @return {Promise<*>}
 */
export const deleteReportTemplate = async (reportTemplate) => {
  //Check arguments
  if (!reportTemplate?.id) {
    EventLogger("Cannot delete report template because no ID was found on passed in parameter!");
    return;
  }

  //Delete on the front end
  const { deleteMutation } = generateGraphql("ReportTemplate");
  const deleteResult = await ItemMutation(deleteMutation, {
    id: reportTemplate.id,
  });

  //TODO: Perform delete mutation on backend - enable when iam is enabled for ReportTemplate type
  // const {getQuery: connectionsQuery} = generateGraphql("ReportTemplate", ["__typename"]);
  // const deleteResult = await QueryGetItem({
  //   query: connectionsQuery,
  //   itemId: reportTemplate?.id
  // }).then(async (reportTemplate) => {
  //   EventLogger("Deleting reportTemplate - " + reportTemplate?.id);
  //   await GenericDeleteGQL({objectToDelete: reportTemplate});
  // });

  return deleteResult;
};
