import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a MeetingTagLink object
 * @param {object} meeting - the meeting to which to attach the tag
 * @param {object} tag - the tag that needs to be attached to the meeting
 * @param {string} organizationID - the organization identifier of the meeting in the database
 * @return {Promise<*>}
 */
export const createMeetingTagLink = (meeting, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(meeting?.id)) {
    EventLogger("Invalid input!");
  }

  const { createMutation: createMeetingTagLink } = generateGraphql("MeetingTagLink", [
    "meetingID",
    "tagID",
    "__typename",
  ]);

  return ItemMutation(createMeetingTagLink, {
    meetingID: meeting.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
