import React, { useEffect, useState } from "react";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import AddSingleImplementationLevel from "../../ImplementationLevel/components/AddSingleImplementationLevel";
import { useMutation } from "../../../../../../hooks/graphql/useMutation/useMutation";
import { CustomPointOfContactFieldWithoutEvidenceChange } from "../../ProgramElement/components/CustomPointOfContactFieldWithoutEvidenceChange";
import { useQueryGetItem } from "../../../../../../hooks/graphql/useQueryGetItem";
import ImplementationLevelDetailsCard from "../../ImplementationLevel/components/ImplementationLevelDetailsCard";
import { DeleteImplementationLevel } from "../../ImplementationLevel/functions/DeleteImplementationLevel";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";

const { updateMutation: updateImplementationLevel } = generateGraphql("ImplementationLevel");

const { updateMutation: updateProgramSection } = generateGraphql("ProgramSection");

const { getQuery: getProgramSection } = generateGraphql(
  "ProgramSection",
  ["currentLevel", "goalLevel", "implementationLevels"],
  {
    currentLevel: `{ id level indicator frequency pointOfContact { id firstName lastName} }`,
    goalLevel: `{ id level indicator frequency }`,
    implementationLevels: `(limit: 100) { items { id level indicator frequency } }`,
  },
);

/**
 * Displays details of a ProgramSection
 *
 * @param itemId: id of a ProgramSection
 * @param resetFunction: Reset parent Program Blueprint
 * @param {boolean} [isTemplate = false] - determines if this UI needs to be trimmed down to display as a Template
 * @returns {*}
 * @constructor
 */

const ProgramSectionDetails = ({
  itemId,
  resetFunction,
  isTemplate = false,
  module = modules.GOVERNANCE,
  resource = resources.PROGRAM_SECTION,
  disableRoleChecking = false,
}) => {
  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const checkPermissionsImplementationLevel = useCheckPermissions({
    module,
    resource: resources.IMPLEMENTATION_LEVEL,
    disableRoleChecking,
  });

  const getProgramSectionHook = useQueryGetItem({
    query: getProgramSection,
    itemId: itemId,
    module,
    resource,
    disableRoleChecking,
  });

  const [currentLevelDropDown, setCurrentLevelDropDown] = useState("");
  const [goalLevelDropDown, setGoalLevelDropDown] = useState("");
  const [implementationLevels, setImplementationLevels] = useState([]);
  const [programSection, setProgramSection] = useState(null);

  const currentLevel = programSection && programSection.currentLevel;
  const goalLevel = programSection && programSection.goalLevel;

  useEffect(() => {
    if (getProgramSectionHook && getProgramSectionHook.item) {
      setProgramSection(getProgramSectionHook.item);
    }
  }, [getProgramSectionHook.item]);

  useEffect(() => {
    if (programSection && programSection.implementationLevels && programSection.implementationLevels.items) {
      setImplementationLevels(programSection.implementationLevels.items);
    }
  }, [programSection && programSection.implementationLevels && programSection.implementationLevels.items]);

  useEffect(() => {
    if (programSection && programSection.currentLevel) {
      setCurrentLevelDropDown(`${programSection.currentLevel.level} - ${programSection.currentLevel.indicator}`);
    }

    if (programSection && programSection.goalLevel) {
      setGoalLevelDropDown(`${programSection.goalLevel.level} - ${programSection.goalLevel.indicator}`);
    }
  }, [programSection]);

  const programSectionMutation = useMutation({
    mutation: updateProgramSection,
    module,
    resource,
    disableRoleChecking: true,
    typename: "Program Section",
  });

  const implementationLevelMutation = useMutation({
    mutation: updateImplementationLevel,
    module,
    resource: resources.IMPLEMENTATION_LEVEL,
    disableRoleChecking: true,
    typename: "Implementation Level",
  });

  const updateLevel = (e, levelTypeId) => {
    const dataset = e.target.options[e.target.selectedIndex].dataset;
    let levelLocal = "";

    try {
      levelLocal = JSON.parse(dataset.tag);
    } catch (e) {
      EventLogger("Error! Can not parse JSON Program Section Details");
    }

    if (levelTypeId === "programSectionCurrentLevelId") {
      if (levelLocal) {
        setCurrentLevelDropDown(`${levelLocal.level} - ${levelLocal.indicator}`);

        const pointOfContactId =
          programSection.currentLevel &&
          programSection.currentLevel.pointOfContact &&
          programSection.currentLevel.pointOfContact.id;

        if (levelLocal && levelLocal.id) {
          implementationLevelMutation
            .editItem({
              id: levelLocal.id,
              implementationLevelPointOfContactId: pointOfContactId ? pointOfContactId : undefined,
            })
            .then(() => {
              resetFunction && resetFunction();
              getProgramSectionHook.reset();
            });
        }

        programSection["currentLevel"] = levelLocal ? levelLocal : undefined;
      } else {
        setCurrentLevelDropDown("None");
      }
    } else if (levelTypeId === "programSectionGoalLevelId") {
      if (levelLocal) {
        setGoalLevelDropDown(`${levelLocal.level} - ${levelLocal.indicator}`);
        programSection["goalLevel"] = levelLocal ? levelLocal : undefined;
      } else {
        setGoalLevelDropDown("None");
      }
    }

    programSectionMutation
      .editItem({
        id: programSection && programSection.id,
        [levelTypeId]: levelLocal && levelLocal.id ? levelLocal.id : null,
      })
      .then(() => {
        resetFunction && resetFunction();
        getProgramSectionHook.reset();
      });
  };

  const AddSingleImplementationLevelHook = useModal(
    "Add a Program Blueprint Level",
    <AddSingleImplementationLevel
      resetFunction={getProgramSectionHook.reset}
      programSection={programSection}
      organizationID={programSection?.ownerGroup}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
    />,
    <Button size="sm" color="ghost-success" className="btn-pill" title="Create new Implementation Level">
      <i className="icon-plus" />
      {""} Add a Program Blueprint Level
    </Button>,
    {},
  );

  const UpdateCurrentImplementationLevelHook = useModal(
    "Update Current Program Blueprint Level",
    <ImplementationLevelDetailsCard
      item={programSection && programSection.currentLevel}
      isTemplate={isTemplate}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
    />,
    <Button
      size="sm"
      color="ghost-warning"
      className="btn-pill"
      title={currentLevel ? "Edit Program Blueprint Level" : "Please select a current Level"}
      disabled={!currentLevel}
    >
      <i className="icon-pencil" />
    </Button>,
    {},
  );

  const UpdateGoalImplementationLevelHook = useModal(
    "Update Goal Program Blueprint Level",
    <ImplementationLevelDetailsCard
      item={programSection && programSection.goalLevel}
      isTemplate={isTemplate}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
    />,
    <Button
      size="sm"
      color="ghost-warning"
      className="btn-pill"
      title={goalLevel ? "Edit Program Blueprint Level" : "Please select a goal level"}
      disabled={!goalLevel}
    >
      <i className="icon-pencil" />
    </Button>,
    {},
  );

  useEffect(() => {
    if (
      UpdateCurrentImplementationLevelHook.modalIsOpen === false ||
      UpdateGoalImplementationLevelHook.modalIsOpen === false
    ) {
      getProgramSectionHook.reset();
      resetFunction && resetFunction();
    }
  }, [UpdateCurrentImplementationLevelHook.modalIsOpen || UpdateGoalImplementationLevelHook.modalIsOpen]);

  const implementationLevelDeleteHook = useModal(
    "Delete Program Blueprint Level",
    <div>
      {implementationLevels && Array.isArray(implementationLevels) && implementationLevels.length > 0 ? (
        implementationLevels.map((item, index) => (
          <p key={index}>
            {item.level ? item.level : "No Level"} - {item.indicator ? item.indicator : "No Indicator"}
            <Button
              size="sm"
              color="ghost-danger"
              className="float-right btn-pill"
              title="Delete Program Blueprint Level"
              onClick={() => {
                if (window.confirm(`Are you sure you want to remove ${item && item.indicator}?`)) {
                  if (item && item.id) {
                    DeleteImplementationLevel(item).then(() => {
                      const index = implementationLevels.findIndex((level) => level.id === item.id);
                      if (index > -1) {
                        implementationLevels.splice(index, 1);
                      }
                      resetFunction && resetFunction();
                    });
                  }
                }
              }}
            >
              <i className="icon-trash" />
            </Button>
            <hr />
          </p>
        ))
      ) : (
        <h6>No Program Blueprint Levels to Delete</h6>
      )}
    </div>,
    <Button size="sm" color="ghost-danger" className="float-right btn-pill" title="Delete Program Blueprint Levels">
      <i className="icon-trash" />
    </Button>,
    {},
  );

  return (
    <div>
      <span className="float-right" style={{ marginBottom: "1em" }}>
        {checkPermissionsImplementationLevel.resource.create && AddSingleImplementationLevelHook.modalButton}
        <Button
          size="sm"
          color="ghost-secondary"
          className="btn-pill"
          onClick={() => {
            getProgramSectionHook.reset && getProgramSectionHook.reset();
          }}
          title="Refresh Card Data"
        >
          <i className="icon-reload" />
        </Button>
        {checkPermissionsImplementationLevel.resource.delete && implementationLevelDeleteHook.modalButton}
      </span>
      <br />
      <h5>Program Blueprint Level</h5>
      <InputGroup>
        <InputGroupAddon addonType="prepend">Current Level:</InputGroupAddon>
        <>
          <Input
            type="select"
            value={currentLevelDropDown}
            disabled={checkPermissions.resource.update ? undefined : "disabled"}
            onChange={(e) => updateLevel(e, "programSectionCurrentLevelId")}
          >
            <option value={""}>None</option>
            {implementationLevels
              ? implementationLevels
                  .sort((a, b) => a.level - b.level)
                  .map((implementationLevel) => (
                    <option
                      value={`${implementationLevel.level} - ${implementationLevel.indicator}`}
                      data-tag={JSON.stringify(implementationLevel)}
                      key={JSON.stringify(implementationLevel)}
                    >
                      {implementationLevel.level ? implementationLevel.level : "No Level"} -{" "}
                      {implementationLevel.indicator ? implementationLevel.indicator : "No Indicator"}
                    </option>
                  ))
              : null}
          </Input>
          {checkPermissions.resource.update ? <>{UpdateCurrentImplementationLevelHook.modalButton}</> : null}
        </>
      </InputGroup>
      <br />
      <InputGroup>
        <InputGroupAddon addonType="prepend">Goal Level:</InputGroupAddon>
        <>
          <Input
            type="select"
            value={goalLevelDropDown}
            disabled={checkPermissions.resource.update ? undefined : "disabled"}
            onChange={(e) => updateLevel(e, "programSectionGoalLevelId")}
          >
            <option value={""}>None</option>
            {implementationLevels
              ? implementationLevels
                  .sort((a, b) => a.level - b.level)
                  .map((implementationLevel) => (
                    <option
                      value={`${implementationLevel.level} - ${implementationLevel.indicator}`}
                      data-tag={JSON.stringify(implementationLevel)}
                      key={JSON.stringify(implementationLevel)}
                    >
                      {implementationLevel.level ? implementationLevel.level : "No Level"} -{" "}
                      {implementationLevel.indicator ? implementationLevel.indicator : "No Indicator"}
                    </option>
                  ))
              : null}
          </Input>
          {checkPermissions.resource.update ? <>{UpdateGoalImplementationLevelHook.modalButton}</> : null}
        </>
      </InputGroup>
      <br />
      {!isTemplate && (
        <>
          {programSection?.currentLevel ? (
            <div>
              <p>
                Responsible:{" "}
                <CustomPointOfContactFieldWithoutEvidenceChange
                  item={programSection?.currentLevel}
                  resetFunction={getProgramSectionHook.reset}
                  module={module}
                  resource={resource}
                  field="pointOfContact"
                />
              </p>
              <p>
                Frequency:{" "}
                <GenericEditField
                  inputType="number"
                  item={programSection?.currentLevel}
                  module={module}
                  resource={resource}
                  field="frequency"
                  mutation={updateImplementationLevel}
                  minInputNumber={1}
                  maxInputNumber={365}
                  prefix={"every"}
                  suffix={"day"}
                  suffixIsNoun={true}
                />
              </p>
            </div>
          ) : (
            <p style={{ color: "grey" }}>
              <strong>Note: </strong>
              'Frequency' and 'Responsible' fields can only be set after selecting a current Implementation Level
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withDetailsPage(ProgramSectionDetails);
