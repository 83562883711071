import React from "react";
import { useThreatDataGrid } from "../../Risk/RiskConfig/Threats/hooks/useThreatDataGrid";
import ThreatTemplateDetails from "./ThreatTemplateDetails";
import ThreatTemplateForm from "./ThreatTemplateForm";

/**
 * Displays a list of Threat Templates
 *
 * @param {string} organizationID - ownerGroup query ID
 * @param {object} props - all props passed to data grid hook
 * @returns {JSX.Element}
 * @constructor
 */
const ThreatTemplateGrid = ({ organizationID, ...props }) => {
  const grid = useThreatTemplateGrid({ organizationID, ...props });

  return <div style={{ height: "75vh" }}>{grid.display}</div>;
};

export const useThreatTemplateGrid = ({ organizationID, ...props }) => {
  const grid = useThreatDataGrid({
    organizationID,
    detailsTitle: "Threat Template Details",
    createItemModalHeader: "Create a Threat Template",
    detailsComponent: <ThreatTemplateDetails organizationID={organizationID} />,
    createResourceComponent: <ThreatTemplateForm organizationID={organizationID} />,
    ...props,
  });

  return grid;
};

export default ThreatTemplateGrid;
