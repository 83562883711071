import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";

/**
 * @description Send request to cognitoRoleGroups lambda
 * @param {object} input - input object
 * @example

 [Create Role]
 const input = {
  type: "createRole"
  role: {
    name: String
    description: String
    precedence: Int
    roleConfig: AWSJSON
    ownerGroup: String
  }
 }

 [Delete Role]
 const input = {
  type: "deleteRole",
  role: {
    id: String
  }
 }

 [Assign User(s) to Role]
 const input = {
  type: "assignUsers",
  userIDs: [String],
  role: {
    id: String,
    ownerGroup: String
  }
 }

 [Un-Assign User(s) from Role]
 const input = {
  type: "unAssignUsers",
  userIDs: [String],
  role: {
    id: String
  }
 }

 * @returns {Promise<Array>}
 */
export const cognitoRoleGroups = async (input) => {
  const cognitoRoleGroupsGraphql = `
    query cognitoRoleGroups($input: AWSJSON) {
      cognitoRoleGroups(input: $input)
    }
  `;

  EventLogger(`Sending request to cognitoRoleGroups lambda. Input: ${JSON.stringify(input)}`);

  return GetQuery({
    query: cognitoRoleGroupsGraphql,
    variables: {
      input: JSON.stringify(input),
    },
  });
};
