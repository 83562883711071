import { EventLogger } from "../EventLogger/EventLogger";
import { fargateApi } from "./FargateApi/fargateApi";
import { createJob } from "../../views/OrganizationManager/Jobs/functions/createJob";

/**
 * Generic deep delete function
 * @param {object} item - item to delete
 * @param {string} typename - typename to delete
 * @param {object} config - config for the delete
 * @param {string} organizationID - organization id
 * @param {string} userEmail - user email
 * @param {string} sentryTrace - sentry trace
 * @param {function} onJobStarted - callback function to get job
 * @param {boolean} useQueue - whather to use a queue system or not (default: true)
 * @returns {Promise<null|Array|*>}
 */
export const deepDelete = async ({
  item,
  typename,
  config = {},
  organizationID = item?.ownerGroup,
  userEmail,
  sentryTrace,
  onJobStarted,
  useQueue = true,
}) => {
  if (!item || !typename) {
    EventLogger("Error! Invalid input for deepDelete. Missing item or the resource name.");
    return;
  }

  let job;

  if (organizationID) {
    job = await createJob({
      name: `Delete ${item?.name || `${typename}: ${item?.id}`}`,
      logs: JSON.stringify([
        {
          type: "info",
          title: `Delete operation started ${userEmail ? `by: ${userEmail}` : ""}`,
          timestamp: new Date().toISOString(),
        },
      ]),
      status: "started",
      ownerGroup: organizationID,
    });

    if (typeof onJobStarted === "function") {
      await onJobStarted({ job });
    }
  }

  return await fargateApi({
    input: {
      route: `delete/${typename}`,
      item,
      config,
      job,
      sentryTrace,
      typename,
      organizationID,
      useQueue,
    },
  });
};
