import React from "react";

const ArticleDetails = React.lazy(() => import("../../views/HelpCenter/components/Articles/ArticleDetails"));

/**
 * @description Routes to the Help Center module
 */
export const helpCenterRoutes = [
  {
    path: "/help-center/articles/:id",
    exact: true,
    name: "Article Details",
    component: ArticleDetails,
  },
];
