import { Col, Input, Label } from "reactstrap";
import React, { useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../EventLogger/EventLogger";
import ForgotPassword from "./ForgotPassword";
import { GoogleRecaptchaSiteKey } from "../../../env-config";
import LoginButton from "./LoginButton";
import LoginButtonSeparator from "./LoginButtonSeparator";
import ReCAPTCHA from "react-google-recaptcha";
import ResetPasswordForm from "./ResetPasswordForm";
import { ssoProviderIcons } from "@views/OrganizationManager/GeneralConfig/components/SsoProviders/constants/ssoProviderConstants";
import { useFeatureFlagsContext } from "../../../featureFlags/FeatureFlagsProvider";

/**
 * Allow user to authenticate themselves and enter into the app
 * @param {boolean} isLoading - if TRUE blocks clicking email and password login
 * @param {function} onSignIn - callback when user attempts to sign in with email and password
 * @param {function} onError - callback when sign in has failed, to report reason to user
 * @return {JSX.Element}
 */
const Login = ({ isLoading, onSignIn, onError }) => {
  const allowSSOLogin = true;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [reCaptcha, setReCaptcha] = useState(false);

  const { flags } = useFeatureFlagsContext();

  const isSubmitEnabled = reCaptcha && username && password && !isLoading;
  const isFederatedSignInEnabled = !isLoading;

  const ssoSignIn = async () => {
    try {
      await Auth.federatedSignIn();
    } catch (e) {
      EventLogger("[Login] Failed to sign in with SSO provider: ", e.toString());
      if (typeof onError === "function") {
        onError("Failed to sign in with SSO provider");
      }
    }
  };

  return (
    <>
      {isForgotPassword ? (
        <ForgotPassword setIsForgotPassword={setIsForgotPassword} />
      ) : (
        <>
          <Col md={12}>
            <Label htmlFor="username">Email</Label>
            <Input
              className="appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              key="username"
              name="username"
              type="email"
              autoFocus={true}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Col>
          <p></p>
          <Col md={12}>
            <Label htmlFor="password">Password</Label>
            <Input
              className="appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              key="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Col>
          <Col md={12} className="text-sm-right">
            <p className="text-grey-dark text-xs">
              Forgot your password?
              <ResetPasswordForm username={username} setIsForgotPassword={setIsForgotPassword} />
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1em",
              }}
            >
              <ReCAPTCHA
                sitekey={GoogleRecaptchaSiteKey}
                onChange={(data) => {
                  setReCaptcha(true);
                }}
              />
            </div>
            <div>
              <LoginButton
                text={"Login"}
                buttonColor={"#83C6E3"}
                textColor={"black"}
                disabled={!isSubmitEnabled}
                title={"Login using email and password"}
                onClick={() => onSignIn(username?.toLowerCase(), password)}
              />
              {allowSSOLogin && (
                <>
                  <LoginButtonSeparator />
                  <LoginButton
                    startIcon={ssoProviderIcons.AZURE}
                    text={"Continue with Microsoft Azure AD"}
                    buttonColor={"#CED4DA"}
                    buttonBorder={"1px solid #CED4DA"}
                    disabled={!isFederatedSignInEnabled}
                    textColor={"black"}
                    variant={"outlined"}
                    onClick={ssoSignIn}
                  />
                  {flags?.isOktaSsoUiEnabled && (
                    <LoginButton
                      startIcon={ssoProviderIcons.OKTA}
                      text={"Continue with Okta"}
                      buttonColor={"#CED4DA"}
                      buttonBorder={"1px solid #CED4DA"}
                      disabled={!isFederatedSignInEnabled}
                      textColor={"black"}
                      variant={"outlined"}
                      onClick={ssoSignIn}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
        </>
      )}
    </>
  );
};

export default Login;
