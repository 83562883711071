import { Button } from "@mui/material";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import React from "react";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getProceduresNestedFields } from "../functions/getProceduresNestedFields";

/**
 * Component allowing to select one of two options to create the first procedure
 * @param {function} openPlaybookBuilder - called when user chooses to use builder
 * @param {string} typename - the resource name for which the playbook is being built (ie. Exercise, Incident)
 * @param {object} item - the resource object with an id field for which to update the procedures
 * @param {function} resetFunction - callback to call to refresh 'item'
 * @return {JSX.Element}
 */
const NoPlaybookAvailable = ({ openPlaybookBuilder, typename, item, resetFunction }) => {
  const startFromScratch = async () => {
    if (!Array.isArray(item?.procedures) || item?.procedures.length === 0) {
      try {
        const { updateMutation } = generateGraphql(typename, ["procedures"], getProceduresNestedFields());

        const input = {
          id: item?.id,
          procedures: {
            parentID: item?.id,
            parentTypename: typename,
            name: `General: ${typename}`,
          },
        };

        await ItemMutation(updateMutation, input);

        resetFunction && resetFunction();
      } catch (e) {
        EventLogger(`Failed to create a general ${typename} procedure group - ${e.message}`);
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p>
        A Playbook has not been configured for this {typename ? convertCamelCaseToSentence(typename) : "item"}. Please
        select what you would like to do.{" "}
      </p>
      <div style={{ display: "flex" }}>
        {openPlaybookBuilder && (
          <Button
            color={"primary"}
            title={"Select the Playbooks to copy from various Response Plan resources."}
            onClick={openPlaybookBuilder}
          >
            Use the Playbook Builder
          </Button>
        )}
        {openPlaybookBuilder && startFromScratch && <p style={{ margin: "1em", fontWeight: "bold" }}>OR</p>}
        {startFromScratch && (
          <Button title={"Create procedure steps for this Playbook manually."} onClick={startFromScratch}>
            Create a Playbook from Scratch
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoPlaybookAvailable;
