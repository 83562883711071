import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useCreateKeyPerformanceIndicator } from "../hooks/useCreateKeyPerformanceIndicator";

/**
 * Component instance of the useCreateKeyPerformanceIndicator hook
 * @param {object} props
 * @param {function} [props.toggleModal]
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateKeyPerformanceIndicator = (props) => {
  return useCreateKeyPerformanceIndicator(props).display;
};

export default withOrganizationCheck(CreateKeyPerformanceIndicator);
