import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generatePresignedUrlsLambda } from "../../../../utils/Functions/S3Storage/generatePresignedUrlsLambda";
import { S3OperationType } from "../../../../utils/Functions/S3Storage/constants/S3OperationType";

/**
 * Retrieves base 64 version of the organization logo (if one exists)
 * @param {string} s3FileName - the last section of the key in s3 that the file needs to uploaded into
 * @param {string} organizationID - the organization for which to retrieve the logo
 * @return {Promise<string|null>} - if exists the base 64 encoded version of the org logo, if doesn't null
 */
export const getOrganizationLogoData = async ({ s3FileName, organizationID }) => {
  try {
    // - check arguments
    if (!s3FileName || !organizationID) {
      return null;
    }

    const generatePresignedUrlsResult = await generatePresignedUrlsLambda({
      route: "organization/brandingUpdate",
      files: [{ name: s3FileName }],
      operationType: S3OperationType.GET_OBJECT,
      operationData: {
        s3FileName,
      },
    });
    const presignedUrls = generatePresignedUrlsResult?.presignedUrls;
    if (!presignedUrls) {
      EventLogger("No presigned logo url found.");
      return null;
    }

    return presignedUrls?.[s3FileName]?.url;
  } catch (e) {
    EventLogger("Could not retrieve organization logo", e);
  }

  return null;
};
