import { isNonEmptyArray } from "@rivial-security/func-utils";
import { handleFileUploads } from "../../../../utils/GenericComponents/Validation/FileValidation/FileUploadValidation/functions/handleFileUploads";

export const validatePolicyFiles = (
  files: File[],
  allowedFileTypes: string[] = ["docx", "doc", "pdf", "txt"],
): {
  validFiles: File[];
  invalidFiles: File[];
  validationMessage?: string;
} => {
  if (!isNonEmptyArray(files) || !isNonEmptyArray(allowedFileTypes)) {
    return {
      validFiles: [],
      invalidFiles: [],
    };
  }
  const validFiles = handleFileUploads(files, allowedFileTypes);
  const invalidFiles = files.filter((file) => !validFiles.includes(file));
  const invalidFileNames = isNonEmptyArray(invalidFiles) ? invalidFiles.map((file) => file?.name) : [];
  // generate message to display to user if there are invalid files
  const validationMessage = isNonEmptyArray(invalidFiles)
    ? `The following files were not accepted: ${invalidFileNames?.join(
        ", ",
      )}. Accepted file types: ${allowedFileTypes?.join(", ")}`
    : undefined;
  return {
    validFiles,
    invalidFiles,
    validationMessage,
  };
};
