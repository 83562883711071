import { getAllLinkedItems } from "@rivial-security/func-utils";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { generateGraphql } from "@rivial-security/generategraphql";

const { listByQuery: listTargetFindingLinksByVulnerabilityID } = generateGraphql(
  "TargetFindingLink",
  ["id", "target"],
  {
    target: `{
      id
      systems(limit: 250) {
        items {
          id
          system {
            id
            name
            description
            pointOfContact {
              id
              firstName
              lastName
              title
            }
            adminPointOfContact {
              id
              firstName
              lastName
              title
            }
            hosting
          }
        }
      }
    }`,
  },
  {
    indexName: "listTargetFindingLinksByVulnerabilityID",
    partitionKey: "vulnerabilityID",
    partitionKeyType: "ID",
    sortKey: "targetID",
    sortKeyType: "ModelIDKeyConditionInput",
  },
);

/**
 * Displays all systems associated with a vulnerability through a target
 * @param {object} item - the Vulnerability (Finding) id inside of an object
 * @param {string} organizationID - the organization id to use for the query, passed from withOrganizationCheck
 */
const VulnerabilitySystems = ({ item, organizationID }) => {
  const grid = useSystemDataGrid({
    createResourceComponent: null,
    normalizeData: (targetLinks) => {
      const targets = getAllLinkedItems({
        links: targetLinks,
        connectionField: "target",
      });

      const systemLinks = [];
      for (const target of targets) {
        const targetSystems = target?.systems?.items;

        if (Array.isArray(targetSystems)) {
          systemLinks.push(...targetSystems);
        }
      }

      return getAllLinkedItems({
        links: systemLinks,
        connectionField: "system",
      });
    },
    query: listTargetFindingLinksByVulnerabilityID,
    variables: {
      vulnerabilityID: item?.id,
    },
    organizationID,
  });

  return grid.gridDisplay;
};

export default withOrganizationCheck(VulnerabilitySystems);
