import React, { useContext, useRef } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Alert } from "@mui/material";
import CreateTask from "../../CreateTask";
import { OrganizationContext } from "@utils/Context/OrganizationContext";
import { TaskStatus } from "../../../constants/taskStatus";
import { taskCategory } from "../constants/taskCategory";
import useAdminCheck from "../../../../../../hooks/permissions/useAdminCheck";
import { useModal } from "@hooks/views/useModal";
import { useTaskDetails } from "../../../hooks/useTaskDetails";
import { useUpdateTask } from "./useUpdateTask";

/**
 * A custom hook to handle the task related modals (create modal, details modal, etc.)
 * @param {string} selectedMenu - The currently selected menu
 * @param {function} setSelectedMenu - The setter function for the selected menu
 * @param {object} currentList - The currently selected custom list
 * @param {string} departmentID - The department ID
 * @param {string} selectedTaskID - The currently selected task ID
 * @param {boolean} isSidebarView - Whether the component is being rendered in the TaskSidebar or TaskManagementUI
 * @param {function} setTasks - The setter function for the tasks
 * @param {function} setCompletedTasks - The setter function for the completed tasks
 * @param {function} setCustomLists - The setter function for the custom lists
 * @param {function} setCurrentList - The setter function for the current list
 * @returns {object}
 */
export const useTaskModals = ({
  selectedMenu,
  currentList,
  departmentID,
  selectedTaskID,
  isSidebarView = true,
  setTasks,
  setCompletedTasks,
  setCustomLists,
  setCurrentList,
}) => {
  const context = useContext(OrganizationContext);
  const { isAdmin } = useAdminCheck();
  const adminOwnerGroup = context?.role?.ownerGroup;

  const { updateTaskStatus } = useUpdateTask({
    setTasks,
    setCompletedTasks,
  });
  const taskDetailsHook = useTaskDetails({
    itemId: selectedTaskID,
    module: modules.GOVERNANCE,
    resource: resources.TASK,
    updateItemById: (updatedItem) => {
      // handles updating the task when the user updates the task in the details modal
      updateTaskStatus(updatedItem, updatedItem?.status);

      // Update other properties of the task in the active task list
      setTasks((prevTasks) => {
        return prevTasks.map((task) => (task?.id === updatedItem?.id ? updatedItem : task));
      });

      // Update other properties of the task in the 'completedTasks' list
      setCompletedTasks((prevCompletedTasks) => {
        return prevCompletedTasks.map((task) => (task?.id === updatedItem?.id ? updatedItem : task));
      });
    },
    toggleModal: () => {
      taskDetailsModalRef?.current?.toggleModal?.();
    },
  });
  const taskDetailModal = useModal("Task Details", taskDetailsHook.display, "", {
    width: "70vw",
  });
  const taskDetailsModalRef = useRef(taskDetailModal);

  const taskCreationModal = useModal(
    "Create A Task",
    <CreateTask
      getNewItem={(item) => {
        if (item.status === TaskStatus.DONE) {
          setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, item]);
        } else {
          // If current list is selected, update its tasks
          if (selectedMenu === currentList?.name) {
            setTasks((prevTasks) => [...prevTasks, item]);
            setCurrentList((prevList) => {
              const updatedTasks = [...(prevList?.tasks?.items ?? {}), item];
              return { ...prevList, tasks: { items: updatedTasks } };
            });
            setCustomLists((prevLists) => {
              const updatedLists = prevLists.map((list) => {
                if (list?.id === currentList?.id) {
                  return {
                    ...list,
                    tasks: { items: [...(list?.tasks?.items ?? {}), item] },
                  };
                } else {
                  return list;
                }
              });
              return updatedLists;
            });
          } else {
            setTasks((prevTasks) => [...prevTasks, item]);
          }
        }
      }}
      item={{
        pointOfContactID:
          selectedMenu === taskCategory.MY_TASKS && !isAdmin ? context?.loggedInPointOfContactId : undefined,

        departmentID: selectedMenu === taskCategory.DEPARTMENT_TASKS && !isAdmin ? departmentID : undefined,
        taskListID: currentList?.id,
      }}
      organizationID={isAdmin ? adminOwnerGroup : context?.selectedOrganization}
      header={
        isAdmin && isSidebarView ? (
          <Alert severity="info">
            {
              "To assign this task to a point of contact from an organization other than your own, please use the Action Center."
            }
          </Alert>
        ) : null
      }
    />,
    "",
    {
      width: "50vw",
    },
  );

  const { setModalIsOpen: setCreateModalOpen, modal: createModal } = taskCreationModal;
  const { setModalIsOpen: setTaskModalIsOpen, modal: detailModal } = taskDetailModal;

  return {
    selectedTaskID,
    setCreateModalOpen,
    createModal,
    setTaskModalIsOpen,
    detailModal,
  };
};
