import { GetQuery } from "../../../../utils/Functions/Graphql/GetQuery";
import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";

/**
 * @description This function is called when the user clicks the remove button on a point of contact.
 * @param {object} pointOfContact - The point of contact to remove.
 * @param {object} evidence - The evidence object.
 */
export const evidenceOnRemovePointOfContactRemoveActions = async ({ pointOfContact, evidence }) => {
  if (!pointOfContact) {
    EventLogger("[evidenceOnRemovePointOfContactRemoveActions.js] Missing pointOfContact");
    return;
  }

  if (!evidence) {
    EventLogger("[evidenceOnRemovePointOfContactRemoveActions.js] Missing evidence");
    return;
  }

  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["actions"], {
    actions: `(limit: 1000) {
      items {
        id
        __typename
        module
        data
        recommendations (limit: 1000) {
          items {
            id
            __typename
          }
        }
      }
     }`,
  });

  await GetQuery({
    query: getPointOfContact,
    variables: {
      id: pointOfContact?.id,
    },
  }).then(async (data) => {
    /**
     * Point of contact with actions
     */
    if (data) {
      const actions = data?.actions?.items;
      if (actions?.length > 0) {
        for (const action of actions) {
          try {
            if (action?.module === "compliance") {
              const actionData = JSON.parse(action?.data);
              if (actionData?.evidenceID === evidence?.id) {
                EventLogger(
                  `[evidenceOnRemovePointOfContactRemoveActions.js] Removing action item from point of contact: ${action?.id}`,
                );
                await GenericDeleteGQL({ objectToDelete: action });
              }
            }
          } catch (error) {
            EventLogger(
              "[evidenceOnRemovePointOfContactRemoveActions.js] Cannot remove action item from point of contact",
            );
          }
        }
      }
    }
  });
};
