"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxIntegrity = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMaxIntegrity = (system, ratingScale) => {
    const informationAssetLinks = system?.informationAssets?.items ? system.informationAssets.items : [];
    const informationAssets = [];
    for (const item of informationAssetLinks) {
        informationAssets.push(item.informationAsset);
    }
    const assetsWithScale = [];
    for (const item of informationAssets) {
        // @ts-expect-error Fix me, needs better typing
        const assetWithScale = { ...item, integrity: ratingScale[item.integrity] };
        assetsWithScale.push(assetWithScale);
    }
    const arr = [];
    for (const item of assetsWithScale) {
        // @ts-expect-error Fix me, needs better typing
        arr.push(item.integrity);
    }
    return Math.max(...arr);
};
exports.getMaxIntegrity = getMaxIntegrity;
