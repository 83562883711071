import { useElementSize } from "../../../functional/useElementSize";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useRef } from "react";

/**
 * The component showing custom information at the bottom of the grid
 * @param {JSX.Element} viewControlsDisplay - a set of buttons controlling the data grid view settings
 * @param {boolean} disableViews - whether to disable the display of view controls
 * @return {JSX.Element}
 */
const DataGridFooter = ({ viewControlsDisplay, disableViews }) => {
  const apiRef = useGridApiContext();
  const columnCounterRef = useRef(null);
  const { width: columnCounterWidth } = useElementSize({
    ref: columnCounterRef,
  });

  /**
   * Retrieves the right side text displayed in the footer, shows the amount of total and selected rows
   * @return {string} - the row information text to display in the footer
   */
  const getFooterRowAmountText = () => {
    const selectedRowsAmount = apiRef?.current?.state?.selection?.length;
    const filteredItems = apiRef?.current?.state?.filter?.filterModel?.items?.length;
    const paginationRowCount = apiRef?.current?.state?.pagination?.rowCount;
    const totalRowsAmount = apiRef?.current?.getRowsCount();

    if (!totalRowsAmount) {
      return "No items";
    }

    const itemsText = totalRowsAmount === 1 ? "item" : "items";

    if (filteredItems) {
      if (selectedRowsAmount) {
        return `${selectedRowsAmount} of ${paginationRowCount || 0} filtered ${itemsText} selected`;
      } else {
        return `${paginationRowCount} of ${totalRowsAmount || 0} ${itemsText} filtered`;
      }
    }

    if (!selectedRowsAmount) {
      return `${totalRowsAmount || 0} ${itemsText}`;
    } else {
      return `${selectedRowsAmount} of ${totalRowsAmount || 0} ${itemsText} selected`;
    }
  };

  return (
    <div
      style={{
        borderTop: "solid",
        borderWidth: "thin",
        borderColor: "#E0E0E0",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflowX: "auto",
      }}
    >
      {!disableViews && viewControlsDisplay}
      <div
        ref={columnCounterRef}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        {columnCounterWidth > 150 && (
          <p
            style={{
              fontSize: "1em",
              marginRight: "1em",
              marginBottom: ".2em",
              marginTop: ".2em",
            }}
          >
            {getFooterRowAmountText()}
          </p>
        )}
      </div>
    </div>
  );
};

export default DataGridFooter;
