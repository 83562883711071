export const IncidentResponseLinks = {
  name: "Response",
  url: "/response",
  icon: "simple-line-icons:target",
  children: [
    {
      dashboardPage: {
        name: "Dashboard",
        icon: "simple-line-icons:grid",
        url: "/response/dashboard",
      },
    },
    {
      responsePlanPage: {
        name: "Plan",
        icon: "simple-line-icons:map",
        url: "/response/plan",
      },
    },
    {
      exercisesPage: {
        name: "Exercises",
        icon: "simple-line-icons:note",
        url: "/response/exercises",
      },
    },
    {
      incidentsPage: {
        name: "Incidents",
        icon: "simple-line-icons:flag",
        url: "/response/incidents",
      },
    },
  ],
};
