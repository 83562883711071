export const VendorLinks = {
  name: "Vendors",
  url: "/vendors",
  icon: "simple-line-icons:tag",
  children: [
    {
      vendorDashboardPage: {
        name: "Dashboard",
        url: "/vendors/dashboard",
        icon: "simple-line-icons:grid",
      },
    },
    {
      vendorPage: {
        name: "Vendor List",
        url: "/vendors/vendor",
        icon: "simple-line-icons:basket",
      },
    },
    {
      vendorSolutionPage: {
        name: "Vendor Solutions",
        url: "/vendors/solutions",
        icon: "simple-line-icons:basket-loaded",
      },
    },
    {
      vendorReviewPage: {
        name: "Vendor Reviews",
        url: "/vendors/reviews",
        icon: "simple-line-icons:book-open",
      },
    },
    {
      vendorControlPage: {
        name: "Vendor Controls",
        url: "/vendors/controls",
        icon: "simple-line-icons:list",
      },
    },
    {
      vendorCertificationPage: {
        name: "Pre-Qualified Certs",
        url: "/vendors/certification",
        icon: "simple-line-icons:badge",
      },
    },
    {
      vendorConfig: {
        name: "Configuration",
        url: "/vendors/config",
        icon: "simple-line-icons:settings",
      },
    },
  ],
};
