import React from "react";
import { formattedDollar, formattedPercent, isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Enumerable type for the possible number formats for this component
 * @type {{PERCENT: string, DOLLAR: string, NONE: string}}
 */
export const NUMBER_FORMAT = {
  NONE: "none",
  PERCENT: "percent",
  DOLLAR: "dollar",
};

/**
 * Generic component for visualizing that a number has changed.
 *
 * @param {number} value - the number that is being represented, defaults to trying this prop
 * @param {object} [item] - alternatively, can pass an item and field param instead of the value directly.
 * @param {string} [field] - alternatively, can pass an item and field param instead of the value directly.
 * @param {string} [positiveNumber = "green"] - the text color that represents a positive number
 * @param {string} [negativeNumber = "red"] - the text color that represents a negative number
 * @param {string} [notApplicable = "grey"] - the text color that represents a N/A number
 * @param {NUMBER_FORMAT} [format = NONE] - optional formatting for the number
 * @param {number} [toFixed = 2] - to set the number of decimal places for percentages
 * @param {object} [style = {}] - optional style params
 * @returns {JSX.Element}
 * @constructor
 */
const NumberChange = ({
  value: valueParam,
  positiveNumber = "green",
  negativeNumber = "red",
  notApplicable = "grey",
  format = NUMBER_FORMAT.NONE, // percent, dollar, none,
  toFixed = 2,
  style = {},
  item,
  field,
}) => {
  /**
   * Handles the Text coloring
   * @param value
   * @returns {string|undefined}
   */
  const getColor = (value) => {
    if (value === "N/A" || value === "Not Applicable") return notApplicable;
    else if (value > 0) return positiveNumber;
    else if (value < 0) return negativeNumber;
    else return undefined;
  };

  /**
   * Handles the final value, either grabs directly from the 'value' prop, or from the 'item' and 'field' props
   * @returns {number}
   */
  const getValue = () => {
    let finalValue;
    if (!isNullOrUndefined(valueParam)) {
      finalValue = valueParam;
    } else if (!isNullOrUndefined(item) && !isNullOrUndefined(field)) {
      finalValue = parseFloat(item[field]);
    }

    return Number.isFinite(finalValue) ? finalValue : "N/A";
  };

  const value = getValue();

  return (
    <>
      <span
        style={{
          color: getColor(value),
          fontStyle: value === "N/A" || value === "Not Applicable" ? "italic" : undefined,
          ...style,
        }}
      >
        {(() => {
          if (value === "N/A" || value === "Not Applicable") return value;
          switch (format) {
            case NUMBER_FORMAT.PERCENT:
              return formattedPercent(value, toFixed);
            case NUMBER_FORMAT.DOLLAR:
              return formattedDollar(value);
            default:
              return value;
          }
        })()}
      </span>
    </>
  );
};

export default NumberChange;
