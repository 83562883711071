import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteReport } = generateGraphql("Report", ["name"]);

const DeleteReport = (report) => {
  API.graphql(
    graphqlOperation(deleteReport, {
      input: {
        id: report.id,
      },
    }),
  ).then(() => EventLogger(`Report ${report.name} was Successfully Deleted`));
};

export default DeleteReport;
