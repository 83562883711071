import awsmobile from "./aws-exports";

// Get environment name from the S3 bucket name in the aws-exports.js file
export const ENV_NAME = awsmobile?.aws_user_files_s3_bucket?.split("-")?.pop() ?? "";

// AWS constants
export const S3_BUCKET_REGION = awsmobile.aws_user_files_s3_bucket_region;
export const S3_BUCKET_NAME = awsmobile.aws_user_files_s3_bucket;

// Assign "ENV_NAME" to the "AmplifyEnv" variable since we use that name in the code
export const AmplifyEnv = ENV_NAME;
export const ConsoleLogging = true;

// Recaptcha keys for the different environments
const prodRecaptchaKey = "6LdoYbAUAAAAAJS-CgNstGGZbRE5eRjOn7XJywqd";
const developmentRecaptchaKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

export const GoogleRecaptchaSiteKey = ENV_NAME === "prod" ? prodRecaptchaKey : developmentRecaptchaKey;

// Lambdas
export const MonteCarloCalculation = `monteCarloSystemCalculation-${ENV_NAME}`;

// Base URL for the Rivial Platform Help Center
export const helpCenterUrl = "https://rivial-helpcenter.scrollhelp.site/hcenter/";

// Base URL for the Rivial Mitre Attack Workbench
export const mitreAttackApiUrl = "https://d21q5uxsrqsrrl.cloudfront.net";

// Automation Api Gateway ids for the different environments
enum AUTOMATION_GATEWAY {
  prod = "6zh1g24t2e",
  beta = "cfuuxo9xtj",
  dev = "alpokptnn3",
  testing = "j8k1yk165c",
  staging = "bztuhc4w32",
  tahoe = "y0s48x5i0b",
  flathead = "f72r77kcwc",
}

// Url for incoming webhooks
export const automationWebHooksUrl = `https://${AUTOMATION_GATEWAY[ENV_NAME]}.execute-api.us-west-2.amazonaws.com/hooks/automationwebhooks-${ENV_NAME}`;

// Platform password length requirements
export const passwordLength = 12;

// Get the URL for the current environment
enum ENV_URLS {
  prod = "https://platform.rivialsecurity.com",
  beta = "https://d1sq84a9l17lxv.cloudfront.net",
  dev = "https://d2k7mif0rgptgu.cloudfront.net",
  testing = "https://d22moti88feyt7.cloudfront.net",
  staging = "https://d35ba1j8qhvl76.cloudfront.net",
  tahoe = "https://d1zrurvtyuh2ky.cloudfront.net",
  flathead = "https://d1c0u88rmrzo85.cloudfront.net",
}

// Get Cognito User Pool URL
enum COGNITO_USER_POOL_URL {
  prod = "rivialsecurity.auth.us-west-2.amazoncognito.com",
  beta = "rivialsecurity-beta.auth.us-west-2.amazoncognito.com",
  dev = "rivialsecurity-dev.auth.us-west-2.amazoncognito.com",
  testing = "rivialsecurity-testing.auth.us-west-2.amazoncognito.com",
  staging = "rivialsecurity-staging.auth.us-west-2.amazoncognito.com",
}

// Get the Cognito user pool URL for the current environment
export const COGNITO_USER_POOL_ENV_URL = COGNITO_USER_POOL_URL[ENV_NAME] as string;

// Get the URL for the current environment
export const ENV_URL = ENV_URLS[ENV_NAME] as string;

export const ROOT_URL = `${ENV_URL}/#`;
export const PolicyLandingPageURL = `${ENV_URL}/#/governance/review_policy/`;

export const MUI_LICENSE_KEY =
  "0344be8276f68d822711d91e0a2749a9Tz02MDg3OCxFPTE3MTE1ODQwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=";
export const SYNCFUSION_LICENSE_KEY =
  "ORg4AjUWIQA/Gnt2VFhhQlJDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5VdEViW31ecXJSQGZb";
export const SENTRY_DSN =
  "https://35d4038632df4d79a56304e83150747c@o4504131704782848.ingest.sentry.io/4504131710681088";

enum LAUNCHDARKLY_CLIENT_SIDE_IDS {
  prod = "6655f68f6398530fcc8b26b3",
  beta = "6661ec8beda5ba10dc3b0efb",
  dev = "6661ec9b1d426a102d573a2a",
  testing = "6661ecade84f940fe59bc6b6",
  staging = "6661ecb96f1d3c0fdfee924d",
  tahoe = "6661ecc004ea160f54532db1",
  flathead = "6661ecc8c68ce70f8dbc6112",
}

export const LAUNCHDARKLY_CLIENT_SIDE_ID = LAUNCHDARKLY_CLIENT_SIDE_IDS[ENV_NAME] as string;

export const STATUS_PAGE_URL = "https://status.rivialsecurity.com/";
export const HOMEPAGE_URL = "https://www.rivialsecurity.com/";
