import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemQuery } from "../../../../utils/Functions/Graphql/ItemQuery";
import { DeleteVendorControlDocumentLink } from "../../VendorSubControls/functions/DeleteVendorControlDocumentLink";
import { Storage } from "@aws-amplify/storage";

/**
 * Deletes a VendorDocument
 * Deletes all associated VendorControlDocumentLinks
 * Deletes S3 object
 *
 * @param {object} vendorDocument
 * @param {string} vendorDocument.id
 * @returns {Promise<void>}
 */
export const deleteVendorDocument = async (vendorDocument) => {
  if (!vendorDocument && !vendorDocument.id) {
    EventLogger("Error! Missing input for deleteVendorDocument function");
    return;
  }

  // setup graphql
  const { getQuery, deleteMutation } = generateGraphql("VendorDocument", ["file", "controlLinks"], {
    file: `{ bucket key }`,
    controlLinks: `(limit: 500) { items { id } }`,
  });

  // get full VendorDocument
  const item = await ItemQuery(getQuery, vendorDocument.id);

  // get all associated controlLinks
  const controlLinks = item && item.controlLinks && item.controlLinks.items ? item.controlLinks.items : [];

  if (item && item.id) {
    // delete S3 object
    if (item.file && item.file.bucket && item.file.key) await Storage.remove(item?.file?.key);
    // delete all associated control links
    for (const controlLink of controlLinks) await DeleteVendorControlDocumentLink(controlLink);

    // delete VendorDocument
    await ItemMutation(deleteMutation, {
      id: item && item.id,
    });
  } else {
    EventLogger("Error! Can not delete Vendor Document! Invalid input!");
  }
};
