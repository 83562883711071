import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

export const deleteMetricType = async (metricType) => {
  if (!metricType?.id) {
    return;
  }

  const { getQuery } = generateGraphql("MetricType", ["__typename", "metrics", "keyPerformanceIndicators"], {
    keyPerformanceIndicators: `(limit: 1000) { items { __typename id }}`,
    metrics: `(limit: 1000){
      items {
        id
        __typename
      }
      nextToken
    }`,
  });

  return await QueryGetItem({ query: getQuery, itemId: metricType.id }).then(async (metricType) => {
    await GenericDeleteGQL({ objectToDelete: metricType });
    EventLogger(`Metric Type ${metricType.id} was Successfully Deleted`);
  });
};
