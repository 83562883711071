export const listTasksByPointOfContact = /* GraphQL */ `
  query TasksByPointOfContact($pointOfContactID: ID!, $limit: Int, $nextToken: String) {
    taskByPointOfContact(pointOfContactID: $pointOfContactID, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        type
        module
        dueDate
        taskListID
        notes {
          content
        }
        ownerGroup
        checklist {
          name
          description
          groups {
            id
            name
            description
            items {
              id
              content
              completed
              notes {
                content
              }
            }
          }
        }
        custom {
          content
        }
        pointOfContact {
          id
          firstName
          lastName
          title
          email
          phone
          ownerGroup
        }
        department {
          id
          name
          description
          departmentHeadID
          departmentHead {
            id
          }
          departmentMembers {
            items {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listTasksByOwnerGroup = /* GraphQL */ `
  query TasksByOwnerGroup($ownerGroup: String!, $limit: Int, $nextToken: String) {
    tasksByOwnerGroup(ownerGroup: $ownerGroup, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        type
        module
        dueDate
        taskListID
        notes {
          content
        }
        ownerGroup
        checklist {
          name
          description
          groups {
            id
            name
            description
            items {
              id
              content
              completed
              notes {
                content
              }
            }
          }
        }
        custom {
          content
        }
        pointOfContact {
          id
          firstName
          lastName
          title
          email
          phone
          ownerGroup
          department {
            id
            name
            description
            departmentHeadID
          }
        }
        department {
          id
          name
          description
          departmentHeadID
        }
      }
      nextToken
    }
  }
`;
export const listTasksByDepartment = /* GraphQL */ `
  query TaskByDepartment($departmentID: ID!, $limit: Int, $nextToken: String) {
    taskByDepartment(departmentID: $departmentID, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        type
        module
        dueDate
        notes {
          content
        }
        ownerGroup
        checklist {
          name
          description
          groups {
            id
            name
            description
            items {
              id
              content
              completed
              notes {
                content
              }
            }
          }
        }
        custom {
          content
        }
        pointOfContact {
          id
          firstName
          lastName
          title
          email
          phone
          ownerGroup
        }
        department {
          id
          name
          description
          departmentHeadID
          departmentMembers {
            items {
              id
              firstName
              lastName
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getPointOfContact = /* GraphQL */ `
  query GetPointOfContact($id: ID!) {
    getPointOfContact(id: $id) {
      id
      firstName
      lastName
      title
      email
      phone
      ownerGroup
      departmentID
    }
  }
`;

export const listTasksByTaskList = /* GraphQL */ `
  query TasksByTaskList($taskListID: ID!, $limit: Int, $nextToken: String) {
    taskByTaskList(taskListID: $taskListID, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        type
        taskListID
        module
        dueDate
        notes {
          content
        }
        ownerGroup
        checklist {
          name
          description
          groups {
            id
            name
            description
            items {
              id
              content
              completed
              notes {
                content
              }
            }
          }
        }
        custom {
          content
        }
        pointOfContact {
          id
          firstName
          lastName
          title
          email
          phone
          ownerGroup
        }
        department {
          id
          name
          description
        }
        taskList {
          id
          name
          description
          pointOfContactID
        }
      }
      nextToken
    }
  }
`;
