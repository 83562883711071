import React from "react";
import { Redirect } from "react-router-dom";

const TestingDashboard = React.lazy(() => import("../../views/Testing/Dashboard/components/TestingDashboard"));
const AssessmentsList = React.lazy(() => import("../../views/Testing/Assessments/components/AssessmentDataGrid"));
const AssessmentDetailsCard = React.lazy(() => import("../../views/Testing/Assessments/components/AssessmentDetails"));
const TargetsList = React.lazy(() => import("../../views/Testing/Targets/components/TargetsGrid"));
const TargetDetailsCard = React.lazy(() => import("../../views/Testing/Targets/components/TargetDetails"));
const VulnerabilityDetails = React.lazy(
  () => import("../../views/Testing/Vulnerabilities/components/VulnerabilityDetails"),
);
const VulnerabilityDataGrid = React.lazy(
  () => import("../../views/Testing/Vulnerabilities/components/VulnerabilityDataGrid"),
);

// Testing Routes
export const vulnerabilitiesRoutes = [
  // Legacy 'Testing' routes
  {
    path: "/testing/dashboard",
    exact: true,
    name: "Testing Dashboard",
    component: () => <Redirect to="/vulnerabilities/dashboard" />,
  },
  {
    path: "/testing/assessments",
    exact: true,
    name: "Assessments",
    component: () => <Redirect to="/vulnerabilities/assessments" />,
  },
  {
    path: "/testing/assessments/:id",
    exact: true,
    name: "Assessment Details",
    component: () => <Redirect to="/vulnerabilities/assessments/:id" />,
  },
  {
    path: "/testing/targets",
    exact: true,
    name: "Targets",
    component: () => <Redirect to="/vulnerabilities/targets" />,
  },
  {
    path: "/testing/targets/:id",
    exact: true,
    name: "Target Details",
    component: () => <Redirect to="/vulnerabilities/targets/:id" />,
  },
  {
    path: "/testing/vulnerabilities",
    exact: true,
    name: "Vulnerabilities",
    component: () => <Redirect to="/vulnerabilities/vulnerabilities" />,
  },
  {
    path: "/testing/vulnerabilities/:id",
    exact: true,
    name: "Vulnerability Details",
    component: () => <Redirect to="/vulnerabilities/vulnerabilities/:id" />,
  },
  {
    path: "/testing/vulnerability_groups",
    exact: true,
    name: "Vulnerabilities",
    component: () => <Redirect to="/vulnerabilities/vulnerability_groups" />,
  },
  {
    path: "/testing/vulnerability_groups/:id",
    exact: true,
    name: "Vulnerability Details",
    component: () => <Redirect to="/vulnerabilities/vulnerability_groups/:id" />,
  },
  {
    path: "/testing",
    component: () => <Redirect to="/vulnerabilities/dashboard" />,
  },

  // Updated 'Vulnerabilities' routes
  {
    path: "/vulnerabilities/dashboard",
    exact: true,
    name: "Vulnerabilities Dashboard",
    component: TestingDashboard,
  },
  {
    path: "/vulnerabilities/assessments",
    exact: true,
    name: "Assessments",
    component: AssessmentsList,
  },
  {
    path: "/vulnerabilities/assessments/:id",
    exact: true,
    name: "Assessment Details",
    component: AssessmentDetailsCard,
  },
  {
    path: "/vulnerabilities/targets",
    exact: true,
    name: "Targets",
    component: TargetsList,
  },
  {
    path: "/vulnerabilities/targets/:id",
    exact: true,
    name: "Target Details",
    component: TargetDetailsCard,
  },
  {
    path: "/vulnerabilities/vulnerabilities",
    exact: true,
    name: "Vulnerabilities",
    component: VulnerabilityDataGrid,
  },
  {
    path: "/vulnerabilities/vulnerabilities/:id",
    exact: true,
    name: "Vulnerability Details",
    component: VulnerabilityDetails,
  },
  {
    path: "/vulnerabilities/vulnerability_groups",
    exact: true,
    name: "Vulnerabilities",
    component: VulnerabilityDataGrid,
  },
  {
    path: "/vulnerabilities/vulnerability_groups/:id",
    exact: true,
    name: "Vulnerability Details",
    component: VulnerabilityDetails,
  },
  {
    path: "/vulnerabilities",
    component: () => <Redirect to="/vulnerabilities/dashboard" />,
  },
];
