import React from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import ThreatDetails from "../../../../Response/Threats/components/ThreatDetails";
import { deleteThreat } from "../../../../Response/Threats/functions/deleteThreat";
import CreateThreat from "../../../../Response/Threats/components/CreateThreat";

/**
 * Displays a list of Threats
 *
 * @param {string} organizationID - ownerGroup query ID
 * @param {object} ...props - all props passed to the useDataGrid hook
 */
export const useThreatDataGrid = ({
  organizationID,
  module = modules.RISK,
  resource = resources.THREAT,
  fields = [],
  disableRoleChecking = false,
  ...props
}) => {
  const { listQuery, updateMutation } = generateGraphql("Threat", ["name", "techniqueID"]);

  fields = [
    {
      name: "name",
      flex: 1,
      minWidth: 200,
      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "techniqueID",
      friendlyName: "Technique Attached",
      width: 150,
      valueGetter: (params) => (params?.row?.techniqueID ? "Yes" : "No"),
      plainText: true,
      description: "Displays if Mitre ATT&CK technique is available for a threat",
      disableEdits: true,
    },
    ...fields,
  ];

  const queryCard = useDataGrid({
    query: listQuery,
    updateMutation,
    organizationID,
    typename: "Threat",
    createResourceComponent: <CreateThreat organizationID={organizationID} />,
    createItemModalHeader: "Create a Threat",
    detailsComponent: <ThreatDetails organizationID={organizationID} />,
    detailsTitle: "Threat Details",
    module,
    resource,
    disableRoleChecking,
    fields,
    title: "Threats",
    headerIcon: "icon-target",
    deleteFunction: deleteThreat,
    options: ["details", "delete"],
    persistenceUUID: "5c30b176-da51-4156-bc30-35feaa4eb12d",
    ...props,
  });

  return { ...queryCard };
};
