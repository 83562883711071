export const OperationPanelLinks = {
  name: "Operation Panel",
  url: "/operation_panel",
  icon: "simple-line-icons:wrench",
  children: [
    // {
    //   name: 'Dashboard',
    //   url: '/operation_panel/dashboard',
    //   icon: 'simple-line-icons:mustache',
    // },
    {
      name: "Organizations",
      url: "/operation_panel/organizations",
      icon: "simple-line-icons:mustache",
    },
    {
      name: "Users",
      url: "/operation_panel/users",
      icon: "simple-line-icons:people",
    },
    {
      name: "Templates",
      url: "/operation_panel/template_manager",
      icon: "simple-line-icons:layers",
    },
    {
      name: "Configuration",
      url: "/operation_panel/configuration",
      icon: "simple-line-icons:settings",
    },
  ],
};
