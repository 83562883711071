import { convertCamelCaseToSentence, enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { KNOW_BE_4_METRIC_TYPES } from "../../KnowBe4/enums/KNOW_BE_4_METRIC_TYPES";
import { TOOL_TYPES } from "@rivial-security/integration-utils";
import { UIContext } from "../../../../utils/Context/UIContext";
import { autoCreateToolMetricTypes } from "../functions/autoCreateToolMetricTypes";
import { createToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { useContext } from "react";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useToolDataGrid } from "./useToolDataGrid";

/**
 * @description Create a new tool hook
 * @param {string} organizationID - The organization ID
 * @param {function} toggleModal - The toggle modal function
 * @param {boolean} noHeader - If the modal should have a header
 * @param {function} getNewItem - The function to get a new item
 * @param {function} resetFunction - The function to reset the form
 * @param {object} props - The props to pass to the form
 * @returns {{input: {}, display: *}}
 */
export const useCreateTool = ({
  organizationID,
  toggleModal,
  noHeader,
  getNewItem,
  resetFunction,
  isTemplate = false,
  disableTemplates = false,
  ...props
}) => {
  const module = modules.TOOLS;
  const resource = resources.TOOL;
  const typename = resources.TOOL;

  const { addToast, updateToast } = useContext(UIContext);

  const toolsGrid = useToolDataGrid({ organizationID });

  const submitFunction = async (input) => {
    const { name, description, type, secret } = input;

    const createTool = /* GraphQL */ `
      mutation CreateTool($input: CreateToolInput!) {
        createTool(input: $input) {
          id
          name
          description
          type
          createSecret(secret: "${secret}")
          ownerGroup
        }
      }
    `;

    return await createToastOperation({
      addToast,
      updateToast,
      typename,
      operation: async () =>
        await ItemMutation(createTool, {
          name,
          description,
          type,
          ownerGroup: organizationID,
        }),
    });
  };

  // After the mutation, handles built-in Integration functionalities, e.g. setting up Metrics for KnowBe4
  const callback = async (input) => {
    try {
      if (input.type === TOOL_TYPES.KNOW_BE_4) {
        const knowBe4Configs = Object.values(KNOW_BE_4_METRIC_TYPES);
        await autoCreateToolMetricTypes({
          configs: knowBe4Configs,
          toolID: input.id,
          organizationID,
        });
      }
    } catch (e) {
      EventLogger("could not automatically create Knowbe4 metric types");
      EventLogger(e);
    }
  };

  const form = useForm({
    callback,
    fieldConfig: {
      name: {
        required: true,
        label: "Name",
        defaultValue: (props.name && convertCamelCaseToSentence(props.name)) || "",
      },
      description: {
        label: "Description",
      },
      type: {
        required: true,
        label: "Type",
        tooltip: "Configure a Custom Tool Integration or used a built-in advanced Integration",
        inputType: "dropdown",
        defaultValue: props.toolType || TOOL_TYPES.CUSTOM,
        dropdownConfig: {
          data: enumToDropdownData({ ENUM: TOOL_TYPES }).filter(
            (x) => !toolsGrid?.data?.find((y) => y.type === x.value && y.type !== TOOL_TYPES.CUSTOM),
          ),
        },
      },
      secret: {
        label: "API Key",
        tooltip: "The API Key or Auth token used for accessing a 3rd party integration",
        inputType: "textarea",
      },
    },
    submitFunction,
    organizationID,
    getNewItem,
    module,
    resource,
    toggleModal,
    enableTemplates: !disableTemplates && !isTemplate,
    typename: "Integration",
    ...props,
  });

  return {
    ...form,
  };
};
