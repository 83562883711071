import { handleRoleTypes, roleConfigDefault } from "@rivial-security/role-utils";

import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import React from "react";
import ResourceSection from "./ResourceSection";

/**
 * Displays all Resources for a Module in Role Config
 * @param {function} handleResourceChange - handler for changing resource operation permissions
 * @param {object} roleConfig - the current user's roleConfig object
 * @param {number} precedence - the current user's precedence integer
 * @param {string} module - the module these resources are related to
 * @param {string} searchValue - a search string used for highlighting
 * @param {boolean} disableEdits - whether edits are disabled for this resource section
 * @param {boolean} moduleDisabled - adds a note to explain that even though the module is disabled, resource access may still be configured
 * @returns {JSX.Element}
 * @constructor
 */
const ResourceSections = ({
  handleResourceChange,
  roleConfig,
  precedence,
  module,
  searchValue,
  disableEdits,
  moduleDisabled,
}) => {
  const resources = filterResources(roleConfigDefault, precedence, module);

  return (
    <div>
      {moduleDisabled && (
        <span id={"module-disabled-alert"}>
          <i>This Module is disabled, but the following Resource(s) may still be accessed through related Modules.</i>
          <br />
          <br />
        </span>
      )}
      <span>
        {resources?.length > 0 ? (
          resources.map((resource) => (
            <ResourceSection
              disableEdits={disableEdits}
              module={module}
              searchValue={searchValue}
              key={resource}
              resource={resource}
              operations={roleConfig?.resources?.[resource]}
              handleResourceChange={handleResourceChange}
            />
          ))
        ) : (
          <i>This module has no configurable Resource permissions</i>
        )}
      </span>
    </div>
  );
};

/**
 * Filters and sorts the resources in roleConfig based on module and precedence
 * @param {object} roleConfig - the role config object
 * @param {number} precedence - the precedence of this role
 * @param {string} module - the module to filter resources by
 * @returns {string[]}
 */
const filterResources = (roleConfig, precedence, module) => {
  try {
    return Object.keys(handleRoleTypes(roleConfig, precedence).resources)
      .filter((resource) => roleConfigDefault.resources[resource].module === module)
      .sort((a, b) => a.localeCompare(b));
  } catch (e) {
    EventLogger("Could not filter resources. ", e);
    return [];
  }
};

export default ResourceSections;
