import { Button, Row } from "reactstrap";
import React, { useContext } from "react";

import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../utils/Functions/Graphql/ItemMutation";
import { ItemQuery } from "../../../utils/Functions/Graphql/ItemQuery";
import NotEnoughData from "../../../utils/GenericComponents/NotEnoughData";
import { UIContext } from "../../../utils/Context/UIContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { merge } from "lodash";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

/**
 * Displays the contents of the role change request notification
 * @param {string} html - html of all the changes to the role config that need to be made
 * @param {object} data - additional information about the requesting user and the role being edited
 * @param {function} toggleModal - if toast is in container can call this function to close the container if it is a modal
 * @param {function} removeToast - function that removes the toasts from view
 * @return {*}
 */
const RoleChangeRequestNotificationBody = ({ html, data, toggleModal, removeToast }) => {
  //[HOOKS]
  const history = useHistory();
  const { addToast, updateToast } = useContext(UIContext);

  //[DATA]
  const { role, user, type, minimalRoleConfigChanges } = data;
  const roleName = !isNullOrUndefined(role?.name) ? role?.name : "requesting user's";
  // - prepare shown textual information
  const actionsIntro = `How would you like to respond to this request?`;

  //[BUTTON ACTIONS]
  const onApplyChanges = async () => {
    const toastId = addToast({
      header: `Updating "${role?.name || "User"}" Role...`,
      icon: "spinner",
      color: "success",
    });

    try {
      //check arguments
      if (!role?.id) {
        throw Error("Cannot retrieve ID to update because role id is missing!");
      }

      //get the current version of the role
      const { getQuery, updateMutation } = generateGraphql("Role", ["name", "roleConfig", "precedence"]);
      const currentRole = await ItemQuery(getQuery, role.id);

      if (!currentRole?.roleConfig) {
        throw Error("Cannot find role config info in the current role!");
      }

      //merge role configs changes
      const newRoleConfig = JSON.parse(currentRole?.roleConfig);
      merge(newRoleConfig, minimalRoleConfigChanges);

      //update the role
      const result = await ItemMutation(updateMutation, {
        id: role.id,
        roleConfig: JSON.stringify(newRoleConfig),
      });

      if (!result?.id) {
        throw Error("Updated role config was not returned.");
      }

      //since succeeded remove the notification
      removeToast();

      updateToast({
        id: toastId,
        header: `Successfully updated "${role?.name || "User"}" Role`,
        icon: "success",
      });
    } catch (e) {
      EventLogger(e);
      updateToast({
        id: toastId,
        header: `Failed to update "${role?.name || "User"}" Role`,
        icon: "danger",
      });
    }
  };

  const onDenyChanges = () => {
    removeToast();
  };

  const onViewRoleDetails = () => {
    toggleModal && toggleModal();
    if (role?.id) {
      history.push(`/organization_manager/roles/${role?.id}`);
    } else {
      history.push(`/organization_manager/roles`);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "AliceBlue", padding: "1em" }}>{html && parse(html)}</div>
      <hr />
      <p>{actionsIntro}</p>
      <p>
        <span style={{ fontWeight: "bold" }}>Note: </span>
        <span>{`Your selection will impact all users assigned to the "${roleName}" role.`}</span>
      </p>
      <Row>
        <Button
          title={`Update the ${roleName} role to include the above changes.`}
          style={{ marginLeft: "1em", marginRight: "1em" }}
          color={"success"}
          size={"sm"}
          onClick={onApplyChanges}
        >
          Apply Changes
        </Button>
        {"or"}
        <Button
          title={"Do not process the requested changes and discard this notification."}
          style={{ marginLeft: "1em", marginRight: "1em" }}
          color={"danger"}
          size={"sm"}
          onClick={onDenyChanges}
        >
          Deny Changes
        </Button>
      </Row>
      <br />
      <div style={{ display: "flex" }}>
        <NotEnoughData
          message={"Alternatively,"}
          itemName={"NotificationRoleDetails"}
          callToAction={{
            message: " to view the role details page.",
            function: onViewRoleDetails,
          }}
        />
      </div>
    </div>
  );
};

export default RoleChangeRequestNotificationBody;
