import { useState, useEffect } from "react";

/**
 * Custom hook to format the due date for an item and check if it's past due.
 * @param {string} dueDate - The due date of the item
 * @return {Object} - Object containing formatted due date and a boolean indicating if the date is past due
 */
export const useFormattedDueDate = ({ dueDate }) => {
  const [formattedDate, setFormattedDate] = useState("No due date set");
  const [isPastDue, setIsPastDue] = useState(false);

  useEffect(() => {
    if (!dueDate) {
      setFormattedDate("No due date set");
      setIsPastDue(false);
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const taskDate = new Date(dueDate);
    taskDate.setHours(0, 0, 0, 0);

    const diffTime = taskDate - today;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24)); // Convert difference in milliseconds to days

    if (diffDays < 0) {
      // If the due date is in the past
      setFormattedDate(`Past due by ${Math.abs(diffDays)} day${Math.abs(diffDays) > 1 ? "s" : ""}`);
      setIsPastDue(true);
    } else if (diffDays === 0) {
      setFormattedDate("Due Today");
      setIsPastDue(false);
    } else if (diffDays === 1) {
      setFormattedDate("Due Tomorrow");
      setIsPastDue(false);
    } else {
      setFormattedDate(`Due on ${taskDate.toLocaleDateString()}`);
      setIsPastDue(false);
    }
  }, [dueDate]);

  return {
    formattedDate,
    isPastDue,
  };
};
