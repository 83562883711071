import { EventLogger } from "../../EventLogger/EventLogger";

/**
 * Helper function for converting an object to a string with additional options
 * Useful for limiting the amount of useEffect calls for object dependencies
 * @param {object} item - the object data to convert
 * @param {string[]} [omitFields=[]] - fields to omit from the object before conversion
 * @param {string[]} [keepFields] - if provided will delete all fields that don't exist in the array before conversion
 * @param {boolean} [enableLogging] - if true will show debug logs
 * @returns {string} - the modified object converted to a string
 */
export const objectToString = ({ item, omitFields = [], keepFields, enableLogging = false }) => {
  if (!item) {
    if (enableLogging) {
      EventLogger(`[objectToString] item is null or undefined - ${item}`);
    }
    return "";
  }

  const newItem = {
    ...item,
  };

  //Omit any requested fields
  if (Array.isArray(omitFields)) {
    for (const omitField of omitFields) {
      delete newItem[omitField];
    }
  }

  if (Array.isArray(keepFields)) {
    for (const field in newItem) {
      if (!keepFields.includes(field)) {
        delete newItem[field];
      }
    }
  }

  return JSON.stringify({ newItem });
};
