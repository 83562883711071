import { ListQueryBy } from "../../../../utils/Functions/Graphql/ListQueryBy";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * @description Retrieves a nested array of Compliance Control Documents for Control Set(s)
 * @param {string} organizationID - selected organization
 * @param {string} controlSetId - selected control set, pass null to get all control sets
 * @returns {Promise<*[]|*>}
 */
export const getDocumentsByControlSets = async ({ organizationID, controlSetId }) => {
  if (!organizationID) {
    EventLogger("[getDocumentsByControlSets] Missing organizationID");
    return [];
  }

  /**
   * Get all control sets for an organization
   */
  let controlSets = await ListQueryBy({
    query: controlSetsByOwnerGroup,
    variables: {
      ownerGroup: organizationID,
    },
  });

  if (!Array.isArray(controlSets)) return [];

  /**
   * Filter by control set id if provided
   */
  if (controlSetId) {
    controlSets = controlSets.filter((x) => x.id === controlSetId);
  }

  /**
   * Get all controls for each control set
   */
  if (Array.isArray(controlSets)) {
    for (const controlSet of controlSets) {
      await getControlsByControlSet(controlSet).then((controls) => {
        controlSet.controls = { items: controls || [] };
      });
    }
  }

  /**
   * Keep list of evidences that have already been queried
   */
  const processedEvidenceList = [];

  /**
   * Get all activities for all evidences
   */
  if (Array.isArray(controlSets)) {
    for (const controlSet of controlSets) {
      const controls = controlSet?.controls?.items;
      if (Array.isArray(controls)) {
        for (const control of controls) {
          const evidenceLinks = control?.evidences?.items;
          if (Array.isArray(evidenceLinks)) {
            const getDocumentsPromises = [];

            for (const evidenceLink of evidenceLinks) {
              const evidence = evidenceLink?.evidence;
              if (evidence?.id) {
                /**
                 * Check if evidence is already being fetched
                 */
                const foundEvidence = processedEvidenceList.find((x) => x?.id === evidence?.id);
                /**
                 * Found evidence, use the activity from the processed list
                 */
                if (foundEvidence) {
                  evidence.activity = foundEvidence?.activity;
                } else {
                  /**
                   * Evidence not found, fetch the activity
                   */
                  getDocumentsPromises.push(
                    getEvidenceActivities(evidence).then((activities) => {
                      evidence.activity = { items: activities || [] };
                      processedEvidenceList.push(evidence);
                    }),
                  );
                }
              }
            }
            await Promise.allSettled(getDocumentsPromises);
          }
        }
      }
    }
  }

  return controlSets;
};

/**
 * Get all controls for a control set
 * @param {object} controlSet - control set object
 * @returns {Promise<*|*[]>}
 */
const getControlsByControlSet = async (controlSet) => {
  if (!controlSet?.id) {
    EventLogger("[getControlsByControlSet] Missing controlSet id");
    return [];
  }
  return await ListQueryBy({
    query: listControlsByControlSet,
    variables: {
      ownerGroup: controlSet?.ownerGroup,
      controlControlSetId: { eq: controlSet?.id },
    },
  });
};

/**
 * Get all activities for an evidence
 * @param {object} evidence - evidence object
 */
const getEvidenceActivities = async (evidence) => {
  if (!evidence?.id) {
    EventLogger("[getEvidenceActivities] Missing evidence id");
    return [];
  }
  return await ListQueryBy({
    query: activityByEvidence,
    variables: {
      evidenceID: evidence?.id,
    },
  });
};

const controlSetsByOwnerGroup = /* GraphQL */ `
  query ControlSetsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelControlSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlSetsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerGroup
      }
      nextToken
    }
  }
`;

const listControlsByControlSet = /* GraphQL */ `
  query ListControlsByControlSet(
    $ownerGroup: String
    $controlControlSetId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControlsByControlSet(
      ownerGroup: $ownerGroup
      controlControlSetId: $controlControlSetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statementNumber
        name
        isDisabled
        controlControlSetId
        evidences(limit: 1000) {
          items {
            evidence {
              id
              name
            }
          }
        }
        ownerGroup
      }
      nextToken
    }
  }
`;

const activityByEvidence = /* GraphQL */ `
  query ActivityByEvidence(
    $evidenceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEvidenceActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityByEvidence(
      evidenceID: $evidenceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        type
        pointOfContact {
          id
          firstName
          lastName
        }
        data
        evidenceID
        artifact {
          id
          name
          file {
            bucket
            key
            region
          }
          ownerGroup
        }
        documents(limit: 1000) {
          items {
            id
            name
            ownerGroup
            avStatus
            lastAVCheck
            visibility
            createdAt
            file {
              bucket
              region
              key
            }
            accessControl {
              password
              passwordOwnerEmail
              pointOfContacts
              roles
            }
          }
        }
        ownerGroup
      }
      nextToken
    }
  }
`;
