import { useModal } from "../../../hooks/views/useModal";
import { Alert, Button } from "reactstrap";
import React from "react";
import { useUserTimeout } from "./useUserTimeout";

/**
 * A modal that displays when a user gets signed out of the app, redirects them to login page
 * @returns {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean, modal: JSX.Element}}
 */
export const useSignOutModal = () => {
  const modalBody = (
    <div style={{ textAlign: "center" }}>
      <Alert color="danger">
        <h4>You have been automatically logged out from the Platform.</h4>
      </Alert>
      <Button color="primary" onClick={() => window.location.reload()}>
        <i className="icon-logout" style={{ marginRight: "1em" }} />
        Return to login page
      </Button>
    </div>
  );

  const signOutModal = useModal("Warning", modalBody, null, {
    width: "45vw",
    disableClickToggle: true,
    onClosed: () => window.location.reload(),
  });

  useUserTimeout({ signOutModal });

  return {
    ...signOutModal,
  };
};
