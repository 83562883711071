import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * Creates a TaskTagLink object
 * @param {object} task - the task that needs to be attached to the tag
 * @param {object} tag - the tag that needs to be attached to the task
 * @param {string} organizationID - the organization identifier of the task in the database
 * @return {Promise<*>}
 */
export const createTaskTagLink = (task, tag, organizationID) => {
  if (isNullOrUndefined(tag) || isNullOrUndefined(task?.id)) {
    EventLogger("Invalid input!");
  }

  const { createMutation: createTaskTagLink } = generateGraphql("TaskTagLink", ["taskID", "tagID", "__typename"]);

  return ItemMutation(createTaskTagLink, {
    taskID: task.id,
    tagID: tag.id,
    ownerGroup: organizationID,
  });
};
