import "./styles/App.scss";

import { API, graphqlOperation } from "@aws-amplify/api";
import { COGNITO_USER_POOL_ENV_URL, ENV_NAME, ENV_URL } from "../../env-config";
import { Route, Switch } from "react-router-dom";
import { modules, resources } from "@rivial-security/role-utils";
import { useEffect, useRef, useState } from "react";

import Amplify from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { EventLogger } from "../../utils/EventLogger/EventLogger";
import { ItemMutation } from "../../utils/Functions/Graphql/ItemMutation";
import { MainLayout } from "../Layout/MainLayout";
import { OrganizationProvider } from "@utils/Context/OrganizationContext";
import { QueryGetItem } from "../../hooks/graphql/useQueryGetItem";
import { Storage } from "@aws-amplify/storage";
import { UIContextProvider } from "@utils/Context/UIContext";
import awsconfig from "../../aws-exports";
import { generateGraphql } from "@rivial-security/generategraphql";
import { getMinimalOperationTeam } from "./functions/getMinimalOperationTeam";
import { getOrganization_minimal } from "../../views/AdminPanel/Organizations/graphql/__organizationGQL";
import { getRolePrecedenceTypeText } from "../../views/OrganizationManager/Roles/functions/getRolePrecedenceTypeText";
import { getUser_app_init } from "../../views/OrganizationManager/Users/graphql/__userGQL";
import { useGetLogo } from "../Layout/hooks/useGetLogo";
import { useLicenseAgreement } from "../../hooks/views/useLicenseAgreement";
import { useSentryConfig } from "./hooks/useSentryConfig";
import { useTranslation } from "react-i18next";

awsconfig.oauth = {
  domain: COGNITO_USER_POOL_ENV_URL,
  scope: ["openid", "email", "profile"],
  redirectSignIn: ENV_URL, // Replace with your redirect URL
  redirectSignOut: `${ENV_URL}/`, // Replace with your sign-out URL
  responseType: "code", // or 'token', depending on your requirements
};

// Amplify init
Amplify.configure(awsconfig);

Storage.configure(awsconfig);

const AuthenticatedApp = ({ onStateChange, client }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loggedInPointOfContactId, setLoggedInPointOfContactId] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("No Organization Selected");
  const [selectedOrganizationObjectMinimal, setSelectedOrganizationObjectMinimal] = useState({});
  const [minimalOperationTeam, setMinimalOperationTeam] = useState({});

  const [selectedHighlightColor, setSelectedHighlightColor] = useState("rgba(255,190,0,0.08)");
  const [role, setRole] = useState({});
  const [roleConfig, setRoleConfig] = useState({
    modules: {},
    resources: {},
    functions: {},
  });
  const [operationTeamID, setOperationTeamID] = useState();
  const [selectedOrgID, setSelectedOrgID] = useState(); // for an operation team user
  const [userCognitoGroups, setUserCognitoGroups] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [isInOperationTeamGroup, setIsInOperationTeamGroup] = useState(false);

  const [layoutAlertMessage, setLayoutAlertMessage] = useState("");
  const { t } = useTranslation();
  /**
   * Sentry configuration
   */
  const { sentryTrace } = useSentryConfig({
    selectedOrganization,
    selectedOrganizationObjectMinimal,
    operationTeamID,
    loggedInPointOfContactId,
    loggedInUserId,
    userEmail,
  });

  // default to 20 minute timeout
  const timeoutDuration = useRef(1200);

  // set timeoutDuration based on user preferences
  useEffect(() => {
    if (preferences?.loginOptions?.timeoutDuration) {
      timeoutDuration.current = preferences.loginOptions.timeoutDuration;
    }
  }, [preferences]);

  const setTimeoutDuration = (timeout) => {
    if (timeout > 0 && typeof timeout === "number" && !isNaN(timeout)) {
      timeoutDuration.current = timeout;
    }
  };

  const [sideBar, setSideBar] = useState(null);

  const startTime = useRef(new Date());

  const [isActive, setIsActive] = useState(true);

  // Initial Render
  useEffect(() => {
    getUserGroups();

    window.addEventListener("mousemove", isUserActive, false);
    window.addEventListener("mousedown", isUserActive, false);
    window.addEventListener("keypress", isUserActive, false);
    window.addEventListener("DOMMouseScroll", isUserActive, false);
    window.addEventListener("mousewheel", isUserActive, false);
    window.addEventListener("touchmove", isUserActive, false);
    window.addEventListener("MSPointerMove", isUserActive, false);

    isUserActive();
  }, []);

  useEffect(() => {
    if (!isActive) {
      client.resetStore().then(() => {
        EventLogger("User information was successfully reset");

        Auth.signOut()
          .then(() => {
            EventLogger(`User: ${userEmail} was successfully logged out`);

            window.removeEventListener("mousemove", isUserActive, true);
            window.removeEventListener("mousedown", isUserActive, true);
            window.removeEventListener("keypress", isUserActive, true);
            window.removeEventListener("DOMMouseScroll", isUserActive, true);
            window.removeEventListener("mousewheel", isUserActive, true);
            window.removeEventListener("touchmove", isUserActive, true);
            window.removeEventListener("MSPointerMove", isUserActive, true);

            if (typeof onStateChange === "function") {
              onStateChange("signedOut", null);
            }

            alert("You've been signed out due to inactivity");
          })
          .catch((err) => EventLogger(err));
      });
    }
  }, [isActive]);

  const isUserActive = () => {
    startTime.current = new Date();
  };

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime() / 1000;
      const past = new Date(startTime.current).getTime() / 1000;

      if (now - past > timeoutDuration.current) {
        setIsActive(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (preferences?.interfaceOptions) {
      if (preferences.interfaceOptions.highlightColor) {
        setHighlightColor(preferences.interfaceOptions.highlightColor);
      } else {
        setHighlightColor("rgba(255,190,0,0.08)");
      }
    }
  }, [preferences]);

  // This gets passed to the Context Provider so that the AdminLayout child may change the selected organization
  const toggleOrganization = (event) => {
    if (event?.target?.value) {
      setSelectedOrganization(event.target.value);
      const { getQuery, updateMutation } = generateGraphql("User", ["config"]);
      /**
       * Get and set user config with selected organization
       */
      QueryGetItem({ query: getQuery, itemId: loggedInUserId })
        .then(async (user) => {
          const userConfig = user?.config ? JSON.parse(user?.config) : {};
          userConfig["selectedOrganization"] = event.target.value;
          await ItemMutation(updateMutation, {
            id: loggedInUserId,
            config: JSON.stringify({ ...userConfig }),
          })
            .then(() => window.location.reload())
            .catch((err) => EventLogger("Cannot update user config", err));
        })
        .catch((err) => EventLogger("Cannot get logged in user", err));
    } else {
      EventLogger("[App.js -> toggleOrganization] Selected organization id is null");
    }
  };

  // Same functionality as toggleOrganization, but uses an ID param directly.
  const selectOrganization = (id) => {
    if (isAdmin) {
      toggleOrganization({ target: { value: id } });
    }
  };

  // This gets passed to the Context Provider so that the AdminLayout child may change the selected color
  const setHighlightColor = (hex) => {
    setSelectedHighlightColor(hex);
  };

  const [initialRoleConfig, setInitialRoleConfig] = useState({});

  const getUserGroups = async () => {
    // Gets CurrentSession token and extrapolates the groups attribute
    await Auth.currentSession()
      .then((response) => {
        const groups = response?.accessToken?.payload?.["cognito:groups"] ?? [];

        setUserCognitoGroups(groups);

        const isAdmin = !!groups?.includes("Admin");
        const isDeveloper = !!groups?.includes("Developer");
        /*
          Account Types
          ------
          standard
          admin
          operationTeamMember
       */
        let accountType = response?.idToken?.payload["custom:accountType"] ?? "";

        // Here for legacy purposes. custom:accountType should always be filled in going forward
        if (isAdmin) accountType = "admin";
        if (isDeveloper) accountType = "dev";
        const userEmail = response?.idToken?.payload?.email ?? "";
        const customPointOfContactId = response?.idToken?.payload?.["custom:pointOfContactId"] ?? "";
        const customUserId = response?.idToken?.payload?.["custom:userID"] ?? "";

        // This used by an Operation Team User
        const customSelectedOrgID = response?.idToken?.payload?.["custom:selectedOrgID"] ?? "";

        setIsAdmin(isAdmin);
        setIsDev(isDeveloper);
        setUserEmail(userEmail);
        setAccountType(accountType);
        setLoggedInPointOfContactId(customPointOfContactId);
        setLoggedInUserId(customUserId);

        // Last selected organization by an Operation Team user
        setSelectedOrgID(customSelectedOrgID);

        // Get logged-in user information
        getLoggedInUser({ loggedInUserId: customUserId, groups, userEmail, accountType });
      })
      .catch((err) => EventLogger("Cannot get user groups", err));
  };

  /**
   * License Agreement Hook
   */
  const licenseAgreement = useLicenseAgreement({ loggedInUserId });

  const getLoggedInUser = ({ loggedInUserId, groups, userEmail, accountType }) => {
    if (loggedInUserId) {
      API.graphql(
        graphqlOperation(getUser_app_init, {
          id: loggedInUserId,
        }),
      )
        .then((response) => {
          /**
           * User object from the database
           */
          const user = response?.data?.getUser;

          /**
           * Check if a valid user
           */
          if (user?.id) {
            /**
             * Check if a user has accepted the license agreement
             */
            if (!user?.acceptanceDate) {
              licenseAgreement?.setModalIsOpen(true);
            }

            /**
             * Get an Operation Team id if a user is part of an Operation Team
             */
            const operationTeamID = user?.operationTeam?.id;

            /**
             * Get last selected organization from the user.config.selectedOrganization
             */
            const userConfig = user?.config ? JSON.parse(user?.config) : {};

            let lastSelected = userConfig?.selectedOrganization;

            /**
             * If user.config.selectedOrganization is null then check user groups and try to find an uuid of the organization
             * This mostly for standard users since they dont have ability to select an organization
             * so a standard user will use organization id from the cognito group
             */
            if (!lastSelected) {
              for (const group of groups) {
                // UUID length is 36 characters.
                // Reference: https://en.wikipedia.org/wiki/Universally_unique_identifier#:~:text=In%20its%20canonical%20textual%20representation,hexadecimal%20characters%20and%204%20hyphens).
                if (
                  group &&
                  typeof group === "string" &&
                  group.length === 36 &&
                  !group.startsWith("role:") &&
                  group !== operationTeamID
                ) {
                  lastSelected = group;
                }
              }
            }

            /**
             * If the user is a member of an Operation Team
             * then use Cognito custom attribute "selectedOrgID" to select an organization
             */
            if (operationTeamID && selectedOrgID) {
              lastSelected = selectedOrgID;
            }

            /**
             * If user.config.selectedOrganization is null and user doesn't have an organization id in the cognito group
             * in this case user will see "No Organization Selected" message
             */
            setSelectedOrganization(lastSelected || "No Organization Selected");
            lastSelected && getOrganization(lastSelected);

            /**
             * Set user preferences
             */
            const preferences = user?.preferences ? JSON.parse(user?.preferences) : {};
            setPreferences(preferences);

            /**
             * Check if the user have any linked roles
             */
            if (!Array.isArray(user?.roleLinks?.items) || user?.roleLinks?.items.length === 0) {
              EventLogger(`Error! Platform User object doesn't have a role [App.js] ${JSON.stringify(user)}`);
            }

            if (!user?.roleLinks?.items[0]?.role?.roleConfig) {
              EventLogger(`Error! Platform User object doesn't have a role config [App.js] ${JSON.stringify(user)}`);
            }

            const roleLocal = user?.roleLinks?.items[0]?.role ? user?.roleLinks?.items[0]?.role : {};
            let roleConfigLocal = user?.roleLinks?.items[0]?.role?.roleConfig
              ? JSON.parse(user?.roleLinks?.items[0]?.role?.roleConfig)
              : {};

            roleLocal["roleConfig"] = roleConfigLocal;

            /**
             * Check if user is part of an Operation Team Cognito Group
             */
            const isInOperationTeamCognitoGroup = groups?.some((x) => x === operationTeamID);

            /**
             * If user is part of an operation team, add Operation Panel role permissions to local role config
             */
            if (operationTeamID && isInOperationTeamCognitoGroup && accountType === "operationTeamMember") {
              /**
               * Confirmed that the user is a member of an Operation Team
               */

              setOperationTeamID(operationTeamID);

              /**
               * Retrieve additional operation team details
               */
              getMinimalOperationTeam({ operationTeamID })
                .then((operationTeam) => {
                  setMinimalOperationTeam(operationTeam);
                })
                .catch((err) => {
                  EventLogger("Error getting minimal organization", err);
                });

              roleConfigLocal = {
                ...roleConfigLocal,

                functions: {
                  ...roleConfigLocal.functions,
                },

                modules: {
                  ...roleConfigLocal.modules,
                  [modules.OPERATION_PANEL]: true,
                  [modules.HELP_CENTER]: true,
                  [modules.ACCOUNT_MANAGER]: true,
                },

                resources: {
                  ...roleConfigLocal.resources,
                  [resources.OPERATION_TEAM_TEMPLATE]: {
                    name: "Operation Team Templates",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: true,
                    update: true,
                    delete: true,
                  },
                  [resources.OPERATION_TEAM_ORGANIZATION]: {
                    name: "Operation Team Organizations",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: false,
                    update: false,
                    delete: false,
                  },
                  [resources.OPERATION_TEAM_USER]: {
                    name: "Operation Team Users",
                    module: modules.OPERATION_PANEL,
                    read: true,
                    create: false,
                    update: false,
                    delete: false,
                  },

                  // Help Center permissions
                  [resources.HELP_ARTICLE]: {
                    name: "Help Article",
                    description: "A how-to article to aid in the use of the Platform",
                    module: modules.HELP_CENTER,
                    read: true,
                  },

                  // User Preferences permissions
                  [resources.PREFERENCES]: {
                    name: "Account Preferences",
                    description: "User Preferences such as interface settings and login options",
                    module: modules.ACCOUNT_MANAGER,
                    read: true,
                    update: true,
                  },
                },
              };
            }

            roleLocal["roleConfig"] = roleConfigLocal;

            // Setting pendo visitor and account details
            try {
              const visitor = {
                id: user?.id ?? loggedInUserId,
                name: user?.name,
                email: userEmail,
                roleId: roleLocal?.id,
                roleType: getRolePrecedenceTypeText({ precedence: roleLocal?.precedence }),
                roleName: roleLocal?.name,
                accountType,
              };

              const account = {
                id: user?.operationTeamID ?? user?.ownerGroup,
                environment: ENV_NAME,
              };

              window.pendo.initialize({
                visitor,
                account,
              });
            } catch (err) {
              EventLogger(`Error from App.js: Could not set pendo visitor and account details: ${JSON.stringify(err)}`);
            }

            // Setting role
            setRole(roleLocal);
            setRoleConfig(roleConfigLocal);
            setInitialRoleConfig(roleConfigLocal);
            setIsInOperationTeamGroup(isInOperationTeamCognitoGroup);
          }
        })
        .catch((err) => EventLogger(`Error from App.js: Could not get user object: ${JSON.stringify(err)}`));
    }
  };

  /**
   *
   * @param {string} organizationID
   * @return {object} {Promise<void>}
   */
  const getOrganization = async (organizationID) => {
    await API.graphql(
      graphqlOperation(getOrganization_minimal, {
        id: organizationID,
      }),
    )
      .then((response) => {
        if (response?.data?.getOrganization) {
          setSelectedOrganizationObjectMinimal(response.data.getOrganization);
        } else {
          EventLogger("Error from App.js [func getOrganization()]: Could not get organization object.");
        }
      })
      .catch((err) => EventLogger("Can not get organization object:", err));
  };

  const resetRoleConfig = () => setRoleConfig(initialRoleConfig);

  const { orgLogo, appLogo } = useGetLogo({
    organizationID: selectedOrganizationObjectMinimal.id,
  });

  return (
    <OrganizationProvider
      key="app_main_entry_point"
      id="app_main_entry_point"
      value={{
        isAdmin,
        isDev,
        userEmail,
        loggedInPointOfContactId,
        loggedInUserId,
        selectedOrganization,
        selectedOrganizationObjectMinimal,
        minimalOperationTeam,
        toggleOrganization,
        selectOrganization,
        setHighlightColor,
        selectedHighlightColor,
        timeoutDuration: timeoutDuration.current,
        setTimeoutDuration,
        sideBar,
        setSideBar,
        role,
        roleConfig,
        setRoleConfig,
        userCognitoGroups,
        operationTeamID,
        layoutAlertMessage,
        setLayoutAlertMessage,
        preferences,
        setPreferences,
        getUserGroups,
        resetRoleConfig,
        orgLogo,
        appLogo,
        accountType,
        isInOperationTeamGroup,
        sentryTrace,
      }}
    >
      {licenseAgreement?.modal}
      <Switch>
        <Route
          path={"/"}
          name={t("home")}
          render={() => (
            <UIContextProvider onStateChange={onStateChange}>
              <MainLayout />
            </UIContextProvider>
          )}
        />
      </Switch>
    </OrganizationProvider>
  );
};

export default AuthenticatedApp;
