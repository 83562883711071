/** DEPRECATED - DO NOT USE
 * Author: Jacob Blazina
 * Created At: N/A
 * Edits:
 *  - 7/22/19 JB: Added header signature.
 *
 * Description: A React ErrorBoundary component.
 *              A wrapper for a component to only crash at one level, and not crash the whole app.
 *              Currently have weird behavior with multiple error boundaries being used in same component.
 *
 */

import * as Sentry from "@sentry/react";

import { useContext, useEffect } from "react";

import { AmplifyEnv } from "./env-config";
import { EventLogger } from "./utils/EventLogger/EventLogger";
import { OrganizationContext } from "./utils/Context/OrganizationContext";
import { locationType } from "./analytics/constants/locationType";

/**
 * @description A fallback component to render when an error is caught.
 * @param {string} error - The error message.
 * @param {string} componentStack - The component stack trace.
 * @param {function} resetError - A function to reset the error.
 * @returns {JSX.Element}
 * @constructor
 */
const FallbackComponent = ({ error, componentStack, resetError }) => {
  const { userEmail, loggedInPointOfContactId, selectedOrganization } = useContext(OrganizationContext);

  useEffect(() => {
    if (error) {
      // This is where we could log the error messages to another place if needed, maybe to the console
      EventLogger("An Error boundary caught an Error!", {
        client: {
          env: AmplifyEnv,
          userEmail,
          pointOfContactId: loggedInPointOfContactId,
          organizationId: selectedOrganization,
        },
        error: error ? error.toString() : undefined,
        locationType: locationType.RENDER,
      });
    }
  }, [error]);

  return (
    <div>
      <h4>⚠️Oops! This component encountered an error.</h4>
      <details style={{ whiteSpace: "pre-wrap" }}>
        {AmplifyEnv !== "prod" ? error && error.toString() : "Please contact a Rivial Administrator for help"}
        <br />
        {AmplifyEnv !== "prod" && AmplifyEnv !== "prodbackup" && componentStack}
      </details>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={(props) => <FallbackComponent {...props} />}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
