import React from "react";

/**
 * @description place holder for an empty field
 * @type {JSX.Element}
 */
//Used with fields that have null or undefined values
export const emptyPlaceholder = (
  <span title="This field has no data" style={{ fontStyle: "italic", color: "lightGrey" }}>
    empty
  </span>
);
/**
 * @description place holder text for an invalid field
 * Used with fields that have null or undefined values.
 * @type {JSX.Element}
 */
//Used with fields that cause an error while being displayed
export const invalidPlaceholder = (
  <span title="This field has invalid data" style={{ fontStyle: "italic", color: "lightCoral" }}>
    invalid
  </span>
);
/**
 * @description The ProgressText component is used to display the progress of the long process.
 * @param {string} module - the module the field is in
 * @param {string} resource - the resource the field is in
 * @param {string} field - The field that the field is from
 * @returns {JSX.Element}
 */
//Used with fields that need additional permission to be shown
export const noPermissionPlaceholder = ({ module, resource, field }) => (
  <span>
    <i
      title={`You need read permissions for ${module} -> ${resource} -> ${field} to view this field.`}
      className="icon-lock"
      data-testid={`no-permission-field-read-${field}`}
    />
  </span>
);
