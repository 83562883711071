import { EventLogger } from "../../../../../EventLogger/EventLogger";
import { isNonEmptyArray } from "@rivial-security/func-utils";

/**
 * Filters files to only include files with allowed file types
 */
export const handleFileUploads = (files: File[], allowedFileTypes: string[]): File[] => {
  if (!isNonEmptyArray(files)) {
    EventLogger("No files provided for upload.");
    return [];
  }

  if (!isNonEmptyArray(allowedFileTypes)) {
    EventLogger("No allowed file types specified.");
    return [];
  }

  // Normalize allowed file types by removing leading dots
  const normalizedAllowedFileTypes = allowedFileTypes.map((type) => (type.startsWith(".") ? type.substring(1) : type));

  try {
    return files.filter((file) => {
      const extension = file?.name.split(".").pop()?.toLowerCase();
      return extension && normalizedAllowedFileTypes.includes(extension);
    });
  } catch (error: unknown) {
    EventLogger("Error filtering files:", error as object);
    return [];
  }
};
