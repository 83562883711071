import { Alert, Button, ButtonGroup, PopoverBody, PopoverHeader, Row, UncontrolledPopover } from "reactstrap";
import React, { useEffect, useState } from "react";

import { CompareActionsField } from "../../utils/CustomFields/CompareActionsField.js";
import { CompareField } from "../../utils/CustomFields/CompareField";
import { EventLogger } from "../../utils/EventLogger/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { modules } from "@rivial-security/role-utils";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";
import { useTable } from "./useTable";

/**
 * Allows to visually select what to do with duplicate items when two sources are merged (this is done in 4 stages)
 * 1) The hook is created with source item information (available fields are shown to the user)
 * 2) User selects which field(s) uniquely identify the item to be used for duplicate search (primary fields)
 * 3) The user is shown a table of item conflicts with possible resolution actions
 * 4) As conflicts are resolved the database operations needed to merge the original and new item arrays can be generated
 * @param {string[]} allFields - array of field names that can be used as primary fields
 * @param {function} getIncomingData - function that retrieves the latest state of incoming data items
 * @returns {}
 */
export const useDuplicateScanner = ({ allFields, getIncomingData }) => {
  //Resolution Actions Definition
  // replace - deletes the old item completely and creates a new one from the incoming item (dangerous, but helpful for Admins)
  // update - updates the old item with any differing fields
  // ignore - ignores this entry completely, moves on to the next one
  // delete - deletes the old item completely. Does not create a new item
  const resolutionType = {
    NONE: "none",
    REPLACE: "replace",
    UPDATE: "update",
    IGNORE: "ignore",
    DELETE: "delete",
  };

  //[Role Hooks]
  const module = modules.ADMINISTRATOR;
  const resource = "duplicateScanner";
  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking: true,
  });
  const ignoreIsEnabled = true;
  const updateIsEnabled = true;
  const replaceIsEnabled = true;
  const deleteIsEnabled = true;

  //[State Hooks]
  const [primaryFields, setPrimaryFields] = useState([]);
  const [sourceItemKeys, setSourceItemKeys] = useState({});
  const [sourceItems, setSource] = useState([]);
  const [incomingItems, setIncoming] = useState([]);
  const [conflicts, setConflicts] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [reuseConflicts, setReuseConflicts] = useState(false);
  const [alert, setAlert] = useState();
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const newCustomFields = [];
    //Add the comparison fields
    for (const field of allFields) {
      newCustomFields.push({
        field: field,
        propName: "fieldInfo",
        component: <CompareField />,
      });
    }

    //Add the actions field
    newCustomFields.push({
      field: "actions",
      propName: "fieldInfo",
      component: <CompareActionsField />,
    });

    setCustomFields(newCustomFields);
  }, [JSON.stringify(allFields)]);

  // Creates a data structure for easier duplicate spotting
  useEffect(() => {
    const newSourceItemKeys = {};

    sourceItems.map((item, index) => {
      const itemKey = generateItemKey(item);

      //if a key already exists add the source index to the array
      if (newSourceItemKeys.hasOwnProperty(itemKey)) {
        newSourceItemKeys[itemKey].push(index);
      } else {
        newSourceItemKeys[itemKey] = [index];
      }
    });

    setSourceItemKeys({ ...newSourceItemKeys });
  }, [primaryFields, sourceItems]);

  // Updates duplicate entries list when the primary keys of the source change
  useEffect(() => {
    if (
      primaryFields &&
      Array.isArray(primaryFields) &&
      primaryFields.length !== 0 &&
      sourceItemKeys &&
      incomingItems &&
      Array.isArray(incomingItems)
    ) {
      if (reuseConflicts) {
        setReuseConflicts(false);
        setConflicts([...conflicts]);
      } else {
        findIncomingItemConflicts(incomingItems);
      }
    } else {
      setConflicts([]);
    }
  }, [sourceItemKeys, incomingItems]);

  const generateItemKey = (item) => {
    let itemKey = "";

    if (!item) return itemKey;

    primaryFields.map((field) => {
      if (item.hasOwnProperty(field)) {
        itemKey = itemKey + item[field];
      }
    });

    return itemKey;
  };

  //[Conflict Resolutions]
  const resolveConflict = (index, type) => {
    const temp = [...conflicts];

    temp[index].resolution = type;

    setConflicts(temp);
  };

  const resolveAllConflicts = (type) => {
    const temp = [...conflicts];

    for (let i = 0; i < conflicts.length; i++) {
      temp[i].resolution = type;
    }

    setConflicts(temp);
  };

  const updatePrimaryField = (fieldName) => {
    //Check if requested primary key is inside all fields
    if (!allFields || !Array.isArray(allFields) || !allFields.includes(fieldName)) {
      return;
    }

    //Add the field if it is not already a primary field, remove it otherwise
    const fieldIndex = primaryFields.indexOf(fieldName);
    const tempFields = [...primaryFields];
    if (fieldIndex !== -1) {
      tempFields.splice(fieldIndex, 1);
    } else {
      tempFields.push(fieldName);
    }

    setPrimaryFields(tempFields);
  };

  //[Conflict Table]

  /**
   *
   * @typedef ConflictObject
   * @property {number} key - a unique row identifier for the conflict
   * @property {Object} source - an item which is already in the database
   * @property {Object} incoming - an incoming item that matches the source object by a primary field
   * @property {string} resolution - one of the following resolution types "none", "ignore", "update", "replace", "delete" for the conflict
   * @property {function} resolve - a function that will mark the proper "resolution" for the conflict if called
   */

  /**
   * Returns array of field information objects with properties for original and incoming info
   * @returns {ConflictObject[]} with properties of the source info, incoming info, resolution type, and resolution function
   */
  const getConflictData = () => {
    const conflictData = [];

    for (let i = 0; i < conflicts.length; i++) {
      const conflictRow = {};

      for (const field of allFields) {
        //Determine if needed field info is present
        let sourceExists, incomingExists;
        try {
          sourceExists = !isNullOrUndefined(sourceItems[conflicts[i].sourceIndex][field]);
        } catch (e) {
          sourceExists = false;
        }
        try {
          incomingExists = !isNullOrUndefined(incomingItems[conflicts[i].incomingIndex][field]);
        } catch (e) {
          incomingExists = false;
        }

        let sourceValue = "";
        if (sourceExists) {
          sourceValue = sourceItems[conflicts[i].sourceIndex][field];
          if (typeof sourceValue === "boolean") {
            sourceValue = sourceValue ? "true" : "false";
          }
        }

        conflictRow[field] = {
          key: conflictData.length,
          source: sourceExists ? sourceValue : undefined,
          incoming: incomingExists ? incomingItems[conflicts[i].incomingIndex][field] : undefined,
          resolution: conflicts[i].resolution,
        };
      }

      //Adding actions method for a specific row (will get passed down to the custom field in duplicates table)
      conflictRow["actions"] = {
        resolution: conflicts[i].resolution,
        resolve: (type) => {
          resolveConflict(i, type);
        },
      };
      conflictData.push(conflictRow);
    }

    return conflictData;
  };

  const conflictTable = useTable({
    fields: [...allFields, "actions"],
    data: [],
    config: {
      disableHighlight: true,
    },
    customFields,
    disableRoleChecking: true,
  });

  useEffect(() => {
    conflictTable.setData(getConflictData());
  }, [conflicts]);

  /**
   * Repopulates conflicting csv rows
   * @param {Object[]} incomingItems - new data matrix to be compared with the source items
   */
  const findIncomingItemConflicts = (incomingItems) => {
    const newConflicts = [];

    //Check if any incoming item has matching keys to source items
    if (incomingItems && Array.isArray(incomingItems)) {
      for (let i = 0; i < incomingItems.length; i++) {
        try {
          const itemKey = generateItemKey(incomingItems[i]);
          if (sourceItemKeys.hasOwnProperty(itemKey)) {
            for (const sourceIndex in sourceItemKeys[itemKey]) {
              newConflicts.push({
                sourceIndex: sourceItemKeys[itemKey][sourceIndex],
                incomingIndex: i,
                resolution: resolutionType.NONE,
              });
            }
          }
        } catch (e) {
          EventLogger("Failed to do a conflict search on a csv item!", e);
        }
      }
    }

    setConflicts(newConflicts);
  };

  /**
   * Refreshes data shown in the duplicates table without resetting any conflicts or action selections
   */
  const refreshData = () => {
    const newIncomingData = getIncomingData && getIncomingData();

    if (
      !newIncomingData ||
      !Array.isArray(newIncomingData) ||
      !incomingItems ||
      !Array.isArray(incomingItems) ||
      newIncomingData.length !== incomingItems.length
    ) {
      EventLogger(
        `Cannot refresh data since the shape of data has changed from - ${incomingItems.length} to ${newIncomingData.length}`,
      );
      setAlert(
        <Alert color={"danger"}>
          Cannot refresh data since the amount of data rows has changed. Try closing the duplicate scanner and opening
          again.
        </Alert>,
      );
      return;
    }

    if (newIncomingData) {
      setReuseConflicts(true);
      setIncoming([...newIncomingData]);
    }
  };

  /**
   * Allows to get the total number of conflicts that the scanner picked up currently
   * @returns {number} the amount of unresolved duplicates
   */
  const unresolvedConflicts = () => {
    if (!conflicts || !Array.isArray(conflicts)) return 0;

    let unresolvedAmount = 0;
    for (const conflict of conflicts) {
      if (conflict && conflict.resolution && conflict.resolution === resolutionType.NONE) {
        unresolvedAmount++;
      }
    }

    return unresolvedAmount;
  };

  /**
   * Set new data items that is to be merged
   * @param {Object[]} newItems
   * @param {boolean} isSource - TRUE if "newItems" are to be set as source, FALSE if items are incoming (from CSV or elsewhere)
   */
  const setItems = (isSource, newItems) => {
    if (!newItems || !Array.isArray(newItems)) {
      return;
    }

    if (isSource) {
      setSource(newItems);
    } else {
      setIncoming(newItems);
    }
  };

  /**
   * Makes a list of database operations that properly merge the information
   * Make sure to do mutate -> delete -> add in that order
   * @returns {{add: Object[], update: Object[], delete: Object[]}}
   */
  const getFinalItemChanges = () => {
    //Check for empty incoming data (add nothing in that case)
    if (!incomingItems || !Array.isArray(incomingItems) || incomingItems.length <= 0) {
      return { add: [], delete: [], update: [] };
    }

    //Check for empty source (add all incoming items in that case)
    if (!sourceItems || !Array.isArray(sourceItems) || incomingItems.length <= 0) {
      return { add: incomingItems, delete: [], update: [] };
    }

    const deleteIDs = {};
    const itemsToDelete = [];
    const itemsToAdd = [];
    const itemsToUpdate = [];

    //Handle conflict causing items
    const processedIndexes = {}; //allows to skip incoming items that have been already added to
    if (conflicts && Array.isArray(conflicts)) {
      //Iterate over each conflict and form lists of items to add/delete
      for (const i in conflicts) {
        const foundSource =
          conflicts[i] != null && conflicts[i].sourceIndex != null && sourceItems[conflicts[i].sourceIndex] != null
            ? sourceItems[conflicts[i].sourceIndex]
            : false;
        const foundIncoming =
          conflicts[i] != null &&
          conflicts[i].incomingIndex != null &&
          incomingItems[conflicts[i].incomingIndex] != null
            ? incomingItems[conflicts[i].incomingIndex]
            : false;

        if ((conflicts[i] && conflicts[i].resolution && foundSource && foundIncoming) || !isEnabled) {
          if (conflicts[i].resolution === resolutionType.UPDATE) {
            const updatedItem = {};
            for (const property in foundSource) {
              if (foundIncoming.hasOwnProperty(property) && foundIncoming[property] !== "") {
                updatedItem[property] = foundIncoming[property];
              } else {
                updatedItem[property] = foundSource[property];
              }
            }
            itemsToUpdate.push(updatedItem);
          } else if (conflicts[i].resolution === resolutionType.REPLACE) {
            const updatedItem = {};
            for (const property in foundSource) {
              if (foundIncoming.hasOwnProperty(property)) {
                updatedItem[property] = foundIncoming[property];
              } else {
                updatedItem[property] = foundSource[property];
              }
            }
            itemsToUpdate.push(updatedItem);
            // TODO: in the future possibly implement a hard reset (delete fully, then add)
            // } else if (conflicts[i].resolution === resolutionType.REPLACE) {
            //   //Ensure there is no item that will be deleted twice
            //   if (foundSource.id && !deleteIDs.hasOwnProperty(foundSource.id)) {
            //     deleteIDs[foundSource.id] = true;
            //     itemsToDelete.push(foundSource);
            //   }
            //   itemsToAdd.push(foundIncoming);
          } else if (conflicts[i].resolution === resolutionType.DELETE) {
            if (foundSource.id && !deleteIDs.hasOwnProperty(foundSource.id)) {
              deleteIDs[foundSource.id] = true;
              itemsToDelete.push(foundSource);
            }
          } else {
            //pass - ignore the new entry with "none" or "ignore" resolutions
          }
        } else {
          //pass - ignore conflict entries without resolutions or if duplicate scanner not enabled
        }

        //Keeping track of incoming items that went through conflict processing (check for null because index 0 is false)
        if (conflicts[i] && conflicts[i].incomingIndex != null) {
          processedIndexes[conflicts[i].incomingIndex] = 1;
        }
      }
    }

    //Handle non-conflict items (simple shallow copy)
    for (let i = 0; i < incomingItems.length; i++) {
      if (!processedIndexes.hasOwnProperty(i)) {
        itemsToAdd.push(incomingItems[i]);
      }
    }

    return { add: itemsToAdd, delete: itemsToDelete, update: itemsToUpdate };
  };

  //[GUI of the Resolver]
  let display = (
    <>
      {isEnabled ? (
        <Alert color={"danger"}>
          {
            "You do not have permissions to use the duplicate scanner tool. (Go to Administrator -> DuplicateScanner to enable)"
          }
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
  if (checkPermissionsHook.resource.read) {
    display = (
      <>
        {isEnabled ? (
          <>
            {allFields && Array.isArray(allFields) && allFields.length >= 1 ? (
              <>
                {alert != null ? alert : <></>}
                <Row>
                  <h5 style={{ paddingRight: "3em" }}> Duplicate entries search: </h5>
                  {getIncomingData != null ? (
                    <Button
                      title="Refresh incoming data without erasing conflict actions"
                      color="success"
                      onClick={() => {
                        refreshData();
                      }}
                    >
                      <i className="icon-refresh" /> Refresh Data
                    </Button>
                  ) : (
                    <div />
                  )}
                  <div style={{ minWidth: "1em" }} />
                  <Button style={{ paddingLeft: "1em" }} color="info" size="sm" id="howToPopover" type="button">
                    {" "}
                    <i className="icon-question" /> Color Dictionary
                  </Button>
                  <UncontrolledPopover placement="top" target="howToPopover">
                    <PopoverHeader>What do the colors mean?</PopoverHeader>
                    <PopoverBody>
                      <b>Before an action is selected:</b>
                      <ul>
                        <li>Black - original data</li>
                        <li>
                          <span style={{ color: "#00B449" }}>Green</span> - differing incoming data
                        </li>
                        <li>
                          <span style={{ color: "#3B90FF" }}>Blue</span> - identical data
                        </li>
                      </ul>
                      <b>After an action is selected:</b>
                      <ul>
                        <li>
                          Black & <span style={{ color: "#3B90FF" }}>Blue</span> - saved original data
                        </li>
                        <li>
                          <span style={{ color: "#00B449" }}>Green</span> - saved incoming data
                        </li>
                        <li>
                          <span style={{ color: "#D10000" }}>
                            <del>Red</del>
                          </span>{" "}
                          - ignored or deleted data
                        </li>
                      </ul>
                    </PopoverBody>
                  </UncontrolledPopover>
                </Row>
                <p> Select primary field(s) - </p>
                <ButtonGroup style={{ marginLeft: "1em" }}>
                  {allFields.map((field) => {
                    return (
                      <Button
                        color={primaryFields.includes(field) ? "primary" : "secondary"}
                        onClick={() => updatePrimaryField(field)}
                      >
                        {field}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </>
            ) : (
              <p> No fields on which to perform duplicate search</p>
            )}
            {primaryFields && Array.isArray(primaryFields) && primaryFields.length >= 1 ? (
              <>
                <br />
                <br />
                {conflicts && Array.isArray(conflicts) && conflicts.length >= 1 ? (
                  <>
                    <Row style={{ marginLeft: "1em" }}>
                      <Button
                        color={"secondary"}
                        disabled={!ignoreIsEnabled}
                        title={
                          ignoreIsEnabled
                            ? "Ignore Incoming Item"
                            : "No permissions to use ignore (enable read for Administrator -> DuplicateScanner -> Ignore)"
                        }
                        onClick={() => resolveAllConflicts(resolutionType.IGNORE)}
                      >
                        Ignore All
                      </Button>
                      <Button
                        style={{ marginLeft: ".5em" }}
                        color={"success"}
                        disabled={!updateIsEnabled}
                        title={
                          updateIsEnabled
                            ? "Update Source Item"
                            : "No permissions to use update (enable read for Administrator -> DuplicateScanner -> Update)"
                        }
                        onClick={() => resolveAllConflicts(resolutionType.UPDATE)}
                      >
                        Update All
                      </Button>
                      <Button
                        style={{ marginLeft: ".5em" }}
                        color={"danger"}
                        disabled={!replaceIsEnabled}
                        title={
                          replaceIsEnabled
                            ? "Replace Source Item"
                            : "No permissions to use replace (enable read for Administrator -> DuplicateScanner -> Replace)"
                        }
                        onClick={() => resolveAllConflicts(resolutionType.REPLACE)}
                      >
                        Replace All
                      </Button>
                      <Button
                        style={{ marginLeft: ".5em" }}
                        color={"danger"}
                        disabled={!deleteIsEnabled}
                        title={
                          deleteIsEnabled
                            ? "Delete Source Item"
                            : "No permissions to use delete (enable read for Administrator -> DuplicateScanner -> Delete)"
                        }
                        onClick={() => resolveAllConflicts(resolutionType.DELETE)}
                      >
                        Delete All
                      </Button>
                    </Row>
                    <br />
                    {conflictTable.display}
                  </>
                ) : (
                  <p> No Duplicates Found </p>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  /**
   * Wrapper function over setIsEnabled that performs some cleanup on close
   * @param isOpened - TRUE if scanner needs to be opened, FALSE if closed
   */
  const scannerIsEnabled = (isOpened) => {
    if (!isOpened) {
      setAlert(null);
    }

    setIsEnabled(isOpened);
  };

  return {
    setItems,
    scannerIsEnabled,
    getFinalItemChanges,
    unresolvedConflicts,
    conflicts,
    display,
  };
};
