import { Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import ChangeInImplementation from "../../../customFields/ChangeInImplementation";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useStateEffect } from "../../../../../../hooks/functional/useStateEffect";

/**
 * Input for the 'Change in Implementation' field for a Risk Recommendation
 * @param selectedRiskControlID
 * @param riskControls
 * @param onChangeCallback
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeInImplementationField = ({ selectedRiskControlID, riskControls, onChangeCallback, defaultValue }) => {
  const [changeInImplementation, setChangeInImplementation] = useState(defaultValue || 0);
  const [newImplementationRating, setNewImplementationRating] = useState(0);

  const [riskControl] = useStateEffect(null, [riskControls, selectedRiskControlID], () => {
    if (!isNullOrUndefined(riskControls) && !isNullOrUndefined(selectedRiskControlID)) {
      return riskControls.find((item) => item.id === selectedRiskControlID);
    }
  });

  // Calculate the change in implementation
  useEffect(() => {
    let result = 0;
    let changeInImplementationDecimal;
    try {
      changeInImplementationDecimal = parseFloat(changeInImplementation / 100);
    } catch (e) {
      changeInImplementationDecimal = 0;
    }

    let currentImplementationRatingDecimal;
    try {
      currentImplementationRatingDecimal = parseFloat(riskControl?.implementationRating);
    } catch (e) {
      currentImplementationRatingDecimal = 0;
    }

    if (Number.isFinite(currentImplementationRatingDecimal)) {
      if (Number.isFinite(changeInImplementationDecimal)) {
        result = currentImplementationRatingDecimal + changeInImplementationDecimal;
      } else {
        result = currentImplementationRatingDecimal;
      }
    } else {
      if (Number.isFinite(changeInImplementationDecimal)) {
        result = changeInImplementationDecimal;
      }
    }

    setNewImplementationRating(result);
  }, [selectedRiskControlID, changeInImplementation, riskControls, defaultValue, riskControl]);

  useEffect(() => {
    onChangeCallback && onChangeCallback(changeInImplementation);
  }, [changeInImplementation]);

  return (
    <FormGroup>
      <DataLoader isLoading={isNullOrUndefined(riskControl)} isEnoughData={selectedRiskControlID}>
        <Row>
          <Col md={4}>
            <ChangeInImplementation
              newPercent={newImplementationRating}
              oldPercent={Number.isFinite(riskControl?.implementationRating) ? riskControl?.implementationRating : 0}
            />
          </Col>
          <Col>
            <InputGroup>
              <Input
                type="number"
                step={1}
                id="implementationChange"
                value={changeInImplementation}
                onChange={(e) => setChangeInImplementation(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </DataLoader>
    </FormGroup>
  );
};

export default ChangeInImplementationField;
