import { Alert, Button } from "@mui/material";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  evidenceResponseSubmissionSteps,
  useLandingPagePleaseWaitModal,
} from "../components/EvidenceLandingPage/hooks/useLandingPagePleaseWaitModal";
import { formattedName, isNullOrUndefined } from "@rivial-security/func-utils";

import { DisabledWrapper } from "../../../../utils/GenericComponents/DisabledWrapper";
import { EVIDENCE_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import EvidenceResponseForm from "../components/EvidenceLandingPage/components/EvidenceResponseForm";
import { GetQuery } from "@rivial-security/appsync-utils";
import Loader from "../../../../utils/LoadingComponents/Loader";
import NotEnoughData from "../../../../utils/GenericComponents/NotEnoughData";
import NoteAdd from "@mui/icons-material/NoteAdd";
import { PriorityBadge } from "../../../Program/PriorityActions/CustomFields/PriorityBadge/PriorityBadge";
import { STEP_STATUS } from "../../../../hooks/views/usePleaseWaitModal";
import SelectEvidenceType from "../components/EvidenceLandingPage/components/SelectEvidenceType";
import { SelectItemGrid } from "../../../../utils/GenericComponents/SelectItemGrid";
import { createEvidenceActivity } from "../functions/createEvidenceActivity";
import { createEvidenceArtifact } from "../components/EvidenceLandingPage/functions/createEvidenceArtifact";
import { generateGraphql } from "@rivial-security/generategraphql";
import { onArtifactUpload } from "../functions/onArtifactUpload";
import { onManualEvidenceSubmission } from "../functions/onManualEvidenceSubmission";
import { resources } from "@rivial-security/role-utils";
import { useEvidenceLandingPageRoute } from "./useEvidenceLandingPageRoute";
import { useForm } from "../../../../hooks/views/useForm/useForm";
import { useGUID } from "../../../../hooks/functional/useGUID";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../../hooks/views/useModal";
import { useOrganizationConfig } from "../../../AdminPanel/Organizations/hooks/useOrganizationConfig";
import { useOrganizationContext } from "../../../AdminPanel/Organizations/hooks/useOrganizationContext";
import { usePointOfContactDataGrid } from "../../../OrganizationManager/PointOfContacts/hooks/usePointOfContactDataGrid";
import { useStateEffect } from "../../../../hooks/functional/useStateEffect";

/**
 * Allows the user to manually record an evidence
 * @param {object} evidence - the evidence item for which to record new artifacts
 * @param {string} organizationID - the currently selected organization id, the evidence needs to be part of this organization
 * @param {function} resetFunction - the function to call to update "evidence" prop
 * @param {boolean} enableHighPriorityItemCheck - if TRUE will check for an active medium - high priority action items and display a warning if found
 * @return {{button: JSX.Element, setModalIsOpen: function(*): void, modalButton: JSX.Element, modalIsOpen: boolean, display: JSX.Element, modal: JSX.Element, key: number, toggleModal: function(): void, setKey: (value: (((prevState: number) => number) | number)) => void}}
 */
export const useRecordEvidence = ({
  evidence,
  organizationID,
  resetFunction,
  enableHighPriorityItemCheck = false,
} = {}) => {
  const organizationConfig = useOrganizationConfig({
    organizationID,
    key: "aiOptIn",
  });

  const pleaseWaitModal = useLandingPagePleaseWaitModal({
    isGuest: false,
    aiOptIn: organizationConfig?.value,
  });
  const history = useHistory();
  const [guid] = useGUID();
  const organizationContext = useOrganizationContext();

  /**
   * New evidence activity after an evidence was recorded
   */
  const [key, setKey] = useState(0);

  /**
   * Handles submit logic for evidence response
   * @param activityInput
   * @returns {Promise<void>}
   */
  const onSubmitResponse = async (activityInput) => {
    /**
     * Assign a comment for the new evidence activity
     */
    if (additionalInputForm?.input?.comment) {
      Object.assign(activityInput, {
        comment: additionalInputForm?.input?.comment,
      });
      additionalInputForm.getInitialState();
    }

    pleaseWaitModal.setModalIsOpen(true);

    // Upload document - completed beforehand

    // Create the Evidence Activity
    pleaseWaitModal.setStepsStatusById({
      ids: [evidenceResponseSubmissionSteps.PROCESSING_RESPONSE],
      status: STEP_STATUS.COMPLETE,
      startNextStep: true,
    });

    const formPointOfContactID = additionalInputForm?.input?.pointOfContactID;
    const loggedInPointOfContactID = organizationContext?.loggedInPointOfContactId;
    const pointOfContactID = organizationContext?.isAdmin ? formPointOfContactID : loggedInPointOfContactID;
    const createEvidenceActivityResult = await createEvidenceActivity(activityInput, evidence.id, organizationID, {
      id: pointOfContactID,
    });
    let evidenceActivity = {};
    try {
      evidenceActivity = JSON.parse(createEvidenceActivityResult?.createEvidenceActivity);
    } catch (e) {
      EventLogger("Error parsing create evidence activity result!", e);
    }
    pleaseWaitModal.setStepsStatusById({
      ids: [evidenceResponseSubmissionSteps.LOGGING_EVIDENCE_ACTIVITY],
      status: STEP_STATUS.COMPLETE,
      startNextStep: true,
    });

    await onManualEvidenceSubmission({ evidence });
    pleaseWaitModal.setStepsStatusById({
      ids: [
        evidenceResponseSubmissionSteps.UPDATING_ACTION_ITEM,
        evidenceResponseSubmissionSteps.UPDATING_EVIDENCE_STATUS,
      ],
      status: STEP_STATUS.COMPLETE,
      startNextStep: true,
    });

    // Create the Evidence Artifact, first retrieve the point of contact
    try {
      const { getQuery: getPointOfContactQuery } = generateGraphql("PointOfContact", [
        "firstName",
        "lastName",
        "email",
      ]);
      const pointOfContact = await GetQuery({
        query: getPointOfContactQuery,
        variables: {
          id: pointOfContactID,
        },
      });
      const artifact = await createEvidenceArtifact({
        organizationID,
        evidenceActivity,
        evidence,
        pointOfContact,
        artifactType: evidenceType,
      });
      pleaseWaitModal.setStepsStatusById({
        ids: [evidenceResponseSubmissionSteps.RECORDING_ARTIFACT_INFORMATION],
        status: STEP_STATUS.COMPLETE,
      });

      pleaseWaitModal.setStepsStatusById({
        ids: [evidenceResponseSubmissionSteps.PROCESSING_ARTIFACT],
        status: STEP_STATUS.IN_PROGRESS,
      });
      // Process uploaded artifact
      onArtifactUpload({
        organizationID,
        artifactID: artifact?.id,
        sentryTrace: organizationContext?.sentryTrace,
      }).then(() => {
        EventLogger("Artifact Processing Complete");
      });
      pleaseWaitModal.setStepsStatusById({
        ids: [evidenceResponseSubmissionSteps.PROCESSING_ARTIFACT],
        status: STEP_STATUS.COMPLETE,
      });
    } catch (e) {
      pleaseWaitModal.setStepsStatusById({
        ids: [evidenceResponseSubmissionSteps.RECORDING_ARTIFACT_INFORMATION],
        status: STEP_STATUS.ERROR,
      });
    }

    pleaseWaitModal.setFinished(true);
    pleaseWaitModal.setModalIsOpen(false);
    activityModal.setModalIsOpen(false);
    resetFunction && resetFunction();
    setKey((key) => key + 1);
  };

  /**
   * Retrieves the evidence type to display the proper form
   */
  const [evidenceType, setEvidenceType] = useStateEffect(
    evidence?.type || EVIDENCE_TYPES.DOCUMENT,
    [evidence?.type],
    () => {
      if (!isNullOrUndefined(evidence?.type)) {
        return evidence?.type;
      } else {
        return EVIDENCE_TYPES.DOCUMENT;
      }
    },
  );

  const additionalInputForm = useForm({
    disableSubmitButton: true,
    disableResetButton: true,
    fieldConfig: {
      comment: {
        inputType: "textarea",
        label: "Comment",
        tooltip: "Leave a comment for the person reviewing this evidence artifact",
      },
      pointOfContactID: {
        inputType: "item-select",
        label: "(Admin) Submitting as Point of Contact",
        required: true,
        tooltip: "Select the Point of Contact that is submitting this evidence artifact",
        isHidden: !organizationContext?.isAdmin,
        itemSelectConfig: {
          grid: <SelectItemGrid organizationID={organizationID} gridHook={usePointOfContactDataGrid} height={"100%"} />,
          typename: resources.POINT_OF_CONTACT,
          outputAsObject: false,
          formatSelectedItem: (pointOfContact) => formattedName({ pointOfContact }),
        },
      },
    },
  });

  const submissionDisabled = organizationContext?.isAdmin && !additionalInputForm?.input?.pointOfContactID;

  const display = (
    <div>
      {pleaseWaitModal.modal}
      {/*Alert stating that admins need to select a point of contact that's from this org*/}
      {submissionDisabled && (
        <Alert severity="warning">
          Because you are logged in as an administrator, please select a point of contact from this organization to use
          as the individual submitting evidence.
        </Alert>
      )}
      <DisabledWrapper
        message={"Select a local point of contact to submit an evidence artifact as an administrator."}
        isDisabled={submissionDisabled}
        opacityPercentage={50}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SelectEvidenceType evidenceType={evidenceType} setEvidenceType={setEvidenceType} />
        </div>
        <EvidenceResponseForm
          onSubmitResponse={onSubmitResponse}
          evidence={evidence}
          evidenceType={evidenceType}
          organizationID={organizationID}
        />
      </DisabledWrapper>
      <div style={{ marginTop: "1em" }}>{additionalInputForm.display}</div>
    </div>
  );

  const activityModal = useModal("Evidence Activity Form", display, null, {
    width: "65vw",
  });

  //Determines how the user is allowed to record evidence (landing page or manual entry)
  const { route, priority, isLoading, getNoLandingPageMessage } = useEvidenceLandingPageRoute({
    evidence,
    recordEvidenceModal: activityModal,
  });
  const [priorityModalShownOnce, setPriorityModalShownOnce] = useState(false);
  const [evidenceArtifactRequestOpen, setEvidenceArtifactRequestOpen] = useState(false);
  useEffect(() => {
    if (route && priority >= 0 && !priorityModalShownOnce && enableHighPriorityItemCheck) {
      setPriorityModalShownOnce(true);
      setEvidenceArtifactRequestOpen(true);
    }
  }, [priority, route]);
  const [noActionItemPopoverOpen, setNoActionItemPopoverOpen] = useState(false);
  const toggleNoActionItemPopover = () => {
    //Only show if the more important popover is not open
    if (!evidenceArtifactRequestOpen) {
      setNoActionItemPopoverOpen((noActionItemPopoverOpen) => !noActionItemPopoverOpen);
    }
  };

  const onRecordEvidence = async () => {
    if (isLoading) {
      return;
    }

    if (route) {
      //Navigate to the landing page if one exists
      history.push(route.replace(/#/g, ""));
    } else {
      //Otherwise, show the manual entry form confirm modal
      toggleNoActionItemPopover();
    }
  };

  const buttonID = `record-evidence-artifact${guid}`;
  const modalButton = (
    <div>
      <Button
        id={buttonID}
        onClick={(e) => {
          onRecordEvidence();
          e.stopPropagation();
        }}
      >
        {isLoading ? <Loader /> : <NoteAdd />}
      </Button>
      <Popover
        trigger="legacy"
        placement="bottom"
        isOpen={evidenceArtifactRequestOpen}
        target={buttonID}
        toggle={() => setEvidenceArtifactRequestOpen(false)}
      >
        <PopoverHeader>Evidence Artifact is Due!</PopoverHeader>
        <PopoverBody>
          <span>A </span>
          <span>
            <PriorityBadge priority={priority} />
          </span>
          <span> priority action item requires that you submit an artifact for this evidence.</span>
          <div>
            <ul>
              <li>To complete the action item press the add button above</li>
              <li>To close this message click elsewhere</li>
            </ul>
          </div>
        </PopoverBody>
      </Popover>
      <Popover
        trigger="legacy"
        placement="bottom"
        isOpen={noActionItemPopoverOpen}
        target={buttonID}
        toggle={() => {
          if (noActionItemPopoverOpen) {
            setNoActionItemPopoverOpen(false);
          }
        }}
      >
        <PopoverHeader>No Active Action Item Exists!</PopoverHeader>
        <PopoverBody>
          <div>
            This means you are not required to submit any artifacts for this evidence. This situation may occur because
            of any of the following reasons:
          </div>
          <div>
            <ul>
              <li>The action item was manually deleted</li>
              <li>The action item status is not 'in progress' or 'past due'</li>
              <li>This evidence's automations are not enabled</li>
              <li>This evidence's frequency is set to daily</li>
              <li>You are not attached as a point of contact for this evidence</li>
              <li>This evidence is not expiring soon or expired yet</li>
            </ul>
          </div>
          {activityModal && (
            <NotEnoughData
              message={""}
              callToAction={{
                function: () => {
                  setNoActionItemPopoverOpen(false);
                  activityModal?.setModalIsOpen(true);
                },
                message: " to submit an artifact anyways",
              }}
            />
          )}
        </PopoverBody>
      </Popover>
    </div>
  );

  return {
    ...activityModal,
    button: modalButton,
    display,
    key,
    setKey,
  };
};
