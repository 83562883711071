import React, { useEffect, useState } from "react";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import cronstrue from "cronstrue";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

/**
 * @description Displays timeline information about a point of contact changing the frequency of an Evidence
 * @param {string} data - custom data, needs to be parsed
 * @returns {JSX.Element}
 * @constructor
 */
const FrequencyChangedActivity = ({ data }) => {
  const [customData, setCustomData] = useState({});

  useEffect(() => {
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        const oldFrequency = cronstrue.toString(parsedData?.oldFrequency);
        const newFrequency = cronstrue.toString(parsedData?.newFrequency);
        setCustomData({
          ...parsedData,
          oldFrequency,
          newFrequency,
        });
      } catch (e) {
        EventLogger("Cannot parse custom data for frequency changed evidence activity", e);
      }
    }
  }, [data]);

  return (
    <span>
      {customData?.pointOfContactName || "Someone"} changed{" "}
      <LightTooltip
        arrow
        interactive
        title={
          <div>
            {customData?.oldFrequency && `From '${customData?.oldFrequency}'`}{" "}
            {customData?.newFrequency && `to '${customData?.newFrequency}'`}{" "}
          </div>
        }
      >
        <span
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          frequency
        </span>
      </LightTooltip>
    </span>
  );
};

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default FrequencyChangedActivity;
