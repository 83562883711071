import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { DetailsTable, useDetails } from "./useDetails/hooks/useDetails";
import React, { useCallback, useContext, useEffect, useState } from "react";

import AccordionIcon from "../../utils/GenericComponents/AccordionIcon";
import CustomTable from "../../views/CustomWidgets/WidgetDetails/components/CustomTable";
import CustomTooltip from "../../utils/GenericComponents/CustomTooltip";
import { EventLogger } from "../../utils/EventLogger/EventLogger";
import GenericEditField from "../../utils/GenericComponents/GenericEditFieldV2";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { checkPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { isEqual } from "lodash";
import { useDeleteMutation } from "../graphql/useDeleteMutation";
import { useDeleteMutationBulk } from "../graphql/useDeleteMutationBulk";
import { useModal } from "./useModal";
import { useStateEffect } from "../functional/useStateEffect";

/**
 * @author Jacob Blazina
 * Created At: 10/22/19
 *
 * Generates a highly configurable ReactStrap table.
 * @function
 * @param {Object[]} data - An array of objects with a unique `id` field. @example {data: [ {id: 1, name: "Jacob", color: "blue"}, {id: 2: name: "Bob", color: "red} ]}
 * @param {string[]} fields - An array of strings representing which fields from the above data array to display in the table. @example fields: ["name", "color"]
 * @param {string[]} hideFields - An array of strings representing which fields to hide. This is used for adding hidden search fields to the table
 * @param {Object[]} customFields - An array of custom field objects that will replace one of the above fields with a customized react component.
 * @example
 * [{
 *     field: "pointOfContact", (used to match a custom component to the information in the "data" array )
 *     propName: "overridePropName" (optional - if not present "field" will be used instead),
 *     component: <LegacyPointOfContactField/>
 *     tooltip:  (optional - when present a tooltip will be displayed, can be a string or a react component)
 * }]
 * @param {string[]} [options] - An array of strings representing options such as a Details Page button or a Delete Button.Current Options: [ "details", "delete" ]
 * @param {object[]} [customOptions] - An array of objects for Custom Options. Follows a similar pattern as customFields, but uses an onClick instead of a react component
 * @example
 * [{
 *    name: "Cancel Action", // this text is displayed as the 'Option Button' text
 *    onClick: (item) => cancelAction(item), // the onClick function for this option
 *    iconClassName: "icon-close", // optional text representing an icon class to use in the options button
 *    validationFunction: (item) => item.status === "cancelled" // an optional function that determines if the option should be displayed or not. True means option is displayed, false means option is hidden
 * }]
 * @param {function} [deleteFunction] - A function that will handle the deleting of an item. This function must take in an `item` object prop with an Appsync `id` field. This function MUST be present if using the "delete" option.
 * @param {string} deleteGqlQuery - GraphQl query with all connections to delete Note: __typename is required for each object in the query Note: In schema the model should have the IAM directive
 * @param {string} [route] - A string representing the full route path for a details page. The ID will be appended automatically. Ex. "#/compliance/controls/"
 * @param {boolean} [popover] deprecated: Please do not use. This is a legacy field used for the old popover style info boxes. This functionality has been replaced with the `detailsComponent` pattern.
 * @param {boolean} [enableNotes] deprecated: Please do not use. This is a legacy field used for the old popover style info boxes. This functionality should be taken care of in the `detailsComponent` itself.
 * @param {object} [rowComponent] - A custom react component. This component should start as a table row. <tr> @see the <TableRow /> component below for a guideline of how to construct this custom row component.
 * @param {object} [fieldNameDictionary] - An object representing a dictionary for friendly names of database fields. Ex. { name: "Person's Name", color: "Favorite Color" }
 * @param {object} [detailsComponent] - A custom react component. This component must take in an `item` prop (unless using a custom `rowComponent` that is different)
 * This is the component that is injected into the details modal.
 * The details modal is only available if this component is present.
 * @param {object} [config] - An object representing misc. configuration settings.
 *              Note: many of these settings are not-so-intuitive and have been moved here for legacy support purposes
 *              Current settings:
 *              config: {
 *                bordered,         // sets the table to be a bordered table
 *                borderless,         // sets the table to be a borderless table
 *                disableHighlight, // disables highlighting of row components
 *                disableDetails,   // manually disables the details modal, even if a `detailsComponent` is passed in.
 *                width,            // sets the width of the details modal component
 *                selectBoxSize     // sets the height and width of select boxes
 *                 showSelectBoxes,  // sets whether select buttons are shown by default
                  selectionType,    // sets the select button type. Current types are: "radio", "button". Defaults to a checkbox for multi-select
                  buttonCallback,   // callback function used if selectionType === "button". Returns the `item` object
                  selectButton = defaultSelectButton, // the react UI to use as the selectButton. Has a default button.
                  selectButtonTableHeader   // the text to display in the table header for "radio" or "button" selections
                  showNoteIcon      // function that is used to check if a note icon should be displayed. Defaults to null
                  noteIconComponent // react component to be displayed if showNoteIcon === true. Defaults a gold "note" icon
 *              }
 * @param {object} [roleConfig] - The current user's RoleConfig object. This is needed for checking read/update/delete functionality
 * @param {string} [module] - A string representing the module that this table lives in
 * @param {string} [resource] - A string represting the resource that this table is displaying
 * @param {string} [updateMutation] - A GraphQL mutation for updating an item. This is needed for Editing Fields functionality
 * @param {string} [deleteMutation] - The GraphQL mutation for deleting an item. This is needed for delete functionality
 * @param {component} [collapseComponent]
 * @param {function} [resetFunction] - A function that is called after the DeleteMutation. This can force the table to re-render instead of waiting on a subscription
 * @param {string} [typename] - A friendly Type Name that is used in the info text during a delete operation. Does not have to match the schema.
 * @param {boolean} [disableRoleChecking] - A boolean that can disable all role checking in this table. (Sets everything to true). Use this in order to use this table hook normally without role constraints
 * @param {object} [genericEditFieldConfig]
 * @returns {object[]} data - The raw data array being used by this table
 * @returns {function} setData - Replaces the raw data array being used by this table
 * @returns {component} display - The ReactStrap table UI that gets generated by this table
 * @returns {function} setSelectedItems - Sets the raw selected items array used with showSelectBoxes
 * @returns {function} setShowSelectBoxes - Shows select box UI in the field headers and the table rows
 * @returns {boolean} showSelectBoxes - The boolean indicating if select boxes are present
 * @returns {function} setItems - Implementation function for the select boxes logic. Seldom used. Mainly use setSelectedItems
 * @returns {string[]} options - The array of strings representing the current options
 * @returns {function} setFullData - An array used for Select Boxes functionality. Not the array used in the render.
 * @returns {object[]} sortFields - An array of the current sort field objects.
 * @returns {string[]} fields - An array of strings representing the currently visible fields
 * @returns {object[]} selectedItems - An array of the currently selected items. Used with showSelectBoxes
 * @returns {function} setSortField - A function for sorting the table by a field. Ex. sortFields("name", "desc")
 */
export const useTable = ({
  data,
  updateItemById,
  fields: fieldsInit,
  hideFields = [],
  customFields,
  options,
  customOptions,
  deleteFunction,
  deleteGqlQuery,
  route,
  popover = true,
  enableNotes,
  rowComponent,
  fieldNameDictionary,
  detailsComponent,
  config = {},
  roleConfig = {},
  module,
  resource,
  updateMutation,
  deleteMutation,
  collapseComponent,
  resetFunction,
  typename,
  disableRoleChecking = false,
  genericEditFieldConfig,
  hideHeader = false,
  // selectionType,  // deprecated.  use config.selectionType instead
  // buttonCallback  // deprecated. use config.buttonCallback instead
}) => {
  // This ensures that the config object is never null
  config = config ? config : {};

  //Updates custom field settings in case they change
  const [customFieldSettings, setCustomFieldsSettings] = useState(customFields);
  useEffect(() => {
    setCustomFieldsSettings(customFields ?? []);
  }, [!isEqual(customFieldSettings, customFields)]);

  const [tableData, setTableData] = useState(data);
  const [sortFields, setSortFields] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [fullData, setFullData] = useState([]);

  const context = useContext(OrganizationContext);

  const isAdmin = context.isAdmin;

  const defaultSelectButton = <Button>Select</Button>;

  const {
    showSelectBoxes,
    selectionType,
    buttonCallback,
    selectButton = defaultSelectButton,
    selectButtonTableHeader,
  } = config;
  const [showSelection, setShowSelection] = useState(showSelectBoxes || false);
  const [selectType, setSelectType] = useState(selectionType);

  const setSorting = (field, direction) => {
    const item = sortFields.find((i) => i.field === field);

    if (item) {
      if (item.direction === "asc") {
        item.direction = direction || "desc";
      } else if (item.direction === "desc") {
        item.direction = direction || "asc";
      }
      const temp = sortFields;
      temp.splice(temp.indexOf(item), 1);
      setSortFields([...temp, item]);
    } else {
      setSortFields([...sortFields, { field: field, direction: direction || "asc" }]);
    }
  };

  const setItems = (item) => {
    const items = selectedItems;
    const index = items && items.findIndex((s) => s["id"] === item.id);
    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(item);
    }
    setSelectedItems([].concat(items));
  };

  const selectAll = () => {
    const allItems = fullData ? fullData : [];
    const items = selectedItems;
    allItems.forEach((item) => {
      const index = items.findIndex((s) => s["id"] === item.id);
      if (index === -1) items.push(item);
    });

    setSelectedItems([].concat(items));
  };

  const clearSelectionList = () => {
    setSelectedItems([]);
  };

  const [optionsButtonIsOpen, setOptionsButtonIsOpen] = useState(false);
  const [bulkDelete, setBulkDelete] = useState(false);

  useEffect(() => {
    if (bulkDelete) {
      setShowSelection(true);
    } else {
      setShowSelection(showSelectBoxes || false);
    }
  }, [bulkDelete]);

  const deleteMutationHook = useDeleteMutationBulk({
    deleteFunction,
    mutation: deleteMutation,
    module,
    resource,
    typename,
    deleteGqlQuery,
    resetFunction: () => {
      setBulkDelete(false);
      resetFunction && resetFunction();
    },
  });

  useEffect(() => {
    if (bulkDelete && deleteMutationHook) {
      deleteMutationHook.setSelectedItems([...selectedItems]);
    }
  }, [selectedItems]);

  const [fields, setFields] = useState(fieldsInit);
  useEffect(() => {
    setFields(fieldsInit);
  }, [JSON.stringify(fieldsInit)]);

  /**
   * Sets up the props to send to the TableRow component or custom TableRow param
   */
  const propsObj = {
    tableData,
    updateItemById,
    fields,
    setFields,
    customFields: customFieldSettings,
    hideFields,
    options,
    customOptions,
    route,
    popover,
    enableNotes,
    deleteFunction,
    deleteGqlQuery,
    detailsComponent,
    setLastSelected,
    isAdmin,
    showSelectBoxes: showSelection,
    setItems: selectType === "radio" ? setSelectedItems : setItems,
    selectedItems,
    collapseComponent,
    config,
    roleConfig,
    module,
    resource,
    updateMutation,
    deleteMutation,
    resetFunction,
    disableRoleChecking,
    genericEditFieldConfig,
    selectionType: selectType,
    buttonCallback,
    selectButton,
    typename,
  };

  const table = (
    <Table responsive hover bordered={config.bordered} borderless={config.borderless} style={{ height: "100%" }}>
      {!hideHeader && (
        <thead>
          <tr>
            {showSelection &&
              (() => {
                switch (selectType) {
                  case "radio":
                    return (
                      <th scope="col" className="dashboard-title" style={{ width: "20%" }}>
                        {selectButtonTableHeader || "Select"}
                      </th>
                    );
                  case "button":
                    return (
                      <th scope="col" className="dashboard-title" style={{ width: "20%" }}>
                        {selectButtonTableHeader || "Select"}
                      </th>
                    );
                  default:
                    return (
                      <th key={"selectBoxes"} scope="col">
                        {bulkDelete &&
                          (deleteFunction || deleteMutation || deleteGqlQuery) &&
                          deleteMutationHook.modalButton}
                        <ButtonGroup size="sm">
                          <Button color="ghost-success" onClick={() => selectAll()} title="Select all Items">
                            <i className="icon-check" />
                          </Button>
                          <Button color="ghost-danger" onClick={() => clearSelectionList()} title="De-Select all Items">
                            <i className="icon-close" />
                          </Button>
                        </ButtonGroup>
                      </th>
                    );
                }
              })()}
            {detailsComponent && (
              <th key={"details"} scope="col" className="dashboard-title">
                <i className="icon-info" title="Item Details" />
              </th>
            )}
            {fields &&
              fields.map((field, index) => {
                const tooltip =
                  Array.isArray(customFields) &&
                  customFields.find((customField) => customField?.field === field)?.tooltip;

                return (
                  hideFields &&
                  !hideFields.includes(field) &&
                  checkPermissions({
                    module,
                    resource,
                    field: field.split(".")[0],
                    roleConfig,
                    disableRoleChecking,
                  }).field &&
                  checkPermissions({
                    module,
                    resource,
                    field: field.split(".")[0],
                    roleConfig,
                    disableRoleChecking,
                  }).field.read && (
                    <th
                      key={index}
                      scope="col"
                      style={{
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                      onClick={() => setSorting(field)}
                      className="dashboard-title"
                    >
                      {fieldNameDictionary && field in fieldNameDictionary
                        ? fieldNameDictionary[field]
                        : convertCamelCaseToSentence(field)}

                      {tooltip && <CustomTooltip tooltip={tooltip} />}
                    </th>
                  )
                );
              })}
            {options &&
              options.length > 0 &&
              ((options.includes("details") &&
                checkPermissions({
                  module,
                  resource,
                  roleConfig,
                  disableRoleChecking,
                }).resource.read) ||
                (options.includes("delete") &&
                  checkPermissions({
                    module,
                    resource,
                    roleConfig,
                    disableRoleChecking,
                  }).resource.delete)) && (
                <th key="options" scope="col" className="dashboard-title">
                  <span>
                    Options
                    {(deleteMutation || deleteFunction || deleteGqlQuery) &&
                      checkPermissions({
                        module,
                        resource,
                        roleConfig,
                        disableRoleChecking,
                      }).resource.delete &&
                      options.includes("delete") && (
                        <ButtonDropdown
                          /*style={{display: "inline-block"}}*/ className="btn-pill"
                          size="sm"
                          direction="down"
                          isOpen={optionsButtonIsOpen}
                          toggle={() => setOptionsButtonIsOpen(!optionsButtonIsOpen)}
                        >
                          <DropdownToggle color="ghost-secondary" size="sm" className="btn-pill">
                            <i className="icon-settings" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {options.includes("delete") &&
                              checkPermissions({
                                module,
                                resource,
                                roleConfig,
                                disableRoleChecking,
                              }).resource &&
                              checkPermissions({
                                module,
                                resource,
                                roleConfig,
                                disableRoleChecking,
                              }).resource.delete && (
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => {
                                    setBulkDelete(!bulkDelete);
                                    setOptionsButtonIsOpen(false);
                                  }}
                                >
                                  Bulk Delete
                                </Button>
                              )}
                          </DropdownMenu>
                        </ButtonDropdown>
                      )}
                  </span>
                </th>
              )}
          </tr>
        </thead>
      )}
      <tbody>
        {tableData &&
          Array.isArray(tableData) &&
          tableData.length > 0 &&
          tableData.map((item, index) =>
            rowComponent ? (
              React.cloneElement(rowComponent, {
                key: item.id ? item.id : JSON.stringify(item),
                item,
                backGround:
                  !config.disableHighlight && lastSelected === item.id ? context.selectedHighlightColor : null,
                ...propsObj,
              })
            ) : (
              <TableRow
                key={item.id ? item.id : JSON.stringify(item) + index}
                item={item}
                backGround={
                  !config.disableHighlight && lastSelected === item.id ? context.selectedHighlightColor : null
                }
                {...propsObj}
              />
            ),
          )}
        {(!Array.isArray(tableData) || tableData.length === 0) && (
          <div style={{ color: "lightGrey", margin: "1em" }}>No Items</div>
        )}
      </tbody>
    </Table>
  );

  return {
    display: table,
    setData: useCallback((data) => setTableData(data)),
    setFullData,
    data,
    fields,
    setFields,
    options,
    sortFields,
    setSortField: setSorting,
    selectedItems,
    setSelectedItems,
    setItems,
    showSelectBoxes: showSelection,
    setShowSelectBoxes: setShowSelection,
    selectionType: selectType,
    setSelectionType: setSelectType,
  };
};

export const TableRow = (props) => {
  // Ensures this is never null
  const customFields = props.customFields || [];
  const roleConfig = props.roleConfig || {};
  const {
    module,
    updateItemById,
    resource,
    updateMutation,
    deleteMutation,
    deleteGqlQuery,
    item,
    resetFunction,
    disableRoleChecking,
    selectButton,
    fields,
    hideFields,
    options,
    route,
    genericEditFieldConfig,
    draggable,
    typename,
    setLastSelected,
    tableData,
  } = props;

  const [optionsButtonIsOpen, setOptionsButtonIsOpen] = useState(false);
  const [checked, setChecked] = useState(props.checked);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  /**
   * The item to show in the details modal.
   * This is held in state so that the user can easily switch between the details items of the list.
   */
  const [detailsItem, setDetailsItem] = useStateEffect(item, [item]);

  /**
   * Booleans indicating if the list has a next or previous item
   */
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  const detailsHook = useDetails({
    item: props.item,
    module: module || "",
    resource: resource || "",
    disableRoleChecking: module && resource && !disableRoleChecking ? false : true,
    enableNotes: true,
  });

  /**
   * Sets the selected "details item" based on array index.
   * @param {number} [indexDiff=1] - a positive or negative integer. Adjusts the current Array index by + or - values.
   * @param {boolean} reset - completed resets the selected details item
   */
  const adjustSelectedItemIndex = (indexDiff = 1, reset = false) => {
    /**
     * If there is no tableData or detailsItem, do nothing.
     */
    if (tableData && detailsItem && detailsItem.id) {
      /**
       * Gets the index of the currently selected details item in the array
       */
      const index = tableData.findIndex((x) => x.id === detailsItem.id);

      if (index !== null && index !== undefined && index !== -1) {
        const newItem = tableData[index + indexDiff];
        if (newItem) {
          setLastSelected(newItem.id);
          setDetailsItem({ ...newItem });

          setHasPrevious(!!tableData[index - 1]);
          setHasNext(!!tableData[index + 1]);
        }
      } else {
        EventLogger(
          "[adjustSelectedIndex] Could not adjust selected Details Item index. Could not find tableData index.",
        );
      }
    }

    /**
     * If reset flag is passed, clear the selected item and set the detailsItem to the first in the list
     */
    if (reset) {
      setLastSelected("");
      tableData && tableData[0] && setDetailsItem(tableData[0]);
    }
  };

  /**
   * Checks if the currently selected details item has a next or previous item in the list
   */
  useEffect(() => {
    if (tableData && detailsItem && detailsItem.id) {
      /**
       * Gets the index of the currently selected details item in the array
       */
      const index = tableData.findIndex((x) => x.id === detailsItem.id);

      if (index !== null && index !== undefined && index !== -1) {
        setHasPrevious(!!tableData[index - 1]);
        setHasNext(!!tableData[index + 1]);
      }
    }
  }, [detailsItem, tableData]);

  /**
   * The Details Modal for this particular item in the list
   */
  const modalHook = useModal(
    <DetailsModalHeader
      adjustSelectedItemIndex={adjustSelectedItemIndex}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      route={props.route}
      detailsItem={detailsItem}
      config={props.config}
    />,
    detailsItem && props.detailsComponent && !props.config.disableDetails
      ? React.cloneElement(props.detailsComponent, {
          key: `details_modal_detail${detailsItem.id}`,
          item: { ...detailsItem },
        })
      : detailsHook.display,
    <i style={{ marginRight: "5px", cursor: "pointer" }} id={`details_modal_${props.item.id}`} className="icon-info" />,
    {
      width: props.config.width || "80vw",
    },
  );

  /**
   * When the details modal is closed, reset the detailsItem state
   */
  useEffect(() => {
    if (item && modalHook.modalIsOpen === false) {
      setDetailsItem(item);
    }
  }, [modalHook.modalIsOpen]);

  const handleCheck = () => {
    setChecked(!checked);
    props.setItems(props.item);
  };

  const deleteMutationHook = useDeleteMutation({
    item,
    module,
    resource,
    mutation: deleteMutation, // can either take in a mutation directly for simple deletions or a function
    resetFunction,
    deleteFunction: props.deleteFunction,
    deleteGqlQuery,
    disableRoleChecking,
    typename,
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  return (
    <>
      <tr
        id={props.item && props.item.id}
        draggable={draggable}
        onDragStart={(e) => e.dataTransfer.setData("data", e.target.id)}
        style={{
          background: props.backGround,
          cursor: props.collapseComponent ? "pointer" : undefined,
        }}
        onClick={() => {
          props.setLastSelected(props.item.id);
          props.collapseComponent && setCollapseIsOpen(!collapseIsOpen);
        }}
      >
        {props.showSelectBoxes
          ? (() => {
              switch (props.selectionType) {
                case "radio":
                  return (
                    <td key={"radioBtn"}>
                      <FormGroup check>
                        <Input
                          style={{
                            height: props.config.selectBoxSize || "1.5em",
                            width: props.config.selectBoxSize || "1.5em",
                          }}
                          type="radio"
                          onChange={() => handleCheck()}
                          name={fields && route ? fields + route : "name"}
                        />
                      </FormGroup>
                    </td>
                  );
                case "button":
                  return (
                    <td key="selectButton">
                      {React.cloneElement(selectButton, {
                        "data-cy": "button-select-row",
                        onClick: () => {
                          if (props.buttonCallback) {
                            setButtonDisabled(true);
                            props.buttonCallback(props.item);
                          } else handleCheck();
                        },
                        item: props.item,
                        disabled: buttonDisabled,
                      })}
                    </td>
                  );
                default:
                  return (
                    <td key={"selectBox"}>
                      <FormGroup check>
                        <Input
                          data-testid={`checkbox-select-row-${item?.id}`}
                          style={{
                            height: props.config.selectBoxSize || "1.5em",
                            width: props.config.selectBoxSize || "1.5em",
                          }}
                          type="checkbox"
                          onChange={() => handleCheck()}
                          checked={props.selectedItems && props.selectedItems.some((el) => el.id === props.item.id)}
                        />
                      </FormGroup>
                    </td>
                  );
              }
            })()
          : undefined}
        {props.detailsComponent && (
          <td key={"detailsButton"}>
            {modalHook.modalButton}
            {props.config.showNoteIcon &&
              props.config.showNoteIcon(item) &&
              (props.config.noteIconComponent ? (
                React.cloneElement(props.config.noteIconComponent)
              ) : (
                <i style={{ color: "gold" }} className="icon-note" />
              ))}
          </td>
        )}
        {props.fields &&
          props.fields.map(
            (field, index) =>
              hideFields &&
              !hideFields.includes(field) &&
              checkPermissions({
                module,
                resource,
                field: field.split(".")[0],
                roleConfig,
                disableRoleChecking,
              }).field &&
              checkPermissions({
                module,
                resource,
                field: field.split(".")[0],
                roleConfig,
                disableRoleChecking,
              }).field.read && (
                <td key={index}>
                  {
                    /**
                     * Handle Custom Fields
                     */
                    customFields.find((item) => item.field === field) ? (
                      (() => {
                        const customField = customFields.find((item) => item.field === field);
                        return React.cloneElement(customField.component, {
                          [customField.propName || customField.field]: props.item[customField.field],
                          updateItemById,
                          item: props.item,
                          resetFunction,
                        });
                      })()
                    ) : /**
                     * Handle Nested Array
                     */
                    Array.isArray(item[field]) ? (
                      <CustomTable data={item[field]} />
                    ) : item[field] && item[field].items && Array.isArray(item[field].items) ? (
                      <CustomTable data={item[field].items} disableRoleChecking={true} />
                    ) : /**
                     * Handle Nested Object
                     */
                    item[field] && typeof item[field] === "object" ? (
                      <DetailsTable item={item[field]} disableRoleChecking={true} />
                    ) : (
                      /**
                       * Handle scalar fields
                       */
                      <GenericEditField
                        item={props.item}
                        field={field}
                        resource={resource}
                        module={module}
                        roleConfig={roleConfig}
                        mutation={updateMutation}
                        genericEditFieldConfig={genericEditFieldConfig}
                        disableRoleChecking={disableRoleChecking}
                      />
                    )
                  }
                </td>
              ),
          )}
        {options &&
          options.length > 0 &&
          ((options.includes("details") &&
            checkPermissions({
              module,
              resource,
              roleConfig,
              disableRoleChecking,
            }).resource.read) ||
            (options.includes("delete") &&
              checkPermissions({
                module,
                resource,
                roleConfig,
                disableRoleChecking,
              }).resource.delete)) && (
            <td>
              <ButtonDropdown
                size="sm"
                direction="down"
                isOpen={optionsButtonIsOpen}
                toggle={() => setOptionsButtonIsOpen(!optionsButtonIsOpen)}
              >
                <DropdownToggle color="ghost-info">
                  <i className="icon-wrench" />
                </DropdownToggle>
                <DropdownMenu>
                  {props.options.includes("details")
                    ? checkPermissions({
                        module,
                        resource,
                        roleConfig,
                        disableRoleChecking,
                      }).resource &&
                      checkPermissions({
                        module,
                        resource,
                        roleConfig,
                        disableRoleChecking,
                      }).resource.read && (
                        <DropdownItem
                          name="item-details"
                          color="ghost-primary"
                          id={props.item.id}
                          href={props.route + props.item.id}
                          title="Go to Details Page"
                        >
                          <i style={{ color: "#4285F4" }} className="icon-share-alt" />
                          {"  "} Details
                        </DropdownItem>
                      )
                    : null}
                  {props.options.includes("delete") &&
                    checkPermissions({
                      module,
                      resource,
                      roleConfig,
                      disableRoleChecking,
                    }).resource &&
                    checkPermissions({
                      module,
                      resource,
                      roleConfig,
                      disableRoleChecking,
                    }).resource.delete &&
                    deleteMutationHook.modalButton}
                  {props.customOptions &&
                    props.customOptions.length > 0 &&
                    props.customOptions.map(
                      (option, index) =>
                        option &&
                        option.name &&
                        (option.validationFunction ? option.validationFunction(item) : true) && (
                          <DropdownItem
                            key={`customOption${option.name}${index}`}
                            name="item-details"
                            color="ghost-primary"
                            id={props.item.id}
                            title={option.name}
                            onClick={() => option.onClick && option.onClick(item)}
                          >
                            {option.iconClassName && (
                              <i className={option.iconClassName} style={{ marginRight: "1em" }} />
                            )}
                            {option.name}
                          </DropdownItem>
                        ),
                    )}
                </DropdownMenu>
              </ButtonDropdown>
            </td>
          )}
        {props.collapseComponent && (
          <td>
            <AccordionIcon isOpen={collapseIsOpen} />
          </td>
        )}
      </tr>
      {props.collapseComponent && (
        <tr style={{ display: !collapseIsOpen ? "none" : undefined }}>
          <td colSpan={props.fields.length + 1}>
            <Collapse isOpen={collapseIsOpen}>
              {React.cloneElement(props.collapseComponent, {
                item: props.item,
                ...props,
              })}
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
};

/**
 * Buttons that allow the User to navigate between the Details components in a list
 * @param {boolean} hasPrevious - indicates if the list has a previous item to navigate to.
 * @param {boolean} hasNext - indicates if the list has a next item to navigate to
 * @param {function} adjustSelectedItemIndex - function pointer.
 * @returns {JSX.Element}
 * @constructor
 */
const NextAndPreviousButtons = ({ hasPrevious, hasNext, adjustSelectedItemIndex }) => {
  return (
    <div style={{ display: "inline-block", marginRight: "1em" }}>
      <Pagination size="sm">
        <PaginationItem
          title={hasPrevious ? "View the Previous Item in the List" : "This is the first item in the list"}
          disabled={hasPrevious === false}
        >
          <PaginationLink onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(-1)} previous />
        </PaginationItem>
        <PaginationItem
          title={hasNext ? "View the Next Item in the List" : "This is the last item in the list"}
          disabled={hasNext === false}
        >
          <PaginationLink onClick={() => adjustSelectedItemIndex && adjustSelectedItemIndex(1)} next />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

/**
 * Modal Header for the Details Modal
 */
const DetailsModalHeader = ({ hasPrevious, hasNext, adjustSelectedItemIndex, detailsItem, route, config = {} }) => {
  return (
    <div>
      <NextAndPreviousButtons
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        adjustSelectedItemIndex={adjustSelectedItemIndex}
      />
      Details
      {detailsItem && route && !config.disableDetails && (
        <NavLink
          style={{ display: "inline-block" }}
          href={route + detailsItem.id}
          className="icon-share-alt"
          title={route + detailsItem.id}
        />
      )}
      <br />
      <small>{detailsItem && detailsItem.name ? detailsItem.name : ""}</small>
    </div>
  );
};
