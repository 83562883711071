export const RiskModuleLinks = {
  name: "Risk",
  url: "/risk",
  icon: "simple-line-icons:energy",
  children: [
    {
      riskDashboardPage: {
        name: "Dashboard",
        url: "/risk/dashboard",
        icon: "simple-line-icons:grid",
      },
    },
    {
      informationSystemsPage: {
        name: "Systems",
        url: "/risk/systems",
        icon: "simple-line-icons:screen-desktop",
      },
    },
    {
      recommendationsPage: {
        name: "Recommendations",
        url: "/risk/recommendations",
        icon: "simple-line-icons:tag",
      },
    },
    {
      riskChangesPage: {
        name: "Changes",
        url: "/risk/changes",
        icon: "simple-line-icons:graph",
      },
    },
    // TEMPORARILY DISABLED UNTIL FULLY IMPLEMENTED
    // {
    //   riskAssessmentsPage: {
    //     name: "Risk Assessments",
    //     url: '/risk/assessments',
    //     icon: 'simple-line-icons:eyeglass'
    //   }
    // },
    {
      threatsPage: {
        name: "Threats",
        icon: "simple-line-icons:target",
        url: "/risk/threats",
      },
    },
    {
      controlsPage: {
        name: "Controls",
        icon: "simple-line-icons:list",
        url: "/risk/control_categories",
      },
    },
    {
      riskAssessmentReportPage: {
        name: "Report",
        url: "/risk/reports/risk_assessment",
        icon: "simple-line-icons:book-open",
      },
    },
    {
      riskConfigPage: {
        name: "Configuration",
        url: "/risk/risk_config",
        icon: "simple-line-icons:settings",
      },
    },
  ],
};
