/**
 * Converts a list of tags in a db item to a string list of the names of tags
 * @param {object} item - db item with a tags field
 * @return {string} concatenated string of tag names
 */
export const convertTags = (item) => {
  if (item?.tags?.items) {
    let string = "";
    item.tags.items.forEach((tagLink, index) => {
      string = string.concat(index !== 0 ? ", " : "", tagLink.tag.name);
    });
    item.tags = string;
  }

  return item.tags;
};
