import { Button, ListGroup } from "reactstrap";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import AddInformationAssetToSystem from "./AddInformationAssetToSystem";
import AddOrRemoveIcon from "../../../../../../../utils/GenericComponents/AddOrRemoveIcon";
import DataLoader from "../../../../../../../utils/LoadingComponents/DataLoader";
import InformationAssetItem from "./InformationAssetItem";
import { realTimeRiskGQL } from "@rivial-security/risk-calc-utils";
import { useCheckPermissions } from "../../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../../hooks/views/useModal";
import { useQueryGetItem } from "../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../utils/Context/withOrganizationCheck";

/**
 * Displays Information Assets for a System
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InformationAssets = ({
  module = modules.RISK,
  resource = resources.INFORMATION_SYSTEM,
  disableRoleChecking = false,
  ...props
}) => {
  const field = "informationAssets";

  const { system, printView } = props;

  const infoAssetsQuery = useQueryGetItem({
    query: realTimeRiskGQL.informationAssetsQuery,
    itemId: system.id,
    module,
    resource,
    disableRoleChecking,
  });

  const reset = () => {
    infoAssetsQuery.reset();
    props.resetSystem && props.resetSystem();
  };

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  });

  const addInformationAssetModal = useModal(
    "Add or Remove Information Asset",
    <AddInformationAssetToSystem
      disableRoleChecking={disableRoleChecking}
      organizationID={props.organizationID}
      system={system}
      currentAssets={
        infoAssetsQuery?.item?.informationAssets?.items ? infoAssetsQuery.item.informationAssets.items : []
      }
      resetFunction={reset}
    />,
    <Button
      size="sm"
      color="ghost-secondary"
      className="btn-pill"
      title={
        checkPermissionsHook.resource.update
          ? "Add/Remove Information Assets for this System"
          : "You don't have Update permission for this Resource"
      }
      disabled={!checkPermissionsHook.resource.update}
    >
      <AddOrRemoveIcon />
    </Button>,
  );

  const [informationAssets, setInformationAssets] = useState([]);

  useEffect(() => {
    const informationAssetLinks =
      infoAssetsQuery && infoAssetsQuery.item && infoAssetsQuery.item.informationAssets
        ? infoAssetsQuery.item.informationAssets.items
        : [];
    const items = [];
    for (const item of informationAssetLinks) {
      if (item.informationAsset) {
        items.push({ ...item.informationAsset, informationAssetLink: item });
      }
    }
    setInformationAssets(items);
  }, [system, infoAssetsQuery.item]);

  return (
    <>
      <h7>
        Information Assets
        {addInformationAssetModal.modalButton}
      </h7>
      <DataLoader
        isLoading={infoAssetsQuery.isLoading}
        isEnoughData={informationAssets?.length > 0}
        dataMessage={"There are no Information Assets linked to this System."}
        callToAction={
          checkPermissionsHook.resource.update && {
            message: " to attach an Information Asset",
            function: () => addInformationAssetModal.setModalIsOpen(true),
          }
        }
        style={{ fontStyle: "italic" }}
      >
        <ListGroup className="shadow">
          {informationAssets.map((item, index) => (
            <InformationAssetItem
              key={`${system && system.id}systemInformationAsset${index}${item.id}`}
              item={item}
              resetFunction={props.resetFunction}
              isOpen={!!props.isOpen}
              printView={printView}
              module={module}
              resource={resource}
              disableRoleChecking={disableRoleChecking}
            />
          ))}
        </ListGroup>
      </DataLoader>
    </>
  );
};

export default withOrganizationCheck(InformationAssets);
