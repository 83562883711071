"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLargestLossToleranceExceedance = void 0;
const findLossToleranceHeightAtPoint_1 = require("./findLossToleranceHeightAtPoint");
/**
 * Finds the largest loss tolerance exceedance by the provided residual loss curve.
 *
 * @function
 * @param lossToleranceCurve - the loss tolerance points (objects with x, y values) defined by the % of chance
 * an organization are willing to accept of a monetary loss of x or more
 * @param residualLossCurve - the residual loss points (objects with x, y values) defined % of chance a
 * simulation resulted in a monetary loss of x or more
 * @returns delta information and risk point index number in the original curve data that has the largest
 * loss tolerance exceedance
 */
const findLargestLossToleranceExceedance = ({ lossToleranceCurve, residualLossCurve, }) => {
    // Check arguments are valid
    // - curves need to be arrays of points
    if (!Array.isArray(lossToleranceCurve) || !Array.isArray(residualLossCurve)) {
        return null;
    }
    // - curves need to have at least 2 points for loss tolerance, 1 for risk
    if (lossToleranceCurve.length <= 1 || residualLossCurve.length === 0) {
        return null;
    }
    // Iterate over every point in the residual loss curve
    const riskDeltas = [];
    for (const riskPoint of residualLossCurve) {
        const result = (0, findLossToleranceHeightAtPoint_1.findLossToleranceHeightAtPoint)({ lossToleranceCurve, riskPoint });
        if (result.lossToleranceRiskAtProbabilityValue !== null &&
            result.lossToleranceRiskAtProbabilityValue !== undefined) {
            riskDeltas.push(riskPoint.x - result.lossToleranceRiskAtProbabilityValue);
        }
    }
    // Find the maximum and index of each metric
    let maxRiskDelta = Number.MIN_SAFE_INTEGER;
    let maxRiskDeltaIndex = -1;
    riskDeltas.forEach((riskValue, i) => {
        if (riskValue > maxRiskDelta) {
            maxRiskDelta = riskValue;
            maxRiskDeltaIndex = i;
        }
    });
    return { delta: maxRiskDelta, index: maxRiskDeltaIndex };
};
exports.findLargestLossToleranceExceedance = findLargestLossToleranceExceedance;
