import React from "react";
import { Redirect } from "react-router-dom";

const ToolsDashboard = React.lazy(() => import("../../views/Tools/ToolsDashboard"));
const Tools = React.lazy(() => import("../../views/Tools/Tool/components/ToolDataGrid"));
const ToolDetails = React.lazy(() => import("../../views/Tools/Tool/components/ToolDetails"));
const ToolDashboard = React.lazy(() => import("../../views/Tools/Tool/components/ToolDashboard"));

/**
 * KnowBe4 components
 */
const KnowBe4TrainingCampaigns = React.lazy(
  () => import("../../views/Tools/KnowBe4/Training/components/KnowBe4TrainingDataGrid"),
);
const KnowBe4TrainingCampaignDetails = React.lazy(
  () => import("../../views/Tools/KnowBe4/Training/components/KnowBe4TrainingDetails"),
);
const KnowBe4PhishingTests = React.lazy(
  () => import("../../views/Tools/KnowBe4/Phishing/components/KnowBe4PhishingDataGrid"),
);
const KnowBe4PhishingTestDetails = React.lazy(
  () => import("../../views/Tools/KnowBe4/Phishing/components/KnowBe4PhishingDetails"),
);
const KnowBe4Groups = React.lazy(() => import("../../views/Tools/KnowBe4/Groups/components/KnowBe4GroupDataGrid"));
const KnowBe4GroupDetails = React.lazy(() => import("../../views/Tools/KnowBe4/Groups/components/KnowBe4GroupDetails"));
const KnowBe4Users = React.lazy(() => import("../../views/Tools/KnowBe4/Users/components/KnowBe4UserDataGrid"));
const KnowBe4UserDetails = React.lazy(() => import("../../views/Tools/KnowBe4/Users/components/KnowBe4UserDetails"));
const KnowBeDashboard = React.lazy(() => import("../../views/Tools/KnowBe4/KnowBe4Dashboard"));

/**
 * Mitre Attack components
 */
const MitreAttackDashboard = React.lazy(() => import("../../views/Tools/MitreAttack/MitreAttackDashboard"));
const MitreAttackTechniques = React.lazy(
  () => import("../../views/Tools/MitreAttack/Techniques/components/MitreAttackTechniquesDataGrid"),
);

// Main Routes
export const toolsRoutes = [
  {
    path: "/integrations/dashboard",
    exact: true,
    name: "Security Integrations Dashboard",
    component: ToolsDashboard,
  },
  {
    path: "/integrations/configuration",
    exact: true,
    name: "Security Tool Integrations",
    component: Tools,
  },
  {
    path: "/integrations/configuration/:id",
    exact: true,
    name: "Security Tool Details",
    component: ToolDetails,
  },
  {
    path: "/integrations/dashboard/:id",
    exact: true,
    name: "Integration Dashboard",
    component: ToolDashboard,
  },

  // KnowBe4 routes
  {
    path: "/integrations/know_be_4",
    exact: true,
    name: "KnowBe4",
    component: KnowBeDashboard,
  },
  {
    path: "/integrations/know_be_4/training",
    exact: true,
    name: "KnowBe4 Training Campaigns",
    component: KnowBe4TrainingCampaigns,
  },
  {
    path: "/integrations/know_be_4/training/:id",
    exact: true,
    name: "KnowBe4 Training Campaign Details",
    component: KnowBe4TrainingCampaignDetails,
  },
  {
    path: "/integrations/know_be_4/phishing",
    exact: true,
    name: "KnowBe4 Phishing Tests",
    component: KnowBe4PhishingTests,
  },
  {
    path: "/integrations/know_be_4/phishing/:id",
    exact: true,
    name: "KnowBe4 Phishing Test Details",
    component: KnowBe4PhishingTestDetails,
  },
  {
    path: "/integrations/know_be_4/group",
    exact: true,
    name: "KnowBe4 Groups",
    component: KnowBe4Groups,
  },
  {
    path: "/integrations/know_be_4/group/:id",
    exact: true,
    name: "KnowBe4 Group Details",
    component: KnowBe4GroupDetails,
  },
  {
    path: "/integrations/know_be_4/user",
    exact: true,
    name: "KnowBe4 Users Tests",
    component: KnowBe4Users,
  },
  {
    path: "/integrations/know_be_4/user/:id",
    exact: true,
    name: "KnowBe4 User Details",
    component: KnowBe4UserDetails,
  },

  // Mitre Attack routes
  {
    path: "/integrations/mitre_attack",
    exact: true,
    name: "Mitre Attack",
    component: MitreAttackDashboard,
  },
  {
    path: "/integrations/mitre_attack/techniques",
    exact: true,
    name: "Mitre Attack Techniques",
    component: MitreAttackTechniques,
  },

  {
    path: "/integrations",
    component: () => <Redirect to="/integrations/configuration" />,
  },
];
