/**
 * Author: Jacob Blazina
 * Created At: 10/1/2019
 * Edits:
 *
 * Description: A Function.
 *              Deletes a Maturity Column from the DB.
 *              First Deletes all Associated Maturity Levels to avoid NULL issues.
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import DeleteMaturityIndicator from "./DeleteMaturityIndicator";
import { generateGraphql } from "@rivial-security/generategraphql";

const { deleteMutation: deleteMaturityMatrixLevel } = generateGraphql("MaturityMatrixLevel");

const DeleteMaturityMatrixLevel = (maturityMatrixLevel) => {
  if (
    maturityMatrixLevel.maturityIndicators &&
    maturityMatrixLevel.maturityIndicators.items &&
    maturityMatrixLevel.maturityIndicators.items.length > 0
  ) {
    maturityMatrixLevel.maturityIndicators.items.forEach((maturityIndicator) => {
      DeleteMaturityIndicator(maturityIndicator);
    });
  }

  API.graphql(
    graphqlOperation(deleteMaturityMatrixLevel, {
      input: {
        id: maturityMatrixLevel.id,
      },
    }),
  ).then(EventLogger(`maturityMatrixLevel: ${maturityMatrixLevel.id} was Successfully Deleted`));
};

export default DeleteMaturityMatrixLevel;
