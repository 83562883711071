import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import React from "react";
import SelectControlTemplates from "../../Controls/components/SelectControlTemplates";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCustomFieldsTable } from "./useCustomFieldsTable";
import { useForm } from "@hooks/views/useForm/useForm";
import { useModal } from "@hooks/views/useModal";

/**
 * A hook for creating a new Control Framework.
 * @alias Control Framework
 * @param {string} organizationID - the organization/operation team for which to create a control framework
 * @param {string} module - platform module for role checking
 * @param {string} resource - platform resource for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {boolean} isTemplate - if TRUE will hide non-template resource data
 * @param {function} [getNewItem] - callback for a new item (after creation)
 * @param {object} formConfig - passed into the create form
 * @param {function} [toggleModal] - called to close containing modal right as the form is submitted
 * @return {{display: JSX.Element}}
 */
export const useCreateControlSet = ({
  organizationID,
  module = modules.COMPLIANCE,
  resource = resources.CONTROL_FRAMEWORK,
  isTemplate = false,
  disableRoleChecking = false,

  getNewItem,
  formConfig = {},
  toggleModal,
}) => {
  const customFieldsTable = useCustomFieldsTable({
    disableRoleChecking: true,
    enableBackendUpdate: false,
    forceTableVisibility: true,
  });

  /**
   * Modifies the input from the main form to include customFields
   * @param {object} input - object from the main form
   * @returns {object} - the full input object with custom fields attached
   */
  const onSubmit = (input) => {
    //Add the custom fields input
    input.customFields = customFieldsTable.customFields;
    return input;
  };

  /**
   * Wrapper around the normal getNewItem that adds the compliance metric default value
   * @param {object} item - the newly created item
   */
  const customGetNewItem = (item) => {
    const newItem = { ...item, totalComplianceMetric: { id: "newItem" } };
    getNewItem && getNewItem(newItem);
  };

  const fieldConfig = {
    name: {
      required: true,
      label: "Name",
      tooltip: "A unique name for this Control Framework.",
    },
    customFields: {
      label: "Custom Fields",
      tooltip: "Add custom properties unique to this control framework.",
      createItemComponent: customFieldsTable.createButton,
      inputType: "custom",
      customConfig: {
        component: customFieldsTable.display,
      },
    },
  };

  //Control framework templates related
  const checkControlTemplatesHook = useCheckPermissions({
    module: modules.COMPLIANCE,
    resource: resources.CONTROL_FRAMEWORK,
  });

  const selectControlFrameworkTemplateModal = useModal(
    "Select a Control Framework",
    <SelectControlTemplates
      organizationID={organizationID}
      onSubmitCallback={() => {
        toggleModal && toggleModal();
        formHook.toggleModal && formHook.toggleModal();
        selectControlFrameworkTemplateModal.setModalIsOpen(false);
      }}
      getNewItem={customGetNewItem}
    />,
    <Button
      color="primary"
      title="Select a preconfigured control framework template to duplicate into the current organization. This will also duplicate all associated controls and evidence."
      style={{ marginBottom: "1em" }}
    >
      Create from Control Framework Templates
    </Button>,
    { width: "80vw" },
  );

  const formHook = useForm({
    organizationID,
    module,
    resource,
    disableRoleChecking,

    fieldConfig,
    mutation: generateGraphql("ControlSet", ["name", "customFields"], {
      customFields:
        "{name type options { label value } multipleSelect { label value } numberSettings { min max step format }}",
    }).createMutation,
    typename: "ControlSet",
    toggleModal,
    updateInputFunction: (input) => onSubmit(input),
    resetCallback: () => {
      customFieldsTable.setCustomFields([]);
    },
    getNewItem: customGetNewItem,
    ...formConfig,
  });

  const display = (
    <>
      {!isTemplate && checkControlTemplatesHook.resource.create && selectControlFrameworkTemplateModal.modalButton}
      {formHook.display}
    </>
  );

  return {
    display,
  };
};
