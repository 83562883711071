import { Redirect } from "react-router-dom";
import DynamicRedirect from "./DynamicRedirect";

interface CreateRedirectComponentProps {
  to: string;
  isDynamic?: boolean;
}

const createRedirectComponent = ({ to, isDynamic = false }: CreateRedirectComponentProps) => {
  return isDynamic ? () => <DynamicRedirect to={to} /> : () => <Redirect to={to} />;
};

export default createRedirectComponent;
