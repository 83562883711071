import createRedirectComponent from "../components/createRedirectComponent";

interface RouteProps {
  oldPath: string;
  newPath: string;
  isDynamic?: boolean;
  exact?: boolean;
  props?: {
    noScrolling?: boolean;
  };
}

const createRoute = ({ oldPath, newPath, isDynamic = false, exact = true, props = {} }: RouteProps) => {
  let adjustedOldPath: string;
  const isOldPathDynamic = oldPath.includes("/:id");
  if (isOldPathDynamic) {
    adjustedOldPath = oldPath;
  } else {
    adjustedOldPath = isDynamic ? `${oldPath}/:id` : oldPath;
  }

  return {
    path: adjustedOldPath,
    exact,
    component: createRedirectComponent({
      to: newPath,
      isDynamic,
    }),
    ...props,
  };
};

export default createRoute;
