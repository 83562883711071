"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAValueUpper = void 0;
/**
 * Gets the lower availability value (in dollars) from a system.
 * @function
 * @param system
 * @param ratingScale
 * @param assetSize
 * @returns
 */
const getAValueUpper = (system) => {
    const maxA = system ? system?.availabilityUpper : 0;
    return maxA;
};
exports.getAValueUpper = getAValueUpper;
