import { formattedName, isNullOrUndefined } from "@rivial-security/func-utils";
import { DataGridField } from "@hooks/views/useDataGrid/types";
import TargetVulnerabilityPointOfContactField from "@views/Testing/Vulnerabilities/components/TargetVulnerabilityPointOfContactField";
import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import { modules, resources } from "@rivial-security/role-utils";
import { PointOfContact } from "schema-types";
import { ReactElement } from "react";
import PointOfContactName from "@views/OrganizationManager/PointOfContacts/hooks/useSelectPointOfContact/components/PointOfContactName";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

export interface GetTargetVulnerabilityLinkPointOfContactFieldParams {
  getMutationID?: ({ item }: { item: Record<string, unknown> }) => string;
  getDefaultActionItemValues?: ({ row }) => Record<string, unknown>;
}

export const getTargetVulnerabilityLinkPointOfContactField = ({
  getMutationID,
  getDefaultActionItemValues,
}: GetTargetVulnerabilityLinkPointOfContactFieldParams): DataGridField => {
  const field = "pointOfContact";

  return {
    name: field,
    friendlyName: "Responsible",
    component: (
      <TargetVulnerabilityPointOfContactField
        getMutationID={getMutationID}
        getDefaultActionItemValues={getDefaultActionItemValues}
      />
    ),
    flex: 1,
    width: 150,
    disablePropagation: true,
    bulkEdit: true,
    renderBulkEditInput: ({
      submitFunction,
    }: {
      submitFunction?: ({ field, value }: { field: string; value?: unknown }) => void;
    }): ReactElement => {
      return (
        <PointOfContactField
          module={modules.VULNERABILITIES}
          resource={resources.VULNERABILITY}
          field={"pointOfContact"}
          idField={"pointOfContactID"}
          onInitialRender={() => {
            submitFunction?.({ field, value: undefined });
          }}
          onSubmitExternal={({ pointOfContact }: { pointOfContact: Partial<PointOfContact> | null }) => {
            submitFunction?.({ field, value: pointOfContact });
          }}
        />
      );
    },
    bulkEditCustomFields: [
      {
        field: "pointOfContact",
        component: <PointOfContactItem field={"pointOfContact"} />,
      },
      {
        field: "newValue",
        component: <PointOfContactItem field={"newValue"} />,
      },
    ],
    editFunction: async ({ item, value }): Promise<void> => {
      const { updateMutation } = generateGraphql("TargetFindingLink");
      const linkID = item?.targetFindingLinkID ?? (item?.link as IDObject | undefined)?.id;
      const pointOfContactID = (value as IDObject | undefined)?.id;
      if (linkID) {
        await ItemMutation({
          mutation: updateMutation,
          input: {
            id: linkID,
            pointOfContactID: isNullOrUndefined(pointOfContactID) ? null : pointOfContactID,
          },
        });
      }
    },
    undefinedValueValid: true,
    valueGetter: (item): string => {
      const row = item?.row as { pointOfContact: Record<string, unknown> };
      return formattedName({
        pointOfContact: row?.pointOfContact,
      });
    },
    searchKeys: ["pointOfContact.firstName", "pointOfContact.lastName"],
  };
};

interface PointOfContactItemProps {
  item?: Record<string, unknown>;
  field: string;
}

const PointOfContactItem = ({ item, field }: PointOfContactItemProps): ReactElement => {
  return <PointOfContactName pointOfContact={item?.[field] as Record<string, unknown>} isDisabled={true} />;
};

interface IDObject {
  id?: string;
}
