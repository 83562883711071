"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAValueLower = void 0;
/**
 * Gets the lower availability value (in dollars) from a system.
 * @function
 * @param system
 * @returns
 */
const getAValueLower = (system) => {
    const maxA = system ? system?.availabilityLower : 0;
    return maxA;
};
exports.getAValueLower = getAValueLower;
