import { EventLogger } from "../../../../../utils/EventLogger/EventLogger";
import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description onSuccess function called when the user.setDeviceStatusNotRemembered succeeds
 * @param {object} result - result object from the user.setDeviceStatusNotRemembered function
 * @param {function} callback - Callback function
 */
export const onSuccess = (result, callback) => {
  if (isNullOrUndefined(result) || isNullOrUndefined(callback)) {
    throw new Error("[setDeviceStatusNotRemembered.js -> onSuccess] Invalid params");
  }

  callback("Not Assigned");
};

/**
 * @description Function for setting remembered device to "NotRemembered" for a Cognito user
 * @param {object} user - Cognito user object
 * @param {function} callback - Callback function for getting device value
 * @param {function} addToast
 */
export const setDeviceStatusNotRemembered = ({ user, callback, addToast }) => {
  if (isNullOrUndefined(user) || isNullOrUndefined(callback)) {
    throw new Error("[setDeviceStatusNotRemembered.js] Invalid params");
  }

  user.setDeviceStatusNotRemembered({
    onSuccess: (result) => {
      onSuccess(result, callback);
      addToast &&
        addToast({
          header: `Device Status was changed to Not Remembered`,
          icon: "success",
        });
    },
    onFailure: (err) => {
      EventLogger("Error! setDeviceStatusNotRemembered", err);
      addToast &&
        addToast({
          header: `Error! Device Status was NOT changed to Not Remembered`,
          icon: "danger",
        });
    },
  });
};
