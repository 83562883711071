import AWS from "aws-sdk";
import { ItemQuery } from "../Graphql/ItemQuery";
import { EventLogger } from "../../EventLogger/EventLogger";
import { GetOrgIamCredentials } from "./GetOrgIamCredentials";

const getOrganization_s3BucketName = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      s3BucketName
    }
  }
`;

/**
 * @function
 * @description Get a S3 object from a bucket
 * @param bucketName
 * @param objectKey
 * @param organizationID
 * @returns {Promise<void>}
 * @constructor
 */

export const GetS3Object = async ({ bucketName, objectKey, organizationID }) => {
  let s3BucketName = null;

  if (organizationID) {
    const org = await ItemQuery(getOrganization_s3BucketName, organizationID);
    s3BucketName = org?.s3BucketName;
  }

  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: "us-west-2",
  });

  const params = {
    Bucket: s3BucketName || bucketName,
    Key: objectKey,
  };

  try {
    return await s3.getObject(params).promise();
  } catch (e) {
    EventLogger("Error! Cannot get S3 object", e);
    return null;
  }
};
