import { API, graphqlOperation } from "@aws-amplify/api";
import { generateGraphql } from "@rivial-security/generategraphql";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";

/**
 * @description function to get the department id for a given point of contact
 * @param {string} pointOfContactId - the id of the point of contact to get the department id for
 * @param {string} setDepartmentID - function to set the department id
 * @param {string} setDepartmentHeadID - function to set the department head id
 * @return {Promise<*|null>}
 */
export const getPointOfContactDepartmentId = async ({ pointOfContactId, setDepartmentID, setDepartmentHeadID }) => {
  const { getQuery: getPointOfContact } = generateGraphql("PointOfContact", ["id", "departmentID", "department"], {
    department: `{ id name departmentHeadID }`,
  });
  try {
    const result = await API.graphql(graphqlOperation(getPointOfContact, { id: pointOfContactId }));

    if (result?.data && result?.data?.getPointOfContact) {
      setDepartmentID(result?.data?.getPointOfContact?.departmentID);
      setDepartmentHeadID(result?.data?.getPointOfContact?.department?.departmentHeadID);
      return result?.data?.getPointOfContact;
    }
    return null;
  } catch (error) {
    EventLogger("Error fetching PointOfContact:", error);
    return null;
  }
};
