import { useContext, useEffect, useState } from "react";
import { taskCategory } from "../constants/taskCategory";
import { getTasks } from "../functions/getTasks";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import useAdminCheck from "../../../../../../hooks/permissions/useAdminCheck";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useTaskFiltering } from "./useTaskFiltering";
import { useDepartmentInfo } from "./useDepartmentInfo";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";

/**
 * A custom hook to fetch tasks based on the selected menu
 * @param {string} initialSelectedMenu - The initial menu to select
 * @param {boolean} isSidebarView - Whether the component is being rendered in the TaskSidebar or TaskManagementUI
 * @returns {object}
 */
export const useGetTasks = ({ initialSelectedMenu, isSidebarView }) => {
  const [tasks, setTasks] = useState([]);
  const [currentList, setCurrentList] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(initialSelectedMenu || null);
  const context = useContext(OrganizationContext);

  const { isAdmin } = useAdminCheck();
  const adminOwnerGroup = context?.role?.ownerGroup;
  const { departmentID } = useDepartmentInfo();
  const { handleTaskFiltering } = useTaskFiltering({
    selectedMenu,
    departmentID,
    context,
    currentList,
    isSidebarView,
  });

  useEffect(() => {
    if (isNullOrUndefined(selectedMenu) && isSidebarView) {
      setSelectedMenu(taskCategory.MY_TASKS);
    }

    let keyID;
    switch (selectedMenu) {
      case taskCategory.MY_TASKS:
        keyID = context?.loggedInPointOfContactId;
        break;
      case taskCategory.DEPARTMENT_TASKS:
        keyID = departmentID;
        break;
      case taskCategory.PAST_DUE_TASKS:
        keyID = isAdmin ? adminOwnerGroup : context?.selectedOrganization;
        break;
      case currentList?.name:
        keyID = currentList?.id;
        break;
      default:
        keyID = null;
    }

    getTasks({
      taskListType: selectedMenu,
      id: keyID,
      setIsLoading,
      currentList,
    })
      .then((tasks) => {
        const { filteredTasks, completedTasks } = handleTaskFiltering(tasks);
        setTasks(filteredTasks);
        setCompletedTasks(completedTasks || []);
      })
      .catch((err) => {
        EventLogger(`There was an error getting ${selectedMenu} tasks`, err);
        setTasks([]);
        setCompletedTasks([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedMenu, currentList?.name]);

  return {
    isLoading,
    tasks,
    setTasks,
    completedTasks,
    setCompletedTasks,
    selectedMenu,
    setSelectedMenu,
    currentList,
    setCurrentList,
  };
};
