import React, { useState } from "react";

import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { withOrganizationCheck } from "../Context/withOrganizationCheck";

/**
 * Generic UI for being able to view either a Form for Creating a new Item, or a Grid for selecting an existing one
 * Used when wanting to connect a single item to a parent object
 * @param {JSX.Element} form - a component instance of the useForm hook
 * @param {JSX.Element} grid - a component instance of the useGrid hook
 * @param {object} queryConfig - a configuration object for grid
 * @param {object[]} linkedItems - array of items already attached to the parent
 * @param {(item) => callback(item)} callback - called when an item is selected either through the grid or the orm
 * @param {string} [typename = "Resource"] - used to display the typename text
 * @param {object} [prepareSelectionForm] - a form to display before a new item can created or selected
 * @param {string} organizationID - the organizationID to pass to the grid
 * @param {string} [selectItemAlert] - an alert to display when selecting an item
 * @returns {JSX.Element}
 * @constructor
 */
const CreateOrSelect = ({
  form,
  grid,
  prepareSelectionForm,
  queryConfig,
  linkedItems,
  callback,
  typename = "Resource",
  organizationID,
  selectItemAlert,
}) => {
  const [option, setOption] = useState(options.NULL);

  return (
    <div>
      {
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            //NOTE: using css here to maintain state in the form
            display: option === options.NULL ? "flex" : "none",
          }}
        >
          {prepareSelectionForm && prepareSelectionForm.display}
        </div>
      }
      {option !== options.NULL && (
        <span>
          <Button size={"sm"} className={"float-right"} onClick={() => setOption(options.NULL)}>
            Back
          </Button>
        </span>
      )}
      {(() => {
        switch (option) {
          case options.SELECT:
            return (
              <SelectItem
                grid={grid}
                callback={callback}
                linkedItems={linkedItems}
                queryConfig={queryConfig}
                organizationID={organizationID}
                prepareSelectionForm={prepareSelectionForm}
                selectItemAlert={selectItemAlert}
              />
            );
          case options.CREATE:
            return (
              <CreateItem
                form={form}
                callback={callback}
                organizationID={organizationID}
                prepareSelectionForm={prepareSelectionForm}
              />
            );
          default:
            return (
              <Chooser
                setOption={setOption}
                typename={typename}
                form={form}
                prepareSelectionForm={prepareSelectionForm}
              />
            );
        }
      })()}
    </div>
  );
};

/**
 * Enum for the various states of this component
 * @type {{NULL: number, CREATE: number, SELECT: number}}
 */
const options = {
  NULL: 0,
  SELECT: 1,
  CREATE: 2,
};

/**
 * Handles the CreateItem part of this component.
 * Must be a component instance of the useForm hook, will inject the necessary props
 * @param {JSX.Element} form - a component instance of the useForm hook that can be used to create a new resource instance
 * @param {function} callback - the callback function to call on submit of the form with the form input
 * @param {string} organizationID - the organization for which the resource is created
 * @param {object} prepareSelectionForm - preconfigured form displayed before the create form can be entered (input is passed down)
 * @returns {JSX.Element} - the create panel of create or select UI
 */
const CreateItem = ({ form, callback, organizationID, prepareSelectionForm }) => {
  return (
    <div>
      {form &&
        React.cloneElement(form, {
          getNewItem: callback,
          organizationID,
          ...prepareSelectionForm?.input,
        })}
    </div>
  );
};

/**
 * Handles the SelectItem part of this component.
 * Must be an component instance of the useGrid hook, will inject the necessary props
 * @param {JSX.Element} grid - a component instance of the useGrid hook, used to display existing items that can be selected
 * @param {function} callback - the callback function to call on submit of the form with the selected item
 * @param {object[]} linkedItems - all items that have already been linked to the parent (used to filter the these items out)
 * @param {object} queryConfig - query config passed down to the grid component
 * @param {string} organizationID - the organization for which to display the selectable resources
 * @param {object} prepareSelectionForm - preconfigured form displayed before the create form can be entered (input is passed down)
 * @param {string} selectItemAlert - an alert to display when selecting an item
 * @returns {JSX.Element}
 */
const SelectItem = ({
  grid,
  callback,
  linkedItems,
  queryConfig,
  organizationID,
  prepareSelectionForm,
  selectItemAlert,
}) => {
  queryConfig = {
    //Filters out items already attached to the parent resource
    normalizeData: (items) => {
      if (Array.isArray(items)) {
        return items.filter((item) => {
          if (Array.isArray(linkedItems)) {
            const index = linkedItems.findIndex((linkedItem) => {
              return linkedItem.id === item.id;
            });

            return index === -1;
          } else {
            return true;
          }
        });
      } else {
        return [];
      }
    },
    ...queryConfig,
  };

  return (
    <div
      style={{
        height: "100%",
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {selectItemAlert && (
        <Alert severity={"warning"} style={{ marginBottom: "1em" }}>
          {selectItemAlert}
        </Alert>
      )}

      {grid &&
        React.cloneElement(grid, {
          queryConfig,
          organizationID,
          /**
           * Params for the useDataGrid hook
           */
          enableSelectButtons: true,
          enableSelectButton: true,
          onSelectCallback: callback, // handles old and new grids
          selectButtonCallback: callback, // handles old and new grids
          /**
           * Parameters gathered from the prepare for selection form
           */
          ...prepareSelectionForm?.input,
        })}
    </div>
  );
};

/**
 * The UI used to choose between the Form and the Grid
 * @param setOption
 * @param typename
 * @param form
 * @param prepareSelectionForm
 * @returns {JSX.Element}
 * @constructor
 */
const Chooser = ({ setOption, typename = "Resource", form, prepareSelectionForm }) => {
  return (
    <div style={{ textAlign: "center" }}>
      {form && (
        <>
          <Button
            disabled={prepareSelectionForm?.submitDisabled ?? false}
            onClick={() => setOption(options.CREATE)}
            startIcon={<i className={"icon-plus"} />}
          >
            Create a New {convertCamelCaseToSentence(typename)}
          </Button>
          <br />
          OR
          <br />
        </>
      )}
      <Button
        disabled={prepareSelectionForm?.submitDisabled ?? false}
        onClick={() => setOption(options.SELECT)}
        startIcon={<i className={"icon-list"} />}
      >
        Select an Existing {convertCamelCaseToSentence(typename)}
      </Button>
    </div>
  );
};

export default withOrganizationCheck(CreateOrSelect);
