import React from "react";
import { Redirect } from "react-router-dom";
import TaskManagementUI from "../../views/Program/Tasks/components/TasksUI/components/TaskManagementUI";
import createRoute from "./functions/createRoute";

const VCISOMaturityMatrix = React.lazy(
  () => import("../../views/Program/VCISOMaturityMatrix/components/MaturityMatrix/components/DisplayOneMaturityMatrix"),
);
const VCISOMaturityMatrixDetails = React.lazy(
  () => import("../../views/Program/VCISOMaturityMatrix/components/MaturityMatrix/components/BlueprintDetails"),
);
const VCISOMaturityMatrixImplementationLevel = React.lazy(
  () =>
    import(
      "../../views/Program/VCISOMaturityMatrix/components/ImplementationLevel/components/ImplementationLevelDataGrid"
    ),
);
const VCISOMaturityMatrixImplementationLevelDetails = React.lazy(
  () =>
    import(
      "../../views/Program/VCISOMaturityMatrix/components/ImplementationLevel/components/ImplementationLevelDetailsCard"
    ),
);
const VCISOMaturityMatrixCalendar = React.lazy(
  () => import("../../views/Program/VCISOMaturityMatrix/components/MaturityMatrixCalendar/components/VCISOCalendar"),
);
const DuplicateMatrix = React.lazy(
  () => import("../../views/Program/VCISOMaturityMatrix/components/MaturityMatrix/deprecated/DuplicateMaturityMatrix"),
);
const MeetingDashboard = React.lazy(() => import("../../views/Program/Meetings/components/MeetingsDataGrid"));
const MeetingDetails = React.lazy(() => import("../../views/Program/Meetings/components/MeetingDetails"));
const ProgramReport = React.lazy(() => import("../../views/Reports/ProgramReport/components/ProgramReport"));
const BlueprintReport = React.lazy(() => import("../../views/Reports/BlueprintReport/components/BlueprintReport"));
const PoliciesList = React.lazy(() => import("../../views/Program/Policies/components/PolicyDataGrid"));
const PolicyDetails = React.lazy(() => import("../../views/Program/Policies/components/PolicyDetails"));
const ReviewPolicy = React.lazy(() => import("../../views/Program/Policies/components/ReviewPolicy"));
const spreadsheet = React.lazy(() => import("../../views/Program/Spreadsheets/components/SpreadsheetDataGrid"));
const spreadsheetDetails = React.lazy(() => import("../../views/Program/Spreadsheets/components/SpreadsheetDetails"));
const FindingsDashboard = React.lazy(
  () => import("../../views/Program/Findings/components/FindingsDashboard/FindingsDashboard"),
);
const ActionItemGrid = React.lazy(() => import("../../views/Program/Actions/components/ActionDataGrid"));
const ActionItemDetails = React.lazy(() => import("../../views/Program/Actions/components/ActionItemDetails"));
const ObservationDetails = React.lazy(() => import("../../views/Program/Observations/components/ObservationDetails"));
const ObservationDataGrid = React.lazy(
  () => import("../../views/Program/Observations/components/ObservationsDataGrid"),
);
const RecommendationDetails = React.lazy(
  () => import("../../views/Program/Recommendations/components/RecommendationDetails"),
);
const RecommendationGrid = React.lazy(
  () => import("../../views/Program/Recommendations/components/RecommendationsDataGrid"),
);
const SourceDataGrid = React.lazy(() => import("../../views/Program/Sources/components/SourceDataGrid"));
const SourceDetails = React.lazy(() => import("../../views/Program/Sources/components/SourceDetails"));
const TaskDetails = React.lazy(() => import("../../views/Program/Tasks/components/TaskDetails"));
const FindingsImporter = React.lazy(() => import("../../views/Program/FindingsImporter/components/FindingsImporter"));
const AIPolicyGeneration = React.lazy(() => import("../../views/AI/AIPolicyGeneration/AIPolicyGeneration"));

const governanceRouteConfigurations = [
  { oldPath: "/program/matrix", newPath: "/governance/blueprints" },
  {
    oldPath: "/program/matrix/:id",
    newPath: "/governance/blueprints/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/implementationLevels",
    newPath: "/governance/implementationLevels",
  },
  {
    oldPath: "/program/implementationLevels/:id",
    newPath: "/governance/implementationLevels/:id",
    isDynamic: true,
  },
  { oldPath: "/program/calendar", newPath: "/governance/calendar" },
  {
    oldPath: "/program/duplicate_matrix",
    newPath: "/governance/duplicate_matrix",
  },
  { oldPath: "/program/meetings", newPath: "/governance/meetings" },
  {
    oldPath: "/program/meetings/:id",
    newPath: "/governance/meetings/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/program_report",
    newPath: "/governance/executive_summary_report",
  },
  {
    oldPath: "/program/blueprint_reports",
    newPath: "/governance/blueprint_reports",
  },
  { oldPath: "/program/policies", newPath: "/governance/policies" },
  {
    oldPath: "/program/policies/:id",
    newPath: "/governance/policies/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/review_policy/:id",
    newPath: "/governance/review_policy/:id",
    isDynamic: true,
  },
  { oldPath: "/program/spreadsheets", newPath: "/governance/spreadsheets" },
  {
    oldPath: "/program/spreadsheets/:id",
    newPath: "/governance/spreadsheets/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/findings_dashboard",
    newPath: "/governance/findings_dashboard",
  },
  { oldPath: "/program/action_items", newPath: "/governance/action_items" },
  {
    oldPath: "/program/action_items/:id",
    newPath: "/governance/action_items/:id",
    isDynamic: true,
  },
  { oldPath: "/program/observations", newPath: "/governance/observations" },
  {
    oldPath: "/program/observations/:id",
    newPath: "/governance/observations/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/recommendations",
    newPath: "/governance/recommendations",
  },
  {
    oldPath: "/program/recommendations/:id",
    newPath: "/governance/recommendations/:id",
    isDynamic: true,
  },
  { oldPath: "/program/sources", newPath: "/governance/sources" },
  {
    oldPath: "/program/sources/:id",
    newPath: "/governance/sources/:id",
    isDynamic: true,
  },
  { oldPath: "/program/tasks", newPath: "/governance/tasks" },
  {
    oldPath: "/program/tasks/:id",
    newPath: "/governance/tasks/:id",
    isDynamic: true,
  },
  {
    oldPath: "/program/import_findings",
    newPath: "/governance/import_findings",
  },
  { oldPath: "/program", newPath: "/governance/blueprints" }, // catch-all redirect for legacy /program routes
];
const newGovernanceRoutes = [
  {
    path: "/governance/blueprints",
    exact: true,
    name: "Blueprints",
    component: VCISOMaturityMatrix,
  },
  {
    path: "/governance/blueprints/:id",
    exact: true,
    name: "Program Blueprint Details",
    component: VCISOMaturityMatrixDetails,
  },
  {
    path: "/governance/implementationLevels",
    exact: true,
    name: "Blueprint Implementation Levels",
    component: VCISOMaturityMatrixImplementationLevel,
  },
  {
    path: "/governance/implementationLevels/:id",
    exact: true,
    name: "Blueprint Implementation Level Details",
    component: VCISOMaturityMatrixImplementationLevelDetails,
  },
  {
    path: "/governance/calendar",
    exact: true,
    name: "Calendar",
    component: VCISOMaturityMatrixCalendar,
  },
  {
    path: "/governance/duplicate_matrix",
    exact: true,
    name: "Select a Matrix",
    component: DuplicateMatrix,
  },
  {
    path: "/governance/meetings",
    exact: true,
    name: "Meetings",
    component: MeetingDashboard,
  },
  {
    path: "/governance/meetings/:id",
    exact: true,
    name: "Meeting Details",
    component: MeetingDetails,
  },
  {
    path: "/governance/executive_summary_report",
    noScrolling: true,
    exact: true,
    name: "Board Report",
    component: ProgramReport,
  },
  {
    path: "/governance/blueprint_reports",
    noScrolling: true,
    exact: true,
    name: "Blueprint Report",
    component: BlueprintReport,
  },
  {
    path: "/governance/policies",
    exact: true,
    name: "Policies",
    component: PoliciesList,
  },
  {
    path: "/governance/policies/ai_policy_generation",
    exact: true,
    name: "AI Policy Generation",
    component: AIPolicyGeneration,
  },
  {
    path: "/governance/policies/:id",
    exact: true,
    name: "Policy Details",
    component: PolicyDetails,
  },
  {
    path: "/governance/review_policy/:id",
    exact: true,
    name: "Acknowledge Security Policy",
    component: ReviewPolicy,
    noScrolling: true,
  },
  {
    path: "/governance/spreadsheets",
    exact: true,
    name: "Spreadsheets",
    component: spreadsheet,
  },
  {
    path: "/governance/spreadsheets/:id",
    exact: true,
    name: "Spreadsheet Details",
    component: spreadsheetDetails,
  },
  {
    path: "/governance/findings_dashboard",
    exact: true,
    name: "Findings and Observations",
    component: FindingsDashboard,
  },
  {
    path: "/governance/action_items",
    exact: true,
    name: "Action Items",
    component: ActionItemGrid,
  },
  {
    path: "/governance/action_items/:id",
    exact: true,
    name: "Action Item Details",
    component: ActionItemDetails,
  },
  {
    path: "/governance/observations",
    exact: true,
    name: "Observations",
    component: ObservationDataGrid,
  },
  {
    path: "/governance/observations/:id",
    exact: true,
    name: "Observation Details",
    component: ObservationDetails,
  },
  {
    path: "/governance/recommendations",
    exact: true,
    name: "Recommendations",
    component: RecommendationGrid,
  },
  {
    path: "/governance/recommendations/:id",
    exact: true,
    name: "Recommendation Details",
    component: RecommendationDetails,
  },
  {
    path: "/governance/sources",
    exact: true,
    name: "Sources",
    component: SourceDataGrid,
  },
  {
    path: "/governance/sources/:id",
    exact: true,
    name: "Source Details",
    component: SourceDetails,
  },
  {
    path: "/governance/tasks",
    exact: true,
    name: "Tasks",
    component: TaskManagementUI,
  },
  {
    path: "/governance/tasks/:id",
    exact: true,
    name: "Task Details",
    component: TaskDetails,
  },

  {
    path: "/governance/import_findings",
    exact: true,
    name: "Import Findings",
    component: FindingsImporter,
  },

  {
    path: "/governance",
    component: () => <Redirect to="/governance/blueprints" />,
  },
];
export const governanceRoutes = [
  ...governanceRouteConfigurations.map((config) => createRoute(config)),
  ...newGovernanceRoutes,
];
