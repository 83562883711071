import { withOrganizationCheck } from "../Context/withOrganizationCheck";
import React from "react";

/**
 * A generic grid wrapper that can be used to quickly convert a normal grid hook into a select button grid (with callback)
 * @param {string} organizationID - currently selected organization
 * @param {function} selectButtonCallback - function that is called when a row in the grid is selected
 * @param {React.Hook} gridHook - the grid to use for selecting items
 * @param {boolean} enableCreate - whether or not to enable the create button (if its avaliable for the grid)
 * @param {string} height - the height of the grid
 * @return {JSC.Element} - the grid with the select button on each row
 */
export const SelectItemGrid = ({ organizationID, selectButtonCallback, gridHook, enableCreate, height } = {}) => {
  const grid = gridHook({
    organizationID,
    gridConfig: {
      ...(enableCreate !== true && { createItemComponent: null }),
      enableSelectButtons: true,
      selectButtonCallback,
    },

    //Data grid props
    onSelectCallback: selectButtonCallback,
    ...(enableCreate !== true && { createResourceComponent: null }),
    enableSelectButton: true,
  });

  return <div style={{ height: height || "70vh" }}>{grid.gridDisplay}</div>;
};

export default withOrganizationCheck(SelectItemGrid);
