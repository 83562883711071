import "./css/styles.css";

import React, { useEffect, useState } from "react";

import { Alert } from "reactstrap";
import DocumentViewer from "./DocumentPreviews/DocumentViewer";
import { EventLogger } from "../EventLogger/EventLogger";
import FileViewer from "@rivial-security/react-file-viewer";
import ImageViewer from "./ImagePreviews/ImageViewer";
import Loader from "../LoadingComponents/Loader";
import SpreadsheetViewer from "./SpreadsheetPreviews/SpreadsheetViewer";

export const getFileType = (url) => {
  return url?.split(/[#|?]/)[0].split(".").pop().trim().toLowerCase();
};

/**
 * Display files from a url
 * @param {object} input
 * @param {string} input.url - the url of the file to display
 * @param {string} [input.viewerHeight] - the css height to use for the document viewer
 * @return {{display: JSX.Element}}
 */
export const useFileViewer = ({ url, viewerHeight }) => {
  const imageFileTypes = ["jpg", "png", "gif"];
  const documentFileTypes = ["dotx", "docx", "docm", "dot", "doc", "sfdt", "txt"];
  const spreadsheetFileTypes = ["xlsx", "xls", "csv"];

  const [fileType, setFileType] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!url) return;
    setFileType(getFileType(url));
  }, [url]);

  const display = (
    <div key={url} style={{ height: "100%" }}>
      {error ? (
        <Alert color="danger">{error}</Alert>
      ) : url && fileType ? (
        <div style={{ height: "100%" }}>
          {(() => {
            if (imageFileTypes.findIndex((extension) => extension === fileType) !== -1) {
              return <ImageViewer url={url} />;
            } else if (documentFileTypes.findIndex((extension) => extension === fileType) !== -1) {
              return <DocumentViewer url={url} fileType={fileType} viewerHeight={viewerHeight} />;
            } else if (spreadsheetFileTypes.findIndex((extension) => extension === fileType) !== -1) {
              return <SpreadsheetViewer url={url} />;
            } else {
              return <FileViewer fileType={fileType} filePath={url} onError={(error) => onError(error)} />;
            }
          })()}
        </div>
      ) : (
        <div style={{ margin: "auto", textAlign: "center" }}>
          <Loader style={{ width: "10em", height: "10em" }} size="sm" color="primary" />
        </div>
      )}
    </div>
  );

  //Generic File Preview Error
  const onError = (error) => {
    EventLogger(error);
    setError(
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3>Can not view the file</h3>
        <p>Please contact a Rivial administrator</p>
      </div>,
    );
  };

  return {
    display,
  };
};
