import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import CustomQueryEditor from "../components/CustomQueryEditor";
import CustomQueryJobsGrid from "../components/CustomQueryJobsGrid";
import DashboardCard from "../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import { EventLogger } from "../../../utils/EventLogger/EventLogger";
import StringListField from "../../../utils/CustomFields/StringListField";
import StyledWrapper from "../../../utils/GenericComponents/StyledWrapper";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isTemplateOrganizationID } from "../../Compliance/utils/isTemplateOrganizationID";
import { useCustomQueryRunner } from "./useCustomQueryRunner/useCustomQueryRunner";
import { useDetailsCard } from "../../../hooks/views/useDetailsCardV2";
import { useModal } from "../../../hooks/views/useModal";
import { useResultFieldViewer } from "./useResultFieldViewer";

/**
 * Displays the details for a single custom query item
 * @param {string} itemId - the id of the custom query for which to show details
 * @param {string} organizationID - the id of the currently selected organization
 * @param {boolean} tableDisplay - if true the details will not be placed in a card
 * @param {function} updateItemById - callback for updating parent state if the item is updated in the details card
 * @param {object} props - any additional props to pass through to the useDetailsCard hook
 */
export const useCustomQueryDetails = ({ itemId, organizationID, tableDisplay, updateItemById, ...props }) => {
  const isTemplateWidget = isTemplateOrganizationID(organizationID);
  const module = isTemplateWidget ? modules.ADMINISTRATOR : modules.ORGANIZATION_MANAGER;
  const resource = resources.CUSTOM_QUERY;
  const typename = "CustomQuery";

  //SAMPLE FIELD CONFIG
  const fields = ["name", "description", "typename", "fields", "rules", "graphql", "queryConfig"];
  const [query, setQuery] = useState();
  const [parsedQueryConfig, setParsedQueryConfig] = useState({});

  const { getQuery, updateMutation } = generateGraphql(typename, fields);

  const queryConfig = {
    itemId,
    organizationID,
    query: getQuery,
  };

  // - UI for viewing and selecting out the created result fields
  const resultFieldViewer = useResultFieldViewer({
    resultFields: parsedQueryConfig?.fields,
    resultFunctions: parsedQueryConfig?.functions,
    // setPath: resultFieldBuilder?.setPath,
  });

  const queryEditorModal = useModal(
    "Edit Custom Query",
    <StyledWrapper wrapperStyle={{ height: "80vh" }}>
      <CustomQueryEditor
        query={{
          ...query,
          queryConfig: parsedQueryConfig,
        }}
      />
    </StyledWrapper>,
    <Button variant={"contained"}>Edit Query</Button>,
    {
      width: "80vw",
    },
  );

  const detailsCardHook = useDetailsCard({
    queryConfig,
    module,
    resource,
    fieldNameDictionary: {
      queryConfig: "Configuration",
    },
    fields: ["name", "description", "queryConfig", "typename"],
    config: {
      header: "Custom Query Details",
    },
    detailsConfig: {
      updateMutation,
      customFields: [
        {
          field: "fields",
          component: <StringListField fieldName={"fields"} />,
        },
        {
          field: "rules",
          component: <StringListField fieldName={"rules"} />,
        },
        {
          field: "typename",
          isHidden: true,
        },
        {
          field: "queryConfig",
          component: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5em",
              }}
            >
              {resultFieldViewer.display}
              <div>{queryEditorModal.modalButton}</div>
            </div>
          ),
        },
      ],
    },
    typename,
    tableDisplay,
    updateItemById,
    ...props,
  });

  useEffect(() => {
    const queryConfig = detailsCardHook?.item?.queryConfig;
    if (queryConfig) {
      let newParsedQueryConfig = {};
      try {
        newParsedQueryConfig = JSON.parse(queryConfig);
      } catch (e) {
        EventLogger("Error parsing queryConfig", e);
      }
      setQuery(detailsCardHook?.item);
      setParsedQueryConfig(newParsedQueryConfig);
    }
  }, [detailsCardHook?.item?.queryConfig]);

  const runner = useCustomQueryRunner({
    organizationID,
    query: parsedQueryConfig,
    customQueryID: itemId,
    persistenceUUID: "details-custom-query-runner",
  });

  return {
    ...detailsCardHook,
    display: (
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <DashboardCard icon={"icon-list"} title={"General Information"}>
            {detailsCardHook.tableDisplay}
          </DashboardCard>
        </Grid>

        <Grid item lg={8} md={8} sm={12} xs={12}>
          <DashboardCard icon={"icon-list"} title={"History"}>
            <CustomQueryJobsGrid organizationID={organizationID} itemId={itemId} module={module} resource={resource} />
          </DashboardCard>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DashboardCard
            icon={"icon-list"}
            title={"Query Results"}
            wrapperStyle={{ height: "70vh" }}
            style={{ height: "70vh" }}
          >
            {runner.display}
          </DashboardCard>
        </Grid>
      </Grid>
    ),
  };
};
