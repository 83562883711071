import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import Typography from "@mui/material/Typography";
import React from "react";
import { getDotIcon } from "./EvidenceActivityItem/functions/getDotIcon";
import { getTimelineContent } from "./EvidenceActivityItem/functions/getTimelineContent";
import { getTimelineDate } from "./EvidenceActivityItem/functions/getTimelineDate";
import { getDotColor } from "./EvidenceActivityItem/functions/getDotColor";
import { Button } from "reactstrap";
import { useModal } from "../../../../hooks/views/useModal";
import EvidenceDetails from "./EvidenceDetails";
import { GenericDeleteButton } from "../../../../utils/GenericComponents/GenericDeleteButton";
import { useBoolean } from "../../../../hooks/functional/useBoolean";
import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { modules, resources } from "@rivial-security/role-utils";
import { deleteEvidenceActivity } from "./EvidenceActivityItem/functions/deleteEvidenceActivity";
import { EVIDENCE_ACTIVITY_TYPES } from "../../../../typedefs/Compliance/Evidence/Evidence";
import UnderlinedTooltip from "../../../../utils/GenericComponents/Tooltips/UnderlinedTooltip";
import EvidenceActivityArtifactButton from "./EvidenceActivityItem/components/EvidenceActivityArtifactButton";

/**
 * Displays a single item in the Evidence timeline
 * @param {boolean} [isLastItem = false] - determines if there should be a trailing connector
 * @param {EvidenceType} type - the type of evidence
 * @param {PointOfContact} pointOfContact - the point of contact
 * @param {object} keyPerformanceIndicator - the kpi that triggered the evidence to change its status
 * @param {Date} date - the date of the activity
 * @param {string} validatedBy - the user that validated the evidence
 * @param {object[]} documents - the documents attached to the evidence
 * @param {boolean} attested - whether the evidence is attested
 * @param {object} report - the report that the evidence is attached to
 * @param {object} meeting - the meeting that the evidence is attached to
 * @param {object} policy - the policy that the evidence is attached to
 * @param {object} screenshot - the screenshot that the evidence is attached to
 * @param {string} comment - the comment that the evidence is attached to
 * @param {string} externalUrl - the external url that the evidence is attached to
 * @param {object} control - the control that the evidence is attached to
 * @param {object} riskChange - the risk change that the evidence is attached to
 * @param {string} evidenceName - if present will enable show more content per each timeline entry
 * 1. evidence name will be displayed above the date of activity
 * 2. date and time text will appear smaller
 * @param {string} evidenceID - evidence ID
 * @param {string} phishingID - KB4 phishing ID
 * @param {string} trainingID - KB4 training ID
 * @param {string} groupID - group ID
 * @param {object[]} activities - the evidence activities
 * @param {object} item - full evidence activity item
 * @param {function} resetFunction - resets parent query to re-fetch all activity
 * @param {string} automationID - the automation ID
 * @param {string} data - custom data, needs to be parsed
 * @param {object} artifact - the artifact that the evidence is attached to
 * @returns {JSX.Element}
 * @constructor
 */
const EvidenceActivityItem = ({
  isLastItem,
  type = "documentUpload",
  pointOfContact = { firstName: "Someone" },
  keyPerformanceIndicator,
  date,
  validatedBy,
  documents,
  attested,
  report,
  meeting,
  policy,
  screenshot,
  comment,
  externalUrl,
  control,
  riskChange,
  evidenceName,
  evidenceID,
  phishingID,
  trainingID,
  groupID,
  activities,
  item,
  resetFunction,
  automationID,
  data,
  artifact,
}) => {
  const EvidenceDetailsComponent = <EvidenceDetails item={{ id: evidenceID }} />;

  const evidenceModalHook = useModal("Evidence Details", EvidenceDetailsComponent, <></>, { width: "85%" });

  const checkPermissions = useCheckPermissions({
    module: modules.COMPLIANCE,
    resource: resources.EVIDENCE,
  });

  const [showDelete, setShowDelete] = useBoolean();

  const onHoverOn = () => {
    if (checkPermissions.resource.update) {
      setShowDelete(true);
    }
  };

  const onHoverOff = () => {
    setShowDelete(false);
  };

  return (
    <TimelineItem onMouseEnter={onHoverOn} onMouseLeave={onHoverOff}>
      <TimelineOppositeContent>
        {evidenceName && (
          <div style={{ marginBottom: ".5em", width: "12em" }}>
            {evidenceModalHook.modal}
            {evidenceID ? (
              <Button
                title={"Click to view Evidence Details"}
                onClick={() => evidenceModalHook.setModalIsOpen(true)}
                color={"link"}
                style={{ textAlign: "right", padding: 0 }}
              >
                <span
                  style={{
                    borderBottom: "2px dashed",
                    textDecoration: "none;",
                    cursor: "pointer",
                    fontSize: ".9em",
                    color: "grey",
                  }}
                >
                  {evidenceName}
                </span>
              </Button>
            ) : (
              <Typography style={{ marginBottom: ".5em", fontSize: "1em" }} color="textSecondary">
                {evidenceName}
              </Typography>
            )}
          </div>
        )}
        <Typography color="textSecondary" style={{ ...(evidenceName && { fontSize: "10pt" }) }}>
          {getTimelineDate(date)}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={getDotColor(type)}>{getDotIcon(type)}</TimelineDot>
        {isLastItem === false && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {getTimelineContent({
          pointOfContact,
          keyPerformanceIndicator,
          validatedBy,
          type,
          documents,
          attested,
          report,
          meeting,
          screenshot,
          externalUrl,
          control,
          policy,
          riskChange,
          trainingID,
          phishingID,
          groupID,
          activities,
          automationID,
          data,
          artifact,
        })}
        {comment && (
          <span>
            {" "}
            with a{" "}
            <UnderlinedTooltip tooltip={comment}>
              <span>comment</span>
            </UnderlinedTooltip>
          </span>
        )}
        {artifact && <EvidenceActivityArtifactButton artifact={artifact} />}
        {showDelete && type !== EVIDENCE_ACTIVITY_TYPES.CREATED_AT && (
          <span className={"float-right"} style={{ position: "absolute", right: "-1em", top: "0em" }}>
            <GenericDeleteButton deleteFunction={deleteEvidenceActivity} item={item} resetFunction={resetFunction} />
          </span>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default EvidenceActivityItem;
