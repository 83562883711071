import { generateGraphql } from "@rivial-security/generategraphql";

export const listOrganizations_custom = `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      shortName
      orgType
      s3BucketName
      publicRSAKey
    }
    nextToken
  }
}
`;

export const deleteOrganization_custom = `mutation DeleteOrganization($input: DeleteOrganizationInput!) {
  deleteOrganization(input: $input) {
    id
    name
    shortName
    s3BucketName
  }
}
`;

export const getOrganization_minimal = `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    shortName
    orgType
    s3BucketName
    publicRSAKey
    assetSize
    riskSetup {
      high
      mediumHigh
      medium
      lowMedium
      low
    }
  }
}
`;

const { updateMutation } = generateGraphql("Organization", ["name"]);

/**
 * Since Organization does not have an 'ownerGroup' field, manually remove from the graphql strings
 */
export const updateOrganization = updateMutation.replace(/ownerGroup/g, "");
