import { ItemQuery } from "../Graphql/ItemQuery";
import { GetOrgIamCredentials } from "./GetOrgIamCredentials";
import AWS from "aws-sdk";
import { EventLogger } from "../../EventLogger/EventLogger";
import awsmobile from "../../../aws-exports";
import { S3FileType } from "./types/S3FileType";

/**
 * Get Signed S3 object URL
 */
export const getSignedObjectUrlV2 = async ({ bucket, key, organizationID }: S3FileType): Promise<string> => {
  let s3BucketName = bucket;

  const getOrganization_s3BucketName = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        name
        s3BucketName
      }
    }
  `;

  if (!bucket && organizationID) {
    const org: { s3BucketName?: string } = await ItemQuery(getOrganization_s3BucketName, organizationID);
    s3BucketName = org?.s3BucketName;
  }

  /**
   * Get credentials for S3 bucket
   */
  const credentials = await GetOrgIamCredentials({ organizationID });

  const s3 = new AWS.S3({
    credentials,
    region: awsmobile?.aws_project_region,
  });

  const params = { Bucket: s3BucketName, Key: key, Expires: 300 };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        EventLogger("Error! ", err);
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};
