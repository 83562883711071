import LayoutFooterElement from "./Element";
import React, { useContext } from "react";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { useUIContext } from "@utils/Context/UIContext";

/**
 * Gets the width and breakpoint of the current viewport to display for Dev accounts
 * @returns {JSX.Element}
 * @constructor
 */
const WidthElement = () => {
  const context = useContext(OrganizationContext);

  /**
   * Gets the current width and breakpoint information to display in the footer for dev accounts
   */
  const { width, getCurrentBreakpoint } = useUIContext();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }} />
      {context?.isDev && (
        <LayoutFooterElement>
          {width}({getCurrentBreakpoint()})
        </LayoutFooterElement>
      )}
    </div>
  );
};

export default WidthElement;
