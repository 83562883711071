import { useContext, useEffect } from "react";
import { UIContext } from "../../../../utils/Context/UIContext";
import { useCsvImporter } from "../../../CsvImporters/hooks/useCsvImporter";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";

export const useUploadInformationAssets = ({ organizationID, toggleModal, resetFunction }) => {
  const { addToast, updateToast } = useContext(UIContext);

  const ratings = ["high", "mediumHigh", "medium", "lowMedium", "low"];

  const headerObjects = [
    {
      title: "name",
      type: "text",
    },
    {
      title: "confidentiality",
      type: "dropdown",
      source: ratings,
    },
    {
      title: "integrity",
      type: "dropdown",
      source: ratings,
    },
    {
      title: "confidentialityPerRecord",
      type: "number",
    },
    {
      title: "integrityPerRecord",
      type: "number",
    },
  ];

  const fields = [
    "id",
    "name",
    "confidentiality",
    "integrity",
    "confidentialityPerRecord",
    "integrityPerRecord",
    "ownerGroup",
  ];
  const { listQuery, updateMutation } = generateGraphql("InformationAsset", fields);
  const { createMutation } = generateGraphql("InformationAsset", [...fields, "createdAt"]);
  const { deleteMutation } = generateGraphql("InformationAsset", ["id"]);

  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Information Assets",
    passThroughDuplicateScanner: true,
  });

  //Load source information into csv uploader for duplicates scanner
  useEffect(() => {
    csvImporter.initSourceQuery(listQuery, organizationID);
  }, []);

  const getFieldValue = (object, field) => {
    return object && object[field] ? object[field] : undefined;
  };

  //see useDuplicatesScanner Output for input into this function
  const createItemsFromArray = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      //Close modal & show update status
      toggleModal && toggleModal();
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;
      addToast({
        id: "csv_upload_info_assets",
        icon: "spinner",
        header: `CSV Upload for Information Assets Started (${totalOperations} Operations)`,
      });

      const informationAssetList = await ListQuery({
        query: listQuery,
        organizationID,
      });

      //perform information asset update
      for (const operationItem of operations.update) {
        //Check if item to mutate exists
        const itemFound = informationAssetList.some(
          (originalItem) => originalItem.id != null && operationItem.id != null && operationItem.id === originalItem.id,
        );

        if (itemFound) {
          await ItemMutation(updateMutation, operationItem);
        }
      }

      //TODO: correctly perform information asset deletions (by deleting associated links)
      //
      // for (let operationItem of operations.delete) {
      //   const foundItem = informationAssetList.find((originalItem) => {
      //     return (
      //       originalItem.id != null &&
      //       operationItem.id != null &&
      //       originalItem.id === operationItem.id
      //     );
      //   });
      //
      //   if (foundItem != null) {
      //     const deletionItem = {id: foundItem.id}
      //     await ItemMutation(deleteMutation, deletionItem);
      //   }
      // }

      //perform information asset additions
      for (const operationItem of operations.add) {
        if (operationItem.name) {
          // set up input
          const input = {};
          input.name = operationItem.name;
          input.confidentiality = getFieldValue(operationItem, "confidentiality");
          input.integrity = getFieldValue(operationItem, "integrity");
          input.ownerGroup = organizationID;

          input.confidentialityPerRecord = operationItem?.confidentialityPerRecord || 0;
          input.integrityPerRecord = operationItem?.integrityPerRecord || 0;

          await ItemMutation(createMutation, input);
        } else {
          EventLogger("Error: Information Assets need a name!");
        }
      }

      updateToast({
        id: "csv_upload_info_assets",
        icon: "success",
        header: `Successfully uploaded Information Assets (${totalOperations} Operations)`,
      });

      resetFunction && resetFunction();
    }
  };

  // Perform the updates
  useEffect(() => {
    if (csvImporter.dataJSONReady) {
      createItemsFromArray(csvImporter.dataJSONReady).then(() => {
        updateToast({
          id: "csv_upload_info_assets",
          icon: "success",
          header: `Information Assets CSV Upload is Complete!`,
        });
      });
    }
  }, [csvImporter.dataJSONReady]);

  return {
    ...csvImporter,
    createItemsFromArray,
  };
};
