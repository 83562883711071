import { getSortedByVersionReportDocuments } from "./getSortedByVersionReportDocuments";
import { EventLogger } from "../../../utils/EventLogger/EventLogger";

/**
 * @description Function to get the latest report document version from a report
 * @param {object} report - a report with report documents object
 * @param {object[]} reportDocuments - optionally pass an array of report documents
 * @returns {*}
 */
export const getLatestReportDocumentVersion = ({ report, reportDocuments }) => {
  if (!report?.documents?.items && !reportDocuments) {
    EventLogger("Error! Invalid params for [getLatestReportDocumentVersion.js]");
  }

  const documentsSortedByVersion = getSortedByVersionReportDocuments({
    report,
    reportDocuments,
  });
  if (documentsSortedByVersion[0]) {
    return documentsSortedByVersion[0];
  }
};
