import React, { useEffect, useState } from "react";
import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import ButtonGroup from "@mui/material/ButtonGroup";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";

/**
 * Displays the isCompliant field of an AuditControlLink.
 * Can optionally show buttons to toggle the isCompliant field.
 * @param {function} [updateItemById] - used to update the parent grid
 * @param {object} item - the AuditControlLink item
 * @param {object[]} items - the AuditControlLink items (if in bulk update)
 * @param {object} item.auditControlLink - the AuditControlLink item
 * @param {boolean} isBulkEdit - whether or not this ui is used to edit items from bulk edit menu
 * @param {function} resetFunction - resets the parent grid
 * @param {function} optimisticUpdate - optimistic update function that will update the top level useAuditDetails state
 * @param props
 * @returns {*}
 */
const AuditControlIsCompliant = ({
  updateItemById,
  item,
  items,
  isBulkEdit,
  resetFunction,
  optimisticUpdate,
  ...props
}) => {
  const module = modules.COMPLIANCE;
  const resource = resources.AUDIT;
  const field = "isCompliant";

  const { showValidateButtons = true } = props;

  // Holds the local state of the buttons
  const [rSelected, setRSelected] = useState(item?.isCompliant);

  // Sets the local state when the parent changes
  useEffect(() => {
    if (!isNullOrUndefined(item?.auditControlLink)) {
      setRSelected(item?.auditControlLink?.isCompliant);
    } else {
      setRSelected(item?.isCompliant);
    }
  }, [item?.isCompliant, item?.auditControlLink, item?.auditControlLink?.isCompliant]);

  const { updateMutation: updateControl } = generateGraphql("AuditControlLink", ["isCompliant"]);

  const updateControlMutationHook = useMutation({
    mutation: updateControl,
    module,
    resource,
    field,
  });

  /**
   * Updates the Compliance value of this AuditControlLink.
   * Fires its own mutation if this function is not supplied
   * @param {bool|null} value - the value to set the isCompliant field to
   */
  const updateCompliance = async (value) => {
    //Find the items that need changing
    let itemsToMutate = items;
    if (!Array.isArray(itemsToMutate) && item) {
      itemsToMutate = [item];
    }

    // Update the items
    if (!isNullOrUndefined(itemsToMutate) && Array.isArray(itemsToMutate)) {
      const updateControlRequests = [];
      for (const item of itemsToMutate) {
        if (item?.auditControlLink?.id) {
          setRSelected(value);

          // Optimistically update the item
          if (typeof optimisticUpdate === "function") {
            // Run the optimistic update function to update the parent state
            if (optimisticUpdate) {
              optimisticUpdate({
                typename: "AuditControlLink",
                field: "isCompliant",
                value: value,
                id: item?.auditControlLink?.id,
                mutation: false,
              });
            }
          }

          const performMutation = async ({ item, value }) => {
            const mutationResult = await updateControlMutationHook.editItem({
              id: item?.auditControlLink?.id,
              isCompliant: value,
            });

            return {
              item,
              res: mutationResult,
            };
          };

          updateControlRequests.push(performMutation({ item, value }));
        }
      }

      // Wait for all the requests to finish
      const results = await Promise.allSettled(updateControlRequests);
      for (const result of results) {
        if (result.status === "rejected") {
          EventLogger("Failed to update an audit control compliance.");
          continue;
        }

        const { item, res } = result.value;

        // Run the updateItemById function to update the parent grid and ensure the grid data is correct
        if (typeof updateItemById === "function") {
          updateItemById(
            {
              ...res,
              auditControlLink: {
                ...item?.auditControlLink,
                isCompliant: res?.isCompliant,
              },
              id: item?.id, // this is the actual control ID which is the index for the grid
            },
            true,
          );
        }
      }

      // If this is part of a bulk edit operation, call a full reset function to ensure everything is updated
      if (isBulkEdit) {
        tryFunction(resetFunction);
      }
    }

    tryFunction(props.toggleModal);
  };

  // Disable buttons if disabled is passed, or if user doesn't have permission
  const isEnabled = !props.disabled && updateControlMutationHook.field.update;

  return (
    <>
      {isEnabled && showValidateButtons ? (
        <ButtonGroup
          id={"audit-control-compliance-buttons"}
          size="small"
          orientation={props.orientation || "horizontal"}
        >
          <Button
            className="btn-ghost-success"
            onClick={() => {
              updateCompliance(true);
            }}
            active={rSelected === "true" || rSelected === true}
            size="sm"
            disabled={!isEnabled}
            title={
              !isEnabled
                ? `You don't have permission to edit Field: isCompliant on Resource: 'Audit'`
                : `Change compliance to "true"`
            }
          >
            Yes
          </Button>
          <Button
            className="btn-ghost-danger"
            onClick={() => {
              updateCompliance(false);
            }}
            active={rSelected === "false" || rSelected === false}
            size="sm"
            disabled={!isEnabled}
            title={
              !isEnabled
                ? `You don't have permission to edit Field: isCompliant on Resource: 'Audit'`
                : `Change compliance to "false"`
            }
          >
            No
          </Button>
          <Button
            className="btn-ghost-secondary"
            onClick={() => {
              updateCompliance(null);
            }}
            active={rSelected === null}
            size="sm"
            disabled={!isEnabled}
            title={
              !isEnabled
                ? `You don't have permission to edit Field: isCompliant on Resource: 'Audit'`
                : `Change compliance to "N/A"`
            }
          >
            n/a
          </Button>
        </ButtonGroup>
      ) : item?.auditControlLink?.isCompliant === "true" || item?.auditControlLink?.isCompliant === true ? (
        <span style={{ color: "green", margin: "0 auto" }} title="This Control is fully Compliant">
          <i className="icon-check" style={{ marginRight: "0.3em" }} />
          Compliant
        </span>
      ) : item?.auditControlLink?.isCompliant === "false" || item?.auditControlLink?.isCompliant === false ? (
        <span style={{ color: "red", margin: "0 auto" }} title="This Control is not Compliant">
          <i className="icon-close" style={{ marginRight: "0.3em" }} />
          Not Compliant
        </span>
      ) : (
        <span style={{ color: "grey", margin: "0 auto" }} title="This Control is not applicable">
          <i className="icon-close" style={{ marginRight: "0.3em" }} />
          N/A
        </span>
      )}
    </>
  );
};

export default withOrganizationCheck(AuditControlIsCompliant);
