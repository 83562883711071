import { getDifferenceInDays } from "./getDifferenceInDays";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import cronParser from "cron-parser";

/**
 * @description Calculate evidence expiring soon date
 * @param evidence
 * @param evidenceExpirationThreshold
 * @returns {boolean|Date}
 */
export const evidenceExpiringSoonDate = ({ evidence, evidenceExpirationThreshold }) => {
  /**
   * Calculated expiring soon day based on the cron expression
   */
  let daysBeforeExpiring;

  try {
    /**
     * Calculate dueDate based on the cron expression
     */
    const interval = cronParser.parseExpression(evidence.frequency);
    /**
     * Calculate next date based on the cron expression
     */
    const nextDate = interval.next();

    /**
     * To calculate the number of days between two dates
     */
    const differenceInDays = getDifferenceInDays(evidence);

    if (differenceInDays <= 1) {
      EventLogger("Daily evidence, skipping..");
      // NOTE: EvidenceDueDate component depends on this function returning false vs undefined
      return false;
    } else {
      daysBeforeExpiring = Math.ceil(differenceInDays * (evidenceExpirationThreshold || 0.1));
    }

    /**
     * Subtract number of days before Evidence will be expiring soon
     */
    nextDate.setDate(nextDate.getDate() - daysBeforeExpiring);

    return new Date(nextDate.toString());
  } catch (e) {
    EventLogger("Error! Can not calculate Evidence ExpiringSoon Date");
  }
};
