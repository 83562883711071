import React from "react";

const ActionCalendar = React.lazy(() => import("../../views/Program/Actions/components/ActionCalendar"));
const VCISODashboard = React.lazy(() => import("../../views/ActionCenter/ActionCenter"));

// Action Center Routes
export const actionCenterRoutes = [
  {
    path: "/action_center",
    exact: true,
    name: "Action Center",
    component: VCISODashboard,
  },
  {
    path: "/action_dashboard",
    exact: true,
    name: "Action Center",
    component: VCISODashboard,
  },
  {
    path: "/action_center/calendar",
    exact: true,
    name: "Action Calendar",
    component: ActionCalendar,
  },
];
