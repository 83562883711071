import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const isMobile = width < 992;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const getCurrentBreakpoint = () => {
    if (width < 600) return "xs";
    if (width < 960) return "sm";
    if (width < 1280) return "md";
    if (width < 1920) return "lg";
    return "xl";
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    // Unmount
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    width,
    height,
    isMobile,
    getCurrentBreakpoint,
  };
};
