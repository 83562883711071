import React, { useEffect, useState } from "react";
import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemQuery } from "../../Functions/Graphql/ItemQuery";
import Tag from "./Tag";
import { Button, Row } from "reactstrap";
import { ListQuery } from "../../Functions/Graphql/ListQuery";
import NotEnoughData from "../../GenericComponents/NotEnoughData";
import Loader from "../../LoadingComponents/Loader";
import { withOrganizationCheck } from "../../Context/withOrganizationCheck";

/**
 * Allows for a quick way to add tags to an item
 * @param {object} item - the item to which to add tags
 * @param {string} typename - the string name of the items schema type
 * @param {function} updateCallback - called with the latest selected tag information on successful addition in the back end
 * @param {function} createCallback - called when a new tag has been created from within the quick pick tag UI
 * @param {object} itemTags - tags that are already on the item needs to have object.tags.items.tag.id structure
 * @param {object[]} organizationTags - custom tag pool from which the item tags can be selected
 * @param {function} createLinkFunction - because tags attached to resources are many to many links,
 * need to provide a custom function to attach tags with this UI. This function will be given 3 positional parameters -
 * (item, tag, organizationID)
 * @param {function} [getItemTagsQueryFunction] - optional override for the query function for getting the tags for the item
 */
const QuickPickTag = ({
  item,
  typename,
  itemTags,
  updateCallback,
  createCallback,
  organizationID,
  createLinkFunction,
  getItemTagsQueryFunction,
}) => {
  const [organizationTags, setOrganizationTags] = useState(null);
  const [availableTags, setAvailableTags] = useState(null);

  const listOrganizationTagsQuery = generateGraphql("Tag", [
    "name",
    "description",
    "fontColor",
    "backgroundColor",
    "systemTagID",
  ]).listQuery;

  const getItemTagsQuery = generateGraphql(typename, ["tags"], {
    tags: `(limit: 100) {items { tag { id }}}`,
  }).getQuery;

  const retrieveRemainingTags = async () => {
    let tagsList = [];

    if (item?.id && organizationID) {
      if (getItemTagsQueryFunction) {
        itemTags = await getItemTagsQueryFunction(item);
      } else if (!itemTags && getItemTagsQuery && item?.id) {
        itemTags = await ItemQuery(getItemTagsQuery, item?.id);
      }

      tagsList =
        organizationTags ||
        (await ListQuery({
          query: listOrganizationTagsQuery,
          organizationID: organizationID,
        }));

      tagsList = tagsList.filter((tag) => {
        return !tag.systemTagID;
      });

      setOrganizationTags([...tagsList]);

      //removing all present tags from the available list
      if (tagsList && Array.isArray(tagsList) && itemTags?.tags?.items && Array.isArray(itemTags.tags.items)) {
        for (const tagLink of itemTags.tags.items) {
          const foundIndex = tagsList.findIndex((item) => {
            return item.id && tagLink?.tag?.id && item.id === tagLink.tag.id;
          });
          if (foundIndex !== null && foundIndex !== undefined && foundIndex !== -1) {
            tagsList.splice(foundIndex, 1);
          }
        }
      }
    }

    setAvailableTags(tagsList);
  };

  //Determining all available tags on initial render
  useEffect(() => {
    retrieveRemainingTags();
  }, []);

  const addTagToItem = async (index) => {
    if (availableTags.length > index)
      if (item?.id && organizationID && createLinkFunction) {
        const itemTagLink = await createLinkFunction(item, availableTags[index], organizationID);
        if (updateCallback && itemTagLink?.id) {
          updateCallback(
            {
              ...availableTags[index],
              tagLinkID: itemTagLink?.id,
              tagLinkTypename: itemTagLink?.__typename,
            },
            availableTags.length === 1,
          );
        }
      }

    //Remove tag from available list
    const tempTags = [...availableTags];
    tempTags.splice(index, 1);
    setAvailableTags(tempTags);
  };

  return (
    <div style={{ width: "250px" }}>
      {availableTags && Array.isArray(availableTags) && availableTags.length !== 0 ? (
        availableTags.map((tag, index) => {
          return (
            <Row
              key={tag?.id ? tag.id : index}
              style={{
                marginBottom: index !== availableTags.length - 1 ? ".5em" : "0em",
              }}
            >
              <Button
                style={{ marginLeft: "1em", marginRight: "1em" }}
                size="sm"
                color="success"
                className="btn-pill"
                onClick={() => addTagToItem(index)}
                title="Add tag to this item"
              >
                <i className="icon-plus" />
              </Button>
              <Tag isSelected={true} tag={tag} />
              <div style={{ marginLeft: "1em" }} />
            </Row>
          );
        })
      ) : organizationTags && Array.isArray(organizationTags) && organizationTags.length === 0 ? (
        <NotEnoughData
          message={"Your organization doesn't have any tags."}
          callToAction={{
            message: " to make the first tag!",
            size: "sm",
            placeOnNewLine: true,
            function: () => {
              createCallback && createCallback();
            },
          }}
        />
      ) : availableTags && Array.isArray(availableTags) && availableTags.length === 0 ? (
        <NotEnoughData
          message={"All tags are already assigned."}
          callToAction={{
            message: " to make more tags!",
            size: "sm",
            placeOnNewLine: true,
            function: () => {
              createCallback && createCallback();
            },
          }}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default withOrganizationCheck(QuickPickTag);
