import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Drawer, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TaskCardBody from "../../TaskSidebar/components/TaskCardBody";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import TaskList from "../../TaskSidebar/components/TaskList";
import { convertCamelCaseToSentence, isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";
import { getIcon } from "../../../../../../utils/Functions/Icon/getIcon";
import Dashboard from "../../../../../../utils/GenericComponents/Dashboard";
import GenericSnackbar from "../../TaskSidebar/components/GenericSnackbar";
import NotEnoughData from "../../../../../../utils/GenericComponents/NotEnoughData";
import { useDepartmentInfo } from "../../TaskSidebar/hooks/useDepartmentInfo";
import { useGetTasks } from "../../TaskSidebar/hooks/useGetTasks";
import { useCustomTaskLists } from "../../TaskSidebar/hooks/useCustomTaskLists";
import { useTaskModals } from "../../TaskSidebar/hooks/useTaskModals";
import { useUpdateTask } from "../../TaskSidebar/hooks/useUpdateTask";
import { getCurrentDate } from "../functions/getCurrentDate";
import { taskCategory } from "@views/Program/Tasks/components/TaskSidebar/constants/taskCategory";

/**
 * The component that handles rendering the tasks management UI
 * @param {boolean} isHeaderInfoProvided - whether the header info is provided (to avoid duplicate headers within Action Center)
 * @returns {JSX.Element}
 */
const TaskManagementUI = ({ isHeaderInfoProvided }) => {
  const [selectedTaskID, setSelectedTaskID] = useState(null);

  const context = useContext(OrganizationContext);
  const { isAdmin } = context;
  const { departmentID, departmentHeadID } = useDepartmentInfo();
  const { tasks, setTasks, setCompletedTasks, currentList, setCurrentList, selectedMenu, setSelectedMenu, isLoading } =
    useGetTasks({
      initialSelectedMenu: taskCategory.MY_TASKS,
      isSidebarView: false,
    });
  const { customLists, setCustomLists, pointOfContact, taskListActionModal, createTaskListModal } = useCustomTaskLists({
    currentList,
    setCurrentList,
    isSidebarView: false,
    setSelectedMenu,
  });
  const { setCreateModalOpen, createModal, setTaskModalIsOpen, detailModal } = useTaskModals({
    selectedMenu,
    currentList,
    departmentID,
    selectedTaskID,
    setTasks,
    setCompletedTasks,
    setCurrentList,
    setCustomLists,
  });

  const { handleCheckboxClick, handleUndo, revertTimeout, setRevertTimeout } = useUpdateTask({
    setTasks,
    setCompletedTasks,
  });
  const userOriginalOrganization = pointOfContact?.ownerGroup;
  const userCurrentOrganization = context?.selectedOrganization;
  const isInOperationTeamGroup = context?.isInOperationTeamGroup;
  const filteredOperationTeamGroupList = customLists?.filter((list) => {
    return list?.pointOfContactID === null;
  });
  const filteredAdminList = customLists?.filter((list) => {
    return list?.pointOfContactID === context?.loggedInPointOfContactId || list?.pointOfContactID === null;
  });

  useEffect(() => {
    if (
      userOriginalOrganization !== userCurrentOrganization &&
      isAdmin &&
      filteredAdminList &&
      isNonEmptyArray(filteredAdminList)
    ) {
      setSelectedMenu(filteredAdminList[0]?.name);
      setCurrentList(filteredAdminList[0]);
    } else if (
      isInOperationTeamGroup &&
      filteredOperationTeamGroupList &&
      isNonEmptyArray(filteredOperationTeamGroupList)
    ) {
      setSelectedMenu(filteredOperationTeamGroupList[0]?.name);
      setCurrentList(filteredOperationTeamGroupList[0]);
    } else {
      setSelectedMenu(null);
    }
  }, [customLists, pointOfContact, context?.selectedOrganization]);

  return (
    <Dashboard
      style={{ width: "100%" }}
      title={!isHeaderInfoProvided ? "Tasks" : null}
      badge={!isHeaderInfoProvided ? getIcon("simple-line-icons:check") : null}
    >
      <Container
        style={{
          marginTop: 20,
          paddingLeft: 0,
          height: "100vh",
          margin: "1rem 0",
        }}
      >
        <Box display="flex" flexDirection="row" height="100%">
          <Drawer
            variant="permanent"
            anchor="left"
            sx={{
              width: 300,
              flexShrink: 0,
              height: "100%",
              zIndex: 1,
              "& .MuiDrawer-paper": {
                width: 300,
                boxSizing: "border-box",
                position: "relative", // ensures it stays within the Container
                height: "100%",
              },
            }}
          >
            <TaskCardBody
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              setTasks={setTasks}
              setCompletedTasks={setCompletedTasks}
              setCurrentList={setCurrentList}
              currentList={currentList}
              setCreateModalOpen={setCreateModalOpen}
              departmentHeadID={departmentHeadID}
              isSidebarView={false}
              customLists={customLists}
              setCustomLists={setCustomLists}
              pointOfContact={pointOfContact}
              taskListActionModal={taskListActionModal}
              createTaskListModal={createTaskListModal}
            />
          </Drawer>
          {(userOriginalOrganization !== userCurrentOrganization || context?.isInOperationTeamGroup) &&
          (isNullOrUndefined(currentList) || isNullOrUndefined(selectedMenu)) ? (
            <Box flexGrow={1} ml={3}>
              <NotEnoughData message={"To start adding tasks, please create or select a task list."} />
            </Box>
          ) : (
            <Box flexGrow={1} ml={3} style={{ overflow: "auto" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TaskAltIcon fontSize="large" style={{ marginRight: ".8rem" }} />
                <Typography variant="h5">
                  {selectedMenu === currentList?.name ? currentList?.name : convertCamelCaseToSentence(selectedMenu)}
                </Typography>
              </div>
              <Typography variant="body1" color="grey" gutterBottom>
                {getCurrentDate()}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: ".5rem 0",
                }}
              >
                <Button onClick={() => setCreateModalOpen(true)} startIcon={<AddIcon />}>
                  Add a Task
                </Button>
              </div>
              <TaskList
                tasks={tasks}
                setTasks={setTasks}
                isLoading={isLoading}
                handleCheckboxClick={handleCheckboxClick}
                setSelectedTaskID={setSelectedTaskID}
                setTaskModalIsOpen={setTaskModalIsOpen}
                selectedTaskID={selectedTaskID}
                isSidebarView={false}
                context={context}
              />
            </Box>
          )}
        </Box>
        {createModal}
      </Container>
      {selectedTaskID && detailModal}
      {revertTimeout && (
        <GenericSnackbar
          open={!!revertTimeout}
          onClose={() => {
            setRevertTimeout(null);
          }}
          message={"Task status updated"}
          buttonText={"Undo"}
          buttonAction={handleUndo}
          position={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
    </Dashboard>
  );
};

export default TaskManagementUI;
