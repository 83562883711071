import Data from "../customFields/Data";
import DateField from "../../../../utils/CustomFields/DateField";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import MetricForm from "../../MetricForm/components/MetricForm";
import React, { useEffect } from "react";
import MetricDetails from "../components/MetricDetails";
import { deleteMetric } from "../functions/deleteMetric";

/**
 *
 * @param item - the MetricType to display Metrics for.
 * @param item.id - the ID of the MetricType to display Metrics for
 * @param organizationID
 */
export const useMetricDataGrid = ({ item, organizationID }) => {
  const fields = [
    {
      field: "data",
      component: <Data />,
      flex: 1,
      minWidth: 150,
      format: "dollar",
    },
    {
      field: "date",
      component: <DateField trimTime={true} fieldName={"date"} />,
      flex: 1,
      minWidth: 150,
      format: "date",
      sort: {
        direction: "desc",
        priority: 1,
      },
    },
  ];

  const metricsByMetricType = /* GraphQL */ `
    query MetricsByMetricType(
      $metricTypeID: ID
      $sortDirection: ModelSortDirection
      $filter: ModelMetricFilterInput
      $limit: Int
      $nextToken: String
    ) {
      metricsByMetricType(
        metricTypeID: $metricTypeID
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          ownerGroup
          name
          description
          level
          date
          data {
            value
            format
          }
          metricTypeID
        }
        nextToken
      }
    }
  `;

  const dataGrid = useDataGrid({
    options: ["delete"],
    organizationID,
    query: metricsByMetricType,
    variables: {
      metricTypeID: item?.id,
    },
    fields,
    disableRoleChecking: true,
    createResourceComponent: <MetricForm metricType={item} organizationID={organizationID} />,
    detailsComponent: <MetricDetails metricType={item} organizationID={organizationID} />,
    deleteFunction: deleteMetric,
  });

  // refresh the data grid when the metricType.id changes
  useEffect(() => {
    if (item?.id) {
      dataGrid.resetFunction();
    }
  }, [item?.id]);

  return dataGrid;
};
