/**
 * Handles pagination configuration for the grid.
 *
 * Note: Pagination is currently disabled for the grid in lieu of full virtualization
 *
 * @returns {{initialState: {pagination: {pageSize: number}}, rowsPerPageOptions: number[]}}
 */
export const useDataGridPagination = () => {
  const rowsPerPageOptions = [10, 25, 50, 100, 250, 500];

  const initialState = { pagination: { pageSize: 10 } };

  return {
    rowsPerPageOptions,
    initialState,
  };
};
