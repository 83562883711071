import { isNullOrUndefined, parseTextFromHtml } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";

import { Button } from "reactstrap";
import { EventLogger } from "../../EventLogger/EventLogger";
import ObservationDetails from "../../../views/Program/Observations/components/ObservationDetails";
import React from "react";
import { getIcon } from "../../Functions/Icon/getIcon";
import { useCheckPermissions } from "../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useCreateObservation } from "../../../views/Program/Observations/hooks/useCreateObservation";
import { useHamburgerMenu } from "../../../hooks/views/useHamburgerMenu/useHamburgerMenu";
import { useModal } from "../../../hooks/views/useModal";
import { useTemplateContext } from "../../Context/TemplateContext";

/**
 * @description Customized hamburger menu hook to display options for Notes
 * @param {function} updateNote - function handler for editing a note
 * @param {object} note - the note object itself
 * @param {function} deleteNote - function handler for deleting a note
 * @param {object} useRichEditor - reference to the useRichTextEditor hook for editing notes
 * @param {function} setIsEditNote - function handler to setting the note to the 'Edit' state
 * @param {object} checkPermissionsHook - permissions check for Editing and Deleting Notes
 * @param {boolean} disableRoleChecking - optional flag for disabling role checking
 * @param {string} organizationID - used as the ownerGroup when creating new observations
 * @param {boolean} observationWasDeleted - flag for if the observation was deleted
 * @param observationConnectionField
 * @param {boolean} setObservationWasDeleted - function handler for setting the observationWasDeleted flag
 * @param {string} module - the module the note is from
 * @param {JSXElement} observationFormOverride - optional JSX element to override the Observation form
 * @param {object} item - the item the note is from
 * @param {function} onCreateObservation - callback function when creating an observation from a note
 * @param {string} auditID - for explicitly connecting Observations to an Audit
 * @param {string} evidenceID - for explicitly connecting Observations to an Evidence
 * @param {string} exerciseID - for explicitly connecting Observations to an Exercise
 * @param {string} complianceControlID - for explicitly connecting Observations to a Compliance Control
 * @param {string} riskControlID - for explicitly connecting Observations to a Risk Control
 * @param {string} vulnerabilityID - for explicitly connecting Observations to a Vulnerability
 * @param {string} incidentID - for explicitly connecting Observations to an Incident
 * @param {string} artifactID - for explicitly connecting Observations to an Artifact
 * @param {string} meetingID - for explicitly connecting Observations to a Meeting
 * @param {boolean} disableEdits - if true will not allow to edit/delete note
 */
export const useNotesHamburgerMenu = ({
  updateNote,
  note,
  deleteNote,
  useRichEditor,
  setIsEditNote,
  checkPermissionsHook,
  disableRoleChecking,
  organizationID,
  module,
  observationConnectionField,
  observationWasDeleted,
  setObservationWasDeleted,
  onCreateObservation,
  item,
  disableEdits = false,
  auditID,
  evidenceID,
  exerciseID,
  complianceControlID,
  riskControlID,
  vulnerabilityID,
  incidentID,
  artifactID,
  meetingID,
  observationFormOverride,
}) => {
  /**
   * Updates the Note 'observationID' field to create a soft connection to the newly created Observation
   * @param {string} id - the ID of the observation to add to the Note
   */
  const updateNoteObservationIDField = (id) => {
    if (typeof updateNote === "function") {
      updateNote({
        ...note,
        observationID: id,
      });
    }
  };

  // Check if user has permissions to create Observations
  const observationPermissionCheck = useCheckPermissions({
    module: modules.GOVERNANCE,
    resource: resources.OBSERVATION,
    disableRoleChecking,
  });

  // When the observation gets created, grab the ID and save it back to the Note
  const callback = (item) => {
    const observationID = item?.id;
    if (!observationID) {
      return;
    }
    setObservationWasDeleted(false);
    updateNoteObservationIDField(observationID);
    closeModal();
    onCreateObservation && onCreateObservation(item);
  };

  // Observation form, Observation Name is pre-filled with the Note content
  const observationForm = useCreateObservation({
    organizationID,
    callback: callback,
    module,
  });

  // Disable Convert to Observation button if resource is a Template
  const { isTemplate } = useTemplateContext();

  //Disabling the convert to observation button if user has already converted it, or they don't have permission
  const disableConvertToObservationButton =
    note?.observationID || !observationPermissionCheck?.resource?.create || isTemplate;

  const form = observationFormOverride
    ? React.cloneElement(observationFormOverride, {
        observationName: parseTextFromHtml(note?.content ? note?.content : ""),
        callback: callback,
      })
    : observationForm.display;

  const modal = useModal("Create an Observation", form, null, {
    width: "80vw",
  });

  const closeModal = () => {
    modal.setModalIsOpen(false);
  };

  // Hamburger menu configuration items
  const items = [
    {
      label: "Edit",
      icon: getIcon("bytesize:edit"),
      onClick: () => {
        useRichEditor.setValue(note?.content || undefined);
        setIsEditNote(true);
      },
      title: "Edit this Note",
      disabled: !checkPermissionsHook?.resource?.update || disableEdits,
    },
    {
      label: "Convert to an Observation",
      icon: getIcon("bi:eye"),
      onClick: () => {
        modal.setModalIsOpen(true);

        if (!organizationID) {
          EventLogger(
            "[useNotesHamburgerMenu]",
            new Error("OrganizationID not available when creating an observation form a note."),
          );
          return;
        }

        const input = {
          name: parseTextFromHtml(note?.content ? note?.content : ""),
          module,
          ownerGroup: organizationID,
          isFinding: false,
          risk: "info",
          auditID,
          evidenceID,
          exerciseID,
          complianceControlID,
          riskControlID,
          vulnerabilityID,
          incidentID,
          artifactID,
          meetingID,
        };
        const observationConnectionIDs = [
          auditID,
          evidenceID,
          exerciseID,
          complianceControlID,
          riskControlID,
          vulnerabilityID,
          incidentID,
          artifactID,
          meetingID,
        ];

        const foundConnectionID = observationConnectionIDs.find((id) => !isNullOrUndefined(id));

        // First check if the connection ID was passed in, if so, use that to connect the Observation.
        if (foundConnectionID) {
          input[observationConnectionField] = foundConnectionID;
        } else if (
          // Otherwise, if observationConnectionField is passed in, connect new Observation to the parent
          !isNullOrUndefined(observationConnectionField) &&
          !isNullOrUndefined(item?.id)
        ) {
          input[observationConnectionField] = item?.id;
        }
        observationForm.setInput(input);
      },
      disabled: !observationConnectionField || (!observationWasDeleted && disableConvertToObservationButton),
    },
    {
      label: "Delete",
      title: "Delete this Note",
      icon: <i className={"icon-trash"} />,
      onClick: () => {
        if (window.confirm("Are you sure you want to delete this note?")) {
          deleteNote && deleteNote(note);
        }
      },
      disabled: !checkPermissionsHook?.resource?.update || disableEdits,
    },
  ];

  // The dropdown menu that displays the Edit, Delete, and Convert buttons
  const hamburgerMenu = useHamburgerMenu({
    items,
    title: "Note Options: Edit, Delete, or Convert this Note to an Observation",
  });

  // Modal that displays Observation Details if attached to a Note
  const observationDetailsModal = useModal(
    "Observation Details",
    <ObservationDetails item={{ id: note?.observationID }} organizationID={organizationID} />,
    <Button
      title={"View Converted Note"}
      className={"float-right"}
      style={{
        color: "muted-grey",
        position: "static",
        right: 0,
        backgroundColor: "Transparent",
        backgroundRepeat: "no-repeat",
        outline: "none",
        bottom: 0,
        border: "none",
        textDecoration: "underline",
        fontSize: "x-small",
        marginRight: 0,
        marginBottom: 0,
        padding: 0,
      }}
    >
      {" "}
      Observation{" "}
    </Button>,
    { width: "80vw" },
  );

  const display = (
    <div>
      {modal.modal}
      {observationDetailsModal.modal}
      {hamburgerMenu.display}
    </div>
  );

  return {
    ...hamburgerMenu,
    observationDetailsModal,
    display,
  };
};
