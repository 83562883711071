import React, { useContext, useEffect, useState } from "react";

import { EventLogger } from "../../utils/EventLogger/EventLogger";
import { ListQueryBy } from "../../utils/Functions/Graphql/ListQueryBy";
import { LoadingSpinner } from "../../utils/LoadingComponents/LoadingSpinner";
import { OrganizationContext } from "../../utils/Context/OrganizationContext";
import { fetchData } from "../../views/Tools/Tool/functions/fetchData";
import { handleIcon } from "../../utils/Functions/Icon/getIcon";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { useGUID } from "../functional/useGUID";
import { useGrid } from "./useGrid/hooks/useGrid";
import { accessResourcesByRole } from "./useDataGrid/functions/accessResourcesByRole";

/**
 * Hook utilizing a useGrid inside a customizable card that also does queries of the database data
 * @param cardConfig
 * @param gridConfig
 * @param queryConfig
 * @param roleConfig
 * @return {object} {{data: *[], setData: (value: (((prevState: *[]) => *[]) | *[])) => void, display: JSX.Element}}
 */
export const useGridCard = ({ cardConfig, gridConfig, queryConfig, roleConfig }) => {
  const {
    query,
    queryExternalApi,
    variables,
    filters,
    limit,
    normalizeData,
    normalizeRowData,
    queryCallback,
    organizationID,
    resetFunction,
  } = queryConfig;

  const { title, headerIcon, showHeader = true, headerButtons } = cardConfig;

  const context = useContext(OrganizationContext);

  const [data, setData] = useState([]);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) setTimeout(() => setError(null), 5000);
  }, [error]);

  const [nextToken, setNextToken] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Get data function gets items from the database
   */
  const getData = () => {
    if (query) {
      setData([]);
      ListQueryBy({
        query,
        variables,
        nextToken,
        setNextToken,
        filter: organizationID
          ? { ownerGroup: { eq: organizationID }, ...filters }
          : filters
            ? { ...filters }
            : undefined,
        limit,
        normalizeData,
        normalizeRowData,
        getDataByNextToken: (items) => {
          /**
           * Filter items by role resources
           */
          const filteredArray = accessResourcesByRole({
            array: items,
            resource: roleConfig?.resource,
            role: context?.role,
          });
          setData((data) => data.concat(filteredArray));
        },
        setIsLoading,
      })
        .catch((err) => {
          EventLogger(err);
          setError(err);
        })
        .finally(() => setIsLoading(false));
    } else if (queryExternalApi) {
      /**
       * Used to fetch data through fetchData lambda
       */
      if (queryExternalApi?.toolType && queryExternalApi?.input) {
        setIsLoading(true);

        fetchData({
          toolType: queryExternalApi?.toolType,
          input: queryExternalApi?.input,
          organizationID,
        })
          .then((res) => {
            if (res?.statusCode === 400) {
              setError("Error occurred, please try again or submit a bug report.");
            } else {
              const d = [];
              for (const item of res) {
                if (!isNullOrUndefined(queryExternalApi?.idField)) {
                  item.id = item[queryExternalApi.idField];
                }
                d.push(item);
              }

              if (normalizeData) {
                setData(normalizeData(res));
              } else {
                setData(res);
              }
            }
          })
          .catch((err) => {
            EventLogger(err);
            setError(err);
          })
          .finally(() => setIsLoading(false));
      } else {
        EventLogger("Missing toolType and input for queryApi");
      }
    } else {
      resetFunction && resetFunction();
    }
  };

  //Rerun query on new filters
  useEffect(() => {
    getData();
  }, []);

  //If provided initiate data callback on new data retrieval
  useEffect(() => {
    queryCallback && queryCallback(data);
  }, [data]);

  const grid = useGrid({
    data,
    organizationID,
    ...gridConfig,
    ...roleConfig,
    resetFunction: getData,
    isLoading,
    setIsLoading,
    error,
    setError,
  });

  const [guid] = useGUID();

  const display = (
    <div className="e-card" style={{ ...cardConfig?.style?.cardContainer }}>
      {showHeader && (
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-title">
              <h5 className="dashboard-title">
                {isLoading ? (
                  <LoadingSpinner style={{ marginRight: "0.5em" }} />
                ) : (
                  headerIcon && <span style={{ marginRight: "0.75em" }}>{handleIcon(headerIcon)}</span>
                )}
                {title}
              </h5>
            </div>
          </div>
          {headerButtons && headerButtons.map((button, index) => React.cloneElement(button, { key: guid + index }))}
        </div>
      )}
      <div className="e-card-content" style={{ height: "100%" }}>
        {grid.display}
      </div>
    </div>
  );

  return {
    data,
    setData,
    isLoading,
    setIsLoading,
    setError,
    error,
    ...grid,
    gridDisplay: grid.display,
    display: gridConfig?.gridDisplay === true ? grid.display : display,
    resetFunction: getData,
  };
};
