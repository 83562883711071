import React, { useContext, useState } from "react";
import ReviewPolicyCheckbox from "./ReviewPolicyCheckbox";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { generateGraphql } from "@rivial-security/generategraphql";
import { withDetailsPage } from "../../../../utils/Context/withDetailsPage";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import FileViewer from "../../../../utils/FilePreviews/FileViewer";
import Loader from "../../../../utils/LoadingComponents/Loader";
import { getSignedObjectUrlV2 } from "../../../../utils/Functions/S3Storage/getSignedObjectUrlV2";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { AV_STATUS } from "../../../../enums/AV_STATUS";
import MuiItemDownloadButton from "../../../../utils/GenericComponents/buttons/MuiItemDownloadButton";
import { useSetAsyncData } from "../../../../hooks/functional/useSetAsyncData";
import { GetQuery } from "@rivial-security/appsync-utils";

/**
 * Landing page for viewing a Policy Version document and marking it as 'Reviewed'
 *
 * @param {string} itemId - the ID of the policy version to fetch and review
 * @param {string} organizationID - the ID of the organization to review the policy for
 */
const ReviewPolicy = ({ itemId, organizationID }) => {
  const context = useContext(OrganizationContext);

  const [policyVersion, setPolicyVersion] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  const isInfected = policyVersion?.avStatus === AV_STATUS.INFECTED;

  const { isLoading: isLoadingVersionReviewers } = useSetAsyncData({
    getData: async () => {
      const reviewers = policyVersion?.reviewers?.items || [];
      if (!reviewers) {
        return;
      }

      const contact = reviewers.find((reviewer) => reviewer?.pointOfContact?.id === context.loggedInPointOfContactId);

      if (contact !== null && contact !== undefined) {
        EventLogger(`Found Match: ${JSON.stringify(contact)}`);
        setError(null);
        setReviewer(contact);
      } else {
        EventLogger("Error: No Match Found");
        setError(
          "You are not listed as a reviewer for this policy. Please contact your manager or a Rivial Administrator for help.",
        );
      }
    },
    dependencies: [policyVersion?.id],
  });

  const { isLoading: isLoadingVersionFile } = useSetAsyncData({
    getData: async () => {
      const policyVersionFile = policyVersion?.file;
      const { key, bucket } = policyVersionFile || {};

      if (isInfected) {
        setError("This file is infected with a virus and cannot be viewed.");
        return;
      }

      if (key && bucket) {
        try {
          const newURL = await getSignedObjectUrlV2({
            bucket,
            key,
            organizationID: organizationID || policyVersion.ownerGroup,
          });
          setUrl(newURL);
        } catch (e) {
          setError("Could not load file");
        }
      }
    },
    dependencies: [policyVersion?.id],
  });

  const { isLoading: isLoadingVersionData } = useSetAsyncData({
    getData: async () => {
      return await GetQuery({
        query: getPolicyVersion,
        variables: {
          id: itemId,
        },
      });
    },
    setData: setPolicyVersion,
    dependencies: [itemId],
  });

  return (
    <Dashboard title={"Review Policy"} icon={"icon-doc"} subTitle={policyVersion?.policy?.name}>
      <DataLoader
        style={{ flex: 1, height: "100%" }}
        isEnoughData={policyVersion}
        isLoading={isLoadingVersionData || isLoadingVersionFile || isLoadingVersionReviewers}
      >
        {error || (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Top row shows the checkbox and download button */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>
                <ReviewPolicyCheckbox reviewer={reviewer} />
              </div>
              <MuiItemDownloadButton item={policyVersion} organizationID={organizationID} size={"large"} />
            </div>

            {/* Bottom row shows the file viewer for the Policy Version */}
            <div style={{ flex: 1 }}>
              {url ? (
                <FileViewer url={url} viewerHeight={"99%"} />
              ) : (
                <div style={{ margin: "auto", textAlign: "center" }}>
                  <Loader style={{ width: "10em", height: "10em" }} size="sm" color="primary" />
                </div>
              )}
            </div>
          </div>
        )}
      </DataLoader>
    </Dashboard>
  );
};

export default withDetailsPage(ReviewPolicy);

const { getQuery: getPolicyVersion } = generateGraphql(
  "PolicyVersion",
  ["reviewers", "file", "policy", "avStatus", "createdAt", "lastAVCheck"],
  {
    file: `{ key bucket }`,
    reviewers: `(limit: 500) { items { id status reviewedDate pointOfContact { id firstName lastName } } }`,
    policy: `{ id name }`,
  },
);
