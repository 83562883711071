import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { getOrganization_minimal } from "../../../AdminPanel/Organizations/graphql/__organizationGQL";
import { zipAndDownloadFiles } from "../../../../utils/Functions/S3Storage/zipAndDownloadFiles";
import { useForm } from "../../../../hooks/views/useForm";
import { useModal } from "../../../../hooks/views/useModal";
import React, { useEffect, useState } from "react";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import Loader from "../../../../utils/LoadingComponents/Loader";
import { Link, Button, Alert } from "@mui/material";
import { getSignedObjectUrlV2 } from "../../../../utils/Functions/S3Storage/getSignedObjectUrlV2";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { useUIContext } from "@utils/Context/UIContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

/**
 * @description Helper hook for generating a zip file with documents
 * @param {object[]} items - documents
 * @param {string} groupBy - field to group by
 * @param {string} organizationID - selected organization
 * @param {boolean} disableDownloadButton - disable download button or use it to reflect loading state of the parent component
 * @param {string} defaultArchiveName - default name of the archive
 * @returns {object} {{setModalIsOpen: function(*=): void, modalButton: JSX.Element, modalIsOpen: boolean | undefined, modal: JSX.Element}}
 */
export const useExportZip = ({ items, groupBy, organizationID, disableDownloadButton, defaultArchiveName = "" }) => {
  const { addToast, updateToast } = useUIContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [zipFile, setZipFile] = useState(null);
  const [isHelpClicked, setIsHelpClicked] = useState(false);

  const initState = () => {
    setIsLoading(false);
    setError(null);
    setZipFile(null);
    setIsHelpClicked(false);
  };

  const submitFunction = async (input) => {
    /**
     * Reset state
     */
    initState();

    setIsLoading(true);

    /**
     * Get org bucket name
     */
    const organization = await QueryGetItem({
      query: getOrganization_minimal,
      itemId: organizationID,
    });

    /**
     * Send request to the lambda
     */
    try {
      await zipAndDownloadFiles({
        zipFileName: input?.zipFileName,
        fileStructure: items,
        bucket: organization?.s3BucketName,
        folder: "evidence/",
        organizationID,
      }).then(async (res) => {
        if (res?.statusCode === 200) {
          if (res?.body && res?.body?.Bucket && res?.body?.Key) {
            setZipFile({
              bucket: res?.body?.Bucket,
              key: res?.body?.Key,
            });
            setIsLoading(false);
          } else {
            setError("Cannot generate Zip file");
          }
        } else {
          setError("Cannot generate Zip file");
        }
      });
    } catch (e) {
      EventLogger("Error: Cannot generate Zip file");
      setError("Cannot generate Zip file");
    } finally {
      setIsLoading(false);
    }
  };

  const zipForm = useForm({
    fieldConfig: {
      zipFileName: {
        label: "Archive File Name:",
        inputType: "text",
        defaultValue: defaultArchiveName,
        validationFunction: (input) => input && !input.match(/\/$/),
        tooltip: `Archive File Name cannot contain forward slashes`,
      },
    },
    disableRoleChecking: true,
    submitFunction,
  });

  useEffect(() => {
    if (groupBy && zipForm) {
      zipForm?.setInput({
        zipFileName: defaultArchiveName,
      });
    }
  }, [groupBy]);

  const zipModal = useModal(
    "Generate a Zip File",
    <div>
      {error && <Alert color="error">{error}</Alert>}
      {!zipFile && !isLoading && zipForm.display}
      {isLoading && (
        <div style={{ margin: "auto", textAlign: "center" }}>
          <Loader style={{ width: "5em", height: "5em" }} size="sm" color="primary" />
        </div>
      )}
      {zipFile && (
        <div
          style={{
            margin: "auto",
            marginTop: "1em",
            marginBottom: "1em",
            textAlign: "center",
          }}
        >
          <h5> Your document archive is ready to download:</h5>
          <h5 style={{ alignText: "center" }}>
            <Link
              component={"button"}
              onClick={() =>
                performToastOperation({
                  addToast,
                  updateToast,
                  operation: async () => {
                    await getSignedObjectUrlV2({
                      bucket: zipFile?.bucket,
                      key: zipFile?.key,
                      organizationID,
                    }).then((data) => {
                      window.open(data, "_blank");
                      setZipFile(null);
                      zipModal?.setModalIsOpen(false);
                    });
                  },
                  inProgressText: "Downloading zip file...",
                  failedText: "Failed to download zip file.",
                  successText: "Success! Downloading zip file...",
                  iconColor: "success",
                })
              }
            >
              Download Zip File
            </Link>
          </h5>
          <br />
          <p>
            Having issues extracting the zip archive? Click{" "}
            <Link component={"button"} onClick={() => setIsHelpClicked(true)}>
              here
            </Link>
          </p>
          {isHelpClicked && (
            <Alert severity="info" sx={{ textAlign: "left" }} icon={false}>
              To ensure optimal file path length compatibility on Windows operating systems, it is recommended to
              extract the contents of the provided zip archive as close to the root folder as possible. This practice is
              particularly important due to the inherent 260 character path limit imposed by Windows. By extracting the
              zip archive near the root folder, you can significantly reduce the risk of encountering path length
              limitations and potential file extraction issues. <br /> Placing the extracted files in a location closer
              to the root directory helps to minimize the overall path length, allowing for smoother operations and
              eliminating the possibility of exceeding the maximum path limit. To extract the zip archive in the desired
              location, follow the steps outlined below: <br /> 1. Locate the zip archive file on your Windows system.{" "}
              <br /> 2. Right-click on the zip archive file to reveal a context menu. <br /> 3. From the context menu,
              select "Extract Here" or a similar option depending on the file archiving software you have installed.
              This will initiate the extraction process. <br /> 4. Ensure that the extraction destination is set to a
              location near the root folder, such as the C:\ drive or another suitable directory. <br /> 5. Proceed with
              the extraction by following any additional prompts or instructions provided by the archiving software.{" "}
              <br /> 6. Once the extraction is complete, you can access the extracted files from the specified
              destination folder, which should be in close proximity to the root directory. <br /> By adhering to this
              recommendation, you can avoid potential issues related to exceeding the maximum path length limit imposed
              by Windows and ensure a successful extraction of the zip archive.
            </Alert>
          )}
        </div>
      )}
    </div>,
    <Button color={"success"} variant="outlined" disabled={disableDownloadButton} startIcon={<CloudDownloadIcon />}>
      Download Zip
    </Button>,
    {
      width: "60vw",
      onClosed: () => initState(),
    },
  );

  return {
    ...zipModal,
  };
};
