// import {handleAuditTemplateConfig} from "../../AuditReport/functions/handleAuditTemplateConfig";

export const TEMPLATE_TYPES = {
  AUDIT_TEMPLATE: "AUDIT_TEMPLATE",
  INCIDENT_RESPONSE_EXERCISE_TEMPLATE: "INCIDENT_RESPONSE_EXERCISE_TEMPLATE",
};

export const TEMPLATE_TYPES_CONFIG = {
  AUDIT_TEMPLATE: () => {}, // TEMP handleAuditTemplateConfig,
};
