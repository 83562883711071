import { useState } from "react";
import { TaskStatus } from "../../../constants/taskStatus";
import { ItemMutation } from "../../../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { generateGraphql } from "@rivial-security/generategraphql";

/**
 * Hook to handle updating a task's status
 * @param {function} setTasks - The setter function for the active tasks
 * @param {function} setCompletedTasks - The setter function for the completed tasks
 * @returns {object}
 */
export const useUpdateTask = ({ setTasks, setCompletedTasks }) => {
  const [revertTimeout, setRevertTimeout] = useState(null);
  const { updateMutation } = generateGraphql("Task", ["id", "status"]);

  const updateTaskStatus = (task, newStatus) => {
    if (newStatus === TaskStatus.DONE) {
      // Remove the task from the active task list
      setTasks((prevTasks) => (prevTasks ? prevTasks.filter((t) => t?.id !== task?.id) : []));

      // Add the task to the completed task list
      setCompletedTasks((prevCompletedTasks) => [...(prevCompletedTasks || []), { ...task, status: newStatus }]);
    } else if (newStatus === TaskStatus.PAST_DUE) {
      // Remove the task from the active task list and from the completed task list
      setTasks((prevTasks) => (prevTasks ? prevTasks.filter((t) => t?.id !== task?.id) : []));

      setCompletedTasks((prevCompletedTasks) =>
        prevCompletedTasks ? prevCompletedTasks.filter((t) => t?.id !== task?.id) : [],
      );
    } else {
      // If the task status is switching from DONE
      setCompletedTasks((prevCompletedTasks) =>
        prevCompletedTasks ? prevCompletedTasks.filter((t) => t?.id !== task?.id) : [],
      );

      // Check if the task already exists in the active task list
      setTasks((prevTasks) => {
        if (prevTasks.some((t) => t?.id === task?.id)) {
          // If it exists, just update its status
          return prevTasks.map((t) => (t?.id === task?.id ? { ...t, status: newStatus } : t));
        } else {
          // If it doesn't exist, add it to the active task list
          return [...prevTasks, { ...task, status: newStatus }];
        }
      });
    }
  };
  const handleCheckboxClick = async (task) => {
    try {
      const currentStatus = task?.status;
      // update task status when user checks the checkbox
      let newStatus;

      if (task?.status === TaskStatus.DONE) {
        newStatus = TaskStatus.TODO;
      } else if (task?.status === TaskStatus.PAST_DUE) {
        newStatus = TaskStatus.DONE;
      } else {
        newStatus = TaskStatus.DONE;
      }

      await ItemMutation(updateMutation, {
        id: task?.id,
        status: newStatus,
      });
      updateTaskStatus(task, newStatus);

      const timeoutRef = setTimeout(() => {
        setRevertTimeout(null);
      }, 5000);

      setRevertTimeout({
        timeoutRef,
        task,
        currentStatus,
      });
    } catch (error) {
      EventLogger("Error updating task:", error);
    }
  };

  const handleUndo = async () => {
    if (revertTimeout) {
      clearTimeout(revertTimeout?.timeoutRef);

      updateTaskStatus(revertTimeout?.task, revertTimeout?.currentStatus);

      if (revertTimeout?.currentStatus === TaskStatus.PAST_DUE) {
        setTasks((prevTasks) => [...prevTasks, revertTimeout?.task]);
      }

      try {
        await ItemMutation(updateMutation, {
          id: revertTimeout?.task?.id,
          status: revertTimeout?.currentStatus,
        });
        setRevertTimeout(null);
      } catch (error) {
        EventLogger("Error updating task:", error);
      }
    }
  };

  return {
    handleCheckboxClick,
    updateTaskStatus,
    handleUndo,
    revertTimeout,
    setRevertTimeout,
  };
};
