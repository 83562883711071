/**
 * Author: Jacob Blazina
 * Last Edit: 7/18/19
 *            08/01/19 AR: Added Evidence query and mutation
 *            08/02/19 AR: Added Empty string if field is NULL
 *            08/02/19 AR: Added isAdmin check
 *            08/09/19 AR: Added check for NULL
 *            08/16/19 AR: Added Cancel Button
 *            08/16/19 AR: Added Input Type
 *            08/26/19 JB: Updated PropTypes and changed <textarea /> to <Input type="textarea"/>
 *            08/30/19 AR: Added Notes Mutation
 *            09/12/19 JB: Added inputType = "dropdown" functionality
 *            09/17/19 JB: Added disableEdits prop
 *            09/17/19 AR: Added Assessment mutation
 *            10/02/19 JB: Added maturity matrix mutations
 *            09/27/19 AR: Added Finding mutation
 *            10/02/19 AR: Added Target mutation
 *            11/14/19 AR: Added Testing Action mutation
 *            11/14/19 AR: Fixed inputType "Date"
 *            12/16/19 JB: Added Organization mutation
 *            07/08/20 AR: Added check if value is null
 *            07/15/20 AR: Added disableRoleChecking && Boolean type input
 *
 * Description: A re-usable generic component for editing
 *              the value of a Field. Includes button and
 *              text input.
 *
 *              Requires:
 *               1. Item to be edited (MUST HAVE ID)
 *               2. The NAME of the mutation (mutation must also be present in HOC in this component)
 *               3. The NAME of the field.
 *
 *              Note: To add a type functionality, include the mutation helper method at the bottom.
 *
 *              Using inputType = "dropdown":
 *                1. Use props.inputType = "dropdown"
 *                2. Must include props.dropdownInput from parent component
 *                3. props.dropdownInput needs initial value from parent, this value will replaced by this component on change.
 *                Example:
 *                                      <GenericEditField
 *                                       item={props.system}
 *                                       field="hosting"
 *                                       mutation="updateSystem"
 *                                       inputType="dropdown"
 *                                       dropdownInput={
                                              <Input type="select" value={props.system.hosting}>
                                                <option value={''}>None</option>
                                                <option value="outsourced">Outsourced</option>
                                                <option value="hybrid">Hybrid</option>
                                                <option value="internal">Internal</option>
                                              </Input>
                                           }
 />
 *
 */

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, CustomInput, FormGroup, Input } from "reactstrap";
import { EventLogger } from "../EventLogger/EventLogger";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useMutation } from "../../hooks/graphql/useMutation/useMutation";
import { UIContext } from "../Context/UIContext";
import {
  convertCamelCaseToSentence,
  formattedDollar,
  formattedPercent,
  isNullOrUndefined,
} from "@rivial-security/func-utils";
import PermissionsOverlay from "../Overlays/PermissionsOverlay";
import { emptyPlaceholder } from "./Placeholders";
import { USER_ACTIVITY_TYPES } from "../../views/OrganizationManager/Users/enums/USER_ACTIVITY_TYPES";
import { createPortal } from "react-dom";
import EditButton from "./GenericEditFieldV3/components/EditButton";
import { useBoolean } from "../../hooks/functional/useBoolean";
import CustomTooltip from "./CustomTooltip";

/**
 * An all-in-one solution for grid and detail components to make editable fields
 * @param {string} module - The module that the field belongs to
 * @param {string} resource - The resource that the field belongs to
 * @param {string} field - The name of the field
 * @param {string} fieldOverride - The name of the field to override
 * @param {object} item - The item to edit
 * @param {string} mutation - The name of the mutation to use
 * @param {string} inputType - The type of input to use
 * @param {boolean} disableEdits - Whether or not to disable edits
 * @param {boolean} forceLowercase - Whether or not to force the field to lowercase
 * @param {object} dropdownInput - The input to use for dropdown
 * @param {string} format - The format to use for the input
 * @param {object} genericEditFieldConfig - The config to use for the input
 * @param {boolean} disableRoleChecking - Whether or not to disable role checking
 * @param {function} resetFunction - The function to call when the field is reset
 * @param {number} minInputNumber - The minimum number of characters to input
 * @param {number} maxInputNumber - The maximum number of characters to input
 * @param {function} confirmFunction - a function that accepts the new input object and returns FALSE if input is denied and TRUE
 * if accepted, useful for confirmation dialogs to show up based on a set of conditions
 * @param {boolean} required - Whether or not the field is required
 * @param {boolean} updateItemById - A function to call when the field is updated
 * @param {function} updateInputFunction - A function to call when the field is updated
 * @param {function} customFormat - A function to call when the field is updated
 * @param {function} updateMutationFunction - A function to call when the field is updated
 * @param {boolean} disableToast - Whether or not to disable the toast
 * @param {string} prefix - text to put in front of the display component
 * @param {string} suffix - text to put after the display component
 * @param {boolean} [suffixIsNoun = false] - TRUE if can append an "s" after the suffix if value is higher than 1
 * @param typename {string} - The type of the item
 * @param {JSX.Element} deleteButton - optionally render a delete button next to the edit button
 * @param {string} tooltip - optionally render a tooltip next to the edit button
 * @param {function} submitFunction - async submitFunction that bypasses the built in submit logic
 * @param {boolean} defaultOpen - defaults the edit field to open
 * @return {*} - The component
 * @constructor
 */
const GenericEditField = ({
  module,
  resource,
  field,
  fieldOverride, // used if the mutation needs a different field name than whats used in role checking
  item,
  mutation,
  inputType,
  disableEdits,
  forceLowercase = false,
  dropdownInput,
  format,
  genericEditFieldConfig,
  disableRoleChecking,
  resetFunction,
  minInputNumber,
  maxInputNumber,
  confirmFunction,
  required,
  updateItemById,
  updateInputFunction,
  customFormat,
  updateMutationFunction,
  disableToast,
  prefix,
  suffix,
  suffixIsNoun = false,
  typename,
  deleteButton,
  tooltip,
  submitFunction,
  defaultOpen = false,
}) => {
  const [toggleEdit, setToggleEdit] = useState(defaultOpen);
  const [value, setValue] = useState(item?.hasOwnProperty(field) ? item[field] : null);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const currentValue = item?.hasOwnProperty(field) ? item[field] : null;

    if (customFormat && typeof customFormat === "function") {
      setValue(customFormat(currentValue));
    } else {
      setValue(currentValue);
    }
  }, [item]);

  const [originalValue, setOriginalValue] = useState(value);

  const { addToast, updateToast } = useContext(UIContext);

  const updateMutationHook = useMutation({
    module,
    resource,
    mutation,
    field,
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
  });

  const saveChange = () => {
    const toastId =
      !disableToast &&
      addToast({
        icon: "spinner",
        color: "warning",
        header: "Updating field..",
      });

    if (required && (value === null || value === "")) {
      !disableToast &&
        updateToast({
          id: toastId,
          icon: "danger",
          header: `Field '${field}' can not be empty`,
        });

      setValue(item[field]);
      return;
    }

    //Perform specified adjustments to the value
    let finalValue = value;

    if (forceLowercase) {
      finalValue = value.toLowerCase();
    }

    if (updateInputFunction && typeof updateInputFunction === "function") {
      finalValue = updateInputFunction(finalValue);
    }

    //Check with the confirm function if the change should be applied
    if (!isNullOrUndefined(confirmFunction) && confirmFunction(finalValue) === false) {
      const res = `Update to field '${field}' was canceled`;
      !disableToast &&
        updateToast({
          id: toastId,
          icon: "danger",
          header: res,
        });
      setValue(item[field]);
      return;
    }

    if (customFormat && typeof customFormat === "function") {
      setValue(customFormat(finalValue));
    } else {
      setValue(finalValue);
    }

    if (originalValue !== finalValue) {
      let mutationInput = {
        id: item.id,
        [fieldOverride || field]: finalValue ? finalValue : null,
      };

      if (!isNullOrUndefined(updateMutationFunction) && typeof updateMutationFunction === "function") {
        mutationInput = updateMutationFunction(mutationInput);
      }

      if (typeof submitFunction === "function") {
        (async () => await submitFunction({ value: finalValue, field }))();
      } else {
        updateMutationHook.editItem(mutationInput).then((data) => {
          let resValue;

          if (customFormat && typeof customFormat === "function") {
            resValue = customFormat(finalValue);
          } else {
            resValue = finalValue;
          }

          const res = `Field: ${field} | Updated to: ${resValue}`;
          EventLogger(res);
          !disableToast &&
            updateToast({
              id: toastId,
              icon: "warning",
              header: res,
              data: {
                type: USER_ACTIVITY_TYPES.UPDATE,
                itemId: data?.id,
                typename,
                name: data?.name,
                field,
                value: resValue,
              },
            });

          if (customFormat && typeof customFormat === "function") {
            setOriginalValue(customFormat(value));
          } else {
            setOriginalValue(value);
            setValue(resValue);
          }

          if (typeof updateItemById === "function") {
            updateItemById(data);
          } else if (typeof resetFunction === "function") {
            resetFunction();
          }
        });
      }
    } else {
      const res = `The value for field \`${field}\` hasn't changed.`;
      EventLogger(res);
      !disableToast &&
        updateToast({
          id: toastId,
          icon: "danger",
          header: res,
        });
    }
  };

  const toggle = (e) => {
    e.stopPropagation();
    if (toggleEdit) {
      saveChange();
    }

    setToggleEdit(!toggleEdit);
  };

  const cancel = (e) => {
    e.stopPropagation();
    setToggleEdit(!toggleEdit);

    if (customFormat && typeof customFormat === "function") {
      setValue(customFormat(item[field]));
    } else {
      setValue(item[field]);
    }
  };

  const updatePermission = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking: disableRoleChecking ? disableRoleChecking : undefined,
  }).field.update;

  /**
   * Allows the user to use the "Enter" key to save a step
   * @param event
   */
  const onEnterKey = (event) => {
    if (event.key === "Enter") {
      toggle(event);
    }
  };

  const getSuffix = () => {
    if (!suffix || value === "" || value === null || value === undefined) {
      return "";
    }

    if (suffixIsNoun && inputType === "number" && value > 1) {
      return `${suffix}s`;
    } else {
      return suffix;
    }
  };

  /**
   * If the field is hovered or not, used for edit buttons
   */
  const [isHovered, setIsHovered] = useBoolean(false);

  return (
    <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ width: "100%" }}>
      <PermissionsOverlay {...{ module, resource, field, disableRoleChecking }}>
        <>
          <>
            {!toggleEdit ? (
              <>
                {!prefix || value === "" || value === null || value === undefined ? "" : `${prefix} `}
                {value !== null && value !== undefined
                  ? inputType && inputType === "date"
                    ? new Date(value).toLocaleString()
                    : inputType && inputType === "boolean"
                      ? value
                        ? "Yes"
                        : "No"
                      : format && format === "dollar"
                        ? `${formattedDollar(value)} `
                        : format && format === "percent"
                          ? `${formattedPercent(value)} `
                          : format && format === "sentence"
                            ? `${convertCamelCaseToSentence(value)} `
                            : value === ""
                              ? emptyPlaceholder
                              : `${value} `
                  : emptyPlaceholder}
                {getSuffix()}
                {item?.id && mutation && updatePermission && !disableEdits ? (
                  <EditButton
                    field={field}
                    toggle={toggle}
                    disableEdits={disableEdits}
                    mutation={mutation}
                    mutationFunction={updateMutationFunction}
                    updatePermission={updatePermission}
                    isHovered={isHovered}
                    deleteButton={deleteButton}
                  />
                ) : null}
              </>
            ) : (
              <FormGroup data-testid={"generic-edit-field-form-group"}>
                {inputType === "textarea" ||
                (genericEditFieldConfig &&
                  genericEditFieldConfig[field] &&
                  genericEditFieldConfig[field].inputType &&
                  genericEditFieldConfig[field].inputType === "textarea") ? (
                  <Input
                    data-testid={`textarea-input-field-${field}`}
                    autoFocus={true}
                    type="textarea"
                    value={value}
                    rows="4"
                    cols="25"
                    onChange={(e) => setValue(e.target.value)}
                  />
                ) : inputType === "dropdown" && dropdownInput ? (
                  React.cloneElement(dropdownInput, {
                    value: value,
                    onChange: (e) => setValue(e.target.value),
                  })
                ) : inputType === "toggle" ? (
                  <CustomInput
                    data-testid={`toggle-input-field-${field}`}
                    type="switch"
                    id="exampleCustomSwitch"
                    name="customSwitch"
                    label="This Indicator is Satisfied"
                  />
                ) : inputType === "date" ? (
                  <DatePicker
                    popperContainer={
                      //REFERENCE: https://github.com/Hacker0x01/react-datepicker/issues/2545
                      //places date popper above all other components
                      ({ children }) => createPortal(children, document.body)
                    }
                    data-testid={`date-input-field-${field}`}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="Select a Date and Time"
                    showTimeInput
                    minDate={new Date(null)}
                    maxDate={new Date().setMonth(new Date().getMonth() + 24)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={value ? new Date(value) : new Date()}
                    onChange={(date) => {
                      setValue(date);
                    }}
                  />
                ) : inputType === "number" ? (
                  <>
                    <Input
                      data-testid={`number-input-field-${field}`}
                      type="number"
                      min={minInputNumber}
                      max={maxInputNumber}
                      value={value}
                      step="1"
                      onChange={(e) => {
                        if (minInputNumber !== undefined && maxInputNumber !== undefined) {
                          if (e.target.value >= minInputNumber && e.target.value <= maxInputNumber) {
                            setValue(e.target.value);
                            setAlertMessage(null);
                          } else {
                            setAlertMessage(`Input range from ${minInputNumber} to ${maxInputNumber}`);
                            setValue("");
                          }
                        } else {
                          setValue(e.target.value);
                          setAlertMessage(null);
                        }
                      }}
                    />
                    {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
                  </>
                ) : inputType === "boolean" ? (
                  <CustomInput
                    data-testid={`switch-input-field-${field}`}
                    id={`switch-${field}`}
                    type="switch"
                    checked={value}
                    onChange={() => setValue(!value)}
                    title={`Turn On/Off ${field}`}
                  />
                ) : (
                  <Input
                    data-testid={`text-input-field-${field}`}
                    autoFocus={true}
                    value={value}
                    onKeyPress={onEnterKey}
                    onChange={(e) => setValue(e.target.value)}
                  />
                )}
                <br />
                <Button
                  data-testid={`button-submit-field-${field}`}
                  size="sm"
                  color="success"
                  className="btn-pill"
                  onClick={(e) => toggle(e)}
                >
                  <i className="icon-check" />
                </Button>
                <Button
                  data-testid={`button-cancel-field-${field}`}
                  size="sm"
                  color="danger"
                  className="btn-pill"
                  onClick={(e) => cancel(e)}
                >
                  <i className="icon-close" />
                </Button>
              </FormGroup>
            )}
          </>
          {tooltip && (
            <span className={"float-right"}>
              <CustomTooltip tooltip={tooltip} />
            </span>
          )}
        </>
      </PermissionsOverlay>
    </span>
  );
};

GenericEditField.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  mutation: PropTypes.string,
  inputType: PropTypes.string, // Options: "textarea", "dropdown", "toggle", "date
  dropdownInput: PropTypes.object, // Only applicable is inputType = "dropdown"
  disableEdits: PropTypes.bool,
};

export default GenericEditField;
