import { modules, resources } from "role-utils";
import PointOfContactField from "@utils/CustomFields/PointOfContactField/PointOfContactField";
import { ReactElement } from "react";
import { PointOfContactFieldProps } from "@views/Testing/types";

export const VulnerabilityPointOfContact = ({
  item,
  module: initModule,
  resource: initResource,
}: PointOfContactFieldProps): ReactElement => {
  const module = initModule || modules.VULNERABILITIES;
  const resource = initResource || resources.VULNERABILITY;
  const typename = resources.VULNERABILITY;

  return (
    <PointOfContactField
      item={item}
      module={module}
      resource={resource}
      typename={typename}
      field={"pointOfContact"}
      idField={"pointOfContactID"}
    />
  );
};

export default VulnerabilityPointOfContact;
