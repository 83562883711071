import { formattedName } from "@rivial-security/func-utils";
import React, { useEffect, useState } from "react";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { useModal } from "../../../../../../hooks/views/useModal";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "reactstrap";
import { modules, resources } from "@rivial-security/role-utils";
import PointOfContactDashboard from "../../../../../OrganizationManager/PointOfContacts/components/PointOfContactDashboard/components/PointOfContactDashboard";

/**
 * @description Displays timeline information about a point of contact assign another point of contact for an Evidence
 * @param {string} data - custom data, needs to be parsed
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactAssignedActivity = ({ data }) => {
  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.POINT_OF_CONTACT;

  const [customData, setCustomData] = useState({});

  useEffect(() => {
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        setCustomData(parsedData);
      } catch (e) {
        EventLogger("Cannot parse custom data for PointOfContactAssignedActivity", e);
      }
    }
  }, [data]);

  const assignedFullName = formattedName({
    pointOfContact: customData?.changedPointOfContact,
  });

  const pointOfContactModal = useModal(
    "Point Of Contact Details",
    <PointOfContactDashboard item={customData?.changedPointOfContact} module={module} resource={resource} />,
    <Button color={"success"}>
      <i className={"icon-eye"} style={{ marginRight: "1em" }} />
      Open Details
    </Button>,
    {
      width: "80vw",
    },
  );

  return (
    <span>
      {customData?.pointOfContactName || "Someone"} assigned{" "}
      {customData?.changedPointOfContact?.id ? (
        <LightTooltip arrow interactive title={<div>{pointOfContactModal.modalButton}</div>}>
          <span
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none;",
              cursor: "pointer",
            }}
          >
            {assignedFullName}
          </span>
        </LightTooltip>
      ) : (
        "Someone"
      )}{" "}
      to this evidence
    </span>
  );
};

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default PointOfContactAssignedActivity;
