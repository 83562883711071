import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 *  Creates a VendorControlDocumentLink between a VendorSubControl and VendorDocument
 *
 * @param controls {object[]}
 * @param control {object}
 * @param documents {object[]}
 * @param document {object}
 * @param organizationID {string}
 * @returns {Promise<void>}
 * @constructor
 */

export const CreateVendorControlDocumentLink = async ({ controls, control, documents, document, organizationID }) => {
  const { createMutation } = generateGraphql("VendorControlDocumentLink", ["documentID", "controlID"]);

  if (controls && Array.isArray(controls) && controls.length > 0 && document && document.id) {
    for (const item of controls) {
      if (item && item.id) {
        await ItemMutation(createMutation, {
          documentID: document.id,
          controlID: item.id,
          ownerGroup: organizationID,
        }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
      }
    }
  } else if (documents && Array.isArray(documents) && documents.length > 0 && control && control.id) {
    for (const item of documents) {
      if (item && item.id) {
        await ItemMutation(createMutation, {
          documentID: item.id,
          controlID: control.id,
          ownerGroup: organizationID,
        }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
      }
    }
  } else if (document && document.id && control && control.id) {
    await ItemMutation(createMutation, {
      documentID: document.id,
      controlID: control.id,
      ownerGroup: organizationID,
    }).then(() => EventLogger("VendorControlDocumentLink was successfully created."));
  } else {
    EventLogger("Error! Invalid input for VendorControlDocumentLink function!");
  }
};
