import { generateGraphql } from "@rivial-security/generategraphql";
import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { EventLogger } from "../../../../utils/EventLogger/EventLogger";

/**
 * Deletes a CustomResourceType along with all of it's CustomResourceEntries.
 * @param customResourceType
 * @returns {Promise<*|null>}
 */
export const deleteCustomResourceType = async (customResourceType) => {
  if (!customResourceType || !customResourceType.id) {
    return null;
  }

  const { getQuery } = generateGraphql("CustomResourceType", ["customResourceEntries", "__typename"], {
    customResourceEntries: `(limit: 1000) {
        items {
          id
          __typename
        }
        nextToken
      }`,
  });

  return await QueryGetItem({
    query: getQuery,
    itemId: customResourceType.id,
  }).then(async (fullCustomResourceType) => {
    await GenericDeleteGQL({ objectToDelete: fullCustomResourceType }).then(() =>
      EventLogger(`customResourceType was Successfully Deleted!`),
    );
  });
};
