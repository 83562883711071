import { EventLogger } from "../../../../utils/EventLogger/EventLogger";
import { checkArguments } from "@rivial-security/func-utils";
import { deleteControlEvidenceLink } from "../../ControlEvidenceLinks/functions/deleteControlEvidenceLink";

/**
 * Performs the operation for unlinking Control items from an Evidence
 * @param {Control[]} controls - the controls to unlink
 * @param {Evidence} evidence - the evidence to unlink from
 * @returns {Promise<void>}
 */
export const handleUnlinkingControls = async (controls, evidence) => {
  try {
    checkArguments(
      {
        controls,
        evidence,
      },
      {
        controls: { type: "object", isArray: true },
        evidence: { type: "object" },
      },
    );
  } catch (e) {
    EventLogger("Error: invalid arguments", e);
    return;
  }

  for (const unlinkItem of controls) {
    if (unlinkItem && evidence?.controls?.items) {
      // loop through control's current evidence links
      for (const controlLink of evidence.controls.items) {
        // if evidence is already linked to this control, unlink
        if (controlLink?.control?.id === unlinkItem.id) {
          await deleteControlEvidenceLink(controlLink.id);
        }
      }
    }
  }
};
