import { generateGraphql } from "@rivial-security/generategraphql";
import { ItemMutation } from "@rivial-security/appsync-utils";
import { EventLogger } from "@utils/EventLogger/EventLogger";
import { System, QuestionnaireSystemLink } from "schema-types";
import { autoAssignQuestionnaireToSystemContacts } from "@views/OrganizationManager/Questionnaires/functions/autoAssignQuestionnaireToSystemContacts";

export interface LinkQuestionnaireToSystemsParams {
  questionnaireID: string;
  selectedSystems: System[];
  organizationID: string;
}
/**
 * Links a questionnaire to one or more systems
 */
export const linkQuestionnaireToSystems = async ({
  questionnaireID,
  selectedSystems,
  organizationID,
}: LinkQuestionnaireToSystemsParams): Promise<PromiseSettledResult<Partial<QuestionnaireSystemLink>>[]> => {
  const { createMutation: createQuestionnaireSystemLink } = generateGraphql("QuestionnaireSystemLink", [
    "id",
    "ownerGroup",
    "systemID",
    "questionnaireID",
  ]);

  const systemQuestionnaireLinkPromises = selectedSystems.map(async (system) => {
    const systemID = system.id;
    const ownerGroup = organizationID;
    try {
      const link = await ItemMutation<Partial<QuestionnaireSystemLink>>({
        mutation: createQuestionnaireSystemLink,
        input: {
          ownerGroup,
          systemID,
          questionnaireID,
        },
      });
      await autoAssignQuestionnaireToSystemContacts({
        questionnaireID,
        systemAdmin: system?.adminPointOfContact,
        systemOwner: system?.pointOfContact,
        organizationID,
      });
      EventLogger(`Questionnaire linked to system: ${system.name}`);
      return link;
    } catch (error) {
      EventLogger(`Failed to link questionnaire to system ${system.name}:`, error);
      throw error;
    }
  });

  return Promise.allSettled(systemQuestionnaireLinkPromises);
};
