import { useContext, useEffect, useState } from "react";
import { getPointOfContactDepartmentId } from "../functions/getPointOfContactDepartmentId";
import { EventLogger } from "../../../../../../utils/EventLogger/EventLogger";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";

/**
 * custom hook to get the departmentID and departmentHeadID of the logged in user
 * @returns {{departmentHeadID: string, departmentID: string}}
 */
export const useDepartmentInfo = () => {
  const [departmentID, setDepartmentID] = useState(null);
  const [departmentHeadID, setDepartmentHeadID] = useState(null);
  const context = useContext(OrganizationContext);

  useEffect(() => {
    getPointOfContactDepartmentId({
      pointOfContactId: context?.loggedInPointOfContactId,
      setDepartmentID,
      setDepartmentHeadID,
    })
      .then((data) => {
        setDepartmentID(data?.departmentID);
        setDepartmentHeadID(data?.department?.departmentHeadID);
      })
      .catch((error) => {
        EventLogger("Failed to get department info:", error);
      });
  }, [context?.loggedInPointOfContactId]);

  return { departmentID, departmentHeadID };
};
